import * as Yup from "yup";

const phoneRegExp = /^[4-9]{1}[-\s\.]?[0-9]{9,11}$/im;
const descriptionMsg = "Description is required";
const fileMsg = "Please upload an Image";


export const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .max(100, "Password should not be more than 100 characters long")
    .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/, 'Password must be at least 8 characters long and should contain one lowercase letter, one uppercase letter, one numeric digit and one special character'),
  email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email should not be more than 100 characters long")
    .required("Email is required"),
  remember_me: Yup.bool().notRequired(),
});

export const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .max(100, "Password should not be more than 100 characters long")
    .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/, 'Password must be at least 8 characters long and should contain one lowercase letter, one uppercase letter, one numeric digit and one special character'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email should not be more than 100 characters long")
    .notRequired()
});

export const MyProfileSchema = Yup.object().shape({
  owner_name: Yup.string()
    .required("Name is required")
    .max(100, "Name should not be more than 100 characters long")
    .trim(),
  phone_number: Yup.string()
    .required("Phone number is required")
    .max(15, "Phone number should not be more than 15 characters long"),
  country_code_and_flag_id: Yup.string()
    .required("Country code is required. ")
    .trim(),
  owner_email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email should not be more than 100 characters long")
    .required("Email is required"),
  current_password: Yup.string()
    .when('new_password', {
      is: (new_password) => new_password && new_password.length !== 0,
      then: Yup.string()
        .required('Current Password is required')
    })
    .max(100, "Password should not be more than 100 characters long")
    .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/, 'Password must be at least 8 characters long and should contain one lowercase letter, one uppercase letter, one numeric digit and one special character'),
  new_password: Yup.string()
    .when('current_password', {
      is: (current_password) => current_password && current_password.length !== 0,
      then: Yup.string()
        .required('New Password is required')
        .test('passwords-match', 'New password should not be same as current password', function (value) {
          return this.parent.current_password !== value
        }),
    })
    .when('confirm_new_password', {
      is: (confirm_new_password) => confirm_new_password && confirm_new_password.length !== 0,
      then: Yup.string()
        .required('New Password is required')
    })
    .max(100, "Password should not be more than 100 characters long")
    .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/, 'Password must be at least 8 characters long and should contain one lowercase letter, one uppercase letter, one numeric digit and one special character'),
  confirm_new_password: Yup.string()
    .when('new_password', {
      is: (current_password) => current_password && current_password.length !== 0,
      then: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('new_password'), null], 'Confirm password must match with new password'),
    })
}, [["current_password", "new_password"], ["new_password", "confirm_new_password"]]);


export const CompanyProfileSchema1 = Yup.object().shape({
  name: Yup.string()
    .required("Company name is required")
    .max(100, "Company name should not be more than 100 characters long")
    .trim(),
  owner_name: Yup.string()
    .required("Full name is required")
    .max(100, "Full name should not be more than 100 characters long")
    .trim(),
  phone_number: Yup.string()
    .required("Phone number is required")
    .max(15, "Phone number should not be more than 15 characters long"),
  owner_email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email should not be more than 100 characters long")
    .required("Email is required"),
  country_code_and_flag_id: Yup.string()
    .required("Country code is required. ")
    .trim(),
});

export const CompanyProfileSchema2 = Yup.object().shape({
  industry_id: Yup.string().notRequired(),
  business_type_id: Yup.string().notRequired(),
});

export const CompanyProfileSchema3 = Yup.object().shape({
  creation_date: Yup.string()
    .nullable()
    .required("Creation date is required"),
  employee_count: Yup.number()
    .nullable()
    .min(1, "Employee count should not be less than 1")
    .required("Employee count is required"),
  head_office: Yup.string()
    .required("Head office is required")
    .trim(),
  from_working_hours: Yup.string()
    .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'From time should be in HH:MM format.')
    .required("From working hours is required. ")
    .nullable(),
  to_working_hours: Yup.string()
    .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, ' To time should be in HH:MM format.')
    .required(" TO working hours is required.")
    .nullable(),
});

export const AddNewBranchSchema = Yup.object().shape({
  branch_name: Yup.string()
    .required("Branch name is required"),
  type_of_point_of_sale: Yup.string()
    .required("Point of sale is required"),
  location_of_point_of_sale: Yup.string()
    .required("Location of sale is required"),
  phone_number: Yup.string()
    .required("Phone number is required.")
    .max(15, "Phone number should not be more than 15 characters long"),
  email: Yup.string().notRequired()
    .email("Invalid email address")
    .max(100, "Email should not be more than 100 characters long"),

  photo: Yup.mixed().notRequired(),
  description: Yup.string().max(500, "Decription should not be more than 500 characters long").notRequired(),
  important_information: Yup.string().max(500, "Information should not be more than 500 characters long").notRequired(),
  country_code_and_flag_id: Yup.string()
    .required("Country code is required. ")
    .nullable()
    .trim(),
  time_zone: Yup.string()
    .required("Time zone is required")
    .trim(),
  from_working_hours: Yup.string()
    .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'From time should be in HH:MM format.')
    .required("From working hours is required. ")
    .nullable(),
  to_working_hours: Yup.string()
    .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, ' To time should be in HH:MM format.')
    .required(" TO working hours is required.")
    .nullable(),
});
export const CustomizeThemeSchema = Yup.object().shape({
  primary_colour: Yup.string()
    .required("Primary color is required")
    .length(7, "Primary color should be 6 characters long")
    .trim(),
  secondary_colour: Yup.string()
    .required("Secondary color is required")
    .length(7, "Secondary color should be 6 characters long")
    .trim()
});

export const InviteEmpoyeesSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email should not be more than 100 characters long")
    .required("Email is required")
    .nullable(),
  branchId: Yup.number().required("Branch is required"),
  description: Yup.mixed()
    .when('role', {
      is: (role) => role && role === 3,
      then: Yup.array().min(1, "Department is required"),
      otherwise: Yup.string().required("Department is required"),
    }),
  role: Yup.number().required("Role is required").nullable(),
})

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .max(100, "Email should not be more than 100 characters long")
});
export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .max(100, "Password should not be more than 100 characters long")
    .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/, 'Password must be at least 8 characters long and should contain one lowercase letter, one uppercase letter, one numeric digit and one special character'),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),

});

export const createTicketTypeSchema = Yup.object().shape({
  issue_name: Yup.string().required("Issue Name is required"),
  department_id: Yup.array().required("Department is required"),
})
export const CreateTicketSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .max(100, "Title should not be more than 100 characters long")
    .trim(),
  due_date: Yup.string()
    .required("Due date is required")
    .trim(),
  ticket_type_id: Yup.string()
    .required("Please select Ticket type ")
    .trim(),
  branch_id: Yup.string()
    .required("Please select Branch")
    .trim(),
  department_id: Yup.string()
    .required("Please select department")
    .trim(),
  assignee_ids: Yup.string()
    .required("Please select Employee")
    .trim(),
  priority: Yup.string()
    .required("Please select Priority")
    .trim(),
  description: Yup.string()
    .required("Description is required")
    .max(500, "Description should not be more than 500 characters long")
});
export const CreateTaskSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .max(100, "Title should not be more than 100 characters long")
    .trim(),
  description: Yup.string()
    .required("Description is required")
    .max(500, "Description should not be more than 500 characters long"),
  due_date: Yup.string()
    .required("Due date is required")
    .trim(),
  branch_id: Yup.string()
    .required("Please select Branch")
    .trim(),
  department_id: Yup.string()
    .required("Please select department")
    .trim(),
  assignee_ids: Yup.string()
    .required("Please select Employee")
    .trim(),
  priority: Yup.string()
    .required("Please select Priority")
    .trim(),

});
export const CompanyProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required("Company name is required")
    .max(100, "Company name should not be more than 100 characters long")
    .trim(),
  owner_name: Yup.string()
    .required("Full name is required")
    .max(100, "Full name should not be more than 100 characters long")
    .trim(),
  phone_number: Yup.string()
    .required("Phone number is required")
    .max(15, "Phone number should not be more than 15 characters long"),
  owner_email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email should not be more than 100 characters long")
    .required("Email is required"),
  industry_id: Yup.string().required("Industry is required."),
  business_type_id: Yup.string().required("Business type is required."),
  creation_date: Yup.string()
    .nullable()
    .required("Creation date is required"),
  employee_count: Yup.number()
    .nullable()
    .min(1, "Employee count should not be less than 1")
    .required("Employee count is required"),
  head_office: Yup.string()
    .required("Head office is required")
    .trim(),
  description: Yup.string().max(500, "Decription should not be more than 500 characters long").notRequired(),
  photo: Yup.mixed().notRequired(),
  country_code_and_flag_id: Yup.string()
    .required("Country code is required")
    .trim(),
  from_working_hours: Yup.string()
    .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'From time should be in HH:MM format.')
    .required("From working hours is required. ")
    .nullable(),
  to_working_hours: Yup.string()
    .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, ' To time should be in HH:MM format.')
    .required(" TO working hours is required.")
    .nullable(),
});

export const BaseTemplateSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .max(100, "Title should not be more than 100 characters long")
    .trim(),
  branch_id: Yup.string()
    .required("Please select branch")
    .trim(),
  department_id: Yup.string()
    .required("Please select department")
    .trim(),
});

export const EditTemplate8Schema = BaseTemplateSchema.concat(

  Yup.object().shape({
    description1: Yup.string()
      .required(descriptionMsg),
    description2: Yup.string()
      .required(descriptionMsg),
  }))

export const CreateTemplate8Schema = EditTemplate8Schema.concat(
  Yup.object().shape({
    image1: Yup.mixed().required(fileMsg),
    image2: Yup.mixed().required(fileMsg),
  }))
export const EditTemplate1Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string()
      .required(descriptionMsg),
  }))
export const CreateTemplate1Schema = EditTemplate1Schema.concat(
  Yup.object().shape({
    image1: Yup.mixed().required(fileMsg),
  }))

export const EditTemplate3Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string()
      .required(descriptionMsg),
    description2: Yup.string()
      .required(descriptionMsg),
    description3: Yup.string()
      .required(descriptionMsg),
    description4: Yup.string()
      .required(descriptionMsg),
  }))
export const CreateTemplate3Schema = EditTemplate3Schema.concat(
  Yup.object().shape({
    image1: Yup.mixed().required(fileMsg),
    image2: Yup.mixed().notRequired(),
    image3: Yup.mixed().notRequired(),
    image4: Yup.mixed().notRequired(),
  }))

export const EditTemplate4Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string().required(descriptionMsg),
    description2: Yup.string().required(descriptionMsg),
    description3: Yup.string().required(descriptionMsg),
    description4: Yup.string().required(descriptionMsg),
    description5: Yup.string().required(descriptionMsg),
    description6: Yup.string().required(descriptionMsg),
    description7: Yup.string().required(descriptionMsg),
    description8: Yup.string().required(descriptionMsg),
  }))

export const CreateTemplate4Schema = EditTemplate4Schema.concat(
  Yup.object().shape({
    image1: Yup.mixed().required(fileMsg),
    image2: Yup.mixed().required(fileMsg),
    image3: Yup.mixed().required(fileMsg),
    image4: Yup.mixed().required(fileMsg),
    image5: Yup.mixed().required(fileMsg),
    image6: Yup.mixed().required(fileMsg),
    image7: Yup.mixed().required(fileMsg),
    image8: Yup.mixed().required(fileMsg),
  }))

export const EditTemplate5Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string().required(descriptionMsg),
    description2: Yup.string().required(descriptionMsg),
    description3: Yup.string().required(descriptionMsg),
    description4: Yup.string().required(descriptionMsg),
  }))
export const CreateTemplate5Schema = EditTemplate5Schema.concat(
  Yup.object().shape({
    image1: Yup.mixed().required(fileMsg),
    image2: Yup.mixed().required(fileMsg),
    image3: Yup.mixed().required(fileMsg),
    image4: Yup.mixed().required(fileMsg),
  }))

export const EditTemplate9Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string()
      .required(descriptionMsg),
    description2: Yup.string()
      .notRequired(),
    description3: Yup.string()
      .notRequired(),
  }))
export const CreateTemplate9Schema = EditTemplate9Schema.concat(
  Yup.object().shape({
    image1: Yup.mixed().required(fileMsg),
    image2: Yup.mixed().required(fileMsg),
    image3: Yup.mixed().required(fileMsg),
  }))

export const EditTemplate10Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string()
      .required(descriptionMsg),
    description2: Yup.string()
      .notRequired(),
  }))
export const CreateTemplate10Schema = EditTemplate10Schema.concat(
  Yup.object().shape({
    image1: Yup.mixed().required(fileMsg),
    image2: Yup.mixed().required(fileMsg),
  }))

export const EditTemplate11Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string().required(descriptionMsg),
    description2: Yup.string().required(descriptionMsg),
    description3: Yup.string().required(descriptionMsg),
    description4: Yup.string().required(descriptionMsg),
    description5: Yup.string().required(descriptionMsg),
  }))
export const CreateTemplate11Schema = EditTemplate11Schema.concat(
  Yup.object().shape({
    image1: Yup.mixed().notRequired(),
    image2: Yup.mixed().required(fileMsg),
    image3: Yup.mixed().required(fileMsg),
    image4: Yup.mixed().required(fileMsg),
    image5: Yup.mixed().required(fileMsg),
  }))

export const EditTemplate12Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string().required(descriptionMsg),
    description2: Yup.string().required(descriptionMsg),
    description3: Yup.string().required(descriptionMsg),
    description4: Yup.string().required(descriptionMsg),
    description5: Yup.string().required(descriptionMsg),
    description6: Yup.string().required(descriptionMsg),
  }))

export const CreateTemplate12Schema = EditTemplate12Schema.concat(
  Yup.object().shape({
    image1: Yup.mixed().required(fileMsg),
    image2: Yup.mixed().required(fileMsg),
    image3: Yup.mixed().required(fileMsg),
    image4: Yup.mixed().required(fileMsg),
    image5: Yup.mixed().required(fileMsg),
    image6: Yup.mixed().required(fileMsg),
  }))
export const EditTemplate13Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string().required(descriptionMsg),
    description2: Yup.string().required(descriptionMsg),
    description3: Yup.string().required(descriptionMsg),
    description4: Yup.string().required(descriptionMsg),
    description5: Yup.string().required(descriptionMsg),
    description6: Yup.string().required(descriptionMsg),
    description7: Yup.string().required(descriptionMsg),
    description8: Yup.string().required(descriptionMsg),
    description9: Yup.string().required(descriptionMsg),
    description10: Yup.string().required(descriptionMsg),
    description11: Yup.string().required(descriptionMsg),
    description12: Yup.string().required(descriptionMsg),
  }))

export const CreateTemplate13Schema = EditTemplate13Schema.concat(
  Yup.object().shape({
    image1: Yup.mixed().required(fileMsg),
    image2: Yup.mixed().required(fileMsg),
    image3: Yup.mixed().required(fileMsg),
    image4: Yup.mixed().required(fileMsg),
    image5: Yup.mixed().required(fileMsg),
    image6: Yup.mixed().required(fileMsg),
    image7: Yup.mixed().required(fileMsg),
    image8: Yup.mixed().required(fileMsg),
    image9: Yup.mixed().required(fileMsg),
    image10: Yup.mixed().required(fileMsg),
    image11: Yup.mixed().required(fileMsg),
    image12: Yup.mixed().required(fileMsg),
  }))

export const EditTemplate14Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string().required(descriptionMsg),
    description2: Yup.string().required(descriptionMsg),
    description3: Yup.string().required(descriptionMsg),
    description4: Yup.string().required(descriptionMsg),
    description5: Yup.string().required(descriptionMsg),
    description6: Yup.string().required(descriptionMsg),
    description7: Yup.string().required(descriptionMsg),
    description8: Yup.string().required(descriptionMsg),
  }))

export const CreateTemplate14Schema = EditTemplate14Schema.concat(
  Yup.object().shape({
    image1: Yup.mixed().required(fileMsg)
  }))

export const EditTemplate16Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string()
      .required(descriptionMsg),
    description2: Yup.string()
      .required(descriptionMsg),
    description3: Yup.string()
      .required(descriptionMsg),
    description4: Yup.string()
      .required(descriptionMsg),
  }))
export const EditTemplate17Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string()
      .required(descriptionMsg),
    description2: Yup.string()
      .required(descriptionMsg),
    description3: Yup.string()
      .required(descriptionMsg),
    description4: Yup.string()
      .required(descriptionMsg),
    description5: Yup.string()
      .required(descriptionMsg),
    description6: Yup.string()
      .required(descriptionMsg),
    description7: Yup.string()
      .required(descriptionMsg),
    description8: Yup.string()
      .required(descriptionMsg),
  }))
export const EditTemplate18Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string()
      .required(descriptionMsg),
    description2: Yup.string()
      .required(descriptionMsg),
  }))
export const EditTemplate20Schema = BaseTemplateSchema.concat(
  Yup.object().shape({
    description1: Yup.string()
      .required(descriptionMsg),
    description2: Yup.string()
      .required(descriptionMsg),
    description3: Yup.string()
      .required(descriptionMsg),
    description4: Yup.string()
      .required(descriptionMsg),
    description5: Yup.string()
      .required(descriptionMsg),
    description6: Yup.string()
      .required(descriptionMsg),
    description7: Yup.string()
      .required(descriptionMsg),
    description8: Yup.string()
      .required(descriptionMsg),
    description9: Yup.string()
      .required(descriptionMsg),
    description10: Yup.string()
      .required(descriptionMsg),
    description11: Yup.string()
      .required(descriptionMsg),
    description12: Yup.string()
      .required(descriptionMsg),
  }))


export const EditProductTemplateSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(100, "Name should not be more than 100 characters long")
    .trim(),
  product_info_type_id: Yup.string()
    .required("Please select category")
    .trim(),
  branch_id: Yup.string()
    .required("Please select branch")
    .trim(),
  department_id: Yup.string()
    .required("Please select department")
    .trim(),
  usd: Yup.string()
    .nullable()
    .matches(/^\d+(\.\d{1,2})?$/, 'Please enter a valid price.')
    .required('Price is required.'),
});

export const CreateProductTemplateSchema = EditProductTemplateSchema.concat(
  Yup.object().shape({
    image: Yup.mixed().required(fileMsg),
  }))

export const EditMenuTemplateSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(100, "Name should not be more than 100 characters long")
    .trim(),
  branch_id: Yup.string()
    .required("Please select branch")
    .trim(),
  department_id: Yup.string()
    .required("Please select department")
    .trim(),
  menu_type_id: Yup.string()
    .required("Please select category")
    .trim(),
  usd: Yup.string()
    .nullable()
    .matches(/^\d+(\.\d{1,2})?$/, 'Please enter a valid price.')
    .required('Price is required.'),
});

export const CreateMenuTemplateSchema = EditMenuTemplateSchema.concat(
  Yup.object().shape({
    image: Yup.mixed().required(fileMsg),
  }))


export const JoiningformSchema1 = Yup.object().shape({
  company_name: Yup.string()
    .required("This is a required field")
    .max(100, "Company name should not be more than 100 characters long")
    .trim(),
  owner_name: Yup.string()
    .required("This is a required field")
    .max(100, "Full name should not be more than 100 characters long")
    .trim(),
  family_name: Yup.string()
    .required("This is a required field")
    .max(100, "Family name should not be more than 100 characters long")
    .trim(),

});


export const JoiningformSchema2 = Yup.object().shape({
  photo: Yup.mixed().
    when('photoimageUrl', {
      is: (photo) => !photo,
      then: Yup.string().required('Profile picture is required'),
    })
    .nullable(),
  owner_email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email should not be more than 100 characters long")
    .required("This is a required field"),
  country_code_and_flag_id: Yup.string()
    .required("Country code is required field. ")
    .trim(),
  phone_number: Yup.string()
    .required("Phone number is required")
    .max(10, "Phone number should not be more than 10 characters long"),
  date_of_birth: Yup.string()
    .nullable()
    .required("This is a required field")
    .test('is-over-18-and-under-100', 'Invalid DOB. Age must be between 18 and 99 years', function (value) {
      if (!value) return true;
      const currentDate = new Date();
      const dob = new Date(value);
      let age = currentDate.getFullYear() - dob.getFullYear();
      const monthDiff = currentDate.getMonth() - dob.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < dob.getDate())) {
        age--;
      }
      return age >= 18 && age <= 99;
    }),
})



export const createUserprofileSchema1 = Yup.object().shape({
  national_id: Yup.mixed()
    .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
      if (value) {
        return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
      }
      return true;
    })
    .test('fileSize', 'The maximum file size is 5 MB', (value) => {
      if (value) {
        return value.size <= 5000000;
      }
      return true;
    }),
  passport: Yup.mixed()
    .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
      if (value) {
        return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
      }
      return true;
    })
    .test('fileSize', 'The maximum file size is 5 MB', (value) => {
      if (value) {
        return value.size <= 5000000;
      }
      return true;
    }),
  vaccination_certificate: Yup.mixed()
    .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
      if (value) {
        return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
      }
      return true;
    })
    .test('fileSize', 'The maximum file size is 5 MB', (value) => {
      if (value) {
        return value.size <= 5000000;
      }
      return true;
    }),
});


export const createUserprofileSchema2 = Yup.object().shape({
  school_degree: Yup.mixed()
    .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
      if (value) {
        return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
      }
      return true;
    })
    .test('fileSize', 'The maximum file size is 5 MB', (value) => {
      if (value) {
        return value.size <= 5000000;
      }
      return true;
    }),
  university_degree: Yup.mixed()
    .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
      if (value) {
        return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
      }
      return true;
    })
    .test('fileSize', 'The maximum file size is 5 MB', (value) => {
      if (value) {
        return value.size <= 5000000;
      }
      return true;
    }),
  certificate: Yup.mixed()
    .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
      if (value) {
        return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
      }
      return true;
    })
    .test('fileSize', 'The maximum file size is 5 MB', (value) => {
      if (value) {
        return value.size <= 5000000;
      }
      return true;
    }),

  driving_license: Yup.mixed()
    .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
      if (value) {
        return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
      }
      return true;
    })
    .test('fileSize', 'The maximum file size is 5 MB', (value) => {
      if (value) {
        return value.size <= 5000000;
      }
      return true;
    }),
});

export const profileSchema = Yup.object().shape({
  owner_name: Yup.string()
    .required("Full name is required")
    .trim(),
    gender: Yup.string()
    .required("Gender is required")
    .trim(),
    owner_email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email should not be more than 100 characters long")
    .required("Email is required"),
    phone_number: Yup.string()
    .required("Phone number is required")
    .max(15, "Phone number should not be more than 15 characters long"),
    country_code_and_flag_id: Yup.string()
    .required("Country code is required. ")
    .trim(),
    date_of_birth: Yup.string()
    .nullable()
    .required("Birth date is required")
    .test('is-over-18-and-under-100', 'Invalid DOB. Age must be between 18 and 99 years', function (value) {
      if (!value) return true;
      const currentDate = new Date();
      const dob = new Date(value);
      let age = currentDate.getFullYear() - dob.getFullYear();
      const monthDiff = currentDate.getMonth() - dob.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < dob.getDate())) {
        age--;
      }
      return age >= 18 && age <= 99;
    })
    .trim(),
    national_id: Yup.mixed()
    .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
      if (value) {
        return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
      }
      return true;
    })
    .test('fileSize', 'The maximum file size is 5 MB', (value) => {
      if (value) {
        return value.size <= 5000000;
      }
      return true;
    }),

  driving_license: Yup.mixed()
  .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
    if (value) {
      return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
    }
    return true;
  })
  .test('fileSize', 'The maximum file size is 5 MB', (value) => {
    if (value) {
      return value.size <= 5000000;
    }
    return true;
  }),
  passport: Yup.mixed()
  .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
    if (value) {
      return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
    }
    return true;
  })
  .test('fileSize', 'The maximum file size is 5 MB', (value) => {
    if (value) {
      return value.size <= 5000000;
    }
    return true;
  }),
  school_degree: Yup.mixed()
  .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
    if (value) {
      return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
    }
    return true;
  })
  .test('fileSize', 'The maximum file size is 5 MB', (value) => {
    if (value) {
      return value.size <= 5000000;
    }
    return true;
  }),
  university_degree: Yup.mixed()
  .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
    if (value) {
      return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
    }
    return true;
  })
  .test('fileSize', 'The maximum file size is 5 MB', (value) => {
    if (value) {
      return value.size <= 5000000;
    }
    return true;
  }),
  vaccination_certificate: Yup.mixed()
  .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
    if (value) {
      return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
    }
    return true;
  })
  .test('fileSize', 'The maximum file size is 5 MB', (value) => {
    if (value) {
      return value.size <= 5000000;
    }
    return true;
  }),
  certificate: Yup.mixed()
  .test('fileType', 'Invalid file format. Only JPEG, PNG, or PDF allowed.', (value) => {
    if (value) {
      return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
    }
    return true;
  })
  .test('fileSize', 'The maximum file size is 5 MB', (value) => {
    if (value) {
      return value.size <= 5000000;
    }
    return true;
  }),
});

export const profileSchemaWork = Yup.object().shape({
  joining_date: Yup.string()
    .notRequired()
    .nullable()
    .trim(),
    reporting_to: Yup.mixed()
    .when('role_id', {
      is: (role) => role && role !== 2,
      then: Yup.string().required("Reporting person is required"),
      otherwise: Yup.string().notRequired(),
    }),
});