// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonTemplateController from "./CommonTemplateController.web";
import { handleApiError, handleExpiredToken, checkToken, getDiffs, handleUndefinedData, getUserRoleID } from "../../../components/src/utils/commonFunctions";
import { ClassNameMap } from "@material-ui/styles";
import { CreateTemplate9Schema, EditTemplate9Schema } from "../../../components/src/utils/validationSchema";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"formContainer" | "inputField" | "dateField" | "reverseContainer"
    | "fieldWrapper" | "inputLabel" | "helperText" | "textAreaField" | "textAreaInputField" | "templateBtnWrapper"
    | "primaryBtn" | "fileInput" | "secondaryBtn" | "inputLabel2" | "approvalContainer" | "dropdownWrapper" | "uploadImageContainer" | "titleText" | "gridItem" | "templateFooterContainer"
  >;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class Template9Controller extends CommonTemplateController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      ...this.state,
      ...this.initialState
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidMount(): any {
    // Customizable Area Start
    checkToken();
    this.getBranchData();

    let { state } = this.props.navigation.history.location;
    if (state) {
      this.setDefaultData9(state)
    } else if(![2,3].includes(Number(getUserRoleID()))) {
      this.props.navigation.goBack();
    }
    // Customizable Area End
  }

  setDefaultData9(data: any, action?: string) {
    const { id, attachment, descriptions_attributes, creator } = data
    this.setState({
      creator,
      defaultData: data,
      id,
      formData: data,
      imageUrls: {
        image1: handleUndefinedData(descriptions_attributes[0].images[0], ""),
        image2: handleUndefinedData(descriptions_attributes[1].images[0], ""),
        image3: handleUndefinedData(descriptions_attributes[2].images[0], "")
      },
      attachment: attachment.url ? attachment : "",
      templateAction: action ?? configJSON.view
    })
  }

  template9InitialValues = () => {
    const { defaultData, formData, templateAction } = this.state;
    const isCreateTemplate = templateAction === configJSON.create;
    const data = {
      department_id: isCreateTemplate ? defaultData.department_id : handleUndefinedData(formData?.department_id, ""),
      branch_id: isCreateTemplate ? defaultData.branch_id : handleUndefinedData(formData?.branch_id, ""),
      title: isCreateTemplate ? defaultData.title : handleUndefinedData(formData?.title, ""),
      send_for_approval: isCreateTemplate ? defaultData.send_for_approval : handleUndefinedData(formData?.send_for_approval, false),
      attachment: null,
      description1: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[0]?.description, ""),
      description3: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[2]?.description, ""),
      description2: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[1]?.description, ""),
      image1: null,
      image3: null,
      image2: null,
    }
    return data;
  }
  template9ValidationSchema = () => {
    const { templateAction } = this.state;
    return templateAction === configJSON.edit ? EditTemplate9Schema : CreateTemplate9Schema
  }

  addEditTemplate9 = (values: any) => {
    const { formData, defaultData, template_id } = this.state;

    const { attachment,department_id,title, send_for_approval, branch_id, descriptions_attributes } = defaultData;
    const {description3, description1, description2, image1, image2,image3 } = values;

    const { id } = formData;

    const createDescriptionsAttr = [
      {
        description: description1,
        images: [image1]
      }, {
        description: description2,
        images: [image2]
      },{
        description: description3,
        images: [image3]
      }
    ];

    const editDescriptionsAttr = () => {
      return [
        {
          description: description1,
          ...(image1 ? { images: [image1] } : {}),
          id: this.state.formData.descriptions_attributes[0].id,
        }, {
          description: description2,
          ...(image2 ? { images: [image2] } : {}),
          id: this.state.formData.descriptions_attributes[1].id,
        },
        {
          description: description3,
          ...(image3 ? { images: [image3] } : {}),
          id: this.state.formData.descriptions_attributes[2].id,
        },
       
      ];

    }


    const orriginalData: any = {
      branch_id,
      department_id,
      title,
      descriptions_attributes,
      ...(attachment ? { attachment } : {}),
      send_for_approval,
    };

    const attrs: any = {
      send_for_approval: values.send_for_approval,
      department_id: values.department_id,
      title: values.title,
      branch_id: values.branch_id,
      ...(values.attachment ? { attachment: values.attachment } : this.checkEmptyAttachment()),
      ...(!id ? { template_id } : {}),
      descriptions_attributes: id ? editDescriptionsAttr() : createDescriptionsAttr,
    };

    let templatePayload: any = getDiffs(orriginalData, attrs);
    this.addEditTemplateApiCall(templatePayload)
  }

  addTemplateResponse = (apiResponse: any, message: string) => {
    if (apiResponse.data) {
      if (this.state.id) {
        const dataItem = apiResponse.data.attributes
        this.setStateData(dataItem);
        this.setDefaultData9(dataItem)
      } else {
        this.setState({ ...this.initialState });
        this.navigateToListingPage()
      }
      this.handleActions(configJSON.view)
      toast.success(message);
    } else if (apiResponse.message) {
      const {defaultData} = this.state
      const dataItem = {...defaultData, favorite: !defaultData.favorite};
      this.setStateData(dataItem);
      this.setDefaultData9(dataItem)
      toast.success(message);
    } else {
      handleApiError(apiResponse.errors);
    }
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resData, this.props.navigation);
      this.setState({ loading: false });
      if (requestCallId && resData) {
        this.setState({ loading: false });
        switch (requestCallId) {
          case this.createTemplateApiId:
            this.addTemplateResponse(resData, configJSON.procedureSuccessMessage);
            break;
          case this.duplicateTemplateApiId:
            this.addTemplateResponse(resData, configJSON.procedureDuplicateMessage);
            break;
          case this.deleteTemplateApiId:
            this.deleteTemplateResponse(resData);
            break;
          case this.branchDataApiId:
            this.getBranchesResponse(resData);
            break;
          case this.departmentsApiId:
            this.getDepartmentsResponse(resData);
            break;
          case this.favouriteTemplateApiId:
            const toastMessage = this.state.formData.favorite ? configJSON.procedureUnFavMessage : configJSON.procedureFavMessage;
            this.addTemplateResponse(resData, toastMessage);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
