import { colors } from "../../../components/src/utils/commonDesign";
import { inputField } from "../../../components/src/utils/commonInputStyle";
export const styles: any = {
  ...inputField,
  card: {
    borderRadius: 16,
    margin:"auto",
    overflow:"auto"
  },
  cardContent: {
    display:"flex",
    padding: "45px !important",
  },
  formContainer:{
    width:"25rem"
  },
  headerLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom:"30px"
  },
  title: {
    color: colors.secondaryColor1,
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "42px",
  },
  rememberContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "4px",
    "& .Mui-checked": {
      color: colors.secondaryColor1,
    },
  },
  forgotPass: {
    color: colors.secondaryColor1,
    fontSize: "16px",
    lineHeight: "21px",
    marginLeft: "15px",
    float: "right",
    cursor: "pointer",
    fontWeight: "500",
    textDecoration: "underline",
  },
  errorMsg: {
    paddingBottom: "5px",
    color: colors.errorText,
    marginBottom: "-20px",
    fontSize: "18px",
    marginTop: "10px",
    letterSpacing: "0.03333em",
    fontWeight: 400,
    lineHeight: "21px",
  },
  btnWrapper: {
    padding: "0 36.5px",
  },
  btn: {
    color: colors.primaryColor2,
    marginTop: 25,
    backgroundColor: colors.secondaryColor2,
    boxShadow: "none",
    fontWeight: 700,
    textTransform: "capitalize",
    height: "56px",
    borderRadius: "10px",
    fontSize: "16px",
    lineHeight: "21px",
    "&:hover": {
      backgroundColor: colors.secondaryColor2,
    },
  },

  imageContainer:{
    height:"500px"
  },
  inputPassword:{
    "& .MuiFormHelperText-root": {
      width:"370px"
    },
  }
};
