import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall,  handleExpiredToken} from "../../../components/src/utils/commonFunctions";
import { slider1, slider2, slider3 } from "./assets";
export interface CarouselImages{
  id: number;
  text: string;
  name: string;
  url: string;
}
import { ClassNameMap } from "@material-ui/styles";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: ClassNameMap<"btnGroup" | "headerSection"
>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  index: number;
  imgData: any;
  isAll: boolean,
  isWithEmpty: boolean,
  isWithImage: boolean,
  cimages_list:CarouselImages[],
  slider: any,
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CarouselDisplayController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  swiper: any;
  CarouselListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      index: 0,
      imgData: [
        {
          id: 1,
          img: slider1,
        },
        {
          id: 2,
          img: slider2,
        },
        {
          id: 3,
          img: slider3,
        },
      ],
      isAll: true,
      isWithEmpty: false,
      isWithImage: false,
      cimages_list: [],
      slider:[],
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.handleCarouselResponseData(from, message);
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
  
    this.getCarouselList();
  }
  componentDidUpdate(prevProps: Props, prevState: S): any {
    if (this.state.isWithImage !== prevState.isWithImage
      || this.state.isWithEmpty !== prevState.isWithEmpty
    ) {
      this.getCarouselList()
    }
  }
  handleButtonClick = (buttonNumber: number) => {
    if (buttonNumber === 1) {
      this.setState({
        isAll: true,
        isWithEmpty: false,
        isWithImage: false,
      });
    } else if (buttonNumber === 2) {
      this.setState({
        isAll: false,
        isWithEmpty: false,
        isWithImage: true,
      });
    }
    else {
      this.setState({
        isAll: false,
        isWithEmpty: true,
        isWithImage: false,
      });
    }
  };
  handleNavigate = ( id: number ) => {
    
    this.props.navigation.history.push(`Template${id}`)
}

getCarouselList = () => {
  this.setState({ loading: true });
  const baseEndpoint = configJSON.CarouselImagesApiEndpoint;

  let queryParam = '';

  if (this.state.isWithImage) {
    queryParam += 'has_image=true';
  } else if (this.state.isWithEmpty) {
    queryParam += 'has_image=false';
  }

  const endpoint = queryParam ? `${baseEndpoint}?${queryParam}` : baseEndpoint;

  this.CarouselListApiCallId = apiCall({
    method: configJSON.validationApiMethodType,
    endPoint: endpoint,
    token: true,
  });
}
handleCarouselResponseData = (from: string, message: Message) => {
  this.setState({ loading: false });
  if (this.CarouselListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
        handleExpiredToken(response, this.props.navigation);

        if (response) {
          const filteredImages: CarouselImages[] = response.data
            .map((item: { attributes: { template_id: number; template_image: { url: string; }; name:string; }; }) => {
              return {
                id: item.attributes.template_id,
                url: item.attributes.template_image.url,
                name: item.attributes.name,
                text: item.attributes.name,
              };
            });

  this.setState({
    cimages_list: filteredImages,
  });      
  }
}
}

  // Customizable Area End
}
