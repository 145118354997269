import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall, checkToken, handleExpiredToken } from "../../../components/src/utils/commonFunctions";
export interface CarouselImages {
  id: number;
  text: string;
  name: string;
  url: string;
}
import { ClassNameMap } from "@material-ui/styles";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: ClassNameMap<"container"
  >;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  index: number;
  menu_data: CarouselImages[],
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MenuCarouselController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  swiper: any;
  getMenuCarouselApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      index: 0,
      loading: false,
      menu_data: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({ loading: false });
    runEngine.debugLog("Message Recived", message);
    this.handleCarouselResponseData(from, message);
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    checkToken();
    this.getCarouselList();
  }

  getCarouselList = () => {
    this.setState({ loading: true });
    this.getMenuCarouselApiCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.MenuCarouselTemplateApiEndpoint,
      token: true,
    });
  }

  handleNavigate = (id: number) => {
    this.props.navigation.history.push(`MenuTemplate${id}`)
  }
  
  handleCarouselResponseData = (from: string, message: Message) => {

    if (this.getMenuCarouselApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const resData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      handleExpiredToken(resData, this.props.navigation);

      if (resData) {
        const formatedMenu: CarouselImages[] = resData.data
          .map((menuItem: { attributes: { template_id: number; template_image: { url: string; }; name: string; }; }) => {
            return {
              id: menuItem.attributes.template_id,
              url: menuItem.attributes.template_image.url,
              name: menuItem.attributes.name,
              text: menuItem.attributes.name,
            };
          });

        this.setState({
          menu_data: formatedMenu,
        });
      }
    }
  }

  // Customizable Area End
}
