// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  apiCall,
  checkToken,
  handleApiError,
  handleExpiredToken,
} from "../../../components/src/utils/commonFunctions";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string,string>;

  // Customizable Area End

}

interface S {
  // Customizable Area Start
  loading: boolean;
  allDepartments: DepartmentsRoot[];
  departmentSelected: string[];
  branches: Lists[];
  branchID: string | number;
  searchQuery: string;
  searchAllDepartments: DepartmentsRoot[] | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
export interface DepartmentsRoot {
  id: number
  name: string
  departments: Department[]
}

export interface Department {
  id: string
  name: string
  photo: string
}

interface BranchData {
  id: string | number;
  attributes: {
      branch_name: string;
      location_of_point_of_sale: string;
  }
}
interface ResponseBranchData {
  branches: {
      data: BranchData[]
  }
}

interface ResponseErrorData {
  errors: string[]
}
interface Lists {
  id?: number,
  value: string | number;
  label: string;
  isDisabled?:boolean
}

// Customizable Area End
export default class AddDepartmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDepartmentApiId: string = "";
  selectedDepaApiId: string = "";
  addDepartmentApiId: string = "";
  getBranchesApiId: string = "";
  searchDepartmentApiId: string = "";
// Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
  // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      loading: false,
      allDepartments: [],
      departmentSelected: [],
      branches: [],
      branchID: "",
      searchQuery: "",
      searchAllDepartments: null,
    };
// Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

   // Customizable Area Start
  async componentDidMount() {
    checkToken(this.props.navigation);
    this.setState({ loading: true });
    this.getDepartmentApiId = apiCall({ method: configJSON.getMethod, endPoint: configJSON.departmentApiEndPoint, token: true });
    this.getBranchesApiId = apiCall({ method: configJSON.getMethod, endPoint: configJSON.createBranchApiEndpoint, token: true });
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.branchID !== prevState.branchID) {
      this.setState({ loading: true });
      this.selectedDepaApiId = apiCall({ method: configJSON.getMethod, endPoint: `${configJSON.selectedDepartmentApiEndPoint}/${this.state.branchID}`, token: true })
    }
    if (this.state.searchQuery !== prevState.searchQuery) {
      this.setState({ loading: true });
      this.searchDepartmentApiId = apiCall({ method: configJSON.getMethod, endPoint: `bx_block_departments/departments/search?query=${this.state.searchQuery}`, token: true })
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(responseJson, this.props.navigation);
      this.setState({ loading: false });

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getDepartmentApiId) {
          this.getDepartmentRes(responseJson);
        } else if (apiRequestCallId === this.selectedDepaApiId) {
          this.getSelectedDepartmentRes(responseJson);
        } else if (apiRequestCallId === this.addDepartmentApiId) {
          this.addDeparmentsResponse(responseJson);
        } else if (apiRequestCallId === this.getBranchesApiId) {
          this.getBranchesRes(responseJson);
        } else if (apiRequestCallId === this.searchDepartmentApiId) {
          this.getSearchDeptResponse(responseJson);
        }
      }
    }
  }

  getDepartmentRes = (response:DepartmentsRoot[]) => {
    if (response.length > 0) {
      this.setState({ allDepartments: response });
    }
  };
  getSelectedDepartmentRes = (response: DepartmentsRoot[]) => {
    if (response.length > 0) {
      let arrayData: Department[] = []
      response.forEach((item) => arrayData.push(...(item.departments)));
      this.setState({ departmentSelected: arrayData.map((item) => (item.id)) });
    } else {
      this.setState({ departmentSelected: [] });
    }
  };
  addDeparmentsResponse = (response:{data:object,errors:string[]}) => {
    if (response.data) {
      this.props.navigation.history.push(`ExistingDepartment?id=${this.state.branchID}`);
      toast.success(configJSON.departSuccessSelectMessage);
    } else {
      handleApiError(response.errors);
    }
  };

  getBranchesRes = (response: ResponseBranchData & ResponseErrorData) => {
    const { data } = response.branches;
    const defaultItem = { value: "", label: "Select Branch", isDisabled: true }
    if (data.length > 0) {
      const urlParams = new URLSearchParams(window.location.search);
      let branchID = urlParams.get("id") ?? this.state.branchID;
      let finalBranchData: Lists[] = data.map((item) => {
        const { id, attributes } = item;
        const { branch_name, location_of_point_of_sale } = attributes;
        return { label: `${branch_name} - ${location_of_point_of_sale}`, value: Number(id), data: attributes };
      })
      finalBranchData.unshift(defaultItem);
      this.setState({ branches: finalBranchData, branchID: branchID ? Number(branchID) : Number(data[data.length - 1].id) });
    } else {
      toast.error(configJSON.branchErrorMessage);
    }
  };

  getSearchDeptResponse = (response: DepartmentsRoot[]) => {
    this.setState({ searchAllDepartments: response.length > 0 ? response : null });
  }

  handleSelectChange = (event: OptionType) => {
    this.setState({ branchID: event?.value });
  }

  backClick = () => {
    this.props.navigation.goBack();
  }

  handleSelectDepartment = (item: { id: string; }) => {
    const { departmentSelected } = this.state;

    if (!departmentSelected.includes(item.id)) {
      this.setState({
        departmentSelected: [...departmentSelected, item.id],
      });
    } else {
      const updatedSelectedDepartments = departmentSelected.filter(
        (selectedItem) => selectedItem !== item.id
      );
      this.setState({
        departmentSelected: updatedSelectedDepartments,
      });
    }
  }

  handleSkip = () => {
    this.props.navigation.navigate("ExistingBranch");
  };

  chooseDepartment = () => {
    const { departmentSelected } = this.state;
    if (departmentSelected.length > 0) {
      if (this.state.branchID) {
        this.setState({ loading: true });
        const payloadData = {
          attributes: {
            branch_id: this.state.branchID,
            department_ids: departmentSelected
          }
        };
        const httpBody = {
          data: payloadData,
        };
        this.addDepartmentApiId = apiCall({
          contentType: configJSON.validationApiContentType,
          method: configJSON.exampleAPiMethod,
          body: JSON.stringify(httpBody),
          endPoint: configJSON.chooseDepartmentApiEndPoint,
          token: true,
        });
      } else {
        toast.error(configJSON.branchErrorMessage);
      }
    } else {
      toast.error(configJSON.errorDepartMessage);
    }
  };

  handleSearch = (query: string) => {
    this.setState({ searchQuery: query })
  }
  // Customizable Area End
}

