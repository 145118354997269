import { colors } from "../../../components/src/utils/commonDesign";

export const styles: any = {
  attaachments: {
    display: 'flex', 
    justifyContent: 'flex-end',
    marginBottom: "-23px",
    "& a" : {
      cursor: "pointer",
      color: "#4196F0",
      borderBottom: "1px solid #4196F0",
      textDecoration: "none",
      fontSize: "14px !important",
    }
  },
  UserProfilecard: {
    borderRadius: 16,
    margin: "auto",
    overflow: "auto"
  },
  UserProfilecardContent: {
    padding: "35px 45px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  UserProfiletimeContainer: {
    marginTop: 10,
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: 80,
    width: "95%"
  },
  companyProfileInputSection: {
    display: "grid",
    gridTemplateColumns: '1fr',
    justifyItems: "center"
  },
  UserProfilecompanyProfileLeftSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  UserProfiletitle: {
    color: colors.secondaryColor1,
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "42px",
    marginTop: 20,
  },
  UserProfilecontainer: {
    flexWrap: "nowrap",
    gap: "67px",
  },
  UserProfileuploadImage: {
    display:"flex",
    flexDirection: "column",
    position: "relative",
    bottom: "0",
    right: "0",
    height: 55,
    width: "100%",
    border: "1px solid #969492",
    borderRadius: 8,
    cursor: "pointer",
    "& .innerContent": {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "100%",
      transform: "translate(-50%, -50%)",
      "& .filePreview": {
        width: "auto",
        height: "auto",
        maxHeight: 130,
        maxWidth: 130,
        objectFit: "contain",
        aspectRatio: "3/2"
      },
      "& .uploadImage": {
        flexDirection: "row",
        width: 26,
        height: 25,
        paddingTop: 4,
        marginBottom: 9,
      },
      "& .uploadText": {
        flexDirection: "row",
        color: colors.primaryColor2,
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "18px",
        paddingTop:6,
        left:'20px',
   
      
      }


      
    },
    "& .MuiFormControl-root": {
      height: "100%",
      width: "100%",
      zIndex: "100",
      "& .MuiInputBase-formControl": {
        height: "100%",
        width: "100%",
        borderRadius: "50%",
        border: "none",
        "& input": {
          padding: "0",
          position: "relative",
          zIndex: "99",
          opacity: "0",
          height: "100%",
          width: "100%",
          cursor: "pointer",
        },
        "& fieldset": {
          display: "none",
        },
      },
    },
  },
  UserProfileuploadImage1: {
    width: "100% !important",
    border: "1px dashed #969492 !important",
  },
  UserProfileuploadDocuments: {
    height: "140px !important",
    marginTop: "30px !important",
    "& .uploadImage": {
      width: "56px !important",
      height: "55px !important",
    },
  },
  UserProfileform: {
    margin: "35px 72px 0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  UserProfilefieldWrapper: {
    margin: "0px",
    width: "438px",
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      height: "56px",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "21px",
        "& .Mui-disabled": {
          backgroundColor: "#F5F5F5",
          color: "#020202",
          borderRadius: "10px",
        },
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.secondaryColor1,
      },
    },
  },
  UserProfileinputLabel: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 400,
    color: colors.primaryColor2,
  },
  UserProfileinputField: {
    margin: 0,
    marginTop: "10px",
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      height: "50px",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "21px",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.secondaryColor1,
      },
    },
  },
  UserProfiledateField: {
    "& .MuiInputBase-root": {
      border: "1px solid #0000003b",
      padding : "0 14px",
    },
    "& .Mui-focused": {
        borderColor: colors.secondaryColor1,
    },
  },
  UserProfiletextAreaField: {
    height: "145px !important",
    "& .MuiInputBase-root": {
      height: "100% !important",
      "& .MuiInputBase-input": {
        height: "100% !important",
        overflow: "auto !important",
      },
    },
  },
  UserProfiledocList: {
    justifyContent: "space-between",
    padding: "0px 10px",
    height: "48px",
    border: "1px solid #969492",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    marginTop:"40px",
    "& .docName": {
      fontSize: "18px",
      lineHeight: "24px",
      textDecoration: "none",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginRight: 20,
    },
    "& .deleteIcon": {
      height: 24,
      width: 21,
      cursor: "pointer",
      marginRight: 20
    }
  },
  UserProfilehelperText: {
    color: colors.errorText,
    margin: 0,
    marginTop: "5px",
  },
  UserProfileerrorMsg: {
    color: colors.errorText,
    paddingBottom: "5px",
    marginBottom: "-20px",
    marginTop: "10px",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.03333em",
  },
  UserProfileprogressStepper: {
    padding: "0px",
    marginTop: 15,
    background: "transparent",
    color: colors.primaryColor2,
    "& .MuiMobileStepper-progress": {
      height: 8,
      width: 457,
      backgroundColor: "#bec3c1",
      borderRadius: 4,
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: colors.secondaryColor1,
    }
  },
  UserProfiletextStepper: {
    padding: "0px",
    background: "transparent",
    width: 436,
    color: colors.primaryColor2,
  },
  UserProfilebtnWrapper : {
    marginTop: "50px",
    gap: "20px",
    display: "flex",
    justifyContent: "center",
  },
  UserProfilebtn: {
    boxShadow: "none",
    fontWeight: 700,
    textTransform: "capitalize",
    height: "50px",
    width: "150px",
    borderRadius: "10px",
    fontSize: "16px",
    lineHeight: "21px",
    color: colors.primaryColor2,
    margin: '0px',
  },
  UserProfileprimaryBtn: {
    backgroundColor: colors.secondaryColor2,
    "&:hover": {
      backgroundColor: colors.secondaryColor2,
      
    }
  },
  UserProfilesecondaryBtn: {
    border: "1px solid black",
    "&:hover": {
      backgroundColor: colors.primaryColor1,
    }
  },
  UserProfileuploadBox: {
    paddingTop:10,
    dUserProfileisplay: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  UserProfilerightSection: {
    paddingTop: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  UserProfilerightSection2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    position: "relative",
  },
  UserProfileuploadWrapper: {
    width: "100%",
    marginTop:"",
    "& > div:nth-of-type(2)":{
      marginTop:"43px"
    }
  },

  UserProfileuploadValidation: {
    color: "#969492",
    fontSize: "12px",
  },
  UserProfilecloseIcn: {
    zIndex: 1000,
    position: "absolute",
    top:"30%",
    
    right: "10px",
    padding: "2px 4px",
    "&:hover": {
      backgroundColor: "#beb8bc",
      borderRadius: 8,
    }
  },
  UserProfileCompanyPhoneGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height:"50px",
    marginTop:6,
  },
  UserProfileinputWidth: {
    width: "64% !important",
    marginTop:11,
    position: "relative",
    "& p": {
      position: "absolute",
      bottom: "-20px"
    }
  },
  UserProfiledropdownWidth: {
    width: "145px"
  }
};
