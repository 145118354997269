import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgChecklist, imgDropdown, imgLinearScale, imgMultipleChoice, imgParagraph, imgSingleAnswer } from "./assets";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { apiCall, checkToken, commonDownloadPdfFunc, convertAllFormData, getUserRoleID, handleApiError, handleExpiredToken, handleUndefinedData, isBO, loggedUserId, ownerName, sortArrayAlphabetically, userBranchId, usersDepartments } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
import React, { RefObject } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    // Customizable Area Start
    classes: Record<string,string>
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    branch_list: Lists[];
    department_list: Lists[];
    loading: boolean;
    formData: FormValues;
    action: string;
    isOpen: { [key: string]: boolean };
    openViewMenu: boolean;
    favorite: boolean;
    openDeleteModal: boolean;
    stateValue: ChecklistFillValues;
    defaultStateValue: ChecklistFillValues;
    defaultFormData: FormValues;
    fromLibrary: string;
    fromUserLogs: string;
    is_download:boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start

    // Customizable Area End
}

// Customizable Area Start
interface BranchData {
    id: string;
    attributes: {
        branch_name: string;
        location_of_point_of_sale: string;
    }
}

interface ResponseBranchData {
    branches: {
        data: BranchData[]
    }
    id: string;
}

interface ResponseErrorData {
    errors: string[]
}

interface Lists {
    id?: number,
    value: string | number;
    label: string;
    isDisabled?: boolean;
}

interface FormValues {
    branch_id: string;
    department_id: string;
    paragraphs_attributes: DataPara[];
    single_answers_attributes: DataPara[];
    title: string;
    checkboxes_attributes: DataOptions[];
    multiple_choices_attributes: DataOptions[];
    dropdowns_attributes: DataOptions[];
    linear_scales_attributes: DataOptionLinear[];
    id: string;
    filled_form_by: string;
    creator?:Creator,
    [keytem: string]: string | null | boolean | DataPara[] | DataOptionLinear[] | Creator | undefined;
}
interface ClearValue {
  [key: string]: string
}

interface Creator {
    id: string | number;
    email: string;
    name: string;
    designation: string;
}
interface ChecklistFillValues {
    id?: number;
    filled_by: string;
    creator: { name: string, id?: number | string},
    single_answer_answers_attributes: DataPara[];
    paragraph_answers_attributes: DataPara[];
    checkbox_answers_attributes: DataOptions[];
    dropdown_answers_attributes: DataOptions[];
    multiple_choice_answers_attributes: DataOptions[];
    linear_scale_answers_attributes: DataOptionLinear[];
    [keytem: string]: any
}

interface DataPara {
    text: string;
    id?:string | number;
    value?:string | null;
}

interface DataOptions {
    text: string;
    options_attributes: {
        option: string,
        id?: any
    }[];
    selected_option?:string | null;
    selected_options?:{id: string | number}[];
    id?:string | number
}

interface ResponseDepartment {
    id: string;
    departments: {
        id: string;
        name: string;
    }[]
}

export interface Paragraph {
    value: string;
    onChange: (arg: React.ChangeEvent<{ value: string }>) => void;
    index: number
}
export interface OptionField {
    value: string;
    onChangeText: (arg: React.ChangeEvent<{ value: string }>) => void;
    option: string[];
    index: number;
    isKey: keyof FormValues;
}

interface DataOptionLinear {
    text: string;
    highest_value: number;
    lowest_value: number;
    highest: string;
    lowest: string;
    id?:string | number
    value?:number | null
}

interface ChecklistResponse {
    data: {
        id: string;
        attributes: {
            favorite: boolean;
            creator:{
                id: string;
                email: string;
                name: string;
                designation: string;
            }
        } & FormValues
    }
}

// Customizable Area End

export default class ChecklistFormController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getBranchesApicallId: string = ""
    getDepaApicallId: string = ""
    createChecklistApiId: string = ""
    checklistFillApiCallId: string = ""
    getChecklistApiId: string = ""
    favouriteChecklistApiId: string = ""
    deleteChecklistApiId: string = ""
    pdfContentRef: RefObject<HTMLDivElement>
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.pdfContentRef = React.createRef();
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        const initialData = {
            branch_id: !isBO() && userBranchId,
            department_id: "",
            paragraphs_attributes: [],
            single_answers_attributes: [],
            title: "",
            checkboxes_attributes: [],
            multiple_choices_attributes: [],
            dropdowns_attributes: [],
            linear_scales_attributes: [],
            id: "",
            filled_form_by:""
        }
        const checklistFillInitialData = {
            filled_by: "",
            creator: {
              name: "",
            },
            paragraph_answers_attributes: [],
            single_answer_answers_attributes:[],
            checkbox_answers_attributes: [],
            multiple_choice_answers_attributes: [],
            dropdown_answers_attributes: [],
            linear_scale_answers_attributes: []
        }
        this.state = {
            branch_list: [],
            loading: false,
            formData: initialData,
            defaultFormData: initialData,
            department_list: [],
            action: configJSON.create,
            isOpen: {},
            openViewMenu: false,
            favorite: false,
            openDeleteModal: false,
            stateValue:checklistFillInitialData,
            defaultStateValue: checklistFillInitialData,
            fromLibrary: "",
            fromUserLogs: "",
            is_download:false
        };
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let resJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            handleExpiredToken(resJson, this.props.navigation);
            this.setState({ loading: false });

            if (apiCallId && resJson) {
                if (apiCallId === this.getBranchesApicallId) {
                    this.handleGetBranchesResponse(resJson);
                } else if (apiCallId === this.getDepaApicallId) {
                    this.handleGetDepartmentResponse(resJson);
                } else if (apiCallId === this.createChecklistApiId) {
                    this.handleCreateChecklistResponse(resJson);
                } else if (apiCallId === this.getChecklistApiId) {
                    this.handleGetChecklistResponse(resJson);
                } else if (apiCallId === this.favouriteChecklistApiId) {
                    this.handleFavouriteResponse(resJson);
                } else if (apiCallId === this.deleteChecklistApiId) {
                    this.handleDeleteApiResponse(resJson);
                }
            }
        }
        this.handleResponseForChecklistFill(from,message)
        // Customizable Area End
    }

    // Customizable Area Start

    addChecklistPanel = [
        {
            imgSrc: imgParagraph,
            label: configJSON.paragraph
        },
        {
            imgSrc: imgSingleAnswer,
            label: configJSON.singleAnswer
        },
        {
            imgSrc: imgChecklist,
            label: configJSON.checkbox
        },
        {
            imgSrc: imgMultipleChoice,
            label: configJSON.multipleChoice
        },
        {
            imgSrc: imgDropdown,
            label: configJSON.dropdown
        },
        {
            imgSrc: imgLinearScale,
            label: configJSON.linearScale
        }
    ];

    async componentDidMount() {
        super.componentDidMount();
        checkToken();
        this.getBranch()
        !isBO() && this.getDepartment()
        const urlParams = new URLSearchParams(window.location.search);
        const dataId = urlParams.get('id') as string

        let { state } = this.props.navigation.history.location;
        if (state?.from && state?.from === configJSON.fromLibrary) {
          this.setState({fromLibrary: state?.from, action : state?.action ?? configJSON.view});
        } else if (state?.from && state?.from === configJSON.fromUserLogs) {
          this.setState({fromUserLogs: configJSON.fromUserLogs, action : state?.action ?? configJSON.view});
          this.loadFilledResponse(state.data)
        }else if(!!dataId) {
            this.setState({action: configJSON.fill, fromLibrary: "from library"});
          } else if(![2,3].includes(Number(getUserRoleID()))) {
            this.props.navigation.goBack();
          }

        this.setState({ loading: true })
        if (dataId) {
          this.getCheckListData(dataId)
        }
        
    }

    componentDidUpdate(prevProps: Props, previousState: S) {
        if (this.state.formData.branch_id !== previousState.formData.branch_id && this.state.formData.branch_id) {
            this.setState({ loading: true, department_list: [] });
            this.getDepartment()
        }
    }

    getCheckListData = (dataId?: string) => {
      const {id: checklist_id} = this.state.formData;
      this.setState({ loading: true })
      this.getChecklistApiId = apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `${configJSON.createChecklistApiEndpoint}/${dataId ?? checklist_id}`,
        token: true,
    });
    }

    loadFilledResponse = (item: ChecklistFillValues) => { 
      const { id: dataId,creator, paragraph_answers_attributes, checkbox_answers_attributes, dropdown_answers_attributes, multiple_choice_answers_attributes, single_answer_answers_attributes, linear_scale_answers_attributes} = item
      
      const finalItem = {
        ...this.state.stateValue,
        id: dataId,
        creator,
        paragraph_answers_attributes,
        checkbox_answers_attributes,
        dropdown_answers_attributes,
        linear_scale_answers_attributes,
        multiple_choice_answers_attributes,
        single_answer_answers_attributes
    }

      this.setState({
        defaultStateValue: finalItem
    })
    }

    addIdInAnswerField = (object: { id: number; }) => {
      const dataId = object?.id;
      return dataId ? { id: dataId } : {};
    }

    handleUpdate = (item?: any) => {
      const {creator, id: dataId, paragraph_answers_attributes, checkbox_answers_attributes, dropdown_answers_attributes, multiple_choice_answers_attributes, single_answer_answers_attributes, linear_scale_answers_attributes} = item

        const paragraphAnswers = this.state.formData.paragraphs_attributes.map((item, index) => ({ text: item.text, paragraph_id: item.id, value: handleUndefinedData(paragraph_answers_attributes[index]?.value, null), ...(this.addIdInAnswerField(paragraph_answers_attributes[index])) }));
        const singleAnswers = this.state.formData.single_answers_attributes.map((item, index) => ({ text: item.text, single_answer_id: item.id, value: handleUndefinedData(single_answer_answers_attributes[index]?.value, null), ...(this.addIdInAnswerField(single_answer_answers_attributes[index])) }));
        const checkboxAnswers = this.state.formData.checkboxes_attributes.map((item, index) => ({
          text: item.text, checkbox_id: item.id, options_attributes: item.options_attributes,
          selected_options: handleUndefinedData(checkbox_answers_attributes[index]?.options_attributes.map(({id: dataId}:{id: number}) => dataId), []), ...(this.addIdInAnswerField(checkbox_answers_attributes[index]))
      }));
        const multipleChoiceAnswers = this.state.formData.multiple_choices_attributes.map((item, index) => ({
          text: item.text, multiple_choice_id: item.id, options_attributes: item.options_attributes,
          selected_option: handleUndefinedData(multiple_choice_answers_attributes[index]?.options_attributes[0]?.id, null), ...(this.addIdInAnswerField(multiple_choice_answers_attributes[index]))
      }));
        const dropdownAnswers = this.state.formData.dropdowns_attributes.map((item, index) => ({
          text: item.text, dropdown_id: item.id, options_attributes: item.options_attributes,
          selected_option: handleUndefinedData(dropdown_answers_attributes[index]?.options_attributes[0]?.id, null), ...(this.addIdInAnswerField(dropdown_answers_attributes[index]))
      }));
        const linearAnswers = this.state.formData.linear_scales_attributes.map((item, index) => ({ text: item.text, linear_scale_id: item.id, highest: item.highest, lowest: item.lowest, highest_value: item.highest_value, lowest_value: item.lowest_value, value: handleUndefinedData(linear_scale_answers_attributes[index]?.value, null), ...(this.addIdInAnswerField(linear_scale_answers_attributes[index])) }));
        const filledBy = this.state.formData.filled_form_by
        this.setState({
            stateValue: {
                ...this.state.stateValue,
                id: dataId,
                creator,
                paragraph_answers_attributes: paragraphAnswers,
                single_answer_answers_attributes: singleAnswers,
                checkbox_answers_attributes: checkboxAnswers,
                multiple_choice_answers_attributes: multipleChoiceAnswers,
                dropdown_answers_attributes: dropdownAnswers,
                linear_scale_answers_attributes: linearAnswers,
                filled_by:filledBy
            }
        })
    }
    handleCancel = () => {
      this.props.navigation.history.push(`Library`, 3)
  }
    getBranch = () => {
        this.getBranchesApicallId = apiCall({ method: "GET", endPoint: configJSON.getBranchApiEndpoint, token: true });
    }

    getDepartment = () => {
        this.getDepaApicallId = apiCall({ method: "GET", endPoint: `${configJSON.getDeptApiEndpoint}${this.state.formData.branch_id}`, token: true })
    }

    formateChecklistResponse = (ChecklistRes: ChecklistResponse) => { 
      const { data: { id: dataId, attributes: { branch_id, department_id, title,creator, paragraphs_attributes, single_answers_attributes, checkboxes_attributes, multiple_choices_attributes, linear_scales_attributes, dropdowns_attributes, favorite } } } = ChecklistRes
      const userName = this.state.fromLibrary ? ownerName : this.state.fromUserLogs ? ChecklistRes.data.attributes.creator.name : ""
      const dataItem = { branch_id: branch_id.toString(),filled_form_by :userName,creator, department_id, title, paragraphs_attributes, single_answers_attributes, multiple_choices_attributes, checkboxes_attributes, dropdowns_attributes, linear_scales_attributes, id: dataId };
      this.setState({ formData: dataItem, defaultFormData: dataItem, favorite })
      this.handleUpdate(this.state.defaultStateValue);
    }

    handleGetChecklistResponse = (resDataItem: ChecklistResponse & ResponseErrorData) => {        
      if (resDataItem.data) {
        this.formateChecklistResponse(resDataItem);
      } else {
        this.handleCancelClick();
        handleApiError(resDataItem.errors);
      }
    }

    handleCreateChecklistResponse = (resDataItem: ChecklistResponse & ResponseErrorData) => {
        if (resDataItem.errors) {
          handleApiError(resDataItem.errors)
        } else {
            this.formateChecklistResponse(resDataItem);
            toast.success(configJSON.createChecklistSuccessMsg);
            if( this.state.action === configJSON.edit) {
              this.setState({action: configJSON.fill});
            }else {
              this.handleCancel();
          }
        }
    }

    handleFavouriteResponse = (resDataItem: { message: string[] }) => {
        this.getCheckListData()
        toast.success(resDataItem.message[0]);
    }

    handleGetDepartmentResponse = (resData: ResponseDepartment[] & ResponseErrorData) => {
      const defaultItem = { value: "", label: configJSON.departSelect, isDisabled: true }
      if (resData.length > 0) {
        let finalDepartValue: Lists[] = [];
        if(isBO()){
            resData.forEach((item) => {
                item.departments.forEach((depItem) => {
                    const { id: dataId, name } = depItem;
                    finalDepartValue.push({ label: name, value: dataId });
                })
            })
          }else {
            usersDepartments.forEach((depItem:{attributes:{name:string;id:string}}) => {
                finalDepartValue.push({ label: depItem.attributes.name, value: depItem.attributes.id });
            })
          }
      
          finalDepartValue = sortArrayAlphabetically(finalDepartValue, 'label');
          finalDepartValue.unshift(defaultItem);
          this.setState({ department_list: finalDepartValue });
      } else {
          this.setState({ department_list: [defaultItem] });
          toast.error(configJSON.templateNoDepartmentMessage);
      }
  };

    handleDeleteApiResponse = (resDataItem: ResponseErrorData) => {
        if (resDataItem.errors) {
            handleApiError(resDataItem.errors)
        } else {
          if(this.state.fromUserLogs) {
            toast.success(configJSON.deleteLogsChecklist)
            this.props.navigation.history.push(`/UserLogsListing/my_logs/submitted_checklist`)
          }else {
              toast.success(configJSON.deleteLibraryChecklist)
              this.handleCancel();
            }
        }
    }

    handleBackClick = () => {
        this.props.navigation.goBack();
    }

    handleSelectChange = (eventData: OptionType, keytem: keyof FormValues, clearValue?: ClearValue) => {
        let finalData:  string | boolean | DataPara[] | DataOptionLinear[] | Creator | null | undefined  = eventData?.value;
        if (clearValue && (this.state.formData[keytem] !== finalData)) {
            this.setState({ formData: { ...this.state.formData, [keytem]: finalData, ...clearValue } });
        } else {
            this.setState({ formData: { ...this.state.formData, [keytem]: finalData } });
        }
    };

    handleGetBranchesResponse = (responseData: ResponseBranchData & ResponseErrorData) => {
      const { data } = responseData.branches;
      const defaultItem = { value: 0, label: configJSON.branchPlaceholder, isDisabled: true, data: { location_of_point_of_sale: "", branch_name: "" } }
      if (data.length > 0) {
          let finalBranchesData = data.map((item) => {
              const { id: dataId, attributes } = item;
              const { branch_name, location_of_point_of_sale } = attributes;
              return { label: `${branch_name} - ${location_of_point_of_sale}`, value: Number(dataId), data: attributes };
          })
          finalBranchesData = sortArrayAlphabetically(finalBranchesData, 'label');
          finalBranchesData.unshift(defaultItem);
          let filteredBranch = data.filter((branch: { id: string; }) => 
          branch.id == userBranchId).map(item => ({ value: +item.id, label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }));
          if (isBO()) {
              this.setState({
                  branch_list: finalBranchesData
              })
          } else {
              this.setState({
                  branch_list: filteredBranch
              })
          }
      } else {
          this.setState({ branch_list: [] });
          toast.error(configJSON.branchErrorMessage);
      }
  };

    handleAddPanelClick = (label: string) => {
        switch (label) {
            case configJSON.paragraph:
                this.addPara("paragraphs_attributes")
                break
            case configJSON.singleAnswer:
                this.addPara("single_answers_attributes")
                break
            case configJSON.checkbox:
                this.addOptionText("checkboxes_attributes")
                break
            case configJSON.multipleChoice:
                this.addOptionText("multiple_choices_attributes")
                break
            case configJSON.dropdown:
                this.addOptionText("dropdowns_attributes")
                break
            case configJSON.linearScale:
                this.addOptionTextLinear("linear_scales_attributes")
                break
            default:
                break
        }
    }

    addPara = (keytem: keyof FormValues) => {
        const dataItem: DataPara = { text: "" }
        const newAttribute = [...this.state.formData[keytem] as DataPara[]]
        newAttribute.push(dataItem)
        this.setState({ formData: { ...this.state.formData, [keytem]: newAttribute } })
    }

    addOptionText = (keytem: keyof FormValues) => {
        const dataItem: DataOptions = { text: "", options_attributes: [{ option: "" }] }
        const newAttribute = [...this.state.formData[keytem] as DataOptions[]]
        newAttribute.push(dataItem)
        this.setState({ formData: { ...this.state.formData, [keytem]: newAttribute } })
    }

    addOptionTextLinear = (keytem: keyof FormValues) => {
        const dataItem: DataOptionLinear = { text: "", highest_value: 2, lowest_value: 0, lowest: "", highest: "" }
        const newAttribute = [...this.state.formData[keytem] as DataOptionLinear[]]
        newAttribute.push(dataItem)
        this.setState({ formData: { ...this.state.formData, [keytem]: newAttribute } })
    }

    addOption = (index: number, keytem: keyof FormValues) => {
        const newAttribute = [...this.state.formData[keytem] as DataOptions[]]
        const dataItem = newAttribute[index]
        const newOption = { option: "" }
        const options_attributes = [...dataItem.options_attributes, newOption]
        newAttribute[index] = { ...dataItem, options_attributes }
        this.setState({ formData: { ...this.state.formData, [keytem]: newAttribute } })
    }

    deleteOption = (index: number, optionIndex: number, keytem: keyof FormValues) => {
        const newAttribute = [...this.state.formData[keytem] as DataOptions[]]
        const dataItem = newAttribute[index]
        const options_attributes = [...dataItem.options_attributes]
        options_attributes.splice(optionIndex, 1)
        newAttribute[index] = { ...dataItem, options_attributes }
        this.setState({ formData: { ...this.state.formData, [keytem]: newAttribute } })
    }

    handleParaChange = (event: React.ChangeEvent<{ value: string }>, index: number, keytem: keyof FormValues) => {
        event.stopPropagation()
        const dataItem = { text: event.target.value }
        const newAttribute = [...this.state.formData[keytem] as DataPara[]]
        const prevData = newAttribute[index]
        newAttribute[index] = {...prevData, ...dataItem}
        this.setState({ formData: { ...this.state.formData, [keytem]: newAttribute } })
    }

    handleOptionFieldTitleTextChange = (event: React.ChangeEvent<{ value: string }>, index: number, keytem: keyof FormValues) => {
        event.stopPropagation()
        const newAttribute = [...this.state.formData[keytem] as DataPara[]]
        const dataItem = newAttribute[index]
        newAttribute[index] = { ...dataItem, text: event.target.value }
        this.setState({ formData: { ...this.state.formData, [keytem]: newAttribute } })
    }

    handleOptionFieldOptionTextChange = (event: React.ChangeEvent<{ value: string }>, index: number, optionIndex: number, keytem: keyof FormValues) => {
        event.stopPropagation()
        const newAttribute = [...this.state.formData[keytem] as DataPara[]] as DataOptions[]
        const dataItem = newAttribute[index]
        const options_attributes = [...dataItem.options_attributes]
        options_attributes[optionIndex] = { ...options_attributes[optionIndex], option: event.target.value }
        newAttribute[index] = { ...dataItem, options_attributes }
        this.setState({ formData: { ...this.state.formData, [keytem]: newAttribute } })
    }

    handleLinearFieldChange = (event: React.ChangeEvent<{ value: string }>, index: number, keytem: keyof FormValues, optionalField: keyof { highest: string; lowest: string }) => {
        event.stopPropagation()
        const newAttribute = [...this.state.formData[keytem] as DataPara[]] as DataOptionLinear[]
        const dataItem = newAttribute[index]
        dataItem[optionalField] = event.target.value
        this.setState({ formData: { ...this.state.formData, [keytem]: newAttribute } })
    }

    handleScaleChange = (eventData: OptionType, index: number, keytem: keyof { highest_value: number; lowest_value: number }) => {
        let finalData: OptionType | string = eventData;
        finalData = eventData?.value
        const newAttribute = [...this.state.formData.linear_scales_attributes] as DataOptionLinear[]
        const dataItem = newAttribute[index]
        dataItem[keytem] = parseInt(finalData as string)
        this.setState({ formData: { ...this.state.formData, linear_scales_attributes: newAttribute } });
    };

    toggleDropdown = (keytem: keyof FormValues, itemId: number) => {
        this.setState((prevState) => {
            const updatedIsOpen = { ...prevState.isOpen };

            for (const keytem in updatedIsOpen) {
                updatedIsOpen[keytem] = false;
            }

            updatedIsOpen[`${keytem}-${itemId}`] = !prevState.isOpen[`${keytem}-${itemId}`];

            return {
                isOpen: updatedIsOpen,
            };
        })

    };

    closeDropDown = () => {
        this.setState((prevState) => {
            const updatedIsOpen = { ...prevState.isOpen };

            for (const keytem in updatedIsOpen) {
                updatedIsOpen[keytem] = false;
            }

            return {
                isOpen: updatedIsOpen,
            };
        });
    }

    removeIdField = (data : any) => {
      const { id, options_attributes, ...rest } = data;
      return {
        ...rest,
        ...(options_attributes && { options_attributes: options_attributes.map(this.removeIdField) }),
      };
    };

    handleFieldDuplicate = (index: number, keytem: keyof FormValues) => {
        const newAttribute = [...this.state.formData[keytem] as DataPara[]]
        newAttribute.splice(index + 1, 0, this.removeIdField(newAttribute[index]))
        this.setState({ formData: { ...this.state.formData, [keytem]: newAttribute } })
        this.closeDropDown()
    }

    handleFieldDelete = (index: number, keytem: keyof FormValues) => {
        const newAttribute = [...this.state.formData[keytem] as DataPara[]]
        newAttribute.splice(index, 1)
        this.setState({ formData: { ...this.state.formData, [keytem]: newAttribute } })
        this.closeDropDown()
    }

    handleDeletedItem = (olCheckdArray: any, newCheckArray: any) => {
      const arr3 = olCheckdArray.map((data1: { id: number; options_attributes: {id: string}; }) => {
        const matchingItem = newCheckArray.find((data2: {id: number}) => data1.id === data2.id);
        if (matchingItem) {
          if (data1.options_attributes && matchingItem.options_attributes) {
            const updatedOptions = this.handleDeletedItem(
              data1.options_attributes,
              matchingItem.options_attributes
            );
            return { ...matchingItem, options_attributes: updatedOptions };
          }
          return { ...matchingItem };
        } else {
          return { ...data1, _destroy: true };
        }
      }).concat(newCheckArray.filter((data2: {id: number}) => !data2.id));

      return arr3;
    }


    handleCreateChecklist = () => {
        const { favorite,formData: { id: dataId, title, department_id, branch_id, paragraphs_attributes, single_answers_attributes, multiple_choices_attributes, checkboxes_attributes, dropdowns_attributes, linear_scales_attributes }, action, defaultFormData } = this.state
        const dataItem = {
            attributes: {
                title,
                branch_id,
                department_id,
                paragraphs_attributes: this.handleDeletedItem(defaultFormData.paragraphs_attributes, paragraphs_attributes),
                single_answers_attributes : this.handleDeletedItem(defaultFormData.single_answers_attributes, single_answers_attributes),
                multiple_choices_attributes : this.handleDeletedItem(defaultFormData.multiple_choices_attributes, multiple_choices_attributes),
                checkboxes_attributes : this.handleDeletedItem(defaultFormData.checkboxes_attributes, checkboxes_attributes),
                dropdowns_attributes : this.handleDeletedItem(defaultFormData.dropdowns_attributes, dropdowns_attributes),
                linear_scales_attributes : this.handleDeletedItem(defaultFormData.linear_scales_attributes, linear_scales_attributes),
                favorite
            }
        };

        const data2 = {
          attributes: {
              title,
              branch_id,
              department_id,
              form_paragraphs_attributes : paragraphs_attributes?.map(item => this.removeIdField(item)),
              single_answers_attributes : single_answers_attributes?.map(item => this.removeIdField(item)),
              multiple_choices_attributes : multiple_choices_attributes?.map(item => this.removeIdField(item)),
              checkboxes_attributes : checkboxes_attributes?.map(item => this.removeIdField(item)),
              dropdowns_attributes : dropdowns_attributes?.map(item => this.removeIdField(item)),
              linear_scales_attributes : linear_scales_attributes?.map(item => this.removeIdField(item))
      }}

        const httpBody = {
            data: action === configJSON.duplicate ? data2 :  dataItem,
        };

        this.createChecklistApiId = apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.edit === action ?  configJSON.putMethod : configJSON.exampleAPiMethod,
            body: JSON.stringify(httpBody),
            endPoint: `${configJSON.createChecklistApiEndpoint}/${configJSON.edit === this.state.action ? dataId : ""}`,
            token: true,
        });
    }

    handleSubmitCheckList = () => {
      const {action} = this.state;
      if ([configJSON.create, configJSON.edit, configJSON.duplicate].includes(action)) {
        this.handleCreateChecklist();
      }else {
        this.handleChecklistFill();
      }
    }

    handleChecklistFill = () => {
        const { formData } = this.state
        const { stateValue: {
            id: dataId,
            filled_by,
            paragraph_answers_attributes,
            checkbox_answers_attributes,
            dropdown_answers_attributes,
            linear_scale_answers_attributes,
            multiple_choice_answers_attributes,
            single_answer_answers_attributes

        } } = this.state

        const payload = {
            filled_by,
            checklist_id: formData.id,
            paragraph_answers_attributes,
            checkbox_answers_attributes,
            dropdown_answers_attributes,
            linear_scale_answers_attributes,
            multiple_choice_answers_attributes,
            single_answer_answers_attributes
        }

        if (Object.keys(payload).length) {
            this.setState({ loading: true });
            this.checklistFillApiCallId = apiCall({
                method: dataId ?  configJSON.putMethod : configJSON.exampleAPiMethod,
                body: convertAllFormData(payload, "data[attributes]"),
                endPoint: `${configJSON.checklistFillApiEndpoint}/${dataId ? dataId : ""}`,
                token: true,
            });
        }
    }
    handleResponseForChecklistFill = (from: string, message: Message) => {
        if (this.checklistFillApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const resDataItem = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(resDataItem, this.props.navigation);
            if (resDataItem.data) {
                if(this.state.defaultStateValue.id) {
                  toast.success(configJSON.submitEditChecklistSuccessMsg);
                  this.loadFilledResponse(resDataItem.data.attributes);
                  this.handleUpdate(this.state.defaultStateValue);
                } else {
                  toast.success(configJSON.submitChecklistSuccessMsg)
                  this.handleCancel();
                  }
            }  else {
                handleApiError(resDataItem.errors)
            }
        }
    }
    handleInputChange = (keyValue: string, indexValue: number, value: string | Blob | MediaSource | number, type?: string) => {

        let dataItem = this.state.stateValue[keyValue]
        if (type === "single_checkbox") {
            dataItem[indexValue].selected_option = value
        } else if (type === "multiple_checkbox") {
            let arrays = dataItem[indexValue].selected_options
            const findIndex = arrays?.indexOf(value)
            if (findIndex >= 0) {
                arrays.splice(findIndex, 1)
            } else {
                arrays.push(value)
            }
         } else {
            dataItem[indexValue].value = value
        }
        this.setState({
            stateValue: {
                ...this.state.stateValue,
                [keyValue]: dataItem
            }
        })

    };
 
    toggleViewMenu = () => {
        this.setState(prev => ({ openViewMenu: !prev.openViewMenu }))
    }

    handleDuplicate = () => {
        this.setState(prev => ({ action: configJSON.duplicate, formData: { ...prev.formData, title: `${prev.formData.title} [${configJSON.duplicate}]` } }))
        this.toggleViewMenu()
    }

    handleFavouriteClick = () => {
        const {formData : {id: checklist_id}, favorite} = this.state;
        this.favouriteChecklistApiId = favorite ?
        apiCall({
          method: configJSON.deleteMethod,
          endPoint: `${configJSON.createChecklistApiEndpoint}/${checklist_id}${configJSON.removeFavorite}`,
          token: true,
        })
        :
        apiCall({
          method: configJSON.putMethod,
          endPoint: `${configJSON.createChecklistApiEndpoint}/${checklist_id}${configJSON.addFavorite}`,
          token: true,
        });
        this.toggleViewMenu()
    }

    handelEditCheckList = () => {
      this.setState({ action: this.state.fromLibrary ? configJSON.edit : configJSON.fill, openViewMenu: false })
    }

    handleOpenDeleteModal = () => {
        this.setState({ openDeleteModal: true, openViewMenu: false })
    }
    handleDownloadPdf = () => {
        const element = this.pdfContentRef.current;
        this.setState({is_download:true,loading:true,openViewMenu:false})
        const handleLoading = () => {
        this.setState({loading:false,is_download:false})
    }
    commonDownloadPdfFunc(element,handleLoading,"Checklist",`${this.state.formData.title}`)

    };
    handleCloseDeleteModal = () => {
        this.setState({ openDeleteModal: false })
    }

    handleDeleteClick = () => {
      const {formData , stateValue, fromUserLogs} = this.state
        const endPoint = fromUserLogs ? `${configJSON.checklistFillApiEndpoint}/${stateValue.id}` : `${configJSON.createChecklistApiEndpoint}/${formData.id}`
        this.deleteChecklistApiId = apiCall({ method: "DELETE", endPoint, token: true });
    }

    handleCancelClick = () => {
        this.setState({
            formData: {
                branch_id: "",
                department_id: "",
                paragraphs_attributes: [],
                single_answers_attributes: [],
                title: "",
                checkboxes_attributes: [],
                multiple_choices_attributes: [],
                dropdowns_attributes: [],
                linear_scales_attributes: [],
                id: "",
                filled_form_by:this.state.formData.filled_form_by
            }
        })
    }

    handleCancelCheckList = () => {
      const {action, defaultFormData, fromLibrary, defaultStateValue} = this.state;
      if (action === configJSON.create) {
        this.handleCancelClick();
      } else if (action === configJSON.fill) {
        this.handleUpdate(defaultStateValue);
        this.setState({action: fromLibrary ? configJSON.fill  : configJSON.view})
      } else if (action === configJSON.edit) {
        this.setState({action: fromLibrary ? configJSON.fill  : configJSON.view, formData: defaultFormData})
      } else {
        this.handleCancel();
      }
    }

    checklistHeaderTitle = () => {
      const { action, defaultStateValue } = this.state;
      let startText = '';

      if (action === configJSON.create) {
        startText = configJSON.create;
      } else if (action === configJSON.view) {
        startText = configJSON.view;
      } else if (action === configJSON.edit) {
        startText = configJSON.edit;
      } else if (action === configJSON.duplicate) {
        startText = configJSON.duplicate;
      } else if (action === configJSON.fill && defaultStateValue.id) {
        startText = "Edit " + configJSON.fill;
      } else if (action === configJSON.fill) {
        startText = configJSON.fill;
      }


      return `${startText} ${configJSON.checklistTitle}`;
    }

    allowEditChecklist = () => {
        const {formData, fromUserLogs, stateValue} = this.state
        const dataItem = formData
        return !!((fromUserLogs ? stateValue?.creator?.id === loggedUserId : (dataItem?.creator?.id === loggedUserId )|| isBO()));

      }
      allowEditBranchDepart = () => {
        const {formData, action} = this.state
        const dataItem = formData
        if(configJSON.create === action) {
          return false
        } else if(configJSON.edit === action) {
          return (dataItem?.creator?.id !== loggedUserId)
        } else {
          return true
        }
      }
    // Customizable Area End
}



