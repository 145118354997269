// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControl, TextField, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { styles } from "./BulkInviteStyles.web";
import BulkInviteController, { Props } from "./BulkInviteController.web";
import Loader from "../../../components/src/Loader";
import BulkInviteInfoModalWeb from "../../../components/src/commonComponents/modals/BulkInviteInfoModal.web";
import { formatFileSize } from "../../../components/src/utils/commonFunctions";
import { colors } from "../../../components/src/utils/commonDesign";
import { configJSON } from "./BulkUploadingController";

const infoCircle = require("../../../components/src/assets/info-circle.png");
const closeIcon = require("../../../components/src/assets/blackCloseIcon.png");
const downloadIcon = require("../../../components/src/assets/upload-download-icon.png");
const attachIcon = require("../../../components/src/assets/pin-icon.png");
// Customizable Area End

// Customizable Area Start
// Customizable Area End
class BulkInvite extends BulkInviteController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { open, classes } = this.props;
    const { attachment, loading, openModal, apiError, allErrors, infoModaldescription, infoModaltitle } = this.state;
    return (
      <React.Fragment>
        <Loader loading={loading} />
        <div className={classes.bulkInviteWrapper}>
          <Dialog
            open={open}
            onClose={this.handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.bulkInvieModal}
          >
            <Box className={"titleContainer"}>
              <Box className={"infoContainer"}>
                <DialogTitle data-test-id="bulk-employee-title">{configJSON.title}</DialogTitle>
                <Box className={"infoIcon"}>
                  <img id="info-icon" data-test-id="info-icon" src={infoCircle} alt="info-icon" onClick={() => this.handleOpenInfo("infoModal")} />
                </Box>
              </Box>
              <Box className={"closeIcon"}>
                <img id="close-icon" src={closeIcon} onClick={this.handleCloseModal} alt="close-icon" />
              </Box>
            </Box>
            <DialogContent>
              <Box className="step-box">
                <DialogContentText>
                  {configJSON.downloadText}
                </DialogContentText>
                <Button
                  color="primary"
                  data-test-id="download-btn"
                  className={`${classes.primaryBtn} download-btn`}
                  onClick={this.downloadSampleCsv}
                >
                  <img src={downloadIcon} alt="download-icon" />
                  {configJSON.downloadSampleBtn}
                </Button>
              </Box>
              <Box className="step-box">
                <DialogContentText>
                  {configJSON.editFileText}
                </DialogContentText>
              </Box>
              <Box className="step-box">
                <DialogContentText>
                  {configJSON.attachFileText}
                </DialogContentText>
                <Box>
                  <FormControl className={classes.fileInput}>
                    <TextField
                      id="documents"
                      variant="outlined"
                      type="file"
                      inputProps={{ accept: '.csv' }}
                      onChange={(event: any) => { event.target.files[0] && this.handleFileUpload(event.target.files[0]) }}
                    />
                    {attachment ?
                      <div className={`label-block ${apiError && "error-block"}`} style={{ justifyContent: "start" }}>
                        <span data-test-id="file_name" className="file-text file-name" id="upload-file-name">
                          {attachment.name} ({formatFileSize(attachment.size)})
                        </span>
                      </div>
                      :
                      <div className={`label-block ${apiError && "error-block"}`}>
                        <img src={attachIcon} alt="Upload" />
                        <span data-test-id="file_placeholder" className="file-text" id="upload-file-name">
                          {configJSON.attachFilePlaceholder}
                        </span>
                      </div>
                    }
                  </FormControl>
                  {apiError && <Box className={classes.errorMsg}>
                    <span data-test-id="file_error" style={{ color: colors.errorText }}>*</span>{apiError}
                    {allErrors && <span data-test-id="response_file_errors" className={`${classes.errorMsg} more-link`} onClick={() => this.handleOpenInfo("errorsModal")}>
                      {configJSON.checkMoreText}
                    </span>}
                  </Box>}
                </Box>
              </Box>

            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.uploadCsv}
                color="primary"
                data-test-id="next-btn"
                className={`${classes.primaryBtn} next-btn`}
              >
                {configJSON.nextBtn}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <BulkInviteInfoModalWeb
          open={openModal === "infoModal"}
          handleClose={this.handleCloseInfo}
          description={infoModaldescription}
          title={infoModaltitle}
        />
        <BulkInviteInfoModalWeb
          open={openModal === "errorsModal"}
          handleClose={this.handleCloseInfo}
          description={this.convertErrorsIntoArray()}
          title={configJSON.errorModalTitle}
        />
      </React.Fragment>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(BulkInvite);
export { BulkInvite }
// Customizable Area End