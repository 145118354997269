import { makeStyles } from "@material-ui/core";
import { colors } from "./utils/commonDesign"

const drawerWidth = 240

export const UseStyle = makeStyles((theme) => ({
    wrapper: {
        width: "98%",
        alignSelf: "center",
        padding: "10px 0px",
        zIndex: theme.zIndex.drawer + 1,
    },
    closeImage: {
        width: "150px",
        height: "58px",
        [theme.breakpoints.between('md', 'lg')]: {
            width: "120px",
            height: "44px"
        }
    },
    openImage: {
        width: "58px",
        height: "58px",
        [theme.breakpoints.between('md', 'lg')]: {
            width: "40px",
            height: "40px"
        }
    },
    backImage: {
        cursor: "pointer",
        width: "24px",
        height: "24px"
    },
    headline: {
        fontSize: "28px",
        fontWeight: 700,
        display: 'inline',
        color: colors.text,
        paddingInline: 10,
        paddingLeft:"1rem",
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: "28px",
            paddingLeft:"1rem",
        }
    },
    headerImage: {
        cursor: "pointer",
        width: "32px",
        height: "32px",
        // marginRight: "10px",
        [theme.breakpoints.between('md', 'lg')]: {
            width: "22px",
            height: "22px"
        }
    },
    divider: {
        color: "#020202",
        marginBlock: "5px",
        background:"#020202",
    },
    avatar: {
        width: "42px",
        height: "42px",
        [theme.breakpoints.between('md', 'lg')]: {
            width: "28px",
            height: "28px"
        }
    },
    root: {
        display: 'flex',
        backgroundColor: colors.primaryColor1,
        height: "100%",
        "& svg":{
            cursor:"pointer"
        }
    },
    treeRoot: {
        height: 216,
        flexGrow: 1,
        maxWidth: 400,
    },
    treeViewText: {
        fontSize: "16px",
        color: "#fff",
        marginLeft: "24px"
    },
    sideViewText: {
        fontSize: "18px",
        fontWeight: 700,
        color: "#fff",
        marginLeft: "24px"
    },
    sideViewTextExpand: {
        fontSize: "18px",
        fontWeight: 700,
        color: "#fff",
        marginLeft: "8px"
    },
    treeViewTextExpand: {
        fontSize: "16px",
        color: "#fff",
        marginLeft: "10px"
    },
    appBar: {
        width: `calc(100% - ${theme.spacing(9) + 1}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: colors.primaryColor1,
        color: "#020202",
        boxShadow: "none",
        [theme.breakpoints.between('md', 'lg')]: {
            width: `calc(100% - ${theme.spacing(7) + 1}px)`,
        },
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: colors.primaryColor1,
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: "transparent",
        overflow: "hidden",
    },
    drawerOpen: {
        width: drawerWidth,
        border: "none",
        backgroundColor: "transparent",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflow: "hidden",
    },
    drawerClose: {
        border: "none",
        backgroundColor: "transparent",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(9) + 1,
        [theme.breakpoints.between('md', 'lg')]: {
            width: theme.spacing(7) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        [theme.breakpoints.between('md', 'lg')]: {
            padding: 0,
        },
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1, 3, 3),
        marginTop: "64px",
        overflow: "scroll",
        height: "calc(100vh - 64px)",
    },
    drawerContainer: {
        padding: "10px",
        [theme.breakpoints.between('md', 'lg')]: {
            padding: "10px 5px",
        },
    },
    drawerContainerCover: {
        position: "relative",
        zIndex: 100,
    },
    drawerIcon: {
        width: '32px',
        height: "32px",
        cursor:"pointer",
        [theme.breakpoints.between('md', 'lg')]: {
            width: "28px",
            height: "28px"
        }
    },
    sideBarCompany: {
        cursor: "pointer",
        display: 'flex',
        alignItems: 'center',
        paddingInline: "11px",
        [theme.breakpoints.between('md', 'lg')]: {
            paddingInline: "9px",
        },
    },
    ownerTxt: {
        fontSize: "16px",
        color: "#fff"
    },
    ownerHeadline: {
        fontSize: "14px",
        color: "#fff"
    },
    childStyle: {
        height: "100%",
        borderRadius: "10px",
        width:"100%",
        "@media(min-width:1920px)": {
            width:"80%"
        },
        "@media(min-width:2480px)": {
          width:"50%"
      }
    },
    childTreeItem: {
        paddingInline: 0
    },
    mainToolbar: {
        minHeight: '40px',
        [theme.breakpoints.between('md', 'lg')]: {
            minHeight: '45px'
        }
    },
    treeWrapper: {
        zIndex: 100,
        backgroundColor: colors.secondaryColor1,
        width: "100%",
        cursor: "pointer",
    },
    treeWrapperBorder: {
      "& .MuiTreeItem-content" : {
        border: "2px solid",
        borderColor: colors.secondaryColor2,
        borderRadius: "5px",
        padding: "10px 10px 10px 16px",
        marginLeft: "-16px",
        width: "calc(100% + 26px)"
      }
    },
    expandIcn: {
        color: "#fff"
    },
    rightBox: {
        "& > *:not(:last-child)": {
          marginRight: "1.2rem",
        },
      },
    iconRotate: {
        rotate: "180deg",
    },
    badge:{
        "& span":{
        backgroundColor:`${colors.secondaryColor1} !important`
    }
    }
}))
