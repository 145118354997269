import { colors } from "../../../components/src/utils/commonDesign";
import { inputField } from "../../../components/src/utils/commonInputStyle";
const errorMsg = {
  color: colors.errorText,
  margin: 0,
  marginBottom: "-18px",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: 400,
}

export const styles: any = {
  ...inputField,
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "inherit",
  },
  card: {
    borderRadius: 16,
    margin: "auto",
    overflow: "auto"
  },
  cardContentProfile: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: 40,
    padding: "35px 50px !important",
  },
  cardContent: {
    display: "flex",
    padding: "35px 50px !important",
  },
  contentHeight: {

  },
  headerLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ["& img"]: {
      height: 40
    }
  },
  title: {
    color: colors.secondaryColor1,
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "42px",
  },
  title2: {
    color: colors.secondaryColor1,
    fontSize: "32px",
    lineHeight: "42px",
    fontWeight: "700",
    marginTop: 15,
  },
  uploadImage: {
    position: "relative",
    bottom: "0",
    right: "0",
    margin: "15px 0 0px",
    height: "130px",
    width: "140px",
    border: "1px dashed #969492",
    borderRadius: 8,
    cursor: "pointer",
    "& .innerContent": {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "100%",
      textAlign: "center",
      placeItems: "center",
      transform: "translate(-50%, -50%)",
      "& .filePreview": {
        width: "auto",
        height: "auto",
        maxHeight: 130,
        maxWidth: 130,
        objectFit: "contain",
        aspectRatio: "3/2"
      },
      "& .uploadImage": {
        width: 48,
        height: 56,
        paddingTop: 4,
      },
      "& .uploadText": {
        margin: "8px 10px 0",
        fontSize: "16px",
        color: colors.secondaryColor1,
        lineHeight: "21px",
        fontWeight: "400",
      },
    },
    "& .MuiFormControl-root": {
      width: "100%",
      zIndex: "100",
      height: "100%",
      "& .MuiInputBase-formControl": {
        borderRadius: "50%",
        border: "none",
        height: "100%",
        width: "100%",
        "& fieldset": {
          display: "none",
        },
        "& input": {
          position: "relative",
          padding: "0",
          opacity: "0",
          zIndex: "99",
          width: "100%",
          height: "100%",
          cursor: "pointer",
        },
      },
    },
  },
  form: {
    margin: "auto",
  },
  errorMsg : {...errorMsg, marginTop: "5px", marginBottom: "-23px"},
  btnWrapper: {
    padding: "0 36.5px",
  },
  btn: {
    color: colors.primaryColor2,
    backgroundColor: colors.secondaryColor2,
    marginTop: 52,
    boxShadow: "none",
    fontWeight: 700,
    textTransform: "capitalize",
    height: "56px",
    borderRadius: "10px",
    fontSize: "16px",
    lineHeight: "21px",
    "&:hover": {
      backgroundColor: colors.secondaryColor2,
    }
  },
  endText: {
    marginTop: 25,
    fontSize: "16px",
    "& span": {
      color: colors.secondaryColor1,
      cursor: "pointer",
      fontWeight: "500",
    },
  },
  imageContainer: {
    height: "550px",
    width: "600px"
  },
  imageProfileContainer: {
    height: "580px"
  },
  profileInput: {
    marginTop : "5px",
    "& .MuiInputBase-root": {
      height: "42px",
    },
    "& .MuiFormHelperText-root": errorMsg,
  },
  profileFiledWrapper: {
    margin: "22px 0 0"
  },
  profileBtn: {
    height: 44,
    marginTop: 38,

  },
  closeIcn: {
    zIndex: 1000,
    position: "absolute",
    top: 0,
    right: 0,
    padding: "2px 4px",
    "&:hover": {
      backgroundColor: "#beb8bc",
      borderRadius: 8,
    }
  },
  phoneGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "39px"
  },
  inputWidth: {
    width: "64% !important",
    position: "relative",
    "& p": {
      position: "absolute",
      bottom: "-20px"
    }
  },
  dropdownWidth: {
    width: "145px"
  }
};
