// Customizable Area Start
import React from "react";
import { Grid, Tabs, Tab, Typography, Button, Box, Menu, MenuItem, IconButton } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { ExpandMoreRounded, DateRange } from '@material-ui/icons';
import { Wrapper } from "../../../components/src/Wrapper";
import { colors } from "../../../components/src/utils/commonDesign";
import { imgFilter } from "./assets";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import FavouriteController, { Props, configJSON } from "./FavouriteController.web";
import { commonButton } from "../../../components/src/utils/commonInputStyle";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import ProcedureListing from "./ProcedureListing.web";
import FavouriteProductListing from "./FavouriteProductListing.web";
import FavouriteFormListing from "./FavouriteFormListing.web";
import FavouriteChecklistListing from "./FavouriteChecklistListing.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FavouriteMenuListing from "./FavouriteMenuListing.web";
import { isBO, isManager } from "../../../components/src/utils/commonFunctions";

// Customizable Area End


// Customizable Area Start
export const styles = () => createStyles({
  ...commonButton,
  filterBtn: {
    background: "#FFF",
    borderRadius: "8px",
    "&:hover": {
      background: "#FFF",
    }
  },
  tabHeading: {
    fontSize: "18px",
    color: colors.text,
    textTransform: "capitalize",
    fontWeight: 700,
  },
  inputField: {
    margin: 0,
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      "& .MuiInputBase-input": {
        lineHeight: "21px",
        fontSize: "16px",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.secondaryColor2,
      },
    },
  },
  filterBtnTxt: {
    textTransform: "capitalize",
    fontWeight: 600,
    color: colors.text,
  },

  dateRangeTxt: {
    fontWeight: 500,
    fontSize: "14px",
  },
  dateField: {
    "& .MuiInputBase-root": {
      padding: "0 10px",
      border: `1px solid #0000003b`,
    },
    "& .Mui-focused": {
      borderColor: colors.secondaryColor2,
    },
  },
  filterDayItemHighlight: {
    border: `1px solid ${colors.secondaryColor2}`,
  },
  filterDayItem: {
    width: "100%",
    border: `1px solid #0000003b`,
    textAlign: "center",
    paddingBlock: "3px",
    borderRadius: "8px",
    "&:hover": {
      border: `1px solid ${colors.secondaryColor2}`,
    }
  },
  filterTxtHighlight: {
    fontWeight: 600,
  },
  filterTxt: {
    fontSize: "14px",
  },
  tabBtn: {
    "&.borderBottom": {
      borderBottom: `1px solid ${colors.secondaryColor2}`
    },
    minWidth: "auto",
    marginRight: "25px",
  },
  menuItems: {
    "& .MuiMenu-paper": {
      marginLeft: 30,
      marginTop: 7,
      borderRadius: "8px"
    }
  },
  arrowIcon: {
    paddingRight: "0px",
  },
  feedbackBtn: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "24%",
    "& p": {
      marginLeft: 6,
      textTransform: "capitalize"
    },
    "&.clearAll": {
      width: "99%"
    },
    "& button": {
      borderRadius: "unset",
      textTransform: "unset",
      color: "#4196F0",
      padding: 0,
      borderBottom: "1px solid #4196F0",
      fontSize: "1rem",
      background: "transparent",
      "&:disabled": {
        borderBottom: "1px solid #4196F080",
        color: "#4196F080",
      },
      "&:hover": {
        background: "transparent",
      },
    },
  },
  dropdownBox: {
    display: "flex",
    width: "75%",
    "& .dropdownMargin": {
      marginRight: "20px"
    }
  },
  gridContainer: {
    marginBottom: "20px"
  }
})
// Customizable Area End

export class Favourite extends FavouriteController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const { value, openMenu, date, searchQuery, formData, branch_list, department_list, menuAnchorEl } = this.state
    return (
      <Wrapper
        navigation={this.props.navigation}
        data-test-id="wrapper-comp"
        divider
        title={configJSON.favtitle}
        backbtn
        homeIcn
        bellIcn
        searchHandleClick={this.handleSearch}
        search
        querySearch={this.state.searchQuery}
        child={
          <>
            <Grid spacing={2} container>
              <Grid sm={10} item>
                <Tabs data-test-id="tabs" value={value} onChange={this.handleTabChange} >
                  {configJSON.tablist.map((label: string, index: number) => (
                    <Tab key={label} id={label} className={`${classes.tabBtn} ${index === 1 && value === 1.1 ? 'Mui-selected borderBottom' : ''}`} label={<Typography className={classes.tabHeading}>
                      {value === 1.1 && index === 1 ? "Menu Templates" : label}
                      {label === "Product Info" &&
                        <IconButton
                          onClick={this.handleTabMenuOpen}
                          aria-controls="Product Info"
                          aria-label="Product Info"
                          data-test-id="icon_button"
                          aria-haspopup="true"
                          className={classes.arrowIcon}                                                    >
                          <ExpandMoreIcon />
                        </IconButton>}
                    </Typography>} />
                  ))}
                </Tabs>
                <Menu
                  anchorEl={menuAnchorEl}
                  onClose={this.handleTabMenuClose}
                  getContentAnchorEl={null}
                  open={Boolean(menuAnchorEl)}
                  className={classes.menuItems}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  id="Menu Product Info"
                >
                  <MenuItem data-test-id="product_template" onClick={() => this.handleProductMenu(1)}>{configJSON.productTemplateText}</MenuItem>
                  <MenuItem data-test-id="menu_template" onClick={() => this.handleProductMenu(1.1)}>{configJSON.menuTemplateText}</MenuItem>
                </Menu>
              </Grid>
              <Grid sm={2} item>
                <Button fullWidth variant="outlined" data-test-id="menu-filter" size="large" className={classes.filterBtn} onClick={this.handleMenuOpen}>
                  <Box justifyContent={"space-between"} display={"flex"} width={"100%"}>
                    <img alt="filter-icon" src={imgFilter} />
                    <Typography className={classes.filterBtnTxt} noWrap>{configJSON.filterByData}</Typography>
                    <ExpandMoreRounded fontSize="small" />
                  </Box>
                </Button>
                <Menu
                  open={Boolean(openMenu)}
                  anchorEl={openMenu}
                  onClose={this.handleMenuClose}
                  getContentAnchorEl={null}
                  keepMounted
                  anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                  }}
                  transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                  }}
                >
                  <MenuItem>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        data-test-id="from-date"
                        className={`${classes.inputField} ${classes.dateField}`}
                        margin="dense"
                        variant="inline"
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: <DateRange />,
                        }}
                        autoOk
                        value={date.from_date}
                        name="creation_date"
                        allowKeyboardControl={false}
                        format="MM/dd/yyyy"
                        placeholder={"From"}
                        disableFuture={true}
                        onChange={(e) => this.handleDateChange(e, "from_date")}
                      />
                    </MuiPickersUtilsProvider>
                  </MenuItem>
                  <MenuItem>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        data-test-id="to-date"
                        className={`${classes.inputField} ${classes.dateField}`}
                        margin="dense"
                        variant="inline"
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: <DateRange />,
                        }}
                        value={date.to_date}
                        name="creation_date"
                        autoOk
                        allowKeyboardControl={false}
                        placeholder={"To"}
                        disableFuture={true}
                        minDate={date.from_date}
                        format="MM/dd/yyyy"
                        onChange={(e) => this.handleDateChange(e, "to_date")}
                      />
                    </MuiPickersUtilsProvider>
                  </MenuItem>
                  {configJSON.filterMenuList.map((item: string) => (
                    <MenuItem key={item}>
                      <Box data-test-id={item} onClick={() => this.handleDayChange(item)} className={`${classes.filterDayItem} ${item === date.time_range && classes.filterDayItemHighlight}`} ><Typography className={`${classes.filterTxt} ${item === date.time_range && classes.filterTxtHighlight}`}>{item}</Typography></Box>
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            </Grid>
            <Box pt={2}>
              <Grid spacing={2} style={{ marginBottom: "20px" }} className={classes.gridContainer} container>
                {(isBO() || isManager()) && <Box className={classes.dropdownBox}>
                  <Grid sm={3} item className="dropdownMargin">
                    <DropdownSearch
                      options={branch_list}
                      onChangeValue={(event: OptionType) => this.handleSelectChange(event, "branch_id")}
                      isSearchable={true}
                      data-test-id="branch_id"
                      placeholder={configJSON.branchPlaceholder}
                      value={formData.branch_id}
                      noOptionsMessage={configJSON.noBranchMessage}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <DropdownSearch
                      value={formData.department_id}
                      onChangeValue={(event: OptionType) => this.handleSelectChange(event, "department_id")}
                      placeholder={configJSON.departSelect}
                      data-test-id="department_id"
                      isSearchable={true}
                      noOptionsMessage={configJSON.noDepartmentMessage}
                      options={department_list}
                    />
                  </Grid>
                </Box>}
                <Box data-test-id="clearall_button" onClick={this.handleClearAll} className={`${classes.feedbackBtn} ${(isBO() || isManager()) ? "" : "clearAll"}`}>
                  <Button disabled={this.handleDisable()}>{configJSON.clearAllFilters}</Button>
                </Box>
              </Grid>
              {value === 0 && <ProcedureListing date={date} navigation={navigation} searchQuery={searchQuery} formData={formData} />}
              {value === 1 && <FavouriteProductListing date={date} navigation={navigation} searchQuery={searchQuery} formData={formData} />}
              {value === 1.1 && <FavouriteMenuListing date={date} navigation={navigation} searchQuery={searchQuery} formData={formData} />}
              {value === 2 && <FavouriteFormListing date={date} navigation={navigation} searchQuery={searchQuery} formData={formData} />}
              {value === 3 && <FavouriteChecklistListing date={date} navigation={navigation} searchQuery={searchQuery} formData={formData} />}
            </Box>
          </>
        }
      />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(Favourite);
// Customizable Area End




