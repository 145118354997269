// Customizable Area Start
import React from "react";
import {
	Box,
	Button,
	Typography,
	Grid,
	Card,
	CardContent,
	InputLabel,
	TextField,
	FormHelperText,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./CustomizeStyles.web";
import CustomizeController, { Props, configJSON } from "./CustomizeController.web";
import Loader from "../../../components/src/Loader.web";
import { themeColors } from "../../../components/src/utils/constant";
import { Formik } from "formik";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import { CustomizeThemeSchema } from "../../../components/src/utils/validationSchema";
import InfoModal from "../../../components/src/commonComponents/modals/InfoModal.web";
import { Wrapper } from "../../../components/src/Wrapper";

const infoCircle = require("../../../components/src/assets/info-circle.png");
// Customizable Area End


export class Customize extends CustomizeController {
	constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

	render() {
    // Customizable Area Start
		const { classes } = this.props;
		const { primary_colour, secondary_colour, openModal, info_text } = this.state;

		return (
			// Required for all blocks
			<React.Fragment>
				<Loader loading={this.state.loading} />
				<Wrapper
				navigation={this.props.navigation}
					data-test-id="customize-wrapper-test"
					child={
						<>
							<Card className={classes.card}>
								<CardContent className={classes.cardContent}>
									<Typography variant="h4" className={"title1"} data-test-id="welcome_text">
										{configJSON.customizeWlcomeText}
									</Typography>
									<p className={"body1"} data-test-id="description_text">
                    {configJSON.customizeDescriptionText}
									</p>
									<p className={"body1"} style={{ marginTop: 31 }}>
                  Everyone and everything in one place.<br />A space for you to collaborate, organize, and over achieve.
									</p>
									<Box className={"infoContainer"}>
										<Typography variant="h4" className={"title2"}>
											Choose a template (Recommended)
										</Typography>
										<Box className={classes.infoIcon}>
											<img data-test-id="info_modal_btn" src={infoCircle} alt="info-icon" onClick={this.openPopup} />
										</Box>
									</Box>

									<Box className="pallettContainer">
										<Grid container spacing={6}>
											{themeColors.map((color) => (
												<Grid item xs={6} sm={4} md={3} key={`${color.primary_colour} ${color.secondary_colour}`}>
													<Box data-test-id="color_pallet" className={`palletBox ${this.getSelectedClass(color)}`} onClick={() => this.handleTemplateChange(color)}>
														<Box style={{ backgroundColor: color.primary_colour }} className="palletColor color1"></Box>
														<Box style={{ backgroundColor: color.secondary_colour }} className="palletColor color2"></Box>
														<Box style={{ backgroundColor: color.background_colour }} className="palletColor color3"></Box>
													</Box>
												</Grid>))}
										</Grid>
									</Box>

									<Box className={"customeColorContainer"}>
										<Typography variant="h4" className={"title"}>
											Customize Colors
										</Typography>
										<Formik
											enableReinitialize={true}
											validateOnMount={true}
											initialValues={{
												primary_colour,
												secondary_colour
											}}
											validationSchema={CustomizeThemeSchema}
											onSubmit={this.onSetTheme}
										>
											{({
												errors,
												touched,
												values,
												handleSubmit,
												handleBlur,
												setFieldValue
											}) => {
												return (
													<form
														onSubmit={handleSubmit}
													>
														<Grid container spacing={6} style={{ maxWidth: "950px" }}>
															<Grid item xs={12} sm={7} md={5}>
																<Box
																	className="fieldWrapper"
																>
																	<InputLabel
																		className="inputLabel"
																	>
                                    <div className={classes.inputLabelInner}>
                                      Primary Color
                                      <Box className={`${classes.infoIcon} ${classes.smallInfoIcon}`}>
                                        <Tooltip title="“We advice you to chose a dark color”" placement="right" classes={{ tooltip: classes.toolTip }}>
                                          <img src={infoCircle} alt="info-icon" />
                                        </Tooltip>
                                      </Box>
                                    </div>
																	</InputLabel>
																	<Grid container spacing={7}>
																		<Grid item xs={6} sm={5}>
																			<TextField
																				type="color"
																				variant="outlined"
																				placeholder="#HEX CODE"
																				value={
																					values.primary_colour
																				}
																				name={"primary_colour"}
																				onChange={(event) => setFieldValue("primary_colour", this.handleColorChange(event))}
																				onBlur={handleBlur}
																				fullWidth
																				className="inputField"
																			/>
																		</Grid>
																		<Grid item xs={6} sm={7}>
																			<TextField
																				type="text"
																				variant="outlined"
																				placeholder="#HEX CODE"
																				value={
																					values.primary_colour
																				}
																				name={"primary_colour"}
																				onChange={(event) => setFieldValue("primary_colour", this.handleColorChange(event))}
																				onBlur={handleBlur}
																				fullWidth
																				className="inputField"
																				error={handleInvoiceErrors(
																					errors.primary_colour,
																					touched.primary_colour
																				)}
																			/>
																		</Grid>
																		{handleInvoiceErrors(
																			errors.primary_colour,
																			touched.primary_colour
																		) && (
																				<FormHelperText
																					className={classes.helperText}
																				>
																					{handleInvoiceHelperText(
																						errors.primary_colour,
																						touched.primary_colour
																					)}
																				</FormHelperText>
																			)}
																	</Grid>
																</Box>
																<Box
																	className="fieldWrapper"
																>
																	<InputLabel
																		className="inputLabel"
																	>
                                    <div className={classes.inputLabelInner}>
                                      Secondary Color
                                      <Box className={`${classes.infoIcon} ${classes.smallInfoIcon}`}>
                                        <Tooltip title="“We advice you to chose a bright color”" placement="right" classes={{ tooltip: classes.toolTip }}>
                                          <img src={infoCircle} alt="info-icon" />
                                        </Tooltip>
                                      </Box>
                                    </div>
																	</InputLabel>
																	<Grid container spacing={7}>
																		<Grid item xs={6} sm={5}>
																			<TextField
																				type="color"
																				variant="outlined"
																				placeholder="#HEX CODE"
																				value={
																					values.secondary_colour
																				}
																				name={"secondary_colour"}
																				onChange={(event) => setFieldValue("secondary_colour", this.handleColorChange(event))}
																				onBlur={handleBlur}
																				fullWidth
																				className="inputField"
																			/>
																		</Grid>
																		<Grid item xs={6} sm={7}>
																			<TextField
																				type="text"
																				variant="outlined"
																				placeholder="#HEX CODE"
																				value={
																					values.secondary_colour
																				}
																				name={"secondary_colour"}
																				onChange={(event) => setFieldValue("secondary_colour", this.handleColorChange(event))}
																				onBlur={handleBlur}
																				fullWidth
																				className="inputField"
																				error={handleInvoiceErrors(
																					errors.secondary_colour,
																					touched.secondary_colour
																				)}
																			/>
																		</Grid>
																		{handleInvoiceErrors(
																			errors.secondary_colour,
																			touched.secondary_colour
																		) && (
																				<FormHelperText
																					className={classes.helperText}
																				>
																					{handleInvoiceHelperText(
																						errors.secondary_colour,
																						touched.secondary_colour
																					)}
																				</FormHelperText>
																			)}
																	</Grid>
																</Box>
															</Grid>
														</Grid>
														<Box className={"btnWrapper1"}>
															<Button
																variant="outlined"
																className={`btn secondaryBtn`}
																onClick={this.onSkipTheme}
                                data-test-id="skip_btn"
															>
																Skip
															</Button>
															<Button
																variant="contained"
																className={`btn primaryBtn`}
																type={"submit"}
															>
																Save
															</Button>
														</Box>
													</form>
												);
											}}
										</Formik>
									</Box>
								</CardContent>
							</Card>
							<InfoModal
								open={openModal}
								cancelText="Okay"
								handleClose={this.closePopup}
								description={info_text}
							/>
						</>
					} />

			</React.Fragment>
		);
    // Customizable Area End
	}
}
// Customizable Area Start
export default withStyles(styles)(Customize);
// Customizable Area End
