
import React, { Component } from "react";
// Customizable Area Start

import { ClassNameMap } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
export const configJSON = require("./config");
// Customizable Area End


// Customizable Area Start
interface TicketsProps {
    imagePath: string;
    title: string;
    content?: string;
    strongContent?: string;
    endContent?: string;
    classes: ClassNameMap<"dialogContent" | "mainBox"

    >
}
export const styles: Styles<Theme, {}, string> = () => ({
    mainBox: {
        textAlign: "center",
        "& h4": {
            fontSize: "1.4rem",
            margin: "1rem 0",
            color: "#020202",
            fontWeight: 600,
        },
        "& p": {
            color: "#020202",
            opacity: 0.4
        },
    },
    dialogContent: {
        width: "412px",
    },

});
// Customizable Area End

export class NoTickets extends Component<TicketsProps> {
    constructor(props: TicketsProps) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { imagePath, title, content, strongContent, endContent, classes } = this.props;

        return (
            <Box className={classes.mainBox}>
                <img src={imagePath} alt="ticket" />
                <Typography component="h4">{title}</Typography>
                <Typography className={classes.dialogContent} data-test-id="title-content">
                    {content} <strong>{strongContent}</strong> {endContent}
                </Typography>
            </Box>
        );

        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(NoTickets);
// Customizable Area End
