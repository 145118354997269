// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, capitalizeFirstLetter, filterNullObj, handleApiError, handleExpiredToken, isBO, isManager } from "../../../components/src/utils/commonFunctions";
import { Message } from "../../../framework/src/Message";
import { ClassNameMap } from "@material-ui/styles";
import moment from "moment";
import { toast } from "react-toastify";

import { DateData } from "./LibraryController.web";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"listContainer" | "cardWrapper" | "templateImg" | "procedureTitleTxt"
    | "dateTxt" | "favouriteIcn" | "menuImg" | "menuTxt" | "menuPopper" | "departSelect"
    | "listItem" | "tableHeader" | "listMain" | "tableItem" | "imgContainer" | "svgIcon"
    | "deleteModalDesc" | "btn" | "btn1" | "paginationBox" | "noTicketContainer" | "noTicketOuterContainer">;
  gridView: boolean;
  date: DateData;
  searchQuery: string;
  allowEditDelete: (creatorId: number | string | undefined) => void;
  formData: FormValues
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  menu_list: MenuData[];
  isOpen: { [key: string]: boolean };
  anchorEl: HTMLElement | null;
  search_menu_list: MenuData[] | null;
  menu_id: string;
  openDeleteModal: boolean;
  header_list: string[];
  meta: {
    total_count: number;
    current_page: number;
    previous_page: null | number;
    total_pages: number;
    next_page: null | number;
  },
  isBO: boolean;
  isManager: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}
// Customizable Area Start

interface ResponseErrorsData {
  errors: string[]
}
interface FormValues {
  branch_id: string;
  department_id: string;
}

interface Responsemenu {
  menus: {
    data: {
      attributes: {
        id: string;
        template_id: number;
        name: string;
        favorite: boolean;
        created_at: string;
        menu_template: {
          data: {
            attributes: {
              template_image: {
                name: string;
                url: string;
              }
            }
          }
        }
      }
    }[]
  };
  meta: {
    total_count: number;
    current_page: number;
    total_pages: number;
    previous_page: number | null;
    next_page: number | null;
  }
}

interface MenuData {
  attributes: {
    id: string;
    name: string;
    template_id: number;
    created_at: string;
    creator?: MenuCreator;
    favorite: boolean;
    menu_template: {
      data: {
        attributes: {
          template_image: {
            name: string;
            url: string;
          }
        }
      }
    }
  }
}

export interface MenuCreator {
  id: number | string;
  name: string;
  designation: string;
  email: string
}
// Customizable Area End

export default class MenuTemplateListingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getMenuListApiId: string = ""
  deletemenuApiId: string = ""
  favouritemenuApiId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      loading: false,
      menu_list: [],
      anchorEl: null,
      menu_id: "",
      header_list: configJSON.menuHeaderList,
      search_menu_list: null,
      isOpen: {},
      openDeleteModal: false,
      meta: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
        previous_page: null,
        next_page: null
      },
      isBO: isBO(),
      isManager: isManager()
    }

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount()
    const { date: { from_date, to_date, time_range }, searchQuery, formData } = this.props
    this.setState({ loading: true })
    this.getmenus(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: Props, previousState: S) {
    const { date: { from_date, to_date, time_range }, searchQuery, formData } = this.props
    if ((prevProps.date.time_range !== this.props.date.time_range) || (prevProps.date.from_date !== this.props.date.from_date) || (prevProps.date.to_date !== this.props.date.to_date) || formData.branch_id !== prevProps.formData.branch_id || formData.department_id !== prevProps.formData.department_id) {
      if (from_date && to_date) {
        this.setState({ loading: true })

        this.getmenus(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
      }
      if (time_range !== "custom") {
        this.setState({ loading: true })
        this.getFilteredmenus(from_date, to_date, time_range, searchQuery)
      }
    }
    if (prevProps.gridView !== this.props.gridView) {
      this.closeDropDown()
    }
    if (prevProps.searchQuery !== this.props.searchQuery) {
      this.setState({ loading: true })
      this.getmenus(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let menuRes = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(menuRes, this.props.navigation);
      this.setState({ loading: false });

      if (apiCallId && menuRes) {
        if (apiCallId === this.getMenuListApiId) {
          this.getmenusResponse(menuRes);
        } else if (apiCallId === this.deletemenuApiId) {
          this.handleDeleteApiResponse(menuRes);
        } else if (apiCallId === this.favouritemenuApiId) {
          this.handleFavouriteResponse(menuRes);
        }
      }
    }
  }

  getmenus = (page: number, from_date: string | null, to_date: string | null, time_range: string | null, department_id: string, query?: string) => {
    this.setState({ loading: true })
    let endPointfiter: string = `${configJSON.menuTemplateListApiEndPoint}`
    const { branch_id } = this.props.formData
    let allData = { page, custom_start_date: from_date ? moment(from_date).format("DD/MM/YYYY") : "", custom_end_date: to_date ? moment(to_date).format("DD/MM/YYYY") : "", time_range, branch_id, department_id, query };

    this.getMenuListApiId = apiCall({ method: configJSON.getMethod, endPoint: endPointfiter + filterNullObj(allData), token: true })
  }

  getFilteredmenus = (from_date: string | null, to_date: string | null, time_range: string | null, searchQuery: string = '') => {
    const department_id = this.props.formData.department_id
    switch (time_range) {
      case configJSON.today:
        this.getmenus(this.state.meta.current_page, from_date, to_date, "today", department_id, searchQuery)
        break
      case configJSON.yesterday:
        this.getmenus(this.state.meta.current_page, from_date, to_date, "yesterday", department_id, searchQuery)
        break
      case configJSON.lastDays:
        this.getmenus(this.state.meta.current_page, from_date, to_date, "15_days", department_id, searchQuery)
        break
      default:
        this.getmenus(this.state.meta.current_page, from_date, to_date, "", department_id, searchQuery)
        break
    }
  }

  getmenusResponse = (response: Responsemenu & ResponseErrorsData) => {
    this.closeDropDown()
    if (response.errors) {
      this.setState({ loading: false })
      handleApiError(response.errors)
    } else {
      this.setState(prev => ({ loading: false, menu_list: response.menus.data, meta: response.meta ?? prev.meta }))
    }
  }

  getFormattedDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleString().split(",")[0]
  }

  handleDeleteClick = () => {
    this.setState({ loading: true });
    this.deletemenuApiId = apiCall({ method: configJSON.deleteMethod, endPoint: `${configJSON.menuApiEndPoint}${this.state.menu_id}`, token: true });
    this.handleCloseDeleteModal()
  }

  handleFavouriteClick = (favourite: boolean) => {
    this.setState({ loading: true, isOpen: {} })
    this.favouritemenuApiId = favourite ?
      apiCall({
        method: configJSON.deleteMethod,
        endPoint: `${configJSON.menuApiEndPoint}${this.state.menu_id}${configJSON.removeFavorite}`,
        token: true,
      })
      :
      apiCall({
        method: configJSON.putMethod,
        endPoint: `${configJSON.menuApiEndPoint}${this.state.menu_id}${configJSON.addFavorite}`,
        token: true,
      });
  }

  handleDeleteApiResponse = (resData: ResponseErrorsData) => {
    const { date: { from_date, to_date, time_range }, formData } = this.props
    if (resData.errors) {
      handleApiError(resData.errors)
    } else {
      this.getmenus(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id)
      toast.success(configJSON.deleteMenuSuccessMessage)
    }
  }

  handleFavouriteResponse = (favRes: { message: string[] }) => {
    const { date: { from_date, to_date, time_range }, formData } = this.props
    this.getmenus(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id)
    toast.success(capitalizeFirstLetter(favRes.message[0]))
  }

  handleOpenDeleteModal = () => {
    this.setState({ openDeleteModal: true })
    this.closeDropDown()
  }

  handleCloseDeleteModal = () => {
    this.setState({ openDeleteModal: false })
  }

  toggleDropdown = (menuId: string) => {
    this.setState((prevState) => {
      const updatedOpen = { ...prevState.isOpen };

      for (const keyValue in updatedOpen) {
        updatedOpen[keyValue] = false;
      }

      updatedOpen[menuId] = !prevState.isOpen[menuId];

      return {
        isOpen: updatedOpen,
        menu_id: menuId,
      };
    });
  };

  closeDropDown = () => {
    this.setState((prevState) => {
      const updatedOpen = { ...prevState.isOpen };

      for (const keyValue in updatedOpen) {
        updatedOpen[keyValue] = false;
      }

      return {
        isOpen: updatedOpen,
      };
    });
  }

  handleView = (attributes: { template_id: number }) => {
    const templateId = attributes.template_id
    this.props.navigation.history.push(`${configJSON.menuTemplateNavigation}${templateId}`, attributes)
  }

  handlePageChange = (page: number) => {
    const { date: { from_date, to_date, time_range }, formData } = this.props
    this.setState({
      meta: {
        ...this.state.meta,
        current_page: page
      }
    });
    this.getmenus(page, from_date, to_date, time_range, formData.department_id)
  };


  // Customizable Area End


}
