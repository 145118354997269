// Customizable Area Start
import React from "react";
import {
	Box,
	Button,
	Typography,
	TextField,
	InputLabel,
	Card,
	CardContent,
  FormHelperText
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import MyProfileController, { Props, configJSON } from "./MyProfileController.web";
import LoginSignupRightSection from "../../../components/src/LoginSignupRightSection.web";
import { styles } from "./EmailRegistrationStyles.web";
import LoginSignupBackgroundImgWeb from "../../../components/src/LoginSignupBackgroundImg.web";
import {
	allowNumberWithSpaceValidation,
	handleInvoiceErrors,
	handleInvoiceHelperText,
} from "../../../components/src/utils/commonFunctions";
import { defultProfileIcon } from "./assets";
import { MyProfileSchema } from "../../../components/src/utils/validationSchema";
import Loader from "../../../components/src/Loader.web";
import { toast } from "react-toastify";
import { Close } from "@material-ui/icons";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
const logo = require("../../../components/src/qwipLogo.svg");
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class MyProfile extends MyProfileController {
	constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

	render() {
    // Customizable Area Start
		const { classes } = this.props;
		const {
			profilePicture,
			owner_email,
			owner_name,
			phone_number,
			country_code_and_flag_id,
			placeHolderEmail,
			placeHolderName,
			placeHolderPhoneNumber,
			countryCode
		} = this.state;

		return (
			// Required for all blocks
			<React.Fragment>
				<Loader loading={this.state.loading} />
				<LoginSignupBackgroundImgWeb />
				<Card className={classes.card}>
					<CardContent className={`${classes.cardContentProfile} ${classes.contentHeight}`}>
						<Box>
							<div className={classes.headerLogo}>
								<img src={logo} alt="qwipLogo" />
							</div>
							<Typography variant="h4" className={classes.title2} data-test-id="profileHeader">
								{configJSON.profileHeader}
							</Typography>
							<Formik
								enableReinitialize={true}
								validateOnMount={true}
								initialValues={{
									photo: null,
									owner_email: owner_email,
									owner_name: owner_name,
									phone_number: phone_number,
									country_code_and_flag_id: country_code_and_flag_id
								}}
								validationSchema={MyProfileSchema}
								onSubmit={this.setMyProfile}
							>
								{({
									errors,
									touched,
									values,
									handleSubmit,
									handleChange,
									handleBlur,
									setFieldValue,
									isValid,
								}) => {
									return (
										<form
											onSubmit={handleSubmit}
											className={classes.form}
										>
											<Box
												className={
													classes.uploadImage
												}
											>
												<TextField
                          key={profilePicture}
													name="photo"
													variant="outlined"
													type="file"
													fullWidth
													onChange={(event: any) => {

														if (event.target.files.length > 0) {
															const file = event.target.files[0];
															const fileType = file.type;
															if (fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/jpg') {
																setFieldValue(
																	"photo",
																	event.target.files[0]
																);
																this.handleFileUpload(
																	event
																);
															} else {
																toast.error(configJSON.invalidImageFormate)
															}

														}
													}}
													InputProps={{
														inputProps: {
															accept: "image/*",
														},
													}}
												/>
												<Box className="innerContent">
													{profilePicture ? (
														<img
															src={profilePicture}
															alt="Profile Picture"
															className="filePreview"
                              data-test-id="profilePictureImage"
														/>
													) : (
														<React.Fragment>
															<img
																src={
																	defultProfileIcon
																}
																alt="defultProfileIcon"
																className="uploadImage"
                                data-test-id="uploadImg"
															/>
															<Typography
																variant="body1"
																className="uploadText"
                                data-test-id="uploadImageText"
															>
																{configJSON.uploadImageText}
															</Typography>
														</React.Fragment>
													)}
												</Box>
												{profilePicture && <Box
													className={classes.closeIcn}
                          data-test-id="remove_profile_picture"
													onClick={() => {
														setFieldValue("photo", null)
														this.handleRemoveImage()
													}
													} >
													<Close />
												</Box>}
											</Box>
											<Box
												className={
													`${classes.fieldWrapper} ${classes.profileFiledWrapper}`
												}
											>
												<InputLabel
													required
													className={
														classes.inputLabel
													}
												>
													{configJSON.profileNameLabel}
												</InputLabel>
												<TextField
													data-test-id="txtInputName"
													type="text"
													variant="outlined"
													placeholder={
														placeHolderName
													}
													name={"owner_name"}
													value={values.owner_name}
													onChange={handleChange}
													onBlur={handleBlur}
													fullWidth
													className={
														`${classes.inputField} ${classes.profileInput} marginTop`
													}
													error={handleInvoiceErrors(
														errors.owner_name,
														touched.owner_name
													)}
													helperText={handleInvoiceHelperText(
														errors.owner_name,
														touched.owner_name
													)}
												/>
											</Box>
											<Box
												className={
													`${classes.fieldWrapper} ${classes.profileFiledWrapper}`
												}
											>
												<InputLabel
													required
													className={
														classes.inputLabel
													}
												>
                          {configJSON.profilePhoneLabel}
												</InputLabel>
												<Box className={classes.phoneGroup}>
													<Box className={classes.dropdownWidth}>
														<DropdownSearch
															value={values.country_code_and_flag_id}
															onChangeValue={(event: OptionType) => this.handleChange(event, setFieldValue, "country_code_and_flag_id")}
															options={countryCode}
															onBlur={handleBlur}
															id="country_code_and_flag_id"
															isMyProfile ={true}
															placeholder={configJSON.countryCodePlaceholder}
															noOptionsMessage={configJSON.noCountryCode}
                              isSearchable={true}
														/>
													</Box>
													<TextField
														data-test-id="txtInputPhoneNumber"
														type="text"
														variant="outlined"
														placeholder={
															placeHolderPhoneNumber
														}
														value={
															values.phone_number
														}
														name={"phone_number"}
														onChange={handleChange}
														onBlur={handleBlur}
														onKeyPress={
															allowNumberWithSpaceValidation
														}
														fullWidth
														className={
															`${classes.inputField} ${classes.profileInput} marginTop ${classes.inputWidth}`
														}
                            style={{marginTop: "10px"}}
													/>
												</Box>
                        {(handleInvoiceErrors(errors.phone_number, touched.phone_number) ||
                          handleInvoiceErrors(errors.country_code_and_flag_id, touched.country_code_and_flag_id)) && (
                            <FormHelperText
                              data-test-id={"errorMessage"}
                              className={classes.errorMsg}
                            >
                              {handleInvoiceHelperText(errors.country_code_and_flag_id, touched.country_code_and_flag_id)}
                              {handleInvoiceHelperText(errors.phone_number, touched.phone_number)}
                            </FormHelperText>
                          )}
											</Box>
											<Box
												className={
													`${classes.fieldWrapper} ${classes.profileFiledWrapper}`
												}
											>
												<InputLabel
													required
													className={
														classes.inputLabel
													}
												>
                          {configJSON.profilePersonalEmailLabel}
												</InputLabel>
												<TextField
													data-test-id="txtInputEmail"
													type="email"
													variant="outlined"
													placeholder={
														placeHolderEmail
													}
													name={"owner_email"}
													value={values.owner_email}
													onChange={handleChange}
													onBlur={handleBlur}
													fullWidth
													className={
														`${classes.inputField} ${classes.profileInput} marginTop`
													}
													error={handleInvoiceErrors(
														errors.owner_email,
														touched.owner_email
													)}
													helperText={handleInvoiceHelperText(
														errors.owner_email,
														touched.owner_email
													)}
												/>
											</Box>
											<Box
												className={
													classes.btnWrapper
												}
											>
												<Button
													data-test-id={
														"btnEmailSignup"
													}
													variant="contained"
													fullWidth
													className={`${classes.btn} ${classes.profileBtn}`}
													type="submit"
													disabled={!isValid}
												>
													{configJSON.profileNextBtn}
												</Button>
											</Box>
										</form>
									);
								}}
							</Formik>
						</Box>
						<Box className={classes.imageProfileContainer} >
							<LoginSignupRightSection />
						</Box>
					</CardContent>
				</Card>
			</React.Fragment>
		);
    // Customizable Area End
	}
}
// Customizable Area Start
export default withStyles(styles)(MyProfile);
// Customizable Area End
