// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, Avatar, Divider, Button } from "@material-ui/core";
import NotificationListController, {
  Props,
  configJSON,
} from "./NotificationListController.web";
import { Wrapper } from "../../../components/src/Wrapper";
import Loader from "../../../components/src/Loader";
import { colors } from "../../../components/src/utils/commonDesign";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { toRelativeTime } from "../../../components/src/utils/commonFunctions";
// Customizable Area End

// Customizable Area Start

export const styles = {
  notificationMainContainer: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    height: "100%",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column" as const,
    overflowY: "auto" as const,
  },
  notificationContainer: {
    "& .attachment": {
      color: "#4196F0",
      cursor: "pointer",
      textDecoration: "none",
      borderBottom: "1px solid #4196F0",
      fontSize: "14px !important",
    },
    "&.unreadNotification": {
      "& h4": {
        fontWeight: 600
      },
      backgroundColor: `${colors.secondaryColor1}30`,
      "&:hover": {
        backgroundColor: `${colors.secondaryColor1}50`,
      }
    },
    cursor: "pointer",
    padding: "21px 15px",
    display: "flex",
    gap: "21px",
    alignItems: "center",
  },
  profileImageOuter: {
    backgroundColor: "white",
    height: "72px",
    width: "72px",
    minHeight: "72px",
    minWidth: "72px",
    borderRadius: "50%",
  },
  notificationText: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21.12px",
  },
  duration: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 600,
    opacity: 0.4,
    "&.markAllAsRead": {
      fontWeight: 500,
      opacity: 1,
      color: colors.secondaryColor1,
      textTransform: "none",
    }
  },
  avatar: {
    height: "100%",
    width: "100%",
    "& img": {
      objectFit: "contain",
      textAlign: "center",
    }
  },
  divider: {
    marginBottom: "20px",
    backgroundColor: `${colors.secondaryColor1}40`,
  },
  notificationFooter: {
    position: "sticky" as const,
    bottom: "-24px",
    background: colors.primaryColor1
  },
  footerBtn: {
    color: colors.secondaryColor1
  },
  noNotificationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1",
    fontSize: "1.4rem",
    color: "#020202",
    fontWeight: 600,
  },
  clickHereLink: {
    color: "#4196F0",
    cursor: "pointer",
    fontSize: "1rem",
    "&:hover" : {
      borderBottom: "1px solid #4196F0",
    }
  }
}
// Customizable Area End

export class NotificationList extends NotificationListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { loading, notificationList } = this.state;

    return (
      <>
        <Loader loading={loading} />
        <Wrapper
          data-test-id="wrapper-comp"
          divider
          bellIcn={true}
          backbtn={true}
          navigation={this.props.navigation}
          title={configJSON.headerTitle}
          homeIcn={true}
          newNotificationCount={this.state.unread_count}
          markSingleNotificationRead={this.markSingleNotificationRead}
          markAllNotificationRead={this.markAllNotificationRead}
          child={
            <Box className={classes.notificationMainContainer}>
              {Boolean(notificationList.length) ?
                <>
                  <div data-test-id="scroll_div" className={classes.innerContainer} ref={this.scrollRef}>
                    {notificationList.map(notification => (
                      <Box data-test-id="notification" className={`${classes.notificationContainer} ${!notification.is_read && "unreadNotification"}`} key={notification.id} onClick={() => this.handleReadNotification(notification)}>
                        <div className={classes.profileImageOuter}>
                          <Avatar src={notification.creator.photo} className={classes.avatar} />
                        </div>
                        <Box>
                          <Typography variant="h4" className={classes.notificationText} data-test-id="notification-title">
                            {notification.remarks}
                            {!notification.link &&
                              <>
                                {" "}
                                <Typography className={classes.clickHereLink} data-test-id="click-here-to-view-btn" component="a" onClick={() => this.handleClickHere(notification)}>
                                  {configJSON.clickToView}
                                </Typography>
                              </>
                            }
                          </Typography>
                          <Typography variant="body1" className={classes.duration}>
                            {toRelativeTime(notification.created_at)}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </div>
                  <Box className={classes.notificationFooter}>
                    <Divider className={classes.divider} />
                    <Button
                      startIcon={<DoneAllIcon />}
                      data-test-id="mark-all-as-read-btn"
                      className={`${classes.duration} markAllAsRead`}
                      onClick={this.handleMarkALlRead}
                      disabled={this.state.notificationList.length === 0}
                    >
                      {configJSON.markAllAsRead}
                    </Button>
                  </Box>
                </> :
                <Typography data-test-id="no_data_text" className={classes.noNotificationContainer} component="h5" >{configJSON.noListMessage}</Typography>
              }
            </Box>
          }
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(NotificationList);
// Customizable Area End
