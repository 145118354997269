// Customizable Area Start
import React, { RefObject } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall, convertFormData, handleApiError, handleExpiredToken,loggedUserId, checkToken, getDiffs, handleUndefinedData, sortArrayAlphabetically, isBO, userBranchId, usersDepartments } from "../../../components/src/utils/commonFunctions";
import { Priorites } from "../../../components/src/utils/constant";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import moment from "moment";
import { configJSON } from "./CreateTicketController.web";
import { FormikErrors, FormikTouched } from "formik";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: Record<string, string>

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ticketId: number;
  userId: number;
  loading: boolean;
  ticketTypes: OptionType[];
  branches: OptionType[];
  departments: OptionType[];
  employees: OptionType[];
  priorites: OptionType[];
  statusList: Lists[];
  attachment: { name: string, url: File | string } | null | undefined;
  defaultAttachment: { name: string, url: File | string } | null | undefined;
  document: { name: string, url: File | string } | null ;
  comments: Comment[] | undefined;
  apiError: string;
  attachError: string;
  formData: TicketRes;
  previousData: TicketRes;
  openStatus: boolean;
  isEdit: boolean;
  commentId: {id: number | string};
  openCommentsList: boolean;
  openEdtiComment: boolean;
  opeDeleteComment: boolean;
  openSuccess: boolean;
  isEditTitle: boolean;
  isEditDescription: boolean;
  isEditDueDate: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
// Customizable Area Start
export interface FormValues {
  title: string;
  description?: string;
  ticket_type_id: string;
  branch_id: string;
  department_id: string;
  assignee_ids: {id: string};
  due_date: string | Date;
  attachment?: {name: string ,url: File | string} | null;
  priority: string;
}
export interface TicketRes extends FormValues {
  ticket_number: string | number;
  status: string;
  issue_date: string;
  status_name: string;
  comment: string;
  document?: {name: string ,url: File | string} | null;
  feedback: string;
  rating: number | null;
  creator: {id: string | number}
  comments?: Comment[];
}
export interface Lists {
  id?: string | number;
  name: string;
  value: string;
  isDisabled?: boolean;
}
export interface Status {
  id?: string | number;
  name: string;
  text: string;
}
export interface Priorities {
  label: string;
  value: string;
}

export interface Employee {
  id: string | number;
  name: string;
  email: string;
  designation: string;
}
interface FormikErrorsType {
  title: string
  due_date: string | Date;
  ticket_type_id: string
  branch_id: string
  department_id: string
  assignee_ids: {id: string} 
  priority: string
  description: string
}

export type RenderFormProps = {
  errors: FormikErrors<Partial<FormikErrorsType>>,
  touched: FormikTouched<Partial<FormikErrorsType>>,
  handleBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
  handleChange: (field: string | React.ChangeEvent<Element>) => void,
  setFieldValue: (field: string, value: OptionType | string | MaterialUiPickersDate | File | undefined | string[] | null, shouldValidate?: boolean | undefined) => void,
  values: TicketRes
};
export interface Attachment {
  name: string
  url: string
}

export interface Comment {
  id: string
  comment: string
  created_at: string
  updated_at: string
  owner_name: string
  owner_id: number
  document?: Document
}

export interface Document {
  name: string
  url: string
}

export interface Creator {
  id: number | string
  email: string
  name: string
  designation: string
}

export interface AssigneeIds {
  id: number
  email: string
  name: string
  designation: string
}
type ValueType = OptionType | string | MaterialUiPickersDate | File | undefined | string[] | null
export interface ClearValue {
  [key: string]: string | string[]
}
// Customizable Area End

export default class EditTicketController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  anchorRef: RefObject<HTMLDivElement>;

  getTicketApiId: string = "";
  ticketTypesApiId: string = "";
  branchesApiId: string = "";
  departmentsApiId: string = "";
  employeesApiId: string = "";
  statusApiId: string = "";
  editTicketCallId: string = "";

  editCommentCallId: string = "";
  deleteCommentCallId: string = "";
  addCommentCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.anchorRef = React.createRef();
    const ticketId = Number(this.props.navigation.getParam("id"));
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    const initialValues = {
      ticket_number: "",
      title: "",
      description: "",
      priority: "",
      due_date: new Date(),
      issue_date: "",
      status: "pending",
      status_name: "",
      ticket_type_id: "",
      branch_id: "",
      department_id: "",
      assignee_ids: {id:""},
      creator: {id: ""},
      attachment: {url:"",name:""},
      comment: "",
      document: {url:"",name:""},
      feedback: "",
      rating: 0,
    }

    this.state = {
      ticketId,
      userId : Number(loggedUserId),
      loading: false,
      ticketTypes: [],
      defaultAttachment:null,
      branches: [],
      departments: [],
      employees: [],
      priorites: Priorites,
      statusList: [],
      comments: [],
      attachment: null,
      document: null,
      apiError: "",
      attachError: "",
      formData: initialValues,
      previousData: initialValues,
      openStatus: false,
      isEdit: false,
      commentId: {id: ""},
      openCommentsList: false,
      openEdtiComment: false,
      opeDeleteComment: false,
      openSuccess: false,
      isEditTitle: false,
      isEditDescription: false,
      isEditDueDate: false,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    checkToken();
    this.setState({ loading: true });
    this.getTicketDetails();
    this.ticketTypesApiId = apiCall({ method: configJSON.getApiMethodType, endPoint: configJSON.ticketTypeApiEndPoint, token: true });
    this.branchesApiId = apiCall({ method: configJSON.getApiMethodType, endPoint: configJSON.createBranchApiEndpoint, token: true });
    this.statusApiId = apiCall({ method: configJSON.getApiMethodType, endPoint: configJSON.statusListApiEndPoint, token: true });
    // Customizable Area End
  }
  // Customizable Area Start

  componentDidUpdate(prevProps: Props, previousState: S) {
    const { formData } = this.state;
    const { ticket_type_id, branch_id, department_id } = formData;
    if ((ticket_type_id && branch_id) && (formData.ticket_type_id !== previousState.formData.ticket_type_id || formData.branch_id !== previousState.formData.branch_id)) {
      this.setState({ loading: true });
      this.departmentsApiId = apiCall({ method: configJSON.getApiMethodType, endPoint: `${configJSON.deparmentListApiEndPoint}ticket_type_id=${ticket_type_id}&branch_id=${branch_id}`, token: true })
    }
    if ((branch_id && department_id) && (formData.department_id !== previousState.formData.department_id || formData.branch_id !== previousState.formData.branch_id)) {
      this.setState({ loading: true });
      this.employeesApiId = apiCall({ method: configJSON.getApiMethodType, endPoint: `${configJSON.employeeListApiEndPoint}department_id=${department_id}&branch_id=${branch_id}`, token: true })
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resJson, this.props.navigation);
      this.setState({ loading: false });
      if (apiRequestCallId && resJson) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.getTicketApiId) {
          this.getTicketResponse(resJson);
        } else if (apiRequestCallId === this.editTicketCallId) {
          this.editTicketResponse(resJson);
        } else if (apiRequestCallId === this.statusApiId) {
          this.getStatusListResponse(resJson);
        } else if (apiRequestCallId === this.ticketTypesApiId) {
          this.getTicketTypesResponse(resJson);
        } else if (apiRequestCallId === this.departmentsApiId) {
          this.getDepartmentsResponse(resJson);
        } else if (apiRequestCallId === this.employeesApiId) {
          this.getEmployeesResponse(resJson);
        } else if (apiRequestCallId === this.editCommentCallId) {
          this.editCommentResponse(resJson);
        } else if (apiRequestCallId === this.deleteCommentCallId) {
          this.deleteCommentResponse(resJson);
        }
      }
    }
    this.handleResponseForBranchListDropdown(from,message)
    this.handleResponseTicketComment(from,message)
    // Customizable Area End
  }

  getTicketDetails = () => {
    this.setState({ loading: true });
    this.getTicketApiId = apiCall({ method: configJSON.getApiMethodType, endPoint: `${configJSON.ticketListApiEndPoint}/${this.state.ticketId}`, token: true });
  }

  duplicateTicket = () => {
    this.props.navigation.history.push(`/CreateTicket`, { ...this.state.previousData, title: `${this.state.previousData.title} [Duplicate]` })
  }

  getTicketResponse = (response: {data: {attributes: TicketRes}}) => {
    const { data } = response;
    if (data) {
      const { ticket_number, title, description, priority, due_date, issue_date, status, status_name, ticket_type_id, branch_id, department_id, assignee_ids, creator, attachment, feedback, rating, comments } = data.attributes;
      const ticketDetails = {
        ticket_number,
        title,
        description,
        priority,
        due_date,
        issue_date,
        status,
        status_name,
        ticket_type_id,
        branch_id,
        department_id: handleUndefinedData(department_id, ""),
        assignee_ids: handleUndefinedData(assignee_ids?.id, ""),
        creator: creator,
        attachment: null,
        feedback: handleUndefinedData(feedback, ""),
        rating: handleUndefinedData(rating, 0),
        comment: "",
        document: null,
      }
      this.setState({
        formData: ticketDetails,
        previousData: ticketDetails,
        attachment,
        defaultAttachment: attachment,
        document: null,
        comments
      });
    }
  };

  handleClearFile = (
    setFieldValue: { (field: string, value:File, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: null): void; },
    attachmentStateKey: keyof S,
    attachErrorStateKey: keyof S,
    ) => {
    this.setState({
      [attachmentStateKey]: null,
      [attachErrorStateKey]: ""
    } as unknown as Pick<S, keyof S>)
    setFieldValue(attachmentStateKey, null)
  };
  handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: File | null) => void,
    attachmentStateKey: keyof S,
    attachErrorStateKey: keyof S,
  ): void => {
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];

      if (file) {
        const allowFile = file.size <= 5 * 1024 * 1024;

        if (allowFile) {
          this.setState({
            [attachmentStateKey]: { name: file.name, url: URL.createObjectURL(file) },
            [attachErrorStateKey]: ""
          } as unknown as Pick<S, keyof S>)
          setFieldValue(attachmentStateKey, file)
        } else {
          this.handleClearFile(setFieldValue,attachmentStateKey,attachErrorStateKey);
          this.setState({ [attachErrorStateKey]: configJSON.fileSizeError } as unknown as Pick<S, keyof S>);
        }
      }
    }
  };

  getStatusListResponse = (response: { data: Status[]; }) => {
    if (response.data) {
      const resData = response.data.map(({ text, ...item }: Status) => ({ ...item, value: text, isDisabled: false }))
      this.setState({ statusList: resData });
    }
  };

  getTicketTypesResponse = (response: { data: {issue_name: string; ticket_type_id:number}[]; }) => {
    if (response.data) {
      let ticketRes = response.data.map((item) => {
        const { issue_name, ticket_type_id } = item;
        return { value: Number(ticket_type_id), label: issue_name, data: item }
      });
      ticketRes = sortArrayAlphabetically(ticketRes, 'label');
      this.setState({ ticketTypes: ticketRes });
    }
  };

  handleResponseForBranchListDropdown = (from: string, message: Message) => {
    if (this.branchesApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.branches.data) {
        let branchDataList = !isBO() ? apiResponse.branches.data.filter((item:{id:number})=> Number(item.id) === Number(userBranchId)) : apiResponse.branches.data;
        branchDataList = branchDataList.map((item: { id: string; attributes: { location_of_point_of_sale: string, branch_name: string; }; }) => {
          return { value: Number(item.id), label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }
        });
        branchDataList = sortArrayAlphabetically(branchDataList, 'label');
        this.setState({
          branches: branchDataList
        })

      } else {
        handleApiError(apiResponse.errors);

      }


    }
  }
  getDepartmentsResponse = (response: { data: {id: string,attributes:{name: string}}[]; }) => {
    const { data } = response;
    const defaultItem = { value: "", label: configJSON.departSelect, isDisabled: true }
    if (data.length > 0) {
      const filteredIds = data.map((item: { id: string; attributes: { name: string }; }) => item.id)
      const filteredDataArr = usersDepartments.filter((item: { id: string; attributes: { name: string }; }) => filteredIds.includes(item.id))

      let finalData = isBO() ?
        data.map((item: { id: string; attributes: {name: string}; }) => {
          const { id, attributes } = item;
          return { label: attributes.name, value: Number(id) };
        }) : filteredDataArr.map((item: { attributes: { id: number; name: string; }; }) => {
          const { id, name } = item.attributes;
          return { label: name, value: Number(id) };
        })
      if (finalData.length <= 0) {
        toast.error(configJSON.departmentErrorMessage)
      }
      finalData = sortArrayAlphabetically(finalData, 'label');
      finalData.unshift(defaultItem);
      this.setState({ departments: finalData });
    } else {
      this.setState({ departments: [defaultItem] });
      toast.error(configJSON.departmentErrorMessage);
    }
  };

  getEmployeesResponse = (response: { data: {id: number;attributes:{email: string}}[]; errors: string[]; } ) => {
    if (response.data) {
      const finalData = response.data.map((item) => {
        const { id, attributes } = item;
        return { label: attributes.email, value: Number(id) };
      })
      this.setState({ employees: finalData });
    } else {
      this.setState({ employees: [] });
      handleApiError(response.errors)
    }
  };

  editCommentResponse = (response: { data: object; }) => {
    const { data } = response;
    if (data) {
      toast.success(configJSON.editCommentSuccsessMessage);
      this.getTicketDetails();
      this.handleCloseEdit();
    }
  };

  deleteCommentResponse = (response: {message: string}) => {
    const { message } = response;
    if (message) {
      toast.success(configJSON.commentDeletedMessage);
      this.getTicketDetails();
      this.handleCloseDelete();
    }
  };

  editTicketResponse = (response: {data:{attributes: TicketRes},errors: string[]}) => {
    if (response.data) {
      this.setState({ openSuccess: true });
      this.getTicketResponse(response);
      this.handleDisableEdit();
    } else {
      handleApiError(response.errors);
    }
  };

  checkCreator = () => {
    const { formData } = this.state;
    return loggedUserId === formData.creator.id;
  }

  checkDisabled = () => {
    const { isEdit } = this.state;
    return !(this.checkCreator() && isEdit);
  }

  editTicket = (values: TicketRes, { resetForm }: { resetForm: () => void }) => {
    const { formData, previousData } = this.state;

    const { title, description, priority, due_date, comment, status, ticket_type_id, branch_id, department_id, assignee_ids, feedback, rating } = previousData;
    const orriginalData = {
      status,
      title,
      description,
      ticket_type_id,
      branch_id,
      department_id,
      assignee_ids,
      priority,
      due_date: moment(due_date).format("YYYY-MM-DD"),
      ...(document ? { document } : {}),
      ...((comment || document) ? { comment } : {}),
      ...(feedback ? { feedback } : {}),
      ...(rating ? { rating } : {}),
    };


    const attrs = {
      status: formData.status,
      title: values.title,
      description: values.description,
      ticket_type_id: values.ticket_type_id,
      branch_id: values.branch_id,
      department_id: values.department_id,
      assignee_ids: values.assignee_ids,
      priority: values.priority,
      due_date: moment(values.due_date).format("YYYY-MM-DD"),
      ...(values.attachment ? { attachment: values.attachment } : this.checkEmptyAttachment()),
      ...(values.document ? { document: values.document } : {}),
      ...((values.comment || values.document) ? { comment: values.comment } : {}),
      ...(values.feedback ? { feedback: values.feedback } : {}),
      ...(values.rating ? { rating: values.rating } : {}),
    };

    let payload = getDiffs(orriginalData, attrs);
    this.editTicketApiCall(payload, { resetForm })
  }

  checkEmptyAttachment = () => {
    return this.state.attachment ? {} : { attachment: "" }
  }

  editTicketApiCall = (payload: TicketRes, { resetForm }: { resetForm: () => void }) => {
    const { ticketId } = this.state;
    if (Object.keys(payload).length) {
      this.setState({ loading: true });
      this.editTicketCallId = apiCall({
        method: configJSON.putApiMethodType,
        body: convertFormData(payload),
        endPoint: `${configJSON.ticketListApiEndPoint}/${ticketId}`,
        token: true,
      });
      resetForm();
    }
  }
  addCommentApiCall = (payload: object, { resetForm }: { resetForm: () => void }) => {
    this.setState({ loading: true });
    const { ticketId } = this.state;
    if (Object.keys(payload).length) {
      this.addCommentCallId = apiCall({
        method: configJSON.putApiMethodType,
        body: convertFormData(payload),
        endPoint: `${configJSON.ticketListApiEndPoint}/${ticketId}`,
        token: true,
      });
      resetForm();
    }
  }
  addComment = (values: TicketRes, { resetForm }: { resetForm: () => void }) => {
    const { previousData } = this.state;
    const { comment, document } = previousData;
    const currentData = {
      ...(values.document ? { document: values.document } : {}),
      ...((values.comment || values.document) ? { comment: values.comment } : {}),
    };
    const defaultData = {
      ...(document ? { document } : {}),
      ...((comment || document) ? { comment } : {}),
    };
    let payload = getDiffs(defaultData, currentData);
    this.addCommentApiCall(payload, { resetForm })
  }

  handleSelectStatus = (status: Lists) => {
    this.setState({ formData: { ...this.state.formData, status: status.value, status_name: status.name }, openStatus: false });
  }

  handleEnableEdit = () => {
    this.setState({ isEdit: true });
  }

  handleDisableEdit = () => {
    this.setState({ isEdit: false, formData: this.state.previousData,attachment:this.state.defaultAttachment, document: null, isEditTitle: false, isEditDescription: false, isEditDueDate: false });
  }

  toggleStatusModal = () => {
    this.setState({ openStatus: !this.state.openStatus });
  }
  closeStatusModal = (event: { target: Node | null; }) => {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }
    this.setState({ openStatus: false });
  }

  handleSelectChange = (event: OptionType, setFieldValue: { (field: string, value: ValueType, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, keyValue: keyof FormValues, clearValue?: ClearValue) => {
    let finalData: OptionType | string = event;
    if (finalData && typeof finalData === "object" && Array.isArray(finalData)) {
      finalData = event.map((item: OptionType) => item.value)
    } else {
      finalData = event.value
    }

    if (clearValue && (this.state.formData[keyValue] !== finalData)) {
      this.setState({ formData: { ...this.state.formData, [keyValue]: finalData, ...clearValue } });
      setFieldValue(keyValue, finalData);
      for (let item of Object.keys(clearValue)) {
        setFieldValue(item, clearValue[item]);
      }
    } else {
      this.setState({ formData: { ...this.state.formData, [keyValue]: finalData } });
      setFieldValue(keyValue, finalData);
    }
  };


  handleRetingChange = (rating: number | null, setFieldValue: { (field: string, value: number | null, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }) => {
    setFieldValue("rating", rating);
    this.setState({ formData: { ...this.state.formData, rating } });
  }

  openImageInNewTab = () => {
    const { document } = this.state;
    if (document && document.url instanceof File) {
      const itemUrl = URL.createObjectURL(document.url);
      window.open(itemUrl, '_blank');
    } else if (document && typeof document.url === 'string') {
      window.open(document.url, '_blank');
    }
  };
  
  handleOpenComments = () => {
    this.setState({ openCommentsList: true });
  };

  handleCloseComments = () => {
    this.setState({ openCommentsList: false });
  };

  handleOpenEdit = (data: {id:string}) => {
    this.setState({ commentId: data, openEdtiComment: true });
  };

  handleCloseEdit = () => {
    this.setState({ commentId: {id:""}, openEdtiComment: false });
  };

  handleEditComment = (comment: string) => {
    this.setState({ loading: true });
    this.editCommentCallId = apiCall({
      method: configJSON.putApiMethodType,
      body: convertFormData(comment),
      endPoint: `${configJSON.commentApiEndPoint}/${this.state.commentId.id}`,
      token: true,
    });
  }
  handleResponseTicketComment = (from: string, message: Message) => {
    if (this.addCommentCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      this.setState({ loading: false })
      if (apiResponse.data) {

        this.setState({ comments: apiResponse.data.attributes.comments,document:null })

      } else {
        handleApiError(apiResponse.errors);
      }
    }
  }

  handleOpenDelete = (data: {id: string}) => {
    this.setState({ commentId: data, opeDeleteComment: true });
  };

  handleCloseDelete = () => {
    this.setState({ commentId: {id:""}, opeDeleteComment: false });
  };

  handleDeleteComment = () => {
    this.setState({ loading: true });
    this.deleteCommentCallId = apiCall({ method: configJSON.deleteApiMethodType, endPoint: `${configJSON.commentApiEndPoint}/${this.state.commentId.id}`, token: true });
  }

  navigateToTicketList = () => {
    this.props.navigation.history.push("/TicketTableView");
  }

  handleCloseSuccess = () => {
    this.setState({ openSuccess: false, isEdit: false });
  };

  toggleTitle = () => {
    this.setState({ isEditTitle: !this.state.isEditTitle });
  }

  toggleDescription = () => {
    this.setState({ isEditDescription: !this.state.isEditDescription });
  }

  toggleDueDate = () => {
    this.setState({ isEditDueDate: !this.state.isEditDueDate });
  }
  getAllowedStatusList = (): Lists[] => {
    let list = [...this.state.statusList];
    if (list.length) {
      list[0].isDisabled = true;
      list[3].isDisabled = true;
      list[4].isDisabled = true;
      if (this.checkCreator()) {
        list[1].isDisabled = true;
        list[2].isDisabled = true;
        if (this.state.previousData.status === "completed") {
          list[0].isDisabled = true;
          list[3].isDisabled = false;
          list[4].isDisabled = false;
        }
      }
      else {
        list[1].isDisabled = true;
        list[2].isDisabled = true;
        if (this.state.previousData.status !== "resolve") {
          list[1].isDisabled = false;
          list[2].isDisabled = false;
        }
      }
    }
    return list;
  }
  // Customizable Area End
}
