import { getColoursFromLocal } from "./commonFunctions";

const colours: any = getColoursFromLocal();
const {primary_colour, secondary_colour, background_colour} = colours;

export const colors = {
    primaryColor1: background_colour ?? "#f1eeea", // PANTONE WARM GRAY 1 C
    primaryColor2: "#020202", // PANTONE NEUTRAL BLACK C
    secondaryColor1: primary_colour ?? "#6d827f", // gray
    secondaryColor2: secondary_colour ??"#e8e115", // light yellow
    errorText: "#ea2f2f",
    successText: "#3f9d98",
    text: "#020202",
    borderColor: "#02020266",
    urlText: "#4196F0"
}

