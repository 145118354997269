import { colors } from "../../../components/src/utils/commonDesign";

export const styles: any = {
  homeOuterBox: {
    height: "100%",
    width: "100%",
  },
  homeInnerBox: {
    overflowY: "auto",
    height: "100%",
    margin: 0,
    padding: "0 !important",
    width:"100%",
    "& .homeBody": {
      margin: 0,
      color: colors.primaryColor2,
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "21px"
    },
    "& .homeTitle": {
      color: colors.text,
      fontSize: "34px",
      fontWeight: 700,
      lineHeight: "45px",
    },
    "& .homeContainer": {
      marginTop: "3rem",
      display:"grid",
      gridTemplateColumns:"repeat(5,1fr)",
      gap: "40px",
      "& .homeBoxCard": {
        backgroundColor: "white",
        padding: "1.5rem",
        border: `3px solid white`,
        borderRadius: "32px",
        cursor: "pointer",
        "&:hover": {
          border: `3px solid ${colors.secondaryColor2} !important`,
        },
        "& .homeInnerBoxCard": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
          alignItems: "center",
          "& .imgContainer img": {
            height: "100%",
            width: "100%",
          },
          "& .titleName": {
            color: colors.text,
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "32px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100%",
            margin: 0,
            textOverflow: "ellipsis",
            textAlign: "center",
            marginTop:"3.1rem"
          },
        },
        "& .productInnerBoxCard" : {
          justifyContent: "center",
        }
      },
      
    },
    "& .createContainer": {
      marginTop: "3rem",
      display:"grid",
      gridTemplateColumns:"repeat(5,1fr)",
      gap: "40px",
      "& .homeInnerBoxCard": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        alignItems: "center",
        backgroundColor: "white",
        padding: "2rem 1.5rem",
        border: `3px solid white`,
        borderRadius: "32px",
        cursor: "pointer",
        "&:hover": {
          border: `3px solid ${colors.secondaryColor2} !important`,
        },
        "& .imgContainer": {
          height:"130px"
        },
        "& .imgContainer img": {
          height: "100%",
          width: "100%",
        },
        "& .titleName": {
          color: colors.text,
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "32px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          width: "100%",
          margin: 0,
          textOverflow: "ellipsis",
          textAlign: "center",
          marginTop:"2.1rem"
        },
      },
      
    },
  },
  ProductInfoInnerBox: {
   display : "flex",
   alignItems: "center",
   justifyContent: "center",
    "& .productInfoContainer": {
      marginTop: "0px !important",
      display:"flex",
    },
    "& .productInfoBoxCard": {
      minWidth: "270px",
      width: "413px",
      height: "413px",
    },
  },
};
