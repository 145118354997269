import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { ClassNameMap } from "@material-ui/styles";
import moment from "moment";
import { apiCall, checkToken, handleApiError, handleExpiredToken, isBO, isManager, loggedUserId, removeNullFromObj, sortArrayAlphabetically, userBranchId, usersDepartments } from "../../../components/src/utils/commonFunctions";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    // Customizable Area Start
    classes: ClassNameMap<"tabHeading" | "tabBtn" | "menuItems" | "arrowIcon" | "filterBtn" | "filterBtnTxt" | "inputField" | "dateField"
        | "dateRangeTxt" | "filterDayItem" | "filterDayItemHighlight" | "filterTxt" | "filterTxtHighlight"
        | "feedbackBtn" | "dropdownBox" | "gridContainer">;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    openMenu: null | HTMLElement;
    value: number;
    searchQuery: string;
    formData: FavouriteFormValues;
    date: FavouriteDateData;
    department_list: Lists[];
    loading: boolean;
    branch_list: Lists[];
    menuAnchorEl: null | HTMLElement;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;

    // Customizable Area End
}

// Customizable Area Start
export interface FavouriteDateData {
    from_date: null | string;
    to_date: null | string;
    time_range: null | string;
}

interface FavouriteFormValues {
    department_id: string;
    branch_id: string;
}

interface ResponseFavouriteDepartment {
    id: string;
    departments: {
        id: string;
        name: string;
    }[]
}

interface ResponseFavouriteBranchData {
    branches: {
        data: FavouriteBranchData[]
    }
}

interface Lists {
    id?: number,
    label: string;
    value: string;
}

interface FavouriteBranchData {
    id: string;
    attributes: {
        branch_name: string;
        location_of_point_of_sale: string;
    }
}

interface ResponseErrorData {
    errors: string[]
}
// Customizable Area End

export default class FavouriteController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getDepartmentCallId: string = ""
    getBranchesApiId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        const initialData = {
            branch_id: isManager() ? String(userBranchId) : "",
            department_id: "",
        }
        this.state = {
            value: 0,
            openMenu: null,
            date: { time_range: null, to_date: null, from_date: null },
            formData: initialData,
            loading: false,
            searchQuery: "",
            department_list: [],
            branch_list: [],
            menuAnchorEl: null,
        };
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const restAPIResponceMessage = getName(MessageEnum.RestAPIResponceMessage)
        if (restAPIResponceMessage === message.id) {
            let responseJsonData = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            handleExpiredToken(responseJsonData, this.props.navigation);
            this.setState({ loading: false });

            if (apiId && responseJsonData) {
                if (apiId === this.getBranchesApiId) {
                    this.handleFavouriteBranchResponse(responseJsonData);
                } else if (apiId === this.getDepartmentCallId) {
                    this.handleFavouriteDepartmentResponse(responseJsonData);
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.setState({ loading: true })
        checkToken()
        let { state } = this.props.navigation.history.location;
        if (state) {
            this.setState({ value: state });
        }
        if (isBO() || isManager()) {
            this.getFavouriteBranches()
        }
        isManager() && this.getFavouriteDepartments()
    }

    componentDidUpdate(favouritePrevProps: Props, favouritePrevState: S) {
        if (this.state.formData.branch_id !== favouritePrevState.formData.branch_id && this.state.formData.branch_id) {
            this.setState({ loading: true, department_list: [], formData: { department_id: "", branch_id: this.state.formData.branch_id } });
            this.getFavouriteDepartments()
        }
    }

    handleTabMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ menuAnchorEl: event.currentTarget });
    };

    handleTabMenuClose = () => {
        this.setState({ menuAnchorEl: null });
    };
    handleProductMenu = (value: number) => {
        if(isManager()){
            this.setState((prev) => ({ value,menuAnchorEl: null, date: { from_date: null, to_date: null, time_range: null, },formData: { branch_id: prev.formData.branch_id, department_id: "" },searchQuery:"" }));
          }else {
            this.setState({ value, menuAnchorEl: null, date: { from_date: null, to_date: null, time_range: null, },searchQuery:"",formData: { branch_id: "", department_id: "" }, });
    
          }
    };

    getFavouriteBranches = () => {
        this.getBranchesApiId = apiCall({ method: configJSON.getMethod, endPoint: configJSON.getBranchApiEndpoint, token: true });
    }

    getFavouriteDepartments = () => {
        this.getDepartmentCallId = apiCall({ method: configJSON.getMethod, endPoint: `${configJSON.getDeptApiEndpoint}${this.state.formData.branch_id}`, token: true })
    }

    handleFavouriteBranchResponse = (branchResponse: ResponseFavouriteBranchData & ResponseErrorData) => {
        if (branchResponse.branches.data) {
            const defaultItem = { value: "", label: configJSON.branchPlaceholder, isDisabled: true };
            let dataItem = branchResponse.branches.data.map(item => ({ value: item.id, label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }));
            dataItem = [defaultItem, ...dataItem]
            let filteredBranch = branchResponse.branches.data.filter((branch: { id: string; }) => branch.id === String(userBranchId)).map(item => ({ value: item.id, label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }));
            if (isBO()) {
                this.setState({
                    branch_list: dataItem
                })
            } else {
                this.setState({
                    branch_list: filteredBranch
                })
            }

        } else {
            handleApiError(branchResponse.errors);

        }
    }

    handleFavouriteDepartmentResponse = (departmentResponse: ResponseFavouriteDepartment[] & ResponseErrorData) => {
        if (departmentResponse.length > 0) {
            let finalDapartsData: Lists[] = [];
            const defaultItem = { value: "", label: configJSON.departSelect, isDisabled: true };
            if (isBO()) {
                departmentResponse.forEach((item) => {
                    item.departments.forEach((dep) => {
                        const { id: idItem, name } = dep;
                        finalDapartsData.push({ label: name, value: idItem });
                    })
                })
            } else {
                usersDepartments.forEach((dep: { attributes: { name: string; id: string } }) => {
                    finalDapartsData.push({ label: dep.attributes.name, value: dep.attributes.id });
                })
            }
            finalDapartsData = sortArrayAlphabetically(finalDapartsData, 'label');
            finalDapartsData = [defaultItem, ...finalDapartsData]
            this.setState({ department_list: finalDapartsData });
        } else if (departmentResponse.errors) {
            this.setState({ department_list: [] });
            handleApiError(departmentResponse.errors)
        } else {
            this.setState({ department_list: [] });
            toast.error(configJSON.departError)
        }
    }

    handleSelectChange = (event: OptionType, formKey: keyof FavouriteFormValues) => {
        let isFinalData: OptionType | string = event;
        if (isFinalData && typeof isFinalData === "object" && Array.isArray(isFinalData)) {
            isFinalData = event.map((formItem: OptionType) => formItem.value)
        } else {
            isFinalData = event?.value
        }
        this.setState({ formData: { ...this.state.formData, [formKey]: isFinalData } });
    };

    handleMenuClose = () => {
        this.setState({ openMenu: null });
    };

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ openMenu: event.currentTarget });
    };

    handleDayChange = (time_range: string) => {
        this.setState({ date: { time_range, from_date: null, to_date: null } })
        this.handleMenuClose()
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const target = event?.target as HTMLElement;
        if (target && !target.closest('.MuiIconButton-root')) {
            if(isManager()){
                this.setState((prev) => ({ value: newValue, date: { from_date: null, to_date: null, time_range: null, },formData: { branch_id: prev.formData.branch_id, department_id: "" },searchQuery:"" }));
              }else {
                this.setState({ value: newValue, date: { from_date: null, to_date: null, time_range: null, },searchQuery:"",formData: { branch_id: "", department_id: "" }, });
              }
        }
    };

    handleSearch = (searchQuery: string) => {
        this.setState({ searchQuery })
    }

    handleDateChange = (event: Date | null, dataKey: keyof FavouriteDateData) => {
        this.setState({
            date: {
                ...this.state.date,
                time_range: "custom",
                [dataKey]: moment(event).format("MM/DD/YYYY"),
            }
        })
        if ((this.state.date.from_date && dataKey === "to_date") || (this.state.date.to_date && dataKey === "from_date")) {
            this.handleMenuClose()
        }
    }
    handleDisable = () => {
        const { branch_id, department_id } = this.state.formData
        let allManagerData = { ...this.state.date, department_id, query: this.state.searchQuery };
        let allBOData = { ...this.state.date, branch_id, department_id, query: this.state.searchQuery };
        const dataForManager = removeNullFromObj(allManagerData)
        const dataItem = removeNullFromObj(allBOData)
        if (isBO()) {
            return (Object.keys(dataItem).length <= 0)
        } else {
            return (Object.keys(dataForManager).length <= 0)
        }
    }
    handleClearAll = () => {
        if (isBO()) {
            this.setState({
                searchQuery: "",
                formData: { branch_id: "", department_id: "" },
                date: { time_range: null, to_date: null, from_date: null }
            });
        } else {
            this.setState(prev => ({
                searchQuery: "",
                date: { time_range: null, to_date: null, from_date: null },
                formData: { branch_id: prev.formData.branch_id, department_id: "" }
            }))
        }
    }
    // Customizable Area End
}



