import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, Button, Avatar } from "@material-ui/core";
import TicketTableViewController, { Props, Ticket } from "./TicketTableViewController.web";
export const configJSON = require("./config");
import { Wrapper } from "../../../components/src/Wrapper";
import { colors } from "../../../components/src/utils/commonDesign";
import CustomRadioSelect from "../../../components/src/commonComponents/CustomRadioSelect.web";
import { filterImage, exportImage, noTicketImage, inprogress, reopen, completed, resolve, pending } from "./assets";
import { deleteIcon } from "../../OrganisationHierarchy/src/assets";
import moment from "moment";
import NoTickets from "../../../components/src/commonComponents/NoTickets.web";
import Pagination from "../../../components/src/commonComponents/Pagination.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import { commonButton } from "../../../components/src/utils/commonInputStyle";
import Loader from "../../../components/src/Loader";
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
	...commonButton,
	listContainer: {
		display: "flex",
		flexDirection: "column",
		background: colors.primaryColor1,
	},
	headerSection: {
		display: "flex",
		flexDirection: "column",
		gap: 25,
		marginBottom: "1rem",
		["& h4"]: {
			fontSize: "1.4rem",
			fontWeight: 600
		},
	},
	typeButton: {
		background: "transparent !important",
		border: "1px solid #020202",
		marginRight: 20,
		["&:hover"]: {
			background: "transparent !important",
		},
	},
	dropdownSection: {
		display: "flex",
		justifyContent: "space-between",
	},
	dropdownMargin: {
		alignItems: "end"
	},
	departSelect: {
		width: "13rem",
	},
	filterExport: {
		display: "flex",
	},
	filter: {
		marginRight: "2rem",
		width: "10rem",
	},
	exportItem: {
		width: "9rem",
	},
	createAssinBtnGroup: {
		width: "17rem",
		display: "flex",
		justifyContent: "space-between",
		"& button": {
			backgroundColor: "transparent",
			border: "none",
			cursor: "pointer",
			outline: "none",
			textTransform: "unset",
			fontSize: "1.1rem",
			color: "#7c7b79",
			fontWeight: 700,
			padding: 0,
		},
		"& .active": {
			borderBottom: "2px solid #7c7b79",
			color: "#020202",
			borderRadius: "unset",
		},
	},
	listItem: {
		margin: "0px -24px",
		background: colors.primaryColor1,
	},
	tableHeader: {
		background: `${colors.secondaryColor1}40`,
		padding: "0.7rem 24px",
		display: "grid",
		gridTemplateColumns: "repeat(8,1fr)",
		"& p": {
			fontWeight: 600,
			color: "#020202",
			fontSize: "1.1rem"
		},
	},
	listMain: {
		position: "relative",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		height: "calc(100vh - 300px)",
	},
	tableItem: {
		padding: "1.2rem 24px",
		display: "grid",
		gridTemplateColumns: "repeat(8,1fr)",
		borderBottom: "1px solid #E8E8E8",
		alignItems: "center",
		"& p": {
			fontWeight: 400,
			color: "#020202",
			paddingRight: "1rem",
			fontSize: "1rem",
		},
	},
	buttonType: {
		"& p": {
			textTransform: "capitalize",
			fontSize: "14px",
			color: "#ffffff",
			borderRadius: "10px",
			width: "72%",
			padding: "0.4rem",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			"& img": {
				marginRight: "0.6rem",
			},
		},
		"& .inprogress": {
			background: "#DD7271",
		},
		"& .completed": {
			background: "#3E9D98",
		},
		"& .pending": {
			background: "#E59430",
		},
		"& .reopen": {
			background: "#F2A5C7",
		},
		"& .resolve": {
			background: "#8DB5E1",
		},
	},
	listText: {
		padding: "1rem",
		textAlign: "center",
	},

	feedbackBtn: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		"& button": {
			padding: 0,
			borderRadius: "unset",
			textTransform: "capitalize",
			color: "#4196F0",
			borderBottom: "1px solid #4196F0",
			fontSize: "1rem",
			"&:disabled": {
				color: "#4196F040",
				borderBottom: "1px solid #4196F040",
			},
		},
	},
	recentCreated: {
		"& h4": {
			color: "#020202;",
			marginBottom: "0.7rem",
			fontSize: "1.1rem",
			fontWeight: 500
		},
	},
	avatarImage: {
		height: "1.3rem",
		borderRadius: "unset",
		width: "1.3rem",
		cursor: "pointer",
		marginRight: 8,
		"& img": {
			width: "unset",
			height: "unset"
		}
	},
	textTransform: {
		textTransform: "capitalize"
	},
	paginationContainer: {
		"& .paginationPadding": {
			padding: "1.5rem 0rem",
		}
	},
	hideButtons: {
		visibility: "hidden"
	},
	noTicketContainer: {
		top: "50%",
		position: "absolute" as const,
		textAlign: "center" as const,
	},

});
// Customizable Area End

export class TicketTableView extends TicketTableViewController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	// Customizable Area Start

	// Customizable Area End
	render() {
		// Customizable Area Start
		const { classes } = this.props;
		const {
			select_department,
			is_open_department,
			select_downloadTYpe,
			select_filterTYpe,
			is_open_download,
			is_open_filter,
			isCreatedActive,
			isAssignedActive,
			department_list,
			created_header_list,
			assign_header_list,
			ticket_list,
			meta,
			status_list,
			ticket_issue,
			loading,
			isBO,
			isManager,
			all_ticket_list
		} = this.state;
		const imageSources: Record<string, string> = {
			reopen: reopen,
			completed: completed,
			inprogress: inprogress,
			resolve: resolve,
			pending: pending,
		};
		const renderList = (item: Ticket, selectedImageSource: string, isCreatedActive: boolean) => {
			const dueDate = moment(item.attributes.due_date);
			const currentDate = moment();
			const dueDateStyle = {
				color: item.attributes.status !== "completed" && dueDate.isBefore(currentDate) ? "red" : "black",
			};
			return (
				<Box key={item.id} className={classes.tableItem}>
					<Box className={classes.feedbackBtn}>
						<Button data-test-id="ticket_number" onClick={() => this.redirectEditTicket(item.id)}>{item.attributes.ticket_number}</Button>
					</Box>
					<Typography data-test-id="title" component="p">{item.attributes.title}</Typography>
					{isCreatedActive ? (
						<>
							<Typography className={classes.textTransform} component="p">{item.attributes.assignee_ids?.name}</Typography>
							<Typography className={classes.textTransform} component="p">{item.attributes.assignee_ids?.designation}</Typography>
						</>
					) : (
						<>
							<Typography className={classes.textTransform} component="p">{item.attributes.creator?.name}</Typography>
							<Typography className={classes.textTransform} component="p">{item.attributes.creator?.designation}</Typography>
						</>
					)}
					<Typography component="p">{moment(item.attributes.issue_date).format("DD/MM/YYYY")}</Typography>
					<Typography component="p" style={dueDateStyle}>
						{item.attributes.due_date ? moment(item.attributes.due_date).format("DD/MM/YYYY") : ""}
					</Typography>
					<Box className={classes.buttonType}>
						<Typography className={`${item.attributes.status}`} component="p">
							<img src={selectedImageSource} alt={item.attributes.status} />
							{item.attributes.status_name}
						</Typography>
					</Box>
					{isCreatedActive ? (
						<Box className={classes.feedbackBtn}>
							<Button data-test-id="add_view_rating" onClick={() => this.redirectEditTicket(item.id)} disabled={item.attributes.status !== "resolve"}>
								{(item.attributes.rating || item.attributes.feedback) ? `${configJSON.viewFeedbackBtn}` : `${configJSON.addFeedbackBtn}`}
							</Button>
							<Box data-test-id="delete_icon" onClick={() => this.handleDelete(item)} >
								<Avatar
									src={deleteIcon}
									alt="delete"
									className={classes.avatarImage}
								/>
							</Box>
						</Box>
					) : (
						<Box className={classes.feedbackBtn}>
							<Button disabled={item.attributes.status !== "resolve"} onClick={() => this.redirectEditTicket(item.id)}>{configJSON.viewFeedbackBtn}</Button>
						</Box>
					)}
				</Box>
			);
		}
		const tableListView = () => {
			if (ticket_list.length > 0) {
				return (
					ticket_list.map((item) => {
						const status = item.attributes.status;
						const selectedImageSource = imageSources[status];
						return (
							renderList(item, selectedImageSource, isCreatedActive)
						);
					})
				)
			} else if (ticket_list.length === 0 && this.state.searchQuery) {
				return (
					<Box className={classes.noTicketContainer}>
						<Typography data-test-id="search_result" variant="h5">{configJSON.noSearchResult} <strong>{this.state.searchQuery}</strong>.</Typography>
					</Box>
				)
			} else {
				return (
					<NoTickets
						title={configJSON.title}
						imagePath={noTicketImage}
						content={configJSON.content}
						strongContent={configJSON.strongContent}
						endContent={configJSON.endContent}
					/>
				)
			}
		}
		return (
			<>
				<Loader loading={loading} />
				<Wrapper
					data-test-id="wrapper-comp"
					navigation={this.props.navigation}
					backHandleClick={this.handleBackClick}
					divider
					backbtn={true}
					title={configJSON.ticketTitleHeader}
					bellIcn={true}
					homeIcn={true}
					search
					querySearch={this.state.searchQuery}
					searchHandleClick={this.handleSearch}
					searchData={ticket_list}
					child={
						<Box className={classes.listContainer}>
							<Box className={classes.dropdownSection}>
								<Box className={classes.headerSection}>
									<Typography component="h4">{configJSON.ticketTitle}</Typography>
									{(isBO || isManager) && <Box className={classes.departSelect}>
										<CustomRadioSelect
											selectedValue={select_department}
											onValueSelect={this.handleSelectDepartment}
											title={configJSON.departSelect}
											toggleModal={this.toggleSelectDepartmentModal}
											isModalOpen={is_open_department}
											data={department_list}
										/>
									</Box>}
									<Box className={classes.createAssinBtnGroup}>
										<Button data-test-id="created_button" disableRipple className={isCreatedActive ? "active" : ""} onClick={() => this.handleButtonClick(1)}>
											{configJSON.createdBtn}
										</Button>
										<Button data-test-id="assign_button" disableRipple className={isAssignedActive ? "active" : ""} onClick={() => this.handleButtonClick(2)}>
											{configJSON.assignedBtn}
										</Button>
									</Box>
								</Box>
								<Box className={`${classes.headerSection} ${classes.dropdownMargin}`}>
									<Box className={`${isAssignedActive && classes.hideButtons}`}>
										{isBO && <Button onClick={this.onClickTicketType} className={classes.secondaryBtn} data-test-id="branch-btn">
											{configJSON.ticketTypeBtn}
										</Button>}
										<Button onClick={this.onClickRaiseTicket} className={classes.primaryBtn} disabled={ticket_issue.length === 0} data-test-id="branch-btn">{configJSON.ticketRaiseBtn}</Button>
									</Box>
									<Box className={classes.filterExport}>
										<Box className={classes.filter}>
											<CustomRadioSelect
												selectedValue={select_filterTYpe}
												onValueSelect={this.handleSelectFilter}
												title={configJSON.filterSelect}
												isIcon={true}
												imagePath={filterImage}
												toggleModal={this.toggleSelectFilterModal}
												isModalOpen={is_open_filter}
												data={status_list}
											/>
										</Box>
										<Box className={classes.exportItem}>
											<CustomRadioSelect
												hasButton={true}
												downloadData={all_ticket_list}
												selectedValue={select_downloadTYpe}
												onValueSelect={this.handleSelectDownload}
												title={configJSON.exportSelect}
												toggleModal={this.toggleSelectDownloadModal}
												isModalOpen={is_open_download}
												isIcon={true}
												imagePath={exportImage}
												data={configJSON.downloadType}
												onClick={this.handleExportClick}
											/>
										</Box>
									</Box>
									<Box data-test-id="clearall_button" onClick={this.handleClearAllFilter} className={classes.feedbackBtn}>
										<Button disabled={this.handleDisableClear()}>{configJSON.clearAll}</Button>
									</Box>
								</Box>
							</Box>
							{ticket_list.length > 0 && <Box className={classes.recentCreated}>
								<Typography component="h4">{isCreatedActive ? configJSON.recentlyCreatedText : configJSON.recentlyAssignedText}</Typography>
							</Box>}

							<Box id="pdf-content" className={classes.listItem}>
								<Box className={classes.tableHeader}>
									{(isAssignedActive ? assign_header_list : created_header_list).map((item, index) => (
										<Typography component="p" key={index}>
											{item}
										</Typography>
									))}
								</Box>
								<Box className={ticket_list.length === 0 ? classes.listMain : ""}>
									{tableListView()}
								</Box>
							</Box>
							<Box className={classes.paginationContainer}>
								{ticket_list.length > 0 && <Pagination meta={meta} onPageChange={this.handlePageChange} />}
							</Box>
							<ConfirmationModal
								open={this.state.open}
								onClose={this.handleClose}
								confirmDelete={this.handleDeleteTicketList}
								title={this.state.title}
								from={configJSON.confirmTicket}
							/>
						</Box>
					}
				/>
			</>
		);
		// Customizable Area End
	}
}
// Customizable Area Start
export default withStyles(styles)(TicketTableView);
// Customizable Area End
