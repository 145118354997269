import { colors } from "../../../components/src/utils/commonDesign";

export const styles: any = {
  bulkInviteWrapper: {
    width: "100%",
  },
  bulkInvieModal: {
    "& .titleContainer": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "12px",
      "& .infoContainer": {
        display: "flex",
        alignItems: "center",
        "& .MuiDialogTitle-root": {
          "& .MuiTypography-root": {
            color: colors.primaryColor2,
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "32px",
          },
        },
        "& .infoIcon": {
          height: 24,
          marginLeft: 10,
          width: 24,
          "& img": {
            height: "100%",
            cursor: "pointer",
            width: "100%",
          }
        },
      },
      "& .closeIcon": {
        marginLeft: 10,
        height: 24,
        width: 24,
        "& img": {
          height: "100%",
          width: "100%",
          cursor: "pointer",
        }
      }
    },
    "& .MuiDialog-paperWidthSm": {
      boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.24)",
      borderRadius: "16px",
      padding: "56px",
      width: "50vw",
        maxWidth: "50vw",
      "@media(max-width:1440px)": {
        width: "70vw",
        maxWidth: "70vw",
        padding: "40px",
        margin: "0 15px",
      },
      "@media(max-width:1200px)": {
        width: "80vw",
        maxWidth: "90vw",
      },
      "& .MuiDialogContent-root,& .MuiDialogActions-root, & .MuiDialogTitle-root": {
        padding: "0",
        justifyContent: "center",
      },
      "& .MuiDialogContent-root": {
        marginTop: "30px",
        "@media(max-width:1440px)": {
          marginTop: "20px",
        },
        "& .step-box": {
          marginBottom: "23px",
          "& .download-btn": {
            margin: "8px",
            width: "180px ",
            height: "34px",
            justifyContent: "center",
            "& img": {
              marginRight: "12px",
            }
          },
          "& .MuiTypography-root": {
            color: colors.text,
            fontSize: 16,
            lineHeight: "21px",
            fontWeight: 300,
            margin: "0",
            marginBottom: "5",
          },
        },
      },
      "& .MuiDialogActions-root": {
        marginTop: "50px",
        "@media(max-width:374px)": {
          flexWrap: "wrap",
        },
        "& .next-btn": {
          margin: "8px",
          width: "150px ",
        },
      },
    },
  },
  primaryBtn: {
    boxShadow: "none",
    fontWeight: 700,
    height: "56px",
    width: "auto",
    borderRadius: "10px",
    fontSize: "16px",
    lineHeight: "21px",
    color: colors.primaryColor2,
    textTransform: "capitalize",
    backgroundColor: colors.secondaryColor2,
    "&:hover": {
      backgroundColor: colors.secondaryColor2,
    },
    "@media(max-width:1200px)": {
      height: "45px"
    },
  },
  fileInput: {
    display: "block",
    position: "relative",
    width: "100%",
    marginTop: 25,
    "& .MuiFormControl-root": {
      opacity: 0,
      overflow: "hidden",
      position: "absolute",
      right: "0",
      left: "0",
      "& input": {
        cursor: "pointer",
        fontSize: "0",
      },
    },
    "& .label-block": {
      border: " 1px solid #e7e4f1",
      height: "50px",
      margin: 0,
      display: "flex",
      alignItems: "center",
      padding: "0 15px",
      transition: "all 0.3s",
      lineHeight: "50px",
      borderRadius: "6px",
      cursor: "pointer",
      justifyContent: "center",
      fontSize: "16px",
      "& .file-text": {
        color: "#969492",
        display: "block",
        marginLeft: "10px",
        lineHeight: "1.321",
        fontSize: "16px",
        fontWeight: 400,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "& img": {
        width: "25px",
        height: "25px",
        margin: "5px"
      },
    },
    "& .error-block" : {
      border: `1px solid ${colors.errorText}`,
    },
    "& .file-name": {
      color: `${colors.text} !important`,
      fontWeight: "700 !important",
    },
  },
  errorMsg: {
    marginBottom: "-20px",
    fontSize: "16px",
    marginTop: "10px",
    lineHeight: "21px",
    letterSpacing: "0.03333em",
    fontWeight: 400,
    "& .more-link" : {
      color: "blue",
      cursor: "pointer",
    }
  },
};