import React from "react";
// Customizable Area Start
import { Typography, Box, InputLabel, TextField, Button, Grid, Switch } from "@material-ui/core";
import { commonInputField, commonButton, singleValueCss, commonTemplateCss } from "../../../components/src/utils/commonInputStyle";
import Template18Controller, { Props, configJSON } from "./Template18Controller.web"
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Formik, Form } from 'formik';
import { Wrapper } from "../../../components/src/Wrapper";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import AttachFileInput from "../../../components/src/commonComponents/AttachFileInput.web";
import Loader from "../../../components/src/Loader";
import DropdownSearch from "../../../components/src/commonComponents/DropdownSearch.web";
import { EditTemplate18Schema } from "../../../components/src/utils/validationSchema";
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonButton,
  ...commonTemplateCss,
  ...commonInputField

});
// Customizable Area End

export class Template18 extends Template18Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { department_data, attachmentError, formData, loading, attachment, branch_data, isDownload, templateAction, openMenu, opeDeleteModal } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper backbtn={true} divider navigation={this.props.navigation} title={this.headerTitle()} bellIcn={true} homeIcn={true} child={
          <Box>
            <Formik
              enableReinitialize={true}
              initialValues={this.template18InitialValues()}
              validationSchema={EditTemplate18Schema}
              validateOnBlur={true}
              onSubmit={this.addEditTemplate18}
            >
              {({ errors, values, handleBlur, touched, handleChange, setFieldValue }) => (
                <Form className={`${classes.formContainer} ${isDownload && "textColor"}`} translate>
                  <Grid direction="row" alignItems="stretch" ref={this.pdfContentRef} className={classes.gridContainer} container spacing={5}>
                    <Grid item sm={5} xs={10} className={classes.gridItem}>
                      {this.isViewOnly() ?
                        <Typography variant="h4" data-test-id="title" className={`${classes.inputLabel2} title`}>
                          {values.title}
                        </Typography> :
                        <Box className={`${classes.fieldWrapper} marginTitle`}>
                          <InputLabel required className={`${classes.inputLabel2} labetText`}>
                            {configJSON.templateTitleLabel}
                          </InputLabel>
                          <TextField
                            onChange={handleChange}
                            data-test-id="title_input_field"
                            type="text"
                            error={handleInvoiceErrors(
                              errors.title,
                              touched.title,
                            )}
                            fullWidth
                            name={configJSON.templateTitleName}
                            variant="outlined"
                            value={values.title}
                            onBlur={handleBlur}
                            helperText={handleInvoiceHelperText(
                              errors.title,
                              touched.title
                            )}
                            className={classes.inputField}
                          />
                        </Box>
                      }

                    </Grid>
                    <Grid sm={7} xs={2} item className={`${classes.gridItem} actionBtn ${isDownload && "visibility"}`}>
                      {templateAction !== configJSON.create &&
                        <ActionButton
                          deleteVisible={this.allowEdit()}
                          toggleDropdown={this.toggleMenu}
                          download
                          edit={this.allowEdit()}
                          handleEdit={() => this.handleActions(configJSON.edit)}
                          isOpen={openMenu}
                          duplicate={this.allowEdit()}
                          mark_as_favourite
                          handleFavourite={this.handleFavouriteClick}
                          favourite={formData.favorite}
                          handleDuplicate={this.handleDuplicateTemplate}
                          handleDelete={this.handleDelete}
                          handleDownload={this.handleDownloadPdf}
                        />
                      }

                    </Grid>
                    <Grid item className={classes.gridItem} xs={12}>
                      <div className={classes.dropdownContainer}>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            options={branch_data}
                            value={values.branch_id}
                            singleValueCss={isDownload && singleValueCss}
                            id={configJSON.templateBranchName}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                            isDisabled={this.isViewOnly()}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            isSearchable={true}
                            placeholder={configJSON.templateBranchPlaceholder}
                            isMyProfile={true}
                            noOptionsMessage={configJSON.templateNoBranchMessage}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                            )}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                            singleValueCss={isDownload && singleValueCss}
                            id={configJSON.templateDepartmentName}
                            value={values.department_id}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id,
                            )}
                            options={department_data}
                            isSearchable={true}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            placeholder={configJSON.templateDepartmentPlaceholder}
                            noOptionsMessage={configJSON.templateNoDepartmentMessage}
                            errorText={handleInvoiceHelperText(
                              errors.department_id,
                              touched.department_id,
                            )}
                            isMyProfile={true}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid sm={6} item className={classes.gridItem}>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          name={configJSON.description1}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                          type="text"
                          helperText={handleInvoiceHelperText(
                            errors.description1,
                            touched.description1
                          )}
                          value={values.description1}
                          error={handleInvoiceErrors(
                            errors.description1,
                            touched.description1
                          )}
                          className={classes.inputField}
                          disabled={this.isViewOnly()}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                    <Grid item className={classes.gridItem} sm={6}>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel required className={classes.inputLabel}>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          name={configJSON.description2}
                          value={values.description2}
                          error={handleInvoiceErrors(
                            errors.description2,
                            touched.description2,
                          )}
                          className={classes.inputField}
                          fullWidth
                          onChange={handleChange}
                          variant="outlined"
                          type="text"
                          onBlur={handleBlur}
                          disabled={this.isViewOnly()}
                          helperText={handleInvoiceHelperText(
                            errors.description2,
                            touched.description2
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={classes.templateFooterContainer}>
                    <Box className={classes.approvalContainer}>
                      <Typography variant="h4" className={`${classes.inputLabel2} approvalText`}>
                        {configJSON.sendForApproval}
                      </Typography>
                      <Switch
                        name="send_for_approval"
                        color="primary"
                        onChange={handleChange}
                        disabled={this.state.templateAction !== configJSON.create}
                        checked={values.send_for_approval}
                      />
                    </Box>
                    <AttachFileInput
                      clearAttachment={this.clearAttachment.bind(null, setFieldValue)}
                      handleFileUpload={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(e, setFieldValue) }}
                      attachment={attachment}
                      attachmentError={attachmentError}
                      templateAction={templateAction}
                      isViewOnly={this.isViewOnly}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                  <Box className={classes.templateBtnWrapper}>
                    {!this.isViewOnly() &&
                      <Button
                        data-test-id="create-btn"
                        type="submit"
                        className={classes.primaryBtn}
                        variant="contained"
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              title={configJSON.templateDeleteTitle}
              confirmDelete={this.handleDeleteTemplate}
              open={opeDeleteModal}
              from={configJSON.procedureFrom}
              onClose={this.handleDeleteClose}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(Template18);
// Customizable Area End

