// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, checkToken, getNavigationMessage, handleApiError, handleExpiredToken } from "../../../components/src/utils/commonFunctions";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: Record<string, string>;
  openNotificationModel: boolean
  handleClose: () => void
  countValueFunc: (count: number) => void
  readSingleNotificationInList: (count: number) => void
  readAllNotificationInList: () => void
  
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  readNotifications: number;
  unReadNotifications: number;
  notification_list: NotificationList[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
interface NotificationList {
  id: number;
  created_at: string;
  title: string;
  remarks: string;
  link?: string;
  is_read: boolean;
  creator: {
    id: number;
    name: string;
    designation: string;
    email: string
    photo: string
  }
}
export interface NotificationsRespone {
  notification: NotificationList[];
  read_count: number;
  unread_count: number;
  errors?: string[];
}
// Customizable Area End

export default class NotificationsModalController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getNotificationListApiCall: string = "";
  readSingleNotificationApiCall: string = "";
  readAllNotificationApiCall: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      loading: false,
      readNotifications: 0,
      unReadNotifications: 0,
      notification_list: []
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    checkToken(this.props.navigation);
    this.getNotifications();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(responseJson, this.props.navigation);
      this.setState({ loading: false });

      if (apiCallId && responseJson) {
        if (apiCallId === this.getNotificationListApiCall) {
          this.getNotificationsResponse(responseJson);
        } else if (apiCallId === this.readSingleNotificationApiCall) {
          this.readSingleNotificationRes(responseJson);
        } else if (apiCallId === this.readAllNotificationApiCall) {
          this.readAllNotificationRes(responseJson);
        }
      }
    }
  }

  getNotifications = () => {
    this.getNotificationListApiCall = apiCall({ method: configJSON.getMethod, endPoint: `${configJSON.getNotificationEndpoint}?page=1&is_read=false`, token: true });
  }

  getNotificationsResponse = (response: NotificationsRespone) => {
    if (response.notification) {
      this.setState({
        notification_list: response.notification,
        readNotifications: response.read_count,
        unReadNotifications: response.unread_count
      });
      this.props.countValueFunc(response.unread_count)
    } else {
      handleApiError(response.errors);
    }
  }

  handleViewAllClick = () => {
    this.send(getNavigationMessage('NotificationList', this.props));
    this.props.handleClose()
  }

  handleReadNoti = (notification: NotificationList) => {
    if (!notification.is_read) {
      this.readSingleNotificationApiCall = apiCall({ method: configJSON.putMethod, endPoint: `${configJSON.getNotificationEndpoint}/${notification.id}${configJSON.isRead}`, token: true });
    }
  }

  handleClickToView = (notification: NotificationList) => {
    this.props.handleClose()
    this.props.navigation.history.push(notification.link);
  }

  readSingleNotificationRes = (responseData: { notification: NotificationList, message: string } | { errors: string[] }) => {
    if ('errors' in responseData) {
      handleApiError(responseData.errors);
    } else {
      this.getNotifications()
      this.props.readSingleNotificationInList(responseData.notification.id)
    }
  }

  handleMarkAll = () => {
    this.readAllNotificationApiCall = apiCall({ method: configJSON.putMethod, endPoint: `${configJSON.getNotificationEndpoint}${configJSON.isRead}`, token: true });
  }

  readAllNotificationRes = (responseData: { message: string } | { errors: string[] }) => {
    if ('errors' in responseData) {
      handleApiError(responseData.errors);
    } else {
      this.getNotifications();
      this.props.readAllNotificationInList()
    }
  }
  // Customizable Area End
}
