// Customizable Area Start
import { colors } from "../../../components/src/utils/commonDesign";
import { commonButton } from "../../../components/src/utils/commonInputStyle";
const styles = {
  ...commonButton,
  mainContainer:{
    height: "100%"
  },
  innerSection:{
    display: "flex", 
    justifyContent: "flex-end"
  },
  raiseButton:{
    textTransform: "capitalize" as const, 
    float: "right" as const
  },
  ticketListBox:{
    height: `calc(93% - 100px)`,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column" as const,
    gridGap: "20px",
    "& img":{
      width: "100px",
       height: "130px"
    }
  },
  noSearchResult:{
    top: "50%",
    left:"50%",
    position: "absolute" as const,
    textAlign: "center" as const,
    transform: "translate(-50%,-50%)"
  },
  ticketBox: {
    backgroundColor: "#ffffff", 
    borderRadius: "8px",
    border: "1.5px solid #969492"
  },
  buttonStyle: {
    backgroundColor: colors.secondaryColor2,
    color: colors.primaryColor2,
    fontSize: "1rem",
    fontFamily: "Switzer",
    borderRadius: "10px",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: colors.secondaryColor2,
    },
  },
  _buttonStyle: {
    color: colors.primaryColor2,
    fontSize: "1rem",
    borderRadius: "10px",
    fontFamily: "Switzer",
    fontWeight: 700,
  },
  iconBtnGroup:{
    display: "flex",
    justifyContent: "flex-end",
    "& img":{
      height: "14px",
      width: "14px"
    }
  },
  nameSection:{
    display: "flex",
    justifyContent: "space-between"
  },
  nameSectionInner:{
    display: "flex",
    flexDirection: "column" as const
  },
  selectedDepart:{
    fontWeight: 500, 
    color: colors.text
  },
  emptyListTextStyle: {
    width: "min(400px , 31%)",
    fontSize: "1rem",
    color: colors.primaryColor2,
    fontWeight: 300
  },
  issueWrapperTxt: {
    color: colors.text,
  },
  listIcn: {
    marginRight: "10px",
    minWidth: "0px"
  },
  menuWrapper: {
    borderRadius: "8px",
  },
  menuItem: {
    "&:hover": {
      backgroundColor: colors.secondaryColor2
    }
  },
  departmentWrapper: {
    height: "115px",
    overflowY: "scroll" as const,
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "5px"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
      borderRadius: "5px"
    }
  },
  smallTxt: {
    color: colors.text,
    fontSize: "14px",
    fontWeight: 500,
  },
  btnStyle: {
    paddingBottom: 0,
  }
};

export default styles
// Customizable Area End