// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./ExistingDepartmentStyles.web";
import ExistingDepartmentController, { Props, configJSON } from "./ExistingDepartmentController.web";
import Loader from "../../../components/src/Loader.web";
import Slider from "../../../components/src/commonComponents/slider.web";
import { Wrapper } from "../../../components/src/Wrapper";
import InfoModal from "../../../components/src/commonComponents/modals/InfoModal.web";
import { colors } from "../../../components/src/utils/commonDesign";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { isBO, isManager } from "../../../components/src/utils/commonFunctions";
// Customizable Area End

 // Customizable Area Start
// Customizable Area End
export class ExistingDepartment extends ExistingDepartmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
  render() {
    const { classes } = this.props;
    const { allDepartments, selectedDepartments,
      branchID, branches, openModal, isSelectedDepartments, searchAllDepartments, searchQuery, departData} = this.state;
    const namesData2 = departData.map((item) => item.label);
    const modalDescription = () => {
      const item = isSelectedDepartments
      return (
        <Box overflow={"hidden"} pb={4}>
          <Box className={classes.boxCard} >
            <div className={classes.innerBoxCard}>
              <div className="imgContainer">
                {item?.photo && <img src={item?.photo} alt={"Image"} />}
              </div>
              <Typography variant="h4" className={"depName"}>
                {item?.name}
              </Typography>
            </div>
          </Box>
          <Box pt={4}>
            <Grid container spacing={2} justifyContent={"center"} >
              {isBO() ?
                <Grid item sm={6}>
                  <Button variant="outlined" fullWidth className={classes.employeeBtn} onClick={() => this.handleViewEmployeeBtn(item)} >
                    <Box display={"flex"}>
                      <Box px={"2px"}><img src={require("../assets/employee.png")} height={"20px"} width={"20px"} /></Box>
                      <Box fontSize={"1rem"} color={colors.text}>{configJSON.inviteEmployeeText}</Box>
                    </Box>
                  </Button>
                </Grid> : (isManager() && item.name) && namesData2?.includes(item?.name) ? <Grid item sm={6}>
                  <Button variant="outlined" fullWidth className={classes.employeeBtn} onClick={() => this.handleViewEmployeeBtn(item)} >
                    <Box display={"flex"}>
                      <Box px={"2px"}><img src={require("../assets/employee.png")} height={"20px"} width={"20px"} /></Box>
                      <Box fontSize={"1rem"} color={colors.text}>{configJSON.inviteEmployeeText}</Box>
                    </Box>
                  </Button>
                </Grid>: null}
              <Grid item sm={6}>
                <Button variant="contained" fullWidth className={`${classes.primaryBtn} ${classes.employeeBtn}`} onClick={() => this.handleViewEmployee(item)} >
                  <Box display={"flex"}>
                    <Box px={"2px"}><img src={require("../assets/employee.png")} height={"20px"} width={"20px"} /></Box>
                    <Box fontSize={"1rem"} color={colors.text}>{configJSON.employeeTitle}</Box>
                  </Box>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )
    }

    const deptViewer = () => {
      if (allDepartments.length == 0 && branchID && !this.state.loading) {
        return (
          <Box className={classes.departViewer}>
            <Typography variant="h5">{configJSON.goToDepartment}</Typography>
          </Box>
        )
      } else if (!searchAllDepartments && searchQuery && !this.state.loading) {
        return (
          <Box className={classes.departViewer}>
            <Typography data-test-id="no_result" variant="h5">{configJSON.searchResult} <strong>{searchQuery}</strong>.</Typography>
          </Box>
        )
      } else {
        return (searchAllDepartments || allDepartments).map((department) => (
          <Box key={department.id}>
            <Typography variant="h4" data-test-id="department_name" className={"depName"}>
              {department.name}
            </Typography>
            <Slider items={department.departments} selectedItems={selectedDepartments} handleSelect={this.handleSelect} />
          </Box>)
        )
      }
    }
    return (
      // Required for all blocks
      <React.Fragment>
        <Loader loading={this.state.loading} />
        <InfoModal open={openModal} title={configJSON.selectedDepartText} description={modalDescription()} closeIcn handleClose={this.handleModalClose} />
        <Wrapper
          search
          searchHandleClick={this.handleSearch}
          navigation={this.props.navigation}
          divider
          data-test-id="exising-department-wrapper-test"
          backbtn
          title={configJSON.existingDepartText}
          backHandleClick={this.backClick}
          bellIcn
          homeIcn
          searchData={allDepartments}
          child={
            <Box className={classes.outerBox}>
              <Box className={classes.innerBox}>
                <div className="title-container">
                  <Typography variant="h4" data-test-id="title" className={"title1"}>
                    {configJSON.departmentText}
                  </Typography>
                  {isBO() && <Box className="righ-title-container">
                    <Box className={"fieldWrapper"}>
                      <DropdownSearch
                        options={branches}
                        value={branchID}
                        id="branch"
                        onChangeValue={(event: OptionType) => this.handleBranchChange(event)}
                        placeholder={configJSON.selectbranch}
                        noOptionsMessage="No Branch Found"
                        isSearchable={true}
                        isMyProfile={true}
                      />
                    </Box>
                    <Button
                      data-test-id="agree-btn"
                      variant="contained"
                      className={`btn2 ${classes.btn} ${classes.secondaryBtn}`}
                      onClick={this.handleManageDepartment}
                    >
                      {configJSON.manageDepartText}
                    </Button>
                  </Box>}
                </div>

                <Box className="depContainer">
                  {deptViewer()}
                </Box>
              </Box>
            </Box>
          }
        />
      </React.Fragment>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(ExistingDepartment);
// Customizable Area End
