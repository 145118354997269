// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { CreateTemplate1Schema, EditTemplate1Schema } from "../../../components/src/utils/validationSchema";
import CommonTemplateController from "./CommonTemplateController.web";
import { ClassNameMap } from "@material-ui/styles";
import { toast } from "react-toastify";
import { handleApiError, handleExpiredToken, checkToken, getDiffs, handleUndefinedData, getUserRoleID } from "../../../components/src/utils/commonFunctions";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"formContainer" | "inputField"
    | "fieldWrapper" | "textAreaField" | "textAreaInputField" | "templateBtnWrapper" | "inputLabel" | "helperText" 
    | "dateField" | "fileInput" | "primaryBtn" | "secondaryBtn" | "inputLabel2" | "approvalContainer" | "dropdownWrapper" | "imageUploadContainer" | "titleText" | "gridItem" | "templateFooterContainer" | "reverseContainer"
  >;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class Template1Controller extends CommonTemplateController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      ...this.state,
      ...this.initialState,
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidMount(): any {
    // Customizable Area Start
    checkToken();
    this.getBranchData();

    let { state } = this.props.navigation.history.location;
    if (state) {
      this.setDefaultData1(state)
    } else if(![2,3].includes(Number(getUserRoleID()))) {
      this.props.navigation.goBack();
    }
    // Customizable Area End
  }

  setDefaultData1(data1: any, action?: string) {
    const { attachment, descriptions_attributes, id, creator } = data1
    this.setState({
      id,
      attachment: attachment.url ? attachment : "",
      formData: data1,
      defaultData: data1,
      imageUrls: {
        image1: handleUndefinedData(descriptions_attributes[0]?.images[0], ""),
      },
      templateAction: action ?? configJSON.view,
      creator
    })
  }

  template1InitialValues = () => {
    const { defaultData, formData, templateAction } = this.state;
    const isCreateTemplate = templateAction === configJSON.create;
    const data = {
      title: isCreateTemplate ? defaultData.title : handleUndefinedData(formData?.title, ""),
      attachment: null,
      description1: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[0]?.description, ""),
      image1: null,
      branch_id: isCreateTemplate ? defaultData.branch_id : handleUndefinedData(formData?.branch_id, ""),
      department_id: isCreateTemplate ? defaultData.department_id : handleUndefinedData(formData?.department_id, ""),
      send_for_approval: isCreateTemplate ? defaultData.send_for_approval : handleUndefinedData(formData?.send_for_approval, false),
    }
    return data;
  }
  template1ValidationSchema = () => {
    const { templateAction } = this.state;
    return templateAction === configJSON.edit ? EditTemplate1Schema : CreateTemplate1Schema
  }

  addEditTemplate1 = (values: any) => {
    const { formData, defaultData, template_id } = this.state;

    const { title,descriptions_attributes, send_for_approval,branch_id, attachment, department_id } = defaultData;
    const { description1, image1} = values;

    const { id } = formData;

    const createDescriptionsAttr = [
      {
        description: description1,
        images: [image1]
      }
    ];

    const editDescriptionsAttr = () => {
      return [
        {
          description: description1,
          id: this.state.formData.descriptions_attributes[0].id,
          ...(image1 ? { images: [image1] } : {}),
        }
      ];

    }


    const orriginalData = {
      department_id,
      branch_id,
      send_for_approval,
      title,
      ...(attachment ? { attachment } : {}),
      descriptions_attributes
    };

    const attrs = {
      title: values.title,
      department_id: values.department_id,
      branch_id: values.branch_id,
      send_for_approval: values.send_for_approval,
      ...(values.attachment ? { attachment: values.attachment } : this.checkEmptyAttachment()),
      ...(!id ? { template_id } : {}),
      descriptions_attributes: id ? editDescriptionsAttr() : createDescriptionsAttr,
    };

    let templatePayload: any = getDiffs(orriginalData, attrs);
    this.addEditTemplateApiCall(templatePayload)
  }

  addTemplateResponse = (res: any, message: string) => {
    if (res.data) {
      if (this.state.id) {
        const dataItem = res.data.attributes
        this.setStateData(dataItem);
        this.setDefaultData1(dataItem)
      } else {
        this.setState({ ...this.initialState });
        this.navigateToListingPage()
      }
      this.handleActions(configJSON.view)
      toast.success(message);
    } else if (res.message) {
      const {defaultData} = this.state
      const dataItem = {...defaultData, favorite: !defaultData.favorite};
      this.setStateData(dataItem);
      this.setDefaultData1(dataItem)
      toast.success(message);
    } else {
      handleApiError(res.errors);
    }
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const requestApiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(responseJson, this.props.navigation);
      this.setState({ loading: false });
      if (requestApiCallId && responseJson) {
        this.setState({ loading: false });
        switch (requestApiCallId) {
          case this.createTemplateApiId:
            this.addTemplateResponse(responseJson, configJSON.procedureSuccessMessage);
            break;
          case this.duplicateTemplateApiId:
            this.addTemplateResponse(responseJson, configJSON.procedureDuplicateMessage);
            break;
          case this.deleteTemplateApiId:
            this.deleteTemplateResponse(responseJson);
            break;
          case this.branchDataApiId:
            this.getBranchesResponse(responseJson);
            break;
          case this.departmentsApiId:
            this.getDepartmentsResponse(responseJson);
            break;
          case this.favouriteTemplateApiId:
            const toastMessage = this.state.formData.favorite ? configJSON.procedureUnFavMessage : configJSON.procedureFavMessage;
            this.addTemplateResponse(responseJson, toastMessage);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
