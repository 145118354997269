import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Box, InputLabel, Button, TextField, FormHelperText } from "@material-ui/core";
import EditCompanyProfileController, { Props, configJSON } from "./EditCompanyProfileController.web"
import { Wrapper } from "../../../components/src/Wrapper";
import { commonInputField, commonButton } from "../../../components/src/utils/commonInputStyle";
import { Formik, Form } from 'formik';
import { allowHoursMinutes, allowNumberWithSpaceValidation, generateTimeValues, handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import { CompanyProfileSchema } from "../../../components/src/utils/validationSchema";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { DateRange } from '@material-ui/icons';
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import Loader from "../../../components/src/Loader";
import CustomAutoComplete from "../../../components/src/commonComponents/fields/CustomAutoComplete.web";
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonButton,
  ...commonInputField,
  formContainer: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "space-between"
  },
  headerSection: {
    position: "absolute",
    right: 0
  },
  leftContainer: {
    flexBasis: "48%"
  },
  rightContainer: {
    flexBasis: "48%"
  },
  buttonSec: {
    display: "flex",
    justifyContent: "end"
  },
  timeContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: 16
  },
  companyProfile: {
    height: "380px",
    border: "1px solid #969492",
    padding: 10,
    borderRadius: 16,
    marginTop: "5.4rem",
    "& img": {
      borderRadius: 16,
      objectFit: "none"
    },
    "& .imgText": {
      color: "#020202",
      textAlign: "center"
    },
    "&.companyProfile1": {
      "& img": {
        objectFit: "contain"
      }
    },
  },
  wrapperGap: {
    marginBottom: "1.5rem"
  },
  phoneGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  inputWidth: {
    width: "80% !important"
  },
  dropdownWidth: {
    width: "18%"
  },
  paperStyle: {
    maxHeight: "220px",
  },
  listBox: {
    padding: 0,
    maxHeight: "220px",
  },
});
// Customizable Area End

export class EditCompanyProfile extends EditCompanyProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { classes } = this.props;
    const { profileDetail, industryDetail, business_type_list, currentImage, country_code_list, loading
    } = this.state
    return (
      <>
        <Loader loading={loading} />
        <Wrapper
          navigation={this.props.navigation}
          divider
          backbtn
          title={configJSON.editCompanyProfileText}
          bellIcn
          homeIcn
          child={
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={{
                name: profileDetail.attributes.name,
                owner_name: profileDetail.attributes.owner_name,
                owner_email: profileDetail.attributes.owner_email,
                phone_number: profileDetail.attributes.phone_number,
                industry_id: profileDetail.attributes.industry?.data?.attributes.id,
                business_type_id: profileDetail.attributes.business_type?.data?.attributes.id,
                creation_date: profileDetail.attributes.creation_date,
                employee_count: profileDetail.attributes.employee_count,
                head_office: profileDetail.attributes.head_office,
                description: profileDetail.attributes.description,
                from_working_hours: profileDetail.attributes.from_working_hours,
                to_working_hours: profileDetail.attributes.to_working_hours,
                country_code_and_flag_id: profileDetail.attributes.country_code_and_flag_id,
                photo: null
              }}
              validationSchema={CompanyProfileSchema}
              onSubmit={this.handleSave}
            >
              {({ errors, touched, handleBlur, handleChange, setFieldValue, values }) => (
                <Form translate={undefined} className={classes.formContainer}>
                  <Box className={classes.leftContainer}>
                    <Box className={`${classes.fieldWrapper} ${classes.marginWrap}`}>
                      <InputLabel required className={classes.inputLabel}>
                        {configJSON.companyNameText}
                      </InputLabel>
                      <TextField
                        type="text"
                        variant="outlined"
                        placeholder={configJSON.textareaPlaceHolder}
                        name={"name"}
                        disabled
                        value={values.name}
                        onChange={handleChange}
                        fullWidth
                        className={classes.inputField}
                        error={handleInvoiceErrors(
                          errors.name,
                          touched.name
                        )}
                        helperText={handleInvoiceHelperText(
                          errors.name,
                          touched.name
                        )}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel required className={classes.inputLabel}>
                        {configJSON.ownerNameText}
                      </InputLabel>
                      <TextField
                        type="text"
                        variant="outlined"
                        placeholder={configJSON.textareaPlaceHolder}
                        name={"owner_name"}
                        value={values.owner_name}
                        onChange={handleChange}
                        fullWidth
                        className={classes.inputField}
                        error={handleInvoiceErrors(
                          errors.owner_name,
                          touched.owner_name
                        )}
                        helperText={handleInvoiceHelperText(
                          errors.owner_name,
                          touched.owner_name
                        )}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel required className={classes.inputLabel}>
                        {configJSON.ownerEmailId}
                      </InputLabel>
                      <TextField
                        type="email"
                        variant="outlined"
                        placeholder={configJSON.textareaPlaceHolder}
                        name={"owner_email"}
                        value={values.owner_email}
                        onChange={handleChange}
                        disabled
                        fullWidth
                        className={classes.inputField}
                        error={handleInvoiceErrors(
                          errors.owner_email,
                          touched.owner_email
                        )}
                        helperText={handleInvoiceHelperText(
                          errors.owner_email,
                          touched.owner_email
                        )}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel required className={classes.inputLabel}>
                        {configJSON.ownerPhoneNumber}
                      </InputLabel>
                      <Box className={classes.phoneGroup}>
                        <Box className={classes.dropdownWidth}>
                          <DropdownSearch
                            value={values.country_code_and_flag_id}
                            options={country_code_list}
                            onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "country_code_and_flag_id")}
                            onBlur={handleBlur}
                            id="country_code_and_flag_id"
                            placeholder={configJSON.countryCodePlaceholder}
                            noOptionsMessage={configJSON.noCountryCode}
                            isSearchable={true}
                            errorText={handleInvoiceHelperText(
                              errors.country_code_and_flag_id,
                              touched.country_code_and_flag_id
                            )}
                            showError={handleInvoiceErrors(
                              errors.country_code_and_flag_id,
                              touched.country_code_and_flag_id
                            )}
                          />
                        </Box>
                        <TextField
                          value={values.phone_number}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          placeholder={configJSON.textareaPlaceHolder}
                          name={"phone_number"}
                          fullWidth
                          helperText={handleInvoiceHelperText(
                            errors.phone_number,
                            touched.phone_number
                          )}
                          className={`${classes.inputField} ${classes.inputWidth}`}
                          error={handleInvoiceErrors(
                            errors.phone_number,
                            touched.phone_number
                          )}
                          onBlur={handleBlur}
                          onKeyPress={allowNumberWithSpaceValidation}
                        />
                      </Box>

                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel className={classes.inputLabel} required>
                        {configJSON.industryText}
                      </InputLabel>
                      <DropdownSearch
                        options={industryDetail}
                        value={values.industry_id}
                        onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "industry_id")}
                        onBlur={handleBlur}
                        id="industry_id"
                        isSearchable={true}
                        placeholder={configJSON.industryPlaceholder}
                        noOptionsMessage={configJSON.noBranchMessage}
                        showError={handleInvoiceErrors(
                          errors.industry_id,
                          touched.industry_id
                        )}
                        errorText={handleInvoiceHelperText(
                          errors.industry_id,
                          touched.industry_id
                        )}
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel className={classes.inputLabel} required>
                        {configJSON.typeOfBusinessText}
                      </InputLabel>
                      <DropdownSearch
                        options={business_type_list}
                        value={values.business_type_id}
                        onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "business_type_id")}
                        onBlur={handleBlur}
                        id="business_type_id"
                        isSearchable={true}
                        placeholder={configJSON.typeOfBusinessPlaceholder}
                        noOptionsMessage={configJSON.noBranchMessage}
                        showError={handleInvoiceErrors(
                          errors.business_type_id,
                          touched.business_type_id
                        )}
                        errorText={handleInvoiceHelperText(
                          errors.business_type_id,
                          touched.business_type_id
                        )}
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel required className={classes.inputLabel}>
                        {configJSON.creationData}
                      </InputLabel>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          variant="inline"
                          margin="normal"
                          fullWidth
                          className={`${classes.inputField} ${classes.dateField}`}
                          InputProps={{
                            endAdornment: <DateRange />,
                            disableUnderline: true,
                          }}
                          autoOk
                          name="due_date"
                          value={values.creation_date}
                          format="MM/dd/yyyy"
                          allowKeyboardControl={false}
                          placeholder={configJSON.textareaPlaceHolder}
                          onChange={setFieldValue.bind(null, "creation_date")}
                          onBlur={handleBlur}
                        />
                      </MuiPickersUtilsProvider>
                      <FormHelperText className="error-text">
                        {touched.creation_date && errors.creation_date}
                      </FormHelperText>
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel required className={classes.inputLabel}>
                        {configJSON.noOfEmployeeText}
                      </InputLabel>
                      <TextField
                        type="text"
                        variant="outlined"
                        placeholder={configJSON.textareaPlaceHolder}
                        name={"employee_count"}
                        value={values.employee_count}
                        onChange={handleChange}
                        fullWidth
                        className={classes.inputField}
                        error={handleInvoiceErrors(
                          errors.employee_count,
                          touched.employee_count
                        )}
                        helperText={handleInvoiceHelperText(
                          errors.employee_count,
                          touched.employee_count
                        )}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel required className={classes.inputLabel}>
                        Working Hours
                      </InputLabel>

                      <Box className={classes.timeContainer}>
                        <CustomAutoComplete
                          options={generateTimeValues()}
                          name="from_working_hours"
                          handleBlur={handleBlur}
                          value={values.from_working_hours}
                          placeholder="From"
                          classes={classes}
                          freeSolo
                          handleKeyPress={allowHoursMinutes}
                          onChange={(event: { target: { name: string } }, newValue: OptionType) => setFieldValue("from_working_hours", newValue)}
                          error={handleInvoiceErrors(
                            errors.from_working_hours,
                            touched.from_working_hours
                          )}
                        />
                        <CustomAutoComplete
                          name="to_working_hours"
                          options={generateTimeValues()}
                          onChange={(event: { target: { name: string } }, newValue: OptionType) => setFieldValue("to_working_hours", newValue)}
                          handleBlur={handleBlur}
                          value={values.to_working_hours}
                          placeholder="To"
                          handleKeyPress={allowHoursMinutes}
                          freeSolo
                          error={handleInvoiceErrors(
                            errors.to_working_hours,
                            touched.to_working_hours
                            )}
                          classes={classes}
                        />
                      </Box>
                      {(handleInvoiceErrors(errors.to_working_hours, touched.to_working_hours) ||
                        handleInvoiceErrors(errors.from_working_hours, touched.from_working_hours)) && (
                          <FormHelperText
                            data-test-id={"formToErrorMsg"}
                            className={classes.helperText}
                          >
                            {handleInvoiceHelperText(errors.from_working_hours, touched.from_working_hours)}
                            {handleInvoiceHelperText(errors.to_working_hours, touched.to_working_hours)}
                          </FormHelperText>
                        )}
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel required className={classes.inputLabel}>
                        {configJSON.headOfficeText}
                      </InputLabel>
                      <TextField
                        type="text"
                        variant="outlined"
                        placeholder={configJSON.textareaPlaceHolder}
                        name={"head_office"}
                        value={values.head_office}
                        onChange={handleChange}
                        fullWidth
                        className={classes.inputField}
                        error={handleInvoiceErrors(
                          errors.head_office,
                          touched.head_office
                        )}
                        helperText={handleInvoiceHelperText(
                          errors.head_office,
                          touched.head_office
                        )}
                        onBlur={handleBlur}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.rightContainer}>
                    <Box className={classes.buttonSec}>
                      <Button type="submit" className={classes.primaryBtn} data-test-id="save-btn" >{configJSON.saveButtonText}</Button>
                    </Box>
                    <Box className={`${classes.companyProfile} ${classes.wrapperGap} ${currentImage && "companyProfile1"}`}>
                      <ImageUploadView
                        handleImageChange={this.handleImageChange}
                        setFieldValue={setFieldValue}
                        name={"photo"}
                        currentImage={currentImage}
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel className={classes.inputLabel}>
                        {configJSON.textDescription}
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputDescription"
                        type="text"
                        variant="outlined"
                        name={"description"}
                        value={values.description}
                        placeholder={configJSON.textareaPlaceHolder}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        className={`${classes.inputField} ${classes.textAreaField
                          }`}
                      />
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          } />


      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(EditCompanyProfile);
// Customizable Area End

