// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./AddDepartmentStyles.web";
import AddDepartmentController, { Props, configJSON } from "./AddDepartmentController.web";
import Loader from "../../../components/src/Loader.web";
import Slider from "../../../components/src/commonComponents/slider.web";
import { Wrapper } from "../../../components/src/Wrapper";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class AddDepartment extends AddDepartmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
   // Customizable Area End
  }
// Customizable Area Start
  render() {
    const { classes } = this.props;
    const { allDepartments, departmentSelected, branchID, branches, searchAllDepartments, searchQuery, loading } = this.state;

    return (
      <React.Fragment>
        <Loader loading={this.state.loading} />
        <Wrapper search searchHandleClick={this.handleSearch} navigation={this.props.navigation} divider data-test-id="add-department-wrapper-test" backbtn={true} title={configJSON.departmentText} backHandleClick={this.backClick} bellIcn={true} homeIcn={true} searchData={allDepartments} child={
          <Box className={classes.outerBox}>
            <Box className={classes.innerBox}>
              <div className="title-container">
                <div>
                  <Typography data-test-id="title" variant="h4" className={"title1"}>
                    {configJSON.addDepartText}
                  </Typography>
                  <p className={"body1"} style={{ marginTop: 12 }}>
                    {configJSON.paraDepartText}
                  </p>
                </div>
                <Box className={"fieldWrapper"}>
                  <DropdownSearch
                    options={branches}
                    value={branchID}
                    id="branch"
                    onChangeValue={(event:OptionType) => this.handleSelectChange(event)}
                    placeholder={configJSON.selectbranch}
                    noOptionsMessage="No Branch Found"
                    isSearchable={true}
                    isMyProfile={true}
                  />
                </Box>
              </div>
              <Box className="depContainer">
                {(!Boolean(searchAllDepartments) && Boolean(searchQuery) && !loading) ? (
                  <Box width={"95%"} position={"absolute"} top={"50%"} textAlign={"center"}>
                    <Typography variant="h5">{configJSON.searchResult} <strong>{searchQuery}</strong>.</Typography>
                  </Box>
                )
                  :
                  ((searchAllDepartments || allDepartments)).map((department) => (
                    <Box key={department.id}>
                      <Typography data-test-id="department_name" variant="h4" className={"depName"}>
                        {department.name}
                      </Typography>
                      <Slider items={department.departments} selectedItems={departmentSelected} handleSelect={this.handleSelectDepartment} />
                    </Box>)
                  )}
              </Box>
              {!(!Boolean(searchAllDepartments) && Boolean(searchQuery) && !loading) &&
                <Box className={"btnWrapper1"}>
                  <Button
                    data-test-id="skip-btn"
                    variant="outlined"
                    className={`btn secondaryBtn`}
                    onClick={this.handleSkip}
                  >
                    {configJSON.skipButton}
                  </Button>
                  <Button
                    data-test-id="next-btn"
                    variant="contained"
                    className={`btn primaryBtn`}
                    onClick={this.chooseDepartment}
                  >
                    {configJSON.nextButton}
                  </Button>
                </Box>}
            </Box>
          </Box>
        }
        />
      </React.Fragment>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(AddDepartment);
// Customizable Area End
