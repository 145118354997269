// Customizable Area Start
import React from "react";
import { Box, Typography, Button, TextField, IconButton, Grid, Menu, MenuItem } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Close, MoreHoriz } from '@material-ui/icons';
import CompanyDocController, { Props, configJSON } from "./CompanyDocController.web";
import { Wrapper } from "../../../components/src/Wrapper";
import { colors } from "../../../components/src/utils/commonDesign";
import InfoModalWeb from "../../../components/src/commonComponents/modals/InfoModal.web";
import { uploadDocIcon, docImage, downloadIcon, deleteIcon } from "./assets";
import { commonButton } from "../../../components/src/utils/commonInputStyle";
import { isBO } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
// Customizable Area End


// Customizable Area Start
export const styles = () => createStyles({
    ...commonButton,
    mainContainer:{
        height:"100vh",
        position:"relative"
    },
    subheading: {
        fontSize: "24px",
        fontWeight: 600,
    },
    btnWidth: {
        maxWidth: "220px",
    },
    uploadDocText: {
        textAlign: "center",
    },
    lightTxt: {
        fontWeight: 400,
        width: "100%"
    },
    uploadDocUpperText: {
        fontSize: "14px !important",
        marginBottom: "0px !important",
        marginTop: "5px !important",
        color: colors.text,
    },
    uploadedDocTxt: {
        marginBottom: "0px !important",
        fontWeight: 500,
        color: colors.text,
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    uploadedDocSize: {
        marginBottom: "0px !important",
        color: colors.text,
    },
    docContainer: {
        backgroundColor: "#ffffff",
        border: "1px solid transparent",
        borderRadius: "13px",
        opacity: 0.5,
        "&:hover": {
            borderColor: colors.secondaryColor2,
            opacity: 1,
        },
    },
    docImage: {
        width: "70px",
        height: "80px",
        margin: "auto",
    },
    successFileMsg: {
        marginBottom: "0px !important",
        color: "#3F9D98 !important",
        fontSize: "14px !important",
        fontWeight: 400,
    },
    errFileMsg: {
        marginBottom: "0px !important",
        color: "#DD7271 !important",
        fontSize: "14px !important",
        fontWeight: 400,
    },
    menuImg: {
        width: "16px",
        height: "16px",
        marginRight: "10px",
        color: colors.text,
    },
    menuTxt: {
        fontSize: "14px !important",
        color: colors.text,
    },
    anchor: {
        textDecoration: "none",
        color: "black"
    },
    modalConatiner: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        "& .message":{
            width:"100%"
        }
    },
    dialogBox: {
        margin: "16px 0",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        border: `1.5px solid ${colors.secondaryColor2}`,
        width: "50%",
        borderRadius: "16px",
        padding: 16,
        cursor: "pointer"
    },
    fileSection: {
        padding: "8px 16px",
        display: "flex",
        justifyContent: "space-between",
        border: `1px solid ${colors.secondaryColor2}`,
        width: "100%",
        borderRadius: "4px",
        alignItems:"end"
    },
    fileBox:{
        width:"90%"
    },
    btnGroup:{
        display: "flex",
        justifyContent: "center",
        marginTop: 24
    },
    uploadDocButton:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    noDocList:{
        width: "95%", 
        position: "absolute",
        top: "50%",
        textAlign: "center"
    },
    searchResult:{
        width: "95%", 
        position: "absolute",
        top: "50%",
        textAlign: "center",
    },
    iconMenu:{
        display: "flex",
        justifyContent: "flex-end"
    },
    centerFlex:{
        display:"flex",
        justifyContent:"center",
    }


});

// Customizable Area End

export class CompanyDoc extends CompanyDocController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes, navigation } = this.props;

        const { openModal, file, docList, isValid, msg, errMsg, anchorEl, searchQuery, searchDocList, loading, currentDoc } = this.state
        const modalDescription = (
            <Box className={classes.modalConatiner}>
                <Box data-test-id='upload-doc-modal-upload1-btn' onClick={this.handleUploadBoxClick} className={classes.dialogBox}>
                    <img src={uploadDocIcon} alt={configJSON.uploadDocumentIcon} />
                    <Typography className={classes.uploadDocUpperText}>{configJSON.uploadDocuments}</Typography>
                    <Typography className={`${classes.uploadDocText} ${classes.uploadDocUpperText}`}>{configJSON.uploadDocText}</Typography>
                </Box>
                {file &&
                    <>
                        <Box className={classes.fileSection}>
                            <Box className={classes.fileBox}>
                                <Typography className={`${classes.uploadedDocTxt} ${classes.lightTxt}`}>{file.name}</Typography>
                                <Typography className={classes.uploadedDocSize}>{Math.floor(file.size / (1024 * 1024))}mb</Typography>
                            </Box>
                            <IconButton onClick={this.handleUploadRemove}><Close /></IconButton>
                        </Box>
                        {(errMsg || msg) && <Box data-test-id="message" className="message"><Typography className={msg ? classes.successFileMsg : classes.errFileMsg}>{msg || errMsg}</Typography></Box>}
                    </>
                }
                <Box className={classes.btnGroup}>
                    <Button data-test-id="upload-doc-modal-cancel-btn" variant="outlined" className={`${classes.secondaryBtn} ${classes.btnWidth}`} onClick={this.handleCloseModal}>
                        {configJSON.cancel}
                    </Button>
                    <Button data-test-id="upload-doc-modal-upload-btn" disabled={!isValid} className={`${classes.primaryBtn} ${classes.btnWidth}`} onClick={this.handleUpload}>
                        {configJSON.upload}
                    </Button>
                </Box>
                <TextField
                    id="upload-doc-file-input"
                    data-test-id='upload-doc-file-input'
                    type="file"
                    key={String(new Date())}
                    onChange={this.handleOnChange}
                    style={{ display: "none" }}
                />
            </Box>
        )
        return (
            <React.Fragment>
                <Loader loading={loading} />

                {openModal &&
                    <InfoModalWeb
                        open={true}
                        title={configJSON.uploadDocuments}
                        description={modalDescription}
                        closeIcn
                        handleClose={this.handleCloseModal}
                    />}
                <Wrapper
                    backbtn
                    search
                    bellIcn
                    homeIcn
                    divider
                    navigation={navigation}
                    title={configJSON.companyDoc}
                    searchHandleClick={this.handleSearch}
                    searchData={docList}
                    child={
                        <Box className={classes.mainContainer}>
                            <Box className={classes.uploadDocButton}>
                                <Typography className={classes.subheading}>{configJSON.recentlyUploadedDoc}</Typography>
                                {isBO() && <Button data-test-id="upload-doc-modal-btn" className={classes.primaryBtn} onClick={this.handleOpenModal} >{configJSON.uploadDocuments}</Button>}
                            </Box>
                            {docList?.length === 0 &&
                                <Box className={classes.noDocList}>
                                    <Typography variant="h5">{configJSON.noDocumentListTxt}</Typography>
                                </Box>
                            }
                            {(!Boolean(searchDocList) && Boolean(searchQuery) && !loading) ? (
                                <Box className={classes.searchResult}>
                                    <Typography data-test-id="no_result" variant="h5">{configJSON.noSearchResult} <strong>{searchQuery}</strong>.</Typography>
                                </Box>
                            ) :
                                <Box pt={4} pb={2}>
                                    <Grid container spacing={3}>
                                        {(searchDocList || docList)?.map(docItem => {
                                            return (
                                                
                                                    <Grid item lg={3} md={4} sm={6} xs={12} key={docItem.id}>
                                                        <Box className={classes.docContainer}>
                                                            <Box className={classes.iconMenu}>
                                                                <IconButton data-test-id='comapny-doc-horiz' onClick={(e) => this.handleMenuOpen(e, docItem)}><MoreHoriz /></IconButton>
                                                                <Menu
                                                                    anchorEl={anchorEl}
                                                                    keepMounted
                                                                    open={Boolean(anchorEl)}
                                                                    onClose={this.handleMenuClose}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                >
                                                                    <MenuItem >
                                                                        <a href={currentDoc?.url} target="_blank" className={classes.anchor} data-test-id='comapny-doc-anchor'>
                                                                            <Box  className={classes.centerFlex} onClick={this.handleMenuClose}>
                                                                                <img src={downloadIcon} className={classes.menuImg} />
                                                                                <Typography data-test-id="download_btn" className={classes.menuTxt}>{configJSON.download}</Typography>
                                                                            </Box>
                                                                        </a>
                                                                    </MenuItem>
                                                                    {isBO() && <MenuItem >
                                                                        <Box data-test-id='comapny-doc-delete'  className={classes.centerFlex} onClick={() => this.handleDeleteClick(currentDoc)}>
                                                                            <img src={deleteIcon} className={classes.menuImg} />
                                                                            <Typography className={classes.menuTxt}>{configJSON.deleteButton}</Typography>
                                                                        </Box>
                                                                    </MenuItem>}
                                                                </Menu>
                                                            </Box>
                                                            <Box className={classes.centerFlex}>
                                                                <img className={classes.docImage} src={docImage} alt="docImage" />
                                                            </Box>
                                                            <Box p={2}>
                                                                <Typography data-test-id = "doc_title" className={classes.uploadedDocTxt}>{docItem.name}</Typography>
                                                                <Typography className={classes.uploadedDocSize}>Date- {docItem.uploaded_date.split(" ")[0]}</Typography>
                                                            </Box>
                                                        </Box>

                                                    </Grid>
                                               
                                            )
                                        })
                                        }
                                    </Grid>
                                    <ConfirmationModal
                                        open={this.state.open}
                                        onClose={this.handleClose}
                                        confirmDelete={this.handleDeleteTicketList}
                                        title={this.state.title}
                                        from={configJSON.confirmDoc}
                                    />
                                </Box>}
                        </Box>
                    }
                />
            </React.Fragment>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(CompanyDoc);
// Customizable Area End


