// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase/app';
import "firebase/messaging";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import HomeScreen from "../../components/src/HomeScreen";
import { ModalContainer } from "react-router-modal";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import DisplayCarousel from "../../blocks/carouseldisplay/src/DisplayCarousel.web";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import CreditdebitCardPayments from "../../blocks/CreditdebitCardPayments/src/CreditdebitCardPayments";
import CfTabletView from "../../blocks/CfTabletView/src/CfTabletView";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import StripeGatewayApiFrontend from "../../blocks/StripeGatewayApiFrontend/src/StripeGatewayApiFrontend";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import OrganisationHierarchy from "../../blocks/OrganisationHierarchy/src/OrganisationHierarchy";
import Documentation from "../../blocks/Documentation/src/Documentation";
import Customform from "../../blocks/customform/src/Customform";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Notifications from "../../blocks/notifications/src/Notifications";
import ServiceSpecificSettingsAdmin from "../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin";
import Analytics from "../../blocks/analytics/src/Analytics";
import ProjectTaskTracking from "../../blocks/ProjectTaskTracking/src/ProjectTaskTracking";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import DownloadOptions from "../../blocks/DownloadOptions/src/DownloadOptions";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import TaskGroups from "../../blocks/TaskGroups/src/TaskGroups";
import DragDropInterface from "../../blocks/dragdropinterface/src/DragDropInterface";
import TaskList from "../../blocks/TaskList/src/TaskList";
import EmailLogin from '../../blocks/email-account-login/src/EmailLogin.web';
import EmailRegistration from '../../blocks/email-account-registration/src/EmailRegistration.web';
import LandingPage from '../../blocks/email-account-registration/src/LandingPage.web';
import MyProfile from '../../blocks/email-account-registration/src/MyProfile.web';
import CompanyProfile from '../../blocks/email-account-registration/src/CompanyProfile.web';
import TermAndConditions from '../../blocks/email-account-registration/src/TermAndConditions.web';
import AddNewBranch from "../../blocks/OrganisationHierarchy/src/AddNewBranch.web"
import Customize from '../../blocks/dashboard/src/Customize.web';
import AddDepartment from '../../blocks/OrganisationHierarchy/src/AddDepartment.web';
import ExistingDepartment from '../../blocks/OrganisationHierarchy/src/ExistingDepartment.web';
import ManualInvite from '../../blocks/OrganisationHierarchy/src/ManualInvite.web';
import HomePage from '../../blocks/dashboard/src/HomePage.web';
import CreatePage from '../../blocks/dashboard/src/CreatePage.web';
import ProductInfo from '../../blocks/categoriessubcategories/src/ProductInfo.web';
import ExistingBranch from '../../blocks/OrganisationHierarchy/src/ExistingBranch.web';
import { colors } from "../../components/src/utils/commonDesign";
import EditNewBranch from "../../blocks/OrganisationHierarchy/src/EditNewBranch.web";
import PasswordChanged from "../../blocks/forgot-password/src/PasswordChanged.web";
import TicketTypeList from "../../blocks/ticketsystem/src/TicketTypeList.web";
import TicketTableView from "../../blocks/ticketsystem/src/TicketTableView.web";
import CreateTicket from "../../blocks/ticketsystem/src/CreateTicket.web";
import EditTicket from "../../blocks/ticketsystem/src/EditTicket.web";
import ViewCompanyProfile from '../../blocks/OrganisationHierarchy/src/ViewCompanyProfile.web';
import EditMyProfile from "../../blocks/CustomisableUserProfiles/src/EditMyProfile.web";

import CompanyDoc from "../../blocks/OrganisationHierarchy/src/CompanyDoc.web";
import EditCompanyProfile from '../../blocks/OrganisationHierarchy/src/EditCompanyProfile.web';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import EditTask from "../../blocks/TaskAllocator/src/EditTask.web";
import CreateTask from "../../blocks/TaskAllocator/src/CreateTask.web"
import TaskListView from "../../blocks/TaskList/src/TaskListView.web"
import Template8 from "../../blocks/cftemplatebuilder6/src/Template8";
import Template1 from "../../blocks/cftemplatebuilder6/src/Template1";
import Template3 from "../../blocks/cftemplatebuilder6/src/Template3";
import Template4 from "../../blocks/cftemplatebuilder6/src/Template4";
import Template5 from "../../blocks/cftemplatebuilder6/src/Template5";
import Template9 from "../../blocks/cftemplatebuilder6/src/Template9";
import Template10 from "../../blocks/cftemplatebuilder6/src/Template10";
import Template11 from "../../blocks/cftemplatebuilder6/src/Template11";
import Template14 from "../../blocks/cftemplatebuilder6/src/Template14";
import Template12 from "../../blocks/cftemplatebuilder6/src/Template12";
import Template13 from "../../blocks/cftemplatebuilder6/src/Template13";
import Template16 from "../../blocks/cftemplatebuilder6/src/Template16";
import Template17 from "../../blocks/cftemplatebuilder6/src/Template17";
import Template18 from "../../blocks/cftemplatebuilder6/src/Template18";
import Template19 from "../../blocks/cftemplatebuilder6/src/Template19";
import Template20 from "../../blocks/cftemplatebuilder6/src/Template20";
import Library from "../../blocks/catalogue/src/Library.web";
import Cftemplatebuilder6 from "../../blocks/cftemplatebuilder6/src/Cftemplatebuilder6";
import ChecklistForm from "../../blocks/multipageforms/src/ChecklistForm.web";
import Form from "../../blocks/multipageforms/src/Form.web";

import ProductCarousel from "../../blocks/carouseldisplay/src/ProductCarousel.web";
import ProductTemplate1 from "../../blocks/cftemplatebuilder6/src/ProductTemplate1";
import Favourite from "../../blocks/contentmanagement/src/Favourite.web";
import ProductTemplate3 from "../../blocks/cftemplatebuilder6/src/ProductTemplate3";
import ProductTemplate5 from "../../blocks/cftemplatebuilder6/src/ProductTemplate5";
import ProductTemplate6 from "../../blocks/cftemplatebuilder6/src/ProductTemplate6";

import EmployeeDirectory from '../../blocks/accountgroups/src/EmployeeDirectory.web';
import MenuCarousel from "../../blocks/carouseldisplay/src/MenuCarousel.web";
import MenuTemplate1 from "../../blocks/cftemplatebuilder6/src/MenuTemplate1";
import MenuTemplate2 from "../../blocks/cftemplatebuilder6/src/MenuTemplate2";
import MenuTemplate3 from "../../blocks/cftemplatebuilder6/src/MenuTemplate3";
import MenuTemplate5 from "../../blocks/cftemplatebuilder6/src/MenuTemplate5";
import MenuTemplate6 from "../../blocks/cftemplatebuilder6/src/MenuTemplate6";
import UserLogsListing from "../../blocks/analytics/src/UserLogsListing.web";
import Setting from "../../blocks/Settings5/src/Setting.web";
import DepartmentEmployee from '../../blocks/accountgroups/src/DepartmentEmployee.web';
import EmployeeProfileDetails from '../../blocks/accountgroups/src/EmployeeProfileDetails.web';
import JoiningForm from "../../blocks/email-account-registration/src/JoiningForm.web"
import UserProfile from "../../blocks/email-account-registration/src/UserProfile.web"
import CompanyHierarchy from "../../blocks/OrganisationHierarchy/src/CompanyHierarchy";
import NotificationList from "../../blocks/notifications/src/NotificationList";
import { setDeviceToken } from "../../components/src/utils/commonFunctions";

const routeMap = {
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },
  TaskAllocator: {
    component: TaskAllocator,
    path: "/TaskAllocator"
  },
  OrganisationHierarchy: {
    component: OrganisationHierarchy,
    path: "/OrganisationHierarchy"
  },
  Documentation: {
    component: Documentation,
    path: "/Documentation"
  },
  Customform: {
    component: Customform,
    path: "/Customform"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  ServiceSpecificSettingsAdmin: {
    component: ServiceSpecificSettingsAdmin,
    path: "/ServiceSpecificSettingsAdmin"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  ProjectTaskTracking: {
    component: ProjectTaskTracking,
    path: "/ProjectTaskTracking"
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: "/ReviewAndApproval"
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: "/UploadMedia2"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions"
  },
  AdminConsole: {
    component: AdminConsole,
    path: "/AdminConsole"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  DownloadOptions: {
    component: DownloadOptions,
    path: "/DownloadOptions"
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: "/EmailNotifications2"
  },
  TaskGroups: {
    component: TaskGroups,
    path: "/TaskGroups"
  },
  DragDropInterface: {
    component: DragDropInterface,
    path: "/DragDropInterface"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },

  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  DisplayCarousel: {
    component: DisplayCarousel,
    path: "/DisplayCarousel",
    allowedUsers: [2,3]
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  CreditdebitCardPayments: {
    component: CreditdebitCardPayments,
    path: "/CreditdebitCardPayments"
  },
  CfTabletView: {
    component: CfTabletView,
    path: "/CfTabletView"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  AdminConsole2: {
    component: AdminConsole2,
    path: "/AdminConsole2"
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: "/RolesPermissions2"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword",
    allowedToAll : true
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP",
    allowedToAll : true
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword",
    allowedToAll : true
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  StripeGatewayApiFrontend: {
    component: StripeGatewayApiFrontend,
    path: "/StripeGatewayApiFrontend"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },

  Home: {
    component: HomePage,
    path: '/DragDropInterface',
    exact: true
  },
  Default: {
    component: HomePage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  EmailLogin: {
    component: EmailLogin,
    path: '/EmailLogin',
    allowedToAll : true
  },
  EmailRegistration: {
    component: EmailRegistration,
    path: '/EmailRegistration',
    allowedToAll : true
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage',
    allowedToAll : true
  },
  MyProfile: {
    component: MyProfile,
    path: '/MyProfile',
    allowedToAll : true
  },
  CompanyProfile: {
    component: CompanyProfile,
    path: '/CompanyProfile',
    allowedToAll : true
  },
  TermAndConditions: {
    component: TermAndConditions,
    path: '/TermAndConditions',
    allowedToAll : true
  },
  AddNewBranch: {
    component: AddNewBranch,
    path: '/AddNewBranch',
    allowedUsers: [2]
  },
  EditNewBranch: {
    component: EditNewBranch,
    path: '/edit-branch/:id',
    allowedUsers: [2,3,4,5]
  },
  Customize: {
    component: Customize,
    path: '/Customize',
    allowedUsers: [2]
  },

  AddDepartment: {
    component: AddDepartment,
    path: '/AddDepartment'
  },

  ManualInvite: {
    component: ManualInvite,
    path: '/Invite',
    allowedUsers: [2,3]
  },

  ExistingDepartment: {
    component: ExistingDepartment,
    path: '/ExistingDepartment',
    allowedUsers: [2,3,4,5]
  },
  ExistingBranch: {
    component: ExistingBranch,
    path: '/ExistingBranch',
    allowedUsers: [2,3,4,5]
  },

  HomePage: {
    component: HomePage,
    path: '/HomePage',
    exact: true,
    allowedUsers: [2,3,4,5]
  },
  CreatePage: {
    component: CreatePage,
    path: '/CreatePage',
    allowedUsers: [2,3]
  },
  ProductInfo: {
    component: ProductInfo,
    path: '/ProductInfo',
    allowedUsers: [2,3]
  },
  PasswordChanged: {
    component: PasswordChanged,
    path: '/PasswordChanged',
    exact: true,
    allowedToAll : true
  },
  TicketTypeList: {
    component: TicketTypeList,
    path: '/TicketTypeList',
    exact: true,
    allowedUsers: [2]
  },
  TicketTableView: {
    component: TicketTableView,
    path: '/TicketTableView',
    exact: true,
    allowedUsers: [2,3,4,5]
  },



  CreateTicket: {
    component: CreateTicket,
    path: '/CreateTicket',
    exact: true,
    allowedUsers: [2,3,4,5]
  },
  EditTicket: {
    component: EditTicket,
    path: '/EditTicket/:id',
    allowedUsers: [2,3,4,5]
  },
  ViewCompanyProfile: {
    component: ViewCompanyProfile,
    path: '/ViewCompanyProfile',
    allowedUsers: [2,3,4,5]
  },
  EditMyProfile: {
    component: EditMyProfile,
    path: '/EditMyProfile',
    allowedUsers: [2,3,4,5]
  },
  CompanyDoc: {
    component: CompanyDoc,
    path: '/CompanyDoc',
    allowedUsers: [2,3,4,5]
  },
  EditCompanyProfile: {
    component: EditCompanyProfile,
    path: '/EditCompanyProfile',
    allowedUsers: [2]
  },
  EditTask: {
    component: EditTask,
    path: '/EditTask/:id',
    allowedUsers: [2,3,4,5]
  },
  CreateTask: {
    component: CreateTask,
    path: '/CreateTask',
    allowedUsers: [2,3,4]
  },
  TaskListView: {
    component: TaskListView,
    path: '/TaskListView',
    allowedUsers: [2,3,4,5]
  },
  Template1: {
    component: Template1,
    path: '/Template1',
    allowedUsers: [2,3,4,5]
  },
  Template2: {
    component: Template1,
    path: '/Template2',
    allowedUsers: [2,3,4,5]
  },
  Template3: {
    component: Template3,
    path: '/Template3',
    allowedUsers: [2,3,4,5]
  },
  Template4: {
    component: Template4,
    path: '/Template4',
    allowedUsers: [2,3,4,5]
  },
  Template5: {
    component: Template5,
    path: '/Template5',
    allowedUsers: [2,3,4,5]
  },
  Template6: {
    component: Template4,
    path: '/Template6',
    allowedUsers: [2,3,4,5]
  },
  Template7: {
    component: Template5,
    path: '/Template7',
    allowedUsers: [2,3,4,5]
  },
  Template8: {
    component: Template8,
    path: '/Template8',
    allowedUsers: [2,3,4,5]
  },
  Template9: {
    component: Template9,
    path: '/Template9',
    allowedUsers: [2,3,4,5]
  },
  Template10: {
    component: Template10,
    path: '/Template10',
    allowedUsers: [2,3,4,5]
  },
  Template11: {
    component: Template11,
    path: '/Template11',
    allowedUsers: [2,3,4,5]
  },
  Template12: {
    component: Template12,
    path: '/Template12',
    allowedUsers: [2,3,4,5]
  },
  Template14: {
    component: Template14,
    path: '/Template14',
    allowedUsers: [2,3,4,5]
  },
  Template15: {
    component: Template14,
    path: '/Template15',
    allowedUsers: [2,3,4,5]
  },

  Template13: {
    component: Template13,
    path: '/Template13',
    allowedUsers: [2,3,4,5]
  },
  Template16: {
    component: Template16,
    path: '/Template16',
    allowedUsers: [2,3,4,5]
  },
  Template17: {
    component: Template17,
    path: '/Template17',
    allowedUsers: [2,3,4,5]
  },
  Template18: {
    component: Template18,
    path: '/Template18',
    allowedUsers: [2,3,4,5]
  },
  Template19: {
    component: Template19,
    path: '/Template19',
    allowedUsers: [2,3,4,5]
  },
  Template20: {
    component: Template20,
    path: '/Template20',
    allowedUsers: [2,3,4,5]
  },
  Library: {
    component: Library,
    path: '/Library',
    allowedUsers: [2,3,4,5]
  },
  Cftemplatebuilder6: {
    component: Cftemplatebuilder6,
    path: '/Cftemplatebuilder6'
  },
  ChecklistForm: {
    component: ChecklistForm,
    path: '/ChecklistForm',
    allowedUsers: [2,3,4,5]
  },
  Form: {
    component: Form,
    path: '/Form',
    allowedUsers: [2,3,4,5]
  },

  ProductCarousel: {
    component: ProductCarousel,
    path: '/ProductCarousel',
    allowedUsers: [2,3]
  },

  ProductTemplate1: {
    component: ProductTemplate1,
    path: '/ProductTemplate1',
    allowedUsers: [2,3,4,5]
  },
  Favourite: {
    component: Favourite,
    path: '/Favourite',
    allowedUsers: [2,3,4,5]
  },
  ProductTemplate2: {
    component: ProductTemplate1,
    path: '/ProductTemplate2',
    allowedUsers: [2,3,4,5]
  },
  EmployeeDirectory: {
    component: EmployeeDirectory,
    path: '/EmployeeDirectory',
    allowedUsers: [2,3,4,5]
  },
  ProductTemplate3: {
    component: ProductTemplate3,
    path: '/ProductTemplate3',
    allowedUsers: [2,3,4,5]
  },
  ProductTemplate4: {
    component: ProductTemplate3,
    path: '/ProductTemplate4',
    allowedUsers: [2,3,4,5]
  },
  ProductTemplate5: {
    component: ProductTemplate5,
    path: '/ProductTemplate5',
    allowedUsers: [2,3,4,5]
  },
  ProductTemplate6: {
    component: ProductTemplate6,
    path: '/ProductTemplate6',
    allowedUsers: [2,3,4,5]
  },
  
  MenuCarousel: {
    component: MenuCarousel,
    path: '/MenuCarousel',
    allowedUsers: [2,3]
  },
  MenuTemplate1: {
    component: MenuTemplate1,
    path: '/MenuTemplate1',
    allowedUsers: [2,3,4,5]
  },
  MenuTemplate2: {
    component: MenuTemplate2,
    path: '/MenuTemplate2',
    allowedUsers: [2,3,4,5]
  },
  MenuTemplate3: {
    component: MenuTemplate3,
    path: '/MenuTemplate3',
    allowedUsers: [2,3,4,5]
  },
  MenuTemplate4: {
    component: MenuTemplate3,
    path: '/MenuTemplate4',
    allowedUsers: [2,3,4,5]
  },
  MenuTemplate5: {
    component: MenuTemplate5,
    path: '/MenuTemplate5',
    allowedUsers: [2,3,4,5]
  },
  MenuTemplate6: {
    component: MenuTemplate6,
    path: '/MenuTemplate6',
    allowedUsers: [2,3,4,5]
  },
  UserLogsListing1: {
    component: UserLogsListing,
    path: '/UserLogsListing/my_logs/submitted_form',
    allowedUsers: [2,3,4,5],
    exact: true,
  },
  UserLogsListing2: {
    component: UserLogsListing,
    path: '/UserLogsListing/my_logs/submitted_checklist',
    allowedUsers: [2,3,4,5],
    exact: true,
  },
  UserLogsListing3: {
    component: UserLogsListing,
    path: '/UserLogsListing/user_logs/submitted_form',
    allowedUsers: [2,3,4,5],
    exact: true,
  },
  UserLogsListing4: {
    component: UserLogsListing,
    path: '/UserLogsListing/user_logs/submitted_checklist',
    allowedUsers: [2,3,4,5],
    exact: true,
  },
  Setting: {
    component: Setting,
    path: '/Setting',
    allowedUsers: [2,3,4,5]
  },
  DepartmentEmployee: {
    component: DepartmentEmployee,
    path: '/DepartmentEmployee',
    allowedUsers: [2,3,4,5],
    exact:true
  },
  EmployeeProfileDetails: {
    component: EmployeeProfileDetails,
    path: '/EmployeeProfileDetails/:id',
    allowedUsers: [2,3,4,5],
    exact:true
  },
  UserProfile: {
    component: UserProfile,
    path: '/UserProfile',
    exact:true,
    allowedToAll : true,
  },
  JoiningForm: {
    component: JoiningForm,
    path: '/JoiningForm',
    allowedToAll : true,
  },
  CompanyHierarchy: {
    component: CompanyHierarchy,
    path: '/CompanyHierarchy',
    allowedUsers: [2,3,4,5]
  },
  NotificationList: {
    component: NotificationList,
    path: '/NotificationList',
    allowedUsers: [2,3,4,5]
  },
};

const firebaseAPI = firebase.initializeApp({
  // apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  // authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  // databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  // projectId: "rnmasterapp-c11e9",
  // storageBucket: "rnmasterapp-c11e9.appspot.com",
  // messagingSenderId: "649592030497",
  // appId: "1:649592030497:web:7728bee3f2baef208daa60",
  // measurementId: "G-FYBCF3Z2W3"
  
  apiKey: "AIzaSyDNDQDi33eKg0b7zPE13tK1iJdfPHV8vZI",
  authDomain: "social-media-aac65.firebaseapp.com",
  projectId: "social-media-aac65",
  storageBucket: "social-media-aac65.appspot.com",
  messagingSenderId: "1042464662098",
  appId: "1:1042464662098:web:1a4b861ad069eb129d358b",
  measurementId: "G-GMKGW645FM"
});

export const messaging = firebase.messaging(firebaseAPI)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then(function (registration) {
        console.log('tttt firebase Service Worker registered with scope:', registration.scope);
      })
      .catch(function (error) {
        console.error('tttt firebase Service Worker registration failed:', error);
      });
  });
}

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: `"Switzer", "Bitter-VariableFont", "Helvetica", "Arial", sans-serif`,
      color: colors.text
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: { backgroundColor: `${colors.secondaryColor1} !important` },
    },
    MuiPickersDay: {
        current: { color: `inherit` },
        daySelected: { backgroundColor: `${colors.secondaryColor2} !important`, fontFamily: "Bitter-VariableFont", color: colors.text},
        day: {fontFamily: "Bitter-VariableFont", color: colors.text}
    },
    MuiRadio: {
      colorSecondary: {
        '&.Mui-checked': {
          color: `${colors.secondaryColor1} !important`,
        },
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&.Mui-checked': {
          color: `${colors.secondaryColor1} !important`,
        },
      }
    },
  },
  palette: {
    primary: {
        main: colors.secondaryColor1
    },
    secondary: {
      main: colors.secondaryColor2
    }
  },
});

export const generateFCMToken = () => {
  const messaging = firebase.messaging();
  return messaging.getToken({ vapidKey: "BBDZ5IVORKayrpqxWymqDPhX-PszQk3EMpoi9CKLfic4cXV3ecxXXYmOA8p7gB5aLLcK--v3PrTr4jYyihFvh1w"})
}

class App extends Component {

  async componentDidMount(){
    const messaging = firebase.messaging();
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      await generateFCMToken().then(async(token) => {
        console.log("FCM Token", token);
        setDeviceToken(token);
      })
      .catch(error => {
        console.log("error", error);
        setDeviceToken("");
      });
    } else {
      setDeviceToken("");
    }
  }

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <ThemeProvider theme={theme}>
        <View style={{ fontFamily: "Switzer" }}>
          {WebRoutesGenerator({ routeMap })}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            theme="light"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
          <ModalContainer />
        </View>
      </ThemeProvider>
    );
  }
}

export default App;
