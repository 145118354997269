// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { apiCall, sortArrayAlphabetically, convertAllFormData, getLastDigitsFromString, getUserRoleID, isBO, loggedUserId, userBranchId, usersDepartments, getUserBranchId, commonDownloadPdfFunc, handleValuesToDescArr } from "../../../components/src/utils/commonFunctions";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import React from "react";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  id: string | number | null;
  loading: boolean;
  attachment: any;
  attachmentError: string;
  branch_data: ListData[];
  department_data: ListData[];
  imageUrls: any;
  formData: any;
  defaultData: any;
  templateAction: string;
  template_id: number;
  openMenu: boolean;
  opeDeleteModal: boolean;
  isDownload:boolean;
  creator?: Creator;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
export interface ListData {
  id?: number,
  value: string;
  label: string;
}
interface ResponseErrorData {
  errors: string[]
}
export interface Document {
  id?: number,
  name: string;
  url: string;
  uploaded_date?: string
}

export interface Creator {
  id: number,
  name: string;
  designation: string;
  email: string
}

export interface DescriptionData {
  id: number;
  title: string;
  description: string;
  created_at: string;
  images: Document;
}

// Customizable Area End

export default class CommonTemplateController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createTemplateApiId: string = "";
  duplicateTemplateApiId: string = "";
  deleteTemplateApiId: string = "";
  favouriteTemplateApiId: string = "";
  branchDataApiId: string = "";
  departmentsApiId: string = "";
  pdfContentRef:React.RefObject<HTMLDivElement>
  initialFormData = {
    id: null,
    title: "",
    description: "",
    branch_id: "",
    department_id: "",
    attachment: null,
    colour_code: null,
    send_for_approval: false,
    images: [],
    descriptions_attributes: [],
  }

  initialState = {
    id: "",
    loading: false,
    attachment: null,
    attachmentError: "",
    template_id: getLastDigitsFromString(window.location.pathname),
    formData: this.initialFormData,
    defaultData: this.initialFormData,
    imageUrls: "",
    templateAction: configJSON.create,
    openMenu: false,
    opeDeleteModal: false,
  };

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.pdfContentRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      ...this.initialState,
      branch_data: [],
      department_data: [],
      isDownload:false
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getBranchData = () => { 
    this.branchDataApiId = apiCall({ method: configJSON.getMethod, endPoint: configJSON.getBranchesUrl, token: true });
  }
  getDepartmentData = (branch_id: number) => { 
    this.departmentsApiId = apiCall({ method: configJSON.getMethod, endPoint: `${configJSON.deparmentDataApiEndPoint}${branch_id}`, token: true })
  }

  componentDidUpdate(prevProps: Props, previousState: S): any {
    const { formData } = this.state;
    const { branch_id } = formData;
    if (branch_id && (formData.branch_id !== previousState.formData.branch_id)) {
      this.setState({ loading: true });
      this.getDepartmentData(branch_id);
    }
  }

  headerTitle = () => {
    const { templateAction, template_id } = this.state;
    let start = '';

    if (templateAction === configJSON.create) {
      start = configJSON.create;
    } else if (templateAction === configJSON.view) {
      start = configJSON.view;
    } else if (templateAction === configJSON.edit) {
      start = configJSON.edit;
    } else if (templateAction === configJSON.duplicate) {
      start = configJSON.duplicate;
    }

    return `${start} ${configJSON.headerTitle} ${template_id}`;
  };

  isViewOnly = () => {
    return this.state.templateAction === configJSON.view || [4,5].includes(Number(getUserRoleID()))
  }

  toggleMenu = () => {
    this.setState({openMenu : !this.state.openMenu})
  }

  closeMenu = () => {
    this.setState({openMenu : false})
  }

  handleFileUpload = (
    eventData: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ): void => {
    const inputFile = eventData.target;
    if (inputFile.files && inputFile.files.length > 0) {
      const file = inputFile.files[0];
      const allowedFileTypes = ["text/csv", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/pdf", "image/jpg", "image/jpeg", "image/png"];
      const fileAllow = allowedFileTypes.includes(file.type);
      if (fileAllow) {
        this.setState({
          attachmentError: "",
          attachment: { name: file.name, url: URL.createObjectURL(file) },
        });
        setFieldValue("attachment", file);
      } else {
        this.clearAttachment(setFieldValue);
        this.setState({ attachmentError: configJSON.fileTypeErrorMessage });
      }
    }
  };

  handleImageChange = (event: { target: { name: string; files: any[]; }; }, setFieldValue: any) => {
    const file = event.target.files[0];
    const name = event.target.name;
    if (file) {
      const allowedFileTypes = ["image/jpg", "image/jpeg", "image/png"];
      const fileAllow = allowedFileTypes.includes(file.type);
      if (fileAllow) {
        this.setState({
          imageUrls: {
            ...this.state.imageUrls,
            [name]: { url: URL.createObjectURL(file), name: file.name },
          }
        });
        setFieldValue(name, file);
      } else {
        toast.error(configJSON.imagerrorMessage);
      }
    }
  };
  clearAttachment = (setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: null): void; }) => {
    this.setState({
      attachment: null,
      attachmentError: "",
    });
    setFieldValue("attachment", null)
  };
  handleSelectChange = (values: any, eventData: OptionType, setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, keyData: any, clearValue?: any) => {
    this.setState({openMenu:false})
    const { formData } = this.state
    const finalData = { ...formData, ...values, 
      descriptions_attributes: handleValuesToDescArr(values, formData.descriptions_attributes, "description"),
      [keyData]: eventData.value 
    }
    if (clearValue && (formData[keyData] !== eventData.value)) {
      this.setState({ formData: { ...finalData, ...clearValue } });
      setFieldValue(keyData, eventData.value);
      for (let item of Object.keys(clearValue)) {
        setFieldValue(item, clearValue[item]);
      }
    } else {
      let finalFormData = formData;
      finalFormData[keyData] = eventData.value;
      this.setState({ formData: finalData });
      setFieldValue(keyData, eventData.value);
    }
  };
  

  getBranchesResponse = (resDataItem: any) => {
    const { data } = resDataItem.branches;
    const defaultItem = { value: "", label: configJSON.templateBranchPlaceholder, isDisabled: true }
    if (data.length > 0) {
      let finalBranchData = !isBO() ? data.filter((item: any)=> Number(item.id) === Number(userBranchId)) : data;
       finalBranchData = finalBranchData.map((item: any) => {
        const { id, attributes } = item;
        const { branch_name, location_of_point_of_sale } = attributes;
        return { label: `${branch_name} - ${location_of_point_of_sale}`, value: Number(id), data: attributes };
      })
      finalBranchData = sortArrayAlphabetically(finalBranchData, 'label');
      finalBranchData.unshift(defaultItem);
      const branch_id = getUserBranchId() || this.state.formData.branch_id;
      const formData = {...this.state.formData, branch_id}
      const defaultData = {...this.state.defaultData, branch_id}
      this.setState({ branch_data: finalBranchData, formData, defaultData });
    } else {
      this.setState({ branch_data: [] });
      toast.error("No branches found. Please add a branch first");
    }
  };

  getDepartmentsResponse = (resDataItem: any) => {
    const defaultItem = { value: "", label: configJSON.templateDepartmentPlaceholder, isDisabled: true }
    if (resDataItem.length > 0) {
      let finalData: any = [];
      isBO() ? resDataItem.map((item: any) => {
        item.departments.map((dept: any) => {
          const { id: deptId, name } = dept;
          return finalData.push({ label: name, value: Number(deptId) });
        })
      }) : usersDepartments.map((dept: { attributes: { id: number; name: string; }; }) => {
        const { id, name } = dept.attributes;
        return finalData.push({ label: name, value: Number(id) });
      })
      finalData = sortArrayAlphabetically(finalData, 'label');
      finalData.unshift(defaultItem);
      this.setState({ department_data: finalData });
    } else {
      this.setState({ department_data: [defaultItem] });
      toast.error(configJSON.templateNoDepartmentMessage);
    }
  };

  handleActions = (name: string) => {
    this.closeMenu()
    this.setState({ templateAction: name});
  }

  checkEmptyAttachment = () => {
    return this.state.attachment ? {} : { attachment: "" }
  }

  addEditTemplateApiCall = (payload: {}) => {
    const { id, formData } = this.state;
    payload = {...payload, branch_id: formData.branch_id }
    if (Object.keys(payload).length) {
      this.setState({ loading: true });
      this.createTemplateApiId = apiCall({
        method: id ? configJSON.putMethod : configJSON.exampleAPiMethod,
        body: convertAllFormData(payload, "data[attributes]"),
        endPoint: `${configJSON.templateUrl}/${id}`,
        token: true,
      });
    }
  }

	handleDownloadPdf = () => {

    this.setState({openMenu:false,isDownload:true,loading:true})
		const element = this.pdfContentRef.current;
    const handleLoading = () => {
      this.setState({loading:false,isDownload:false})
    }
    commonDownloadPdfFunc(element,handleLoading,`Procedure Template ${this.state.template_id}`,`${this.state.defaultData.title}`)
      
	};

  navigateToListingPage = () => {
    this.props.navigation.history.push(`Library`, 0);
  }
  handleDelete = () => {
    this.toggleMenu();
    this.setState({ opeDeleteModal: true });
  };
  handleFavouriteClick = () => {
    this.toggleMenu();
    const {  formData, id } = this.state;
    this.setState({ loading: true });
    this.favouriteTemplateApiId = formData.favorite ?
    apiCall({
      method: configJSON.deleteMethod,
      endPoint: `${configJSON.templateUrl}/${id}${configJSON.removeFavorite}`,
      token: true,
    })
    :
    apiCall({
      method: configJSON.putMethod,
      endPoint: `${configJSON.templateUrl}/${id}${configJSON.addFavorite}`,
      token: true,
    });
}
  handleDeleteClose = () => {
    this.setState({ opeDeleteModal: false });
  };
  handleDeleteTemplate = () => {
    this.setState({ loading: true });
    this.deleteTemplateApiId = apiCall({ method: configJSON.deleteMethod, endPoint: `${configJSON.templateUrl}/${this.state.id}`, token: true });
  }
  deleteTemplateResponse = (resDataItem: { procedure: string[]; }) => {
    const { procedure } = resDataItem;
    if (procedure.length > 0) {
      toast.success(configJSON.procedureDeleteSuccessMessage);
      this.handleDeleteClose();
      this.navigateToListingPage()
    }
  };

  handleDuplicateTemplate = () => {
    this.setState({ loading: true });
    this.duplicateTemplateApiId = apiCall({ method: configJSON.exampleAPiMethod, endPoint: `${configJSON.templateUrl}/${this.state.id}/duplicate`, token: true });
  }

  setStateData = (response: object) => {
    const { template_id } = this.state;
    this.props.navigation.history.push(`${configJSON.TemplateNavigation}${template_id}`, response)
  }

  allowEdit = () => {
    return !!((this.state.creator?.id === loggedUserId) || isBO());
  }

  // Customizable Area End
  // Customizable Area End
}
