import React from "react";
// Customizable Area Start
import { Typography, Box, InputLabel, TextField, Button, Grid, FormHelperText, InputAdornment, Switch } from "@material-ui/core";
import ProductTemplate5Controller, { Props, configJSON } from "./ProductTemplate5Controller.web"
import { commonInputField, commonButton, helperText, singleValueCss, commonTemplateCss } from "../../../components/src/utils/commonInputStyle";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Formik, Form } from 'formik';
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import { Wrapper } from "../../../components/src/Wrapper";
import { allowOnlyPrice, handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";

// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonTemplateCss,
  ...commonButton,
  ...commonInputField

});
// Customizable Area End

export class ProductTemplate5 extends ProductTemplate5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { productData, loading, branchData, productTempAction, categoryData, imageUrl, departmentData, openMenu, opeDeleteModal, isDownload } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper divider backbtn={true} bellIcn navigation={this.props.navigation} title={this.headerTitle()} homeIcn={true} child={
          <Box>
            <Formik
              enableReinitialize={true}
              validationSchema={this.productTempValidationSchema()}
              validateOnBlur={true}
              initialValues={this.productTempInitialValues()}
              onSubmit={this.addEditTemplate}
            >
              {({ touched, handleBlur, handleChange, setFieldValue, values, errors }) => (
                <Form className={`${classes.formContainer} ${isDownload && "textColor"} removePadding `} translate>
                  <div className={`${classes.actionBtnContainer} ${isDownload && "visibility"}`}>
                    {productTempAction !== configJSON.create &&
                      <ActionButton
                        deleteVisible={this.allowProductEdit()}
                        edit={this.allowProductEdit()}
                        handleDownload={this.downloadProductTemp}
                        download
                        handleEdit={() => this.handleActions(configJSON.edit)}
                        toggleDropdown={this.toggleMenu}
                        handleDelete={this.handleDelete}
                        duplicate={this.allowProductEdit()}
                        isOpen={openMenu}
                        mark_as_favourite
                        favourite={productData.favorite}
                        handleFavourite={this.handleProductFavouriteClick}
                        handleDuplicate={this.handleDuplicateProductTemp}
                      />
                    }
                  </div>
                  <Grid ref={this.pdfRef} alignItems="stretch" direction="row" className={classes.gridContainer} container spacing={5}>
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      {this.isViewOnly() ?
                        <Typography data-test-id="title" className={`title ${classes.inputLabel2}`} variant="h4">
                          {values.name}
                        </Typography> :
                        <Box className={`${classes.fieldWrapper} marginTitle`}>
                          <InputLabel className={`${classes.inputLabel}`} required>
                            {configJSON.productTempNameLabel}
                          </InputLabel>
                          <TextField
                            name={configJSON.productTempNameName}
                            data-test-id="title_input_field"
                            variant="outlined"
                            type="text"
                            fullWidth
                            value={values.name}
                            className={classes.inputField}
                            error={handleInvoiceErrors(
                              errors.name,
                              touched.name,
                            )}
                            onBlur={handleBlur}
                            helperText={handleInvoiceHelperText(
                              errors.name,
                              touched.name
                            )}
                            onChange={handleChange}
                          />
                        </Box>
                      }
                      <div className={classes.dropdownContainer}>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            value={values.branch_id}
                            singleValueCss={isDownload && singleValueCss}
                            id={configJSON.templateBranchName}
                            noOptionsMessage={configJSON.templateNoBranchMessage}
                            isSearchable={true}
                            onChangeValue={(event: OptionType) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            options={branchData}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            isDisabled={this.isViewOnly()}
                            placeholder={configJSON.templateBranchPlaceholder}
                            isMyProfile={true}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            value={values.department_id}
                            onChangeValue={(event: OptionType) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                            singleValueCss={isDownload && singleValueCss}
                            noOptionsMessage={configJSON.templateNoDepartmentMessage}
                            options={departmentData}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            errorText={handleInvoiceHelperText(
                              errors.department_id,
                              touched.department_id,
                            )}
                            isMyProfile={true}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id,
                            )}
                            placeholder={configJSON.templateDepartmentPlaceholder}
                            id={configJSON.templateDepartmentName}
                            isSearchable={true}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <Box className={`${classes.fieldWrapper} marginTitle`}>
                        <InputLabel required className={classes.inputLabel}>
                          {configJSON.productTempCategoryLabel}
                        </InputLabel>
                        <DropdownSearch
                          value={values.product_info_type_id}
                          onChangeValue={(event: OptionType) => this.handleSelectChange(values, event, setFieldValue, "product_info_type_id")}
                          noOptionsMessage={configJSON.productTempNoCategoryMessage}
                          options={categoryData}
                          placeholder={""}
                          isDisabled={this.isViewOnly()}
                          isSearchable={true}
                          showError={handleInvoiceErrors(
                            errors.product_info_type_id,
                            touched.product_info_type_id
                          )}
                          onBlur={handleBlur}
                          errorText={handleInvoiceHelperText(
                            errors.product_info_type_id,
                            touched.product_info_type_id
                          )}
                          id={configJSON.productTempCategoryName}
                          singleValueCss={isDownload && singleValueCss}
                        />
                      </Box>
                    </Grid>
                    <Grid item className={`${classes.gridItem} marginTop`} sm={6}>
                      <Box className={classes.fieldWrapper}>
                        <Box className={`${classes.uploadImageContainer} productTempHeight1`}>
                          <ImageUploadView
                            name={configJSON.image}
                            currentImage={imageUrl?.url}
                            handleImageChange={this.handleImageChange}
                            setFieldValue={setFieldValue}
                            uploadTexts={["Upload Image"]}
                            isDisabled={this.isViewOnly()}
                          />
                        </Box>
                        {handleInvoiceErrors(errors.image, touched.image)
                          &&
                          (
                            <FormHelperText style={helperText}>
                              {handleInvoiceHelperText(errors.image, touched.image)}
                            </FormHelperText>
                          )}
                      </Box>
                      <Box className={`${classes.productGrid2} ${classes.gridItem}`}>
                        <Box className={`${classes.fieldWrapper6} ${classes.fieldWrapper}`}>
                          <InputLabel className={classes.inputLabel} required>
                            {configJSON.usdLabel}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            fullWidth
                            value={values.usd}
                            type="text"
                            helperText={handleInvoiceHelperText(
                              errors.usd,
                              touched.usd
                            )}
                            name={configJSON.usdName}
                            disabled={this.isViewOnly()}
                            className={classes.inputField}
                            onKeyPress={allowOnlyPrice}
                            error={handleInvoiceErrors(
                              errors.usd,
                              touched.usd
                            )}
                            InputProps={{
                              startAdornment: productTempAction !== configJSON.create && <InputAdornment className={classes.usdSymbole} position="start">$</InputAdornment>,
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <InputLabel className={classes.inputLabel}>
                            {configJSON.itemCodeLabel}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            type="text"
                            fullWidth
                            value={values.item_code}
                            name={configJSON.itemCodeName}
                            className={classes.inputField}
                            disabled={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Box>
                      </Box>
                      <Box className={classes.approvalContainer}>
                        <Typography className={`${classes.inputLabel2} approvalText`} variant="h4">
                          {configJSON.sendForApproval}
                        </Typography>
                        <Switch
                          name="send_for_approval"
                          color="primary"
                          disabled={this.state.productTempAction !== configJSON.create}
                          checked={values.send_for_approval}
                          onChange={handleChange}
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={6} className={classes.gridItem}>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel}>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          type="text"
                          variant="outlined"
                          disabled={this.isViewOnly()}
                          fullWidth
                          value={values.description}
                          onChange={handleChange}
                          multiline
                          onBlur={handleBlur}
                          name={configJSON.templateDescriptionName}
                          className={`${classes.inputField} ${classes.textAreaInputHeight} ${classes.textAreaField}`}
                        />
                      </Box>
                      <Box className={classes.productGrid3}>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <InputLabel className={classes.inputLabel}>
                            {configJSON.pairWithLabel}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            type="text"
                            value={values.pair_with1}
                            fullWidth
                            name={configJSON.pair_with1}
                            className={classes.inputField}
                            onChange={handleChange}
                            disabled={this.isViewOnly()}
                            onBlur={handleBlur}
                          />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <TextField
                            variant="outlined"
                            type="text"
                            value={values.pair_with2}
                            fullWidth
                            name={configJSON.pair_with2}
                            onChange={handleChange}
                            className={`${classes.inputField} ${classes.paddingTop18}`}
                            disabled={this.isViewOnly()}
                            onBlur={handleBlur}
                          />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <TextField
                            variant="outlined"
                            type="text"
                            value={values.pair_with3}
                            fullWidth
                            name={configJSON.pair_with3}
                            className={`${classes.inputField} ${classes.paddingTop18}`}
                            onChange={handleChange}
                            disabled={this.isViewOnly()}
                            onBlur={handleBlur}
                          />
                        </Box>
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel}>
                          {configJSON.faqLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          type="text"
                          disabled={this.isViewOnly()}
                          value={values.faq}
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          multiline
                          name={configJSON.faqName}
                          className={`${classes.inputField} ${classes.textAreaInputHeight} ${classes.textAreaField}`}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={`${classes.templateBtnWrapper} productBtnContainer`}>
                    {!this.isViewOnly() &&
                      <Button
                        data-test-id="create-btn"
                        className={classes.primaryBtn}
                        type="submit"
                        variant="contained"
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              onClose={this.handleDeleteClose}
              title={configJSON.productTempDeleteTitle}
              confirmDelete={this.handleDeleteTemplate}
              open={opeDeleteModal}
              from={configJSON.productFrom}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(ProductTemplate5);
// Customizable Area End

