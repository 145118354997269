import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, Button, Avatar } from "@material-ui/core";
import TaskListViewController, { Props, Task } from "./TaskListViewController.web";
export const configJSON = require("./config");
import { Wrapper } from "../../../components/src/Wrapper";
import { colors } from "../../../components/src/utils/commonDesign";
import CustomRadioSelect from "../../../components/src/commonComponents/CustomRadioSelect.web";
import { taskManagerIcon, filterImage, exportImage, completed, pending, deleteIcon } from "./assets";
import moment from "moment";
import NoTickets from "../../../components/src/commonComponents/NoTickets.web";
import Pagination from "../../../components/src/commonComponents/Pagination.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import { commonButton } from "../../../components/src/utils/commonInputStyle";
import Loader from "../../../components/src/Loader";
import { capitalizeFirstLetter } from "../../../components/src/utils/commonFunctions";
// Customizable Area End

// Customizable Area Start
export const styles = {
    ...commonButton,
    listContainer: {
        display: "flex",
        flexDirection: "column" as const,
        background: colors.primaryColor1,
    },
    headerContentBox: {
        display: "flex",
        justifyContent: "space-between",
    },
    headerContent: {
        marginTop: "20px",
        marginBottom: "20px",
        justifyContent: "space-between",
        ["& h4"]: {
            fontWeight: 600,
            fontSize: "1.4rem"
        },
    },
    typeButton: {
        background: "transparent !important",
        border: "1px solid #020202",
        marginRight: 20,
        ["&:hover"]: {
            background: "transparent !important",
        },
    },
    sectionDropdown: {
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "end",
        marginBottom: 20,
        marginTop: 20,
        "& .createBtn": {
            marginBottom: 20
        }
    },
    departSelect: {
        width: "13rem",
        marginTop: 40
    },
    filter: {
        marginRight: "2rem",
        width: "9.5rem",
        "& .filter": {
            height: "auto"
        }
    },
    assignFilter: {
        width: "13rem",
    },
    filterExport: {
        display: "flex",
        marginBottom: 20
    },
    exportItem: {
        width: "9rem",
    },
    btnGroup: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        gridGap: "25px",
        "& button": {
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            textTransform: "unset",
            fontSize: "1.1rem",
            color: "#7c7b79",
            fontWeight: 700,
            padding: 0,
            cursor: "pointer",
        },
        "& .active": {
            color: "#020202",
            borderRadius: "unset",
            borderBottom: "2px solid #7c7b79",
        },
    },
    listItem: {
        margin: "0px -24px",
        background: colors.primaryColor1,
    },
    tableHeader: {
        background: `${colors.secondaryColor1}40`,
        padding: "0.7rem 24px",
        display: "grid",
        gridTemplateColumns: "repeat(8,1fr)",
        "& p": {
            fontWeight: 600,
            color: "#020202",
            fontSize: "1.1rem"
        },
        "&.assignedHeader": {
            gridTemplateColumns: "repeat(7,1fr)",
        },
    },
    listMain: {
        position: "relative" as const,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 300px)",
    },
    tableItem: {
        padding: "1.2rem 24px",
        display: "grid",
        borderBottom: "1px solid #E8E8E8",
        alignItems: "center",
        gridTemplateColumns: "repeat(8,1fr)",
        "& p": {
            paddingRight: "1rem",
            fontSize: "1rem",
            fontWeight: 400,
            color: "#020202",

        },
        "&.assignedItem": {
            gridTemplateColumns: "repeat(7,1fr)",
        },
    },
    buttonType: {
        "& p": {
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#ffffff",
            borderRadius: "10px",
            width: "auto",
            maxWidth: "90%",
            padding: "0.4rem",
            "@media(max-width:1000px)": {
              maxWidth: "95%",
            },
            "@media(max-width:800px)": {
              maxWidth: "100%",
            },
            "& img": {
                marginRight: "0.6rem",
            },
        },
        "& .pending": {
            background: "#E59430",
        },
        "& .completed": {
            background: "#3E9D98",
        },
        "& .submit_for_review": {
            border: "2px solid #3F9D98",
            color: "#3F9D98",
            borderRadius: 8,
        },
    },
    listText: {
        textAlign: "center" as const,
        padding: "1rem",
    },

    feedbackBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& button": {
            color: "#4196F0",
            borderBottom: "1px solid #4196F0",
            textTransform: "capitalize" as const,
            padding: 0,
            borderRadius: "unset",
            fontSize: "1rem",
            "&:disabled": {
                color: "#4196F040",
                borderBottom: "1px solid #4196F040",
            },
        },
        "&.center": {
            justifyContent: "center",
        }
    },
    avatarImage: {
        borderRadius: "unset",
        marginRight: 8,
        width: "1.3rem",
        height: "1.3rem",
        cursor: "pointer",
        "& img": {
            width: "unset",
            height: "unset"
        }
    },
    textTransform: {
        textTransform: "capitalize" as const
    },
    paginationContainer: {
        "& .paginationPadding": {
            padding: "1.5rem 0rem",
        }
    },
    displayNone: {
        display: "none"
    },
    noTicketContainer: {
        top: "50%",
        position: "absolute" as const,
        textAlign: "center" as const,
    },

};
// Customizable Area End

export class TaskListView extends TaskListViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start


    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            select_department,
            is_open_department,
            select_downloadTYpe,
            select_filterTYpe,
            is_open_download,
            is_open_filter,
            isCreatedActive,
            isAssignedActive,
            department_list,
            created_header_list,
            task_list,
            meta,
            statusList,
            assignStaus,
            isManageActive,
            loading,
            isAgent,
            isBO,
            isManager,
            all_task_list
        } = this.state;
        const imageSources: Record<string, string> = {
            completed: completed,
            pending: pending,
        };
        const renderList = (listItem: Task, selectedImageSource: string, isCreatedActive: boolean, isManageActive: boolean, isAssignedActive: boolean) => {
            const dueDate = moment(listItem.attributes.due_date);
            const currentDate = moment();
            const dueDateStyle = {
                color: listItem.attributes.status !== "completed" && dueDate.isBefore(currentDate) ? "red" : "black",
            };
            return (
                <Box key={listItem.id} className={`${classes.tableItem} ${isAssignedActive && "assignedItem"}`}>
                    <Box className={classes.feedbackBtn}>
                        <Button data-test-id="task_number" onClick={() => this.redirectEditTask(listItem.id)}>{listItem.attributes.task_id}</Button>
                    </Box>
                    <Typography data-test-id="title" component="p">{capitalizeFirstLetter(listItem.attributes.title)}</Typography>
                    {isAssignedActive ? (
                        <>
                            <Typography className={classes.textTransform} component="p">{listItem.attributes.creator?.name}</Typography>
                            <Typography className={classes.textTransform} component="p">{listItem.attributes.creator?.designation}</Typography>
                        </>
                    ) : (
                      <>
                        <Typography className={classes.textTransform} component="p">{listItem.attributes.assignee_ids?.name}</Typography>
                        <Typography className={classes.textTransform} component="p">{listItem.attributes.assignee_ids?.designation}</Typography>
                        </>
                    )}
                    <Typography component="p">{moment(listItem.attributes.issue_date).format("DD/MM/YYYY")}</Typography>
                    <Typography component="p" style={dueDateStyle}>
                        {listItem.attributes.due_date ? moment(listItem.attributes.due_date).format("DD/MM/YYYY") : ""}
                    </Typography>
                    <Box className={classes.buttonType}>
                        <Typography className={`${listItem.attributes.status}`} data-test-id="status_title" component="p">
                            {listItem.attributes.status !== "submit_for_review" && <img src={selectedImageSource} alt={listItem.attributes.status} />}
                            {listItem.attributes.status_name === "Submit for Review" ? configJSON.inReview : listItem.attributes.status_name}
                        </Typography>
                    </Box>
                    {isCreatedActive || isManageActive ? (
                        <Box className={`${classes.feedbackBtn} center`}>
                            <Box data-test-id="delete_icon" onClick={() => this.handleDelete(listItem)} >
                                <Avatar
                                    src={deleteIcon}
                                    alt="delete"
                                    className={classes.avatarImage}
                                />
                            </Box>
                        </Box>
                    ) : (
                        null
                    )}
                </Box>
            );
        }
        const showTaskList = () => {
            if (task_list.length > 0) {
                return (
                    task_list.map((item) => {
                        const status = item.attributes.status;
                        const selectedImageSource = imageSources[status];
                        return (
                            renderList(item, selectedImageSource, isCreatedActive, isManageActive, isAssignedActive)
                        );
                    })
                )
            } else if (task_list.length === 0 && this.state.searchQuery) {
                return (
                    <Box className={classes.noTicketContainer}>
                        <Typography data-test-id="search_result" variant="h5">{configJSON.noSearchResult} <strong>{this.state.searchQuery}</strong>.</Typography>
                    </Box>
                )
            } else if (task_list.length === 0) {
                return (
                    <NoTickets
                        title={configJSON.title}
                        endContent={!isAssignedActive && configJSON.endContent}
                        imagePath={taskManagerIcon}
                        content={isAssignedActive ? configJSON.noAssignedContent : configJSON.content}
                        strongContent={!isAssignedActive && configJSON.strongContent}
                    />
                )
            }
        }
        return (
            <>
                <Loader loading={loading} />
                <Wrapper
                    data-test-id="wrapper-comp"
                    bellIcn={true}
                    homeIcn={true}
                    search
                    navigation={this.props.navigation}
                    backHandleClick={this.handleBackClick}
                    divider
                    backbtn={true}
                    title={this.handleTitle()}
                    querySearch={this.state.searchQuery}
                    searchHandleClick={this.handleSearch}
                    searchData={task_list}
                    child={
                        <Box className={classes.listContainer}>
                            <Box className={classes.headerContentBox}>
                                <Box className={classes.headerContent}>
                                    <Box className={classes.btnGroup}>
                                        <Button data-test-id="assign_button" disableRipple className={`${isAssignedActive ? "active" : ""}`} onClick={() => this.handleButtonClick(3)}>
                                            {configJSON.assignedTaskBtn}
                                        </Button>
                                        {!isAgent && <>
                                            <Button data-test-id="created_button" disableRipple className={isCreatedActive ? "active" : ""} onClick={() => this.handleButtonClick(1)}>
                                                {configJSON.createdTaskBtn}
                                            </Button>
                                            <Button data-test-id="manage_button" disableRipple className={isManageActive ? "active" : ""} onClick={() => this.handleButtonClick(2)}>
                                                {configJSON.taskTitleManage}
                                            </Button>
                                        </>}
                                    </Box>
                                    {(isBO || isManager) && <Box className={classes.departSelect}>
                                        <CustomRadioSelect
                                            selectedValue={select_department}
                                            onValueSelect={this.handleSelectDepart}
                                            title={configJSON.departSelect}
                                            toggleModal={this.toggleSelectDepart}
                                            isModalOpen={is_open_department}
                                            data={department_list}
                                        />
                                    </Box>}
                                </Box>
                                <Box className={classes.sectionDropdown}>
                                    <Box className={`${!isCreatedActive && classes.displayNone} createBtn`}>
                                        {!isAgent && <Button onClick={this.onClickCreateTask} className={classes.primaryBtn} data-test-id="branch-btn">{configJSON.taskTitleCreated}</Button>}
                                    </Box>
                                    <Box className={classes.filterExport}>
                                        <Box className={`${classes.filter} ${isManageActive && classes.displayNone} ${isAssignedActive && classes.assignFilter}`}>
                                            <CustomRadioSelect
                                                title={configJSON.filterSelect}
                                                isIcon={true}
                                                selectedValue={select_filterTYpe}
                                                onValueSelect={this.handleSelectFilter}
                                                imagePath={filterImage}
                                                toggleModal={this.toggleSelectFilter}
                                                isModalOpen={is_open_filter}
                                                data={isCreatedActive ? statusList : assignStaus}
                                            />
                                        </Box>
                                        <Box className={classes.exportItem}>
                                            <CustomRadioSelect
                                                hasButton={true}
                                                downloadData={all_task_list}
                                                selectedValue={select_downloadTYpe}
                                                onValueSelect={this.handleSelectDownload}
                                                title={configJSON.exportSelect}
                                                toggleModal={this.toggleSelectDownloadModal}
                                                isModalOpen={is_open_download}
                                                isIcon={true}
                                                imagePath={exportImage}
                                                data={configJSON.downloadType}
                                                onClick={this.handleExportClick}
                                            />
                                        </Box>
                                    </Box>
                                    <Box data-test-id="clearall_button" onClick={this.handleClearFilter} className={classes.feedbackBtn}>
                                        <Button disabled={this.handleDisable()}>{configJSON.clearAll}</Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box id="pdf-content" className={classes.listItem}>
                                <Box className={`${classes.tableHeader} ${isAssignedActive && "assignedHeader"}`}>
                                    {created_header_list.map((item) => (
                                        <Typography component="p" key={item}>
                                            {item}
                                        </Typography>
                                    ))}
                                </Box>
                                <Box className={task_list.length === 0 ? classes.listMain : ""}>
                                    {showTaskList()}
                                </Box>
                            </Box>
                            <Box className={classes.paginationContainer}>
                                {task_list.length > 0 && <Pagination meta={meta} onPageChange={this.handlePageChange} />}
                            </Box>
                            <ConfirmationModal
                                open={this.state.open}
                                onClose={this.handleClose}
                                confirmDelete={this.handleDeleteTask}
                                title={this.state.title}
                                from={configJSON.confirmTask}
                            />
                        </Box>
                    }
                />
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default withStyles(styles)(TaskListView);
// Customizable Area End
