// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall, convertFormData, handleApiError, handleExpiredToken, checkToken, getNavigationMessage, handleUndefinedData, sortArrayAlphabetically, isBO, userBranchId, usersDepartments } from "../../../components/src/utils/commonFunctions";
import { Priorites, selectAllOption } from "../../../components/src/utils/constant";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: Record<string,string>

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  department_list: Lists[];
  employees_list: Lists[];
  priorites: Lists[];

  attachment: {name: string ,url: File | string} | null;
  apiError: string;
  ticket_type_list: Lists[];
  branch_list: Lists[];
  formData: FormValues;
  defaultData: FormValues;
  open: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
// Customizable Area Start
export interface ClearValue {
  [key: string]: string | string[]
}
export interface FormValues {
  title: string;
  description: string;
  ticket_type_id: string;
  branch_id: string;
  department_id: string;
  assignee_ids?: string[];
  due_date: string | Date;
  attachment?: File | null;
  priority: string;
}
export interface Lists {
  id?: number,
  value: string;
  label: string;
}
// Customizable Area End

export default class CreateTicketController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createTicketCallId: string = "";
  ticketTypeDataCallId: string = "";
  branchListCallId: string = "";
  departmentListCallId: string = "";
  employeeListApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    const initialData = {
      title: "",
      description: "",
      priority: "",
      due_date: new Date(),
      ticket_type_id: "",
      branch_id: "",
      department_id: "",
      assignee_ids: [],
      attachment: null,
    }

    this.state = {
      loading: false,
      department_list: [],
      employees_list: [],
      priorites: Priorites,
      attachment: null,
      apiError: "",
      ticket_type_list: [],
      branch_list: [],
      formData: initialData,
      defaultData: initialData,
      open: false

    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    checkToken();
    this.getTicketTypeList()
    this.branchListDropdown();

    const { state } = this.props.navigation.history.location;
    const formData = {
      title: handleUndefinedData(state?.title, ""),
      description: handleUndefinedData(state?.description, ""),
      priority: handleUndefinedData(state?.priority, ""),
      due_date: handleUndefinedData(state?.due_date, new Date()),
      ticket_type_id: handleUndefinedData(state?.ticket_type_id, ""),
      branch_id: handleUndefinedData(state?.branch_id, ""),
      department_id: handleUndefinedData(state?.department_id, ""),
      assignee_ids: [],
      attachment: null,
    }
    this.setState({ formData: { ...this.state.formData, ...formData }, defaultData: formData })
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: Props, previousState: S) {
    const { formData } = this.state;
    const { ticket_type_id, branch_id, department_id } = formData;
    if ((ticket_type_id && branch_id) && (formData.ticket_type_id !== previousState.formData.ticket_type_id || formData.branch_id !== previousState.formData.branch_id)) {
      this.setState({ loading: true });
      this.getDepartmentList()
    }
    if ((branch_id && department_id) && (formData.department_id !== previousState.formData.department_id || formData.branch_id !== previousState.formData.branch_id)) {
      this.setState({ loading: true });
      this.getEmployeeList()
    }
  }
  handleOkClick = () => {
    this.send(getNavigationMessage('TicketTableView', this.props));
  }

  handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: File | null) => void
  ): void => {
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];

      if (file) {
        const allowFile = file.size <= 5 * 1024 * 1024;

        if (allowFile) {
          this.setState({
            attachment: {
              name: file.name,
              url: URL.createObjectURL(file),
            },
            apiError: "",
          });
          setFieldValue("attachment", file);
        } else {
          this.handleClearFile(setFieldValue);
          this.setState({ apiError: configJSON.fileSizeError});
        }
      }
    }
  };

  handleSelectChange = (event: OptionType, setFieldValue: { (field: string, value: string | string[] | undefined | OptionType, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, keyValue: keyof FormValues, clearValue?: ClearValue | null | undefined) => {
    let finalData: OptionType | string | string[] = event;
    if (finalData && typeof finalData === "object" && Array.isArray(finalData)) {
      finalData = event.map((item: OptionType) => item.value)
    } else {
      finalData = event?.value
    }

    if (clearValue && (this.state.formData[keyValue] !== finalData)) {
      setFieldValue(keyValue, finalData);
      this.setState({ formData: { ...this.state.formData, [keyValue]: finalData, ...clearValue } });
      for (let keyItem of Object.keys(clearValue)) {
        setFieldValue(keyItem, clearValue[keyItem]);
      }
    } else {
      setFieldValue(keyValue, finalData);
      this.setState({ formData: { ...this.state.formData, [keyValue]: finalData } });
    }
  };

  handleClearFile = (setFieldValue: { (field: string, value:File, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: null): void; }) => {
    this.setState({
      attachment: null,
      apiError: "",
    });
    setFieldValue("attachment", null)
  };
  getTicketTypeList = () => {
    this.ticketTypeDataCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.ticketTypeApiEndPoint, token: true, });
  }
  handleResponseForTicketTypeData = (from: string, message: Message) => {
    if (this.ticketTypeDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.data) {
          let ticketData = apiResponse.data.map((item: { issue_name: string; ticket_type_id: string; }) => {
          const { issue_name, ticket_type_id } = item;
          return { value: Number(ticket_type_id), label: issue_name }
        });
        ticketData = sortArrayAlphabetically(ticketData, 'label');
        this.setState({ ticket_type_list: ticketData })
      } else {
        handleApiError(apiResponse.errors);

      }

    }
  }
  branchListDropdown = () => {
    this.branchListCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: `${configJSON.createBranchApiEndpoint}`, token: true, });
  }
  handleResponseForBranchListDropdown = (from: string, message: Message) => {
    if (this.branchListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.branches.data) {
        let branchData = !isBO() ? apiResponse.branches.data.filter((item:{id:number})=> Number(item.id) === Number(userBranchId)) : apiResponse.branches.data;
        branchData = branchData.map((item: { id: string; attributes: { location_of_point_of_sale: string, branch_name: string; }; }) => {
          return { value: Number(item.id), label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }
        });
        branchData = sortArrayAlphabetically(branchData, 'label');
        this.setState({
          branch_list: branchData
        })

      } else {
        handleApiError(apiResponse.errors);

      }


    }
  }
  getDepartmentList = () => {
    const { formData } = this.state
    this.departmentListCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: `${configJSON.deparmentListApiEndPoint}branch_id=${formData.branch_id}&ticket_type_id=${formData.ticket_type_id}`, token: true, });
  }
  handleResponseForDepartmentList = (from: string, message: Message) => {
    if (this.departmentListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false })
      handleExpiredToken(apiResponse, this.props.navigation);
      this.setState({ loading: false })
      const defaultItem = { value: "", label: configJSON.departSelect, isDisabled: true };
      if (apiResponse.data) {
        const { data } = apiResponse;
        if (data.length > 0) {
          const filteredIDs = data.map((item: { id: string; attributes: { name: string }; }) => item.id)
          const filteredData = usersDepartments.filter((item: { id: string; attributes: { name: string }; }) => filteredIDs.includes(item.id))
          let finalData = 
          isBO() ? data.map((item: { id: string; attributes: { name: string }; }) => {
            const { id, attributes } = item;
            return { label: attributes.name, value: Number(id) };
          }) : filteredData.map((item: { attributes: { id: number; name: string; }; }) => {
            const { id, name } = item.attributes;
            return { label: name, value: Number(id) };
          })
          if(finalData.length <= 0) {
            toast.error(configJSON.departmentErrorMessage);
          }
          finalData = sortArrayAlphabetically(finalData, 'label');
          finalData.unshift(defaultItem);
          this.setState({ department_list: finalData });
        } else {
          this.setState({ department_list: [defaultItem], formData: { ...this.state.formData, department_id: "", assignee_ids: [] } });
          toast.error(configJSON.departmentErrorMessage);
        }

      }
      else {
        this.setState({ department_list: [defaultItem], formData: { ...this.state.formData, department_id: "", assignee_ids: [] } });
        handleApiError(apiResponse.errors);
      }

}
  }
  getEmployeeList = () => {
    const { formData: { department_id, branch_id } } = this.state
    this.employeeListApiId = apiCall({ method: configJSON.validationApiMethodType, endPoint: `${configJSON.employeeListApiEndPoint}department_id=${department_id}&branch_id=${branch_id}`, token: true })
  }

  handleResponseForEmployeeList = (from: string, message: Message) => {
    if (this.employeeListApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false })
      handleExpiredToken(apiResponse, this.props.navigation);
      this.setState({ loading: false })
      if (apiResponse.data) {
        const { data } = apiResponse;
        if (data.length > 0) {
          const finalData = data.map((item: { id: string; attributes: { email: string }; }) => {
            const { id, attributes } = item;
            return { label: attributes.email, value: Number(id) };
          })
          this.setState({ employees_list: finalData });
        } else {
          this.setState({ employees_list: [], formData: { ...this.state.formData, assignee_ids: [] } });
          toast.error(configJSON.employeeErrorMessage);
        }

      } else {
        this.setState({ employees_list: [] });
        handleApiError(apiResponse.errors);
      }

    }
  }
  handleSave = (values: FormValues) => {
    this.createTicket(values)
  };
  createTicket = (values: FormValues) => {
    this.setState({ loading: true });
    const attrs: FormValues = {
      title: values.title,
      description: values.description,
      ticket_type_id: values.ticket_type_id,
      branch_id: values.branch_id,
      department_id: values.department_id,
      assignee_ids: values.assignee_ids?.filter(item => item !== selectAllOption.value),
      due_date: values.due_date,
      ...(values.attachment ? { attachment: values.attachment } : {}),
      priority: values.priority,
    };
    this.createTicketCallId = apiCall({
      method: configJSON.exampleAPiMethod,
      body: convertFormData(attrs),
      endPoint: configJSON.ticketListApiEndPoint,
      token: true,
    });
  }
  handleResponseForCreateTicket = (from: string, message: Message) => {
    if (this.createTicketCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      this.setState({ loading: false })
      if (apiResponse.message) {

        this.setState({ open: true })

      } else {
        handleApiError(apiResponse.errors);
      }
    }
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForTicketTypeData(from, message)
    this.handleResponseForBranchListDropdown(from, message)
    this.handleResponseForDepartmentList(from, message)
    this.handleResponseForEmployeeList(from, message)
    this.handleResponseForCreateTicket(from, message)
    // Customizable Area End
  }

  // Customizable Area End


}
