// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall, convertFormData, handleApiError, handleExpiredToken, checkToken } from "../../../components/src/utils/commonFunctions";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes:Record<string,string>

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  timezone_data:{
    label:string;
    value:string
  }[];
  code_list:DataList[];
  currentImage: string | ArrayBuffer | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
// Customizable Area Start
export interface FormValues {
  branch_name: string;
  type_of_point_of_sale: string;
  location_of_point_of_sale: string;
  time_zone:  null | { label:string;value:string} | string;
  from_working_hours: null | string;
  to_working_hours: null | string;
  phone_number: string;
  email: string;
  photo?: null | File;
  description: string;
  important_information: string;
  country_code_and_flag_id:string

}

export interface DataList {
  id?: number,
  value: string;
  label: string;
}
// Customizable Area End

export default class AddNewBranchController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createBranchCallId: string = "";
  timezoneApiCallId: string = "";
  countryCodelistCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      timezone_data:[],
      code_list:[],
      currentImage: null,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

 async componentDidMount() {
    // Customizable Area Start
    checkToken(this.props.navigation);
    this.getTimezoneData()
    this.getCountryCodeData()
    // Customizable Area End
  }
  // Customizable Area Start
  goBack = ()=> {
    this.props.navigation.goBack();
  } 
  handleSelectChange = (event: OptionType, setFieldValue: { (field: string, value: FormValues, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, keyItem: string) => {
    setFieldValue(keyItem, event.value);
};

  handleImageChange = (event: { target: {name:string; files: File[]; }; },setFieldValue: (arg0: string, arg1: File) => void) => {
    const file = event.target.files[0];
    if (file) {
        setFieldValue(event.target.name,file)
        this.setState({
            currentImage: URL.createObjectURL(event.target.files[0])
        });
    }
};

  getCountryCodeData = () => {
    this.countryCodelistCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.countryCodeApiEndPoint, token: true, });
}
handleResponseForCountryCodeData = (from: string, message: Message) => {
    if (this.countryCodelistCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        handleExpiredToken(apiResponse, this.props.navigation);
        if (apiResponse.data) {
            const countryData = apiResponse.data.map((item:{id:string;name:string;alpha2:string;country_code:string}) => {
                const { id,country_code,alpha2}= item
                return { value: Number(id), label: `${country_code} (${alpha2})` }
            });
            this.setState({
                code_list: countryData
            })

        } else {
            handleApiError(apiResponse.errors);
        }

    }
}
  
  createNewBranch = (values: FormValues) => {

    const attrs: FormValues = {
      branch_name: values.branch_name,
      type_of_point_of_sale: values.type_of_point_of_sale,
      location_of_point_of_sale: values.location_of_point_of_sale,
      time_zone: values.time_zone,
      from_working_hours: values.from_working_hours,
      to_working_hours: values.to_working_hours,
      phone_number: values.phone_number,
      email: values.email,
      ...(values.photo ? { photo: values.photo } : {}),
      description: values.description,
      important_information: values.important_information,
      country_code_and_flag_id:values.country_code_and_flag_id,

    };
    this.createBranchCallId = apiCall({ method: configJSON.exampleAPiMethod, body: convertFormData(attrs), endPoint: configJSON.createBranchApiEndpoint, token: true, });
  }
getTimezoneData = ()=> {
  this.timezoneApiCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.timezoneApiEndpoint, token: true, });

}
  handleResponseForCreateBranch = (from: string, message: Message) => {
    if (this.createBranchCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.data) {
        toast.success(configJSON.toastSuccessMessage);
        this.props.navigation.history.push(`AddDepartment?id=${apiResponse?.data.id}`);
      } else {
        handleApiError(apiResponse.errors ?? configJSON.invalidMessage);
      }

    }
  }
  handleResponseForTimezone = (from: string, message: Message) => {
    if (this.timezoneApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse) {
        this.setState({
          timezone_data:apiResponse.map((item: {label:string,value:string})=> {
            return{
              label:item,
              value:item
            }
          })
        })

      } 

    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForCreateBranch(from, message)
    this.handleResponseForTimezone(from,message)
    this.handleResponseForCountryCodeData(from,message)
    // Customizable Area End
  }
  // Customizable Area End


}
