import React, { Component } from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";
import { UploadFile, image_upload } from "./assets"
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start

interface ImageWithButtonProps {
  currentImage: any;
  handleImageChange: any;
  setFieldValue: any;
  name: string;
  editable?: boolean;
  uploadTexts?: string[];
  uploadImg?: string;
  isDisabled?: boolean;
  classes: ClassNameMap<"mainContainer" | "imageInput" | "hoverContainer" | "hoverContent" | "uploadImage" | "uploadText" | "textContainer">
}

interface ImageWithButtonState {
  isHovered: boolean;
}

export const styles = () => createStyles({
  mainContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    gap: "10px"
  },
  imageInput: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    alignItems: "center",
  },
  hoverContainer: {
    padding: "8px",
    background: "rgba(255, 255, 255, 1)",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    textAlign: "center",
    height: "120px",
    margin: "auto",
    width: "100%",
    maxWidth: "175px",
    objectFit: "contain",
    aspectRatio: "5/4",
  },
  hoverContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "100%",
  },
  uploadImage: {
    borderRadius: "unset !important",
    width: "24px !important",
    height: "24px !important",
    marginBottom: 10
  },
  uploadText: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  textContainer : {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "3px"
  }
});
// Customizable Area End

export class ImageUploadView extends Component<ImageWithButtonProps, ImageWithButtonState>  {
  private fileInputRef: React.RefObject<HTMLInputElement>;
  constructor(props: ImageWithButtonProps) {
    super(props);
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    this.state = {
      isHovered: false,
    };
    // Customizable Area End
  }
  // Customizable Area Start
  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  handleChooseImage = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };
  render() {
    const { isHovered } = this.state;
    const { currentImage, handleImageChange, classes, editable, uploadTexts, uploadImg, isDisabled } = this.props;
    return (
      <>
        <Box
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          className={classes.mainContainer}
        >
          {currentImage ?
            <img
              src={currentImage}
              alt="Hoverable Image"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                opacity: isHovered && !editable && !isDisabled ? 0.4 : 1
              }}

            /> : (
              <React.Fragment>
                <img
                  src={uploadImg ?? UploadFile}
                  alt="UploadFile"
                  className="uploadImage"
                />
                <div className={classes.textContainer}>
                  {
                    (uploadTexts && uploadTexts?.length > 0) ?
                      uploadTexts.map(text => (
                        <Typography
                          variant="body1"
                          className={classes.uploadText}
                        >
                          {text}
                        </Typography>
                      ))
                      :
                      <Typography
                        variant="body1"
                        className={`${classes.uploadText} imgText`}
                      >
                        <Typography>{configJSON.uploadPicText}</Typography>
                        <Typography>{configJSON.picTypeText}</Typography>
                      </Typography>
                  }
                </div>
              </React.Fragment>
            )}

          {isHovered && !editable && !isDisabled && (
            <Box
              className={classes.imageInput}
            >
              <input
                key={currentImage}
                type="file"
                accept="image/*"
                name={this.props.name}
                onChange={(e) => handleImageChange(e, this.props.setFieldValue)}
                style={{ display: "none" }}
                ref={this.fileInputRef}
                data-test-id="image-id"
                disabled={isDisabled}
              />
              <Box
                onClick={this.handleChooseImage}
                className={classes.hoverContainer}
              >
                <Box
                  className={classes.hoverContent}
                >
                  <img src={image_upload} className={classes.uploadImage} />
                  <Typography>{currentImage ? configJSON.replaceText : configJSON.addText}</Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(ImageUploadView);
// Customizable Area End

