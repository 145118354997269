import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, Button, Avatar } from "@material-ui/core";
import ExistingBranchController, { Props } from "./ExistingBranchController.web"
export const configJSON = require("./config");
import { Wrapper } from "../../../components/src/Wrapper";
import { colors } from "../../../components/src/utils/commonDesign"
import { editIcon, deleteIcon, noBranchImage } from "./assets"
import Pagination from "../../../components/src/commonComponents/Pagination.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import NoTickets from "../../../components/src/commonComponents/NoTickets.web";
import { isBO } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
// Customizable Area End

// Customizable Area Start
export const styles = {
  listContainer: {
    display: "flex",
    flexDirection: "column" as const,
    background: colors.primaryColor1,
  },
  headerSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ["& button"]: {
      textTransform: "capitalize",
      padding: "0.7rem 2rem",
      fontWeight: 600,
      fontSize: "1rem",
      borderRadius: 8,
      background: colors.secondaryColor2,
      color: colors.primaryColor2,
      "&:hover": {
        background: colors.secondaryColor2,
      },
    },
    ["& h4"]: {
      fontSize: "1.4rem"
    },
  },
  tableHeader: {
    background: `${colors.secondaryColor1}40`,
    padding: "0.7rem 24px",
    marginTop: "2rem",
    display: "grid",
    gridTemplateColumns: "repeat(5,1fr)",
    "& p": {
      fontWeight: 600,
      color: "#020202"
    },
    "&.otherUserHeader": {
      gridTemplateColumns: "repeat(3,1fr)",
    }
  },
  btnGroup: {
    display: "flex"
  },
  btnInner: {
    display: "flex",
    alignItems: "center",
    marginRight: "2rem",
    "& button": {
      minWidth: "unset",
      padding: 0,
      color: "#020202;",
      fontWeight: 400,
      textTransform: "capitalize"
    }
  },
  avatarImage: {
    width: "1.3rem",
    height: "1.3rem",
    borderRadius: "unset",
    marginRight: 8,
    cursor: "pointer",
    "& img": {
      width: "unset",
      height: "unset"
    }
  },
  listText: {
    textAlign: "center" as const,
    padding: "1rem"
  },
  listMain: {
    overflow: "auto"
  },
  noList: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 200px)",
  },
  listItem: {
    margin: "0px -24px",
    background: colors.primaryColor1,
  },
  tableItem: {
    padding: "1.2rem 24px",
    display: "grid",
    gridTemplateColumns: "repeat(5,1fr)",
    borderBottom: "1px solid #E8E8E8",
    alignItems: "center",
    "& p": {
      fontWeight: 400,
      color: "#020202;",
      paddingRight: "1rem"
    },
    "&.otherUserItem": {
      gridTemplateColumns: "repeat(3,1fr)",
    }
  },
  backBtn: {
    justifyContent: "center",
    margin: "1rem",
    "& button": {
      width: "17rem"
    }
  },
  feedbackBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& button": {
      textTransform: "capitalize",
      padding: 0,
      borderRadius: "unset",
      color: "#4196F0",
      borderBottom: "1px solid #4196F0",
      fontSize: "1rem",
      cursor: "pointer",

    },
  },
  paginationContainer:{
		"& .paginationPadding":{
			padding: "1.5rem 24px",
		}
	}

};
// Customizable Area End

export class ExistingBranch extends ExistingBranchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { classes } = this.props;
    const { header_list, branch_list, meta,loading } = this.state
    return (
      <>
        <Loader loading={loading} />
        <Wrapper data-test-id="wrapper-comp" navigation={this.props.navigation} backHandleClick={this.handleBackClick} divider backbtn={true} title={configJSON.wrapperTitle} bellIcn={true} homeIcn={true} child={
          <Box className={classes.listContainer}>
            <Box className={classes.headerSection}>
              <Typography component="h4">{configJSON.existingTitle}</Typography>
              {isBO() && <Button data-test-id="branch-btn" onClick={this.handleBranchClick}>{configJSON.btnTitle}</Button>}
            </Box>
            <Box className={classes.listItem}>
              <Box className={`${classes.tableHeader} ${!isBO() && "otherUserHeader"}`}>
                {header_list.map((item) => (
                  <Typography component="p" key={item}>{item}</Typography>
                ))}
              </Box>
              <Box className={classes.listMain}>
                {branch_list.length > 0 ?
                  branch_list.map((item) => (
                    <Box key={item.id} className={`${classes.tableItem} ${!isBO() && "otherUserItem"}`}>
                      <Box className={classes.feedbackBtn}>
                        <Button data-test-id="branch-name" onClick={() => this.handleViewClick(item)}>{item.attributes.branch_name}</Button>
                      </Box>
                      <Typography component="p" >{item.attributes.type_of_point_of_sale}</Typography>
                      <Typography component="p" >{item.attributes.location_of_point_of_sale}</Typography>
                      {isBO() && <>
                        <Box className={classes.btnGroup}>
                          <Box className={classes.btnInner} data-test-id={`edit-btn-${item.id}`} onClick={() => this.handleEditClick(item)}>
                            <Avatar
                              src={editIcon}
                              alt="edit"
                              className={classes.avatarImage}
                            />
                            <Button>{configJSON.editButton}</Button>
                          </Box>
                          <Box className={classes.btnInner} data-test-id="delete-btn" onClick={() => this.handleDelete(item)}>
                            <Avatar
                              src={deleteIcon}
                              alt="delete"
                              className={classes.avatarImage}
                            />
                            <Button>{configJSON.deleteButton}</Button>
                          </Box>

                        </Box>
                        <Box className={classes.feedbackBtn} data-test-id={`department-btn-${item.id}`} onClick={() => this.handleDepartment(item)}>
                          <Button data-test-id="ticket_number">{configJSON.departmentButton}</Button>
                        </Box>
                      </>}
                    </Box>

                  )) :
                  <Box className={classes.noList}>
                    <NoTickets
                      title={configJSON.title}
                      imagePath={noBranchImage}
                      content={configJSON.content}
                      strongContent={configJSON.strongContent}
                      endContent={configJSON.endContent}
                      classes={this.props.classes}
                    />
                  </Box>

                }
              </Box>
              <Box className={classes.paginationContainer}>
                {branch_list.length > 0 && <Pagination meta={meta} onPageChange={this.handlePageChange} />}
              </Box>
            </Box>

            <ConfirmationModal
              open={this.state.open}
              onClose={this.handleClose}
              confirmDelete={this.handleDeleteBranch}
              title={this.state.title}
              from={configJSON.confirmBranch}
            />
          </Box >
        } />
      </>
    );
  }
  // Customizable Area End
}
export default withStyles(styles)(ExistingBranch);

