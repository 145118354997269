// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { ClassNameMap } from "@material-ui/styles";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonTemplateController from "./CommonTemplateController.web";
import { handleApiError, handleExpiredToken, checkToken, getDiffs, handleUndefinedData, getUserRoleID } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"formContainer" | "inputField" | "dateField" | "approvalContainer" 
    | "fieldWrapper" | "inputLabel" | "helperText" | "textAreaField" | "textAreaInputField" | "templateBtnWrapper"
     | "fileInput" | "primaryBtn" | "secondaryBtn" | "inputLabel2" | "dropdownWrapper" | "uploadImageContainer" | "titleText" | "gridItem" | "templateFooterContainer"
  >;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class Template17Controller extends CommonTemplateController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      ...this.initialState,
      ...this.state
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidMount(): any {
    // Customizable Area Start
    checkToken();
    this.getBranchData();
    let { state } = this.props.navigation.history.location;
    if (state) {
      this.setDefaultData17(state)
    } else if(![2,3].includes(getUserRoleID())) {
      this.props.navigation.goBack()
    }
    // Customizable Area End
  }

  setDefaultData17(data: any, action?: string) {
    const { creator, id, attachment } = data
    this.setState({
      formData: data,
      creator,
      id,
      templateAction: action ?? configJSON.view,
      defaultData: data,
      attachment: attachment.url ? attachment : "",
    })
  }

  template17InitialValues = () => {
    const { defaultData, formData, templateAction } = this.state;
    const isCreateTemplate17 = templateAction === configJSON.create;
    const data = {
      department_id: isCreateTemplate17 ? defaultData.department_id : handleUndefinedData(formData?.department_id, ""),
      title: isCreateTemplate17 ? defaultData.title : handleUndefinedData(formData?.title, ""),
      branch_id: isCreateTemplate17 ? defaultData.branch_id : handleUndefinedData(formData?.branch_id, ""),
      attachment: null,
      send_for_approval: isCreateTemplate17 ? defaultData.send_for_approval : handleUndefinedData(formData?.send_for_approval, false),
      description1: isCreateTemplate17 ? "" : handleUndefinedData(formData?.descriptions_attributes[0]?.description, ""),
      description2: isCreateTemplate17 ? "" : handleUndefinedData(formData?.descriptions_attributes[1]?.description, ""),
      description3: isCreateTemplate17 ? "" : handleUndefinedData(formData?.descriptions_attributes[2]?.description, ""),
      description4: isCreateTemplate17 ? "" : handleUndefinedData(formData?.descriptions_attributes[3]?.description, ""),
      description5: isCreateTemplate17 ? "" : handleUndefinedData(formData?.descriptions_attributes[4]?.description, ""),
      description6: isCreateTemplate17 ? "" : handleUndefinedData(formData?.descriptions_attributes[5]?.description, ""),
      description7: isCreateTemplate17 ? "" : handleUndefinedData(formData?.descriptions_attributes[6]?.description, ""),
      description8: isCreateTemplate17 ? "" : handleUndefinedData(formData?.descriptions_attributes[7]?.description, ""),

    }
    return data;
  }

  addEditTemplate17 = (values: any) => {
    const { formData,template_id, defaultData } = this.state;

    const {branch_id,descriptions_attributes, department_id,attachment,title, send_for_approval } = defaultData;
    const {description1, description2,description3, description4 ,description5,description6,description7,description8} = values;

    const { id } = formData;

    const createDescriptionsAttr = [
      {
        description: description1,
      }, {
        description: description2,
      },{
        description: description3,
      },{
        description: description4,
      },{
        description: description5,
      }, {
        description: description6,
      },{
        description: description7,
      },{
        description: description8,
      }
    ];

    const editDescriptionsAttr = () => {
      return [
        {
          id: this.state.formData.descriptions_attributes[0].id,
          description: description1,
        }, {
          id: this.state.formData.descriptions_attributes[1].id,
          description: description2,
        },
        {
          description: description3,
          id: this.state.formData.descriptions_attributes[2].id,
        },
        {
          id: this.state.formData.descriptions_attributes[3].id,
          description: description4,
        },
        {
          description: description5,
          id: this.state.formData.descriptions_attributes[4].id,
        },
        {
          description: description6,
          id: this.state.formData.descriptions_attributes[5].id,
        },
        {
          description: description7,
          id: this.state.formData.descriptions_attributes[6].id,
        },
        {
          description: description8,
          id: this.state.formData.descriptions_attributes[7].id,
        }
      ];

    }


    const orriginalData = {
      department_id,
      title,
      branch_id,
      send_for_approval,
      ...(attachment ? { attachment } : {}),
      descriptions_attributes,
    };

    const attrs = {
      department_id: values.department_id,
      title: values.title,
      send_for_approval: values.send_for_approval,
      ...(!id ? { template_id } : {}),
      descriptions_attributes: id ? editDescriptionsAttr() : createDescriptionsAttr,
      ...(values.attachment ? { attachment: values.attachment } : this.checkEmptyAttachment()),
      branch_id: values.branch_id,
    };

    let templatePayload = getDiffs(orriginalData, attrs);
    this.addEditTemplateApiCall(templatePayload)
  }

  addTemplate17Response = (responseData17: any, message: string) => {
    if (responseData17.data) {
      if (this.state.id) {
        const dataItem = responseData17.data.attributes
        this.setStateData(dataItem);
        this.setDefaultData17(dataItem)
      } else {
        this.setState({ ...this.initialState });
        this.navigateToListingPage()
      }
      this.handleActions(configJSON.view)
      toast.success(message);
    } else if (responseData17.message) {
      const {defaultData} = this.state
      const dataItem = {...defaultData, favorite: !defaultData.favorite};
      this.setStateData(dataItem);
      this.setDefaultData17(dataItem)
      toast.success(message);
    } else {
      handleApiError(responseData17.errors);
    }
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resData17 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const requestMessageCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resData17, this.props.navigation);
      this.setState({ loading: false });
      if (requestMessageCallId && resData17) {
        this.setState({ loading: false });
        switch (requestMessageCallId) {
          case this.createTemplateApiId:
            this.addTemplate17Response(resData17, configJSON.procedureSuccessMessage);
            break;
          case this.duplicateTemplateApiId:
            this.addTemplate17Response(resData17, configJSON.procedureDuplicateMessage);
            break;
          case this.deleteTemplateApiId:
            this.deleteTemplateResponse(resData17);
            break;
          case this.branchDataApiId:
            this.getBranchesResponse(resData17);
            break;
          case this.departmentsApiId:
            this.getDepartmentsResponse(resData17);
            break;
          case this.favouriteTemplateApiId:
            const toastMessage = this.state.formData.favorite ? configJSON.procedureUnFavMessage : configJSON.procedureFavMessage;
            this.addTemplate17Response(resData17, toastMessage);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
