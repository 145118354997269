// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonTemplateController from "./CommonTemplateController.web";
import { handleApiError, handleExpiredToken, checkToken, getDiffs, handleUndefinedData, getUserRoleID } from "../../../components/src/utils/commonFunctions";
import { ClassNameMap } from "@material-ui/styles";
import { CreateTemplate11Schema, EditTemplate11Schema } from "../../../components/src/utils/validationSchema";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"formContainer" | "inputField" | "dateField" | "reverseContainer"
    | "fieldWrapper" | "inputLabel" | "helperText" | "textAreaField" | "textAreaInputField" | "templateBtnWrapper"
    | "primaryBtn" | "fileInput" | "secondaryBtn" | "inputLabel2" | "approvalContainer" | "dropdownWrapper" | "uploadImageContainer" | "titleText" | "gridItem" | "templateFooterContainer"
  >;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class Template11Controller extends CommonTemplateController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {

    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.state = {
      ...this.initialState,
      ...this.state,
    }

    this.subScribedMessages = [
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  // Customizable Area Start
  componentDidMount(): any {

    // Customizable Area Start
    checkToken();

    this.getBranchData();

    let { state } = this.props.navigation.history.location;
    if (state) {
      this.setDefaultData11(state)
    } else if(![2,3].includes(Number(getUserRoleID()))) {
      this.props.navigation.goBack();
    }
    // Customizable Area End
  }

  template11InitialValues = () => {
    
    const { defaultData, formData, templateAction } = this.state;
    const isCreateTemplate = templateAction === configJSON.create;

    const data = {
      branch_id: isCreateTemplate ? defaultData.branch_id : handleUndefinedData(formData?.branch_id, ""),
      department_id: isCreateTemplate ? defaultData.department_id : handleUndefinedData(formData?.department_id, ""),
      title: isCreateTemplate ? defaultData.title : handleUndefinedData(formData?.title, ""),
      attachment: null,
      send_for_approval: isCreateTemplate ? defaultData.send_for_approval : handleUndefinedData(formData?.send_for_approval, false),
      description1: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[0]?.description, ""),
      description2: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[1]?.description, ""),
      description3: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[2]?.description, ""),
      description4: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[3]?.description, ""),
      description5: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[4]?.description, ""),
      image1: null,
      image2: null,
      image3: null,
      image4: null,
      image5: null,
    }
    return data;
  }
  template11ValidationSchema = () => {
    const { templateAction } = this.state;
    return templateAction === configJSON.edit ? EditTemplate11Schema : CreateTemplate11Schema
  }

  setDefaultData11(item: any, action?: string) {
    const { creator, id, attachment, descriptions_attributes } = item
    this.setState({
      defaultData: item,
      id,
      formData: item,
      creator,
      imageUrls: {
        image1: handleUndefinedData(descriptions_attributes[0].images[0], ""),
        image2: handleUndefinedData(descriptions_attributes[1].images[0], ""),
        image3: handleUndefinedData(descriptions_attributes[2].images[0], ""),
        image4: handleUndefinedData(descriptions_attributes[3].images[0], ""),
        image5: handleUndefinedData(descriptions_attributes[4].images[0], ""),
      },
      attachment: attachment.url ? attachment : "",
      templateAction: action ?? configJSON.view
    })
  }

  addEditTemplate11 = (values: any) => {
    const { formData, defaultData, template_id } = this.state;

    const { attachment, department_id, title, send_for_approval, branch_id, descriptions_attributes } = defaultData;
    const { description1, description2, description3, description4, description5, image1, image2, image3, image4, image5 } = values;

    const { id } = formData;

    const createDescriptionsAttr = [
      {
        images: [image1],
        description: description1,
      }, {
        images: [image2],
        description: description2,
      },
      {
        images: [image3],
        description: description3,
      }, {
        images: [image4],
        description: description4,
      }, {
        images: [image5],
        description: description5,
      },
    ];

    const editDescriptionsAttr = () => {
      return [
        {
          id: descriptions_attributes[0].id,
          ...(image1 ? { images: [image1] } : {}),
          description: description1,
        }, {
          id: descriptions_attributes[1].id,
          ...(image2 ? { images: [image2] } : {}),
          description: description2,
        },
        {
          id: descriptions_attributes[2].id,
          ...(image3 ? { images: [image3] } : {}),
          description: description3,
        }, {
          id: descriptions_attributes[3].id,
          ...(image4 ? { images: [image4] } : {}),
          description: description4,
        }, {
          id: descriptions_attributes[4].id,
          ...(image5 ? { images: [image5] } : {}),
          description: description5,
        },
      ];
    }

    const orriginalData11: any = {
      branch_id,
      department_id,
      title,
      descriptions_attributes,
      ...(attachment ? { attachment } : {}),
      send_for_approval,
    };

    const updatedData11: any = {
      send_for_approval: values.send_for_approval,
      department_id: values.department_id,
      title: values.title,
      branch_id: values.branch_id,
      ...(values.attachment ? { attachment: values.attachment } : this.checkEmptyAttachment()),
      ...(!id ? { template_id } : {}),
      descriptions_attributes: id ? editDescriptionsAttr() : createDescriptionsAttr,
    };

    let template11Payload: any = getDiffs(orriginalData11, updatedData11);
    this.addEditTemplateApiCall(template11Payload)
  }

  addTemplate11Response = (apiResData: any, message: string) => {
    if (apiResData.data) {
      if (this.state.id) {
        const dataItem = apiResData.data.attributes
        this.setStateData(dataItem);
        this.setDefaultData11(dataItem)
      } else {
        this.setState({ ...this.initialState });
        this.navigateToListingPage()
      }
      this.handleActions(configJSON.view)
      toast.success(message);
    } else if (apiResData.message) {
      const {defaultData} = this.state
      const dataItem = {...defaultData, favorite: !defaultData.favorite};
      this.setStateData(dataItem);
      this.setDefaultData11(dataItem)
      toast.success(message);
    } else {
      handleApiError(apiResData.errors);
    }
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resData11 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resData11, this.props.navigation);
      this.setState({ loading: false });
      if (requestCallId && resData11) {
        this.setState({ loading: false });
        switch (requestCallId) {
          case this.createTemplateApiId:
            this.addTemplate11Response(resData11, configJSON.procedureSuccessMessage);
            break;
          case this.duplicateTemplateApiId:
            this.addTemplate11Response(resData11, configJSON.procedureDuplicateMessage);
            break;
          case this.deleteTemplateApiId:
            this.deleteTemplateResponse(resData11);
            break;
          case this.branchDataApiId:
            this.getBranchesResponse(resData11);
            break;
          case this.departmentsApiId:
            this.getDepartmentsResponse(resData11);
            break;
          case this.favouriteTemplateApiId:
            const toastMessage = this.state.formData.favorite ? configJSON.procedureUnFavMessage : configJSON.procedureFavMessage;
            this.addTemplate11Response(resData11, toastMessage);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
