// Customizable Area Start
import React from "react";
import {
  InputLabel,
  TextField,
  Box,
  Checkbox,
  Button,
  Paper,
  Chip
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TicketTypeFormController, {
  Props,
  configJSON
} from "./TicketTypeFormController.web";
import styles from "./TicketTypeFormStyle.web";
import InfoModalWeb from "../../../components/src/commonComponents/modals/InfoModal.web";
import { Formik, Form } from "formik";
import {
  handleInvoiceErrors,
  handleInvoiceHelperText
} from "../../../components/src/utils/commonFunctions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import { createTicketTypeSchema } from "../../../components/src/utils/validationSchema";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class TicketTypeForm extends TicketTypeFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes, open, handleClose, isEdit } = this.props;
    const { department, department_id, issue_name, allSelect } = this.state;

    const description = (
      <>
        <Formik
          initialValues={{
            issue_name: issue_name,
            department_id: department_id,
          }}
          onSubmit={(values) => this.handleCreateTicket(values)}
          validateOnMount
          validationSchema={createTicketTypeSchema}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            isValid
          }) => (
            <Box>
              <Form translate={undefined}>
                <Box className={classes.issueField}>
                  <InputLabel data-test-id="issue_name" required className={classes.label}>
                    {configJSON.issueName}
                  </InputLabel>
                  <TextField
                    data-test-id="txtInputName"
                    variant="outlined"
                    placeholder={configJSON.enterIssueName}
                    name={"issue_name"}
                    value={values.issue_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={handleInvoiceErrors(
                      errors.issue_name,
                      touched.issue_name
                    )}
                    helperText={handleInvoiceHelperText(
                      errors.issue_name,
                      touched.issue_name
                    )}
                    className={classes.input}
                  />
                </Box>
                <InputLabel required className={classes.label}>
                  {configJSON.departments}
                </InputLabel>
                <Autocomplete
                  data-test-id="ticket-type-form-autocomplete"
                  multiple
                  disablePortal
                  limitTags={2}
                  onChange={(event) => {
                    this.handleChange(event, values, setFieldValue)
                  }}
                  open
                  value={values.department_id}
                  options={department}
                  disableCloseOnSelect
                  popupIcon={false}
                  onBlur={handleBlur}
                  classes={{
                    paper: classes.paperStyle,
                    popper: classes.popperStyle,
                    option: classes.options,
                    listbox: classes.listBox,
                  }}
                  getOptionLabel={option => option.name}
                  renderOption={option => {
                    const isSelected = values.department_id.find(
                      department => department.name == option.name
                    );
                    return (
                      <React.Fragment>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank />}
                          checkedIcon={
                            <CheckBox
                              className={classes.checkBoxStyle}
                            />
                          }
                          id={JSON.stringify(option)}
                          className={classes.optionMargin}
                          checked={Boolean(isSelected)}
                        />
                        {option.name}
                      </React.Fragment>
                    );
                  }}
                  fullWidth
                  renderInput={params => {
                    return (
                      <TextField
                        name="department_id"
                        {...params}
                        onBlur={handleBlur}
                        error={handleInvoiceErrors(
                          errors.department_id,
                          touched.department_id
                        )}
                        helperText={handleInvoiceHelperText(
                          errors.department_id,
                          touched.department_id
                        )}
                        variant="outlined"
                        placeholder={
                          values.department_id.length == 0
                            ? configJSON.selectDepartment
                            : ""
                        }
                        className={classes.input2}
                      />
                    );
                  }}
                  className={classes.inputauto}
                  PaperComponent={(paperProps) => {
                    const { children, ...restPaperProps } = paperProps
                    return (
                      <Paper {...restPaperProps}>
                        <Box px={2}>
                          <Checkbox
                            data-test-id="ticket-type-form-select-all"
                            onClick={() => this.handleSelectAll(setFieldValue)}
                            icon={<CheckBoxOutlineBlank />}
                            checkedIcon={
                              <CheckBox
                                className={classes.checkBoxStyle}
                              />
                            }
                            className={classes.optionMargin}
                            checked={Boolean(allSelect)}
                          />
                          {configJSON.selectAll}
                        </Box>
                        {children}
                      </Paper>
                    )
                  }}
                  renderTags={(tagValue) =>
                    tagValue.map((option) => (
                      <Chip
                        label={option.name}
                        className={classes.chipMargin}
                      />
                    ))
                  }
                />
                <Box className={classes.btnGroup}>
                  {isEdit &&
                    <Button
                      size="large"
                      variant="outlined"
                      className={classes.cnacelBtn}
                      disabled={!isValid}
                      onClick={handleClose}
                    >
                      {configJSON.cancel}
                    </Button>}
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    className={classes.primaryBtn}
                    disabled={!isValid}
                  >
                    {isEdit ? configJSON.save : configJSON.create}
                  </Button>
                </Box>
              </Form>
            </Box>
          )}
        </Formik>
      </>
    );
    return (
      <React.Fragment>
        <InfoModalWeb
          open={open}
          title={configJSON.createTicketType}
          closeIcn
          description={description}
          handleClose={handleClose}
        />
      </React.Fragment>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(TicketTypeForm);
