export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgFilter = require("../assets/filter.png");
export const imgGridList = require("../assets/grid-list.png");
export const imgListGrid = require("../assets/list-grid.png");
export const imgTemplate = require("../assets/template-img.png");
export const imgDelete = require("../assets/deleteIcon.png");
export const imgDuplicate = require("../assets/duplicate.png");
export const imgEdit = require("../assets/edit_image.png");
export const imgFavourite = require("../assets/favourite.png");
export const libraryIcon = require("../assets/library_Icons.png");
export const check_List_Bold_Icon = require("../assets/check_List_Bold_Icon.png");
export const check_List_Icon = require("../assets/check_List_Icon.svg");
export const form_Icon = require("../assets/form_Icon.png");