// Customizable Area Start
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
const img = require("./image_Group1.png");

interface Props {
  classes?: any;
  imagePath?: string;
}

const styles: any = {
  root: {
    paddingLeft: 52,
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
};

class LoginSignupRightSection extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, imagePath } = this.props;
    return (
      <div className={classes?.root}>
          <img src={imagePath ? imagePath : img} className={classes?.img} />
      </div>
    );
  }
}

export default withStyles(styles)(LoginSignupRightSection);
// Customizable Area End