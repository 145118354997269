import { colors } from "../../../components/src/utils/commonDesign";

export const styles: any = {
  card: {
    borderRadius: 16,
    height: "auto",
    maxHeight: "100%",
    width: "100%",
  },
  cardContent: {
    padding: "0 !important",
    margin: "20px 40px 50px",
    overflowY: "auto",
    height: "100%",
    "& .body1": {
      margin: 0,
      color: colors.primaryColor2,
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "21px"
    },
    "& .title1": {
      color: colors.primaryColor2,
      fontSize: "34px",
      fontWeight: 700,
      lineHeight: "45px",
    },
    "& .infoContainer": {
      marginTop: 30,
      display: "flex",
      "& .title2": {
        color: colors.primaryColor2,
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "24px",
      },
    },
    "& .pallettContainer": {
      maxWidth: 950,
      margin: "30px 50px 0px 0px",
      "& .palletBox": {
        height: 50,
        display: "flex",
        borderRadius: 16,
        overflow: "hidden",
        cursor: "pointer",
        border: "2px solid transparent",
        "&:hover": {
          border: "2px solid " + colors.primaryColor2 + "!important",
        },
        "& .palletColor": {
          height: "100%",
        },
        "& .color1": {
          flex: "1 1 50%",
        },
        "& .color2, .color3": {
          flex: "1 1 25%",
        },
      },
      "& .selectedTheme": {
        border: "2px solid " + colors.primaryColor2 + "!important",
      },
    },
    "& .customeColorContainer": {
      marginTop: 40,
      marginRight: 50,
      paddingBottom:40,
      "& .title": {
        color: colors.primaryColor2,
        lineHeight: "24px",
        fontSize: "18px",
        fontWeight: 600,
      },
      "& .fieldWrapper": {
        margin: "25px 0 3px",
        "& .MuiInputBase-root": {
          borderRadius: "10px",
          height: "56px",
          "& .MuiInputBase-input": {
            fontSize: "16px",
            lineHeight: "21px",
            "& .Mui-disabled": {
              backgroundColor: "#F5F5F5",
              color: "#020202",
              borderRadius: "10px",
            },
          },
        },
        "& .Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.secondaryColor1,
          },
        },
        "& .inputLabel": {
          fontSize: "16px",
          lineHeight: "21px",
          fontWeight: 300,
          color: colors.primaryColor2,
        },
        "& .inputField": {
          margin: 0,
          marginTop: "15px",
          "& .MuiInputBase-root": {
            borderRadius: "10px",
            height: "56px",
            "& .MuiInputBase-input": {
              fontSize: "16px",
              lineHeight: "21px",
            },
          },
          "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.secondaryColor1,
            },
          },
        },
      },
    },
    "& .btnWrapper1": {
      marginTop: "65px",
      display: "flex",
      justifyContent: "center",
      "& .btn": {
        margin: "0 16px",
        boxShadow: "none",
        fontWeight: 700,
        textTransform: "capitalize",
        height: "56px",
        width: "300px",
        borderRadius: "10px",
        fontSize: "16px",
        lineHeight: "21px",
        color: colors.primaryColor2,
      },
      "& .primaryBtn": {
        backgroundColor: colors.secondaryColor2,
        "&:hover": {
          backgroundColor: colors.secondaryColor2,
        }
      },
      "& .secondaryBtn": {
        border: `1px solid ${colors.primaryColor2}`,
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: colors.primaryColor1,
        }
      },
    },
  },
  helperText: {
    color: colors.errorText,
    margin: "-22px 0 22px 30px",
  },
  infoIcon: {
    height: 24,
    width: 24,
    marginLeft: 10,
    "& img": {
      cursor: "pointer",
      height: "100%",
      width: "100%"
    }
  },
  smallInfoIcon: {
    height: 18,
    width: 18,
    marginLeft: 7,
    zIndex: 1,
    "& .MuiTooltip-tooltip" : {
      color: "red !important",
      hight: "48px",
      boorderRadius: "8px",
      backgroundColor: "#fff",
      zIndex: 10000
    }
  },
  inputLabelInner: {
    display: "flex",
    alignItems: "center",
  },
  toolTip: {
    color: "#fff",
    background: 'rgba(2, 2, 2, 0.40)', 
    borderRadius: 8,
    height: "48px",
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "21px"
  }
};
