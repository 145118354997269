// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { ClassNameMap } from "@material-ui/styles";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import CommonTemplateController from "./CommonTemplateController.web";
import { handleApiError, handleExpiredToken, checkToken, getDiffs, handleUndefinedData, getUserRoleID } from "../../../components/src/utils/commonFunctions";
import { CreateTemplate14Schema, EditTemplate14Schema } from "../../../components/src/utils/validationSchema";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"formContainer" | "inputField" | "dateField"
    | "fieldWrapper" | "inputLabel" | "helperText" | "textAreaField" | "textAreaInputField" | "templateBtnWrapper"
    | "fileInput" | "primaryBtn" | "secondaryBtn" | "inputLabel2" | "approvalContainer" | "dropdownWrapper" | "uploadImageContainer" | "titleText" | "gridItem" | "templateFooterContainer"
  >;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class Template14Controller extends CommonTemplateController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      ...this.state,
      ...this.initialState,
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidMount(): any {
    // Customizable Area Start

    checkToken();
    this.getBranchData();

    let { state } = this.props.navigation.history.location;
    if (state) {
      this.setDefaultData14(state)
    } else if(![2,3].includes(Number(getUserRoleID()))) {
      this.props.navigation.goBack()
    }
    // Customizable Area End

  }

  setDefaultData14(item: any, action?: string) {
    const { id, attachment, descriptions_attributes , creator} = item
    this.setState({
      creator,
      id,
      defaultData: item,
      formData: item,
      imageUrls: {
        image1: handleUndefinedData(descriptions_attributes[0].images[0], ""),
      },
      attachment: attachment.url ? attachment : "",
      templateAction: action ?? configJSON.view
    })
  }

  template14InitialValues = () => {
    const { templateAction, defaultData, formData, } = this.state;
    const isCreateTemplate = templateAction === configJSON.create;
    const item = {
      attachment: null,
      branch_id: isCreateTemplate ? defaultData.branch_id : handleUndefinedData(formData?.branch_id, ""),
      department_id: isCreateTemplate ? defaultData.department_id : handleUndefinedData(formData?.department_id, ""),
      title: isCreateTemplate ? defaultData.title : handleUndefinedData(formData?.title, ""),
      send_for_approval: isCreateTemplate ? defaultData.send_for_approval : handleUndefinedData(formData?.send_for_approval, false),
      description1: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[0]?.description, ""),
      image1: null,
      description2: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[1]?.description, ""),
      description3: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[2]?.description, ""),
      description4: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[3]?.description, ""),
      description5: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[4]?.description, ""),
      description6: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[5]?.description, ""),
      description7: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[6]?.description, ""),
      description8: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[7]?.description, ""),
    }
    return item;
  }

  template14ValidationSchema = () => {
    const { templateAction } = this.state;
    return templateAction === configJSON.edit ? EditTemplate14Schema : CreateTemplate14Schema
  }

  isTemplate15 = () => {
    const { template_id } = this.state;
    return template_id === 15;
  }

  addEditTemplate14 = (values: any) => {
    const { formData, defaultData, template_id } = this.state;

    const { department_id, title, send_for_approval, attachment, branch_id, descriptions_attributes } = defaultData;
    const { description3, description1, description2, description4, description5, description6, description7, description8, image1 } = values;

    const { id } = formData;

    const createDescriptionsAttr = [
      {
        description: description1,
        images: [image1]
      }, {
        description: description2,
      }, {
        description: description3,
      }, {
        description: description4,
      },
      {
        description: description5,
      }, {
        description: description6,
      },
      {
        description: description7,
      }, {
        description: description8,
      },
    ];

    const editDescriptionsAttr = () => {
      return [
        {
          description: description1,
          id: this.state.formData.descriptions_attributes[0].id,
          ...(image1 ? { images: [image1] } : {}),
        },
        {
          description: description2,
          id: this.state.formData.descriptions_attributes[1].id,
        },
        {
          description: description3,
          id: this.state.formData.descriptions_attributes[2].id,
        },
        {
          description: description4,
          id: this.state.formData.descriptions_attributes[3].id,
        },
        {
          id: this.state.formData.descriptions_attributes[4].id,
          description: description5,
        }, {
          id: this.state.formData.descriptions_attributes[5].id,
          description: description6,
        },
        {
          id: this.state.formData.descriptions_attributes[6].id,
          description: description7,
        }, {
          id: this.state.formData.descriptions_attributes[7].id,
          description: description8,
        },
      ];

    }


    const orriginalData14: any = {
      title,
      branch_id,
      department_id,
      descriptions_attributes,
      send_for_approval,
      ...(attachment ? { attachment } : {}),
    };

    const updatedData14: any = {
      title: values.title,
      send_for_approval: values.send_for_approval,
      department_id: values.department_id,
      ...(values.attachment ? { attachment: values.attachment } : this.checkEmptyAttachment()),
      ...(!id ? { template_id } : {}),
      branch_id: values.branch_id,
      descriptions_attributes: id ? editDescriptionsAttr() : createDescriptionsAttr,
    };

    let template14Payload: any = getDiffs(orriginalData14, updatedData14);
    this.addEditTemplateApiCall(template14Payload)
  }

  addTemplate14Response = (responseData14: any, message: string) => {
    if (responseData14.data) {
      if (this.state.id) {
        const dataItem = responseData14.data.attributes
        this.setStateData(dataItem);
        this.setDefaultData14(dataItem)
      } else {
        this.setState({ ...this.initialState });
        this.navigateToListingPage()
      }
      this.handleActions(configJSON.view)
      toast.success(message);
    } else if (responseData14.message) {
      const {defaultData} = this.state
      const dataItem = {...defaultData, favorite: !defaultData.favorite};
      this.setStateData(dataItem);
      this.setDefaultData14(dataItem)
      toast.success(message);
    } else {
      handleApiError(responseData14.errors);
    }
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseData14 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const requestMessageCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(responseData14, this.props.navigation);
      this.setState({ loading: false });
      if (requestMessageCallId && responseData14) {
        this.setState({ loading: false });
        switch (requestMessageCallId) {
          case this.createTemplateApiId:
            this.addTemplate14Response(responseData14, configJSON.procedureSuccessMessage);
            break;
          case this.duplicateTemplateApiId:
            this.addTemplate14Response(responseData14, configJSON.procedureDuplicateMessage);
            break;
          case this.deleteTemplateApiId:
            this.deleteTemplateResponse(responseData14);
            break;
          case this.branchDataApiId:
            this.getBranchesResponse(responseData14);
            break;
          case this.departmentsApiId:
            this.getDepartmentsResponse(responseData14);
            break;
          case this.favouriteTemplateApiId:
            const toastMessage = this.state.formData.favorite ? configJSON.procedureUnFavMessage : configJSON.procedureFavMessage;
            this.addTemplate14Response(responseData14, toastMessage);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
