// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, handleApiError, handleExpiredToken } from "../../../components/src/utils/commonFunctions";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    classes:Record<string,string>

    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profileDetail: Company;
    loading:boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start
export interface Industry {
    data: {
        attributes: {
            id: number | string;
            name: string;
        };
    };
}

export interface BusinessType {
    data: {
        attributes: {
            id: number | string;
            name: string;
        };
    };
}
export interface Company {

    id: string;
    attributes: {
        id: number | string;
        name: string;
        account_id: number | string;
        owner_name: string;
        owner_email: string;
        phone_number: string;
        head_office: string;
        employee_count: number | string;
        creation_date: string;
        from_working_hours: string;
        to_working_hours: string;
        description: string;
        industry: Industry;
        business_type: BusinessType;
        country_code:string | null;
        country_code_and_flag_id:string | null;
        photo: string;
        industry_id:string;
        business_type_id:string
    };

}

// Customizable Area End

export default class ViewCompanyProfileController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    companyProfileDataCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            profileDetail: {
                id: "",
                attributes: {
                    id: "",
                    name: "",
                    account_id: "",
                    owner_name: "",
                    owner_email: "",
                    phone_number: "",
                    head_office: "",
                    employee_count: "",
                    creation_date: "",
                    from_working_hours: "",
                    to_working_hours: "",
                    description: "",
                    industry: {
                        data: {
                            attributes: {
                                id: "",
                                name: "",
                            }
                        }
                    },
                    business_type: {
                        data: {
                            attributes: {
                                id: "",
                                name: "",
                            }
                        }
                    },
                    photo: "",
                    country_code: null,
                    country_code_and_flag_id:"1",
                    industry_id:"", 
                    business_type_id:"",
                }
            },
            loading:false
        }
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getCompanyProfileData()
        // Customizable Area End
    }
    // Customizable Area Start

    handleEditClick = (item: Company) => {
        this.props.navigation.history.push("EditCompanyProfile", item)
    }
    getCompanyProfileData = () => {
        this.setState({loading:true})
        this.companyProfileDataCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.companyProfileApiEndPoint, token: true, });
    }
    handleResponseForCompanyProfileData = (from: string, message: Message) => {
        if (this.companyProfileDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(apiResponse, this.props.navigation);
            if (apiResponse.data) {
                this.setState({
                    profileDetail: apiResponse.data,
                    loading:false
                })

            } else {
                this.setState({loading:false})
                handleApiError(apiResponse.errors);
            }

        }
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResponseForCompanyProfileData(from, message)
        // Customizable Area End
    }
    // Customizable Area End


}
