export const openCircle = require("../assets/openCircle.png")
export const filledCircle = require("../assets/filledCircle.png")
export const attachIcon = require("../assets/pin-icon.png");
export const closeIcon = require("../assets/blackCloseIcon.png");
export const YellowsendIcon = require("../assets/YellowsendIcon.png");
export const EditIcon = require("../assets/EditIcon.png");
export const GreenEditIcon = require("../assets/GreenEditIcon.png");
export const CopyIcon = require("../assets/CopyIcon.png");
export const PendingIcon = require("../assets/PendingIcon.png");
export const InProgressIcon = require("../assets/InProgressIcon.png");
export const CompletedIcon = require("../assets/CompletedIcon.png");
export const RedolvedIcon = require("../assets/ResolvedIcon.png");
export const ReOpenedIcon = require("../assets/ReOpenedIcon.png");
export const DeleteIcon = require("../assets/DeleteIcon.png");
export const RedDeleteIcon = require("../assets/RedDeleteIcon.png");
export const CalenderIcon = require("../assets/calender.png");
export const image_upload = require("../assets/image_upload.png");
export const UploadFile = require("../assets/UploadFile.png");
export const favouriteIcon = require("../assets/favourite.png");
export const downloadIcon = require("../assets/download.png");
export const LeftArrowIcon = require("../assets/arrow-left.png");
export const RightArrowIcon = require("../assets/arrow-right.png");
export const ViewIcon = require("../assets/view.png");
export const FileDocumentIcon = require("../assets/file-document-icon.png");
export const profileImage = require("../assets/profile-icon.png");
export const DownArrowBlack= require("../assets/arrow-down-black.png");
