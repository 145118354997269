/** @format */

import React from "react";
// Customizable Area Start
import { commonInputField, commonButton, helperText, singleValueCss, commonTemplateCss } from "../../../components/src/utils/commonInputStyle";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, TextField, Button, Grid, Switch, FormHelperText } from "@material-ui/core";
import Template14Controller, { Props, configJSON } from "./Template14Controller.web"
import { Formik, Form } from 'formik';
import { Wrapper } from "../../../components/src/Wrapper";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import AttachFileInput from "../../../components/src/commonComponents/AttachFileInput.web";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import DropdownSearch from "../../../components/src/commonComponents/DropdownSearch.web";
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonButton,
  ...commonInputField,
  ...commonTemplateCss

});
// Customizable Area End

export class Template14 extends Template14Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { formData, loading, attachmentError, attachment, branch_data, department_data, isDownload, imageUrls, templateAction, openMenu, opeDeleteModal } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper navigation={this.props.navigation} backbtn={true} divider title={this.headerTitle()} bellIcn={true} homeIcn={true} child={
          <Box>
            <Formik
              initialValues={this.template14InitialValues()}
              validateOnBlur={true}
              enableReinitialize={true}
              validationSchema={this.template14ValidationSchema()}
              onSubmit={this.addEditTemplate14}
            >
              {({ touched, errors, handleBlur, handleChange, setFieldValue, values }) => (
                <Form translate className={`${classes.formContainer} ${isDownload && "textColor"}`}>
                  <Grid alignItems="stretch" direction="row" ref={this.pdfContentRef} className={classes.gridContainer} container spacing={5}>
                    <Grid item xs={10} sm={5} className={classes.gridItem}>
                      {this.isViewOnly() ?
                        <Typography variant="h4" data-test-id="title" className={`${classes.inputLabel2} title`}>
                          {values.title}
                        </Typography> :
                        <Box className={`marginTitle ${classes.fieldWrapper}`}>
                          <InputLabel className={`${classes.inputLabel2} labetText`} required>
                            {configJSON.templateTitleLabel}
                          </InputLabel>
                          <TextField
                            name={configJSON.templateTitleName}
                            onChange={handleChange}
                            type="text"
                            data-test-id="title_input_field"
                            variant="outlined"
                            value={values.title}
                            className={classes.inputField}
                            error={handleInvoiceErrors(
                              errors.title,
                              touched.title,
                            )}
                            onBlur={handleBlur}
                            helperText={handleInvoiceHelperText(
                              errors.title,
                              touched.title
                            )}
                            fullWidth
                          />
                        </Box>
                      }

                    </Grid>
                    <Grid item xs={2} sm={7} className={`${classes.gridItem} actionBtn ${isDownload && "visibility"}`}>
                      {templateAction !== configJSON.create &&
                        <ActionButton
                          isOpen={openMenu}
                          toggleDropdown={this.toggleMenu}
                          edit={this.allowEdit()}
                          deleteVisible={this.allowEdit()}
                          download
                          handleEdit={() => this.handleActions(configJSON.edit)}
                          handleDelete={this.handleDelete}
                          mark_as_favourite
                          favourite={formData.favorite}
                          handleFavourite={this.handleFavouriteClick}
                          handleDuplicate={this.handleDuplicateTemplate}
                          handleDownload={this.handleDownloadPdf}
                          duplicate={this.allowEdit()}
                        />
                      }
                    </Grid>
                    <Grid item className={classes.gridItem} xs={12}>
                      <div className={classes.dropdownContainer}>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            isDisabled={this.isViewOnly()}
                            value={values.branch_id}
                            singleValueCss={isDownload && singleValueCss}
                            options={branch_data}
                            id={configJSON.templateBranchName}
                            noOptionsMessage={configJSON.templateNoBranchMessage}
                            placeholder={configJSON.templateBranchPlaceholder}
                            isSearchable={true}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            isMyProfile={true}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            options={department_data}
                            value={values.department_id}
                            singleValueCss={isDownload && singleValueCss}
                            isSearchable={true}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            id={configJSON.templateDepartmentName}
                            noOptionsMessage={configJSON.templateNoDepartmentMessage}
                            errorText={handleInvoiceHelperText(
                              errors.department_id,
                              touched.department_id,
                            )}
                            placeholder={configJSON.templateDepartmentPlaceholder}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id,
                            )}
                            isMyProfile={true}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                          />
                        </Box>
                      </div>
                    </Grid>

                    <Grid item className={classes.gridItem} xs={12}>
                      <Grid container className={`${this.isTemplate15() && classes.reverseContainer} ${classes.gridContainer} ${classes.gridItem}`} spacing={5}>

                        <Grid item className={classes.gridItem} xs={12} sm={7}>
                          <Grid className={classes.gridContainer} container spacing={5}>
                            <Grid item className={classes.gridItem} xs={12} sm={6}>
                              <Box className={classes.fieldWrapper}>
                                <InputLabel className={classes.inputLabel} required>
                                  {configJSON.descriptionLabel}
                                </InputLabel>
                                <TextField
                                  value={values.description1}
                                  fullWidth
                                  type="text"
                                  name={configJSON.description1}
                                  variant="outlined"
                                  disabled={this.isViewOnly()}
                                  error={handleInvoiceErrors(
                                    errors.description1,
                                    touched.description1
                                  )}
                                  onBlur={handleBlur}
                                  helperText={handleInvoiceHelperText(
                                    errors.description1,
                                    touched.description1
                                  )}
                                  className={classes.inputField}
                                  onChange={handleChange}
                                />
                              </Box>
                              <Box className={classes.fieldWrapper}>
                                <InputLabel className={classes.inputLabel} required>
                                  {configJSON.descriptionLabel}
                                </InputLabel>
                                <TextField
                                  value={values.description2}
                                  variant="outlined"
                                  name={configJSON.description2}
                                  type="text"
                                  fullWidth
                                  disabled={this.isViewOnly()}
                                  className={classes.inputField}
                                  error={handleInvoiceErrors(
                                    errors.description2,
                                    touched.description2,
                                  )}
                                  onBlur={handleBlur}
                                  helperText={handleInvoiceHelperText(
                                    errors.description2,
                                    touched.description2
                                  )}
                                  onChange={handleChange}
                                />
                              </Box>
                              <Box className={classes.fieldWrapper}>
                                <InputLabel className={classes.inputLabel} required>
                                  {configJSON.descriptionLabel}
                                </InputLabel>
                                <TextField
                                  value={values.description3}
                                  type="text"
                                  variant="outlined"
                                  fullWidth
                                  disabled={this.isViewOnly()}
                                  name={configJSON.description3}
                                  error={handleInvoiceErrors(
                                    errors.description3,
                                    touched.description3
                                  )}
                                  className={classes.inputField}
                                  helperText={handleInvoiceHelperText(
                                    errors.description3,
                                    touched.description3
                                  )}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Box>
                              <Box className={classes.fieldWrapper}>
                                <InputLabel className={classes.inputLabel} required>
                                  {configJSON.descriptionLabel}
                                </InputLabel>
                                <TextField
                                  value={values.description4}
                                  disabled={this.isViewOnly()}
                                  variant="outlined"
                                  name={configJSON.description4}
                                  type="text"
                                  fullWidth
                                  className={classes.inputField}
                                  error={handleInvoiceErrors(
                                    errors.description4,
                                    touched.description4
                                  )}
                                  onBlur={handleBlur}
                                  helperText={handleInvoiceHelperText(
                                    errors.description4,
                                    touched.description4,
                                  )}
                                  onChange={handleChange}
                                />
                              </Box>
                            </Grid>
                            <Grid item className={classes.gridItem} xs={12} sm={6}>
                              <Box className={classes.fieldWrapper}>
                                <InputLabel className={classes.inputLabel} required>
                                  {configJSON.descriptionLabel}
                                </InputLabel>
                                <TextField
                                  fullWidth
                                  type="text"
                                  name={configJSON.description5}
                                  value={values.description5}
                                  variant="outlined"
                                  onChange={handleChange}
                                  disabled={this.isViewOnly()}
                                  error={handleInvoiceErrors(
                                    errors.description5,
                                    touched.description5
                                  )}
                                  onBlur={handleBlur}
                                  helperText={handleInvoiceHelperText(
                                    errors.description5,
                                    touched.description5
                                  )}
                                  className={classes.inputField}
                                />
                              </Box>
                              <Box className={classes.fieldWrapper}>
                                <InputLabel className={classes.inputLabel} required>
                                  {configJSON.descriptionLabel}
                                </InputLabel>
                                <TextField
                                  variant="outlined"
                                  onChange={handleChange}
                                  name={configJSON.description6}
                                  value={values.description6}
                                  type="text"
                                  fullWidth
                                  disabled={this.isViewOnly()}
                                  className={classes.inputField}
                                  error={handleInvoiceErrors(
                                    errors.description6,
                                    touched.description6,
                                  )}
                                  onBlur={handleBlur}
                                  helperText={handleInvoiceHelperText(
                                    errors.description6,
                                    touched.description6
                                  )}
                                />
                              </Box>
                              <Box className={classes.fieldWrapper}>
                                <InputLabel className={classes.inputLabel} required>
                                  {configJSON.descriptionLabel}
                                </InputLabel>
                                <TextField
                                  onChange={handleChange}
                                  type="text"
                                  variant="outlined"
                                  value={values.description7}
                                  fullWidth
                                  disabled={this.isViewOnly()}
                                  name={configJSON.description7}
                                  error={handleInvoiceErrors(
                                    errors.description7,
                                    touched.description7
                                  )}
                                  className={classes.inputField}
                                  helperText={handleInvoiceHelperText(
                                    errors.description7,
                                    touched.description7
                                  )}
                                  onBlur={handleBlur}
                                />
                              </Box>
                              <Box className={classes.fieldWrapper}>
                                <InputLabel className={classes.inputLabel} required>
                                  {configJSON.descriptionLabel}
                                </InputLabel>
                                <TextField
                                  disabled={this.isViewOnly()}
                                  variant="outlined"
                                  name={configJSON.description8}
                                  type="text"
                                  value={values.description8}
                                  fullWidth
                                  className={classes.inputField}
                                  onChange={handleChange}
                                  error={handleInvoiceErrors(
                                    errors.description8,
                                    touched.description8
                                  )}
                                  onBlur={handleBlur}
                                  helperText={handleInvoiceHelperText(
                                    errors.description8,
                                    touched.description8,
                                  )}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={`${classes.gridItem} marginTop`} xs={12} sm={5}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`${classes.uploadImageContainer} templateHeight3`}>
                              <ImageUploadView
                                name={configJSON.image1}
                                setFieldValue={setFieldValue}
                                currentImage={imageUrls[configJSON.image1]?.url}
                                uploadTexts={["Upload Image"]}
                                isDisabled={this.isViewOnly()}
                                handleImageChange={this.handleImageChange}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image1,
                              touched.image1
                            ) && (

                                <FormHelperText
                                  style={helperText}
                                >

                                  {handleInvoiceHelperText(
                                    errors.image1,
                                    touched.image1,
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box className={classes.templateFooterContainer}>
                    <Box className={classes.approvalContainer}>
                      <Typography
                        variant="h4" className={`${classes.inputLabel2} approvalText`}
                      >
                        {configJSON.sendForApproval}
                      </Typography>
                      <Switch
                        disabled={this.state.templateAction !== configJSON.create}
                        color="primary"
                        onChange={handleChange}
                        name="send_for_approval"
                        checked={values.send_for_approval}
                      />
                    </Box>
                    <AttachFileInput
                      setFieldValue={setFieldValue}
                      templateAction={templateAction}
                      isViewOnly={this.isViewOnly}
                      attachment={attachment}
                      attachmentError={attachmentError}
                      handleFileUpload={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(e, setFieldValue) }}
                      clearAttachment={this.clearAttachment.bind(null, setFieldValue)}
                    />
                  </Box>
                  <Box className={classes.templateBtnWrapper}>
                    {!this.isViewOnly() &&
                      <Button
                        variant="contained"
                        type="submit"
                        className={classes.primaryBtn}
                        data-test-id="create-btn"
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              onClose={this.handleDeleteClose}
              open={opeDeleteModal}
              from={configJSON.procedureFrom}
              confirmDelete={this.handleDeleteTemplate}
              title={configJSON.templateDeleteTitle}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(Template14);
// Customizable Area End

