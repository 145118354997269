// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, Button, Avatar} from "@material-ui/core";
import CompanyHierarchyController, {
  Props,
  configJSON,
} from "./CompanyHierarchyController.web";
import { Wrapper } from "../../../components/src/Wrapper";
import Loader from "../../../components/src/Loader";
import Tree from "react-d3-tree";
import { colors } from "../../../components/src/utils/commonDesign";
import { singleEmployee, multiEmployee, upArrow, downArrow } from "./assets"
import { profileImage } from "../../../components/src/commonComponents/assets";

// Customizable Area End

// Customizable Area Start

export const styles = {
  hierarchyTitle: {
    fontSize: "34px",
    fontWeight: 600,
    lineHeight: "45px",
  },
  containerStyles: {
    width: "100%",
    height: "99vh"
  },
  boxContainer: {
    width: "172px",
    height: "176px",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    backgroundColor: colors.primaryColor1
  },
  imgContainer: {
    width: "84px",
    height: "84px",
    margin: "0 auto",
    border: `2px solid ${colors.secondaryColor2}`,
    borderRadius: "50%",
    position: "relative" as const,
    overflow: "hidden",
    textAlign: "center" as const,
    backgroundColor: "#fff",
  },
  avatar: {
    height: "100%",
    width: "100%",
    "& img": {
      objectFit: "contain",
      textAlign: "center",
    }
  },
  detailsContainer: {
    height: "80px",
    paddingTop: "6px",
    width: "100%",
    backgroundColor: colors.secondaryColor2,
    borderRadius: "4px",
    marginTop: "-33px",
  },
  detailsInnerContainer: {
    height: "100%",
    backgroundColor: "#bcc2be",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 5px 0px",
  },
  btnContainer: {
    paddingLeft: "14px",
    paddingRight: "14px",
    backgroundColor: colors.secondaryColor1,
    width: "100%",
    margin: "0 auto",
    borderRadius: "17px",
    color: "#fff",
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: colors.secondaryColor1,
    },
    "&.Supervisor": {
      width: "60%"
    },
  },
  countText: {
    fontSize: "14px",
    color: "#fff",
    fontWeight: 400,
    fontFamily: "Bitter-VariableFont",
    "&.childCount": {
      fontWeight: 700,
    }
  },
  nameText: {
    maxWidth: "100%",
    fontSize: "12px",
    fontWeight: 600,
    "&.roleNameText": {
      fontWeight: 500,
    }
  }
};
// Customizable Area End

export class CompanyHierarchy extends CompanyHierarchyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { loading, translate, myTreeData } = this.state;

    const nodeSize = { x: 172, y: 176 };
    const foreignObjectProps = {
      width: nodeSize.x,
      height: nodeSize.y,
      x: -0.5 * nodeSize.x,
      y: -1 * nodeSize.y
    };
    const renderForeignObjectNode = ({
      nodeDatum,
      toggleNode,
      foreignObjectProps
    }: any) => {
      return (
        <g>
          <foreignObject {...foreignObjectProps}>
            <Box className={classes.boxContainer}>
              <Box>
                <Box className={classes.imgContainer}>
                  <Avatar alt="Profile Picture" src={nodeDatum.profile_picture ??  profileImage} className={classes.avatar} />
                </Box>
                <Box data-test-id="user-detail-card" className={classes.detailsContainer} onClick={() => this.goToUserDetail(nodeDatum.id)}>
                  <Box className={classes.detailsInnerContainer}>
                    <Typography data-test-id="user-name" noWrap variant="h6" className={classes.nameText}>
                      {nodeDatum.name}
                    </Typography>
                    <Typography noWrap variant="h6" className={`${classes.nameText} roleNametext`}>
                      {this.showDepartments(nodeDatum.role_name) ? `${nodeDatum.role_name}/${nodeDatum.departments.join(", ")}` : nodeDatum.role_name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {nodeDatum.direct_child_count > 0 && <Button onClick={toggleNode} className={`${classes.btnContainer} ${nodeDatum.role_name}`} data-test-id="count-btn" >
                {!this.isSupervisor(nodeDatum.role_name) &&
                  <>
                    <img src={multiEmployee} alt="All-Employees" />
                    <Typography variant="h6" className={classes.countText}>
                      {nodeDatum.total_child_count}
                    </Typography>
                  </>
                }
                <img src={singleEmployee} alt="Child-Employees" />
                <Typography variant="h6" className={`${classes.countText} childCount`}>
                  {nodeDatum.direct_child_count}
                </Typography>
                <img src={nodeDatum.__rd3t.collapsed ? downArrow : upArrow} alt="Expand" />
              </Button>}
            </Box>
          </foreignObject>
        </g>
      );
    };

    return (
      <>
        <Loader loading={loading} />
        <Wrapper
          data-test-id="wrapper-comp"
          divider
          bellIcn={true}
          backbtn={true}
          navigation={this.props.navigation}
          title={configJSON.headerTitle}
          homeIcn={true}
          child={
            <>
              <Typography variant="h5" className={classes.hierarchyTitle} data-test-id="hierarcht-text">
                {configJSON.companyHierarchyTitle}
              </Typography>

              <div className={classes.containerStyles} id="tree-container">
                {myTreeData.length > 0 && <Tree
                  svgClassName="tree-container"
                  pathFunc={(linkData) => {
                    const { source, target } = linkData;
                    const deltaY = target.y - source.y;
                    return `M${source.x},${source.y} V${source.y + deltaY / 6} H${target.x} V${target.y - (target.children ? 0 : 50)}`;
                  }}
                  depthFactor={250}
                  separation={{ nonSiblings: 1.3, siblings: 1.3 }}
                  data={myTreeData}
                  translate={translate}
                  nodeSize={nodeSize}
                  initialDepth={0}
                  renderCustomNodeElement={(rd3tProps: any) =>
                    renderForeignObjectNode({
                      ...rd3tProps,
                      foreignObjectProps
                    })
                  }
                  orientation="vertical"
                />}
              </div>
            </>
          }
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(CompanyHierarchy);
// Customizable Area End
