import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@material-ui/core';
import { styles } from "./ModalStyles.web";
import moment from "moment";
import { EditIcon, DeleteIcon, closeIcon } from "../assets";


interface Props {
  classes?: any;
  open: boolean;
  title?: string;
  list?: any;
  cancelText?: string;
  handleClose?: () => void;
  handleEdit: any;
  handleDelete: any;
  userId: number;
}

class TicketCommentsModal extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { open, classes, title, list, handleClose, handleEdit, handleDelete, userId } = this.props;
    return (
      <div className={classes?.modalWrapper}>
        <Dialog
          open={open}
          aria-labelledby="bulk-info-modal-title"
          aria-describedby="bulk-info-modal-description"
          className={classes?.customModal}
        >
          <Box className={"titleContainer"}>
            <DialogTitle id="bulk-info-modal-title">{title && title}</DialogTitle>
            <Box className={"closeIcon"}>
              <img src={closeIcon} onClick={handleClose} alt="close-icon" />
            </Box>
          </Box>
          <DialogContent>
            <ul className="comment-ul">
              {list.map((item: any) => (
                <li key={item.id} style={{ margin: "0 0 10px 0" }}>
                  <Typography variant="body1" className={"commentsDetails"}>
                    {item.comment} {item.document?.url && <span>- <a className={`attachment`} href={item.document.url} target="_blank">View Attachment</a></span>}
                  </Typography>
                  <Typography variant="body1" className={`commentsDetails detailsContainer`}>
                    <Box>
                      <span style={{ fontFamily: "Bitter-VariableFont" }}>{moment(item.created_at).format("DD/MM/YYYY")}</span> {item.updated_at && "(Edited)"} - <span style={{ opacity: "0.6" }}>{item.owner_name}</span>
                    </Box>
                    {userId === item.owner_id && <Box>
                      <img onClick={() => handleEdit(item)} src={EditIcon} alt="EditIcon" />
                      <img onClick={() => handleDelete(item)} src={DeleteIcon} alt="DeleteIcon" />
                    </Box>}
                  </Typography>
                </li>
              ))}
            </ul>

          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(TicketCommentsModal);
