import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { styles } from "./ModalStyles.web";

const closeIcon = require("../../../../components/src/assets/blackCloseIcon.png");


interface Props {
  classes?: any;
  open: boolean;
  title?: string;
  description?: string | string[];
  cancelText?: string;
  handleClose?: () => void;
}

class BulkInviteInfoModal extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { open, classes, title, description, handleClose } = this.props;
    return (
      <div className={classes?.modalWrapper}>
        <Dialog
          open={open}
          aria-labelledby="bulk-info-modal-title"
          aria-describedby="bulk-info-modal-description"
          className={classes?.customModal}
        >
          <Box className={"titleContainer"}>
            <DialogTitle id="bulk-info-modal-title">{title && title}</DialogTitle>
            <Box className={"closeIcon"}>
              <img data-test-id="close_btn" src={closeIcon} onClick={handleClose} alt="close-icon" />
            </Box>
          </Box>
          <DialogContent>
            {typeof description === "string" ?
              <DialogContentText id="bulk-info-modal-description">
                {description}
              </DialogContentText>
              : description?.map(desc =>
                <DialogContentText className="array-description" key={desc} id="bulk-info-modal-description">
                  <span className="star-list">* </span>{desc}
                </DialogContentText>
              )
            }
          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(BulkInviteInfoModal);
