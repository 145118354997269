import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, TextField, Button, Grid, Switch, FormHelperText } from "@material-ui/core";
import Template11Controller, { Props, configJSON } from "./Template11Controller.web"
import { commonInputField, commonButton, helperText, singleValueCss, commonTemplateCss } from "../../../components/src/utils/commonInputStyle";
import { Formik, Form } from 'formik';
import { Wrapper } from "../../../components/src/Wrapper";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import DropdownSearch from "../../../components/src/commonComponents/DropdownSearch.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import AttachFileInput from "../../../components/src/commonComponents/AttachFileInput.web";
import Loader from "../../../components/src/Loader";

// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonButton,
  ...commonTemplateCss,
  ...commonInputField

});
// Customizable Area End

export class Template11 extends Template11Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start
  render() {
    const { classes } = this.props;
    const { attachmentError, branch_data, formData, attachment, loading, department_data, templateAction, openMenu, opeDeleteModal, isDownload, imageUrls } = this.state;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper navigation={this.props.navigation} backbtn={true} divider title={this.headerTitle()} homeIcn={true} bellIcn child={
          <Box>
            <Formik
              validationSchema={this.template11ValidationSchema()}
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={this.template11InitialValues()}
              onSubmit={this.addEditTemplate11}
            >
              {({ touched, handleBlur, handleChange, setFieldValue, values, errors }) => (
                <Form className={`${classes.formContainer} ${isDownload && "textColor"}`} translate>
                  <Grid ref={this.pdfContentRef} direction="row" alignItems="stretch" className={classes.gridContainer} container spacing={5}>
                    <Grid item className={classes.gridItem} xs={10} sm={5}>
                      {this.isViewOnly() ?
                        <Typography
                          data-test-id="title"
                          variant="h4" className={`${classes.inputLabel2} title`}
                        >
                          {values.title}
                        </Typography> :
                        <Box className={`${classes.fieldWrapper} marginTitle`}>
                          <InputLabel className={`labetText ${classes.inputLabel2}`} required>
                            {configJSON.templateTitleLabel}
                          </InputLabel>
                          <TextField
                            className={classes.inputField}
                            name={configJSON.templateTitleName}
                            type="text"
                            variant="outlined"
                            error={handleInvoiceErrors(
                              errors.title,
                              touched.title,
                            )}
                            value={values.title}
                            fullWidth
                            helperText={handleInvoiceHelperText(
                              errors.title,
                              touched.title
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            data-test-id="title_input_field"
                          />
                        </Box>
                      }
                    </Grid>
                    <Grid item className={`actionBtn ${classes.gridItem} ${isDownload && "visibility"}`} xs={2} sm={7}>
                      {templateAction !== configJSON.create &&
                        <ActionButton
                          toggleDropdown={this.toggleMenu}
                          deleteVisible={this.allowEdit()}
                          handleDownload={this.handleDownloadPdf}
                          download
                          mark_as_favourite
                          favourite={formData.favorite}
                          handleFavourite={this.handleFavouriteClick}
                          handleEdit={() => this.handleActions(configJSON.edit)}
                          edit={this.allowEdit()}
                          handleDelete={this.handleDelete}
                          isOpen={openMenu}
                          handleDuplicate={this.handleDuplicateTemplate}
                          duplicate={this.allowEdit()}
                        />
                      }
                    </Grid>
                    <Grid item className={classes.gridItem} xs={12}>
                      <div className={classes.dropdownContainer}>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            singleValueCss={isDownload && singleValueCss}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                            options={branch_data}
                            id={configJSON.templateBranchName}
                            noOptionsMessage={configJSON.templateNoBranchMessage}
                            placeholder={configJSON.templateBranchPlaceholder}
                            isSearchable={true}
                            isDisabled={this.isViewOnly()}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            value={values.branch_id}
                            isMyProfile={true}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            isSearchable={true}
                            options={department_data}
                            value={values.department_id}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                            singleValueCss={isDownload && singleValueCss}
                            isMyProfile={true}
                            errorText={handleInvoiceHelperText(
                              errors.department_id,
                              touched.department_id,
                            )}
                            placeholder={configJSON.templateDepartmentPlaceholder}
                            id={configJSON.templateDepartmentName}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id,
                            )}
                            noOptionsMessage={configJSON.templateNoDepartmentMessage}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid id="container1" item xs={12} sm={6} className={classes.gridItem}>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel required className={classes.inputLabel}>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          fullWidth
                          type="text"
                          disabled={this.isViewOnly()}
                          value={values.description1}
                          error={handleInvoiceErrors(
                            errors.description1,
                            touched.description1
                          )}
                          onBlur={handleBlur}
                          helperText={handleInvoiceHelperText(
                            errors.description1,
                            touched.description1
                          )}
                          onChange={handleChange}
                          className={classes.inputField}
                          name={configJSON.description1}
                        />
                      </Box>
                    </Grid>
                    <Grid item className={`${classes.gridItem} marginTop`} xs={12} sm={6}>
                      <div id="container2" className={`${classes.gridItem} gridItem11`}>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <Box className={`${classes.uploadImageContainer} templateHeight11`}>
                            <ImageUploadView
                              setFieldValue={setFieldValue}
                              handleImageChange={this.handleImageChange}
                              name={configJSON.image2}
                              uploadTexts={configJSON.uploadImageText}
                              currentImage={imageUrls[configJSON.image2]?.url}
                              isDisabled={this.isViewOnly()}
                            />
                          </Box>
                          {handleInvoiceErrors(
                            errors.image2,
                            touched.image2
                          ) && (
                              <FormHelperText
                                style={helperText}
                              >
                                {handleInvoiceHelperText(
                                  errors.image2,
                                  touched.image2
                                )}
                              </FormHelperText>
                            )}
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <InputLabel className={classes.inputLabel} required>
                            {configJSON.descriptionLabel}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            fullWidth
                            type="text"
                            disabled={this.isViewOnly()}
                            value={values.description2}
                            error={handleInvoiceErrors(
                              errors.description2,
                              touched.description2
                            )}
                            onBlur={handleBlur}
                            helperText={handleInvoiceHelperText(
                              errors.description2,
                              touched.description2
                            )}
                            onChange={handleChange}
                            className={classes.inputField}
                            name={configJSON.description2}
                          />
                        </Box>
                      </div>
                      <div id="container3" className={`${classes.gridItem} gridItem11`}>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <Box className={`${classes.uploadImageContainer} templateHeight11`}>
                            <ImageUploadView
                              setFieldValue={setFieldValue}
                              handleImageChange={this.handleImageChange}
                              name={configJSON.image3}
                              uploadTexts={configJSON.uploadImageText}
                              currentImage={imageUrls[configJSON.image3]?.url}
                              isDisabled={this.isViewOnly()}
                            />
                          </Box>
                          {handleInvoiceErrors(
                            errors.image3,
                            touched.image3
                          ) && (
                              <FormHelperText
                                style={helperText}
                              >
                                {handleInvoiceHelperText(
                                  errors.image3,
                                  touched.image3
                                )}
                              </FormHelperText>
                            )}
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <InputLabel className={classes.inputLabel} required>
                            {configJSON.descriptionLabel}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            fullWidth
                            type="text"
                            disabled={this.isViewOnly()}
                            value={values.description3}
                            error={handleInvoiceErrors(
                              errors.description3,
                              touched.description3
                            )}
                            onBlur={handleBlur}
                            helperText={handleInvoiceHelperText(
                              errors.description3,
                              touched.description3
                            )}
                            onChange={handleChange}
                            className={classes.inputField}
                            name={configJSON.description3}
                          />
                        </Box>
                      </div>
                      <div id="container4" className={`${classes.gridItem} gridItem11`}>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <Box className={`${classes.uploadImageContainer} templateHeight11`}>
                            <ImageUploadView
                              setFieldValue={setFieldValue}
                              handleImageChange={this.handleImageChange}
                              name={configJSON.image4}
                              uploadTexts={configJSON.uploadImageText}
                              currentImage={imageUrls[configJSON.image4]?.url}
                              isDisabled={this.isViewOnly()}
                            />
                          </Box>
                          {handleInvoiceErrors(
                            errors.image4,
                            touched.image4
                          ) && (
                              <FormHelperText
                                style={helperText}
                              >
                                {handleInvoiceHelperText(
                                  errors.image4,
                                  touched.image4
                                )}
                              </FormHelperText>
                            )}
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <InputLabel className={classes.inputLabel} required>
                            {configJSON.descriptionLabel}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            fullWidth
                            type="text"
                            disabled={this.isViewOnly()}
                            value={values.description4}
                            error={handleInvoiceErrors(
                              errors.description4,
                              touched.description4
                            )}
                            onBlur={handleBlur}
                            helperText={handleInvoiceHelperText(
                              errors.description4,
                              touched.description4
                            )}
                            onChange={handleChange}
                            className={classes.inputField}
                            name={configJSON.description4}
                          />
                        </Box>
                      </div>
                      <div id="container5" className={`${classes.gridItem} gridItem11`}>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <Box className={`${classes.uploadImageContainer} templateHeight11`}>
                            <ImageUploadView
                              setFieldValue={setFieldValue}
                              handleImageChange={this.handleImageChange}
                              name={configJSON.image5}
                              uploadTexts={configJSON.uploadImageText}
                              currentImage={imageUrls[configJSON.image5]?.url}
                              isDisabled={this.isViewOnly()}
                            />
                          </Box>
                          {handleInvoiceErrors(
                            errors.image5,
                            touched.image5
                          ) && (
                              <FormHelperText
                                style={helperText}
                              >
                                {handleInvoiceHelperText(
                                  errors.image5,
                                  touched.image5
                                )}
                              </FormHelperText>
                            )}
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <InputLabel className={classes.inputLabel} required>
                            {configJSON.descriptionLabel}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            fullWidth
                            type="text"
                            disabled={this.isViewOnly()}
                            value={values.description5}
                            error={handleInvoiceErrors(
                              errors.description5,
                              touched.description5
                            )}
                            onBlur={handleBlur}
                            helperText={handleInvoiceHelperText(
                              errors.description5,
                              touched.description5
                            )}
                            onChange={handleChange}
                            className={classes.inputField}
                            name={configJSON.description5}
                          />
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                  <Box className={classes.templateFooterContainer}>
                    <Box className={classes.approvalContainer}>
                      <Typography className={`${classes.inputLabel2} approvalText`} variant="h4">
                        {configJSON.sendForApproval}
                      </Typography>
                      <Switch
                        color="primary"
                        onChange={handleChange}
                        name="send_for_approval"
                        disabled={this.state.templateAction !== configJSON.create}
                        checked={values.send_for_approval}
                      />
                    </Box>
                    <AttachFileInput
                      setFieldValue={setFieldValue}
                      attachment={attachment}
                      templateAction={templateAction}
                      isViewOnly={this.isViewOnly}
                      clearAttachment={this.clearAttachment.bind(null, setFieldValue)}
                      handleFileUpload={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(e, setFieldValue) }}
                      attachmentError={attachmentError}
                    />
                  </Box>
                  <Box className={classes.templateBtnWrapper}>
                    {!this.isViewOnly() &&
                      <Button
                        data-test-id="create-btn"
                        type="submit"
                        variant="contained"
                        className={classes.primaryBtn}
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              onClose={this.handleDeleteClose}
              open={opeDeleteModal}
              title={configJSON.templateDeleteTitle}
              from={configJSON.procedureFrom}
              confirmDelete={this.handleDeleteTemplate}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(Template11);
// Customizable Area End

