import React from "react";
// Customizable Area Start
import { Typography, Box, InputLabel, TextField, Button, Grid, FormHelperText, Switch, InputAdornment } from "@material-ui/core";
import MenuTemplate2Controller, { Props, configJSON } from "./MenuTemplate2Controller.web"
import { withStyles, createStyles } from "@material-ui/core/styles";
import { commonInputField, commonButton, helperText, singleValueCss, commonTemplateCss } from "../../../components/src/utils/commonInputStyle";
import { Formik, Form } from 'formik';
import { Wrapper } from "../../../components/src/Wrapper";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import { allowOnlyPrice, handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import DropdownSearch from "../../../components/src/commonComponents/DropdownSearch.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";

// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonTemplateCss,
  ...commonInputField,
  ...commonButton

});
// Customizable Area End

export class MenuTemplate2 extends MenuTemplate2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { loading, imageUrl, menuData, branchData, categoryData, departmentData, menuTempAction, openMenu, opeDeleteModal, isDownload } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper bellIcn backbtn={true} navigation={this.props.navigation} divider title={this.headerTitle()} homeIcn={true} child={
          <Box>
            <Formik
              enableReinitialize={true}
              validationSchema={this.menuTempValidationSchema()}
              validateOnBlur={true}
              onSubmit={this.addEditTemplate}
              initialValues={this.menuTempInitialValues()}
            >
              {({ touched, handleBlur, handleChange, setFieldValue, values, errors }) => (
                <Form translate className={`${classes.formContainer} removePadding ${isDownload && "textColor"}`}>
                  <div className={`${classes.actionBtnContainer} ${isDownload && "visibility"}`}>
                    {menuTempAction !== configJSON.create &&
                      <ActionButton
                        deleteVisible={this.allowMenuEdit()}
                        toggleDropdown={this.toggleMenu}
                        handleDownload={this.downloadMenuTemp}
                        edit={this.allowMenuEdit()}
                        download
                        mark_as_favourite
                        favourite={menuData.favorite}
                        handleFavourite={this.handleMenuFavouriteClick}
                        handleDelete={this.handleDelete}
                        handleEdit={() => this.handleActions(configJSON.edit)}
                        handleDuplicate={this.handleDuplicateMenuTemp}
                        duplicate={this.allowMenuEdit()}
                        isOpen={openMenu}
                      />
                    }
                  </div>
                  <Grid direction="row" ref={this.pdfRef} alignItems="stretch" className={classes.gridContainer} container spacing={5}>
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      {this.isViewOnly() ?
                        <Typography data-test-id="title" variant="h4" className={`title ${classes.inputLabel2}`}>
                          {values.name}
                        </Typography> :
                        <Box className={`marginTitle ${classes.fieldWrapper}`}>
                          <InputLabel required className={`${classes.inputLabel}`}>
                            {configJSON.menuTempNameLabel}
                          </InputLabel>
                          <TextField
                            name={configJSON.menuTempNameName}
                            type="text"
                            data-test-id="title_input_field"
                            fullWidth
                            variant="outlined"
                            value={values.name}
                            error={handleInvoiceErrors(
                              errors.name,
                              touched.name,
                            )}
                            onChange={handleChange}
                            helperText={handleInvoiceHelperText(
                              errors.name,
                              touched.name
                            )}
                            onBlur={handleBlur}
                            className={classes.inputField}
                          />
                        </Box>
                      }
                      <div className={classes.dropdownContainer}>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            isSearchable={true}
                            singleValueCss={isDownload && singleValueCss}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                            value={values.branch_id}
                            id={configJSON.templateBranchName}
                            options={branchData}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            noOptionsMessage={configJSON.templateNoBranchMessage}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            placeholder={configJSON.templateBranchPlaceholder}
                            isDisabled={this.isViewOnly()}
                            isMyProfile={true}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            options={departmentData}
                            value={values.department_id}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                            noOptionsMessage={configJSON.templateNoDepartmentMessage}
                            singleValueCss={isDownload && singleValueCss}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            errorText={handleInvoiceHelperText(
                              errors.department_id,
                              touched.department_id,
                            )}
                            placeholder={configJSON.templateDepartmentPlaceholder}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id,
                            )}
                            isMyProfile={true}
                            id={configJSON.templateDepartmentName}
                            isSearchable={true}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <Box className={`${classes.fieldWrapper} marginTitle`}>
                        <InputLabel required className={classes.inputLabel}>
                          {configJSON.menuTempCategoryLabel}
                        </InputLabel>
                        <DropdownSearch
                          options={categoryData}
                          value={values.menu_type_id}
                          onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "menu_type_id")}
                          noOptionsMessage={configJSON.menuTempNoCategoryMessage}
                          isDisabled={this.isViewOnly()}
                          isSearchable={true}
                          showError={handleInvoiceErrors(
                            errors.menu_type_id,
                            touched.menu_type_id
                          )}
                          singleValueCss={isDownload && singleValueCss}
                          errorText={handleInvoiceHelperText(
                            errors.menu_type_id,
                            touched.menu_type_id
                          )}
                          onBlur={handleBlur}
                          id={configJSON.menuTempCategoryName}
                          placeholder={""}
                        />
                      </Box>
                      <Box className={`${classes.menuTypeContainer}`} >
                        <Box className={classes.approvalContainer}>
                          <Typography variant="h4" className={`${classes.inputLabel2} approvalText`}>
                            {configJSON.vegLabel}
                          </Typography>
                          <Switch
                            color="primary"
                            disabled={this.isViewOnly()}
                            checked={values.veg}
                            name={configJSON.vegName}
                            onChange={handleChange}
                          />
                        </Box>
                        <Box className={classes.approvalContainer}>
                          <Typography className={`${classes.inputLabel2} approvalText`} variant="h4">
                            {configJSON.gfLabel}
                          </Typography>
                          <Switch
                            name={configJSON.gfName}
                            color="primary"
                            onChange={handleChange}
                            disabled={this.isViewOnly()}
                            checked={values.gf}
                          />
                        </Box>
                        <Box className={classes.approvalContainer}>
                          <Typography className={`${classes.inputLabel2} approvalText`} variant="h4">
                            {configJSON.kLabel}
                          </Typography>
                          <Switch
                            color="primary"
                            onChange={handleChange}
                            name={configJSON.kName}
                            disabled={this.isViewOnly()}
                            checked={values.k}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item className={`${classes.gridItem}`} sm={6}>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel}>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          type="text"
                          variant="outlined"
                          fullWidth
                          disabled={this.isViewOnly()}
                          onBlur={handleBlur}
                          value={values.description}
                          onChange={handleChange}
                          multiline
                          name={configJSON.templateDescriptionName}
                          className={`${classes.inputField} ${classes.textAreaInputHeight} menuTextAreaInputHeight1 ${classes.textAreaField}`}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel}>
                          {configJSON.faqLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          type="text"
                          disabled={this.isViewOnly()}
                          fullWidth
                          value={values.faq}
                          onChange={handleChange}
                          multiline
                          onBlur={handleBlur}
                          name={configJSON.faqName}
                          className={`${classes.inputField} ${classes.textAreaInputHeight} menuTextAreaInputHeight1 ${classes.textAreaField}`}
                        />
                      </Box>
                      <Box
                        className={`${classes.gridItem} ${classes.productGrid2}`}
                      >
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <InputLabel required className={classes.inputLabel}>
                            {configJSON.usdLabel}
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            value={values.usd}
                            className={classes.inputField}
                            name={configJSON.usdName}
                            InputProps={{
                              startAdornment: menuTempAction !== configJSON.create && <InputAdornment className={classes.usdSymbole} position="start">$</InputAdornment>,
                            }}
                            disabled={this.isViewOnly()}
                            onKeyPress={allowOnlyPrice}
                            helperText={handleInvoiceHelperText(
                              errors.usd,
                              touched.usd
                            )}
                            fullWidth
                            error={handleInvoiceErrors(
                              errors.usd,
                              touched.usd
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <InputLabel className={classes.inputLabel}>
                            {configJSON.recipeCodeLabel}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            type="text"
                            value={values.recipe_code}
                            fullWidth
                            name={configJSON.recipeCodeName}
                            onChange={handleChange}
                            className={classes.inputField}
                            disabled={true}
                            onBlur={handleBlur}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item sm={6} className={`${classes.gridItem} marginTop`}>
                      <Box className={classes.fieldWrapper}>
                        <Box className={`${classes.uploadImageContainer} menuTempHeight2`}>
                          <ImageUploadView
                            currentImage={imageUrl?.url}
                            isDisabled={this.isViewOnly()}
                            handleImageChange={this.handleImageChange}
                            uploadTexts={["Upload Image"]}
                            name={configJSON.image}
                            setFieldValue={setFieldValue}
                          />
                        </Box>
                        {handleInvoiceErrors(
                          errors.image,
                          touched.image
                        ) && (
                            <FormHelperText
                              style={helperText}
                            >
                              {handleInvoiceHelperText(
                                errors.image,
                                touched.image,
                              )}
                            </FormHelperText>
                          )}
                      </Box>
                      <Box className={classes.productGrid3}>
                        <Box
                          className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}
                        >
                          <InputLabel className={classes.inputLabel}>
                            {configJSON.pairWithLabel}
                          </InputLabel>
                          <TextField
                            type="text"
                            value={values.pair_with1}
                            name={configJSON.pair_with1}
                            variant="outlined"
                            fullWidth
                            disabled={this.isViewOnly()}
                            className={classes.inputField}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <TextField
                            type="text"
                            value={values.pair_with2}
                            name={configJSON.pair_with2}
                            fullWidth
                            variant="outlined"
                            className={`${classes.inputField} ${classes.paddingTop18}`}
                            disabled={this.isViewOnly()}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <TextField
                            type="text"
                            value={values.pair_with3}
                            variant="outlined"
                            name={configJSON.pair_with3}
                            className={`${classes.inputField} ${classes.paddingTop18}`}
                            fullWidth
                            onChange={handleChange}
                            disabled={this.isViewOnly()}
                            onBlur={handleBlur}
                          />
                        </Box>
                      </Box>
                      <Box className={classes.productGrid3}>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <InputLabel className={classes.inputLabel}>
                            {configJSON.addOnLabel}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            type="text"
                            value={values.add_on1}
                            fullWidth
                            name={configJSON.add_on1}
                            onChange={handleChange}
                            className={classes.inputField}
                            disabled={this.isViewOnly()}
                            onBlur={handleBlur}
                          />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <TextField
                            variant="outlined"
                            type="text"
                            fullWidth
                            value={values.add_on2}
                            disabled={this.isViewOnly()}
                            name={configJSON.add_on2}
                            onChange={handleChange}
                            className={`${classes.inputField} ${classes.paddingTop18}`}
                            onBlur={handleBlur}
                          />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <TextField
                            variant="outlined"
                            type="text"
                            fullWidth
                            value={values.add_on3}
                            name={configJSON.add_on3}
                            className={`${classes.inputField} ${classes.paddingTop18}`}
                            onChange={handleChange}
                            disabled={this.isViewOnly()}
                            onBlur={handleBlur}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box 
                    className={`${classes.templateFooterContainer} marginTop40`} 
                  >
                    <Box className={classes.approvalContainer}>
                      <Typography className={`${classes.inputLabel2} approvalText`} variant="h4">
                        {configJSON.sendForApproval}
                      </Typography>
                      <Switch
                        color="primary"
                        disabled={this.state.menuTempAction !== configJSON.create}
                        checked={values.send_for_approval}
                        onChange={handleChange}
                        name="send_for_approval"
                      />
                    </Box>
                  </Box>
                  <Box className={`${classes.templateBtnWrapper} menuBtnContainer`}>
                    {!this.isViewOnly() &&
                      <Button
                        data-test-id="create-btn"
                        variant="contained"
                        className={classes.primaryBtn}
                        type="submit"
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              onClose={this.handleDeleteClose}
              open={opeDeleteModal}
              title={configJSON.menuTempDeleteTitle}
              from={configJSON.menuFrom}
              confirmDelete={this.handleDeleteTemplate}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(MenuTemplate2);
// Customizable Area End

