// Customizable Area Start
import React from "react";
import {
	Box,
	Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./HomePageStyles.web";
import HomePageController, { Props } from "./HomePageController.web";
import Loader from "../../../components/src/Loader.web";
import { Wrapper } from "../../../components/src/Wrapper";
import { HomePageContent } from "../../../components/src/utils/constant";
import { getUserRoleID } from "../../../components/src/utils/commonFunctions";

const infoCircle = require("../../../components/src/assets/info-circle.png");
// Customizable Area End

export class HomePage extends HomePageController {
	constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
		const { classes } = this.props;
		const { loading, companyName, ownerName } = this.state;

		return (
			// Required for all blocks
			<React.Fragment>
				<Loader loading={loading} />
				<Wrapper navigation={this.props.navigation} divider data-test-id="home-wrapper-test" bellIcn={true} homeIcn={true} child={
					<Box className={classes.homeOuterBox}>
						<Box className={classes.homeInnerBox}>
							<Typography data-test-id="welcome" variant="h4" className={"homeTitle"}>
								Welcome to {companyName}
							</Typography>
							<p className={"homeBody"} style={{ marginTop: 5 }}>
								{ownerName}
							</p>
							<div>
								<Box className="homeContainer">
									{HomePageContent.map((item: any) => (
										(item?.allowedUsers?.includes(getUserRoleID()) && <Box data-test-id="boxCard" className={`homeBoxCard`} onClick={() => this.handleNavigation(item.path)} key={item.name}>
											<Box>
												<div className="homeInnerBoxCard">
													<div className="imgContainer">
														{item.photo && <img src={item.photo} alt="Image" />}
													</div>
													<Typography variant="h4" className={"titleName"}>
														{item.name}
													</Typography>
												</div>
											</Box>
										</Box>)
									))}
								</Box>
							</div>
						</Box>
					</Box>
				}
				/>
			</React.Fragment>
		);
    // Customizable Area End
	}
}
// Customizable Area Start
export default withStyles(styles)(HomePage);
// Customizable Area End
