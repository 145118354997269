/** @format */

import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core/styles";
import {
    Divider,
    IconButton,
    Paper,
    InputBase
} from "@material-ui/core";

import AdvanceSearchBarController, { Props } from "./AdvanceSearchBarController.web";
export const configJSON = require("./config");
import { Close } from "@material-ui/icons";
import { searchIcon } from "./assets"
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
    rootSearch: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 250,
        borderRadius: "20px",
        margin: "0px 10px",
        maxHeight: "40px"
    },
    input: {
        marginLeft: "1rem",
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: "20px",
        marginInline: "4px"
    }
});
// Customizable Area End

export class AdvanceSearchBar extends AdvanceSearchBarController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes, setQuery, handleEnter, query, searchHandleClick, searchCloseClick,searchData,autoFocus } = this.props
        return (
            <Paper className={classes.rootSearch}>
                <InputBase
                    data-test-id="input-search"
                    name="input-search"
                    className={classes.input}
                    placeholder="Search"
                    autoFocus = {autoFocus ?? true}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={handleEnter}
                    value={query ?? ""}
                    disabled={searchData?.length === 0}
                />

                <IconButton data-test-id="search-list" type="submit" className={classes.iconButton} onClick={() => searchHandleClick(query.trim())}>
                    <img src={searchIcon} alt="search" />
                </IconButton>
                <Divider className={classes.divider} orientation='vertical' />
                <IconButton data-test-id="search-click" type="submit" className={classes.iconButton} onClick={searchCloseClick}>
                    <Close fontSize='small' />
                </IconButton>
            </Paper>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default withStyles(styles)(AdvanceSearchBar);
// Customizable Area End
