import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { apiCall, checkToken, convertFormData, getNavigationMessage, handleApiError, handleExpiredToken } from "../../../components/src/utils/commonFunctions";
import { ClassNameMap } from "@material-ui/styles";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { toast } from "react-toastify";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  // Customizable Area Start
  classes: ClassNameMap<"checkboxContainer" | "approvalContainer" | "primaryBtn" | "secondaryBtn" | "btnContainer" | "dropdownContainer"
  >;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  timezone_data: {
    label: string;
    value: string
  }[];
  checkedNotification: boolean;
  checkedEmailNotification: boolean;
  time_zone: string | null,
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;

  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

export default class SettingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  timezoneCallId: string = "";
  getSettingCallId: string = "";
  updateSettingCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      timezone_data: [],
      checkedNotification: false,
      checkedEmailNotification: false,
      time_zone: "",
      loading: false
    }
    // Customizable Area End
  }


  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.setState({ loading: true });
    checkToken()
    this.getTimezoneData()
    this.getSettingCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.showSettingApiEndpoint, token: true, });
  }
  handleChange = (event: { target: { checked: boolean; }; }, key: string) => {
    this.setState({ ...this.state, [key]: event.target.checked });
  };
  handleSelectChange = (e: OptionType) => {
    let finalRes = e?.value;
    this.setState({ time_zone: finalRes });

  };
  handleNavigateClick = (path: string) => {
    this.send(getNavigationMessage(path, this.props));
  }
  getTimezoneData = () => {
    this.setState({ loading: true });
    this.timezoneCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.timezoneApiEndpoint, token: true, });
  }
  handleResponseForTimezone = (from: string, message: Message) => {
    this.setState({ loading: false });
    if (this.timezoneCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const res = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(res, this.props.navigation);
      if (res) {
        this.setState({
          timezone_data: res.map((item: { label: string, value: string }) => {
            return {
              label: item,
              value: item
            }
          })
        })

      }

    }
  }
  handleResponseForShowSetting = (from: string, message: Message) => {
    this.setState({ loading: false });
    if (this.getSettingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const res = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(res, this.props.navigation);
      if (res.data) {
        this.setState({
          checkedNotification: res.data.attributes.notification,
          checkedEmailNotification: res.data.attributes.email_notification,
          time_zone: res.data.attributes.time_zone,
        })

      } else {
        handleApiError(res.errors)
      }

    }
  }
  updateSetting = () => {
    this.setState({ loading: true });
    const { checkedEmailNotification, checkedNotification, time_zone } = this.state
    const attrs = {
      notification: checkedNotification,
      email_notification: checkedEmailNotification,
      time_zone: time_zone,
    };
    this.updateSettingCallId = apiCall({ method: configJSON.updateApiMethodType, body: convertFormData(attrs), endPoint: configJSON.updateSettingApiEndpoint, token: true, });
  }
  handleResponseForUpdateSetting = (from: string, message: Message) => {
    this.setState({ loading: false });
    if (this.updateSettingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(response, this.props.navigation);
      if (response.data) {
        toast.success(configJSON.settingSuccessMessage)
      } else {
        toast.error(configJSON.settingErrorMessage)
      }

    }
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForTimezone(from, message)
    this.handleResponseForShowSetting(from, message)
    this.handleResponseForUpdateSetting(from, message)
    // Customizable Area End
  }
  // Customizable Area End
}

