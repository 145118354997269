// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Typography,
    Card,
    CardContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ForgotPasswordOTPController, { Props } from "./ForgotPasswordOTPController.web";
import LoginSignupRightSection from "../../../components/src/LoginSignupRightSection.web";
import { styles } from "../../../components/src/utils/Styles.web";
import LoginSignupBackgroundImgWeb from "../../../components/src/LoginSignupBackgroundImg.web";
const logo = require("../../../components/src/qwipLogo.svg");
export const configJSON = require("./config");
// Customizable Area End


// Customizable Area Start
// Customizable Area End

export class ForgotPasswordOTP extends ForgotPasswordOTPController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            inputOtp,
            hasValue,
            timer
        } = this.state;
        const isDisabled = inputOtp.some((digit) => digit === "");
        const isResendDisabled = timer > 0;
        const formattedMinutes = String(Math.floor(timer / 60)).padStart(2, "0");
        const formattedSeconds = String(timer % 60).padStart(2, "0");
        return (
            <React.Fragment>
                <LoginSignupBackgroundImgWeb />
                <Card className={classes.mainCard}>
                    <CardContent className={classes.cardContentInner}>
                        <Box className={classes.mainContainer}>
                            <Box className={classes.logoHeader}>
                                <img src={logo} alt="qwipLogo" />
                            </Box>
                            <Typography variant="h4" className={classes.headerTitle}>
                                {configJSON.otpText}
                            </Typography>
                            <Box className={classes.inputsField}>
                                {inputOtp.map((digit, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength={1}
                                        value={digit}
                                        placeholder="●"
                                        className={`${hasValue[index] && classes.inputContent}`}
                                        onChange={(e) => this.handleInputChange(e, index)}
                                        ref={this.otpInputs[index]}
                                    />
                                ))}
                            </Box>
                            <Box className={`${classes.btnWrapper} ${classes.btnOtp}`}>
                                <Button
                                    data-test-id={"verify_button"}
                                    variant="contained"
                                    fullWidth
                                    className={classes.innerBtn}
                                    type="submit"
                                    onClick={this.handleButtonClick}
                                    disabled={isDisabled}
                                >
                                    {configJSON.verifyBtnText}
                                </Button>
                                <Box className={classes.resendOtpBox} >
                                    <Typography component="p">{formattedMinutes}:{formattedSeconds}</Typography>
                                    <Button data-test-id="resend_button" onClick={this.handleResendClick} disabled={isResendDisabled}>{configJSON.resendBtn}</Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.imageBox} >
                            <LoginSignupRightSection />
                        </Box>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
        // Customizable Area End
    }
}

export default withStyles(styles)(ForgotPasswordOTP);

