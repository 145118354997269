
import React from "react";
// Customizable Area Start
import { Box, Grid, Typography } from "@material-ui/core";
import { Favorite } from '@material-ui/icons';
import { withStyles, createStyles } from "@material-ui/core/styles";
import MenuTemplateListingController, { Props } from "./MenuTemplateListingController.web";
export const configJSON = require("./config");
import { commonButton, libraryCommonCss } from "../../../components/src/utils/commonInputStyle";
import { libraryIcon } from "../../contentmanagement/src/assets";
import NoTickets from "../../../components/src/commonComponents/NoTickets.web";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import Loader from "../../../components/src/Loader";
import Pagination from "../../../components/src/commonComponents/Pagination.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonButton,
  ...libraryCommonCss
});
// Customizable Area End

export class MenuTemplateListing extends MenuTemplateListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, gridView, searchQuery } = this.props;
    const { loading, menu_list, header_list, openDeleteModal, meta } = this.state

    const menuGridView = () => (
      <Grid container spacing={2}>
        {(Boolean(menu_list.length)) ?
          menu_list.map(item => (
            <Grid key={item.attributes.id} item lg={3} md={4} sm={6} xs={12}>
              <Box className={classes.cardWrapper}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <ActionButton
                    view
                    handleDelete={this.handleOpenDeleteModal}
                    mark_as_favourite
                    deleteVisible={this.props.allowEditDelete(item.attributes.creator?.id)}
                    isOpen={this.state.isOpen[item.attributes.id]}
                    handleView={() => this.handleView(item.attributes)}
                    favourite={item.attributes.favorite}
                    toggleDropdown={() => this.toggleDropdown(item.attributes.id)}
                    handleFavourite={() => this.handleFavouriteClick(item.attributes.favorite)}
                  />
                </Box>
                <Box className="cursor" onClick={() => this.handleView(item.attributes)}>
                  <Box display={"flex"} justifyContent={"center"}>
                    <img className={classes.templateImg} src={item.attributes.menu_template.data.attributes.template_image.url} alt={item.attributes.menu_template.data.attributes.template_image.name} />
                  </Box>
                  <Box p={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box width={"90%"}>
                      <Typography noWrap className={classes.procedureTitleTxt} data-test-id="title">{item.attributes.name}</Typography>
                      <Typography className={classes.dateTxt}>{configJSON.date}{this.getFormattedDate(item.attributes.created_at)}</Typography>
                    </Box>
                    {Boolean(item.attributes.favorite) && <Favorite className={classes.favouriteIcn} />}
                  </Box>
                </Box>
              </Box>
            </Grid>
          )) :
          <Box className={classes.noTicketContainer} >
            <NoTickets
              title={configJSON.noMenuText}
              imagePath={libraryIcon}
            />
          </Box>
        }
      </Grid>
    )

    const menuListView = () => (
      <Box id="pdf-content" className={classes.listItem}>
        <Box className={classes.tableHeader}>
          {header_list.map((item, index) => (
            <Typography component="p" key={index}>
              {item}
            </Typography>
          ))}
        </Box>
        <Box className={menu_list.length === 0 ? classes.listMain : ""}>
          {menu_list.length > 0 ? (
            menu_list.map((menuItem, index) => {
              return (
                <Box key={menuItem.attributes.id} className={classes.tableItem}>
                  <Box className={classes.imgContainer}>
                    <img src={menuItem.attributes.menu_template.data.attributes.template_image.url} alt={menuItem.attributes.menu_template.data.attributes.template_image.name} />
                  </Box>
                  <Typography data-test-id="menu_title" component="p">{menuItem.attributes.name}</Typography>
                  <Typography component="p">Menu</Typography>
                  <Typography component="p">{this.getFormattedDate(menuItem.attributes.created_at)}</Typography>
                  <Box display={"flex"} >
                    <ActionButton
                      handleDelete={this.handleOpenDeleteModal}
                      mark_as_favourite
                      deleteVisible={this.props.allowEditDelete(menuItem.attributes.creator?.id)}
                      handleFavourite={() => this.handleFavouriteClick(menuItem.attributes.favorite)}
                      moreVert
                      view
                      isOpen={this.state.isOpen[menuItem.attributes.id]}
                      favourite={menuItem.attributes.favorite}
                      toggleDropdown={() => this.toggleDropdown(menuItem.attributes.id)}
                      handleView={() => this.handleView(menuItem.attributes)}
                    />

                  </Box>
                  {Boolean(menuItem.attributes.favorite) &&
                    <Box>
                      <Box borderRadius={"50%"} border={"1px solid #0000003b"} height={"fit-content"} width={"fit-content"} padding={"6px"}>
                        <Favorite className={classes.favouriteIcn} />
                      </Box>
                    </Box>
                  }
                </Box>
              );
            })
          ) : (
            <NoTickets
              imagePath={libraryIcon}
              title={configJSON.noMenuText}
            />
          )}
        </Box>
      </Box>
    )

    const menuViewer = () => {
      if (menu_list.length > 0) {
        return (
          <>
            {gridView ? menuGridView() : menuListView()}
            <Box className={classes.paginationBox}>
              <Pagination meta={meta} onPageChange={this.handlePageChange} />
            </Box>
          </>
        )
      }else if (menu_list.length === 0 && !loading && Boolean(searchQuery)) {
        return (
          <Box className={classes.noTicketContainer}>
            <Typography variant="h5">{configJSON.searchResult} <strong>{searchQuery}</strong>.</Typography>
          </Box>
        )
      } else if (menu_list.length === 0 && !loading) {
        return (
          <Box className={classes.noTicketContainer} >
            <NoTickets
              title={configJSON.noMenuText}
              imagePath={libraryIcon}
            />
          </Box>
        )
      }
    }

    return (
      <React.Fragment>
        <Loader loading={loading} />
        <Box pt={2} className={`${menu_list.length > 0 ? "" : classes.noTicketOuterContainer}`}>
          {menuViewer()}
        </Box>
        {openDeleteModal &&
          <ConfirmationModal
            onClose={this.handleCloseDeleteModal}
            confirmDelete={this.handleDeleteClick}
            from={configJSON.fromLibrary}
            open={openDeleteModal}
            title={configJSON.menuTemplateDeleteTitle}
          />}
      </React.Fragment>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(MenuTemplateListing);
// Customizable Area End
