import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
  classes?: any;
  loading: boolean;
}

const styles: any = {
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 9999,
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
};


class Loader extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { loading, classes } = this.props;

    return loading ? (
      <div className={classes?.root}>
        <div className={classes?.circularContainer}>
          <CircularProgress />
        </div>
      </div>
    ) : (
      <div />
    );
  }
}

export default withStyles(styles)(Loader);
