import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { apiCall, checkToken, handleApiError, handleExpiredToken } from "../../../components/src/utils/commonFunctions";
import { ClassNameMap } from "@material-ui/styles";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  // Customizable Area Start
  classes: ClassNameMap<"hierarchyTitle" | "containerStyles" | "boxContainer" | "imgContainer" | "detailsContainer" | "detailsInnerContainer" | "btnContainer" | "countText" | "nameText" | "avatar"
  >;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  translate: { x: number; y: number };
  myTreeData: UserData[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;

  // Customizable Area End
}

// Customizable Area Start

export interface UserData {
  id: number;
  name: string;
  profile_picture: string;
  role_name: string;
  branch_name: string | null;
  departments: string[];
  directChildCount: number;
  totalChildCount: number;
  children: UserData[];
}

// Customizable Area End

export default class CompanyHierarchyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCompanyHierarchyApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      loading: false,
      translate: { x: 0, y: 0 },
      myTreeData : []
    }
    // Customizable Area End
  }


  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    checkToken();
    const containerElem = document.getElementById("tree-container");
    if (containerElem) {
      const { width, height } = containerElem.getBoundingClientRect();
      this.setState({
        translate: { x: width / 2, y: height / 2 }
      });
    }
    this.getCompanyHierarchyApi = apiCall({ method: configJSON.getMethod, endPoint: configJSON.getHierarchyEndpoint, token: true });
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(responseJsonData, this.props.navigation);
      this.setState({ loading: false });

      if (apiCallId && responseJsonData) {
        if (apiCallId === this.getCompanyHierarchyApi) {
          this.getHierarchyResponse(responseJsonData);
        }
      }
      // Customizable Area End
    }
  }

  getHierarchyResponse = (response: UserData[] | { errors: string[]}) => {
    if ('errors' in response) {
      handleApiError(response.errors);
    } else {
      this.setState({myTreeData : response})
    }
  };

  goToUserDetail = (userId: number) => {    
    this.props.navigation.navigate(`EmployeeProfileDetails`, {id: userId});
  };

  isSupervisor = (roleName: string) => {
    return roleName === "Supervisor"
  };

  showDepartments = (roleName: string) => {
    return this.isSupervisor(roleName) || roleName === "Agent";
  };
  // Customizable Area End
}

