// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Typography,
    Card,
    CardContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PasswordChangedController, { Props } from "./PasswordChangedController.web";
import LoginSignupRightSection from "../../../components/src/LoginSignupRightSection.web";
import { styles } from "../../../components/src/utils/Styles.web";
import LoginSignupBackgroundImgWeb from "../../../components/src/LoginSignupBackgroundImg.web";
const logo = require("../../../components/src/qwipLogo.svg");
export const configJSON = require("./config");
// Customizable Area End


// Customizable Area Start
// Customizable Area End

export class PasswordChanged extends PasswordChangedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <React.Fragment>
                <LoginSignupBackgroundImgWeb />
                <Card className={classes.mainCard}>
                    <CardContent className={classes.cardContentInner}>
                        <Box className={classes.mainContainer}>
                            <Box className={classes.logoHeader}>
                                <img src={logo} alt="qwipLogo" />
                            </Box>
                            <Typography variant="h4" className={classes.headerTitle}>
                                {configJSON.changedPasswordText}
                            </Typography>
                            <Box className={classes.para}>
                            <Typography component="p">
                                {configJSON.paraText}
                            </Typography>
                            <Typography component="p">
                                {configJSON.backtologinText}
                            </Typography>
                            </Box>
                            <Box className={`${classes.btnWrapper} ${classes.btnOtp}`}>
                                <Button
                                    data-test-id={"go_to_login"}
                                    variant="contained"
                                    fullWidth
                                    className={classes.innerBtn}
                                    type="submit"
                                    onClick={this.handleLoginClick}
                                >
                                    {configJSON.loginBtnText}
                                </Button>
                            </Box>
                        </Box>
                        <Box className={classes.imageBox} >
                            <LoginSignupRightSection />
                        </Box>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
        // Customizable Area End
    }
}

export default withStyles(styles)(PasswordChanged);

