import React from "react";
// Customizable Area Start
import {  Box } from "@material-ui/core";
import { Wrapper } from "../../../components/src/Wrapper";
import CommonCarousel from "../../../components/src/commonComponents/CommonCarousel.web";
import { withStyles, createStyles } from "@material-ui/core/styles";

import MenuCarouselController, {
  Props,
  configJSON,
} from "./MenuCarouselController";
import Loader from "../../../components/src/Loader";
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  container: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
  },

});
// Customizable Area End

export class MenuCarousel extends MenuCarouselController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { classes } = this.props;
    return (
      <>
        <Loader loading={this.state.loading} />
        <Wrapper
          data-test-id="wrapper-comp"
          navigation={this.props.navigation}
          backbtn={true}
          divider
          title={configJSON.menuHeaderTitle}
          child={
            <>
              <Box className={classes.container}>
                  <CommonCarousel
                    handleNavigate={this.handleNavigate}
                    data={this.state.menu_data}
                  />
                </Box>
            </>
          }
        />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(MenuCarousel);
// Customizable Area End

