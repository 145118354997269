
import React, { Component } from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import { ClassNameMap } from "@material-ui/styles";
import { Box, Button, Typography, Dialog, DialogContent } from "@material-ui/core";
import { colors } from "../utils/commonDesign";
export const configJSON = require("./config");
// Customizable Area End


// Customizable Area Start
export const styles: Styles<Theme, {}, string> = () => ({
    dialogBox: {
        borderRadius: "16px !important",
    },
    dialogBoxContent: {
        padding: "3rem !important",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        "& p": {
            fontWeight: 400,
            color: "#020202;",
        }
    },
    groupButton: {
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
        ["& button"]: {
            textTransform: "capitalize",
            padding: "0.7rem",
            fontWeight: 600,
            fontSize: "1rem",
            borderRadius: 8,
            width: "9rem",
            color: "#020202"
        },
    },
    cancelButton: {
        background: "transparent",
        border: "1px solid #020202",
        marginRight: 20,
        ["&:hover"]: {
            background: "transparent",
        },
    },
    successButton: {
        backgroundColor: colors.secondaryColor2,
        border: "1px solid transparent",
        ["&:hover"]: {
            backgroundColor: colors.secondaryColor2,
        },
    },

});

interface ConfirmationModalProps {
    open: boolean;
    onClose: () => void;
    confirmDelete: () => void;
    startingText?: string;
    title: string | undefined;
    from?: string;
    logout?:boolean;
    classes: ClassNameMap<"dialogBox" | "dialogBoxContent" | "groupButton" | "cancelButton" | "successButton"

    >
}

// Customizable Area End

export class ConfirmationModal extends Component<ConfirmationModalProps> {
    constructor(props: ConfirmationModalProps) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { open, onClose, title, classes, from,logout, confirmDelete, startingText } = this.props;

        return (

            <Dialog open={open} onClose={onClose} classes={{paper:classes.dialogBox}}>
                <DialogContent className={classes.dialogBoxContent}>
                    <Typography data-test-id="title-content">
                        {startingText ? startingText : logout ? `${configJSON.logoutConfirm}` : `${configJSON.deleteConfirm}`} <strong>{title}</strong> {from}?
                    </Typography>
                    <Box className={classes.groupButton}>
                        <Button data-test-id="cancel-btn"  className={classes.cancelButton} onClick={onClose}>{configJSON.noButton}</Button>
                        <Button data-test-id="confirm-delete-btn"  className={classes.successButton} onClick={confirmDelete}>{configJSON.yesButton}</Button>
                    </Box>
                </DialogContent>
            </Dialog>

        );
    }
    // Customizable Area End
}
export default withStyles(styles)(ConfirmationModal);

