// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall, convertFormData, handleApiError, handleExpiredToken, checkToken } from "../../../components/src/utils/commonFunctions";
import { DataList, FormValues } from "./AddNewBranchController.web";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: Record<string,string>

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  branchId: number;
  timezone_data: {
    label: string;
    value: string
  }[];
  // data: DataValues;
  data: DataValues | null;
  uploaded_image: string;
  country_code_data: DataList[];
  currentImage: string | ArrayBuffer | null;
  isEdit: boolean;
  open: boolean;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
// Customizable Area Start

interface DataValues {
  id: string | number;
  attributes: FormValues
}



// Customizable Area End

export default class EditNewBranchController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getBranchCallId: string = "";
  editBranchCallId: string = "";
  timezonCallId: string = "";
  countryCodeDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    const branchId = Number(this.props.navigation.getParam("id"));
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      branchId,
      timezone_data: [],
      data: null,
      uploaded_image: "",
      country_code_data: [],
      currentImage: null,
      isEdit: this.props.navigation.history.location.state.isEdit,
      open: false,
      loading: false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    checkToken(this.props.navigation);
    this.getBranchData()
    this.getTimezoneDataList()
    this.getCountryCodeDataList()
    // Customizable Area End
  }
  // Customizable Area Start
  handleEditClick = () => {
    this.setState({
      isEdit: true
    })
  }
  handleImageChange = (event: { target: { name: string; files: File[]; }; }, setFieldValue: (arg0: string, arg1: File) => void) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      setFieldValue(event.target.name, imageFile)
      this.setState({
        currentImage: URL.createObjectURL(event.target.files[0])
      });
    }
  };
  handleOkClick = () => {
    this.props.navigation.history.push("/ExistingBranch")
  }
  handleCloseClick = () => {
    this.setState({open: false});
  }
  handleSelectChange = (event: OptionType, setFieldValue: { (field: string, value: FormValues, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, keyValue: string) => {
    setFieldValue(keyValue, event.value);
  };


  handleSave = (values: FormValues) => {
    this.editNewBranch(values)
  }

  editNewBranch = (values: FormValues) => {
    this.setState({ loading: true });
    const attrs: FormValues = {
      branch_name: values.branch_name,
      type_of_point_of_sale: values.type_of_point_of_sale,
      location_of_point_of_sale: values.location_of_point_of_sale,
      time_zone: values.time_zone,
      from_working_hours: values.from_working_hours,
      to_working_hours: values.to_working_hours,
      phone_number: values.phone_number,
      email: values.email,
      ...(typeof values.photo === "object" && values.photo instanceof File ? { photo: values.photo } : {}),
      description: values.description,
      important_information: values.important_information,
      country_code_and_flag_id: values.country_code_and_flag_id

    };
    this.editBranchCallId = apiCall({ method: configJSON.updateApiMethodType, body: convertFormData(attrs), endPoint: `${configJSON.createBranchApiEndpoint}/${this.state.branchId}`, token: true, });
  }

  getBranchData = () => {
    this.setState({ loading: true });
    this.getBranchCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: `${configJSON.createBranchApiEndpoint}/${this.state.branchId}`, token: true, });
  }
  getTimezoneDataList = () => {
    this.setState({ loading: true });
    this.timezonCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.timezoneApiEndpoint, token: true, });

  }
  handleBack = () => {
    this.props.navigation.goBack();
  }
  getCountryCodeDataList = () => {
    this.setState({ loading: true });
    this.countryCodeDataCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.countryCodeApiEndPoint, token: true, });
  }
  handleResponseForCountryCodeDataList = (from: string, message: Message) => {
    if (this.countryCodeDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.data) {
        const updatedData = apiResponse.data.map((item: { id: string; name: string; alpha2: string; country_code: string }) => {
          const { id, alpha2, country_code } = item
          return { value: Number(id), label: `${country_code} (${alpha2})` }
        });
        this.setState({
          country_code_data: updatedData
        })

      } else {
        handleApiError(apiResponse.errors);
      }

    }
  }
  handleResponseForGetBranch = (from: string, message: Message) => {

    if (this.getBranchCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(response, this.props.navigation);
      if (response.data) {
        this.setState({ data: response.data, currentImage: response.data?.attributes.photo })
      } else {
        handleApiError(response.errors);
      }

    }
  }
  handleResponseForEditBranch = (from: string, message: Message) => {

    if (this.editBranchCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(response, this.props.navigation);
      if (response.data) {
        this.setState({ open: true, isEdit: false, data: response.data,currentImage: response.data?.attributes.photo})
      } else {
        handleApiError(response.errors ?? configJSON.invalidMessage);
      }

    }
  }
  handleResponseForTimezoneList = (from: string, message: Message) => {
    if (this.timezonCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponseData, this.props.navigation);
      if (apiResponseData) {
        this.setState({
          timezone_data: apiResponseData.map((item: { label: string, value: string }) => {
            return {
              label: item,
              value: item
            }
          })
        })

      }

    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({ loading: false });
    this.handleResponseForGetBranch(from, message)
    this.handleResponseForEditBranch(from, message)
    this.handleResponseForTimezoneList(from, message)
    this.handleResponseForCountryCodeDataList(from, message)
    // Customizable Area End
  }
  // Customizable Area End


}
