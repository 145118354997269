// Customizable Area Start
import React from "react";
import {
	Box,
	Button,
	Typography,
	InputAdornment,
	IconButton,
	TextField,
	InputLabel,
	Card,
	CardContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Formik } from "formik";
import EmailRegistrationController, { Props, configJSON } from "./EmailRegistrationController.web";
import LoginSignupRightSection from "../../../components/src/LoginSignupRightSection.web";
import { styles } from "./EmailRegistrationStyles.web";
import LoginSignupBackgroundImgWeb from "../../../components/src/LoginSignupBackgroundImg.web";
import { signupRightSideImg } from "./assets";
import {
	handleInvoiceErrors,
	handleInvoiceHelperText,
} from "../../../components/src/utils/commonFunctions";
import { SignupSchema } from "../../../components/src/utils/validationSchema";
import Loader from "../../../components/src/Loader.web";

const logo = require("../../../components/src/qwipLogo.svg");
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class EmailRegistration extends EmailRegistrationController {
	constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

	render() {
    // Customizable Area Start
		const { classes } = this.props;
		const {
			email,
			placeHolderEmail,
			password,
			confirmPassword,
			placeHolderPassword,
			placeHolderConfirmPassword,
			enablePasswordField,
			enableConfirmPassword,
      isBusinessOwner
		} = this.state;

		return (
			<React.Fragment>
				<Loader loading={this.state.loading} />
				<LoginSignupBackgroundImgWeb />
				<Card className={classes.card}>
					<CardContent className={classes.cardContent}>
						<Box
							sx={{
								maxWidth: "425px",
								width: "100%",
								minWidth: "280px",
							}}
						>
							<Box className={classes.headerLogo}>
								<img src={logo} alt="qwipLogo" />
							</Box>
							<Typography variant="h4" className={classes.title} data-test-id="titleTextSignUp">
								{configJSON.titleTextSignUp}
							</Typography>
							<Formik
								enableReinitialize={true}
								validateOnBlur={true}
								initialValues={{
									email: email,
									password: password,
									confirmPassword: confirmPassword,
								}}
								validationSchema={SignupSchema}
								onSubmit={this.handleSumbit}
							>
								{({
									errors,
									touched,
									values,
									handleSubmit,
									handleChange,
									handleBlur,
								}) => {
									return (
										<form onSubmit={handleSubmit}>
											<Box
												className={
													classes.fieldWrapper
												}
											>
												<InputLabel
													className={
														classes.inputLabel
													}
												>
													{configJSON.labelEmail}
												</InputLabel>
												<TextField
													data-test-id="txtInputEmail"
													type="email"
													name="email"
													variant="outlined"
													placeholder={
														placeHolderEmail
													}
													value={values.email}
													onChange={handleChange}
													onBlur={handleBlur}
													fullWidth
                          disabled={!isBusinessOwner}
													className={
														classes.inputField
													}
													error={handleInvoiceErrors(
														errors.email,
														touched.email
													)}
													helperText={handleInvoiceHelperText(
														errors.email,
														touched.email
													)}
												/>
											</Box>
											<Box
												className={
													classes.fieldWrapper
												}
											>
												<InputLabel
													className={
														classes.inputLabel
													}
												>
													{configJSON.labelPassword}
												</InputLabel>
												<TextField
													data-test-id="txtInputPassword"
													name="password"
													type={
														enablePasswordField
															? "password"
															: "text"
													}
													variant="outlined"
													placeholder={
														placeHolderPassword
													}
													value={values.password}
													onChange={handleChange}
													onBlur={handleBlur}
													fullWidth
													className={
														classes.inputField
													}
													error={handleInvoiceErrors(
														errors.password,
														touched.password
													)}
													helperText={handleInvoiceHelperText(
														errors.password,
														touched.password
													)}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
                                  data-test-id="password-visibility"
																	aria-label="toggle password visibility"
																	onClick={
																		this
																			.handleClickShowPassword
																	}
																	edge="end"
																>
																	{enablePasswordField ? (
																		<VisibilityOff />
																	) : (
																		<Visibility />
																	)}
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											</Box>
											<Box
												className={
													classes.fieldWrapper
												}
											>
												<InputLabel
													className={
														classes.inputLabel
													}
												>
													{configJSON.labelConfirmPassword}
												</InputLabel>
												<TextField
													data-test-id="txtInputConfirmPassword"
													name="confirmPassword"
													type={
														enableConfirmPassword
															? "password"
															: "text"
													}
													variant="outlined"
													placeholder={
														placeHolderConfirmPassword
													}
													value={
														values.confirmPassword
													}
													onChange={handleChange}
													onBlur={handleBlur}
													fullWidth
													className={
														classes.inputField
													}
													error={handleInvoiceErrors(
														errors.confirmPassword,
														touched.confirmPassword
													)}
													helperText={handleInvoiceHelperText(
														errors.confirmPassword,
														touched.confirmPassword
													)}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
                                  data-test-id="confirmpassword-visibility"
																	aria-label="toggle password visibility"
																	onClick={
																		this.handleClickShowConfirmPassword
																	}
																	edge="end"
																>
																	{enableConfirmPassword ? (
																		<VisibilityOff />
																	) : (
																		<Visibility />
																	)}
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											</Box>
											<Box
												className={
													classes.btnWrapper
												}
											>
												<Button
													data-test-id={
														"btnEmailSignup"
													}
													variant="contained"
													fullWidth
													className={classes.btn}
													type="submit"
												>
													{configJSON.signUpBtn}
												</Button>
												<Box
													data-test-id={"btn"}
													className={
														classes.endText
													}
												>
													{configJSON.alreadyHaveAnAccountText}
													<span
                            data-test-id="go-to-login-btn"
														className="redirect"
														onClick={this.goToLogin}>
														{configJSON.signupLoginBtn}
													</span>
												</Box>
											</Box>
										</form>
									);
								}}
							</Formik>
						</Box>
						<Box className={classes.imageContainer} >
							<LoginSignupRightSection imagePath={signupRightSideImg} />
						</Box>
					</CardContent>
				</Card>
			</React.Fragment>
		);
    // Customizable Area End
	}
}
// Customizable Area Start
export default withStyles(styles)(EmailRegistration);
// Customizable Area End
