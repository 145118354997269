// Customizable Area Start
import React from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  MobileStepper,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import UserProfileController, { Props } from "./UserProfileController.web";
import { styles } from "./UserProfileStyles.web";

import LoginSignupBackgroundImgWeb from "../../../components/src/LoginSignupBackgroundImg.web";
import { uploadDocIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import AttachFileInput from "../../../components/src/commonComponents/AttachFileInput.web";
const logo = require("../../../components/src/qwipLogo.svg");
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class UserProfile extends UserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      passport,
      national_id,
      vaccination_certificate,
      school_degree,
      university_degree,
      certificate,
      driving_license,
      activeStep,
      nationalidUrl,
      passportUrl,
      vaccinationcertificateUrl,
      schooldegreeUrl,
      universitydegreeUrl,
      certificateUrl,
      drivinglicenseUrl,
    } = this.state;

    const Stepone = (
      errors: any,
      touched: any,
      values: any,
      handleChange: any,
      setFieldValue: any,
      handleBlur: any
    ) => (
      <Grid container className={classes.UserProfilecontainer}>
        <Grid
          item
          xs={12}
          className={classes.UserProfilecompanyProfileLeftSection}
        >
          <Box className={classes.UserProfilefieldWrapper}>
            <AttachFileInput
              attachmentTitle="National ID"
              attachmentId="national_id"
              placeholderText="Upload in jpeg, png, PDF format only"
              accept="image/jpeg,application/pdf,image/png"
              placeholderIcon={uploadDocIcon}
              attachment={nationalidUrl}
              setFieldValue={setFieldValue}
              handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDocUpload(event, "national_id", "nationalidUrl", setFieldValue)}
              clearAttachment={() => this.handleClearDoc("national_id", "nationalidUrl", setFieldValue)}
              attachmentError={errors.national_id}
            />
          </Box>

          <Box className={classes.UserProfilefieldWrapper}>
            <AttachFileInput
              attachmentTitle="Passport"
              attachmentId="passport"
              placeholderText="Upload in jpeg, png, PDF format only"
              accept="image/jpeg,application/pdf,image/png"
              placeholderIcon={uploadDocIcon}
              attachment={passportUrl}
              setFieldValue={setFieldValue}
              handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDocUpload(event, "passport", "passportUrl", setFieldValue)}
              clearAttachment={() => this.handleClearDoc("passport", "passportUrl", setFieldValue)}
              attachmentError={errors.passport}
            />
          </Box>

          <Box className={classes.UserProfilefieldWrapper}>
            <AttachFileInput
              attachmentTitle="Vaccination Certificate"
              attachmentId="vaccination_certificate"
              placeholderText="Upload in jpeg, png, PDF format only"
              accept="image/jpeg,application/pdf,image/png"
              placeholderIcon={uploadDocIcon}
              attachment={vaccinationcertificateUrl}
              setFieldValue={setFieldValue}
              handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDocUpload(event, "vaccination_certificate", "vaccinationcertificateUrl", setFieldValue)}
              clearAttachment={() => this.handleClearDoc("vaccination_certificate", "vaccinationcertificateUrl", setFieldValue)}
              attachmentError={errors.vaccination_certificate}
            />
          </Box>

        </Grid>
      </Grid>
    )

    const StepTwo = (
      errors: any,
      touched: any,
      values: any,
      handleChange: any,
      setFieldValue: any,
      handleBlur: any
    ) => (
      <Grid container spacing={2}>

        <Grid
          item
          xs={12}
          className={classes.UserProfiletimecompanyProfileInputSection}
        >

          <Box className={classes.UserProfilefieldWrapper}>
            <AttachFileInput
              attachmentTitle="School Degree"
              attachmentId="school_degree"
              placeholderText="Upload in jpeg, png, PDF format only"
              accept="image/jpeg,application/pdf,image/png"
              placeholderIcon={uploadDocIcon}
              attachment={schooldegreeUrl}
              setFieldValue={setFieldValue}
              handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDocUpload(event, "school_degree", "schooldegreeUrl", setFieldValue)}
              clearAttachment={() => this.handleClearDoc("school_degree", "schooldegreeUrl", setFieldValue)}
              attachmentError={errors.school_degree}
            />
          </Box>

          <Box className={classes.UserProfilefieldWrapper}>
            <AttachFileInput
              attachmentTitle="University Degree"
              attachmentId="university_degree"
              placeholderText="Upload in jpeg, png, PDF format only"
              accept="image/jpeg,application/pdf,image/png"
              placeholderIcon={uploadDocIcon}
              attachment={universitydegreeUrl}
              setFieldValue={setFieldValue}
              handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDocUpload(event, "university_degree", "universitydegreeUrl", setFieldValue)}
              clearAttachment={() => this.handleClearDoc("university_degree", "universitydegreeUrl", setFieldValue)}
              attachmentError={errors.university_degree}
            />
          </Box>

          <Box className={classes.UserProfilefieldWrapper}>
            <AttachFileInput
              attachmentTitle="Certificate"
              attachmentId="certificate"
              placeholderText="Upload in jpeg, png, PDF format only"
              accept="image/jpeg,application/pdf,image/png"
              placeholderIcon={uploadDocIcon}
              attachment={certificateUrl}
              setFieldValue={setFieldValue}
              handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDocUpload(event, "certificate", "certificateUrl", setFieldValue)}
              clearAttachment={() => this.handleClearDoc("certificate", "certificateUrl", setFieldValue)}
              attachmentError={errors.certificate}
            />
          </Box>

          <Box className={classes.UserProfilefieldWrapper}>
            <AttachFileInput
              attachmentTitle="Driving License"
              attachmentId="driving_license"
              placeholderText="Upload in jpeg, png, PDF format only"
              accept="image/jpeg,application/pdf,image/png"
              placeholderIcon={uploadDocIcon}
              attachment={drivinglicenseUrl}
              setFieldValue={setFieldValue}
              handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDocUpload(event, "driving_license", "drivinglicenseUrl", setFieldValue)}
              clearAttachment={() => this.handleClearDoc("driving_license", "drivinglicenseUrl", setFieldValue)}
              attachmentError={errors.driving_license}
            />
          </Box>
        </Grid>
      </Grid>
    )

    const renderActiveForm = (
      errors: any,
      touched: any,
      values: any,
      handleChange: any,
      setFieldValue: any,
      handleBlur: any
    ) => {
      switch (activeStep) {
        case 1:
          return (
            <>
              {
                Stepone
                  (
                    errors,
                    touched,
                    values,
                    handleChange,
                    setFieldValue,
                    handleBlur
                  )
              }
            </>
          )
        case 2:
          return (
            <>
              {
                StepTwo
                  (
                    errors,
                    touched,
                    values,
                    handleChange,
                    setFieldValue,
                    handleBlur
                  )
              }
            </>
          )

      }
    };

    const getInitialValuefunction = () => {
      if (activeStep === 1) {
        return {
          national_id,
          passport,
          vaccination_certificate,
        };
      }
      else {
        return {
          school_degree,
          university_degree,
          certificate,
          driving_license
        };
      }
    };

    return (
      // Required for all blocks
      <React.Fragment>
        <Loader loading={this.state.loading} />
        <LoginSignupBackgroundImgWeb />
        <Card className={classes.UserProfilecard}>
          <CardContent className={classes.UserProfilecardContent}>
            <Box>
              <img src={logo} alt="qwipLogo" style={{ height: "45px" }} />
            </Box>
            <Typography variant="h4" className={classes.UserProfiletitle}>
              Create your user profile
            </Typography>
            {activeStep === 2 ?
              <Typography variant="h4" className={classes.UserProfileinputLabel}>
                Basic Info
              </Typography> :
              <Typography variant="h4" className={classes.UserProfileinputLabel}>
                Personal Info
              </Typography>
            }

            <MobileStepper
              variant="progress"
              steps={3}
              position="static"
              activeStep={activeStep}
              className={classes.UserProfileprogressStepper}
              nextButton={
                <Button
                  size="small"
                  style={{ display: "none" }}
                >
                  Next
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  style={{ display: "none" }}
                >
                  Back
                </Button>
              }
            />
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={getInitialValuefunction()}
              onSubmit={(event) => this.handleNextfunction(event)}
              validationSchema={this.getValidationSchemafunction}
              validateOnMount={true}
            >
              {({
                errors,
                touched,
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                handleBlur
              }) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                    className={classes.UserProfileform}
                  >
                    {renderActiveForm(
                      errors,
                      touched,
                      values,
                      handleChange,
                      setFieldValue,
                      handleBlur
                    )}
                    <Box className={classes.UserProfilebtnWrapper}>
                      <Button
                        size="small"
                        data-test-id="backbutton"
                        className={`${classes.UserProfilebtn} ${classes.UserProfilesecondaryBtn}`}
                        onClick={this.handleGoBack}
                      >
                        Back
                      </Button>
                      <Button
                        data-test-id="nextbutton"
                        size="small"
                        type="submit"
                        className={`${classes.UserProfilebtn} ${classes.UserProfileprimaryBtn}`}
                      >
                        Next
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </React.Fragment>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(UserProfile);
// Customizable Area End