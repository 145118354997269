/** @format */

import React from "react";
// Customizable Area Start
import { commonInputField, commonButton, helperText, commonTemplateCss, singleValueCss } from "../../../components/src/utils/commonInputStyle";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, TextField, Button, Grid, Switch, FormHelperText } from "@material-ui/core";
import Template13Controller, { Props, configJSON } from "./Template13Controller.web"
import { Formik, Form } from 'formik';
import { Wrapper } from "../../../components/src/Wrapper";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import AttachFileInput from "../../../components/src/commonComponents/AttachFileInput.web";
import DropdownSearch from "../../../components/src/commonComponents/DropdownSearch.web";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
// Customizable Area End

// Customizable Area Start

export const styles = () => createStyles({
  ...commonButton,
  ...commonInputField,
  ...commonTemplateCss

});
// Customizable Area End

export class Template13 extends Template13Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { formData, department_data, templateAction, attachment, loading, imageUrls,attachmentError, branch_data, isDownload, openMenu, opeDeleteModal } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper divider navigation={this.props.navigation} backbtn={true} title={this.headerTitle()} bellIcn={true} homeIcn={true} child={
          <Box>
            <Formik
              initialValues={this.template13InitialValues()}
              enableReinitialize={true}
              validateOnBlur={true}
              onSubmit={this.addEditTemplate13}
              validationSchema={this.template13ValidationSchema()}
            >
              {({touched, errors, handleBlur, handleChange, setFieldValue, values }) => (
                <Form className={`${classes.formContainer} ${isDownload && "textColor"}`} translate >
                  <Grid direction="row" ref={this.pdfContentRef} container className={classes.gridContainer} spacing={5}>
                    <Grid item className={classes.gridItem} xs={10} sm={5}>
                      {this.isViewOnly() ?
                        <Typography className={`${classes.inputLabel2} title`} data-test-id="title" variant="h4">
                          {values.title}
                        </Typography> :
                        <Box className={`${classes.fieldWrapper} marginTitle`}>
                          <InputLabel  required className={`labetText ${classes.inputLabel2}`}  >
                            {configJSON.templateTitleLabel}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            data-test-id="title_input_field"
                            type="text"
                            name={configJSON.templateTitleName}
                            helperText={handleInvoiceHelperText(
                              errors.title,
                              touched.title
                              )}
                            value={values.title}
                            className={classes.inputField}
                            fullWidth
                            error={handleInvoiceErrors(
                              errors.title,
                              touched.title
                              )}
                              onChange={handleChange}
                                onBlur={handleBlur}
                          />
                        </Box>
                      }
                    </Grid>
                    <Grid item className={`actionBtn ${classes.gridItem} ${isDownload && "visibility"}`} xs={2} sm={7}>
                      {templateAction !== configJSON.create &&
                        <ActionButton
                        deleteVisible={this.allowEdit()}
                        handleEdit={() => this.handleActions(configJSON.edit)}
                        duplicate={this.allowEdit()}
                        download
                        edit={this.allowEdit()}
                        handleDuplicate={this.handleDuplicateTemplate}
                        mark_as_favourite
                        favourite={formData.favorite}
                        handleFavourite={this.handleFavouriteClick}
                        handleDownload={this.handleDownloadPdf}
                        isOpen={openMenu}
                        toggleDropdown={this.toggleMenu}
                        handleDelete={this.handleDelete}
                        />
                      }

                    </Grid>
                    <Grid item className={classes.gridItem} xs={12}>
                      <div className={classes.dropdownContainer}>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            value={values.branch_id}
                            id={configJSON.templateBranchName}
                            options={branch_data}
                            singleValueCss={isDownload && singleValueCss}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                            placeholder={configJSON.templateBranchPlaceholder}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                              )}
                              isDisabled={this.isViewOnly()}
                            isMyProfile={true}
                            isSearchable={true}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            noOptionsMessage={configJSON.templateNoBranchMessage}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            singleValueCss={isDownload && singleValueCss}
                            id={configJSON.templateDepartmentName}
                            options={department_data}
                            value={values.department_id}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            isMyProfile={true}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                            placeholder={configJSON.templateDepartmentPlaceholder}
                            isSearchable={true}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id
                              )}
                              errorText={handleInvoiceHelperText(
                                errors.department_id,
                                touched.department_id
                                )}
                                noOptionsMessage={configJSON.templateNoDepartmentMessage}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid id="container1" item className={classes.gridItem} xs={12} sm={6} md={4} lg={4} >
                      <Grid container spacing={4} alignItems="flex-end">
                        <Grid item lg={6} md={6} sm={6} className="paddingBottom">
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel} required>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              type="text"
                              value={values.description1}
                              variant="outlined"
                              name={configJSON.description1}
                              fullWidth
                              disabled={this.isViewOnly()}
                              helperText={handleInvoiceHelperText(
                                errors.description1,
                                touched.description1
                                )}
                              onChange={handleChange}
                              error={handleInvoiceErrors(
                              errors.description1,
                              touched.description1
                              )}
                              className={classes.inputField}
                                onBlur={handleBlur}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} className={`${classes.gridItem} marginTop12`}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`templateHeight12 ${classes.uploadImageContainer}`}>
                              <ImageUploadView
                                handleImageChange={this.handleImageChange}
                                uploadTexts={configJSON.uploadImageText}
                                setFieldValue={setFieldValue}
                                name={configJSON.image1}
                                currentImage={imageUrls[configJSON.image1]?.url}
                                isDisabled={this.isViewOnly()}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image1,
                              touched.image1
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image1,
                                    touched.image1
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={4} alignItems="flex-end">
                        <Grid item sm={6}  lg={6} md={6} className="paddingBottom">
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel} required>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              value={values.description2}
                              variant="outlined"
                              name={configJSON.description2}
                              fullWidth
                              error={handleInvoiceErrors(
                                errors.description2,
                                touched.description2
                              )}
                              disabled={this.isViewOnly()}
                              type="text"
                              onChange={handleChange}
                              className={classes.inputField}
                              helperText={handleInvoiceHelperText(
                                errors.description2,
                                touched.description2
                                )}
                                onBlur={handleBlur}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} className={`${classes.gridItem} marginTop12`}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`${classes.uploadImageContainer} templateHeight12`}>
                              <ImageUploadView
                                name={configJSON.image2}
                                setFieldValue={setFieldValue}
                                handleImageChange={this.handleImageChange}
                                isDisabled={this.isViewOnly()}
                                uploadTexts={configJSON.uploadImageText}
                                currentImage={imageUrls[configJSON.image2]?.url}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image2,
                              touched.image2
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image2,
                                    touched.image2
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={4} alignItems="flex-end">
                        <Grid item lg={6} md={6} sm={6} className="paddingBottom">
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel} required>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              variant="outlined"
                              value={values.description3}
                              name={configJSON.description3}
                              type="text"
                              className={classes.inputField}
                              onChange={handleChange}
                              disabled={this.isViewOnly()}
                              onBlur={handleBlur}
                              error={handleInvoiceErrors(
                              errors.description3,
                              touched.description3
                            )}
                              fullWidth
                              helperText={handleInvoiceHelperText(
                                errors.description3,
                                touched.description3
                                )}
                                />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} className={`${classes.gridItem} marginTop12`}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`${classes.uploadImageContainer} templateHeight12`}>
                              <ImageUploadView
                                name={configJSON.image3}
                                uploadTexts={configJSON.uploadImageText}
                                setFieldValue={setFieldValue}
                                handleImageChange={this.handleImageChange}
                                currentImage={imageUrls[configJSON.image3]?.url}
                                isDisabled={this.isViewOnly()}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image3,
                              touched.image3
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image3,
                                    touched.image3
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={4} alignItems="flex-end">
                        <Grid item lg={6} md={6} sm={6} className="paddingBottom">
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel} required>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              variant="outlined"
                              value={values.description4}
                              name={configJSON.description4}
                              error={handleInvoiceErrors(
                              errors.description4,
                              touched.description4
                            )}
                              type="text"
                              disabled={this.isViewOnly()}
                              className={classes.inputField}
                              fullWidth
                              onChange={handleChange}
                              helperText={handleInvoiceHelperText(
                                errors.description4,
                                touched.description4
                                )}
                                onBlur={handleBlur}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} className={`${classes.gridItem} marginTop12`}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`${classes.uploadImageContainer} templateHeight12`}>
                              <ImageUploadView
                                name={configJSON.image4}
                                uploadTexts={configJSON.uploadImageText}
                                setFieldValue={setFieldValue}
                                handleImageChange={this.handleImageChange}
                                currentImage={imageUrls[configJSON.image4]?.url}
                                isDisabled={this.isViewOnly()}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image4,
                              touched.image4
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image4,
                                    touched.image4
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid id="container2" item className={classes.gridItem} xs={12} sm={6} md={4} lg={4} >
                      <Grid container spacing={4} alignItems="flex-end">
                        <Grid item lg={6} md={6} sm={6} className="paddingBottom">
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel} required>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              variant="outlined"
                              name={configJSON.description5}
                              value={values.description5}
                              type="text"
                              helperText={handleInvoiceHelperText(
                                errors.description5,
                                touched.description5
                              )}
                              fullWidth
                              disabled={this.isViewOnly()}
                              onChange={handleChange}
                              className={classes.inputField}
                              error={handleInvoiceErrors(
                                errors.description5,
                                touched.description5
                                )}
                                onBlur={handleBlur}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} className={`${classes.gridItem} marginTop12`}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`${classes.uploadImageContainer} templateHeight12`}>
                              <ImageUploadView
                                handleImageChange={this.handleImageChange}
                                uploadTexts={configJSON.uploadImageText}
                                setFieldValue={setFieldValue}
                                currentImage={imageUrls[configJSON.image5]?.url}
                                isDisabled={this.isViewOnly()}
                                name={configJSON.image5}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image5,
                              touched.image5
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image5,
                                    touched.image5
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={4} alignItems="flex-end">
                        <Grid item lg={6} md={6} sm={6} className="paddingBottom">
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel} required>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              value={values.description6}
                              variant="outlined"
                              type="text"
                              fullWidth
                              disabled={this.isViewOnly()}
                              onChange={handleChange}
                              name={configJSON.description6}
                              className={classes.inputField}
                              onBlur={handleBlur}
                              error={handleInvoiceErrors(
                                errors.description6,
                                touched.description6
                                )}
                                helperText={handleInvoiceHelperText(
                                  errors.description6,
                                  touched.description6
                                  )}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} className={`${classes.gridItem} marginTop12`}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`${classes.uploadImageContainer} templateHeight12`}>
                              <ImageUploadView
                                name={configJSON.image6}
                                currentImage={imageUrls[configJSON.image6]?.url}
                                handleImageChange={this.handleImageChange}
                                setFieldValue={setFieldValue}
                                isDisabled={this.isViewOnly()}
                                uploadTexts={configJSON.uploadImageText}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image6,
                              touched.image6
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image6,
                                    touched.image6
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={4} alignItems="flex-end">
                        <Grid item lg={6} md={6} sm={6} className="paddingBottom">
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel} required>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              type="text"
                              variant="outlined"
                              value={values.description7}
                              name={configJSON.description7}
                              className={classes.inputField}
                              fullWidth
                              onChange={handleChange}
                              disabled={this.isViewOnly()}
                              helperText={handleInvoiceHelperText(
                                errors.description7,
                                touched.description7
                                )}
                                onBlur={handleBlur}
                                error={handleInvoiceErrors(
                                errors.description7,
                                touched.description7
                              )}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} className={`${classes.gridItem} marginTop12`}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`${classes.uploadImageContainer} templateHeight12`}>
                              <ImageUploadView
                                handleImageChange={this.handleImageChange}
                                name={configJSON.image7}
                                uploadTexts={configJSON.uploadImageText}
                                setFieldValue={setFieldValue}
                                currentImage={imageUrls[configJSON.image7]?.url}
                                isDisabled={this.isViewOnly()}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image7,
                              touched.image7
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image7,
                                    touched.image7
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={4} alignItems="flex-end">
                        <Grid item lg={6} md={6} sm={6} className="paddingBottom">
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel} required>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              type="text"
                              variant="outlined"
                              value={values.description8}
                              name={configJSON.description8}
                              className={classes.inputField}
                              fullWidth
                              onChange={handleChange}
                              disabled={this.isViewOnly()}
                              helperText={handleInvoiceHelperText(
                                errors.description8,
                                touched.description8
                                )}
                                onBlur={handleBlur}
                                error={handleInvoiceErrors(
                                errors.description8,
                                touched.description8
                              )}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} className={`${classes.gridItem} marginTop12`}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`${classes.uploadImageContainer} templateHeight12`}>
                              <ImageUploadView
                                handleImageChange={this.handleImageChange}
                                name={configJSON.image8}
                                uploadTexts={configJSON.uploadImageText}
                                setFieldValue={setFieldValue}
                                currentImage={imageUrls[configJSON.image8]?.url}
                                isDisabled={this.isViewOnly()}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image8,
                              touched.image8
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image8,
                                    touched.image8
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid id="container3" item className={classes.gridItem} xs={12} sm={6} md={4} lg={4} >
                      <Grid container spacing={4} alignItems="flex-end">
                        <Grid item lg={6} md={6} sm={6} className="paddingBottom">
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel} required>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              type="text"
                              value={values.description9}
                              variant="outlined"
                              name={configJSON.description9}
                              fullWidth
                              disabled={this.isViewOnly()}
                              onChange={handleChange}
                              className={classes.inputField}
                              helperText={handleInvoiceHelperText(
                                errors.description9,
                                touched.description9
                              )}
                              onBlur={handleBlur}
                              error={handleInvoiceErrors(
                                errors.description9,
                                touched.description9
                              )}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} className={`${classes.gridItem} marginTop12`}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`${classes.uploadImageContainer} templateHeight12`}>
                              <ImageUploadView
                                handleImageChange={this.handleImageChange}
                                name={configJSON.image9}
                                setFieldValue={setFieldValue}
                                currentImage={imageUrls[configJSON.image9]?.url}
                                isDisabled={this.isViewOnly()}
                                uploadTexts={configJSON.uploadImageText}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image9,
                              touched.image9
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image9,
                                    touched.image9
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={4} alignItems="flex-end">
                        <Grid item lg={6} md={6} sm={6} className="paddingBottom">
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel} required>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              type="text"
                              value={values.description10}
                              variant="outlined"
                              name={configJSON.description10}
                              fullWidth
                              disabled={this.isViewOnly()}
                              onChange={handleChange}
                              className={classes.inputField}
                              helperText={handleInvoiceHelperText(
                                errors.description10,
                                touched.description10
                                )}
                                onBlur={handleBlur}
                              error={handleInvoiceErrors(
                                errors.description10,
                                touched.description10
                              )}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} className={`${classes.gridItem} marginTop12`}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`${classes.uploadImageContainer} templateHeight12`}>
                              <ImageUploadView
                                handleImageChange={this.handleImageChange}
                                name={configJSON.image10}
                                setFieldValue={setFieldValue}
                                isDisabled={this.isViewOnly()}
                                uploadTexts={configJSON.uploadImageText}
                                currentImage={imageUrls[configJSON.image10]?.url}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image10,
                              touched.image10
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image10,
                                    touched.image10
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={4} alignItems="flex-end">
                        <Grid item lg={6} md={6} sm={6} className="paddingBottom">
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel} required>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              type="text"
                              variant="outlined"
                              value={values.description11}
                              name={configJSON.description11}
                              className={classes.inputField}
                              fullWidth
                              onChange={handleChange}
                              disabled={this.isViewOnly()}
                              helperText={handleInvoiceHelperText(
                                errors.description11,
                                touched.description11
                                )}
                                onBlur={handleBlur}
                                error={handleInvoiceErrors(
                                errors.description11,
                                touched.description11
                              )}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} className={`${classes.gridItem} marginTop12`}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`${classes.uploadImageContainer} templateHeight12`}>
                              <ImageUploadView
                                handleImageChange={this.handleImageChange}
                                name={configJSON.image11}
                                uploadTexts={configJSON.uploadImageText}
                                setFieldValue={setFieldValue}
                                currentImage={imageUrls[configJSON.image11]?.url}
                                isDisabled={this.isViewOnly()}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image11,
                              touched.image11
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image11,
                                    touched.image11
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={4} alignItems="flex-end">
                        <Grid item lg={6} md={6} sm={6} className="paddingBottom">
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel} required>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              type="text"
                              variant="outlined"
                              value={values.description12}
                              name={configJSON.description12}
                              className={classes.inputField}
                              fullWidth
                              onChange={handleChange}
                              disabled={this.isViewOnly()}
                              helperText={handleInvoiceHelperText(
                                errors.description12,
                                touched.description12
                                )}
                                onBlur={handleBlur}
                                error={handleInvoiceErrors(
                                errors.description12,
                                touched.description12
                              )}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} className={`${classes.gridItem} marginTop12`}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`${classes.uploadImageContainer} templateHeight12`}>
                              <ImageUploadView
                                handleImageChange={this.handleImageChange}
                                name={configJSON.image12}
                                uploadTexts={configJSON.uploadImageText}
                                setFieldValue={setFieldValue}
                                currentImage={imageUrls[configJSON.image12]?.url}
                                isDisabled={this.isViewOnly()}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image12,
                              touched.image12
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image12,
                                    touched.image12
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box className={classes.templateFooterContainer} style={{ marginTop: "20px" }}>
                    <Box className={classes.approvalContainer}>
                      <Typography
                        className={`${classes.inputLabel2} approvalText`}
                        variant="h4"
                      >
                        {configJSON.sendForApproval}
                      </Typography>
                      <Switch
                        checked={values.send_for_approval}
                        color="primary"
                        onChange={handleChange}
                        name="send_for_approval"
                        disabled={this.state.templateAction !== configJSON.create}
                      />
                    </Box>
                    <AttachFileInput
                      handleFileUpload={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(e, setFieldValue) }}
                      attachmentError={attachmentError}
                      isViewOnly={this.isViewOnly}
                      attachment={attachment}
                      templateAction={templateAction}
                      setFieldValue={setFieldValue}
                      clearAttachment={this.clearAttachment.bind(null, setFieldValue)}
                    />
                  </Box>
                  <Box className={classes.templateBtnWrapper}>
                    {!this.isViewOnly() &&
                      <Button
                      type="submit"
                        variant="contained"
                        className={classes.primaryBtn}
                        data-test-id="create-btn"
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              confirmDelete={this.handleDeleteTemplate}
              from={configJSON.procedureFrom}
              onClose={this.handleDeleteClose}
              open={opeDeleteModal}
              title={configJSON.templateDeleteTitle}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(Template13);
// Customizable Area End