// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { apiCall, filterNullObj, handleApiError, handleExpiredToken } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
import moment from "moment";
import { DateData } from "./LibraryController.web";
// Customizable Area Start
export const configJSON = require("./config");

export interface Props {
    classes: Record<string, string>;
    gridView: boolean;
    date: DateData;
    searchQuery: string;
    // Customizable Area Start
    navigation: any;
    allowEditDelete: (creatorId: number | string | undefined) => void;
    formData:FormValues
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    checklist_list: FormListData[];
    anchorEl: HTMLElement | null;
    checklist_id: string;
    header_list: string[];
    isOpen: { [key: string]: boolean };
    openDeleteModal: boolean;
    search_checklist_list: FormListData[] | null;
    formListData: FormListData[];
    meta: {
        total_pages: number;
        total_count: number;
        current_page: number;
        next_page: null | number;
        previous_page: null | number;
    },
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

// Customizable Area Start

interface ValidData {
    data: Array<ValidDataObj>;
}

interface ValidDataObj {
    attributes: {
        title: string;
        id: string;
        branch_id: number;
        created_at: string;
        favorite: boolean;
    }
}

export interface InvalidResponseType {
    errors: string;
}

export interface ValidResponseType {
    checklists: ValidData;
}

interface ResponseCheckList {
    forms: {
        data: Array<ValidDataObj>;
    },
    meta: {
        total_count: number;
        total_pages: number;
        next_page: number | null;
        previous_page: number | null;
        current_page: number;
    }
}

interface FormValues {
    department_id: string;
    branch_id: string;
}

interface ResponseErrorData {
    errors: string[]
}

export interface FormListData {
    attributes: {
        title: string;
        id: string;
        branch_id: number;
        created_at: string;
        favorite: boolean;
        creator?: FormCreator;
    }
}

export interface FormCreator {
  id: number | string;
  name: string;
  designation: string;
  email: string
} 


// Customizable Area End

export default class FormController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    deleteFormApiId: string = "";
    favouriteFormApiId: string = "";
    getFormApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            checklist_list: [],
            loading: false,
            checklist_id: "",
            formListData: [],
            anchorEl: null,
            openDeleteModal: false,
            header_list: configJSON.formHeaderList,
            search_checklist_list: null,
            isOpen: {},
            meta: {
                total_count: 1,
                total_pages: 1,
                previous_page: null,
                next_page: null,
                current_page: 1,
            },
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const messageResponse = getName(MessageEnum.RestAPIResponceMessage)
        if (messageResponse === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            handleExpiredToken(responseJson, this.props.navigation);
            this.setState({ loading: false });
            if (apiCallId && responseJson) {
                 if (apiCallId === this.deleteFormApiId) {
                    this.handleDeleteResponse(responseJson);
                }
                else if (apiCallId === this.favouriteFormApiId) {
                    this.handleFavouriteDataResponse(responseJson);
                }
                else if (apiCallId === this.getFormApiCallId) {
                    this.handleFormResponse(responseJson);
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.setState({ loading: true })
        const { date: { from_date, to_date, time_range }, searchQuery,formData } = this.props
        this.getForms(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
    }

    componentDidUpdate(isPrevProps: Props, isPrevState: S) {
        const { date: { from_date, to_date, time_range }, searchQuery,formData } = this.props
        if ((isPrevProps.date.time_range !== this.props.date.time_range) || (isPrevProps.date.from_date !== this.props.date.from_date) || (isPrevProps.date.to_date !== this.props.date.to_date) || formData.branch_id !== isPrevProps.formData.branch_id || formData.department_id !== isPrevProps.formData.department_id) {
            
            if (from_date && to_date) {
                this.setState({ loading: true })

                this.getForms(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
            }
            if (time_range !== "custom") {
                this.setState({ loading: true })
                this.getFilteredForms(from_date, to_date, time_range, searchQuery)
            }
        }
        if (isPrevProps.gridView !== this.props.gridView) {
            this.closeDropDown()
        }
        if (isPrevProps.searchQuery !== this.props.searchQuery) {
            this.setState({ loading: true })
            this.getForms(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
        }
    }

    handleFavouriteDataResponse = (respone: { message: string[] }) => {
        const { date: { from_date, to_date, time_range },formData } = this.props
        this.getForms(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id)
        toast.success(respone.message[0])
    }

    handleDeleteResponse = (deleteRes: ResponseErrorData) => {
        const { date: { from_date, to_date, time_range } ,formData} = this.props
        if (deleteRes.errors) {
            handleApiError(deleteRes.errors)
        } else {
            this.getForms(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id)
            toast.success(configJSON.formDeleteMessage)
        }
    }

    handleFormResponse = (response: ResponseCheckList & ResponseErrorData) => {
        this.closeDropDown()
        if (response.errors) {
            this.setState({loading:false})
            handleApiError(response.errors)
        } else {
            this.setState(prev => ({ loading: false, formListData: response.forms.data, meta: response.meta ?? prev.meta }))
        }
    }

    getForms = (page: number, from_date: string | null, to_date: string | null, time_range: string | null, department_id: string, query?: string) => {
        this.setState({ loading: true })
        let endPointfiter: string = `${configJSON.FormApiEndPoint}`
        const {branch_id} = this.props.formData
        let allData = {page, custom_start_date: from_date ? moment(from_date).format("DD/MM/YYYY") : "", custom_end_date: to_date ? moment(to_date).format("DD/MM/YYYY") : "", time_range, branch_id, department_id, query};

        this.getFormApiCallId = apiCall({ method: "GET", endPoint: endPointfiter + filterNullObj(allData), token: true })
    }

    getFormattedDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString().split(",")[0];
    };

    getFilteredForms = (from_date: string | null, to_date: string | null, time_range: string | null, searchQuery: string = '') => {
        const department_id = this.props.formData.department_id
        switch (time_range) {
            case configJSON.today:
                this.getForms(this.state.meta.current_page, from_date, to_date, "today", department_id, searchQuery)
                break
            case configJSON.yesterday:
                this.getForms(this.state.meta.current_page, from_date, to_date, "yesterday", department_id, searchQuery)
                break
            case configJSON.lastDays:
                this.getForms(this.state.meta.current_page, from_date, to_date, "15_days", department_id, searchQuery)
                break
            default:
                this.getForms(this.state.meta.current_page, from_date, to_date, "", department_id, searchQuery)
                break
        }
    }

    toggleDropdown = (itemId: string) => {
        this.setState((isPrevState) => {
            const newOpen = { ...isPrevState.isOpen };
            for (const keyValue in newOpen) {
                newOpen[keyValue] = false;
            }
            newOpen[itemId] = !isPrevState.isOpen[itemId];
            return {
                isOpen: newOpen,
                checklist_id: itemId
            };
        });
    };

    handleCloseDeleteModal = () => {
        this.setState({ openDeleteModal: false });
    };

    handleOpenDeleteModal = () => {
        this.setState({ openDeleteModal: true });
        this.closeDropDown();
    };

    handleFavouriteClick = (favourite: boolean) => {
        this.setState({ loading: true, isOpen: {} })
        this.favouriteFormApiId = favourite ?
          apiCall({
            method: configJSON.deleteMethod,
            endPoint: `${configJSON.deleteFormApiEndPoint}${this.state.checklist_id}${configJSON.removeFavorite}`,
            token: true,
          })
          :
          apiCall({
            method: configJSON.putMethod,
            endPoint: `${configJSON.deleteFormApiEndPoint}${this.state.checklist_id}${configJSON.addFavorite}`,
            token: true,
          });
    };

    closeDropDown = () => {
        this.setState((isPrevState) => {
            const newOpen = { ...isPrevState.isOpen };

            for (const keyValue in newOpen) {
                newOpen[keyValue] = false;
            }
            return {
                isOpen: newOpen,
            };
        });
    };

    handlePageChange = (page: number) => {
        const { date: { from_date, to_date, time_range },formData } = this.props
        this.setState({
            meta: {
                ...this.state.meta,
                current_page: page
            }
        });
        this.getForms(page, from_date, to_date, time_range, formData.department_id)
    };

    handleViewClick = (itemId: string) => {
        this.props.navigation.history.push(`Form?id=${itemId}`, { from: configJSON.fromLibrary, action: configJSON.fill })
    };

    handleDeleteClick = () => {
        this.setState({ loading: true });
        this.deleteFormApiId = apiCall({ method: configJSON.deleteMethod, endPoint: `${configJSON.deleteFormApiEndPoint}${this.state.checklist_id}`, token: true });
        this.handleCloseDeleteModal();
    };

    // Customizable Area End
}