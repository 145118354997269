// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, handleExpiredToken, checkToken, convertFormData, handleApiError, userRoleType, removeNullFromObj, loggedUserId } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: Record<string,string>

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading:boolean;
  employee_list: EmployeeTypes[];
  item: Partial<EmployeeTypes>,
  meta: {
    total_pages: number;
    total_count: number;
    current_page: number;
    next_page: null | number;
    previous_page: null | number;
  },
  openDeleteModel: boolean;
  openRoleModel: boolean;
  is_open_role: boolean;
  select_role: string | null;
  role_id: string | number;
  searchQuery: string;
  select_status: string;
  is_open_status: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
export interface EmployeeTypes {
  id: string
  type: string
  attributes: Attributes
}
export interface Attributes {
  activated: boolean
  country_code: string
  email: string
  first_name: string
  full_phone_number: string
  last_name: string
  phone_number: string
  unique_auth_id: string
  role_id: number
  branch_id: number
  joining_date: string
  role_name: string
  branch_name: string
  status: string;
  department_names: string[],
  editable: boolean,
  requested_by: RequestedBy,
  profile: {
    data: {
      attributes: {
        owner_name: string;
        phone_number: string;
        owner_email: string;
      }
    } | null
  }
}

interface RequestedBy {
  id: number | string;
  name: string;
  designation: string;
  email: string
} 

// Customizable Area End

export default class DepartmentEmployeeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  employeeListDataCallId: string = "";
  editemployeeApiCallId: string = "";
  deleteEmployeeApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      loading:false,
      employee_list: [],
      item: {},
      meta: {
        total_pages: 1,
        total_count: 1,
        current_page: 1,
        next_page: null,
        previous_page: null
      },
      openDeleteModel: false,
      openRoleModel: false,
      is_open_role: false,
      select_role: "",
      role_id: "",
      searchQuery: "",
      select_status: "",
      is_open_status: false

    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

 async componentDidMount() {
    // Customizable Area Start
    checkToken(this.props.navigation);
    this.employeeListData(this.state.meta.current_page)
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.searchQuery !== prevState.searchQuery || this.state.select_status !== prevState.select_status) {
      this.employeeListData(this.state.meta.current_page)
    }
  }
  handlePageChange = (pageNumber: number) => {
    this.setState({
      meta: {
        ...this.state.meta,
        current_page: pageNumber
      }
    }, () => { this.employeeListData(this.state.meta.current_page) });

  };
  handleClearAllFilter = () => {
    this.setState({
      searchQuery:"",
      select_status: ""
    })
  }
  handleDisable = () => {
    let allData = {select_status:this.state.select_status,query: this.state.searchQuery};
    const nullableData = removeNullFromObj(allData)
   return (Object.keys(nullableData).length <= 0)
  }
  handleEditableStatus = (value: { name: string; id: string | number }) => {
    this.setState({
      select_status: value.name,
      is_open_status: false,
    });
  };

  toggleEditableStatus = () => {
    this.setState((prevState) => ({
      is_open_status: !prevState.is_open_status
    }));
  };
  handleSearch = (query: string) => {
    this.setState({ searchQuery: query })
  }
  handleClose = () => {
    this.setState({ openDeleteModel: false, openRoleModel: false })
  }
  handleEdit = (item: EmployeeTypes) => {
    const role = userRoleType === "Business Owner" || userRoleType === "Manager"
    const userStatus = item.attributes.status === "pending"
    const  { editable }= item.attributes
    if (role && editable) {
      if (userStatus) {
        this.setState({
          item: item,
          openRoleModel: true,
          select_role: item.attributes.role_name,
        })
      } else {
        toast.error(configJSON.roleSelectErrorMessage)
      }
    } else {
      toast.error(configJSON.editErrorMessage)
    }

  }
  handleSelectRole = (value: { name: string; id: string | number }) => {
    this.setState({
      select_role: value.name,
      is_open_role: false,
      role_id: value.id
    });
  };

  toggleSelectRole = () => {
    this.setState((prevState) => ({
      is_open_role: !prevState.is_open_role
    }));
  };

  handleDelete = (item: EmployeeTypes) => {
    const role = userRoleType === "Business Owner" || userRoleType === "Manager"
    const userStatus = item.attributes.status === "pending" || item.attributes.status === "inactive"
    const  {editable }= item.attributes
    if (role && (editable)) {
      if (userStatus) {
        this.setState({
          item: item,
          openDeleteModel: true,
        })
      } else {
        toast.error(configJSON.deleteClickErrorMessage)
      }
    } else {
      toast.error(configJSON.editErrorMessage)
    }

  }
  handleDeleteEmployee = () => {
    this.deleteEmployeeApiCallId = apiCall({ method: configJSON.deleteApiMethod, endPoint: `${configJSON.deleteEmployeeEndpoint}/${this.state.item.id}`, token: true, });

  }

  employeeListData = (page: number) => {
    this.setState({ loading: true })
    const dataValue = this.props.navigation.history.location.state
    this.employeeListDataCallId = apiCall({
      method: configJSON.postApiMethod,
      endPoint: `${configJSON.employeeListApiEndpoint}${dataValue.depart_id}&branch_id=${dataValue.branch_id}&page=${page}&query=${this.state.searchQuery}&status=${this.state.select_status.toLowerCase().replace(/\s/g, '')}`,
      token: true,
    });
  }

  editEmployee = () => {
    const stateData = this.props.navigation.history.location.state
    const { role_id, item } = this.state
    const attrs = {
      role_id: role_id,
      employee_id: item.id,
      assigned_department_ids: [stateData.depart_id],
    };

    this.editemployeeApiCallId = apiCall({ method: configJSON.putApiMethod, body: convertFormData(attrs), endPoint: configJSON.geteditEmployeeListingApiEndpoint, token: true, });

  }

  handleResponseForEmployeeList = (from: string, message: Message) => {
    if (this.employeeListDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.data) {
        this.setState((prev) => ({
          loading:false,
          employee_list: apiResponse.data.filter((user: {id: string}) => Number(user.id) !== loggedUserId),
          meta: apiResponse.meta ?? prev.meta
        }))
      } else {
        handleApiError(apiResponse.errors)
      }


    }
  }
  handleResponseForEditEmployee = (from: string, message: Message) => {
    if (this.editemployeeApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.data) {
        this.employeeListData(this.state.meta.current_page)
        this.setState({ openRoleModel: false })
        toast.success(configJSON.roleSuccessMessage)
      } else {
        this.setState({ openRoleModel: false })
        handleApiError(apiResponse.errors)
      }
    }
  }

  handleDeleteEmployeeResponse = (from: string, message: Message) => {
    if (this.deleteEmployeeApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(response, this.props.navigation);
      if (response.message) {
        toast.success(`${response.message}`)
        this.employeeListData(this.state.meta.current_page)
        this.setState({ openDeleteModel: false })
      } else {
        this.setState({ openDeleteModel: false })
        handleApiError(response.errors);

      }

    }
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForEmployeeList(from, message)
    this.handleResponseForEditEmployee(from, message)
    this.handleDeleteEmployeeResponse(from, message)
    // Customizable Area End
  }
  // Customizable Area End


}
