/** @format */

import React from "react";
// Customizable Area Start
import { commonInputField, commonButton, helperText, commonTemplateCss, singleValueCss } from "../../../components/src/utils/commonInputStyle";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, TextField, Button, Grid, Switch, FormHelperText } from "@material-ui/core";
import Template8Controller, { Props, configJSON } from "./Template8Controller.web"
import { Formik, Form } from 'formik';
import { Wrapper } from "../../../components/src/Wrapper";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import DropdownSearch from "../../../components/src/commonComponents/DropdownSearch.web";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import AttachFileInput from "../../../components/src/commonComponents/AttachFileInput.web";
// Customizable Area End

// Customizable Area Start

export const styles = () => createStyles({
  ...commonButton,
  ...commonInputField,
  ...commonTemplateCss

});
// Customizable Area End

export class Template8 extends Template8Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { classes } = this.props;
    const { attachment, formData, isDownload, imageUrls, attachmentError, loading, branch_data, department_data, templateAction, openMenu, opeDeleteModal } = this.state;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper navigation={this.props.navigation} divider backbtn={true} title={this.headerTitle()} bellIcn={true} homeIcn={true} child={
          <Box>
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={this.template8InitialValues()}
              validationSchema={this.template8ValidationSchema()}
              onSubmit={this.addEditTemplate8}
            >
              {({ errors, touched, handleBlur, handleChange, setFieldValue, values }) => (
                <Form translate className={`${classes.formContainer} ${isDownload && "textColor"}`}>
                  <Grid ref={this.pdfContentRef} direction="row" container className={classes.gridContainer}>
                    <Grid item className={classes.gridItem} xs={10} sm={5}>
                      {this.isViewOnly() ?
                        <Typography data-test-id="title" variant="h4" className={classes.inputLabel2} style={{ fontWeight: 700 }}>
                          {values.title}
                        </Typography> :
                        <Box className={classes.fieldWrapper} style={{ margin: "0" }}>
                          <InputLabel className={classes.inputLabel2} style={{ fontSize: "16px" }} required>
                            {configJSON.templateTitleLabel}
                          </InputLabel>
                          <TextField
                            type="text"
                            data-test-id="title_input_field"
                            variant="outlined"
                            name={configJSON.templateTitleName}
                            value={values.title}
                            onChange={handleChange}
                            fullWidth
                            className={classes.inputField}
                            error={handleInvoiceErrors(
                              errors.title,
                              touched.title
                            )}
                            helperText={handleInvoiceHelperText(
                              errors.title,
                              touched.title
                            )}
                            onBlur={handleBlur}
                          />
                        </Box>
                      }
                    </Grid>
                    <Grid xs={2} sm={7} item className={`${classes.gridItem} actionBtn ${isDownload && "visibility"}`}>
                      {templateAction !== configJSON.create &&
                        <ActionButton
                          edit={this.allowEdit()}
                          download
                          mark_as_favourite
                          favourite={formData.favorite}
                          handleFavourite={this.handleFavouriteClick}
                          deleteVisible={this.allowEdit()}
                          duplicate={this.allowEdit()}
                          handleEdit={() => this.handleActions(configJSON.edit)}
                          handleDelete={this.handleDelete}
                          isOpen={openMenu}
                          toggleDropdown={this.toggleMenu}
                          handleDuplicate={this.handleDuplicateTemplate}
                          handleDownload={this.handleDownloadPdf}
                        />
                      }

                    </Grid>
                    <Grid className={classes.gridItem} item xs={12}>
                      <div className={classes.dropdownContainer}>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            options={branch_data}
                            singleValueCss={isDownload && singleValueCss}
                            value={values.branch_id}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                            id={configJSON.templateBranchName}
                            isSearchable={true}
                            isDisabled={this.isViewOnly()}
                            placeholder={configJSON.templateBranchPlaceholder}
                            noOptionsMessage={configJSON.templateNoBranchMessage}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            isMyProfile={true}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            value={values.department_id}
                            options={department_data}
                            singleValueCss={isDownload && singleValueCss}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            id={configJSON.templateDepartmentName}
                            isSearchable={true}
                            noOptionsMessage={configJSON.templateNoDepartmentMessage}
                            placeholder={configJSON.templateDepartmentPlaceholder}
                            errorText={handleInvoiceHelperText(
                              errors.department_id,
                              touched.department_id
                            )}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id
                            )}
                            isMyProfile={true}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid container className={`${classes.gridContainer} columnGap`}>
                      <Grid item className={classes.gridItem} sm={6}>
                        <Box className={classes.fieldWrapper}>
                          <InputLabel className={classes.inputLabel} required>
                            {configJSON.descriptionLabel}
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            name={configJSON.description1}
                            value={values.description1}
                            onChange={handleChange}
                            fullWidth
                            disabled={this.isViewOnly()}
                            className={classes.inputField}
                            error={handleInvoiceErrors(
                              errors.description1,
                              touched.description1
                            )}
                            helperText={handleInvoiceHelperText(
                              errors.description1,
                              touched.description1
                            )}
                            onBlur={handleBlur}
                          />
                        </Box>
                        <Box className={classes.fieldWrapper}>
                          <Box className={classes.uploadImageContainer}>
                            <ImageUploadView
                              handleImageChange={this.handleImageChange}
                              setFieldValue={setFieldValue}
                              name={configJSON.image1}
                              currentImage={imageUrls[configJSON.image1]?.url}
                              uploadTexts={["Upload Image"]}
                              isDisabled={this.isViewOnly()}
                            />
                          </Box>
                          {handleInvoiceErrors(
                            errors.image1,
                            touched.image1
                          ) && (
                              <FormHelperText
                                style={helperText}
                              >
                                {handleInvoiceHelperText(
                                  errors.image1,
                                  touched.image1
                                )}
                              </FormHelperText>
                            )}
                        </Box>
                      </Grid>
                      <Grid item className={`${classes.gridItem} flex`} sm={6}>
                        <Box className={classes.fieldWrapper}>
                          <InputLabel className={classes.inputLabel} required>
                            {configJSON.descriptionLabel}
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            name={configJSON.description2}
                            value={values.description2}
                            onChange={handleChange}
                            fullWidth
                            disabled={this.isViewOnly()}
                            className={classes.inputField}
                            error={handleInvoiceErrors(
                              errors.description2,
                              touched.description2
                            )}
                            helperText={handleInvoiceHelperText(
                              errors.description2,
                              touched.description2
                            )}
                            onBlur={handleBlur}
                          />
                        </Box>
                        <Box className={classes.fieldWrapper}>
                          <Box className={classes.uploadImageContainer}>
                            <ImageUploadView
                              handleImageChange={this.handleImageChange}
                              setFieldValue={setFieldValue}
                              name={configJSON.image2}
                              currentImage={imageUrls[configJSON.image2]?.url}
                              uploadTexts={["Upload Image"]}
                              isDisabled={this.isViewOnly()}
                            />
                          </Box>
                          {handleInvoiceErrors(
                            errors.image2,
                            touched.image2
                          ) && (
                              <FormHelperText
                                style={helperText}
                              >
                                {handleInvoiceHelperText(
                                  errors.image2,
                                  touched.image2
                                )}
                              </FormHelperText>
                            )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box className={classes.templateFooterContainer}>
                    <Box className={classes.approvalContainer}>
                      <Typography variant="h4" className={`${classes.inputLabel2} approvalText`}>
                        {configJSON.sendForApproval}
                      </Typography>
                      <Switch
                        checked={values.send_for_approval}
                        onChange={handleChange}
                        name="send_for_approval"
                        color="primary"
                        disabled={this.state.templateAction !== configJSON.create}
                      />
                    </Box>
                    <AttachFileInput
                      attachment={attachment}
                      attachmentError={attachmentError}
                      templateAction={templateAction}
                      handleFileUpload={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(e, setFieldValue) }}
                      clearAttachment={this.clearAttachment.bind(null, setFieldValue)}
                      setFieldValue={setFieldValue}
                      isViewOnly={this.isViewOnly}
                    />
                  </Box>
                  <Box className={classes.templateBtnWrapper}>
                    {!this.isViewOnly() &&
                      <Button
                        data-test-id="create-btn"
                        variant="contained"
                        className={classes.primaryBtn}
                        type="submit"
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              open={opeDeleteModal}
              onClose={this.handleDeleteClose}
              confirmDelete={this.handleDeleteTemplate}
              title={configJSON.templateDeleteTitle}
              from={configJSON.procedureFrom}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(Template8);
// Customizable Area End

