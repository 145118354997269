/** @format */

import React, { Component } from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import { ClassNameMap } from "@material-ui/styles";
import { Box, Button, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { colors } from "../utils/commonDesign";
// Customizable Area End


// Customizable Area Start
export const styles: Styles<Theme, {}, string> = () => ({
  pagination: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1.5rem 3rem",
    "& button": {
      minWidth: "unset",
      width: "2.2rem",
      borderRadius: 5,
      border: "1px solid #C9C9C9",
      lineHeight: "unset",
      margin: 3,
      height: "2.2rem"
    }
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center"
  },
  active: {
    backgroundColor: colors.secondaryColor1,
    color: "#FFFFFF !important",
    "&:hover": {
      backgroundColor: colors.secondaryColor1,
    }
  },
  inactive: {
    display: "none"
  },
  centerButtons: {
    //width: "16rem",
    display: "flex",
  },
  underScore: {
    width: "1.5rem",
    borderBottom: "1px solid #C9C9C9",
    marginBottom: 4
  },
  iconButton: {
    backgroundColor: colors.secondaryColor2,
    color: "#000000 !important",
    "&:hover": {
      backgroundColor: colors.secondaryColor2,
    }
  },
  disabledButton: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.26) !important",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});
interface PaginationProps {
  meta: {
    total_pages: number;
    total_count: number;
    current_page: number;
    next_page: null | number;
    previous_page: null | number;
  },
  onPageChange: (page: number) => void;
  classes: ClassNameMap<"pagination" | "active" | "inactive" | "centerButtons" | "underScore" | "iconButton"
    | "disabledButton" | "buttonContainer"

  >
}



// Customizable Area End

export class Pagination extends Component<PaginationProps> {
  constructor(props: PaginationProps) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { onPageChange, classes, meta } = this.props;
    const itemsPerPage = 10;
    const startItem = meta.total_count === 0 ? 0 : (meta.current_page - 1) * itemsPerPage + 1;
    const endItem = meta.next_page !== null ? (meta.current_page * itemsPerPage) : meta.total_count
    return (
      <Box className={`${classes.pagination} paginationPadding`}>
        <Typography>
          Showing {startItem} to {endItem} of {meta.total_pages} entries
        </Typography>
        <Box className={classes.buttonContainer}>
          <Button
            data-test-id="previous-btn"
            className={`${classes.iconButton} ${meta.current_page === 1 ? classes.disabledButton : ""}`}
            disableRipple
            onClick={() => onPageChange(Math.max(meta.current_page - 1, 1))}
            disabled={meta.current_page === 1}
          >
            <ArrowBackIcon />
          </Button>
          <Box className={classes.centerButtons}>
            {meta.total_pages !== 0 ? <>
              <Button
                disableRipple
                onClick={() => onPageChange(1)}
                className={`${meta.current_page === 1 ? `${classes.active}` : ""
                  } ${meta.total_pages === 1 ? `${classes.inactive}` : ""}`}
              >
                {meta.total_pages === 1 ? null : 1}
              </Button>
              {meta.current_page > 3 && <Typography className={classes.underScore} component="span"></Typography>}
              {meta.current_page > 2 && (
                <Button
                  disableRipple
                  onClick={() => onPageChange(meta.current_page - 1)}
                  className={meta.current_page - 1 === meta.current_page ? `${classes.active}` : ""}
                >
                  {meta.current_page - 1}
                </Button>
              )}
              {meta.current_page !== 1 && meta.current_page !== meta.total_pages && (
                <Button disableRipple onClick={() => onPageChange(meta.current_page)} className={classes.active}>
                  {meta.current_page}
                </Button>
              )}
              {meta.current_page < meta.total_pages - 1 && (
                <Button
                  disableRipple
                  onClick={() => onPageChange(meta.current_page + 1)}
                  className={meta.current_page + 1 === meta.current_page ? `${classes.active}` : ""}
                >
                  {meta.current_page + 1}
                </Button>
              )}
              {meta.current_page < meta.total_pages - 2 && (
                <Typography className={classes.underScore} component="span"></Typography>
              )}
              <Button
                disableRipple
                onClick={() => onPageChange(meta.total_pages)}
                className={meta.current_page === meta.total_pages ? `${classes.active}` : ""}
              >
                {meta.total_pages}
              </Button>
            </> : null}
          </Box>
          <Button
            data-test-id="next-btn"
            disableRipple
            className={`${classes.iconButton} ${meta.current_page === meta.total_pages || meta.total_pages === 0 ? classes.disabledButton : ""}`}
            onClick={() => onPageChange(Math.min(meta.current_page + 1, meta.total_pages))}
            disabled={meta.current_page === meta.total_pages || meta.total_pages === 0}
          >
            <ArrowForwardIcon />
          </Button>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
export default withStyles(styles)(Pagination);

