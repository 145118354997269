// Customizable Area Start
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const bgImage = require("./backgroundImg1.png");

interface Props {
  classes?: any;
  imagePath?: string;
}

const styles: any = {
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
};

class LoginSignupBackgroundImg extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, imagePath } = this.props;
    const backgroundImageStyle = {
      backgroundImage: `url(${imagePath ? imagePath : bgImage})`,
    };
    return <div className={classes?.backgroundImage} style={backgroundImageStyle} />;
  }
}

export default withStyles(styles)(LoginSignupBackgroundImg);
// Customizable Area End