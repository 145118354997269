import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, TextField, Button, Grid, FormHelperText, Switch, InputAdornment } from "@material-ui/core";
import MenuTemplate3Controller, { Props, configJSON } from "./MenuTemplate3Controller.web"
import { commonInputField, commonButton, helperText, singleValueCss, commonTemplateCss } from "../../../components/src/utils/commonInputStyle";
import { Formik, Form } from 'formik';
import { Wrapper } from "../../../components/src/Wrapper";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import { allowOnlyPrice, handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import DropdownSearch from "../../../components/src/commonComponents/DropdownSearch.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import Loader from "../../../components/src/Loader";

// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonButton,
  ...commonTemplateCss,
  ...commonInputField

});
// Customizable Area End

export class MenuTemplate3 extends MenuTemplate3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { menuData, loading, branchData, categoryData, imageUrl, departmentData, openMenu, opeDeleteModal, isDownload, menuTempAction, template_id } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper bellIcn backbtn={true} navigation={this.props.navigation} divider title={this.headerTitle()} homeIcn={true} child={
          <Box>
            <Formik
              validateOnBlur={true}
              validationSchema={this.menuTempValidationSchema()}
              onSubmit={this.addEditTemplate}
              initialValues={this.menuTempInitialValues()}
              enableReinitialize={true}
            >
              {({ touched, handleBlur, handleChange, setFieldValue, values, errors }) => (
                <Form className={`${classes.formContainer} removePadding ${isDownload && "textColor"}`} translate>
                  <div className={`${classes.actionBtnContainer} ${isDownload && "visibility"}`}>
                    {menuTempAction !== configJSON.create &&
                      <ActionButton
                        toggleDropdown={this.toggleMenu}
                        mark_as_favourite
                        favourite={menuData.favorite}
                        handleFavourite={this.handleMenuFavouriteClick}
                        edit={this.allowMenuEdit()}
                        deleteVisible={this.allowMenuEdit()}
                        download
                        handleEdit={() => this.handleActions(configJSON.edit)}
                        duplicate={this.allowMenuEdit()}
                        isOpen={openMenu}
                        handleDelete={this.handleDelete}
                        handleDuplicate={this.handleDuplicateMenuTemp}
                        handleDownload={this.downloadMenuTemp}
                      />
                    }
                  </div>
                  <Grid ref={this.pdfRef} alignItems="stretch" direction="row" className={classes.gridContainer} container spacing={5}>
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      {this.isViewOnly() ?
                        <Typography data-test-id="title" variant="h4" className={`title ${classes.inputLabel2}`}>
                          {values.name}
                        </Typography> :
                        <Box className={`marginTitle ${classes.fieldWrapper}`}>
                          <InputLabel required className={`${classes.inputLabel}`}>
                            {configJSON.menuTempNameLabel}
                          </InputLabel>
                          <TextField
                            name={configJSON.menuTempNameName}
                            data-test-id="title_input_field"
                            variant="outlined"
                            value={values.name}
                            fullWidth
                            error={handleInvoiceErrors(
                              errors.name,
                              touched.name,
                            )}
                            type="text"
                            helperText={handleInvoiceHelperText(
                              errors.name,
                              touched.name
                            )}
                            onChange={handleChange}
                            className={classes.inputField}
                            onBlur={handleBlur}
                          />
                        </Box>
                      }
                      <div className={classes.dropdownContainer}>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            singleValueCss={isDownload && singleValueCss}
                            value={values.branch_id}
                            isSearchable={true}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                            id={configJSON.templateBranchName}
                            options={branchData}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            placeholder={configJSON.templateBranchPlaceholder}
                            isDisabled={this.isViewOnly()}
                            noOptionsMessage={configJSON.templateNoBranchMessage}
                            isMyProfile={true}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            options={departmentData}
                            noOptionsMessage={configJSON.templateNoDepartmentMessage}
                            value={values.department_id}
                            singleValueCss={isDownload && singleValueCss}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                            errorText={handleInvoiceHelperText(
                              errors.department_id,
                              touched.department_id,
                            )}
                            isMyProfile={true}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id,
                            )}
                            isSearchable={true}
                            placeholder={configJSON.templateDepartmentPlaceholder}
                            id={configJSON.templateDepartmentName}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <Box className={`${classes.fieldWrapper} marginTitle`}>
                        <InputLabel required className={classes.inputLabel}>
                          {configJSON.menuTempCategoryLabel}
                        </InputLabel>
                        <DropdownSearch
                          options={categoryData}
                          noOptionsMessage={configJSON.menuTempNoCategoryMessage}
                          value={values.menu_type_id}
                          onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "menu_type_id")}
                          isSearchable={true}
                          isDisabled={this.isViewOnly()}
                          showError={handleInvoiceErrors(
                            errors.menu_type_id,
                            touched.menu_type_id
                          )}
                          errorText={handleInvoiceHelperText(
                            errors.menu_type_id,
                            touched.menu_type_id
                          )}
                          singleValueCss={isDownload && singleValueCss}
                          id={configJSON.menuTempCategoryName}
                          placeholder={""}
                          onBlur={handleBlur}
                        />
                      </Box>
                      <Box className={`${classes.menuTypeContainer}`} >
                        <Box className={classes.approvalContainer}>
                          <Typography className={`${classes.inputLabel2} approvalText`} variant="h4">
                            {configJSON.vegLabel}
                          </Typography>
                          <Switch
                            name={configJSON.vegName}
                            disabled={this.isViewOnly()}
                            color="primary"
                            onChange={handleChange}
                            checked={values.veg}
                          />
                        </Box>
                        <Box className={classes.approvalContainer}>
                          <Typography className={`${classes.inputLabel2} approvalText`} variant="h4">
                            {configJSON.gfLabel}
                          </Typography>
                          <Switch
                            name={configJSON.gfName}
                            disabled={this.isViewOnly()}
                            color="primary"
                            onChange={handleChange}
                            checked={values.gf}
                          />
                        </Box>
                        <Box className={classes.approvalContainer}>
                          <Typography className={`${classes.inputLabel2} approvalText`} variant="h4">
                            {configJSON.kLabel}
                          </Typography>
                          <Switch
                            name={configJSON.kName}
                            disabled={this.isViewOnly()}
                            color="primary"
                            onChange={handleChange}
                            checked={values.k}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item className={`${classes.gridItem} marginTop`} sm={6}>
                      <Box className={classes.fieldWrapper}>
                        <Box className={`${classes.uploadImageContainer} ${template_id === 3 ? "menuTempHeight3" : "menuTempHeight4"} ${template_id === 4 && "menuTempWidth4"}`}>
                          <ImageUploadView
                            currentImage={imageUrl?.url}
                            name={configJSON.image}
                            handleImageChange={this.handleImageChange}
                            setFieldValue={setFieldValue}
                            uploadTexts={["Upload Image"]}
                            isDisabled={this.isViewOnly()}
                          />
                        </Box>
                        {handleInvoiceErrors(
                          errors.image,
                          touched.image
                        ) && (
                            <FormHelperText
                              style={helperText}
                            >
                              {handleInvoiceHelperText( errors.image,touched.image)}
                            </FormHelperText>
                          )}
                      </Box>
                      <Box
                        className={
                          `${classes.gridItem} ${classes.productGrid2}`
                        }
                      >
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <InputLabel required className={classes.inputLabel}>
                            {configJSON.usdLabel}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            name={configJSON.usdName}
                            type="text"
                            disabled={this.isViewOnly()}
                            value={values.usd}
                            className={classes.inputField}
                            helperText={handleInvoiceHelperText(
                              errors.usd,
                              touched.usd
                            )}
                            error={handleInvoiceErrors(
                              errors.usd,
                              touched.usd
                            )}
                            onChange={handleChange}
                            fullWidth
                            onBlur={handleBlur}
                            InputProps={{
                              startAdornment: menuTempAction !== configJSON.create && <InputAdornment className={classes.usdSymbole} position="start">$</InputAdornment>,
                            }}
                            onKeyPress={allowOnlyPrice}
                          />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <InputLabel className={classes.inputLabel}>
                            {configJSON.recipeCodeLabel}
                          </InputLabel>
                          <TextField
                            name={configJSON.recipeCodeName}
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={values.recipe_code}
                            disabled={true}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={classes.inputField}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item sm={6} className={classes.gridItem}>
                      <Box className={classes.productGrid3}>
                        <Box
                          className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}
                        >
                          <InputLabel className={classes.inputLabel}>
                            {configJSON.pairWithLabel}
                          </InputLabel>
                          <TextField
                            fullWidth
                            type="text"
                            value={values.pair_with1}
                            name={configJSON.pair_with1}
                            disabled={this.isViewOnly()}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={classes.inputField}
                            variant="outlined"
                          />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <TextField
                            fullWidth
                            type="text"
                            value={values.pair_with2}
                            name={configJSON.pair_with2}
                            disabled={this.isViewOnly()}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={`${classes.inputField} ${classes.paddingTop18}`}
                            variant="outlined"
                          />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <TextField
                            fullWidth
                            type="text"
                            value={values.pair_with3}
                            name={configJSON.pair_with3}
                            disabled={this.isViewOnly()}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={`${classes.inputField} ${classes.paddingTop18}`}
                            variant="outlined"
                          />
                        </Box>
                      </Box>
                      <Box className={classes.productGrid3}>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <InputLabel className={classes.inputLabel}>
                            {configJSON.addOnLabel}
                          </InputLabel>
                          <TextField
                            className={classes.inputField}
                            fullWidth
                            type="text"
                            value={values.add_on1}
                            name={configJSON.add_on1}
                            disabled={this.isViewOnly()}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <TextField
                            className={`${classes.inputField} ${classes.paddingTop18}`}
                            fullWidth
                            type="text"
                            value={values.add_on2}
                            name={configJSON.add_on2}
                            disabled={this.isViewOnly()}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                          <TextField
                            className={`${classes.inputField} ${classes.paddingTop18}`}
                            fullWidth
                            type="text"
                            value={values.add_on3}
                            name={configJSON.add_on3}
                            disabled={this.isViewOnly()}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Box>
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel}>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          className={`${classes.inputField} ${classes.textAreaInputHeight}  ${template_id === 3 ? "menuTextAreaInputHeight3" : "menuTextAreaInputHeight4"} ${classes.textAreaField}`}
                          fullWidth
                          type="text"
                          disabled={this.isViewOnly()}
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={configJSON.templateDescriptionName}
                          multiline
                          variant="outlined"
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel}>
                          {configJSON.faqLabel}
                        </InputLabel>
                        <TextField
                          fullWidth
                          className={`${classes.inputField} ${classes.textAreaInputHeight}  ${template_id === 3 ? "menuTextAreaInputHeight3" : "menuTextAreaInputHeight4"} ${classes.textAreaField}`}
                          type="text"
                          disabled={this.isViewOnly()}
                          value={values.faq}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={configJSON.faqName}
                          multiline
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={`${classes.templateFooterContainer} marginTop40`} >
                    <Box 
                      className={classes.approvalContainer}
                    >
                      <Typography className={`${classes.inputLabel2} approvalText`} variant="h4">
                        {configJSON.sendForApproval}
                      </Typography>
                      <Switch
                        name="send_for_approval"
                        color="primary"
                        checked={values.send_for_approval}
                        onChange={handleChange}
                        disabled={this.state.menuTempAction !== configJSON.create}
                      />
                    </Box>
                  </Box>
                  <Box className={`${classes.templateBtnWrapper} menuBtnContainer`}>
                    {!this.isViewOnly() &&
                      <Button
                        type="submit"
                        className={classes.primaryBtn}
                        variant="contained"
                        data-test-id="create-btn"
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              onClose={this.handleDeleteClose}
              open={opeDeleteModal}
              confirmDelete={this.handleDeleteTemplate}
              from={configJSON.menuFrom}
              title={configJSON.menuTempDeleteTitle}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(MenuTemplate3);
// Customizable Area End

