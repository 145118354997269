/** @format */

import React from "react";
// Customizable Area Start
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { DateRange, StarOutline, ArrowDropDown } from '@material-ui/icons';
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, TextField, Button, ButtonGroup, Grid, FormHelperText, Switch } from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';
import EditTaskController, { Props, RenderFormProps, configJSON } from "./EditTaskController.web"
import { Formik } from 'formik';
import { CreateTaskSchema } from "../../../components/src/utils/validationSchema"
import { Wrapper } from "../../../components/src/Wrapper";
import { getStatusIcon, handleInvoiceErrors, handleInvoiceHelperText, loggedUserId } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import { CalenderIcon, CopyIcon, DeleteIcon, EditIcon, YellowsendIcon, attachIcon, closeIcon } from "../../../components/src/commonComponents/assets";
import RadioListWeb from "../../../components/src/commonComponents/fields/RadioList.web";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import TicketCommentsModal from "../../../components/src/commonComponents/modals/TicketCommentsModal.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import EditCommentModal from "../../../components/src/commonComponents/modals/EditCommentModal.web";
import SuccessModal from "../../../components/src/commonComponents/SuccessModal.web";
import { colors } from "../../../components/src/utils/commonDesign";
import { commonButton, commonInputField } from "../../../components/src/utils/commonInputStyle";
import AttachFileInput  from "../../../components/src/commonComponents/AttachFileInput.web";
// Customizable Area End

export class EditTask extends EditTaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { userId,attachment, isEditTask, taskData, branches, departments, employees, priorites, document, comments, openStatus, apiError, attachError, loading, openCommentsList, openEdtiComment, opeDeleteComment, openSuccess, commentId, isEditTitle, isEditDescription, isEditDueDate } = this.state;
    const { classes } = this.props;
    const taskHeaderContent = (
     { values,
      errors,
      touched,
      handleBlur,
      handleChange,
      setFieldValue}:RenderFormProps
    ) => (
      <>
        <Box className={classes.headerContainer}>
          <Box>
            <Box className={classes.innerHeaderContainer}>
              <Typography variant="h4" data-test-id="task_details" className={classes.inputLabel2}>
                {configJSON.taskDetails}
              </Typography>
              <Typography variant="body1" className={classes.taskDetailsText}>
                <Typography component="span" data-test-id="task_number" className="ticketOpacity">{configJSON.taskID} - {taskData.task_id}</Typography>
              </Typography>
            </Box>
          </Box>
          <Box className={classes.btnContainer}>
            {(taskData.status !== 'completed' && this.checkCreator()) &&
              <Box className={classes.innerBtnContainer}>
                {!isEditTask && <Button
                  variant="outlined"
                  data-test-id="enable-edit-btn"
                  onClick={this.handleEnableEdit}
                  className={`${classes.secondaryBtn} ${classes.secondaryBtn2}`}
                  startIcon={<img src={EditIcon} alt="EditIcon" />}
                >
                  {configJSON.edit}
                </Button>}
                <Button
                  data-test-id="duplicate-btn"
                  onClick={this.duplicateTask}
                  variant="outlined"
                  className={`${classes.secondaryBtn} ${classes.secondaryBtn2}`}
                  startIcon={<img src={CopyIcon} alt="CopyIcon" />}
                >
                  {configJSON.duplicate}
                </Button>
              </Box>
            }
            <Box>
              <Box>
                <ButtonGroup className={classes.btnGroup} variant="contained" color="primary" ref={this.anchorRef} aria-label="split button">
                  <Button
                    id="status-btn"
                    className={`${classes.primaryBtn} ${classes.primaryBtn2} ${taskData.status}`}
                    onClick={this.toggleStatusModal}
                    disabled={taskData.status === "completed"}
                    endIcon={taskData.status !== "completed" && <ArrowDropDown />}
                  >
                    {taskData.status !== "submit_for_review" && <img id="status-icon" src={getStatusIcon(taskData.status)} className={classes.imageMargin} alt="Icon" />} {taskData.status_name}
                  </Button>
                </ButtonGroup>
                <RadioListWeb
                  data-test-id="radio-status-list"
                  list={this.getAllowedStatusList()}
                  selected={taskData.status}
                  open={openStatus}
                  anchorRef={this.anchorRef}
                  handleClose={this.closeStatusModal}
                  handleSelect={this.handleSelectStatus}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className={classes.innerHeaderContainer}>
            <Box className={classes.contentWithIcon}>
              <Typography variant="h4" className={classes.inputLabel2}>
                {configJSON.taskTitle} {!isEditTitle && `- ${values.title}`}
              </Typography>
              {!this.checkDisabled() && <img src={EditIcon} data-test-id="toggle_title" alt="EditIcon" onClick={this.toggleTitle} />}
            </Box>
            {isEditTitle &&
              <Box className={`${classes.fieldWrapper} ${classes.editField}`}>
                <Grid alignItems="stretch" direction="row" spacing={3} container className="gridMargin">
                  <Grid sm={5} item>
                    <TextField
                      variant="outlined"
                      type="text"
                      name={configJSON.taskTitleName}
                      placeholder={configJSON.taskPlaceholder}
                      onChange={handleChange}
                      value={values.title}
                      fullWidth
                      className={`${classes.inputField} textField`}
                      error={handleInvoiceErrors(
                        errors.title,
                        touched.title
                      )}
                      onBlur={handleBlur}
                      helperText={handleInvoiceHelperText(
                        errors.title,
                        touched.title
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>}
            <Typography variant="body1" className={`${classes.taskDetailsText} ticketDetailFont`}>
              {configJSON.assignedDate} : <Typography component="span" className="ticketOpacity">{moment(taskData.issue_date).format("DD/MM/YYYY")}</Typography>
            </Typography>
          </Box>
          <Box>
            <Box className={classes.contentWithIcon}>
              <Typography variant="h4" className={`${classes.inputLabel2} textSize`}>
                {configJSON.dueDate} : <Typography component="span" className="ticketOpacity">{moment(values.due_date).format("DD/MM/YYYY")}</Typography>
              </Typography>
              {!this.checkDisabled() && <img src={CalenderIcon} alt="CalenderIcon" onClick={this.toggleDueDate} />}
            </Box>
            {isEditDueDate &&
              <Box className={`${classes.fieldWrapper} ${classes.editField}`}>
                <Grid alignItems="stretch" direction="row" spacing={3} container>
                  <Grid sm={5} item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        variant="inline"
                        className={`${classes.inputField} ${classes.dateField}`}
                        margin="normal"
                        InputProps={{
                          endAdornment: <DateRange />,
                          disableUnderline: true,
                        }}
                        fullWidth
                        name={configJSON.taskDueDateName}
                        autoOk
                        format="MM/dd/yyyy"
                        value={values.due_date}
                        onChange={setFieldValue.bind(null, configJSON.taskDueDateName)}
                        allowKeyboardControl={false}
                        onBlur={handleBlur}
                        placeholder={configJSON.taskDueDatePlaceholder}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <FormHelperText className="error-textioii">
                  {touched.due_date && errors.due_date}
                </FormHelperText>
              </Box>
            }
          </Box>
        </Box>
        <Box className={classes.fieldWrapper}>
          <InputLabel className={`${classes.inputLabel2} ${classes.contentWithIcon}`}>
            {configJSON.taskDescription} {!this.checkDisabled() && <img src={EditIcon} alt="EditIcon" onClick={this.toggleDescription} />}
          </InputLabel>
          {isEditDescription ?
            <TextField
              type="text"
              variant="outlined"
              name={configJSON.taskDescriptionName}
              placeholder={configJSON.taskPlaceholder}
              onChange={handleChange}
              value={values.description}
              fullWidth
              className={classes.inputField}
              error={handleInvoiceErrors(
                errors.description,
                touched.description
              )}
              onBlur={handleBlur}
              helperText={handleInvoiceHelperText(
                errors.description,
                touched.description
              )}
            /> :
            <Typography variant="body1" className={classes.commentsDetails}>
              {values.description}
            </Typography>
          }
        </Box>
      </>
    )
    const taskFildesContent = (
      { values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue}:RenderFormProps
    ) => (
      <Grid alignItems="stretch" direction="row" container spacing={3} >
        <Grid sm={5} item>
          <Box className={classes.fieldWrapper}>
            <InputLabel required className={classes.inputLabel} >
              {configJSON.taskBranchLabel}
            </InputLabel>
            <DropdownSearch
              value={values.branch_id}
              options={branches}
              onBlur={handleBlur}
              onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "branch_id", { department_id: "", assignee_ids: [] })}
              id={configJSON.taskBranchName}
              isDisabled={this.checkDisabledWithProcedure()}
              isSearchable={true}
              noOptionsMessage={configJSON.taskNoBranchMessage}
              placeholder={configJSON.taskBranchPlaceholder}
              errorText={handleInvoiceHelperText(
                errors.branch_id,
                touched.branch_id
              )}
              showError={handleInvoiceErrors(
                errors.branch_id,
                touched.branch_id
              )}
            />
          </Box>
          <Box className={classes.fieldWrapper}>
            <InputLabel required className={classes.inputLabel}>
              {configJSON.taskDepartmentLabel}
            </InputLabel>
            <DropdownSearch
              value={values.department_id}
              options={departments}
              onBlur={handleBlur}
              onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "department_id", { assignee_ids: [] })}
              isDisabled={this.checkDisabledWithProcedure() || !values.branch_id}
              id={configJSON.taskDepartmentName}
              isSearchable={true}
              noOptionsMessage={configJSON.taskNoDepartmentMessage}
              placeholder={configJSON.taskDepartmentPlaceholder}
              errorText={handleInvoiceHelperText(
                errors.department_id,
                touched.department_id
              )}
              showError={handleInvoiceErrors(
                errors.department_id,
                touched.department_id
              )}
            />
          </Box>
          <Box className={classes.fieldWrapper}>
            <InputLabel required className={classes.inputLabel}>
              {configJSON.taskPriorityLabel}
            </InputLabel>
            <DropdownSearch
              value={values.priority}
              options={priorites}
              onBlur={handleBlur}
              onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "priority")}
              id={configJSON.taskPriorityName}
              noOptionsMessage={configJSON.taskNoPriorityMessage}
              placeholder={configJSON.taskPriorityPlaceholder}
              isDisabled={this.checkDisabled()}
            />
          </Box>
          {(!this.checkCreator()) ?
            <Box className={classes.fieldWrapper}>
              <InputLabel required className={classes.inputLabel}>
                {configJSON.taskAssignedByLabel}
              </InputLabel>
              <TextField
                variant="outlined"
                type="text"
                disabled
                value={this.state.taskData.creatorData?.email}
                fullWidth
                className={classes.inputField}
              />
            </Box> :
            <Box className={classes.fieldWrapper}>
              <InputLabel required className={classes.inputLabel}>
                {this.checkCreator() ? configJSON.taskEmployeeLabel : configJSON.taskAssignedByLabel}
              </InputLabel>
              <DropdownSearch
                value={values.assignee_ids}
                options={(this.checkTemplates() && this.state.taskData.assignee_idsData && employees.length > 0) ? [...employees, { label: this.state.taskData.assignee_idsData.email, value: Number(this.state.taskData.assignee_idsData.id) }] : employees}
                onBlur={handleBlur}
                onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "assignee_ids")}
                id={configJSON.taskEmployeeName}
                isDisabled={this.checkDisabled() || !values.department_id || this.checkTemplates()}
                isSearchable={true}
                noOptionsMessage={configJSON.taskNoEmployeeMessage}
                placeholder={configJSON.taskEmployeePlaceholder}
                showError={handleInvoiceErrors(
                  errors.assignee_ids,
                  touched.assignee_ids
                )}
                errorText={handleInvoiceHelperText(
                  errors.assignee_ids,
                  touched.assignee_ids
                )}
              />
            </Box>
          }
        </Grid>
      </Grid>
    )
    return (
      <>
        <Loader loading={loading} />
        <Wrapper navigation={this.props.navigation} divider backbtn={true} title={this.taskHeaderTitle()} bellIcn={true} homeIcn={true} child={
          <Box>
            <Formik
              enableReinitialize={true}
              validateOnMount={true}
              validateOnBlur={true}
              initialValues={taskData}
              validationSchema={CreateTaskSchema}
              onSubmit={this.editTask}
            >
              {({ errors, touched, handleBlur, handleChange, setFieldValue, handleReset, handleSubmit, values }) => (
                <form className={classes.formContainer} onSubmit={handleSubmit}>
                  {
                    taskHeaderContent(
                      {values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue,}
                    )
                  }
                  {
                    taskFildesContent(
                      {values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue}
                    )
                  }

                  <Grid alignItems="stretch" direction="row" container spacing={3}>
                    {!taskData.procedure_template && <>
                      <Grid sm={5} item>
                            <Box className={`${classes.fieldWrapper} ${classes.fileInput}`}>
                            <Box className={classes.fileUploadInput}>
                            <AttachFileInput
                                attachmentTitle={configJSON.attachmentTitle}
                                templateAction={isEditTask ? "" :"View"}
                                attachmentId="attachment"
                                placeholderText={configJSON.attachmentPlaceholder}
                                accept={configJSON.acceptImageType}
                                attachment={attachment}
                                setFieldValue={setFieldValue}
                                isViewOnly={() => this.checkDisabled()}
                                handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(event, setFieldValue,"attachment","attachError") }}
                                clearAttachment={() => this.handleClearFile(setFieldValue,"attachment","attachError")}
                                attachmentError={attachError}
                            />
                            {attachment?.url === null && !attachError && <Typography className={classes.maxSizeTicket} component="span">{configJSON.fileSizeText}</Typography>} 
                        </Box>
                          </Box>
                      </Grid>
                      <Grid item sm={7}></Grid>
                    </>}
                    {taskData.procedure_template &&
                      <Grid item sm={5} className={classes.procedureContainer}>
                        <Typography className={classes.attachment} data-test-id="view_template" target="_blank" component="a" onClick={this.navigateToProcedure}>View Template</Typography>
                        <Box className={classes.procedureImgContainer}>
                          <img data-test-id="template-img" src={taskData.procedure_template?.template_image.url} alt={taskData.procedure_template?.template_image.name} />
                        </Box>
                        <Box className={classes.approvalContainer}>
                          <Typography className={`${classes.inputLabel2} approvalText`} variant="h4">
                            {configJSON.approvalTxt}
                          </Typography>
                          <Switch
                            name="active"
                            color="primary"
                            disabled={taskData.status === "completed"}
                            checked={values.active}
                            onChange={handleChange}
                          />
                        </Box>
                      </Grid>
                    }

                    {taskData.status !== "completed" &&
                      <Grid item sm={12}>
                        <Box className={classes.fieldWrapper}>
                          <InputLabel className={classes.inputLabel2}>
                            {configJSON.taskCommentLabel}
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            name={configJSON.taskCommentName}
                            onChange={handleChange}
                            value={values.comment}
                            placeholder={configJSON.taskCommentPlaceholder}
                            fullWidth
                            className={`${classes.inputField} ${classes.textAreaField} ${classes.fieldHeight}`}
                            multiline
                            InputProps={{
                              endAdornment: (
                                <div className={classes.textFiedContainer}>
                                  <div>
                                    <input
                                      accept="image/*"
                                      data-test-id="comment_docs"
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(event, setFieldValue,"document","apiError") }}
                                      id={configJSON.taskDocumentName}
                                      type="file"
                                    />
                                    <label htmlFor={configJSON.taskDocumentName}>
                                      <img className="attachIcon" src={attachIcon} alt="attachIcon" />
                                    </label>
                                  </div>
                                  <img data-test-id="add_comment" onClick={this.addComment.bind(this, values, { resetForm: handleReset })} id="yellow_icon" src={YellowsendIcon} alt="YellowsendIcon" />
                                </div>
                              ),
                            }}
                          />
                          {apiError && <Box className={classes.helperText}>{apiError}</Box>}
                          {document &&
                            <>
                              <Box className={`attachment ${classes.helperText} attachment`}>
                                <Typography data-test-id="doc_name" component="span" onClick={this.openImageInNewTab}>{document.name}</Typography>
                                <img id="close-icon" className="close-icon" src={closeIcon} alt="Close" onClick={this.handleClearFile.bind(null, setFieldValue, "document", "apiError")} />
                              </Box>
                            </>
                          }
                        </Box>
                      </Grid>
                    }
                  </Grid>
                  {taskData.status === "completed" &&
                    <>
                      {!taskData.procedure_template && <Box className={`${classes.fieldWrapper} ${classes.ratingMargin}`}>
                        <InputLabel className={classes.inputLabel2}>
                          {configJSON.taskRatingLabel}
                        </InputLabel>
                        <Rating
                          data-test-id="ratings"
                          name={configJSON.taskRatingName}
                          size="large"
                          onChange={(event, newValue) => {
                            this.handleRetingChange(newValue, setFieldValue);
                          }}
                          value={values.rating}
                          emptyIcon={<StarOutline fontSize="inherit" />}
                          disabled={!this.checkCreator()}
                        />
                      </Box>}
                      <Box className={`${classes.fieldWrapper} ${classes.feedbackWrapper}`}>
                        <InputLabel className={classes.inputLabel2}>
                          {configJSON.taskFeedbackLabel}
                        </InputLabel>
                        <TextField
                          type="text"
                          variant="outlined"
                          name={configJSON.taskFeedbackName}
                          onChange={handleChange}
                          value={values.feedback}
                          placeholder={configJSON.taskCommentPlaceholder}
                          multiline
                          fullWidth
                          className={`${classes.inputField} ${classes.textAreaField} ${classes.fieldHeight}`}
                          disabled={!this.checkCreator()}
                        />
                      </Box>
                    </>
                  }
                  {comments && comments.length > 0 &&
                    (<div className={classes.commentsContainer}>
                      <Typography variant="h4" className={classes.inputLabel2}>
                        {configJSON.taskAllComments}
                      </Typography>
                      <ul className="comment-ul">
                        {comments?.slice(0, 2).map((data) => (
                          <li key={data.id}>
                            <Typography variant="body1" className={`${classes.commentsDetails} comment`}>
                              {data.comment} {data.document?.url && <Box>- <Typography component="a" className={`attachment`} href={data.document.url} target="_blank">{configJSON.viewAttachmentText}</Typography></Box>}
                            </Typography>
                            <Box className={classes.commentAndIcon}>
                              <Typography variant="body1" className={classes.commentsDetails}>
                                <Typography component="span" className="fontFamily">{moment(data.created_at).format("DD/MM/YYYY")}</Typography> {data.updated_at && "(Edited)"} - <Typography component="span" className="textOpacity">{data.owner_name}</Typography>
                              </Typography>
                              {Number(loggedUserId) === data.owner_id && <Box>
                                <img data-test-id="edit_comment" onClick={this.handleOpenEdit.bind(null, data)} src={EditIcon} alt="EditIcon" />
                                <img data-test-id="delete_comment" onClick={this.handleOpenDelete.bind(null, data)} src={DeleteIcon} alt="DeleteIcon" />
                              </Box>}
                            </Box>
                          </li>
                        ))}
                      </ul>
                      {comments.length > 2 && <Box className={classes.boxAlign}><Typography component="span" className="attachment" onClick={this.handleOpenComments}>{configJSON.taskViewAllComments}</Typography></Box>}
                    </div>)
                  }
                  <Box className={classes.editTaskBtnWrapper}>
                    <Button
                      data-test-id="cancel-btn"
                      variant="outlined"
                      className={classes.secondaryBtn}
                      onClick={this.handleDisableEdit}
                    >
                      {configJSON.cancel}
                    </Button>
                    <Button
                      data-test-id="edit-btn"
                      variant="contained"
                      className={classes.primaryBtn}
                      type="submit"
                    >
                      {configJSON.save}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        } />
        <SuccessModal
          title={`${configJSON.taskID} - ${taskData.task_id}`}
          message={configJSON.taskSuccessMessage}
          open={openSuccess}
          onClose={this.handleCloseSuccess}
          closeIcon={true}
          handleClick={this.navigateToTaskList}
          buttonText={configJSON.okBtnText}
        />
        <TicketCommentsModal
          open={openCommentsList}
          handleClose={this.handleCloseComments}
          handleEdit={this.handleOpenEdit}
          handleDelete={this.handleOpenDelete}
          list={comments}
          title={configJSON.taskCommentLabel}
          userId={userId}
        />
        <EditCommentModal
          open={openEdtiComment}
          handleClose={this.handleCloseEdit}
          editComment={this.handleEditComment}
          comment={commentId}
        />
        <ConfirmationModal
          open={opeDeleteComment}
          onClose={this.handleCloseDelete}
          confirmDelete={this.handleDeleteComment}
          title={configJSON.taskCommentName}
          from={configJSON.taskFromComment}
        />
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export const styles = {
  ...commonInputField,
  ...commonButton,
  innerHeaderContainer: {
    "& .MuiFormHelperText-root": {
      marginBottom: "0px !important",
    },
    marginBottom: "21px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  innerBtnContainer: {
    display: "flex",
    flexWrap: "wrap" as const,
    justifyContent: "end",
    gap: "15px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    flexWrap: "wrap" as const,
    gap: "30px",
  },
  primaryBtn2: {
    width: "auto",
    minWidth: "125px",
    margin: "0px",
    textTransform: "none" as const,
    height: "35px",
    fontWeight: 300,
    color: "#FFF !important",
  },
  secondaryBtn2: {
    width: "125px",
    height: "35px",
    margin: "0",
    fontWeight: 600,
  },
  btnGroup: {
    borderRadius: "10px",
    boxShadow: "none",
    "& .submit_for_review": {
      background: "transparent",
      color: "#03615C !important",
      border: "2px solid #03615C"
    },
    "& .completed, .mark_as_complete": {
      background: "#3E9D98"
    },
    "& .pending, .mark_as_incomplete": {
      background: "#E59430"
    },
  },
  formContainer: {
    marginTop: "10px",
    "& .gridMargin":{
      margin:0
    }
  },
  editTaskBtnWrapper: {
    display: "flex",
    paddingBottom: "20px",
    marginTop: "50px",
    justifyContent: "center",
  },
  inputLabel2: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    wordBreak: "break-all" as const,
    color: colors.primaryColor2,
    "&.approvalText": {
      fontWeight: 500,
    }
  },
  taskDetailsText: {
    lineHeight: "24px",
    fontSize: "18px",
    fontWeight: 500,
    color: colors.text,
    "&.ticketDetailFont":{
      fontSize: "16px", 
      fontWeight: 600
    },
    "& .ticketOpacity":{
      opacity:"0.6",
      fontFamily: "Bitter-VariableFont", 
      fontWeight: 500
    }
  },
  commentsDetails: {
    lineHeight: "21px",
    fontSize: "16px",
    fontWeight: 300,
    color: colors.text,
    "& .textOpacity":{
      opacity:"0.6"
    },
    "& .fontFamily":{
      fontFamily: "Bitter-VariableFont" 
    },
    "&.comment":{
      display:"flex"
    }
  },
  commentsContainer: {
    marginTop: "40px",
    "& .comment-ul": {
      margin: "0px",
      "& li::marker": {
        fontSize: "20px",
        color: colors.secondaryColor1,
      }
    },
    "& .attachment": {
      cursor: "pointer",
      color: "#4196F0",
      borderBottom: "1px solid #4196F0",
      textDecoration: "none",
      fontSize: "14px !important"
    }
  },
  attachment: {
    cursor: "pointer",
    color: "#4196F0",
    borderBottom: "1px solid #4196F0",
    textDecoration: "none",
    fontSize: "14px !important"
  },
  textFiedContainer: {
    display: "flex",
    gap: "21px",
    alignItems: "center",
    "& .attachIcon": {
      width: "25px",
      height: "24px",
    },
    "& img": {
      cursor: "pointer",
    },
    "& input":{
      display:"none"
    },
  },
  contentWithIcon: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    "& .textSize":{
      fontSize:18
    },
    "& img": {
      cursor: "pointer",
      width: "20px",
      height: "20px",
    }
  },
  commentAndIcon: {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    "& img": {
      cursor: "pointer",
    },
    "& img:nth-child(2)": {
      marginLeft: "10px",
    },
  },
  procedureImgContainer: {
    marginTop: "20px",
    "& img": {
      width: "100%",
      boxShadow: "0px 1.276839256286621px 28.09046173095703px rgba(0, 0, 0, 0.26)",
    }
  },
  approvalContainer: {
    paddingTop: "25px",
    display: "flex",
    alignItems: "center",
    gap: "29px",
    "& .MuiSwitch-root": {
      "& .MuiSwitch-switchBase.Mui-disabled": {
        color: colors.secondaryColor1
      },
      "& .MuiSwitch-track": {
        backgroundColor: colors.secondaryColor1
      },
    }
  },
  procedureContainer: {
    marginBottom: "-30px"
  },
  maxSizeTicket: {
    fontSize: 12,
    display: "block",
    color: "#020202",
    opacity: 0.6,
    position: "absolute" as const,
    right: 0
  },
  fileUploadInput:{
    "& .containerWidth":{
      maxWidth:"unset !important"
    }
  },
  editField:{
    margin: 0, 
    marginBottom: "10px",
    "& .gridMargin":{
      margin:0
    },
    "& .textField":{
      marginTop:0
    }
  },
  fieldHeight:{
    height:80
  },
  feedbackWrapper:{
    marginTop:"50px"
  },
  boxAlign:{
    textAlign: "end" as const
  },
  imageMargin:{
    marginRight:10
  },
  ratingMargin:{
    marginBottom:"-25px"
  }
};

export default withStyles(styles)(EditTask);
// Customizable Area End

