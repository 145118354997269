import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, IconButton } from '@material-ui/core';
import { styles } from "./ModalStyles.web";
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  classes?: any;
  open: boolean;
  title?: string;
  description?: React.ReactNode;
  cancelText?: string;
  handleClose?: () => void;
  closeIcn?: boolean;
  handleCloseModal?: () => void;
}

class InfoModal extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { open, classes, title, description, cancelText, handleClose, closeIcn, handleCloseModal } = this.props;

    return (
      <div className={classes?.modalWrapper}>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes?.customModal}
        >
          <DialogTitle id="alert-dialog-title">
            <Box display={"flex"} alignItems={"center"} justifyContent={title ? "space-between" : "end"}>
              {title}
              {closeIcn && <IconButton data-test-id="close_icon" onClick={handleCloseModal ?? handleClose}><CloseIcon style={{ color: "#020202" }} /></IconButton>}
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
          {cancelText &&
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                className="primary-btn"
                data-test-id="close_btn"
              >
                {cancelText ?? "Cancel"}
              </Button>
            </DialogActions>}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(InfoModal);
