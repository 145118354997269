/** @format */

import React from "react";
// Customizable Area Start
import { commonInputField, commonButton, helperText, commonTemplateCss, singleValueCss } from "../../../components/src/utils/commonInputStyle";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, TextField, Button, Grid, Switch, FormHelperText } from "@material-ui/core";
import Template4Controller, { Props, configJSON } from "./Template4Controller.web"
import { Formik, Form } from 'formik';
import { Wrapper } from "../../../components/src/Wrapper";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import DropdownSearch from "../../../components/src/commonComponents/DropdownSearch.web";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import AttachFileInput from "../../../components/src/commonComponents/AttachFileInput.web";
// Customizable Area End

// Customizable Area Start

export const styles = () => createStyles({
  ...commonButton,
  ...commonInputField,
  ...commonTemplateCss

});
// Customizable Area End

export class Template4 extends Template4Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { classes } = this.props;
    const { attachment, formData, loading, attachmentError, branch_data, department_data, templateAction, isDownload, imageUrls, openMenu, opeDeleteModal } = this.state;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper navigation={this.props.navigation} backbtn={true} divider title={this.headerTitle()} bellIcn={true} homeIcn={true} child={
          <Box>
            <Formik
              initialValues={this.template4InitialValues()}
              enableReinitialize={true}
              validateOnBlur={true}
              onSubmit={this.addEditTemplate4}
              validationSchema={this.template4ValidationSchema()}
            >
              {({ errors, touched, handleBlur, handleChange, setFieldValue, values }) => (
                <Form translate className={`${classes.formContainer} ${isDownload && "textColor"}`}>
                  <Grid ref={this.pdfContentRef} direction="row" container className={classes.gridContainer} spacing={5}>
                    <Grid item className={classes.gridItem} xs={10} sm={5}>
                      {this.isViewOnly() ?
                        <Typography data-test-id="title" variant="h4" className={classes.inputLabel2} style={{ fontWeight: 700 }}>
                          {values.title}
                        </Typography> :
                        <Box className={classes.fieldWrapper} style={{ margin: "0" }}>
                          <InputLabel className={classes.inputLabel2} required style={{ fontSize: "16px" }}>
                            {configJSON.templateTitleLabel}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            data-test-id="title_input_field"
                            type="text"
                            name={configJSON.templateTitleName}
                            onChange={handleChange}
                            value={values.title}
                            className={classes.inputField}
                            fullWidth
                            helperText={handleInvoiceHelperText(
                              errors.title,
                              touched.title
                            )}
                            onBlur={handleBlur}
                            error={handleInvoiceErrors(
                              errors.title,
                              touched.title
                            )}
                          />
                        </Box>
                      }
                    </Grid>
                    <Grid item className={`${classes.gridItem} actionBtn ${isDownload && "visibility"}`} xs={2} sm={7}>
                      {templateAction !== configJSON.create &&
                        <ActionButton
                          edit={this.allowEdit()}
                          mark_as_favourite
                          favourite={formData.favorite}
                          handleFavourite={this.handleFavouriteClick}
                          download
                          deleteVisible={this.allowEdit()}
                          duplicate={this.allowEdit()}
                          handleEdit={() => this.handleActions(configJSON.edit)}
                          handleDelete={this.handleDelete}
                          isOpen={openMenu}
                          toggleDropdown={this.toggleMenu}
                          handleDuplicate={this.handleDuplicateTemplate}
                          handleDownload={this.handleDownloadPdf}
                        />
                      }

                    </Grid>
                    <Grid item className={classes.gridItem} xs={12}>
                      <div className={classes.dropdownContainer}>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            value={values.branch_id}
                            singleValueCss={isDownload && singleValueCss}
                            options={branch_data}
                            id={configJSON.templateBranchName}
                            isSearchable={true}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                            isDisabled={this.isViewOnly()}
                            placeholder={configJSON.templateBranchPlaceholder}
                            isMyProfile={true}
                            noOptionsMessage={configJSON.templateNoBranchMessage}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                            )}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            options={department_data}
                            value={values.department_id}
                            singleValueCss={isDownload && singleValueCss}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                            id={configJSON.templateDepartmentName}
                            isMyProfile={true}
                            isSearchable={true}
                            placeholder={configJSON.templateDepartmentPlaceholder}
                            noOptionsMessage={configJSON.templateNoDepartmentMessage}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id
                            )}
                            errorText={handleInvoiceHelperText(
                              errors.department_id,
                              touched.department_id
                            )}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid id="container1" item className={`${classes.gridItem} ${this.isTemplate6() && 'gridItem6'}`} xs={12} sm={6} md={this.mdItemsWidth()} lg={this.lgItemsWidth()}>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <Box className={`${classes.uploadImageContainer} templateHeight4 ${this.isTemplate6() && 'templateHeight6'}`}>
                          <ImageUploadView
                            setFieldValue={setFieldValue}
                            handleImageChange={this.handleImageChange}
                            name={configJSON.image1}
                            uploadTexts={configJSON.uploadImageText}
                            currentImage={imageUrls[configJSON.image1]?.url}
                            isDisabled={this.isViewOnly()}
                          />
                        </Box>
                        {handleInvoiceErrors(
                          errors.image1,
                          touched.image1
                        ) && (
                            <FormHelperText
                              style={helperText}
                            >
                              {handleInvoiceHelperText(
                                errors.image1,
                                touched.image1
                              )}
                            </FormHelperText>
                          )}
                      </Box>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.description1}
                          name={configJSON.description1}
                          fullWidth
                          onChange={handleChange}
                          className={classes.inputField}
                          disabled={this.isViewOnly()}
                          helperText={handleInvoiceHelperText(
                            errors.description1,
                            touched.description1
                          )}
                          error={handleInvoiceErrors(
                            errors.description1,
                            touched.description1
                          )}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                    <Grid id="container2" item className={`${classes.gridItem} ${this.isTemplate6() && 'gridItem6'}`} xs={12} sm={6} md={this.mdItemsWidth()} lg={this.lgItemsWidth()}>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <Box className={`${classes.uploadImageContainer} templateHeight4 ${this.isTemplate6() && 'templateHeight6'}`}>
                          <ImageUploadView
                            handleImageChange={this.handleImageChange}
                            setFieldValue={setFieldValue}
                            name={configJSON.image2}
                            currentImage={imageUrls[configJSON.image2]?.url}
                            uploadTexts={configJSON.uploadImageText}
                            isDisabled={this.isViewOnly()}
                          />
                        </Box>
                        {handleInvoiceErrors(
                          errors.image2,
                          touched.image2
                        ) && (
                            <FormHelperText
                              style={helperText}
                            >
                              {handleInvoiceHelperText(
                                errors.image2,
                                touched.image2
                              )}
                            </FormHelperText>
                          )}
                      </Box>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.description2}
                          name={configJSON.description2}
                          fullWidth
                          onChange={handleChange}
                          className={classes.inputField}
                          disabled={this.isViewOnly()}
                          helperText={handleInvoiceHelperText(
                            errors.description2,
                            touched.description2
                          )}
                          error={handleInvoiceErrors(
                            errors.description2,
                            touched.description2
                          )}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                    <Grid id="container3" item className={`${classes.gridItem} ${this.isTemplate6() && 'gridItem6'}`} xs={12} sm={6} md={this.mdItemsWidth()} lg={this.lgItemsWidth()}>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <Box className={`${classes.uploadImageContainer} templateHeight4 ${this.isTemplate6() && 'templateHeight6'}`}>
                          <ImageUploadView
                            handleImageChange={this.handleImageChange}
                            setFieldValue={setFieldValue}
                            name={configJSON.image3}
                            currentImage={imageUrls[configJSON.image3]?.url}
                            uploadTexts={configJSON.uploadImageText}
                            isDisabled={this.isViewOnly()}
                          />
                        </Box>
                        {handleInvoiceErrors(
                          errors.image3,
                          touched.image3
                        ) && (
                            <FormHelperText
                              style={helperText}
                            >
                              {handleInvoiceHelperText(
                                errors.image3,
                                touched.image3
                              )}
                            </FormHelperText>
                          )}
                      </Box>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.description3}
                          name={configJSON.description3}
                          fullWidth
                          onChange={handleChange}
                          className={classes.inputField}
                          disabled={this.isViewOnly()}
                          helperText={handleInvoiceHelperText(
                            errors.description3,
                            touched.description3
                          )}
                          error={handleInvoiceErrors(
                            errors.description3,
                            touched.description3
                          )}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                    <Grid id="container4" item className={`${classes.gridItem} ${this.isTemplate6() && 'gridItem6'}`} xs={12} sm={6} md={this.mdItemsWidth()} lg={this.lgItemsWidth()}>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <Box className={`${classes.uploadImageContainer} templateHeight4 ${this.isTemplate6() && 'templateHeight6'}`}>
                          <ImageUploadView
                            handleImageChange={this.handleImageChange}
                            setFieldValue={setFieldValue}
                            name={configJSON.image4}
                            currentImage={imageUrls[configJSON.image4]?.url}
                            uploadTexts={configJSON.uploadImageText}
                            isDisabled={this.isViewOnly()}
                          />
                        </Box>
                        {handleInvoiceErrors(
                          errors.image4,
                          touched.image4
                        ) && (
                            <FormHelperText
                              style={helperText}
                            >
                              {handleInvoiceHelperText(
                                errors.image4,
                                touched.image4
                              )}
                            </FormHelperText>
                          )}
                      </Box>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.description4}
                          name={configJSON.description4}
                          fullWidth
                          onChange={handleChange}
                          className={classes.inputField}
                          disabled={this.isViewOnly()}
                          helperText={handleInvoiceHelperText(
                            errors.description4,
                            touched.description4
                          )}
                          error={handleInvoiceErrors(
                            errors.description4,
                            touched.description4
                          )}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                    <Grid id="container5" item className={`${classes.gridItem} ${this.isTemplate6() && 'gridItem6'}`} xs={12} sm={6} md={this.mdItemsWidth()} lg={this.lgItemsWidth()}>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <Box className={`${classes.uploadImageContainer} templateHeight4 ${this.isTemplate6() && 'templateHeight6'}`}>
                          <ImageUploadView
                            setFieldValue={setFieldValue}
                            handleImageChange={this.handleImageChange}
                            name={configJSON.image5}
                            uploadTexts={configJSON.uploadImageText}
                            currentImage={imageUrls[configJSON.image5]?.url}
                            isDisabled={this.isViewOnly()}
                          />
                        </Box>
                        {handleInvoiceErrors(
                          errors.image5,
                          touched.image5
                        ) && (
                            <FormHelperText
                              style={helperText}
                            >
                              {handleInvoiceHelperText(
                                errors.image5,
                                touched.image5
                              )}
                            </FormHelperText>
                          )}
                      </Box>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.description5}
                          name={configJSON.description5}
                          fullWidth
                          onChange={handleChange}
                          className={classes.inputField}
                          disabled={this.isViewOnly()}
                          helperText={handleInvoiceHelperText(
                            errors.description5,
                            touched.description5
                          )}
                          error={handleInvoiceErrors(
                            errors.description5,
                            touched.description5
                          )}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                    <Grid id="container6" item className={`${classes.gridItem} ${this.isTemplate6() && 'gridItem6'}`} xs={12} sm={6} md={this.mdItemsWidth()} lg={this.lgItemsWidth()}>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <Box className={`${classes.uploadImageContainer} templateHeight4 ${this.isTemplate6() && 'templateHeight6'}`}>
                          <ImageUploadView
                            handleImageChange={this.handleImageChange}
                            setFieldValue={setFieldValue}
                            name={configJSON.image6}
                            currentImage={imageUrls[configJSON.image6]?.url}
                            uploadTexts={configJSON.uploadImageText}
                            isDisabled={this.isViewOnly()}
                          />
                        </Box>
                        {handleInvoiceErrors(
                          errors.image6,
                          touched.image6
                        ) && (
                            <FormHelperText
                              style={helperText}
                            >
                              {handleInvoiceHelperText(
                                errors.image6,
                                touched.image6
                              )}
                            </FormHelperText>
                          )}
                      </Box>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.description6}
                          name={configJSON.description6}
                          fullWidth
                          onChange={handleChange}
                          className={classes.inputField}
                          disabled={this.isViewOnly()}
                          helperText={handleInvoiceHelperText(
                            errors.description6,
                            touched.description6
                          )}
                          error={handleInvoiceErrors(
                            errors.description6,
                            touched.description6
                          )}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                    <Grid id="container7" item className={`${classes.gridItem} ${this.isTemplate6() && 'gridItem6'}`} xs={12} sm={6} md={this.mdItemsWidth()} lg={this.lgItemsWidth()}>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <Box className={`${classes.uploadImageContainer} templateHeight4 ${this.isTemplate6() && 'templateHeight6'}`}>
                          <ImageUploadView
                            handleImageChange={this.handleImageChange}
                            setFieldValue={setFieldValue}
                            name={configJSON.image7}
                            currentImage={imageUrls[configJSON.image7]?.url}
                            uploadTexts={configJSON.uploadImageText}
                            isDisabled={this.isViewOnly()}
                          />
                        </Box>
                        {handleInvoiceErrors(
                          errors.image7,
                          touched.image7
                        ) && (
                            <FormHelperText
                              style={helperText}
                            >
                              {handleInvoiceHelperText(
                                errors.image7,
                                touched.image7
                              )}
                            </FormHelperText>
                          )}
                      </Box>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.description7}
                          name={configJSON.description7}
                          fullWidth
                          onChange={handleChange}
                          className={classes.inputField}
                          disabled={this.isViewOnly()}
                          helperText={handleInvoiceHelperText(
                            errors.description7,
                            touched.description7
                          )}
                          error={handleInvoiceErrors(
                            errors.description7,
                            touched.description7
                          )}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                    <Grid id="container8" item className={`${classes.gridItem} ${this.isTemplate6() && 'gridItem6'}`} xs={12} sm={6} md={this.mdItemsWidth()} lg={this.lgItemsWidth()}>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <Box className={`${classes.uploadImageContainer} templateHeight4 ${this.isTemplate6() && 'templateHeight6'}`}>
                          <ImageUploadView
                            handleImageChange={this.handleImageChange}
                            setFieldValue={setFieldValue}
                            name={configJSON.image8}
                            currentImage={imageUrls[configJSON.image8]?.url}
                            uploadTexts={configJSON.uploadImageText}
                            isDisabled={this.isViewOnly()}
                          />
                        </Box>
                        {handleInvoiceErrors(
                          errors.image8,
                          touched.image8
                        ) && (
                            <FormHelperText
                              style={helperText}
                            >
                              {handleInvoiceHelperText(
                                errors.image8,
                                touched.image8
                              )}
                            </FormHelperText>
                          )}
                      </Box>
                      <Box className={`${classes.fieldWrapper} ${this.isTemplate6() && classes.fieldWrapper6}`}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.description8}
                          name={configJSON.description8}
                          fullWidth
                          onChange={handleChange}
                          className={classes.inputField}
                          disabled={this.isViewOnly()}
                          helperText={handleInvoiceHelperText(
                            errors.description8,
                            touched.description8
                          )}
                          error={handleInvoiceErrors(
                            errors.description8,
                            touched.description8
                          )}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={classes.templateFooterContainer}>
                    <Box className={classes.approvalContainer}>
                      <Typography
                        style={{ fontSize: "20px" }}
                        variant="h4"
                        className={classes.inputLabel2}
                      >
                        {configJSON.sendForApproval}
                      </Typography>
                      <Switch
                        onChange={handleChange}
                        checked={values.send_for_approval}
                        color="primary"
                        disabled={this.state.templateAction !== configJSON.create}
                        name="send_for_approval"
                      />
                    </Box>
                    <AttachFileInput
                      attachmentError={attachmentError}
                      attachment={attachment}
                      handleFileUpload={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(e, setFieldValue) }}
                      templateAction={templateAction}
                      setFieldValue={setFieldValue}
                      clearAttachment={this.clearAttachment.bind(null, setFieldValue)}
                      isViewOnly={this.isViewOnly}
                    />
                  </Box>
                  <Box className={classes.templateBtnWrapper}>
                    {!this.isViewOnly() &&
                      <Button
                        variant="contained"
                        className={classes.primaryBtn}
                        type="submit"
                        data-test-id="create-btn"
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              onClose={this.handleDeleteClose}
              open={opeDeleteModal}
              title={configJSON.templateDeleteTitle}
              from={configJSON.procedureFrom}
              confirmDelete={this.handleDeleteTemplate}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(Template4);
// Customizable Area End