// Customizable Area Start
import React, { Component } from "react";
import Select, { StylesConfig, components } from "react-select";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { FormHelperText, FormControlLabel, Checkbox } from "@material-ui/core";
import { helperText } from "../utils/commonInputStyle";
import { colors } from "../utils/commonDesign";
import { selectAllOption } from "../utils/constant";

export interface OptionType {
  [x: string]: any;
  label: string;
  value: any;
  data?: any;
  isDisabled?: boolean;
}

interface SearchFilterProps {
  options: OptionType[];
  value: any;
  onChangeValue?: any;
  id?: string;
  showError?: boolean;
  errorText?: string;
  onBlur?: any;
  placeholder?: string;
  noOptionsMessage?: string;
  isSearchable?: boolean;
  isMulti?: any;
  isDisabled?: boolean;
  isMyProfile?: boolean
  singleValueCss?: any
}

interface SearchFilterState {
  isDropdownOpen: boolean;
}
interface MoreSelectedBadgeProps {
  items: string[];
}

class DropdownSearch extends Component<SearchFilterProps, SearchFilterState> {
  constructor(props: SearchFilterProps) {
    super(props);

    this.state = {
      isDropdownOpen: false,
    };
  }

  handleDropdownOpen = () => {
    this.setState({ isDropdownOpen: true });
  };

  handleDropdownClose = () => {
    this.setState({ isDropdownOpen: false });
  };

  render() {
    const { isDropdownOpen } = this.state;
    const { id, placeholder, isMyProfile, noOptionsMessage, value, onChangeValue, showError, errorText, onBlur, isSearchable, isMulti, isDisabled, singleValueCss } = this.props;
    let { options } = this.props;
    
    if (isMulti) {

      const selectAllIndex = options.findIndex(
        (option) => option.value === "select_all"
      );

      if (selectAllIndex === -1) {
        options = [selectAllOption, ...options];
      }
    }

    const customStyles: StylesConfig<OptionType, false> = {
      control: (provided) => ({
        ...provided,
        border: showError ? `1px solid ${colors.errorText}` : `1px solid ${colors.borderColor}`,
        borderRadius: "10px",
        boxShadow: "none",
        padding: isMyProfile ? "0 10px" : "10px",
        minHeight: isMyProfile ? "100%" : "56px",
        height: "auto",
        marginTop: "11px",
        cursor: "pointer",
        fontSize: "16px",
        lineHeight: "21px",
        "&:hover": {
          borderColor: "#969492",
        },
        backgroundColor: "#FFFFFF",
        color: (this.props.isDisabled) ? "rgba(0, 0, 0, 0.38)" : colors.text,
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),
      option: (provided, state) => ({
        ...provided,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        backgroundColor: (state.isSelected && !state.isDisabled) ? "#F1EEEA" : "transparent",
        color: state.isDisabled ? "hsl(0,0%,50%)" : colors.text,
        "&:hover": {
          backgroundColor: !state.isDisabled ? state.isSelected ? "#F1EEEA" : "#eaecef" : "transparent",
        },
      }),
      singleValue: (styles, { data }) => ({
        ...styles,
        color: (data.isDisabled || this.props.isDisabled) ? "rgba(0, 0, 0, 0.38)" : colors.text,
        ...(singleValueCss || {}),
      }),
      placeholder: (styles) => ({ ...styles, ...singleValueCss }),
      indicatorsContainer: (styles, state) => ({ ...styles, ...(state.isDisabled ? { display: "none" } : {}) }),
      menu: (provided) => ({
        ...provided,
        zIndex: 999,
      })
    };

    const CustomOption = ({ innerProps, label, isSelected, isMulti }: any) => (
      <div {...innerProps}>
        {isMulti && (
          <FormControlLabel
            control={<Checkbox checked={isSelected} name={label} />}
            label={label}
            style={{ paddingLeft: "15px" }}
          />
        )}
      </div>
    );
    const MoreSelectedBadge: React.FC<MoreSelectedBadgeProps> = ({ items }) => {
      const style: any = {
        background: "hsl(0,0%,90%)",
        color: "hsl(0,0%,20%)",
        borderRadius: '4px',
        fontSize: '14px',
        padding: '3px',
        order: 99,
      };

      const title = items.join(', ');
      const length = items.length;
      const label = `+ ${length} more${length !== 1 ? "" : ''} selected`;

      return (
        <div style={style} title={title}>
          {label}
        </div>
      );
    };

    const MultiValue = ({ index, getValue, children, ...props }: any) => {
      let maxToShow = getValue()[0].label === selectAllOption.label ? 3 : 2;
      const overflow = getValue()
        .slice(maxToShow)
        .map((x: { label: any; }) => x.label);

      if (index === 0 && children === selectAllOption.label) {
        return null;
      } else {
        return index < maxToShow ? (
          <components.MultiValue {...props} children={children} />
        ) : index === maxToShow ? (
          <MoreSelectedBadge items={overflow} />
        ) : null;
      }
    };
    let finalValue = value;
    if (value && typeof value === "object" && Array.isArray(value)) {
      finalValue = options.filter((item: OptionType) => value.includes(item.value))
    } else {
      finalValue = options.find((item: OptionType) => item.value === value) ?? value
    }

    const handleChange = (item: any, actionMeta: any) => {
      const { action, option } = actionMeta;
      
      if (action === "select-option" && (option.value === selectAllOption.value)) {
        onChangeValue(options)
      } else if (action === "select-option" && (this.props.options.length === item.length)) {
        onChangeValue([...item, selectAllOption])
      } else if (action === "select-option" && (this.props.options.length !== item.length)) {
        onChangeValue(item)
      } else if ((action === "deselect-option" && option.value === selectAllOption.value)) {
        onChangeValue([])
      } else if ((actionMeta.action === "deselect-option") && option.value !== selectAllOption.value) {
        onChangeValue(item.filter((data: { value: any; }) => data.value !== selectAllOption.value))
      } else if (actionMeta.action === "remove-value") {
        onChangeValue(item?.filter((data: { value: any; }) => data.value !== selectAllOption.value))
      } else {
        onChangeValue(item)
      }
    }
    return (
      <div>
        <Select
          options={options}
          placeholder={placeholder ?? "Search Timezone..."}
          value={finalValue}
          onChange={isMulti ? handleChange : onChangeValue}
          onMenuOpen={this.handleDropdownOpen}
          onMenuClose={this.handleDropdownClose}
          noOptionsMessage={() => noOptionsMessage ?? "No Timezone found"}
          components={{
            ...(!!isMulti ? {
              Option: (props) => (
                <CustomOption {...props} isMulti={isMulti} />
              )
            } : {}),
            DropdownIndicator: ({ selectProps }) => {
              return isDropdownOpen ? <KeyboardArrowUpIcon /> : < KeyboardArrowDownIcon />;
            },
            MultiValue
          }}
          styles={customStyles}
          onBlur={onBlur}
          inputId={id}
          isSearchable={isSearchable ? isSearchable : false}
          isMulti={isMulti ?? false}
          closeMenuOnSelect={isMulti ? false : true}
          hideSelectedOptions={false}
          isDisabled={isDisabled ?? false}
        />
        {(showError && errorText) && (
          <FormHelperText
            style={helperText}
          >
            {errorText}
          </FormHelperText>
        )}
      </div>
    );
  }
}

export default DropdownSearch;
// Customizable Area End