// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  Card,
  CardContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoginSignupBackgroundImgWeb from "../../../components/src/LoginSignupBackgroundImg.web";
import { landingBackImg } from "./assets";
import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController.web";
import Loader from "../../../components/src/Loader.web";

const logo = require("../../../components/src/qwipLogo.svg");
import { colors } from "../../../components/src/utils/commonDesign";
// Customizable Area End

// Customizable Area Start
export const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center" as const,
    height : "inherit",
  },
  card: {
    borderRadius: 16,
    position: "fixed" as const,
    maxWidth: "55rem",
    margin: "0 30px",
    backgroundColor : "#f1eeea",
    padding: "4rem"
  },
  cardContent: {
  },
  headerLogo: {
    margin: "3.5px 0 16px",
  },
  title: {
    color: colors.text,
    fontSize: "44px",
    lineHeight: "58px",
    fontWeight: 700,
    paddingTop: 11,
  },
  body1: {
    color: colors.text,
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
  },
  body2: {
    color: colors.text,
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
    paddingTop: 75,
  },
  btnWrapper : {
    display: "flex",
    justifyContent: "center" as const,
  },
  btn: {
    color: colors.text,
    marginTop: "113px",
    backgroundColor: colors.secondaryColor2,
    boxShadow: "none",
    textTransform: "capitalize" as const,
    height: "56px",
    fontWeight: 700,
    borderRadius: "10px",
    width: "352px",
    lineHeight: "21px",
    fontSize: "16px",
    "&:hover" : {
      backgroundColor: colors.secondaryColor2,
    }
  },
};
// Customizable Area End

export class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Loader loading={this.state.loading}/>
        <LoginSignupBackgroundImgWeb imagePath={landingBackImg} />
        <Container maxWidth="lg" className={classes.root}>
          <Card className={classes.card}>
            <CardContent >
              <Box className={classes.headerLogo}>
                <img src={logo} alt="qwipLogo" />
              </Box>
              <Typography
                variant="body1"
                component={"p"}
                className={classes.body1}
                data-test-id="header_title"
              >
                {configJSON.titleText}
              </Typography>
              <Typography
                variant="body2"
                component={"p"}
                className={classes.body2}
              >
                {configJSON.landingDescriptionText}
              </Typography>
              <Box className={classes.btnWrapper}>
                <Button
                  data-test-id={"btnLanding"}
                  variant="contained"
                  fullWidth
                  className={classes.btn}
                  onClick={this.handleUserRedirection}
                >
                  {configJSON.getStartedBtn}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </React.Fragment>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(LandingPage);
// Customizable Area End
