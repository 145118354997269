import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, FormHelperText, TextField, InputLabel } from "@material-ui/core";
import AddNewBranchController, { Props } from "./AddNewBranchController.web"
export const configJSON = require("./config");
import { Formik, Form } from 'formik';
import { AddNewBranchSchema } from "../../../components/src/utils/validationSchema"
import { Wrapper } from "../../../components/src/Wrapper";
import { colors } from "../../../components/src/utils/commonDesign"
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web"
import { allowHoursMinutes, allowNumberWithSpaceValidation, generateTimeValues, handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import { commonInputField , commonButton} from "../../../components/src/utils/commonInputStyle";
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import CustomAutoComplete from "../../../components/src/commonComponents/fields/CustomAutoComplete.web";
// Customizable Area End

// Customizable Area Start
export const styles = {
  ...commonInputField,
  ...commonButton,
  formContainer: {
    display: "flex",
    flexDirection: "column" as const,
    overflowY: "scroll" as const
  },
  mainContainer: {
    display: "flex",
  },
  editSection: {
    flexDirection: "column" as const
  },
  leftSection: {
    flexBasis: "48%"
  },
  astrik: {
    marginLeft: "-2px"
  },
  textareaFieldGroup: {
    marginBottom: 25,
    marginTop: 25
  },
  locationSearch: {
    display: "flex",
    alignItems: "center",
    border: "2px solid #969492",
    background: "#ffffff",
    borderRadius: 10,
    marginTop: 10,
    padding: 10,
    ["& svg"]: {
      color: "#969492",
      marginRight: 10,
      cursor: "pointer"
    },
    ["& div"]: {
      width: "100%"
    }
  },
  timeContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: 16
  },
  rightSection: {
    flexBasis: "48%",
    marginLeft: 30
  },
  imageUploadContainer: {
    border: "2px solid #969492",
    borderRadius: "10px",
    padding: "10px",
    marginTop: 28,
    paddingBottom: 10,
    width: "50%",
    height: "274px",
    "& img": {
      borderRadius: 16,
      objectFit: "contain"
    },
    "& .imgText": {
      textAlign: "center",
      color: "#020202"
    }

  },
  "@media (min-width: 600px) and (max-width: 900px)": {
    imageUploadContainer: {
      width: "100%"
    },
  },
  "@media (min-width: 901px) and (max-width: 1200px)": {
    imageUploadContainer: {
      width: "80%"
    },
  },
  textInfo: {
    marginBottom: "-5px"
  },
  submitBtn: {
    margin: "50px auto",
    background: colors.secondaryColor2,
    borderRadius: 10,
    ["& button"]: {
      textTransform: "capitalize",
      padding: "10px 35px",
      fontWeight: 600,
      fontSize: 16,
      borderRadius: 8,
      minWidth: 352,
      width: "auto",
      minHeight: 56,
      height: "auto",
      colors: colors.primaryColor2
    },
    ["&:hover"]: {
      background: colors.secondaryColor2,
    },
  },
  editBtnContainer : {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
  },
  editBtn: {
    width: "103px",
    height: "42px",
    margin: "0",
    backgroundColor: "white",
    fontWeight: 600,
    border: "1px solid rgba(2, 2, 2, 0.40)",
    "&:hover": {
      backgroundColor: "white !important",
      border: "1px solid rgba(2, 2, 2, 0.40)",
    },
  },
  imageAvatar: {
    width: "1.3rem",
    height: "1.3rem",
    cursor: "pointer",
    borderRadius: "unset",
    marginRight: 8,
    "& img": {
      height: "unset",
      width: "unset",
    }
  },
  displayNone: {
    visibility: "hidden" as const
  },
  paperStyle: {
    maxHeight: "220px",
  },

  listBox: {
    padding: 0,
    maxHeight: "220px",
  },
};
// Customizable Area End

export class AddNewBranch extends AddNewBranchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { classes } = this.props;
    return (
      <Wrapper navigation={this.props.navigation} divider backbtn={true} backHandleClick={this.goBack} title="Add New Branch" bellIcn={true} homeIcn={true} child={
          <Formik
            initialValues={{
              branch_name: "",
              type_of_point_of_sale: "",
              location_of_point_of_sale: "",
              time_zone: "",
              from_working_hours: null,
              to_working_hours: null,
              phone_number: "",
              email: "",
              photo: null,
              description: "",
              important_information: "",
              country_code_and_flag_id: ""
            }}
            validationSchema={AddNewBranchSchema}
            onSubmit={this.createNewBranch}
          >
            {({ errors, touched, handleBlur, handleChange, setFieldValue,handleSubmit, values }) => (
              <form onSubmit={handleSubmit} className={classes.formContainer}>
                <Box className={classes.mainContainer}>
                  <Box className={classes.leftSection}>
                    <Box className={`${classes.fieldWrapper} ${classes.marginWrap}`}>
                      <InputLabel data-test-id ="branch_name_text" required className={classes.inputLabel}>
                        {configJSON.branchNameText}
                      </InputLabel>
                      <TextField
                        type="text"
                        variant="outlined"
                        placeholder="Enter here"
                        name={"branch_name"}
                        value={values.branch_name}
                        onChange={handleChange}
                        fullWidth
                        className={classes.inputField}
                        error={handleInvoiceErrors(
                          errors.branch_name,
                          touched.branch_name
                        )}
                        helperText={handleInvoiceHelperText(
                          errors.branch_name,
                          touched.branch_name
                        )}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel required className={classes.inputLabel}>
                        {configJSON.pointOfSaleText}
                      </InputLabel>
                      <TextField
                        type="text"
                        variant="outlined"
                        placeholder="Enter here"
                        name={"type_of_point_of_sale"}
                        data-test-id='type_of_point_of_sale-input'
                        value={values.type_of_point_of_sale}
                        onChange={handleChange}
                        fullWidth
                        className={classes.inputField}
                        error={handleInvoiceErrors(
                          errors.type_of_point_of_sale,
                          touched.type_of_point_of_sale
                        )}
                        helperText={handleInvoiceHelperText(
                          errors.type_of_point_of_sale,
                          touched.type_of_point_of_sale
                        )}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel required className={classes.inputLabel}>
                        {configJSON.locationText}
                      </InputLabel>
                      <TextField
                        type="text"
                        variant="outlined"
                        placeholder="Enter here"
                        name={"location_of_point_of_sale"}
                        value={values.location_of_point_of_sale}
                        onChange={handleChange}
                        fullWidth
                        className={classes.inputField}
                        error={handleInvoiceErrors(
                          errors.location_of_point_of_sale,
                          touched.location_of_point_of_sale
                        )}
                        helperText={handleInvoiceHelperText(
                          errors.location_of_point_of_sale,
                          touched.location_of_point_of_sale
                        )}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel className={classes.inputLabel} required>
                        {configJSON.timeZoneText}
                      </InputLabel>
                      <DropdownSearch
                        options={this.state.timezone_data}
                        value={values.time_zone}
                        onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "time_zone")}
                        onBlur={handleBlur}
                        id="time_zone"
                        isSearchable={true}
                        placeholder={"Search Timezone..."}
                        noOptionsMessage={configJSON.noTimeZoneMessage}
                        showError={handleInvoiceErrors(
                          errors.time_zone,
                          touched.time_zone
                        )}
                        errorText={handleInvoiceHelperText(
                          errors.time_zone,
                          touched.time_zone
                        )}
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel required className={classes.inputLabel}>
                        {configJSON.hoursText}
                      </InputLabel>
                      <Box className={classes.timeContainer}>
                        <CustomAutoComplete
                          options={generateTimeValues()}
                          name="from_working_hours"
                          handleBlur={handleBlur}
                          value={values.from_working_hours}
                          placeholder={configJSON.openingTitle}
                          classes={classes}
                          freeSolo
                          onChange={(event: { target: { name: string } }, newValue: OptionType) => setFieldValue("from_working_hours", newValue)}
                          error={handleInvoiceErrors(
                            errors.from_working_hours,
                            touched.from_working_hours
                          )}
                          handleKeyPress={allowHoursMinutes}
                        />
                        <CustomAutoComplete
                          onChange={(event: { target: { name: string } }, newValue: OptionType) => setFieldValue("to_working_hours", newValue)}
                          name="to_working_hours"
                          options={generateTimeValues()}
                          handleBlur={handleBlur}
                          value={values.to_working_hours}
                          placeholder={configJSON.closingTitle}
                          freeSolo
                          handleKeyPress={allowHoursMinutes}
                          error={handleInvoiceErrors(
                            errors.to_working_hours,
                            touched.to_working_hours
                          )}
                          classes={classes}
                        />
                      </Box>
                      {(handleInvoiceErrors(errors.to_working_hours, touched.to_working_hours) ||
                        handleInvoiceErrors(errors.from_working_hours, touched.from_working_hours)) && (
                          <FormHelperText
                            className={classes.helperText}
                            data-test-id={"formToErrorMsg"}
                          >
                            {handleInvoiceHelperText(errors.from_working_hours, touched.from_working_hours)}
                            {handleInvoiceHelperText(errors.to_working_hours, touched.to_working_hours)}
                          </FormHelperText>
                        )}
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel required className={classes.inputLabel}>
                        {configJSON.phoneNumberText}
                      </InputLabel>
                      <Box className={classes.phoneGroup}>
                        <Box className={classes.dropdownWidth}>
                          <DropdownSearch
                            onBlur={handleBlur}
                            options={this.state.code_list}
                            value={values.country_code_and_flag_id}
                            onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "country_code_and_flag_id")}
                            showError={handleInvoiceErrors(
                              errors.country_code_and_flag_id,
                              touched.country_code_and_flag_id
                            )}
                            id="country_code_and_flag_id"
                            isSearchable={true}
                            placeholder={configJSON.countryCodePlaceholder}
                            noOptionsMessage={configJSON.noCountryCode}

                          />
                        </Box>
                        <TextField
                          type="text"
                          variant="outlined"
                          name="phone_number"
                          value={values.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          placeholder="Enter here"
                          className={`${classes.inputField} ${classes.inputWidth}`}
                          error={handleInvoiceErrors(
                            errors.phone_number,
                            touched.phone_number
                          )}
                          onKeyPress={allowNumberWithSpaceValidation}
                        />
                      </Box>
                      {(handleInvoiceErrors(errors.phone_number, touched.phone_number) || handleInvoiceErrors(errors.country_code_and_flag_id, touched.country_code_and_flag_id)) && (
                        <FormHelperText
                          className={classes.helperText}
                          data-test-id={"errorMessage"}
                        >
                          {handleInvoiceHelperText(errors.country_code_and_flag_id, touched.country_code_and_flag_id)}
                          {handleInvoiceHelperText(errors.phone_number,
                            touched.phone_number)}
                        </FormHelperText>
                      )}
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel className={classes.inputLabel}>
                        {configJSON.emailText}
                      </InputLabel>
                      <TextField
                        type="email"
                        variant="outlined"
                        placeholder="Enter here"
                        name={"email"}
                        value={values.email}
                        onChange={handleChange}
                        fullWidth
                        className={classes.inputField}
                        error={handleInvoiceErrors(
                          errors.email,
                          touched.email
                        )}
                        helperText={handleInvoiceHelperText(
                          errors.email,
                          touched.email
                        )}
                        onBlur={handleBlur}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.rightSection}>
                    <Box className={classes.imageUploadContainer}>
                      <ImageUploadView
                        handleImageChange={this.handleImageChange}
                        setFieldValue={setFieldValue}
                        name={"photo"}
                        currentImage={this.state.currentImage}
                        editable={false}
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel className={classes.inputLabel}>
                        {configJSON.textDescription}
                      </InputLabel>
                      <TextField
                        name={"description"}
                        value={values.description}
                        variant="outlined"
                        fullWidth
                        data-test-id="description"
                        multiline
                        type="text"
                        placeholder={configJSON.textareaPlaceHolder}
                        onChange={handleChange}
                        className={`${classes.inputField} ${classes.textAreaField}`}
                        helperText={handleInvoiceHelperText(
                          errors.description,
                          touched.description
                        )}
                        error={handleInvoiceErrors(
                          errors.description,
                          touched.description
                        )}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <InputLabel className={classes.inputLabel}>
                        {configJSON.textInfo}
                      </InputLabel>
                      <TextField
                        name={"important_information"}
                        value={values.important_information}
                        variant="outlined"
                        fullWidth
                        data-test-id="important_information"
                        multiline
                        type="text"
                        placeholder={configJSON.textareaPlaceHolder}
                        onChange={handleChange}
                        className={`${classes.inputField} ${classes.textAreaField}`}
                        helperText={handleInvoiceHelperText(
                          errors.important_information,
                          touched.important_information
                        )}
                        error={handleInvoiceErrors(
                          errors.important_information,
                          touched.important_information
                        )}
                        onBlur={handleBlur}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.submitBtn}>
                  <Button data-test-id="save-btn" type="submit">{configJSON.submitBtnText}</Button>
                </Box>
              </form>
            )}
          </Formik>
        } />
    );
  }
  // Customizable Area End
}
export default withStyles(styles)(AddNewBranch);

