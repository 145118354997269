import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ClassNameMap } from "@material-ui/styles";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { apiCall, checkToken, convertFormData, passwordEncryption, getDiffs, getUser, handleApiError, handleExpiredToken, isBO, setUser, sortArrayAlphabetically } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  id: string | number;
  loading: boolean;
  profileData: ProfileData;
  defaultData: ProfileData;
  profilePicture: string;
  defaultProfilePicture: string;
  countryList: OptionType[];
  countryCodeList: OptionType[];
  isEdit: boolean;
  position: string;
  enablePassword: boolean; 
  enableNewPassword: boolean; 
  enableConfirmNewPassword: boolean; 
  isEditPassword: boolean;
  imgError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface ProfileData {
  owner_name: string;
  owner_email: string;
  phone_number: string;
  photo: string;
  country_id: string | number;
  country_code_and_flag_id: string | number;
  current_password: string;
  new_password: string;
  confirm_new_password: string;
}

export interface ProfileResponse {
  data: {
    id: string | number;
    attributes: {
      owner_name: string;
      phone_number: string;
      owner_email: string;
      photo: string;
      country_id: string | number;
      country_code_and_flag_id: string | number;
      role_name: string;
    }
  },
  errors: string[],
}
// Customizable Area End



export default class MyProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  countryListApiId: string = "";
  getUserProfileApiId: string = "";
  editUserProfileCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];
    const initialData = {
      owner_name: "",
      owner_email: "",
      phone_number: "",
      photo : "",
      country_id: "",
      country_code_and_flag_id: "",
      current_password: "",
      new_password: "",
      confirm_new_password: "",

    }
    this.state = {
      id: "",
      loading: false,
      profileData : initialData,
      defaultData: initialData,
      profilePicture: "",
      defaultProfilePicture: "",
      countryList: [],
      countryCodeList: [],
      isEdit: false,
      position: "",
      enablePassword: true,
      enableNewPassword: true,
      enableConfirmNewPassword: true,
      isEditPassword: false,
      imgError: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  // Customizable Area Start
  async componentDidMount() {
    checkToken();
    this.setState({ loading: true });
    this.countryListApiId = apiCall({ method: configJSON.getCountryListMethod, endPoint: configJSON.getCountryListEndpoint, token: true });
    this.getProfileDetails();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resJson, this.props.navigation);
      this.setState({ loading: false });
      if (apiRequestCallId && resJson) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.getUserProfileApiId) {
          this.getUserResponse(resJson);
        } else if (apiRequestCallId === this.countryListApiId) {
          this.getCountryListResponse(resJson);
        } else if (apiRequestCallId === this.editUserProfileCallId) {
          this.editMyProfileResponse(resJson);
        }
      }
    }
  }

  getProfileDetails = () => {
    this.setState({ loading: true });
    this.getUserProfileApiId = apiCall({ method: configJSON.getMyProfileMethod, endPoint: configJSON.getMyProfileEndpoint, token: true });
  }

  getUserResponse = (response: ProfileResponse) => {
    const { data: userData } = response;
    if (userData) {
      const { owner_name, owner_email, phone_number, country_id, country_code_and_flag_id, photo, role_name } = userData.attributes;
      const defaultDataObj = {
        owner_name,
        owner_email,
        phone_number,
        country_id,
        photo: "",
        country_code_and_flag_id,
        current_password: "",
        new_password: "",
        confirm_new_password: "",
      }
      this.setState({
        profileData: defaultDataObj,
        defaultData: defaultDataObj,
        profilePicture: photo,
        defaultProfilePicture: photo,
        id: userData.id,
        position: role_name
      });
    }
  };

  getCountryListResponse = (response: {data: { id: string; name: string; alpha2: string; country_code: string }[]}) => {
    const { data: countryData} = response;
    const defaultItem: OptionType = { value: "", label: configJSON.countryCodePlaceholder, isDisabled: true }
    if (countryData.length > 0) {
      let finalData = countryData.map((item: { id: string; name: string}) => {
        const { id, name} = item;
        return { label: name, value: Number(id) };
      })
      finalData = sortArrayAlphabetically(finalData, 'label');
      finalData.unshift(defaultItem);

      let countryCodeList = countryData.map((item: { id: string; name: string; alpha2: string; country_code: string }) => {
        const { id, country_code, alpha2} = item;
        return { label: `${country_code} (${alpha2})`, value: Number(id) };
      })
      countryCodeList = sortArrayAlphabetically(countryCodeList, 'label');
      this.setState({ countryList: finalData , countryCodeList});
    }
    else {
      this.setState({ countryList: [defaultItem] });
      toast.error("No countries available");
    }
  };

  editMyProfileResponse = (response: ProfileResponse) => {
    if (response.data) {
      this.getUserResponse(response)
      toast.success(`Profile has been saved successfully`);
      let allData = getUser();
      allData.data.attributes.profile = response;
      setUser(allData);
      this.handleDisableEditProfile({})
      location.reload();
    } else {
      handleApiError(response.errors);
    }
  };

  handleSelectChange = (eventItem: OptionType, setFieldValue: { (field: string, value: string, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, key: keyof ProfileData) => {
    let finalData:  string = eventItem?.value;
    if(key === "country_code_and_flag_id") {
      setFieldValue("country_id", finalData);
      setFieldValue(key, finalData);
    } else {
      setFieldValue(key, finalData);
    }
  };

  handleEnableEditProfile = () => {
    this.setState({ isEdit: true });
  }
  handleDisableEditProfile = ({ resetForm }: { resetForm?: () => void }) => {
    resetForm && resetForm();
    this.setState({ isEdit: false, profilePicture: this.state.defaultProfilePicture, isEditPassword: false , enablePassword: true, enableNewPassword: true, enableConfirmNewPassword: true });
  }

  handleProfileChange = (eventItem: { target: { files: File[]; }; }, setFieldValue: { (field: string, value: string | string[] | undefined | OptionType | File, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; } ) => {
    const file = eventItem.target.files[0];
    if (file) {
      let allowFile = file.size <= 5 * 1024 * 1024;
      if (allowFile) {
        const fileType = file.type;
        if (fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/jpg') {
          setFieldValue("photo", file);
          this.setState({
            imgError: "",
            profilePicture: URL.createObjectURL(file),
          });
        } else {
          toast.error("File selected is not in JPG / PNG format")
        }
      } else {
        toast.error("Maximum file size should be 5MB");
      }
    }
  };

  handleClearProfileImg = (setFieldValue: { (field: string, value:File, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: null): void; }) => {
    this.setState({
      profilePicture: "",
      imgError: "Profile picture is required"
    });
    setFieldValue("photo", null)
  };

  editProfile = async (values: ProfileData) => {
    const { defaultData } = this.state;

    const { owner_name, owner_email, phone_number, country_id, country_code_and_flag_id } = defaultData;

    const orriginalData = {
     owner_name,
     owner_email,
     country_code_and_flag_id,
     phone_number,
     country_id
    };
    const bothPass = (values.new_password && values.current_password)
    let currPass = bothPass && await passwordEncryption(values.current_password).then((res) => res);
    let newPass = bothPass && await passwordEncryption(values.new_password).then((res) => res);
    const attrs = {
      owner_name: values.owner_name,
      owner_email: values.owner_email,
      country_code_and_flag_id: values.country_code_and_flag_id,
      phone_number: values.phone_number,
      country_id: values.country_id,
      ...(values.photo ? { photo: values.photo } :  this.state.profilePicture ? {} : {photo: ""}),
      ...((currPass) ? { current_password: currPass } : {}),
      ...((newPass) ? { new_password: newPass } : {}),
    };

    let payload = getDiffs(orriginalData, attrs);
    if(!isBO() && !this.state.profilePicture) {
      return
    }
    this.editProfileApiCall(payload)
  }

  editProfileApiCall = (countryData: object) => {
    const { id: profileId } = this.state;
    if (Object.keys(countryData).length) {
      this.setState({ loading: true });
      this.editUserProfileCallId = apiCall({
        method: configJSON.editProfileMethod,
        body: convertFormData(countryData),
        endPoint: `${configJSON.editProfileEndpoint}/${profileId}`,
        token: true,
      });
    } else {
      this.handleDisableEditProfile({})
      toast.success(`Profile has been saved successfully`);
    }
  }

  handleClickShowPassword = () => {
    this.setState({
      enablePassword: !this.state.enablePassword,
    });
  };

  handleClickShowNewPassword = () => {
    this.setState({
      enableNewPassword: !this.state.enableNewPassword,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmNewPassword: !this.state.enableConfirmNewPassword,
    });
  };

  toggleEditPassword = () => {
    this.setState({ isEditPassword: !this.state.isEditPassword });
  }

  checkDisabledPass = () => {
    const { isEdit, isEditPassword } = this.state;
    return !(isEditPassword && isEdit);
  }

  // Customizable Area End
}

