import { colors } from "../../../components/src/utils/commonDesign";
export const styles: any = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "inherit",
  },
  card: {
    borderRadius: 16,
    margin:"auto",
  },
  cardContent: {
    padding: "35px 45px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width:"55rem"
  },
  title: {
    color: colors.secondaryColor1,
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "42px",
    margin: "20px 0",
  },
  descriptionContainer: {
    border : "1px solid #979797",
    borderRadius: 16,
    height: 375,
    overflowY: "scroll",
    width: "100%",
    "& p" : {
      color: colors.primaryColor2,
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      marginTop: "10px",
    },
    "& h1,h2,h3,h4,h5,h6" : {
      color: colors.primaryColor2,
      fontFamily: "Bitter-VariableFont",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "22px",
      margin: "12px 0 24px",
    }
  },
  innerContainer : {
    padding: 26,
  },
  downloadIconBox : {
    display: "flex",
    justifyContent : "end",
  },
  downloadIcon : {
    cursor: "pointer",
  },
  helperText: {
    color: colors.errorText,
    margin: 0,
    marginTop: "5px",
  },
  errorMsg: {
    paddingBottom: "5px",
    color: colors.errorText,
    marginBottom: "-20px",
    marginTop: "10px",
    lineHeight: "21px",
    fontSize: "18px",
    fontWeight: 400,
    letterSpacing: "0.03333em",
  },
  btnWrapper1: {
    marginTop: "25px",
    display: "flex",
    justifyContent: "center",
  },
  btn: {
    margin: "0 16px",
    boxShadow: "none",
    fontWeight: 700,
    textTransform: "capitalize",
    height: "45px",
    width: "300px",
    borderRadius: "10px",
    fontSize: "16px",
    lineHeight: "21px",
    color: colors.primaryColor2,
  },
  primaryBtn: {
    backgroundColor: colors.secondaryColor2,
    "&:hover": {
      backgroundColor: colors.secondaryColor2,
    }
  },
};
