import { colors } from "../../../components/src/utils/commonDesign";

export const styles: any = {
  outerBox: {
    height: "100%",
    width: "100%",
  },
  innerBox: {
    padding: "0 !important",
    margin: 0,
    overflowY: "auto",
    height: "100%",
    "& .title-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .body1": {
        margin: 0,
        color: colors.primaryColor2,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "24px"
      },
      "& .title1": {
        color: colors.text,
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "32px",
      },
      "& .fieldWrapper": {
        height: "42px",
        width: "200px",
        "& .MuiInputBase-root": {
          borderRadius: "10px",
          height: "42px",
          backgroundColor: "#FFFFFF",
          "& .MuiInputBase-input": {
            fontSize: "16px",
            lineHeight: "21px",
            padding: "10.5px 14px",
            "& .Mui-disabled": {
              color: colors.primaryColor2,
              backgroundColor: "#F5F5F5",
              borderRadius: "10px",
            },
          },
        },
        "& .Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.secondaryColor1,
          },
        },
        "t& .inputField": {
          margin: 0,
          "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.secondaryColor1,
            },
          },
        },
      },
    },
    "& .depContainer": {
      marginTop: "30px",
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      "& .depName": {
        color: colors.primaryColor2,
        fontSize: "22px",
        fontWeight: 400,
        lineHeight: "29px",
        marginBottom: "15px"
      },
    },
    "& .btnWrapper1": {
      marginTop: "65px",
      display: "flex",
      justifyContent: "center",
      "& .btn": {
        margin: "0 16px",
        boxShadow: "none",
        fontWeight: 700,
        textTransform: "capitalize",
        height: "56px",
        width: "300px",
        borderRadius: "10px",
        fontSize: "16px",
        lineHeight: "21px",
        color: colors.primaryColor2,
      },
      "& .primaryBtn": {
        backgroundColor: colors.secondaryColor2,
        "&:hover": {
          backgroundColor: colors.secondaryColor2,
        }
      },
      "& .secondaryBtn": {
        "&:hover": {
          backgroundColor: colors.secondaryColor2,
          border: "none",
        }
      },
    },
  },
  helperText: {
    color: colors.errorText,
    margin: "-22px 0 22px 30px",
  },
};
