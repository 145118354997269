import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, Button, Avatar, Dialog, DialogContent, DialogActions } from "@material-ui/core";
import DepartmentEmployeeController, { Props } from "./DepartmentEmployeeController.web"
export const configJSON = require("./config");
import { Wrapper } from "../../../components/src/Wrapper";
import { editIcon, deleteImage, noBranchImage } from "../../OrganisationHierarchy/src/assets"
import Pagination from "../../../components/src/commonComponents/Pagination.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import NoTickets from "../../../components/src/commonComponents/NoTickets.web";
import CustomRadioSelect from "../../../components/src/commonComponents/CustomRadioSelect.web";
import { commonButton, commonListingCss } from "../../../components/src/utils/commonInputStyle";
import { statusList } from "../../../components/src/utils/constant";
import Loader from "../../../components/src/Loader";
import { isManager } from "../../../components/src/utils/commonFunctions";

// Customizable Area End

// Customizable Area Start
export const styles = {
    ...commonButton,
    ...commonListingCss,
    roleModel: {
        "& .MuiDialog-paper": {
            maxWidth: "65% !important",
            padding: "4rem !important",
            borderRadius: 8
        },
        "& button": {
            width: 300
        }
    },
    roleDialog: {
        padding: "0 !important",
        "& h4": {
            fontWeight: 700,
            fontSize: 20,
            paddingBottom: 20
        }
    },
    listGroup: {
        display: "flex",
        justifyContent: "space-between",
        height: 200,
        "& .content-item": {
            border: "1px solid #02020240",
            padding: "10px",
            marginRight: "2rem",
            borderRadius: 8,
            width: "200px",
            textAlign: "center",
            height: "50px",
            fontWeight: 700,
            display: "block",
            overflow: "hidden" as const,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        },

    },
    roleDropdown: {
        width: "11rem",
    },
    titleText: {
        fontSize: "18px",
        fontWeight: 700
    },
    feedbackBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent:"flex-end",
        marginTop:"10px",
        "& button": {
            borderRadius: "unset",
            textTransform: "unset",
            color: "#4196F0",
            padding: 0,
            borderBottom: "1px solid #4196F0",
            fontSize: "1rem",
            background: "transparent",
            "&:disabled": {
                borderBottom: "1px solid #4196F040",
                color: "#4196F040",
            },
            "&:hover": {
                background: "transparent",
            },
        },
    },

}
// Customizable Area End

export class DepartmentEmployee extends DepartmentEmployeeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    render() {
        const { classes } = this.props;
        const { employee_list, meta, openDeleteModel,
            openRoleModel, is_open_role, item, select_role, searchQuery, select_status, is_open_status, loading
        } = this.state
        return (
            <>
                <Loader loading={loading} />
                <Wrapper
                    data-test-id="wrapper-comp"
                    navigation={this.props.navigation}
                    divider
                    backbtn
                    title={configJSON.employeeTitle}
                    bellIcn
                    homeIcn
                    search
                    querySearch={searchQuery}
                    searchHandleClick={this.handleSearch}
                    searchData={employee_list}
                    child={
                        <Box className={classes.listContainer}>
                            <Box className={classes.headerSection}>
                                <Typography className={classes.titleText} component="h4">{configJSON.invitedEmployee}</Typography>
                                <Box className={classes.roleDropdown}>
                                    <CustomRadioSelect
                                        title={configJSON.statusText}
                                        data={statusList}
                                        selectedValue={select_status}
                                        toggleModal={this.toggleEditableStatus}
                                        isModalOpen={is_open_status}
                                        onValueSelect={this.handleEditableStatus}
                                    />
                                </Box>
                            </Box>
                            <Box data-test-id="clearall_button" onClick={this.handleClearAllFilter} className={`${classes.feedbackBtn} clearAll`}>
                                <Button disabled={this.handleDisable()}>{configJSON.clearAll}</Button>
                            </Box>
                            <Box className={classes.listItem}>
                                <Box className={classes.tableHeader}>
                                    {configJSON.departEmployeeHeaderData.map((item: string, index: number) => (
                                        <Typography component="p" key={index}>{item}</Typography>
                                    ))}
                                </Box>
                                <Box className={classes.listMain}>
                                    {employee_list.length > 0 ?
                                        employee_list.map((item) => (
                                            <Box key={item.id} className={classes.tableItem}>
                                                <Typography component="p" >{item.attributes.department_names.join(",")}</Typography>
                                                <Typography component="p" data-test-id="employee_name">{item.attributes.profile.data === null ? "" : item.attributes.profile.data.attributes.owner_name}</Typography>
                                                <Typography component="p" className="status" >{item.attributes.status}</Typography>
                                                <Typography component="p" >{item.attributes.role_name}</Typography>
                                                <Box className={classes.btnGroup}>
                                                    <Box onClick={() => this.handleEdit(item)} className={classes.btnInner} data-test-id="edit-btn">
                                                        <Avatar
                                                            src={editIcon}
                                                            alt="edit"
                                                            className={classes.avatarImage}
                                                        />
                                                        <Button>{configJSON.editButton}</Button>
                                                    </Box>
                                                    <Box onClick={() => this.handleDelete(item)} className={classes.btnInner} data-test-id="delete-btn">
                                                        <Avatar
                                                            src={deleteImage}
                                                            alt="delete"
                                                            className={classes.avatarImage}
                                                        />
                                                        <Button>{configJSON.deleteButton}</Button>
                                                    </Box>

                                                </Box>
                                            </Box>

                                        )) : employee_list.length === 0 && searchQuery ? <Box className={classes.noList}>
                                            <Typography data-test-id="search_result" variant="h5">{configJSON.searchResult} <strong>{searchQuery}</strong>.</Typography>
                                        </Box> :
                                            <Box className={classes.noList}>
                                                <NoTickets
                                                    title={configJSON.titleNoEmployee}
                                                    imagePath={noBranchImage}
                                                    content={configJSON.contentText}
                                                />
                                            </Box>

                                    }
                                </Box>
                                <Box className={classes.paginationContainer}>
                                    {employee_list.length > 0 && <Pagination meta={meta} onPageChange={this.handlePageChange} />}
                                </Box>
                            </Box>

                            <ConfirmationModal
                                open={openDeleteModel}
                                onClose={this.handleClose}
                                confirmDelete={this.handleDeleteEmployee}
                                title={item.attributes?.profile.data === null ? "" : item.attributes?.profile.data.attributes.owner_name}
                                from={configJSON.confirmList}
                            />
                            <Dialog open={openRoleModel} className={classes.roleModel} data-test-id="dialog_box">
                                <DialogContent className={classes.roleDialog}>
                                    <Typography component="h4">{configJSON.editRoleText}</Typography>
                                    <Box className={classes.listGroup}>
                                        <Typography className="content-item">{item.attributes?.department_names.join(",")}</Typography>
                                        <Typography className="content-item">{item.attributes?.profile.data === null ? "" : item.attributes?.profile.data.attributes.owner_name}</Typography>
                                        <Box className={classes.roleDropdown}>
                                            <CustomRadioSelect
                                                title={configJSON.roleTitle}
                                                data={isManager() ? configJSON.roleType.slice(1, 3) : configJSON.roleType}
                                                selectedValue={select_role}
                                                toggleModal={this.toggleSelectRole}
                                                isModalOpen={is_open_role}
                                                onValueSelect={this.handleSelectRole}
                                            />
                                        </Box>
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        data-test-id="cancel-btn"
                                        variant="outlined"
                                        className={classes.secondaryBtn}
                                        onClick={this.handleClose}
                                    >
                                        {configJSON.cancel}
                                    </Button>
                                    <Button
                                        data-test-id="save-btn"
                                        variant="contained"
                                        className={classes.primaryBtn}
                                        onClick={this.editEmployee}
                                    >
                                        {configJSON.saveButtonText}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Box >
                    } />


            </>
        );
    }
    // Customizable Area End
}
export default withStyles(styles)(DepartmentEmployee);

