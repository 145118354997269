import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import moment from "moment";
import { apiCall, checkToken, filterNullObj, handleApiError, handleExpiredToken, isBO, isManager, removeNullFromObj, sortArrayAlphabetically, userBranchId, usersDepartments } from "../../../components/src/utils/commonFunctions";
import { ClassNameMap } from "@material-ui/styles";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  // Customizable Area Start
  classes: ClassNameMap<"mainContainerBox" | "rightContainerBox" | "tabBtn" | "tabHeading" | "arrowIcon" | "menuItems" | "filterBtn" | "dateBox" | "filterBtnTxt"
    | "inputField" | "dateField" | "filterDayItem" | "filterDayItemHighlight" | "filterTxt" | "filterTxtHighlight" | "userLogView"
    | "gridBox" | "recentCreated" | "cardWrapper" | "templateImg" | "procedureTitleTxt" | "dateTxt" | "favouriteIcn" | "listItem"
    | "tableHeader" | "listMain" | "tableItem" | "imgContainer" | "paginationBox" | "tableContainer"
    | "userTtitle" | "noText" | "noTicketContainer" | "flexBox" | "feedbackBtn" | "clearSection" | "procedureNameTxt"
  >;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  value: number;
  openMenu: null | HTMLElement;
  date: DateData;
  searchQuery: string;
  tabMenuOpen: boolean[]
  gridView: boolean;
  menuAnchorEl: null;
  menuLabel: string;
  menuAction: string;
  formData: FormValues;
  branch_list: Lists[];
  department_list: Lists[];
  user_logs: string;
  next_user_logs: string;
  logs_list: LogsType[];
  meta: {
    total_pages: number;
    next_page: null | number;
    previous_page: null | number;
    total_count: number;
    current_page: number;
  };
  isOpen: { [key: string]: boolean };
  openDeleteModal: boolean;
  log_id: string | number;
  item: LogsType;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;

  // Customizable Area End
}

// Customizable Area Start
export interface DateData {
  to_date: null | string;
  from_date: null | string;
  time_range: null | string;
}
interface FormValues {
  branch_id: string;
  department_id: string;
}
interface Lists {
  id?: number,
  value: string | number;
  label: string;
  isDisabled?: boolean;
}
interface LogsType {
  id: string;
  attributes: {
    id: number | string;
    filled_by: string;
    created_at: string;
    updated_at: string;
    department_id: number;
    branch_id: number;
    name: string;
    department_name: string;
    role_name: string;
    title: string;
    type: string;
    form_id?: number | string;
    checklist_id?: number | string;
    creator?: {
      id: number | undefined;
      name: string;
      designation: string;
      email: string
    }
  }
}
interface ResponseDepartment {
  id: string;
  departments: {
    id: string;
    name: string;
  }[]
}
// Customizable Area End

export default class UserLogsListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBranchesApiId: string = "";
  getDepaApiId: string = "";
  getLogsApiCallId: string = "";
  deleteLogsApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    const initialData = {
      branch_id: isManager() ? userBranchId : "",
      department_id: "",
    }

    const path = this.props.navigation.history.location.pathname;
    const parts = path.split('/');
    const user_logs = parts[2];
    const menuAction = parts[3];
    
    this.state = {
      value: user_logs === "my_logs" ? 0 : 1,
      gridView: true,
      openMenu: null,
      formData: initialData,
      date: {
        from_date: null,
        time_range: null,
        to_date: null,
      },
      menuAnchorEl: null,
      tabMenuOpen: [false, false],
      searchQuery: "",
      menuLabel: "Submitted Forms",
      menuAction,
      user_logs,
      next_user_logs: user_logs,
      branch_list: [],
      department_list: [],
      logs_list: [],
      meta: {
        total_pages: 1,
        total_count: 1,
        current_page: 1,
        next_page: null,
        previous_page: null
      },
      isOpen: {},
      openDeleteModal: false,
      log_id: "",
      item: {
        id: "",
        attributes: {
          id: "",
          filled_by: "",
          created_at: "",
          updated_at: "",
          department_id: 0,
          branch_id: 0,
          name: "",
          department_name: "",
          role_name: "",
          title: "",
          type: ""
        }
      },
      loading: false
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleBranchResponse(from, message)
    this.handleDepartmentResponse(from, message)
    this.handleResponseForFilterLogsData(from, message)
    this.handleDeleteResponse(from, message)
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    checkToken()
    if (isBO() || isManager()) {
      this.getBranchesApiId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.getBranchApiEndpoint, token: true });
    }
    this.filterLogsList(1)
    isManager() && this.getDepartment()
    let { state } = this.props.navigation.history.location;
    if (state) {
      this.setState({ ...this.state, ...state });
    }
  }
  componentDidUpdate(prevProps: Props, previousState: S) {
    if (this.state.formData.branch_id !== previousState.formData.branch_id && this.state.formData.branch_id) {
      this.setState({ department_list: [], formData: { department_id: "", branch_id: this.state.formData.branch_id } });
      this.getDepartment()
    }
    if (this.state.searchQuery !== previousState.searchQuery
      || this.state.formData.department_id !== previousState.formData.department_id
      || this.state.formData.branch_id !== previousState.formData.branch_id
      || this.state.user_logs !== previousState.user_logs
      || this.state.menuAction !== previousState.menuAction
      || this.state.date.time_range !== previousState.date.time_range
      || ((this.state.date.from_date && this.state.date.to_date) && (this.state.date.from_date !== previousState.date.from_date || this.state.date.to_date !== previousState.date.to_date))
    ) {
      this.filterLogsList(this.state.meta.current_page)
    }
  }

  handleClearAllFilter = () => {
    if (isBO()) {
      this.setState({
        searchQuery: "",
        formData: { branch_id: "", department_id: "" },
        date: { time_range: null, to_date: null, from_date: null }
      });
    } else {
      this.setState(prev => ({
        date: { time_range: null, to_date: null, from_date: null },
        searchQuery: "",
        formData: { branch_id: prev.formData.branch_id, department_id: "" },
      }))
    }
  }
  handleDisable = () => {
    const { branch_id, department_id } = this.state.formData
    let allManagerData = { ...this.state.date, department_id, query: this.state.searchQuery };
    let allBOData = { ...this.state.date, branch_id, department_id, query: this.state.searchQuery };
    const dataBO = removeNullFromObj(allBOData)
    const dataManager = removeNullFromObj(allManagerData)
    if (isBO()) {
      return (Object.keys(dataBO).length <= 0)
    } else {
      return (Object.keys(dataManager).length <= 0)
    }
  }
  handleTabMenuOpen = (event: { currentTarget: null; }, next_user_logs: string,  tabIndex: number) => {
    const { tabMenuOpen } = this.state;
    const newTabMenuOpen = [...tabMenuOpen];
    newTabMenuOpen[tabIndex] = !newTabMenuOpen[tabIndex];

    this.setState({
      menuAnchorEl: event.currentTarget,
      tabMenuOpen: newTabMenuOpen,
      next_user_logs
    });
  };

  handleTabMenuClose = () => {
    this.setState((prevState) => ({
      menuAnchorEl: null,
      tabMenuOpen: prevState.tabMenuOpen.map(() => false)
    }));
  };
  handlePageChange = (pageNumber: number) => {
    this.setState({
      meta: {
        ...this.state.meta,
        current_page: pageNumber
      }
    });
    this.filterLogsList(pageNumber)
  };
  
  handleTabChange = (logType: string) => {
    this.props.navigation.history.push(`/UserLogsListing/${logType}/submitted_form`)
  };

  handleSelectChange = (event: OptionType, keyItemData: keyof FormValues, clearValue?: Partial<FormValues> | undefined) => {
    let finalRes: OptionType | string = event?.value;
    if (clearValue && (this.state.formData[keyItemData] !== finalRes)) {
      this.setState({ formData: { ...this.state.formData, [keyItemData]: finalRes, ...clearValue } });
    } else {
      this.setState({ formData: { ...this.state.formData, [keyItemData]: finalRes } });
    }
  };
  handleViewEditClick = (item: LogsType, action: string) => {
    const urlItem = item.attributes.type === "Form" ? `/Form?id=${item.attributes.form_id}` : `/ChecklistForm?id=${item.attributes.checklist_id}`
    this.props.navigation.history.push(urlItem, { from: configJSON.fromUserLogs, action, data: item.attributes })
  };
  getFormattedDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString().split(",")[0]
  }
  getDepartment = () => {
    this.getDepaApiId = apiCall({ method: configJSON.validationApiMethodType, endPoint: `${configJSON.getDeptApiEndpoint}${this.state.formData.branch_id}`, token: true })
  }
  handleAction = (value: number, action: string, label: string) => {
    this.setState((prevState) => ({
      menuAnchorEl: null,
      tabMenuOpen: prevState.tabMenuOpen.map(() => false),
    }));
    this.props.navigation.history.push(`/UserLogsListing/${this.state.next_user_logs}/${action}`)
  };

  toggleDropdown = (itemId: number | string) => {
    this.setState((prevState) => {
      const updatedIsOpen = { ...prevState.isOpen };

      for (const keyItemData in updatedIsOpen) {
        updatedIsOpen[keyItemData] = false;
      }

      updatedIsOpen[itemId] = !prevState.isOpen[itemId];

      return {
        isOpen: updatedIsOpen,
      };
    });
  };

  handleMenuClose = () => {
    this.setState({ openMenu: null });
  };
  handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ openMenu: event.currentTarget });
  };

  handleDateChange = (event: string | number | Date | moment.Moment | (string | number)[] | moment.MomentInputObject | null | undefined, keyItemData: keyof DateData) => {
    const isDate = moment(event).format("MM/DD/YYYY")
    this.setState({
      date: {
        ...this.state.date,
        [keyItemData]: isDate,
      }
    })
    if ((this.state.date.from_date && keyItemData === "to_date") || (this.state.date.to_date && keyItemData === "from_date")) {
      this.setState(prev => ({
        date: {
          ...prev.date,
          time_range: "custom",
        }
      }))
      this.handleMenuClose()
    }
  }

  toggleView = () => {
    this.setState(prev => ({ gridView: !prev.gridView }))
  }
  handleDayChange = (time_range: string) => {
    if (time_range === "Last 15 days") {
      this.setState({ date: { time_range: "15_days", from_date: null, to_date: null } })
    } else {
      this.setState({ date: { time_range: time_range.toLowerCase(), from_date: null, to_date: null } })
    }
    this.handleMenuClose()
  }

  handleSearch = (query: string) => {
    this.setState({ searchQuery: query })
  }
  handleCloseDeleteModal = () => {
    this.setState({ openDeleteModal: false })
  }

  handleOpenDeleteModal = (item: LogsType) => {
    this.setState({ openDeleteModal: true, isOpen: {}, log_id: item.attributes.id, item: item })
  }
  handleDeleteClick = () => {
    this.deleteLogsApiId = apiCall({ method: configJSON.deleteMethod, endPoint: `${this.state.item.attributes.type === "Form" ? configJSON.formDeleteEndPoint : configJSON.checklistDeleteEndPoint}/${this.state.log_id}`, token: true });
    this.handleCloseDeleteModal()
  }
  filterLogsList = (page: number) => {
    this.setState({ loading: true })
    let endPointfiter: string = `${configJSON.getLogsApiEndpoint}`
    const { date: { from_date, to_date, time_range }, formData: { branch_id, department_id }, searchQuery } = this.state
    let allData = { page, custom_start_date: from_date ? moment(from_date).format("DD/MM/YYYY") : "", custom_end_date: to_date ? moment(to_date).format("DD/MM/YYYY") : "", time_range, branch_id, department_id, query: searchQuery,content:this.state.menuAction, logs_type : this.state.user_logs };

    this.getLogsApiCallId = apiCall({
      method: "GET",
      endPoint: endPointfiter + filterNullObj(allData),
      token: true
    })
  }
  handleResponseForFilterLogsData = (from: string, message: Message) => {
    if (this.getLogsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const respo = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(respo, this.props.navigation);
      if (respo.logs) {
        this.setState(prev => ({
          logs_list: respo.logs.data,
          meta: respo.meta ?? prev.meta,
          loading: false
        }))

      } else {
        this.setState({ loading: false })
        handleApiError(respo.errors);
      }

    }
  }
  handleBranchResponse = (from: string, message: Message) => {
    if (this.getBranchesApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(response, this.props.navigation);
      if (response.branches.data) {
        const defaultItem = { value: "", label: configJSON.branchPlaceholder, isDisabled: true };
        let dataItem = response.branches.data.map((item: { id: number, attributes: { branch_name: string; location_of_point_of_sale: string } }) => ({ value: item.id, label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }));
        dataItem = sortArrayAlphabetically(dataItem, 'label');
        dataItem = [defaultItem, ...dataItem]
        let filteredBranch = response.branches.data.filter((branch: { id: string; }) =>
          branch.id == userBranchId).map((item: { id: string | number; attributes: { branch_name: string; location_of_point_of_sale: string; }; }) => ({ value: +item.id, label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }));
        if (isBO()) {
          this.setState({
            branch_list: dataItem
          })
        } else {
          this.setState({
            branch_list: filteredBranch
          })
        }
      } else {
        handleApiError(response.errors);

      }

    }
  }
  handleDepartmentResponse = (from: string, message: Message) => {
    if (this.getDepaApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const respo: ResponseDepartment[] = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(respo, this.props.navigation);
      const defaultItem = { value: "", label: configJSON.departSelect, isDisabled: true }
      if (respo.length > 0) {
        let finalRes: Lists[] = [];
        if (isBO()) {
          respo.forEach((item) => {
            item.departments.forEach((depart) => {
              const { id, name } = depart;
              finalRes.push({ label: name, value: id });
            })
          })
        } else {
          usersDepartments.map((depart: { attributes: { name: string; id: string } }) => {
            finalRes.push({ label: depart.attributes.name, value: depart.attributes.id });
          })
        }
        finalRes = sortArrayAlphabetically(finalRes, 'label');
        finalRes.unshift(defaultItem);
        this.setState({ department_list: finalRes });
      } else {
        this.setState({ department_list: [defaultItem] });
        toast.error(configJSON.noDepartmentMessage);
      }

    }
  }

  handleDeleteResponse = (from: string, message: Message) => {
    if (this.deleteLogsApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(response, this.props.navigation);
      if (response.errors) {
        handleApiError(response.errors);
      } else {
        toast.success(configJSON.deleteSuccessMessage)
        this.filterLogsList(this.state.meta.current_page)
      }

    }
  }
  allowEditDeleteView = () => {
    return (this.state.user_logs === "user_logs" ? false : true);
  }
  // Customizable Area End
}

