// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  CompanyProfileSchema1,
  CompanyProfileSchema2,
  CompanyProfileSchema3,
} from "../../../components/src/utils/validationSchema";
import {
  apiCall,
  checkTokenBeforeLogin,
  convertFormData,
  generateTimeValues,
  handleApiError,
  handleExpiredToken,
  handleUndefinedData,
  isBusinessOwner,
  mergeFiles,
  sortArrayAlphabetically,
} from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { FormikErrors, FormikTouched } from "formik";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  classes: Record<string, string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  company_id: string | number;
  activeStep: number;
  photoUrl: string;
  documents: (Documents | File)[];
  documentList: (Documents | File)[];
  industries: OptionType[];
  typeOfBusiness: OptionType[];
  apiError: string;
  isEdit: boolean;

  name: string;
  owner_name: string;
  owner_email: string;
  phone_number: string | number;
  head_office: string;
  industry_id: string | number;
  business_type_id: string | number;
  employee_count: number | string;
  creation_date: string | Date | null;
  from_working_hours: string | null;
  to_working_hours: string | null;
  description: string;
  photo: File | null;
  showErrorFrom: boolean;
  isModalOpenFrom: boolean;
  showErrorTo: boolean;
  isModalOpenTo: boolean;
  countryCodeList: {
    label: string;
    value: string
  }[],
  country_code_and_flag_id: string | null;
  hoursList: string[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Attributes {
  id: number
  name: string
  account_id: number
  owner_name: string
  owner_email: string
  phone_number: string
  head_office: string
  employee_count: number
  creation_date: string
  opening_hours: string
  from_working_hours: string
  to_working_hours: string
  description: string
  primary_colour: string
  secondary_colour: string
  background_colour: string
  country_code_and_flag_id: number
  country_code: string
  industry: BusinessAndIndusrtyType
  business_type: BusinessAndIndusrtyType
  photo: string
  documents: Documents[]
}
export interface BusinessAndIndusrtyType {
  data: Data
}
interface Data {
  id: string
  type: string
  attributes: {
    id: number
    name: string
  }
}
interface Documents {
  id?: number;
  name: string;
  url?: string
}

export type RenderFormProps = {
  errors: FormikErrors<Partial<S>>,
  touched: FormikTouched<Partial<S>>,
  handleBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
  handleChange: (field: string | React.ChangeEvent<Element>) => void,
  setFieldValue: (field: string, value: OptionType | string | MaterialUiPickersDate | File[] | Documents[] | File | Documents | undefined | string[] | null, shouldValidate?: boolean | undefined) => void,
  values: Partial<S>
};
// Customizable Area End

export default class CompanyProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCompanyProfileApiCallId: string = "";
  industriesApiCallId: string = "";
  typeOfBusinessApiCallId: string = "";
  saveCompanyApiCallId: string = "";
  getCountryCodeListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      loading: false,
      company_id: "",
      activeStep: 1,
      photoUrl: "",
      documents: [],
      documentList: [],
      industries: [],
      typeOfBusiness: [],
      apiError: "",
      isEdit: false,

      name: "",
      owner_name: "",
      owner_email: "",
      phone_number: "",
      country_code_and_flag_id: "",
      industry_id: "",
      business_type_id: "",
      employee_count: "",
      creation_date: new Date(),
      from_working_hours: null,
      to_working_hours: null,
      head_office: "",
      description: "",
      photo: null,
      showErrorFrom: false,
      showErrorTo: false,
      isModalOpenTo: false,
      isModalOpenFrom: false,
      countryCodeList: [],
      hoursList: []
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.setState({ loading: true, hoursList: generateTimeValues() });
    checkTokenBeforeLogin(this.props.navigation)
    this.getCompanyProfileApiCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.Companyuserdetails,
      token: true,
    });
    this.industriesApiCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getIndustriesApiEndPoint,
      token: false,
    });
    this.typeOfBusinessApiCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getBusinessTypesApiEndPoint,
      token: false,
    });
    this.getCountryCodeListApiCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.countryCodeApiEndPoint });

  }
  handleChangeSelect = (event: OptionType, setFieldValue: { (field: string, value: string, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, keyItem: string) => {
    setFieldValue(keyItem, event.value);
  };
  handleFromWorkingSelect = (event: { target: { name: string } }, newValue: string | null, values: Partial<S> | ((prevState: Readonly<S>, props: Readonly<Props>) => S | Pick<S, keyof S> | null) | Pick<S, keyof S> | null) => {
    this.setState({
      ...this.state,
      ...values,
      from_working_hours: newValue,
      isModalOpenFrom: false
    });
  };
  handleToWorkingSelect = (event: { target: { name: string } }, newValue: string | null, values: Partial<S> | Pick<S, keyof S> | ((prevState: Readonly<S>, props: Readonly<Props>) => S | Pick<S, keyof S> | null) | null) => {
    this.setState({
      ...this.state,
      ...values,
      to_working_hours: newValue,
      isModalOpenTo: false
    });
  };

  handlephotoUpload = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: File | null) => void,) => {
    const files = event.target.files;

    if (!files) {
      return;
    }

    const fileValue = files[0];
    setFieldValue("photo", fileValue);
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (fileValue) {
      if (allowedFileTypes.includes(fileValue.type)) {
        const fileSize = fileValue.size / (1024 * 1024)
        if (fileSize <= 5) {
          const displayUrl = URL.createObjectURL(fileValue);
          this.setState({
            photo: fileValue,
            photoUrl: displayUrl,
          });
        } else {
          toast.error(configJSON.fileSizeError);
        }
      } else {
        toast.error(configJSON.fileSelected)
      }
    }
  };
  handleDocumentsUpload = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: (Documents | File)[]) => void, values: Partial<S>) => {
    if (!event.target.files) {
      return;
    }
    let files = Array.from(event.target.files) as File[];
    const allowedMimeTypes = configJSON.supportedFileTypes
    let uniqueFiels = files.filter(
      (file) => !this.state.documentList.map(({ name }) => name).includes(file.name) && (allowedMimeTypes.includes(file.type))
    );
    if (uniqueFiels.length > 0) {
      setFieldValue("documents", [
        ...(values.documents ? values.documents : []),
        ...uniqueFiels,
      ]);
      this.handleMultiFileUpload(uniqueFiels);

    } else {
      toast.error(configJSON.fileErrorMsg)
    }

  };

  handleMultiFileUpload = (files: File[]) => {
    const fileNames = files.map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
    }));
    this.setState((prev) => ({
      documents: [...prev.documents, ...files],
      documentList: [...prev.documentList, ...fileNames],
    }));
  };
  handleRemoveFile = (item: Documents) => {
    const documents = this.state.documents.filter(
      (data) => data.name !== item.name
    );
    const documentList = this.state.documentList.filter(
      (data) => data.name !== item.name
    );
    this.setState({
      documents,
      documentList,
    });
  };
  handleRemoveImage = () => {
    this.setState({
      photoUrl: "",
    });
  }
  handleNext = (step: Pick<S, never> | null) => {
    if (this.state.activeStep < 3) {
      this.setState((prev) => ({
        ...prev,
        ...step,
        activeStep: prev.activeStep + 1,
      }));
    } else {
      this.setState(
        {
          ...step,
        },
        () => this.setCompanyProfile()
      );
    }
  };
  handleBack = () => {
    if (this.state.activeStep === 1) {
      this.props.navigation.navigate("MyProfile");
    } else {
      this.setState((prev) => ({
        ...prev,
        activeStep: prev.activeStep - 1,
      }));
    }

  };
  handleUserRedirection = () => {
    this.props.navigation.navigate("TermAndConditions");
  };
  getValidationSchema = () => {
    if (this.state.activeStep === 1) {
      return CompanyProfileSchema1;
    } else if (this.state.activeStep === 2) {
      return CompanyProfileSchema2;
    } else {
      return CompanyProfileSchema3;
    }
  };
  handleLoginRedirection = () => {
    this.props.navigation.navigate("EmailLogin");
  };
  handleResponseForCountryListData = (from: string, message: Message) => {
    if (this.getCountryCodeListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponseData, this.props.navigation);
      if (apiResponseData.data) {
        const codeData = apiResponseData.data.map((item: { id: string; name: string; alpha2: string; country_code: string }) => {
          const { country_code, id: CountryId, alpha2 } = item
          return { value: Number(CountryId), label: `${country_code} (${alpha2})` }
        });
        this.setState({
          countryCodeList: codeData
        })

      } else {
        handleApiError(apiResponseData.errors);
      }

    }
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(responseJson, this.props.navigation);

      if (apiRequestCallId && responseJson) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.getCompanyProfileApiCallId) {
          this.getUserResponse(responseJson);
        } else if (apiRequestCallId === this.saveCompanyApiCallId) {
          this.addCompanyProfileResponse(responseJson);
        } else if (apiRequestCallId === this.industriesApiCallId) {
          this.getIndustriResponse(responseJson);
        } else if (apiRequestCallId === this.typeOfBusinessApiCallId) {
          this.getBusinessTypesResponse(responseJson);
        }
      }
    }
    this.handleResponseForCountryListData(from, message)
  }

  getUserResponse = (response: { data: { attributes: { email: string, company_profile: { data: { attributes: Attributes, id: string } } } } }) => {
    if (response.data) {
      const userData = response.data.attributes.company_profile.data;
      if (userData) {
        isBusinessOwner(this.props.navigation, response.data);
        const { attributes, id: dataId } = userData;
        const defaultData = {
          company_id: dataId,
          name: handleUndefinedData(attributes.name, ""),
          owner_name: handleUndefinedData(attributes.owner_name, ""),
          owner_email: handleUndefinedData(attributes.owner_email, ""),
          phone_number: handleUndefinedData(attributes.phone_number, ""),
          country_code_and_flag_id: handleUndefinedData(attributes.country_code_and_flag_id, ""),
          industry_id: handleUndefinedData(attributes?.industry.data?.id && Number(attributes?.industry.data?.id), ""),
          business_type_id: handleUndefinedData(
            attributes?.business_type.data?.id && Number(attributes?.business_type.data?.id),
            ""
          ),
          employee_count: handleUndefinedData(attributes.employee_count, ""),
          creation_date: handleUndefinedData(attributes.creation_date, null),
          from_working_hours: attributes.from_working_hours,
          to_working_hours: attributes.to_working_hours,
          description: handleUndefinedData(attributes.description, ""),
          head_office: handleUndefinedData(attributes.head_office, ""),
          photoUrl: handleUndefinedData(attributes.photo, ""),
          ...(attributes.documents?.length
            ? { documentList: attributes.documents }
            : { documentList: [] }),
        };
        this.setState({ ...defaultData });
      }
      this.setState({
        isEdit: userData ? true : false,
        owner_email: response.data.attributes.email,
      });
      !!this.state.apiError && this.setState({ apiError: "" });
    }
  };

  addCompanyProfileResponse = (response: { data: object, errors: string[] }) => {
    if (response.data) {
      toast.success(configJSON.profileUpdatedSuccess);
      this.handleUserRedirection();
    } else {
      handleApiError(response.errors);
    }
  };

  getIndustriResponse = (response: {data:Data[]}) => {
    if (response.data) {
      const defaultItem: OptionType = { value: "", label: configJSON.industryPlaceholder, isDisabled: true }
      let data = response.data.map(
        (item: { attributes: { name: string; id: number } }) => ({
          label: item.attributes.name,
          value: item.attributes.id,
        })
      );
      data = sortArrayAlphabetically(data, 'label');
      data.unshift(defaultItem);
      this.setState({ industries: data });

      !!this.state.apiError && this.setState({ apiError: "" });
    }
  };
  getBusinessTypesResponse = (response: {data:Data[]}) => {
    if (response.data) {
      const defaultItem: OptionType = { value: "", label: configJSON.businessTypePlaceholder, isDisabled: true }
      let data = response.data.map(
        (item: { attributes: { name: string; id: number } }) => ({
          label: item.attributes.name,
          value: item.attributes.id,
        })
      );
      data = sortArrayAlphabetically(data, 'label');
      data.unshift(defaultItem);
      this.setState({ typeOfBusiness: data });
      !!this.state.apiError && this.setState({ apiError: "" });
    }
  };

  setCompanyProfile = () => {
    this.setState({ loading: true });
    const { isEdit, company_id } = this.state;
    let documentsPromise;

    if (isEdit) {
      documentsPromise = mergeFiles(this.state.documentList, this.state.documents);
    } else {
      documentsPromise = Promise.resolve(this.state.documents);
    }

    documentsPromise.then((mergedDocuments) => {
      const attrs = {
        name: this.state.name,
        owner_name: this.state.owner_name,
        owner_email: this.state.owner_email,
        phone_number: this.state.phone_number,
        country_code_and_flag_id: this.state.country_code_and_flag_id,
        industry_id: this.state.industry_id,
        business_type_id: this.state.business_type_id,
        creation_date: this.state.creation_date,
        employee_count: this.state.employee_count,
        head_office: this.state.head_office,
        from_working_hours: this.state.from_working_hours,
        to_working_hours: this.state.to_working_hours,
        description: this.state.description,
        ...(this.state.photo ? { photo: this.state.photo } : this.state.photoUrl ? {} : { photo: "" }),
        documents: mergedDocuments,
      };

      this.saveCompanyApiCallId = apiCall({
        method: isEdit ? configJSON.putMethod : configJSON.apiMethodTypeAddDetail,
        body: convertFormData(attrs),
        endPoint: `${configJSON.companyProfileApiEndPoint}/${company_id}`,
        token: true,
      });
    });
  };

  // Customizable Area End
}
