import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, Avatar, Button } from "@material-ui/core";
import ViewCompanyProfileController, { Props, configJSON } from "./ViewCompanyProfileController.web"
import { Wrapper } from "../../../components/src/Wrapper";
import { colors } from "../../../components/src/utils/commonDesign"
import { commonInputField } from "../../../components/src/utils/commonInputStyle";
import moment from "moment";
import { isBO } from "../../../components/src/utils/commonFunctions";
import { editImage,uploadPicture } from "./assets"
import Loader from "../../../components/src/Loader";
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
    ...commonInputField,
    mainContainerView: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2rem"
    },
    leftContainer: {
        flexBasis: "48%"
    },
    rightContainer: {
        flexBasis: "48%"
    },
    fieldWrapper: {
        "& p": {
            background: "#fff",
            border: "1px solid #969492",
            borderRadius: "8px",
            padding: "15px 20px",
            fontSize: "1rem",
            color: colors.text,
            marginTop: "10px",
            height:"56px"
        }
    },
    wrapperGap: {
        marginBottom: "1.5rem"
    },
    workingHours: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    buttonSec: {
        display: "flex",
        justifyContent: "end"
    },
    btnInner: {
        display: "flex",
        alignItems: "center",
        border: "1px solid #969492",
        padding: "6px 0",
        borderRadius: 8,
        width: "135px",
        background: "#fff",
        cursor: "pointer",
        justifyContent: "space-evenly",
        "& button": {
            minWidth: "unset",
            padding: 0,
            color: colors.text,
            fontWeight: 600,
            textTransform: "capitalize",
            fontSize: "1rem",
            marginLeft: "-1rem"
        }
    },
    avatarImage: {
        width: "1.3rem",
        height: "1.3rem",
        borderRadius: "unset",
        marginRight: 8,
        cursor: "pointer",
        "& img": {
            width: "unset",
            height: "unset"
        }
    },
    companyProfile: {
        height:"380px",
        border: "1px solid #969492",
        padding: 10,
        borderRadius: 16,
        marginTop:"5.9rem",
        display:"flex",
        justifyContent:"center",
        "& img":{
          height:"100%",
          width:"100%",
          borderRadius: 16,
          objectFit:"none"
        },
        "& .img":{
          objectFit:"contain"
      }
    },
    description:{
        height:"162px !important"
    },
    phoneNumberGroup:{
        display:"flex",
        justifyContent:"space-between",
        "& p:nth-child(1)":{
            flexBasis:"12%"
        },
        "& p:nth-child(2)":{
            flexBasis:"85%"
        }
    }

});
// Customizable Area End

export class ViewCompanyProfile extends ViewCompanyProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    render() {
        const { classes } = this.props;
        const { profileDetail,loading } = this.state
        return (
            <>
            <Loader loading={loading} />
                <Wrapper navigation={this.props.navigation} divider backbtn={true} title={configJSON.companyProfileText} bellIcn={true} homeIcn={true} child={

                    <Box className={classes.mainContainerView}>
                        <Box className={classes.leftContainer}>
                            <Box className={`${classes.fieldWrapper} ${classes.wrapperGap}`}>
                                <InputLabel className={classes.inputLabel}>
                                    {configJSON.companyNameText}
                                </InputLabel>
                                <Typography data-test-id = "company_name" component="p">{profileDetail.attributes.name}</Typography>
                            </Box>
                            <Box className={`${classes.fieldWrapper} ${classes.wrapperGap}`}>
                                <InputLabel className={classes.inputLabel}>
                                    {configJSON.ownerNameText}
                                </InputLabel>
                                <Typography component="p">{profileDetail.attributes.owner_name}</Typography>
                            </Box>
                            <Box className={`${classes.fieldWrapper} ${classes.wrapperGap}`}>
                                <InputLabel className={classes.inputLabel}>
                                    {configJSON.ownerEmailId}
                                </InputLabel>
                                <Typography data-test-id = "email_text" component="p">{profileDetail.attributes.owner_email}</Typography>
                            </Box>
                            <Box className={`${classes.fieldWrapper} ${classes.wrapperGap}`}>
                                <InputLabel className={classes.inputLabel}>
                                    {configJSON.ownerPhoneNumber}
                                </InputLabel>
                                <Box className={classes.phoneNumberGroup}>
                                <Typography component="p">{profileDetail.attributes.country_code}</Typography>
                                <Typography component="p">{profileDetail.attributes.phone_number}</Typography>
                                </Box>
                            </Box>
                            <Box className={`${classes.fieldWrapper} ${classes.wrapperGap}`}>
                                <InputLabel className={classes.inputLabel}>
                                    {configJSON.industryText}
                                </InputLabel>
                                <Typography component="p">{profileDetail.attributes.industry.data === null ? configJSON.noSelectedIndustry : profileDetail.attributes.industry.data.attributes.name}</Typography>
                            </Box>
                            <Box className={`${classes.fieldWrapper} ${classes.wrapperGap}`}>
                                <InputLabel className={classes.inputLabel}>
                                    {configJSON.typeOfBusinessText}
                                </InputLabel>
                                <Typography component="p">{profileDetail.attributes.business_type.data === null ? configJSON.noSelectedBusiness : profileDetail.attributes.business_type.data.attributes.name}</Typography>
                            </Box>
                            <Box className={`${classes.fieldWrapper} ${classes.wrapperGap}`}>
                                <InputLabel className={classes.inputLabel}>
                                    {configJSON.creationData}
                                </InputLabel>
                                <Typography component="p">{moment(profileDetail.attributes.creation_date).format("DD/MM/YYYY")}</Typography>
                            </Box>
                            <Box className={`${classes.fieldWrapper} ${classes.wrapperGap}`}>
                                <InputLabel className={classes.inputLabel}>
                                    {configJSON.noOfEmployeeText}
                                </InputLabel>
                                <Typography component="p">{profileDetail.attributes.employee_count}</Typography>
                            </Box>
                            <Box className={`${classes.fieldWrapper} ${classes.wrapperGap}`}>
                                <InputLabel className={classes.inputLabel}>
                                    {configJSON.hoursText}
                                </InputLabel>
                                <Box className={classes.workingHours}>
                                    <Typography className={classes.leftContainer} component="p">{profileDetail.attributes.from_working_hours}</Typography>
                                    <Typography className={classes.leftContainer} component="p">{profileDetail.attributes.to_working_hours}</Typography>
                                </Box>
                            </Box>
                            <Box className={`${classes.fieldWrapper} ${classes.wrapperGap}`}>
                                <InputLabel className={classes.inputLabel}>
                                    {configJSON.headOfficeText}
                                </InputLabel>
                                <Typography component="p">{profileDetail.attributes.head_office}</Typography>
                            </Box>
                        </Box>
                        <Box className={classes.rightContainer}>
                            <Box className={classes.buttonSec}>
                              {isBO() &&   <Box className={classes.btnInner} data-test-id ="edit_button" onClick={() => this.handleEditClick(profileDetail)}>
                                    <Avatar
                                        src={editImage}
                                        alt="edit"
                                        className={classes.avatarImage}
                                    />
                                    <Button>{configJSON.editButton}</Button>
                                </Box>}
                            </Box>
                            <Box className={`${classes.companyProfile} ${classes.wrapperGap}`}>
                                <img className={`${profileDetail.attributes.photo && "img"}`} src={profileDetail.attributes.photo === null ? uploadPicture : profileDetail.attributes.photo} alt="companay" />
                            </Box>
                             <Box className={`${classes.fieldWrapper} ${classes.wrapperGap}`}>
                                <InputLabel className={classes.inputLabel}>
                                    {configJSON.textDescription}
                                </InputLabel>
                                <Typography className={classes.description} component="p">{profileDetail.attributes.description}</Typography>
                            </Box>
                        </Box>
                    </Box>
                } />


            </>
        );
    }
    // Customizable Area End
}
export default withStyles(styles)(ViewCompanyProfile);

