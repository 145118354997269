// Customizable Area Start
import React from "react";
import { Grid, Box, IconButton, Typography, InputLabel, TextField, Button, Checkbox, FormControlLabel, Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ChecklistFormController, { Props, configJSON } from "./ChecklistFormController.web";
import { Wrapper } from "../../../components/src/Wrapper";
import { colors } from "../../../components/src/utils/commonDesign";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { Close, CheckBoxOutlineBlank, RadioButtonUnchecked } from '@material-ui/icons';
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import { singleValueCss,commonInputField ,commonButton} from "../../../components/src/utils/commonInputStyle";
import Loader from "../../../components/src/Loader";
import ConfirmationModalWeb from "../../../components/src/commonComponents/ConfirmationModal.web";
// Customizable Area End


// Customizable Area Start
export const styles = {
    ...commonInputField,
    ...commonButton,
    mainContainer:{
        position:"relative" as const
    },
    addPanelItems: {
        display: "flex",
        padding: "5%",
        margin: "10%",
        flexDirection: "column" as const,
        borderRadius: "8px",
        border: "1px solid transparent",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
            border: `1px solid ${colors.secondaryColor2}`
        }
    },
    btnGroup:{
        paddingTop: 30,
        display: "flex",
        justifyContent:"center"
    },
    addPanel: {
        width: "85%",
        borderRadius: "16px",
        background: colors.secondaryColor1,
        opacity: 0.9,
        height:"100%",
        overflow:"auto"
    },
    addPanelItemTxt: {
        color: "#F1EEEA",
        textAlign: "center" as const,
        fontWeight: 500,
    },
    textfield: {
        "& .MuiInputBase-root": {
            borderRadius: "10px",
            backgroundColor: "#fff",
            height: "56px",
            "& .MuiInputBase-input": {
                fontSize: "16px",
                lineHeight: "21px",
                "& .Mui-disabled": {
                    color: "#020202",
                    backgroundColor: "#F5F5F5",
                    borderRadius: "10px",
                },
            },
        },
    },
    label: {
        margin: "10px 0px",
        color: colors.text,
        fontSize: "18px",
        fontWeight: 500,
    },
    titleLabel: {
        margin: "10px 0px",
        color: colors.text,
        fontSize: "14px",
        fontWeight: 600,
    },
    addOptionBtn: {
        background: colors.secondaryColor1,
        borderRadius: "8px",
        color: "#F1EEEA",
        fontSize: "16px",
        fontWeight: 500,
        textTransform: "capitalize" as const,
        "&:hover": {
            background: colors.secondaryColor1
        }
    },
    titleTxt: {
        fontSize: "20px",
        fontWeight: 600,
        color: colors.text,
    },
    queLabel: {
        fontSize: "18px",
        fontWeight: 500,
        color: colors.text,
    },
    attachment: {
        "& .containerWidth": {
            maxWidth: "100% !important",
            margin: `${0} !important`
        }
    },
    underLine:{
        borderBottom:"1px solid rgba(0, 0, 0, 0.40)"
    },
    flexDir:{
        flexDirection:"column" as const
    },
textColor: {
  "& input": {
    color: `${colors.text} !important`,
    "&::placeholder": {
      color: `${colors.text} !important`,
    },
  },
  "& .Mui-disabled": {
    color: `${colors.text} !important`,
  },
  "& .Mui-checked": {
    color: `${colors.secondaryColor1} !important`,
  },
},
panelSection:{
    display:"flex" ,
    justifyContent:"flex-end",
    paddingTop: 8,
    height: "85vh"
},
panelPos:{
    position:"fixed" as const,
    right:"20px",
    width:"200px",
    height:"85vh",
    overflow:"hidden",
    scrollBehavior:"smooth" as const
},

}
// Customizable Area End

export class ChecklistForm extends ChecklistFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { formData, branch_list,is_download, department_list, action, openViewMenu, isOpen, stateValue, loading, favorite, openDeleteModal, fromLibrary } = this.state

        const isValid = Boolean(formData.paragraphs_attributes.length || formData.single_answers_attributes.length || formData.checkboxes_attributes.length || formData.multiple_choices_attributes.length || formData.dropdowns_attributes.length || formData.linear_scales_attributes.length) && Boolean(formData.title && formData.branch_id && formData.department_id)
        const create = () => (
            <>
                <Box py={1}>
                    <InputLabel data-test-id="title_text1" className={classes.label}>{configJSON.title}</InputLabel>
                    <TextField
                        data-test-id="txtInputTitle"
                        variant="outlined"
                        placeholder="Enter Title"
                        value={formData.title}
                        onChange={(event: {target:{value:string}}) => this.setState({ formData: { ...this.state.formData, title: event.target.value } })}
                        fullWidth
                        className={classes.textfield}
                    />
                </Box>
                {formData.paragraphs_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            data-test-id="paragraphs_attributes-input"
                            key={`paragraphs_attributes-${index}`}
                            value={item.text}
                            variant="outlined"
                            fullWidth
                            placeholder={`${configJSON.paragraph}`}
                            onChange={event => this.handleParaChange(event, index, "paragraphs_attributes")}
                            className={classes.textfield}
                            InputProps={{
                                endAdornment: <ActionButton
                                    deleteVisible
                                    isOpen={isOpen[`paragraphs_attributes-${index}`]}
                                    handleDelete={() => { this.handleFieldDelete(index, "paragraphs_attributes") }}
                                    toggleDropdown={() => this.toggleDropdown("paragraphs_attributes", index)}
                                    duplicate
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "paragraphs_attributes")}
                                />
                            }}
                        />
                    </Box>
                ))}
                {formData.single_answers_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            data-test-id="single_answers_attributes-input"
                            key={`single_answers_attributes-${index}`}
                            variant="outlined"
                            placeholder={`${configJSON.singleAnswer}`}
                            onChange={event => this.handleParaChange(event, index, "single_answers_attributes")}
                            value={item.text}
                            fullWidth
                            className={classes.textfield}
                            InputProps={{
                                endAdornment: <ActionButton
                                    deleteVisible
                                    handleDelete={() => { this.handleFieldDelete(index, "single_answers_attributes") }}
                                    isOpen={isOpen[`single_answers_attributes-${index}`]}
                                    duplicate
                                    toggleDropdown={() => this.toggleDropdown("single_answers_attributes", index)}
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "single_answers_attributes")}
                                />
                            }}
                        />
                    </Box>
                ))}
                {formData.checkboxes_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            data-test-id="checkboxes_attributes-input"
                            variant="outlined"
                            key={`checkboxes_attributes-${index}`}
                            onChange={event => this.handleOptionFieldTitleTextChange(event, index, "checkboxes_attributes")}
                            fullWidth
                            value={item.text}
                            className={classes.textfield}
                            placeholder={`${configJSON.checkbox}`}
                            InputProps={{
                                endAdornment: <ActionButton
                                    deleteVisible
                                    toggleDropdown={() => this.toggleDropdown("checkboxes_attributes", index)}
                                    handleDelete={() => { this.handleFieldDelete(index, "checkboxes_attributes") }}
                                    isOpen={isOpen[`checkboxes_attributes-${index}`]}
                                    duplicate
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "checkboxes_attributes")}
                                />
                            }}
                        />
                        {item.options_attributes.map((item, optionIndex) => (
                            <Box pt={1} key={`checkboxes_attributes-${index}-${optionIndex}`}>
                                <TextField
                                    onChange={(event) => this.handleOptionFieldOptionTextChange(event, index, optionIndex, "checkboxes_attributes")}
                                    placeholder={`${configJSON.option} ${optionIndex + 1}`}
                                    value={item.option}
                                    InputProps={{
                                        startAdornment: <IconButton><CheckBoxOutlineBlank fontSize="small" /></IconButton> ,
                                        endAdornment: <IconButton data-test-id="delete_checkbox_option" onClick={() => this.deleteOption(index, optionIndex, "checkboxes_attributes")}><Close fontSize="small" /></IconButton>
                                    }}
                                    data-test-id="checkboxes_attributes-option"
                                    fullWidth
                                />
                            </Box>
                        ))}
                        <Box pt={2}>
                            <Button data-test-id="add_checkbox_option" size="large" onClick={() => this.addOption(index, "checkboxes_attributes")} className={classes.addOptionBtn}>{configJSON.addOption}</Button>
                        </Box>
                    </Box>
                ))}
                {formData.multiple_choices_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            data-test-id="multiple_choices_attributes-input"
                            key={`multiple_choices_attributes-${index}`}
                            variant="outlined"
                            placeholder={`${configJSON.multipleChoice}`}
                            value={item.text}
                            onChange={event => this.handleOptionFieldTitleTextChange(event, index, "multiple_choices_attributes")}
                            fullWidth
                            className={classes.textfield}
                            InputProps={{
                                endAdornment: <ActionButton
                                    deleteVisible
                                    handleDelete={() => { this.handleFieldDelete(index, "multiple_choices_attributes") }}
                                    isOpen={isOpen[`multiple_choices_attributes-${index}`]}
                                    toggleDropdown={() => this.toggleDropdown("multiple_choices_attributes", index)}
                                    duplicate
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "multiple_choices_attributes")}
                                />
                            }}
                        />
                        {item.options_attributes.map((item, optionIndex) => (
                            <Box pt={1} key={`multiple_choices_attributes-${index}-${optionIndex}`}>
                                <TextField
                                    fullWidth
                                    value={item.option}
                                    placeholder={`${configJSON.option} ${optionIndex + 1}`}
                                    data-test-id="multiple_choices_attributes-option"
                                    onChange={(event) => this.handleOptionFieldOptionTextChange(event, index, optionIndex, "multiple_choices_attributes")}
                                    InputProps={{
                                        endAdornment: <IconButton data-test-id="delete_multichoice_option" onClick={() => this.deleteOption(index, optionIndex, "multiple_choices_attributes")}><Close fontSize="small" /></IconButton>,
                                        startAdornment: <IconButton><RadioButtonUnchecked fontSize="small" /></IconButton>
                                    }}
                                />
                            </Box>
                        ))}
                        <Box pt={2}>
                            <Button data-test-id="add_multichoice_option" className={classes.addOptionBtn} size="large" onClick={() => this.addOption(index, "multiple_choices_attributes")}>{configJSON.addOption}</Button>
                        </Box>
                    </Box>
                ))}
                {formData.dropdowns_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            onChange={event => this.handleOptionFieldTitleTextChange(event, index, "dropdowns_attributes")}
                            data-test-id="dropdowns_attributes-input"
                            variant="outlined"
                            value={item.text}
                            fullWidth
                            key={`dropdowns_attributes-${index}`}
                            placeholder={`${configJSON.dropdown}`}
                            className={classes.textfield}
                            InputProps={{
                                endAdornment: <ActionButton
                                    toggleDropdown={() => this.toggleDropdown("dropdowns_attributes", index)}
                                    handleDelete={() => { this.handleFieldDelete(index, "dropdowns_attributes") }}
                                    isOpen={isOpen[`dropdowns_attributes-${index}`]}
                                    deleteVisible
                                    duplicate
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "dropdowns_attributes")}
                                />
                            }}
                        />
                        {item.options_attributes.map((item, optionIndex) => (
                            <Box pt={1} key={`dropdowns_attributes-${index}-${optionIndex}`}>
                                <TextField
                                    onChange={(event) => this.handleOptionFieldOptionTextChange(event, index, optionIndex, "dropdowns_attributes")}
                                    placeholder={`${configJSON.dropdown} ${optionIndex + 1}`}
                                    data-test-id="dropdowns_attributes-option"
                                    fullWidth
                                    value={item.option}
                                    InputProps={{
                                        startAdornment: <IconButton><Typography>{`${optionIndex + 1}`}</Typography></IconButton>,
                                        endAdornment: <IconButton data-test-id="delete_dropdown_option" onClick={() => this.deleteOption(index, optionIndex, "dropdowns_attributes")}><Close fontSize="small" /></IconButton>
                                    }}
                                />
                            </Box>
                        ))}
                        <Box pt={2}>
                            <Button data-test-id="add_dropdown_option" className={classes.addOptionBtn} size="large" onClick={() => this.addOption(index, "dropdowns_attributes")}>{configJSON.addOption}</Button>
                        </Box>
                    </Box>
                ))}
                {formData.linear_scales_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            className={classes.textfield}
                            key={`linear_scales_attributes-${index}`}
                            data-test-id="linear_scales_attributes-input"
                            variant="outlined"
                            onChange={event => this.handleOptionFieldTitleTextChange(event, index, "linear_scales_attributes")}
                            fullWidth
                            value={item.text}
                            placeholder={`${configJSON.linearScale}`}
                            InputProps={{
                                endAdornment: <ActionButton
                                    deleteVisible
                                    isOpen={isOpen[`linear_scales_attributes-${index}`]}
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "linear_scales_attributes")}
                                    toggleDropdown={() => this.toggleDropdown("linear_scales_attributes", index)}
                                    handleDelete={() => { this.handleFieldDelete(index, "linear_scales_attributes") }}
                                    duplicate
                                />
                            }}
                        />
                        <Box pt={1}>
                            <Grid container>
                                <Grid item xs={2}>
                                    <DropdownSearch
                                        options={configJSON.scaleLowestItems}
                                        value={item.lowest_value}
                                        onChangeValue={(event: OptionType) => this.handleScaleChange(event, index, "lowest_value")}
                                        id="department_id"
                                        isSearchable={true}
                                        placeholder={configJSON.lowest}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Box textAlign={"center"} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}><Typography>to</Typography></Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <DropdownSearch
                                        options={configJSON.scaleHighestItems}
                                        value={item.highest_value}
                                        onChangeValue={(event: OptionType) => this.handleScaleChange(event, index, "highest_value")}
                                        id="department_id"
                                        isSearchable={true}
                                        placeholder={configJSON.highest}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box pt={1}>
                            <TextField
                                placeholder={`${configJSON.scaleLabel}`}
                                value={item.lowest}
                                data-test-id="scale_initial_title"
                                onChange={event => this.handleLinearFieldChange(event, index, "linear_scales_attributes", "lowest")}
                                fullWidth
                                InputProps={{
                                    startAdornment: <IconButton><Typography>1.</Typography></IconButton>,
                                }}
                            />
                        </Box>
                        <Box pt={1}>
                            <TextField
                                placeholder={`${configJSON.scaleLabel}`}
                                value={item.highest}
                                data-test-id="scale_final_title"
                                onChange={event => this.handleLinearFieldChange(event, index, "linear_scales_attributes", "highest")}
                                fullWidth
                                InputProps={{
                                    startAdornment: <IconButton><Typography>2.</Typography></IconButton>,
                                }}
                            />
                        </Box>
                    </Box>
                ))}
            </>
        )

        const view = () => (
            <>
                <Box py={2}>
                    <Typography data-test-id = "title_text" className={classes.titleTxt}>{formData.title}</Typography>
                </Box>
                <Box py={2}>
                    <Typography className={classes.queLabel}>{configJSON.filledBy}</Typography>
                    <Box pt={1}>
                        <TextField
                            className={classes.textfield}
                            data-test-id="filled_by"
                            variant="outlined"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            value={fromLibrary ? stateValue.filled_by : stateValue.creator.name}
                        />
                    </Box>
                </Box>
                {stateValue.paragraph_answers_attributes?.map((item, index) => (
                    <Box py={2} key={item.id}>
                        <Typography className={classes.queLabel}>{item.text}</Typography>
                        <Box pt={1}>
                            <TextField
                                fullWidth
                                multiline
                                placeholder={!is_download && configJSON.paragraphAnswer}
                                data-test-id="paragraph_answers_attributes"
                                InputProps={{ readOnly: configJSON.view === action }}
                                disabled={configJSON.view === action}
                                onChange={(event) => this.handleInputChange("paragraph_answers_attributes", index, event.target.value)}
                                value={item.value}
                            />
                        </Box>
                    </Box>
                ))}
                {stateValue.single_answer_answers_attributes?.map((item, index) => (
                    <Box py={2} key={item.id}>
                        <Typography className={classes.queLabel}>{item.text}</Typography>
                        <Box pt={1}>
                            <TextField
                                fullWidth
                                InputProps={{ readOnly: configJSON.view === action }}
                                disabled={configJSON.view === action}
                                data-test-id="single_answer_answers_attributes"
                                placeholder={!is_download && configJSON.singleAnswer}
                                onChange={(event) => this.handleInputChange("single_answer_answers_attributes", index, event.target.value)}
                                value={item.value}
                            />
                        </Box>
                    </Box>
                ))}
                {stateValue.checkbox_answers_attributes?.map((checkbox, index: number) => (
                    <Box py={2} key={checkbox.id}>
                        <Typography className={classes.queLabel}>{checkbox.text}</Typography>
                        <Box pt={1}>
                            {checkbox.options_attributes?.map((option) => (
                                <Box className={classes.underLine} key={option.id}>
                                    <FormControlLabel
                                        key={option.id}
                                        data-test-id="radio_form_control"
                                        control={
                                            <Checkbox
                                                data-test-id="checkbox_answers_attributes"
                                                checked={checkbox?.selected_options?.includes(option.id)}
                                                disabled={configJSON.view === action}
                                                onChange={() => this.handleInputChange("checkbox_answers_attributes", index, option.id, "multiple_checkbox")}
                                            />
                                        }
                                        label={option.option}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ))}
                {stateValue.multiple_choice_answers_attributes?.map((item, index: number) => (
                    <Box py={2} key={item.id}>
                        <Typography className={classes.queLabel}>{item.text}</Typography>
                        <Box pt={1}>
                            {item.options_attributes?.map((option) => (
                                <Box className={classes.underLine} key={option.id}>
                                    <FormControlLabel
                                        key={option.id}
                                        data-test-id="multiple_checkbox"
                                        control={
                                            <Radio
                                                disabled={configJSON.view === action}
                                                checked={item.selected_option === option.id}
                                                data-test-id="multiple_choice_answers_attributes"
                                                onChange={() => this.handleInputChange("multiple_choice_answers_attributes", index, option.id, "single_checkbox")}
                                            />
                                        }
                                        label={option.option}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ))}
                {stateValue.dropdown_answers_attributes?.map((item, index: number) => {
                    const options = item.options_attributes.map((option) => ({
                        label: option.option, value: option.id
                    }))
                    return (
                        <Box py={2} key={item.id}>
                            <Typography className={classes.queLabel}>{item.text}</Typography>
                            <Box maxWidth={"50%"}>
                                <DropdownSearch
                                    options={options}
                                    singleValueCss={(configJSON.view !== action || is_download) && singleValueCss}
                                    placeholder={configJSON.chooseOption}
                                    value={item.selected_option}
                                    onChangeValue={(event: { value: string; }) => this.handleInputChange("dropdown_answers_attributes", index, event.value, "single_checkbox")}
                                />
                            </Box>
                        </Box>
                    )
                })}
                {stateValue.linear_scale_answers_attributes?.map((item, index: number) => {
                    const linearOptions = Array.from({ length: (item.highest_value || 2) - (item.lowest_value || 0) + 1 }, (_, index) => item.lowest_value + index)
                    return (
                        <Box py={2} key={item.id}>
                            <Typography className={classes.queLabel}>{item.text}</Typography>
                            <Box display={"flex"} pt={1} alignItems={"flex-end"}>
                                <Typography>{item.lowest}</Typography>
                                <Box display={"flex"} px={1} ml={2}>
                                    {linearOptions?.map((option) => (
                                        <FormControlLabel
                                            data-test-id="linear_control"
                                            className={classes.flexDir}
                                            key={option}
                                            control={
                                                <Radio
                                                    data-test-id="linear_scale_answers_attributes"
                                                    checked={(item.value ? Number(item.value) : item.value) === option}
                                                    disabled={configJSON.view === action}
                                                    onChange={() => this.handleInputChange("linear_scale_answers_attributes", index, option)}
                                                />
                                            }
                                            label={option}
                                        />
                                    ))}
                                </Box>
                                <Typography>{item.highest}</Typography>
                            </Box>
                        </Box>
                    )
                })}
            </>
        )
        const shouldEdit = this.allowEditChecklist() ? (fromLibrary ? configJSON.editFormFields : configJSON.editFilledForm) : false;
        return (
            <React.Fragment>
                <Loader loading={loading} />
                <Wrapper
                    data-test-id="wrapper-checklist"
                    navigation={this.props.navigation}
                    backHandleClick={this.handleBackClick}
                    divider
                    backbtn={true}
                    title={this.checklistHeaderTitle()}
                    bellIcn={true}
                    homeIcn={true}
                    child={
                            <Grid container>
                                <Grid item xs={10} ref={this.pdfContentRef} className={is_download ? classes.textColor : ""}>
                                    <Grid container spacing={1}>
                                        <Grid item sm={3}>
                                            <DropdownSearch
                                                options={branch_list}
                                                value={Number(formData.branch_id)}
                                                onChangeValue={(event: OptionType) => this.handleSelectChange(event, "branch_id", { department_id: "" })}
                                                id="branch_id"
                                                isSearchable={true}
                                                placeholder={configJSON.branchPlaceholder}
                                                noOptionsMessage={configJSON.noBranchMessage}
                                                singleValueCss={(configJSON.view !== action || is_download) && singleValueCss}
                                                isDisabled={this.allowEditBranchDepart()}
                                            />
                                        </Grid>
                                        <Grid item sm={3}>
                                            <DropdownSearch
                                                options={department_list}
                                                value={formData.department_id}
                                                onChangeValue={(event: OptionType) => this.handleSelectChange(event, "department_id")}
                                                id="department_id"
                                                isSearchable={true}
                                                placeholder={configJSON.departSelect}
                                                noOptionsMessage={configJSON.noDepartmentMessage}
                                                isDisabled={this.allowEditBranchDepart()}
                                                singleValueCss={(configJSON.view !== action || is_download) && singleValueCss}
                                                />

                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            {[configJSON.create, configJSON.edit, configJSON.duplicate].includes(action) ? create() : view()}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box className={classes.panelSection}>
                                        {![configJSON.view, configJSON.fill].includes(action) ?
                                           <Box className={classes.panelPos}>
                                             <Box className={classes.addPanel}>
                                                {this.addChecklistPanel.map((item: { imgSrc: string, label: string }) => (
                                                    <Box className={classes.addPanelItems} data-test-id="addpanel-checklist" onClick={() => this.handleAddPanelClick(item.label)}>
                                                        <img src={item.imgSrc} alt={item.label} />
                                                        <Typography className={classes.addPanelItemTxt}>{item.label}</Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                           </Box>
                                            :
                                            <ActionButton
                                                data-test-id="actionbtn-checklist"
                                                isOpen={openViewMenu}
                                                toggleDropdown={this.toggleViewMenu}
                                                download
                                                handleDownload={this.handleDownloadPdf}
                                                mark_as_favourite={!!fromLibrary}
                                                duplicate={!!fromLibrary && this.allowEditChecklist()}
                                                handleFavourite={this.handleFavouriteClick}
                                                handleDuplicate={this.handleDuplicate}
                                                favourite={favorite}
                                                deleteVisible ={this.allowEditChecklist()}
                                                handleDelete={this.handleOpenDeleteModal}
                                                edit={shouldEdit }
                                                handleEdit={this.handelEditCheckList}
                                            />
                                        }
                                    </Box>
                                </Grid>
                                {Boolean(action !== configJSON.view) &&
                                    <Grid item xs={12}>
                                        <Box className={classes.btnGroup}>
                                            <Button data-test-id="cancel-checklist" size="large" variant="outlined" className={classes.secondaryBtn} onClick={this.handleCancelCheckList}>{configJSON.cancel}</Button>
                                            <Button data-test-id="create-checklist" disabled={!isValid} size="large" className={classes.primaryBtn} onClick={this.handleSubmitCheckList}>{configJSON.save}</Button>
                                        </Box>
                                    </Grid>
                                }
                            </Grid>
                    }
                />
                {openDeleteModal &&
                    <ConfirmationModalWeb
                        data-test-id="delete-checklist"
                        open={openDeleteModal}
                        onClose={this.handleCloseDeleteModal}
                        confirmDelete={this.handleDeleteClick}
                        title={configJSON.checklistDeleteTitle}
                        from={fromLibrary ? configJSON.fromLibrary : configJSON.fromLogs}
                    />}
            </React.Fragment>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(ChecklistForm);
// Customizable Area End




