
import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { Favorite } from '@material-ui/icons';
import FormController, { Props, configJSON } from "./FormController.web";
import { libraryIcon, form_Icon } from "./assets";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import NoTickets from "../../../components/src/commonComponents/NoTickets.web";
import Pagination from "../../../components/src/commonComponents/Pagination.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import Loader from "../../../components/src/Loader";
import { styles } from "./ProcedureListing.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
class Form extends FormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    FormListView = (classes: Record<string, string>) => (
        <Box  className={classes.listItem} data-test-id="pdf-content">
            <Box className={classes.tableHeader}>
                {this.state.header_list.map((item, index) => (
                    <Typography  key={index} component="p">
                        {item}
                    </Typography>
                ))}
            </Box>
            <Box className={this.state.formListData.length === 0 ? classes.listMain : ""}>
                {this.state.formListData.length > 0 ? (
                    this.state.formListData.map((item, index) => {
                        return (
                            <Box  className={classes.tableItem} key={item.attributes.id}>
                                <Box className={classes.imgContainer}>
                                    <img  src={form_Icon} alt="check_List_Bold_Icon" className={classes.templateImgListView}/>
                                </Box>
                                <Typography component="p" data-test-id="form_title">
                                    {item.attributes.title}
                                </Typography>
                                <Typography component="p"></Typography>
                                <Typography component="p">
                                    {this.getFormattedDate(item.attributes.created_at)}
                                </Typography>
                                <Box display={"flex"} >
                                    <ActionButton  
                                    handleDelete={this.handleOpenDeleteModal} 
                                    deleteVisible={this.props.allowEditDelete(item.attributes.creator?.id)}
                                    mark_as_favourite 
                                    handleFavourite={() => this.handleFavouriteClick(item.attributes.favorite)} 
                                    moreVert 
                                    isOpen={this.state.isOpen[item.attributes.id]} 
                                    toggleDropdown={() => this.toggleDropdown(item.attributes.id)} 
                                    view handleView={() => this.handleViewClick(item.attributes.id)} 
                                    favourite={item.attributes.favorite} data-test-id="activeButtonListTestId" 
                                    />
                                </Box>
                                {
                                    Boolean(item.attributes.favorite) &&
                                    <Box>
                                        <Box
                                            border={"1px solid #0000003b"}
                                            borderRadius={"50%"}
                                            width={"fit-content"}
                                            height={"fit-content"}
                                            padding={"6px"}>
                                            <Favorite className={classes.favouriteIcn} />
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        );
                    })
                ) : (
                    <NoTickets  imagePath={libraryIcon} title={configJSON.formNoDataTxt}/>
                )}
            </Box>
        </Box>
    )
    FormGridView = (classes: Record<string, string>) => (
        <Grid container spacing={2}>
            {(Boolean(this.state.formListData.length)) ?
                this.state.formListData.map(item => (
                    <Grid key={item.attributes.id} item lg={3} md={4} sm={6} xs={12}>
                        <Box className={classes.cardWrapper}>
                            <Box justifyContent={"flex-end"} display={"flex"}>
                                <ActionButton
                                    deleteVisible={this.props.allowEditDelete(item.attributes.creator?.id)}
                                    handleDelete={this.handleOpenDeleteModal}
                                    handleFavourite={() => this.handleFavouriteClick(item.attributes.favorite)}
                                    mark_as_favourite
                                    isOpen={this.state.isOpen[item.attributes.id]}
                                    view
                                    data-test-id="activeButtonTestId"
                                    toggleDropdown={() => this.toggleDropdown(item.attributes.id)}
                                    favourite={item.attributes.favorite}
                                    handleView={() => this.handleViewClick(item.attributes.id)}
                                />
                            </Box>
                            <Box className="cursor" onClick={() => this.handleViewClick(item.attributes.id)}>
                                <Box justifyContent={"center"} display={"flex"}>
                                    <img src={form_Icon} className={classes.templateImg} alt={item.attributes.title} />
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"} p={1} alignItems={"center"}>
                                    <Box width={"90%"}>
                                        <Typography data-test-id="title" className={classes.procedureTitleTxt} noWrap>{item.attributes.title}</Typography>
                                        <Typography className={classes.dateTxt}>{configJSON.date}{this.getFormattedDate(item.attributes.created_at)}</Typography>
                                    </Box>
                                    {Boolean(item.attributes.favorite) && 
                                    <Favorite className={classes.favouriteIcn} />}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                )) :
                <Box className={classes.noTicketContainer} >
                    <NoTickets
                        title={configJSON.formNoDataTxt}
                        imagePath={libraryIcon}
                    />
                </Box>
            }
        </Grid>
    )



    FormViewer = (classes: Record<string, string>) => {
      const { searchQuery } = this.props;
      const { formListData, loading, meta } = this.state

        if (formListData.length > 0) {
          return (
            <>
                {this.props.gridView ? this.FormGridView(classes) : this.FormListView(classes)}
                <Box className={classes.paginationBox}>
                    <Pagination meta={meta} onPageChange={this.handlePageChange} />
                </Box>
            </>
          )
      } else if (formListData.length === 0 && !loading && Boolean(searchQuery)) {
          return (
              <Box className={classes.noTicketContainer}>
                  <Typography variant="h5">No search result found for <strong>{searchQuery}</strong>.</Typography>
              </Box>
          )
      } else if (formListData.length === 0 && !loading) {
          return (
              <Box className={classes.noTicketContainer} >
                  <NoTickets
                      title={configJSON.formNoDataTxt}
                      imagePath={libraryIcon}
                  />
              </Box>
          )
      } 

    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Loader loading={this.state.loading} />
                <Box pt={2} className={`${this.state.formListData.length > 0 ? "" : classes.noTicketOuterContainer}`}>
                    {this.FormViewer(classes)}
                </Box>
                {this.state.openDeleteModal &&
                    <ConfirmationModal
                        onClose={this.handleCloseDeleteModal}
                        open={this.state.openDeleteModal}
                        title={configJSON.formDeleteTitle}
                        confirmDelete={this.handleDeleteClick}
                        data-test-id="confirmModalTestId"
                        from={configJSON.checklistFrom}
                    />}
            </React.Fragment>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default withStyles(styles)(Form);
export { Form };
// Customizable Area End
