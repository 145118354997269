// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../dashboard/src/HomePageStyles.web";
import ProductInfoController, { Props } from "./ProductInfoController.web";
import { Wrapper } from "../../../components/src/Wrapper";
import { ProductInfoContent } from "../../../components/src/utils/constant";
export const configJSON = require("./config");

export class ProductInfo extends ProductInfoController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      // Required for all blocks
      <React.Fragment>
        <Wrapper backbtn title={configJSON.productInfoHeaderTitle} navigation={this.props.navigation} divider data-test-id="productInfo-wrapper-test" child={
          <Box className={`${classes.homeInnerBox} ${classes.ProductInfoInnerBox}`}>
            <div>
              <Box className="homeContainer productInfoContainer">
                {ProductInfoContent.map((item: any) => (
                  <Box className={`homeBoxCard productInfoBoxCard`} onClick={() => this.handleProductInfoNavigation(item.path)} key={item.name}>
                    <Box>
                      <div className="homeInnerBoxCard productInnerBoxCard">
                        <div className="imgContainer">
                          {item.photo && <img src={item.photo} alt="Image" />}
                        </div>
                        <Typography variant="h4" className={"titleName"}>
                          {item.name}
                        </Typography>
                      </div>
                    </Box>
                  </Box>
                ))}
              </Box>
            </div>
          </Box>
        }
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ProductInfo);
// Customizable Area End
