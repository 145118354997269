Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"

exports.viewNotificationBtn = "View all Notifications"
exports.noListMessage = "No notifications to show"
exports.headerTitle = "Notifications";
exports.markAllAsRead = "Mark all as read";
exports.clickToView = "Click here to view";
exports.isRead = "/is_read";

exports.getMethod = "GET";
exports.putMethod = "PUT";

exports.getNotificationEndpoint = "bx_block_push_notifications/push_notifications";
exports.notificationList = {
  notification: [
    {
      id: 48,
      title: "Comment is added successfully",
      created_at: "2024-05-30T06:16:49.727Z",
      remarks: "Comment has been added on Ticket (['TIC-1f61971a'])",
      is_read: false,
      link: "EditTask/123",
      creator: {
        id: 136,
        email: "kishan117@yopmail.com",
        name: "Kishan",
        designation: "Business Owner",
        photo: "https://minio.b284821.stage.eastus.az.svc.builder.ai/sbucket/whmbjshb9ivolxsfsyyo31vwiij9"
      }
    },
    {
      id: 46,
      title: "Comment is added successfully",
      created_at: "2024-05-30T04:48:13.062Z",
      remarks: "Comment has been added on Ticket (['TIC-1f61971a'])",
      is_read: false,
      link: null,
      creator: {
        id: 136,
        email: "kishan117@yopmail.com",
        name: "Kishan",
        designation: "Business Owner",
        photo: "https://minio.b284821.stage.eastus.az.svc.builder.ai/sbucket/whmbjshb9ivolxsfsyyo31vwiij9"
      }
    },
    {
      id: 34,
      title: "Comment is added successfully",
      created_at: "2024-05-29T10:22:08.752Z",
      remarks: "Comment has been added on Ticket (['TIC-1f61971a'])",
      is_read: false,
      link: null,
      creator: {
        id: 136,
        email: "kishan117@yopmail.com",
        name: "Kishan",
        designation: "Business Owner",
        photo: "https://minio.b284821.stage.eastus.az.svc.builder.ai/sbucket/whmbjshb9ivolxsfsyyo31vwiij9"
      }
    },
    {
      id: 33,
      title: "Comment is added successfully",
      created_at: "2024-05-29T10:21:48.305Z",
      remarks: "Comment has been added on Ticket (['TIC-1f61971a'])",
      is_read: false,
      link: null,
      creator: {
        id: 136,
        email: "kishan117@yopmail.com",
        name: "Kishan",
        designation: "Business Owner",
        photo: "https://minio.b284821.stage.eastus.az.svc.builder.ai/sbucket/whmbjshb9ivolxsfsyyo31vwiij9"
      }
    },
    {
      id: 31,
      title: "Ticket reopen successfully",
      created_at: "2024-05-29T10:19:45.048Z",
      remarks: "Hello  , Your ticket has been reopen",
      is_read: false,
      link: null,
      creator: {
        id: 136,
        email: "kishan117@yopmail.com",
        name: "Kishan",
        designation: "Business Owner",
        photo: "https://minio.b284821.stage.eastus.az.svc.builder.ai/sbucket/whmbjshb9ivolxsfsyyo31vwiij9"
      }
    },
    {
      id: 27,
      title: "Comment is added successfully",
      created_at: "2024-05-29T10:17:08.357Z",
      remarks: "Comment has been added on Ticket (['TIC-1f61971a'])",
      is_read: false,
      link: null,
      creator: {
        id: 136,
        email: "kishan117@yopmail.com",
        name: "Kishan",
        designation: "Business Owner",
        photo: "https://minio.b284821.stage.eastus.az.svc.builder.ai/sbucket/whmbjshb9ivolxsfsyyo31vwiij9"
      }
    },
    {
      id: 26,
      title: "Ticket completed successfully",
      created_at: "2024-05-29T10:09:44.113Z",
      remarks: "Hello  , Your ticket has been completed",
      is_read: false,
      link: null,
      creator: {
        id: 136,
        email: "kishan117@yopmail.com",
        name: "Kishan",
        designation: "Business Owner",
        photo: "https://minio.b284821.stage.eastus.az.svc.builder.ai/sbucket/whmbjshb9ivolxsfsyyo31vwiij9"
      }
    }
  ],
  read_count: 34,
  unread_count: 7,
  meta: {
    total_pages: 7,
    total_count: 70,
    current_page: 1,
    next_page: 2,
    previous_page: 0
  }
}
// Customizable Area End