/** @format */

import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import LogoutController, { Props } from "./LogoutController.web"
export const configJSON = require("./config");
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
// Customizable Area End

// Customizable Area Start

export const styles = {
  sideBarCompany: {
    display: 'flex',
  },
};

// Customizable Area End

export class Logout extends LogoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, handleClose } = this.props;
    return (
      <>
        <ConfirmationModal
          open={open}
          onClose={handleClose}
          confirmDelete={this.handleLogout}
          title="Logout"
          logout={true}
        />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(Logout);
// Customizable Area End

