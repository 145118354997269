// Customizable Area Start
import React from "react";
import { Grid, Tabs, Tab, Typography, Button, Box, Menu, MenuItem, IconButton } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { ExpandMoreRounded, DateRange } from '@material-ui/icons';
import LibraryController, { Props, configJSON } from "./LibraryController.web";
import { Wrapper } from "../../../components/src/Wrapper";
import { colors } from "../../../components/src/utils/commonDesign";
import { imgFilter, imgGridList, imgListGrid } from "./assets";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ProductTemplateListing from "./ProductTemplateListing.web";
import MenuTemplateListing from "./MenuTemplateListing.web";
import MiscellaneousDocListing from "./MiscellaneousDocListing.web";
import ProcedureListing from "./ProcedureListing.web";
import Checklist from "./Checklist.web";
import Forms from "./Form.web";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { isBO, isManager } from "../../../components/src/utils/commonFunctions";
// Customizable Area End


// Customizable Area Start

export const styles = () => createStyles({
    tabHeading: {
        color: colors.text,
        fontSize: "18px",
        fontWeight: 700,
        textTransform: "capitalize",
        "& .iconDisplay": {
            display: "none"
        }
    },
    tabBtn: {
        minWidth: "auto",
        marginRight: "25px",
        "&.borderBottom": {
            borderBottom: `2px solid ${colors.secondaryColor2}`
        }
    },
    arrowIcon: {
        paddingRight: "0px",
    },
    filterBtn: {
        borderRadius: "8px",
        background: "#FFF",
        "&:hover": {
            background: "#FFF",
        }
    },
    filterBtnTxt: {
        color: colors.text,
        fontWeight: 600,
        textTransform: "capitalize",
    },
    inputField: {
        margin: 0,
        "& .MuiInputBase-root": {
            borderRadius: "8px",
            "& .MuiInputBase-input": {
                fontSize: "16px",
                lineHeight: "21px",
            },
        },
        "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.secondaryColor2,
            },
        },
    },
    dateField: {
        "& .MuiInputBase-root": {
            border: `1px solid #0000003b`,
            padding: "0 10px",
        },
        "& .Mui-focused": {
            borderColor: colors.secondaryColor2,
        },
    },
    dateRangeTxt: {
        fontSize: "14px",
        fontWeight: 500,
    },
    filterDayItem: {
        border: `1px solid #0000003b`,
        width: "100%",
        borderRadius: "8px",
        paddingBlock: "3px",
        textAlign: "center",
        "&:hover": {
            border: `1px solid ${colors.secondaryColor2}`,
        }
    },
    filterDayItemHighlight: {
        border: `1px solid ${colors.secondaryColor2}`,
    },
    filterTxt: {
        fontSize: "14px",
    },
    filterTxtHighlight: {
        fontWeight: 600,
    },
    menuItems: {
        "& .MuiMenu-paper": {
            marginLeft: 30,
            marginTop: 7,
            borderRadius: "8px"
        }
    },
    imgCursor: {
        cursor: "pointer"
    },
    feedbackBtn: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        width:"24%",
        "& p": {
            marginLeft: 6,
            textTransform: "unset"
        },
        "&.clearAll":{
            width:"99%"
        },
        "& button": {
            borderRadius: "unset",
            textTransform: "unset",
            color: "#4196F0",
            padding: 0,
            borderBottom: "1px solid #4196F0",
            fontSize: "1rem",
            background:"transparent",
            "&:disabled": {
                borderBottom: "1px solid #4196F080",
                color: "#4196F080",
            },
            "&:hover": {
                background:"transparent",
            },
        },
    },
    dropdownBox:{
        display:"flex",
        width:"75%",
        "& .dropdownMargin":{
            marginRight:"20px"
        }
    }
})
// Customizable Area End

export class Library extends LibraryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes, navigation } = this.props;
        const { value, openMenu, gridView, date, searchQuery, menuAnchorEl, formData, branch_list, department_list } = this.state
        return (
            <React.Fragment>
                <Wrapper
                    data-test-id="wrapper-comp"
                    navigation={this.props.navigation}
                    backHandleClick={this.handleBackClick}
                    divider
                    backbtn={true}
                    title={configJSON.libraryTitle}
                    bellIcn={true}
                    homeIcn={true}
                    search
                    querySearch={this.state.searchQuery}
                    searchHandleClick={this.handleSearch}
                    child={
                        <>
                            <Grid container spacing={2}>
                                <Grid item sm={9}>
                                    <Tabs data-test-id="tabs" value={value} onChange={this.handleTabChange} >
                                        {configJSON.tablist.map((label: string, index: number) => (
                                            <Tab data-test-id="tab_label" id={label} className={`${classes.tabBtn} ${index === 1 && value === 1.1 ? 'Mui-selected borderBottom' : ''}`} label={<Typography className={classes.tabHeading}>
                                                {value === 1.1 && index === 1 ? "Menu Templates" : label}
                                                {label === "Product Info" && <IconButton
                                                    aria-label="Product Info"
                                                    aria-controls="Product Info"
                                                    aria-haspopup="true"
                                                    data-test-id="icon_button"
                                                    onClick={this.handleTabMenuOpen}
                                                    className={classes.arrowIcon}                                                    >
                                                    <ExpandMoreIcon />
                                                </IconButton>}
                                            </Typography>} />
                                        ))}
                                    </Tabs>
                                    <Menu
                                        id="Product Info"
                                        anchorEl={menuAnchorEl}
                                        open={Boolean(menuAnchorEl)}
                                        onClose={this.handleTabMenuClose}
                                        getContentAnchorEl={null}
                                        className={classes.menuItems}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        transformOrigin={{ vertical: "top", horizontal: "right" }}>
                                        <MenuItem onClick={() => this.handleProductMenu(1)}>{configJSON.productTemplateText}</MenuItem>
                                        <MenuItem data-test-id="menu_template" onClick={() => this.handleProductMenu(1.1)}>{configJSON.menuTemplateText}</MenuItem>
                                    </Menu>
                                </Grid>
                                <Grid item sm={2}>
                                    <Button data-test-id="menu-filter" fullWidth variant="outlined" size="large" className={classes.filterBtn} onClick={this.handleMenuOpen}>
                                        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                                            <img src={imgFilter} alt="filter-icon" />
                                            <Typography noWrap className={classes.filterBtnTxt}>{configJSON.filterByData}</Typography>
                                            <ExpandMoreRounded fontSize="small" />
                                        </Box>
                                    </Button>
                                    <Menu
                                        anchorEl={openMenu}
                                        open={Boolean(openMenu)}
                                        keepMounted
                                        onClose={this.handleMenuClose}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <MenuItem>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    data-test-id="from-date"
                                                    variant="inline"
                                                    margin="dense"
                                                    className={`${classes.inputField} ${classes.dateField}`}
                                                    InputProps={{
                                                        endAdornment: <DateRange />,
                                                        disableUnderline: true,
                                                    }}
                                                    autoOk
                                                    name="creation_date"
                                                    value={date.from_date}
                                                    format="MM/dd/yyyy"
                                                    allowKeyboardControl={false}
                                                    placeholder={"From"}
                                                    onChange={(e) => this.handleDateChange(e, "from_date")}
                                                    disableFuture={true}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </MenuItem>
                                        <MenuItem>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    data-test-id="to-date"
                                                    variant="inline"
                                                    margin="dense"
                                                    className={`${classes.inputField} ${classes.dateField}`}
                                                    InputProps={{
                                                        endAdornment: <DateRange />,
                                                        disableUnderline: true,
                                                    }}
                                                    autoOk
                                                    name="creation_date"
                                                    value={date.to_date}
                                                    format="MM/dd/yyyy"
                                                    allowKeyboardControl={false}
                                                    placeholder={"To"}
                                                    onChange={(e) => this.handleDateChange(e, "to_date")}
                                                    disableFuture={true}
                                                    minDate={date.from_date}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </MenuItem>
                                        {configJSON.filterMenuList.map((item: string) => (
                                            <MenuItem>
                                                <Box data-test-id={item} className={`${classes.filterDayItem} ${item === date.time_range && classes.filterDayItemHighlight}`} onClick={() => this.handleDayChange(item)}><Typography className={`${classes.filterTxt} ${item === date.time_range && classes.filterTxtHighlight}`}>{item}</Typography></Box>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>
                                <Grid item sm={1}>
                                    <Box data-test-id="toggle-view" display={"flex"} justifyContent={"space-around"} alignItems={"center"} width={"100%"} onClick={this.toggleView}>
                                        {gridView ? <img src={imgGridList} alt="grid-icon" className={classes.imgCursor} /> : <img src={imgListGrid} alt="list-icon" className={classes.imgCursor} />}
                                    </Box>
                                </Grid>
                            </Grid>
                             <Grid container spacing={2}>
                             {(isBO() || isManager()) && <Box className={classes.dropdownBox}>
                                    <Grid item sm={3} className="dropdownMargin">
                                        <DropdownSearch
                                            options={branch_list}
                                            value={formData.branch_id}
                                            onChangeValue={(event: OptionType) => this.handleSelectChange(event, "branch_id", { department_id: "" })}
                                            id="branch_id"
                                            isSearchable={true}
                                            placeholder={configJSON.branchPlaceholder}
                                            noOptionsMessage={configJSON.noBranchMessage}
                                        />
                                    </Grid>
                                    <Grid item sm={3}>
                                        <DropdownSearch
                                            options={department_list}
                                            value={formData.department_id}
                                            onChangeValue={(event: OptionType) => this.handleSelectChange(event, "department_id")}
                                            id="department_id"
                                            isSearchable={true}
                                            placeholder={configJSON.departSelect}
                                            noOptionsMessage={configJSON.noDepartmentMessage}
                                        />
                                    </Grid>
                                </Box>}
                                <Box onClick={this.handleClearAll} data-test-id="clearall_button" className={`${classes.feedbackBtn} ${(isBO() || isManager()) ? "" : "clearAll"}`}>
                                    <Button disabled={this.handleDisable()}>{configJSON.clearAll}</Button>
                                </Box>
                            </Grid>
                            <Box pt={2}>
                                {value === 0 && <ProcedureListing formData={this.state.formData} gridView={gridView} date={date} navigation={navigation} searchQuery={searchQuery} allowEditDelete={this.allowEditDelete} />}
                                {value === 1 && <ProductTemplateListing formData={this.state.formData} gridView={gridView} date={date} searchQuery={searchQuery} navigation={navigation} allowEditDelete={this.allowEditDelete} />}
                                {value === 1.1 && <MenuTemplateListing formData={this.state.formData} gridView={gridView} date={date} searchQuery={searchQuery} navigation={navigation} allowEditDelete={this.allowEditDelete} />}
                                {value === 2 && <Forms formData={this.state.formData} gridView={gridView} date={date} searchQuery={searchQuery} navigation={navigation} allowEditDelete={this.allowEditDelete} />}
                                {value === 3 && <Checklist formData={this.state.formData} gridView={gridView} date={date} searchQuery={searchQuery} navigation={navigation} allowEditDelete={this.allowEditDelete} />}
                                {value === 4 && <MiscellaneousDocListing formData={this.state.formData} gridView={gridView} date={date} searchQuery={searchQuery} navigation={navigation} allowEditDelete={this.allowEditDelete} />}
                            </Box>
                        </>
                    }
                />
            </React.Fragment>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(Library);
// Customizable Area End




