// Customizable Area Start
import React from "react";
import {
	Box,
	Button,
	Typography,
	Card,
	CardContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./TermAndConditionsStyles.web";
import LoginSignupBackgroundImgWeb from "../../../components/src/LoginSignupBackgroundImg.web";
import { downloadIcon } from "./assets"; 
import TermAndConditionsController, { Props } from "./TermAndConditionsController.web";
import Loader from "../../../components/src/Loader.web";
const logo = require("../../../components/src/qwipLogo.svg");
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class TermAndConditions extends TermAndConditionsController {
	constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
		const { classes } = this.props;
		const {title,description, disableBtn}=this.state
		
		return (
			// Required for all blocks
			<React.Fragment>
				<Loader loading={this.state.loading}/>
				<LoginSignupBackgroundImgWeb />
				<Card className={classes.card}>
						<CardContent className={classes.cardContent}>
							<Box>
								<img src={logo} alt="qwipLogo" style={{height:"45px"}} />
							</Box>
							<Typography variant="h4" className={classes.title} data-test-id="titleText">
								{title}
							</Typography>
							<Box className={classes.descriptionContainer}>
								<Box className={classes.innerContainer}>
									<Box className={classes.downloadIconBox}>
										<img
											src={downloadIcon}
											alt="downloadIcon"
											className={classes.downloadIcon}
											onClick={this.onDownloadTerm}
											data-test-id="download-btn"
										/>
									</Box>
									<Box data-test-id="terms-description" dangerouslySetInnerHTML={{__html:description}} />
								</Box>
							</Box>

							<Box className={classes.btnWrapper1}>
								<Button
								data-test-id="agree-btn"
									variant="contained"
									className={`${classes.btn} ${classes.primaryBtn}`}
									onClick={this.onAcceptTerm}
                  disabled={disableBtn}
								>
									Agree
								</Button>
							</Box>
						</CardContent>
					</Card>
			</React.Fragment>
		);
    // Customizable Area End
	}
}
// Customizable Area Start
export default withStyles(styles)(TermAndConditions);
// Customizable Area End
