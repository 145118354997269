import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {
  afterLoginRedirection,
  apiCall,
  passwordEncryption,
  getLoginToken,
  handleApiError,
  removeLoginToken,
  setLoginToken,
  setUser,
  getDeviceToken
} from "../../../components/src/utils/commonFunctions";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  token: string | null;
  password: string;
  email: string;
  enablePasswordField: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: string;
  imgPasswordInVisible: string;
  isAdmin: boolean;
  remember_me:boolean;
  device_id: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
interface LoginData {
  errors(errors: string[]): unknown;
  user: {
    data: {
      attributes: {
        email: string;
      }
    }
  },
  meta: {
    refresh_token: string;
    auth_token:string;
    remember_me:boolean
  }
}
// Customizable Area End
export default class EmailLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      loading: false,
      token: getLoginToken(),
      email: "",
      password: "",
      enablePasswordField: true,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      isAdmin: false,
      remember_me: false,
      device_id: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if (this.state.token) {
    this.props.navigation.navigate("HomePage");
    }
    const savedUseremail = Cookies.get('useremail');
    const savedIsREmember  = Cookies.get('isRemember');
    this.setState({
      email: savedUseremail || "",
      remember_me:Boolean(savedIsREmember),
      device_id: getDeviceToken()
    })
  }

  // Web Event Handling

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.loginApiCallId) {
          this.getLoginResponse(responseJson);
        }
      }
    }
  }

  handleLogin = (response: LoginData) => {
    if (response.meta.remember_me) {
      Cookies.set('rememberMe', response.meta.auth_token, { expires: 15 });
      Cookies.set('useremail', response.user.data.attributes.email, { expires: 15 });
      Cookies.set('isRemember', "true",{ expires: 15 });
    } else {
      Cookies.remove('rememberMe')
      Cookies.remove('useremail')
      Cookies.remove('isRemember')
    }
  }
  getLoginResponse = (response: LoginData) => {
    if (response.user?.data || response.meta) {
      setLoginToken(response.meta);
      setUser(response.user);
      toast.success(configJSON.loginSuccess)
      this.handleLogin(response)
      afterLoginRedirection(response.user);
    } else {
      removeLoginToken();
      Cookies.remove('rememberMe');
      Cookies.remove('useremail')
      handleApiError(response.errors);
    }
  };

  goToForgotPassword = () => {
    this.props.navigation.navigate("ForgotPassword")
  }

  doEmailLogIn = (eventData: { email: string; password: string; remember_me: boolean; }) => {
    this.setState({ loading: true });
    passwordEncryption(eventData.password).then((response) => 
      {
        const httpBody = {
          data: {
            type: "email_account",
            attributes: {
              email: eventData.email,
              password: response,
              remember_me:eventData.remember_me,
              device_id: this.state.device_id || getDeviceToken(),
            },
          },
        };
        this.loginApiCallId = apiCall({
          contentType: "application/json",
          method: "POST",
          body: JSON.stringify(httpBody),
          endPoint: "/bx_block_login/logins",
          token: false,
        });
      }
    )
  };
  // Customizable Area End
}