import React from "react";
// Customizable Area Start
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { DateRange, StarOutline, ArrowDropDown } from '@material-ui/icons';
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, TextField, Button, ButtonGroup, Grid, FormHelperText } from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';
import EditTicketController, { Props, RenderFormProps } from "./EditTicketController.web"
import { Formik } from 'formik';
import { CreateTicketSchema } from "../../../components/src/utils/validationSchema"
import { Wrapper } from "../../../components/src/Wrapper";
import { styles } from "./EditTicketStyles.web";
import { getStatusIcon, handleInvoiceErrors, handleInvoiceHelperText, loggedUserId } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import { CalenderIcon, CopyIcon, DeleteIcon, EditIcon, YellowsendIcon, attachIcon, closeIcon } from "../../../components/src/commonComponents/assets";
import RadioListWeb from "../../../components/src/commonComponents/fields/RadioList.web";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import TicketCommentsModal from "../../../components/src/commonComponents/modals/TicketCommentsModal.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import EditCommentModal from "../../../components/src/commonComponents/modals/EditCommentModal.web";
import SuccessModal from "../../../components/src/commonComponents/SuccessModal.web";
import { configJSON } from "./CreateTicketController.web";
import AttachFileInput  from "../../../components/src/commonComponents/AttachFileInput.web";

// Customizable Area End

export class EditTicket extends EditTicketController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { classes } = this.props;
    const {attachment, userId, isEdit, formData, ticketTypes, branches, departments, employees, priorites, document, comments, openStatus, apiError, attachError, loading, openCommentsList, openEdtiComment, opeDeleteComment, openSuccess, commentId, isEditTitle, isEditDescription, isEditDueDate } = this.state;
    const headerContent = (
      {values,
      errors,
      touched,
      handleBlur,
      handleChange,
      setFieldValue}:RenderFormProps
    ) => (
      <>
        <Box className={classes.headerContainer}>
          <Box>
            <Box className={classes.innerHeaderContainer}>
              <Typography data-test-id="ticket_details" variant="h4" className={classes.inputLabel2}>
                {configJSON.ticketDetails}
              </Typography>
              <Typography variant="body1" className={classes.ticketDetailsText}>
                <Typography data-test-id="ticket_number" className="ticketOpacity">{configJSON.ticketNumber} - {formData.ticket_number}</Typography>
              </Typography>
            </Box>
          </Box>
          <Box className={classes.btnContainer}>
            {(formData.status !== 'resolve' && this.checkCreator()) && <Box className={classes.innerBtnContainer}>
              {!isEdit && <Button
                data-test-id="enable-edit-btn"
                variant="outlined"
                className={`${classes.secondaryBtn} ${classes.secondaryBtn2}`}
                startIcon={<img src={EditIcon} alt="EditIcon" />}
                onClick={this.handleEnableEdit}
              >
                {configJSON.edit}
              </Button>}
              <Button
                data-test-id="duplicate-btn"
                variant="outlined"
                className={`${classes.secondaryBtn} ${classes.secondaryBtn2}`}
                startIcon={<img src={CopyIcon} alt="CopyIcon" />}
                onClick={this.duplicateTicket}
              >
                {configJSON.duplicate}
              </Button>
            </Box>}
            <Box>
              <Box>
                <ButtonGroup className={classes.btnGroup} variant="contained" color="primary" ref={this.anchorRef} aria-label="split button">
                  <Button
                    id="status-btn"
                    className={`${classes.primaryBtn} ${classes.primaryBtn2} ${formData.status}`}
                    onClick={this.toggleStatusModal}
                    endIcon={<ArrowDropDown />}
                  >
                    <img id="status-icon" src={getStatusIcon(formData.status)} className={classes.imageMargin} alt="Icon" /> {formData.status_name}
                  </Button>
                </ButtonGroup>
                <RadioListWeb
                  data-test-id="radio-status-list"
                  list={this.getAllowedStatusList()}
                  selected={formData.status}
                  open={openStatus}
                  anchorRef={this.anchorRef}
                  handleClose={this.closeStatusModal}
                  handleSelect={this.handleSelectStatus}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className={classes.innerHeaderContainer}>
            <Box className={classes.contentWithIcon}>
              <Typography variant="h4" className={classes.inputLabel2}>
                {configJSON.ticketTitle} {!isEditTitle && `- ${values.title}`}
              </Typography>
              {!this.checkDisabled() && <img src={EditIcon} data-test-id="toggle_title" alt="EditIcon" onClick={this.toggleTitle} />}
            </Box>
            {isEditTitle &&
              <Box className={`${classes.fieldWrapper} ${classes.editField}`}>
                <Grid alignItems="stretch" direction="row" container spacing={3} className="gridMargin">
                  <Grid sm={5} item>
                    <TextField
                      type="text"
                      variant="outlined"
                      placeholder={configJSON.placeholderText}
                      name={"title"}
                      value={values.title}
                      onChange={handleChange}
                      fullWidth
                      className={`${classes.inputField} textField`}
                      error={handleInvoiceErrors(
                        errors.title,
                        touched.title
                      )}
                      onBlur={handleBlur}
                      helperText={handleInvoiceHelperText(
                        errors.title,
                        touched.title
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>}
            <Typography variant="body1" className={`${classes.ticketDetailsText} ticketDetailFont`}>
              {configJSON.issueDate} - <Typography component="span" className="ticketOpacity">{moment(formData.issue_date).format("DD/MM/YYYY")}</Typography>
            </Typography>
          </Box>
          <Box>
            <Box className={classes.contentWithIcon}>
              <Typography variant="h4" className={`${classes.inputLabel2} textSize`}>
                {configJSON.dueDate} - <Typography component="span" className="ticketOpacity">{moment(values.due_date).format("DD/MM/YYYY")}</Typography>
              </Typography>
              {!this.checkDisabled() && <img src={CalenderIcon} alt="CalenderIcon" onClick={this.toggleDueDate} />}
            </Box>
            {isEditDueDate &&
              <Box className={`${classes.fieldWrapper} ${classes.editField}`}>
                <Grid alignItems="stretch" direction="row" container spacing={3} >
                  <Grid sm={5} item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        variant="inline"
                        margin="normal"
                        fullWidth
                        className={`${classes.inputField} ${classes.dateField}`}
                        InputProps={{
                          endAdornment: <DateRange />,
                          disableUnderline: true,
                        }}
                        name="due_date"
                        autoOk
                        format="MM/dd/yyyy"
                        value={values.due_date}
                        allowKeyboardControl={false}
                        onChange={setFieldValue.bind(null, "due_date")}
                        placeholder={configJSON.creationDate}
                        onBlur={handleBlur}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <FormHelperText className="error-text">
                  {touched.due_date && errors.due_date}
                </FormHelperText>
              </Box>
            }
          </Box>
        </Box>
        <Box className={classes.fieldWrapper}>
          <InputLabel className={`${classes.inputLabel2} ${classes.contentWithIcon}`}>
            {configJSON.ticketDescription} {!this.checkDisabled() && <img src={EditIcon} alt="EditIcon" onClick={this.toggleDescription} />}
          </InputLabel>
          {isEditDescription ?
            <TextField
              type="text"
              variant="outlined"
              placeholder={configJSON.placeholderText}
              name={"description"}
              value={values.description}
              onChange={handleChange}
              fullWidth
              className={classes.inputField}
              error={handleInvoiceErrors(
                errors.description,
                touched.description
              )}
              onBlur={handleBlur}
              helperText={handleInvoiceHelperText(
                errors.description,
                touched.description
              )}
            /> :
            <Typography variant="body1" className={classes.commentsDetails}>
              {values.description}
            </Typography>
          }
        </Box>
      </>
    )
    const fildesContent = (
      {values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue}:RenderFormProps
    ) => (
      <Grid alignItems="stretch" direction="row" container spacing={3} >
        <Grid sm={5} item>
          <Box className={classes.fieldWrapper}>
            <InputLabel required className={classes.inputLabel}>
              {configJSON.ticketTypeBtn}
            </InputLabel>
            <DropdownSearch
              options={ticketTypes}
              value={values.ticket_type_id}
              onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "ticket_type_id", { department_id: "", assignee_ids: [] })}
              onBlur={handleBlur}
              id="ticket_type_id"
              showError={handleInvoiceErrors(
                errors.ticket_type_id,
                touched.ticket_type_id
              )}
              errorText={handleInvoiceHelperText(
                errors.ticket_type_id,
                touched.ticket_type_id
              )}
              isSearchable={true}
              isDisabled={this.checkDisabled()}
              placeholder={configJSON.ticketTypePlaceholder}
              noOptionsMessage={configJSON.noTicketType}
            />
          </Box>
          <Box className={classes.fieldWrapper}>
            <InputLabel required className={classes.inputLabel} >
              {configJSON.branchText}
            </InputLabel>
            <DropdownSearch
              options={branches}
              value={values.branch_id}
              onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "branch_id", { department_id: "", assignee_ids: [] })}
              onBlur={handleBlur}
              id="branch_id"
              isSearchable={true}
              isDisabled={this.checkDisabled() || !values.ticket_type_id}
              placeholder={configJSON.branchPlaceholder}
              noOptionsMessage={configJSON.noBranchMessage}
              showError={handleInvoiceErrors(
                errors.branch_id,
                touched.branch_id
              )}
              errorText={handleInvoiceHelperText(
                errors.branch_id,
                touched.branch_id
              )}
            />
          </Box>
          <Box className={classes.fieldWrapper}>
            <InputLabel required className={classes.inputLabel}>
              {configJSON.departmentText}
            </InputLabel>
            <DropdownSearch
              options={departments}
              value={values.department_id}
              onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "department_id", { assignee_ids: [] })}
              onBlur={handleBlur}
              id="department_id"
              isSearchable={true}
              isDisabled={this.checkDisabled() || !values.branch_id}
              placeholder={configJSON.selectDepartment}
              noOptionsMessage={configJSON.noDepartmentMessage}
              showError={handleInvoiceErrors(
                errors.department_id,
                touched.department_id
              )}
              errorText={handleInvoiceHelperText(
                errors.department_id,
                touched.department_id
              )}
            />
          </Box>
          <Box className={classes.fieldWrapper}>
            <InputLabel required className={classes.inputLabel}>
              {configJSON.prorityText}
            </InputLabel>
            <DropdownSearch
              options={priorites}
              value={values.priority}
              onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "priority")}
              onBlur={handleBlur}
              id="priority"
              placeholder={configJSON.priorityPlaceholder}
              noOptionsMessage={configJSON.noPriorityMessage}
              showError={handleInvoiceErrors(
                errors.priority,
                touched.priority
              )}
              errorText={handleInvoiceHelperText(
                errors.priority,
                touched.priority
              )}
              isDisabled={this.checkDisabled()}
            />
          </Box>
          <Box className={classes.fieldWrapper}>
            <InputLabel required className={classes.inputLabel}>
              {configJSON.assignEmployee}
            </InputLabel>
            <DropdownSearch
              options={employees}
              value={values.assignee_ids}
              onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "assignee_ids")}
              onBlur={handleBlur}
              id="assignee_ids"
              isSearchable={true}
              isDisabled={this.checkDisabled() || !values.department_id}
              placeholder={configJSON.employeePlaceholder}
              noOptionsMessage={configJSON.noEmployeeMessage}
              showError={handleInvoiceErrors(
                errors.assignee_ids,
                touched.assignee_ids
              )}
              errorText={handleInvoiceHelperText(
                errors.assignee_ids,
                touched.assignee_ids
              )}
            />
          </Box>
        </Grid>
      </Grid>
    )
    return (
      <>
        <Loader loading={loading} />
        <Wrapper data-test-id="edit-ticket-wrapper-test" navigation={this.props.navigation} divider backbtn={true} title={"Edit Ticket"} bellIcn={true} homeIcn={true} child={
          <Box>
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              validateOnMount={true}
              initialValues={formData}
              validationSchema={CreateTicketSchema}
              onSubmit={this.editTicket}
            >
              {({ errors, touched, handleBlur, handleChange, setFieldValue, handleReset, handleSubmit, values }) => (
                <form className={classes.formContainer} onSubmit={handleSubmit}>
                  {
                    headerContent(
                      {values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue}
                    )
                  }
                  {
                    fildesContent(
                      {values,
                      touched,
                      errors,
                      handleBlur,
                      handleChange,
                      setFieldValue}
                    )
                  }

                  <Grid alignItems="stretch" direction="row" container spacing={3} >
                    <Grid sm={5} item>
                      <Box className={`${classes.fieldWrapper} ${classes.fileInput}`}>
                            <Box className={classes.fileUploadInput}>
                            <AttachFileInput
                                attachmentTitle={configJSON.attachmentTitle}
                                attachmentId="attachment"
                                templateAction={isEdit ? "" :"View"}
                                placeholderText={configJSON.attachmentPlaceholder}
                                accept={configJSON.acceptImageType}
                                attachment={attachment}
                                setFieldValue={setFieldValue}
                                isViewOnly={() => this.checkDisabled()}
                                handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(event, setFieldValue,"attachment","attachError") }}
                                clearAttachment={() => this.handleClearFile(setFieldValue,"attachment","attachError")}
                                attachmentError={attachError}
                            />
                            {!attachment?.url && !attachError && <Typography className={classes.maxSizeTicket} component="span">{configJSON.fileSizeText}</Typography>} 
                        </Box>
                          </Box>
                    </Grid>
                    {formData.status !== "resolve" &&
                      <Grid sm={12} item>
                        <Box className={classes.fieldWrapper}>
                          <InputLabel className={classes.inputLabel2}>
                            {configJSON.comment}
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            name={"comment"}
                            value={values.comment}
                            placeholder={configJSON.placeholderText}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            className={`${classes.inputField} ${classes.textAreaField} ${classes.fieldHeight}`}
                            InputProps={{
                              endAdornment: (
                                <div className={classes.textFiedContainer}>
                                  <div className={""}>
                                    <input
                                      accept="image/*"
                                      className={""}
                                      id="document"
                                      data-test-id="comment_docs"
                                      type="file"
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(event, setFieldValue,"document","apiError") }}
                                    />
                                    <label htmlFor="document">
                                      <img src={attachIcon} alt="attachIcon" className="attachIcon" />
                                    </label>
                                  </div>
                                  <img data-test-id="add_comment" onClick={this.addComment.bind(this, values, { resetForm: handleReset })} src={YellowsendIcon} alt="YellowsendIcon" id="yellow_icon" />
                                </div>
                              )
                            }}
                          />
                          {apiError && <Box className={classes.helperText}>{apiError}</Box>}
                          {document &&
                            <>
                              <Box className={`${classes.helperText} attachment`}>
                                <Typography data-test-id="doc_name" component="span" onClick={this.openImageInNewTab}>{document.name}</Typography>
                                <img className={`${classes.imageCursor} close-icon`} id="close-icon" src={closeIcon} alt="Close" onClick={this.handleClearFile.bind(null, setFieldValue, "document", "apiError")} />
                              </Box>
                            </>
                          }
                        </Box>
                      </Grid>
                    }
                  </Grid>
                  {formData.status === "resolve" &&
                    <>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel2}>
                          {configJSON.rating}
                        </InputLabel>
                        <Rating
                          data-test-id="ratings"
                          name="rating"
                          size="large"
                          value={values.rating}
                          onChange={(event, newValue) => {
                            this.handleRetingChange(newValue, setFieldValue);
                          }}
                          emptyIcon={<StarOutline fontSize="inherit" />}
                          disabled={!this.checkCreator()}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel2}>
                          {configJSON.feedback}
                        </InputLabel>
                        <TextField
                          type="text"
                          variant="outlined"
                          name={"feedback"}
                          value={values.feedback}
                          placeholder={configJSON.placeholderText}
                          onChange={handleChange}
                          fullWidth
                          multiline
                          className={`${classes.inputField} ${classes.textAreaField} ${classes.fieldHeight}`}
                          disabled={!this.checkCreator()}
                        />
                      </Box>
                    </>
                  }
                  {(comments && comments.length > 0) &&
                    (<div className={classes.commentsContainer}>
                      <Typography variant="h4" className={classes.inputLabel2}>
                        {configJSON.allComment}
                      </Typography>
                      <ul className="comment-ul">
                        {comments.slice(0, 2).map((item) => (
                          <li key={item.id}>
                            <Typography variant="body1" className={`${classes.commentsDetails} comment`}>
                              {item.comment} {item.document?.url && <Box>- <Typography component="a" className={`attachment`} href={item.document.url} target="_blank">{configJSON.viewAttachmentText}</Typography></Box>}
                            </Typography>
                            <Box className={classes.commentAndIcon}>
                              <Typography variant="body1" className={classes.commentsDetails}>
                                <Typography component="span" className="fontFamily">{moment(item.created_at).format("DD/MM/YYYY")}</Typography> {item.updated_at && "(Edited)"} - <Typography component="span" className="textOpacity">{item.owner_name}</Typography>
                              </Typography>
                            { Number(loggedUserId) === item.owner_id && <Box>
                                <img data-test-id="edit_comment" onClick={this.handleOpenEdit.bind(null, item)} src={EditIcon} alt="EditIcon" />
                                <img data-test-id="delete_comment" onClick={this.handleOpenDelete.bind(null, item)} src={DeleteIcon} alt="DeleteIcon" />
                              </Box>}
                            </Box>
                          </li>
                        ))}
                      </ul>
                      {comments.length > 2 && <Box className={classes.boxAlign}><Typography component="span" className={`attachment`} onClick={this.handleOpenComments}>{configJSON.viewAllCommentsText}</Typography></Box>}
                    </div>)
                  }
                  <Box className={classes.editTicketBtnWrapper}>
                    <Button
                      data-test-id="cancel-btn"
                      variant="outlined"
                      className={classes.secondaryBtn}
                      onClick={this.handleDisableEdit}
                    >
                      {configJSON.cancel}
                    </Button>
                    <Button
                      data-test-id="edit-btn"
                      variant="contained"
                      className={classes.primaryBtn}
                      type="submit"
                    >
                      {configJSON.save}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        } />
        <TicketCommentsModal
          open={openCommentsList}
          handleClose={this.handleCloseComments}
          handleEdit={this.handleOpenEdit}
          handleDelete={this.handleOpenDelete}
          list={comments}
          title={configJSON.comments}
          userId={userId}
        />
        <ConfirmationModal
          open={opeDeleteComment}
          onClose={this.handleCloseDelete}
          confirmDelete={this.handleDeleteComment}
          title={configJSON.comment}
          from={configJSON.fromComment}
        />
        <EditCommentModal
          open={openEdtiComment}
          handleClose={this.handleCloseEdit}
          editComment={this.handleEditComment}
          comment={commentId}
        />
        <SuccessModal
          title={`${configJSON.ticketNumber} - ${formData.ticket_number}`}
          message={configJSON.ticketUpdateMessage}
          open={openSuccess}
          onClose={this.handleCloseSuccess}
          closeIcon={true}
          handleClick={this.navigateToTicketList}
          buttonText={configJSON.okBtnText}
        />
      </>
    );
  }
  // Customizable Area End
}
export default withStyles(styles)(EditTicket);

