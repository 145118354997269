// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleExpiredToken, handleApiError, apiCall } from "../../../components/src/utils/commonFunctions";
import React from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    classes: Record<string,string>
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    inputOtp: string[];
    hasValue: boolean[];
    timer: number
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}
// Customizable Area Start

// Customizable Area End

export default class ForgotPasswordOTPController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    confirmOtpCallId: string = "";
    otpInputs: React.RefObject<HTMLInputElement>[];
    timerInterval: NodeJS.Timeout | any
    reSendOtpCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            inputOtp: ["", "", "", ""],
            hasValue: [false, false, false, false],
            timer: 120
        };
        this.otpInputs = [React.createRef(), React.createRef(), React.createRef(), React.createRef()];
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.startTimer();
        // Customizable Area End
    }
    // Customizable Area Start
    async componentWillUnmount() {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    }

    startTimer = () => {
        const countdown = () => {
            if (this.state.timer > 0) {
                this.setState((prevState) => ({
                    timer: prevState.timer - 1,
                }));
            }
        };
        this.timerInterval = setInterval(countdown, 1000);
    };
    handleResendClick = () => {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
          }
        this.setState({ timer: 120 }, () => {
            this.startTimer();
        });
        this.handleResendOtp()
    };

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;
        const inputOtp = [...this.state.inputOtp];
        inputOtp[index] = value;
        const hasValue = [...this.state.hasValue];
        hasValue[index] = value !== "";
        this.setState({ inputOtp, hasValue });

        if (value) {
            if (index < 3) {
                this.otpInputs[index + 1].current?.focus();
            }
        } else {
            if (index > 0) {
                this.otpInputs[index - 1].current?.focus();
            }
        }
    };

    handleButtonClick = () => {
        this.confirmOtp()
    };

    confirmOtp = async () => {
        const otpValue = this.state.inputOtp.join("");
        let httpBody = {}
        httpBody = {
            data: {
                otp_code: otpValue,
                token: await getStorageData(configJSON.tokenType)
            }
        }
        this.confirmOtpCallId = apiCall({ method: configJSON.httpPostMethod, body: JSON.stringify(httpBody), contentType: configJSON.forgotPasswordAPiContentType, endPoint: configJSON.confirmOtpApiEndPoint });

    }

    handleResponseForConfirmOtp = (from: string, message: Message) => {
        if (this.confirmOtpCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(apiResponse, this.props.navigation);
            if (apiResponse.messages) {
                this.props.navigation.history.push("/NewPassword")
            } else {
                this.setState({inputOtp: ["", "", "", ""]})
                handleApiError(apiResponse.errors);
            }
        }
    }
    handleResendOtp = () => {
        const httpBody = {
            data: {
                attributes: {
                    email: this.props.navigation.history.location.state.email
                }
            }
        }
        this.reSendOtpCallId = apiCall({ method: configJSON.httpPostMethod, body: JSON.stringify(httpBody), contentType: configJSON.forgotPasswordAPiContentType, endPoint: configJSON.senOtpApiEndPoint });
    }

    handleResponseForResendOtp = async (from: string, message: Message) => {
        if (this.reSendOtpCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(apiResponse, this.props.navigation);
            if (apiResponse.data) {
                toast.success(configJSON.resendOTPConfirmationMessage)
                await setStorageData(configJSON.tokenType, apiResponse.meta.token)
            } else {
                handleApiError(apiResponse.errors);
            }
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResponseForConfirmOtp(from, message)
        this.handleResponseForResendOtp(from,message)
        // Customizable Area End
    }
    // Customizable Area End


}
