Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.deleteConfirm = "Are you sure you want to delete";
exports.logoutConfirm = "Are you sure you want to";
exports.noButton = "No"
exports.yesButton = "Yes";
exports.okButton = "Ok";
exports.exportBtn = "Export";
exports.replaceText = "Replace Image";
exports.addText = "Add Image";
exports.editBtn = "Edit";
exports.deleteBtn = "Delete";
exports.duplicateBtn = "Duplicate";
exports.favouriteBtn = "Mark as Favourite";
exports.downloadBtn = "Download";
exports.viewBtn = "View";
exports.UnfavouriteBtn = "Unmark as Favourite";
exports.attachmentTitle = "Related Document";
exports.attachmentName = "attachment";
exports.attachFileText= "Attach File";
exports.uploadPicText = "Upload Picture";
exports.picTypeText = "(Upload Jpg,png,format only)";

exports.firebaseApiKey = 'AIzaSyDNDQDi33eKg0b7zPE13tK1iJdfPHV8vZI';
exports.firebaseAuthDomain = 'social-media-aac65.firebaseapp.com';
exports.firebaseProjectId = 'social-media-aac65';
exports.firebaseStorageBucket = 'social-media-aac65.appspot.com';
exports.firebaseMessagingSenderId = '1042464662098';
exports.firebaseAppId = '1:1042464662098:web:1a4b861ad069eb129d358b';
exports.firebaseMeasurementId = 'G-GMKGW645FM';

exports.vApiKey = "BBDZ5IVORKayrpqxWymqDPhX-PszQk3EMpoi9CKLfic4cXV3ecxXXYmOA8p7gB5aLLcK--v3PrTr4jYyihFvh1w";
// Customizable Area End