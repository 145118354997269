Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.updateApiMethodType = "PUT"
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";

exports.btnExampleTitle = "CLICK ME";
exports.settingTitle = "Settings";
exports.notificationText = "Notifications";
exports.paraText = "Receive Notification whenever your company requires your attention";
exports.emailText = "Email Notifications";
exports.emailParaText = "Receive Notification whenever your company requires your attention";
exports.timezoneApiEndpoint = "bx_block_branch/branches/time_zones";
exports.showSettingApiEndpoint = "bx_block_settings/show_setting";
exports.updateSettingApiEndpoint = "bx_block_settings/update_setting";
exports.changeThemeText = "Change theme color";
exports.customizeText = "Customize your template";
exports.changeTheme = "Go to theme selector";
exports.timezoneText = "Select Time zone";
exports.saveBtn = "Save";
exports.settingSuccessMessage = "Settings saved successfully";
exports.settingErrorMessage = "Settings not saved";
exports.updateInformation = "Update Information";
exports.profileCustomizeText = "Customize your profile";
exports.editBioText = "Edit Bio"
// Customizable Area End