// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, checkToken, handleExpiredToken } from "../../../components/src/utils/commonFunctions";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import { configJSON } from "./BulkUploadingController";
// Customizable Area End


export interface Props {
  navigation?: any;
  id?: string;
  classes: Record<string, string>;
  // Customizable Area Start
  open: boolean;
  handleClose: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  openModal: string;
  attachment: File | null;
  apiError: string;
  allErrors: any;
  infoModaldescription:string[];
  infoModaltitle:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class BulkInviteController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  downloadCsvApiId: string = "";
  bulkInviteApiId: string = "";
  infoTextApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    // Customizable Area Start

    this.state = {
      loading: false,
      openModal: "",
      attachment: null,
      apiError: "",
      allErrors: "",
      infoModaldescription: [],
      infoModaltitle: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    checkToken();
    this.infoTextApiId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.infoTextEndpoint, token: true });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(responseJson, this.props.navigation);
      this.setState({ loading: false });

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.downloadCsvApiId) {
          this.downloadCsvResponse(responseJson);
        }else if (apiRequestCallId === this.bulkInviteApiId) {
          this.bulkInviteResponse(responseJson);
        }
        else if (apiRequestCallId === this.infoTextApiId) {
          this.bulkInfoResponse(responseJson);
        }
      }
    }
  }

  downloadCsvResponse = (response: { file: string }) => {
    if (response.file) {
      window.open(response.file, "_blank");
    }
  };

  bulkInviteResponse = (response: { failure_response: object }) => {
    if (Object.keys(response.failure_response).length) {
      this.setState({allErrors: response.failure_response, apiError: configJSON.inviteErrorMessage})
    } else {
      this.handleCloseModal()
      toast.success(configJSON.inviteSuccessMessage);
    }
  };

  bulkInfoResponse = (response: { invite_info: { title: string; description: string[]; }; }) => {
    const { title, description } = response.invite_info
    this.setState({ infoModaldescription: description, infoModaltitle: title })
  }

  handleFileUpload = (file: File) => {
    let allowFile = file.type === "text/csv";
    if (allowFile) {
      this.setState({
        attachment: file,
        apiError: "",
        allErrors: "",
      });
    } else {
      this.setState({ apiError: configJSON.fileUploadErrorMessage});
    }
  };

  uploadCsv = () => {
    if(this.state.attachment) {
      this.setState({ loading: true});
      let formData = new FormData();
      formData.append(`data[file]`, this.state.attachment);
  
      this.bulkInviteApiId = apiCall({
        endPoint: configJSON.bulkInviteEndpoint,
        method: configJSON.exampleAPiMethod,
        token: true,
        body: formData,
      });
    } else {
      this.setState({ apiError: configJSON.uploadFileError})
    }
  }

  convertErrorsIntoArray = () => {
    const { allErrors } = this.state;
    return Object.keys(allErrors).map(key => key + " - " + allErrors[key]);
  }

  handleCloseModal = () => {
    this.setState({ attachment: null, apiError: "", allErrors: "" });
    this.props.handleClose();
  };

  handleOpenInfo = (openModal: string) => {
    this.setState({ openModal });
  };

  handleCloseInfo = () => {
    this.setState({ openModal: "" });
  };

  downloadSampleCsv = () => {
    this.setState({ loading: true});
    this.downloadCsvApiId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.downloadSampleEndpoint, token: true });
  };
  // Customizable Area End
}
