
import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import FavouriteMenuListingController, { Props, configJSON } from "./FavouriteMenuListingController.web";
import { commonButton, libraryCommonCss } from "../../../components/src/utils/commonInputStyle";
import { libraryIcon } from "./assets";
import NoTickets from "../../../components/src/commonComponents/NoTickets.web";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import Loader from "../../../components/src/Loader";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import Pagination from "../../../components/src/commonComponents/Pagination.web";
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
    ...commonButton,
    ...libraryCommonCss
});
// Customizable Area End

export class FavouriteMenuListing extends FavouriteMenuListingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes, searchQuery } = this.props;
        const { menu_list, loading, openDeleteModal, meta } = this.state

        const FavouriteMenuViewer = () => {
              if ( menu_list.length > 0) {
                return (
                  <>
                      <Grid spacing={2} container>
                          {(Boolean(menu_list.length)) ?
                              menu_list.map(favourite_menu => (
                                  <Grid item key={favourite_menu.attributes.id} lg={3} md={4} sm={6} xs={12}>
                                      <Box className={classes.cardWrapper}>
                                          <Box className={classes.actionButton}>
                                              <ActionButton
                                                  handleDelete={this.handleOpenDeleteModal}
                                                  deleteVisible
                                                  toggleDropdown={() => this.toggleDropdown(favourite_menu.attributes.id)}
                                                  isOpen={this.state.isOpen[favourite_menu.attributes.id]}
                                                  handleView={() => this.handleView(favourite_menu.attributes)}
                                                  view
                                              />
                                          </Box>
                                          <Box className="cursor" data-test-id="card_view" onClick={() => this.handleView(favourite_menu.attributes)}>
                                              <Box className={classes.imgBoxContainer}>
                                                  <img src={favourite_menu.attributes.menu_template.data.attributes.template_image.url} alt={favourite_menu.attributes.menu_template.data.attributes.template_image.name} className={classes.templateImg} />
                                              </Box>
                                              <Box className={classes.textContainer}>
                                                  <Typography data-test-id="title" className={classes.procedureTitleTxt} noWrap>{favourite_menu.attributes.name}</Typography>
                                                  <Typography className={classes.dateTxt}>{configJSON.date}{this.getFormattedDate(favourite_menu.attributes.created_at)}</Typography>
                                              </Box>
                                          </Box>
                                      </Box>
                                  </Grid>
                              )) :
                              <Box className={classes.noTicketContainer} >
                                  <NoTickets
                                      title={configJSON.noMenuText}
                                      imagePath={libraryIcon}
                                  />
                              </Box>
                          }
                      </Grid>
                      <Box data-test-id="menu-pagination">
                          <Pagination meta={meta} onPageChange={this.handlePageChange} />
                      </Box>
                  </>
                )
            } else if (!loading && Boolean(searchQuery) && menu_list.length === 0) {
                return (
                    <Box className={classes.noTicketContainer}>
                        <Typography variant="h5">{configJSON.noSearchResult} <strong>{searchQuery}</strong>.</Typography>
                    </Box>
                )
            } else if (menu_list.length === 0 && !loading) {
                return (
                    <Box className={classes.noTicketContainer} >
                        <NoTickets title={configJSON.noMenuText} imagePath={libraryIcon} />
                    </Box>
                )
            }
        }

        return (
            <React.Fragment>
                <Loader loading={loading} />
                <Box pt={2} className={`${menu_list.length > 0 ? "" : classes.noTicketOuterContainer}`}> 
                    {FavouriteMenuViewer()}
                </Box>
                {openDeleteModal &&
                    <ConfirmationModal
                        from={configJSON.favouriteFrom}
                        onClose={this.handleCloseDeleteModal}
                        open={openDeleteModal}
                        confirmDelete={this.handleDeleteClick}
                        title={configJSON.menuTemplateDeleteTitle}
                    />}
            </React.Fragment>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default withStyles(styles)(FavouriteMenuListing);
// Customizable Area End
