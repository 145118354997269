// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  apiCall,
  checkToken,
  handleExpiredToken,
  isBO,
  isManager,
  userBranchId,
  usersDepartments,
} from "../../../components/src/utils/commonFunctions";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  allDepartments: DepartmentsRoot[];
  selectedDepartments: string[];
  branches: Lists[];
  branchID: string | number;
  openModal: boolean;
  isSelectedDepartments: Partial<Department>;
  searchQuery: string;
  searchAllDepartments: DepartmentsRoot[] | null;
  departData: DepartmentData[]
  // Customizable Area End
}

interface SS {
  id: string;
}

// Customizable Area Start
interface DepartmentData {
  label: string;
  value: number;
}
export interface DepartmentsRoot {
  id: number
  name: string
  departments: Department[]
}

export interface Department {
  id: string
  name: string
  photo: string
}

interface Lists {
  id?: number,
  value: string | number;
  label: string;
  isDisabled?: boolean
}
interface BranchData {
  id: string | number;
  attributes: {
    branch_name: string;
    location_of_point_of_sale: string;
  }
}
interface ResponseBranchData {
  branches: {
    data: BranchData[]
  }
}

interface ResponseErrorData {
  errors: string[]
}
// Customizable Area End
export default class ExistingDepartmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  selectedDepaApiId: string = "";
  getBranchesApiId: string = "";
  searchDepartmentApiId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      loading: false,
      allDepartments: [],
      selectedDepartments: [],
      branches: [],
      branchID: "",
      openModal: false,
      isSelectedDepartments: {},
      searchQuery: "",
      searchAllDepartments: null,
      departData: []
    };
    // Customizable Area End    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    checkToken(this.props.navigation);
    this.setState({ loading: true });
    this.getBranchesApiId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.createBranchApiEndpoint, token: true });
    if (isManager()) {
      let updateData: DepartmentData[] = []
      usersDepartments.forEach((depart: { attributes: { id: number; name: string; }; }) => {
        const { id, name } = depart.attributes;

        updateData.push({ label: name, value: Number(id) });
      })
      this.setState({
        departData: updateData,
      })
    }
    // Customizable Area End

  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Props, previousState: S) {
    if (this.state.branchID !== previousState.branchID) {
      this.setState({ loading: true });
      this.selectedDepaApiId = apiCall({ method: "GET", endPoint: `bx_block_departments/selected_departments/${this.state.branchID}`, token: true })
    }
    if (this.state.searchQuery !== previousState.searchQuery && this.state.branchID) {
      this.setState({ loading: true });
      this.searchDepartmentApiId = apiCall({ method: "GET", endPoint: `bx_block_departments/departments/search_selected_departments?query=${this.state.searchQuery}&branch_id=${Number(this.state.branchID)}`, token: true })
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resJson, this.props.navigation);
      this.setState({ loading: false });

      if (apiCallId && resJson) {
        if (apiCallId === this.selectedDepaApiId) {
          this.getSelectedDepartmentRes(resJson);
        } else if (apiCallId === this.getBranchesApiId) {
          this.getBranchesResponse(resJson);
        } else if (apiCallId === this.searchDepartmentApiId) {
          this.getSearchDeptResponse(resJson);
        }
      }
    }
  }

  handleViewEmployeeBtn = (item: Partial<Department>) => {
    const stateData = {
      branch_id: this.state.branchID,
      department_id: item.id,
    }
    this.props.navigation.history.push(`Invite?data=${encodeURIComponent(JSON.stringify(stateData))}`, { item });
  }

  handleViewEmployee = (depart: Partial<Department>) => {
    this.props.navigation.history.push("DepartmentEmployee", { branch_id: this.state.branchID, depart_id: depart.id });
  }
  handleBranchChange = (event: OptionType) => {
    this.setState({ branchID: event?.value });
  }

  getBranchesResponse = (response: ResponseBranchData & ResponseErrorData) => {
    const branchList = response.branches.data;
    const defaultBranchValue = { value: "", label: "Select Branch", isDisabled: true }
    if (branchList.length > 0) {
      const urlParams = new URLSearchParams(window.location.search);
      let branchID = isBO() ? (urlParams.get("id") ?? this.state.branchID) : userBranchId;
      let finalBranchResult: Lists[] = branchList.map((item) => {
        const { id, attributes } = item;
        const { branch_name, location_of_point_of_sale } = attributes;
        return { label: `${branch_name} - ${location_of_point_of_sale}`, value: Number(id), data: attributes };
      })
      finalBranchResult.unshift(defaultBranchValue);
      this.setState({ branches: finalBranchResult, branchID: branchID ? Number(branchID) : Number(branchList[branchList.length - 1].id) });
    } else {
      toast.error(configJSON.branchErrorMessage);
      this.props.navigation.history.push(`/AddNewBranch`);
    }
  };

  getSearchDeptResponse = (response: DepartmentsRoot[]) => {
    this.setState({ searchAllDepartments: response.length > 0 ? response : null,loading:false });
  }

  handleManageDepartment = () => {
    this.props.navigation.history.push(`AddDepartment?id=${this.state.branchID}`);
  }

  backClick = () => {
    this.props.navigation.goBack();
  }

  getSelectedDepartmentRes = (response: DepartmentsRoot[]) => {
    this.setState({ allDepartments: response.length ? response : [] });
  };

  handleSelect = (item: Department) => {
    const { selectedDepartments } = this.state;

    if (!selectedDepartments.includes(item.id)) {
      this.setState({
        selectedDepartments: [item.id],
        isSelectedDepartments: item,
        openModal: true,
      });
    } else {
      this.setState({
        selectedDepartments: [],
      });
    }
  }

  handleModalClose = () => {
    this.setState({
      openModal: false
    })
  }

  chooseDepartment = () => {
    this.props.navigation.navigate("HomePage");
  }

  handleSearch = (query: string) => {
    this.setState({ searchQuery: query })
  }

  // Customizable Area End
}


