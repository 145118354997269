// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ClassNameMap } from "@material-ui/styles";
import { apiCall, filterNullObj, handleApiError, handleExpiredToken } from "../../../components/src/utils/commonFunctions";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import { FavouriteDateData } from "./FavouriteController.web";
import moment from "moment";
import { CreatorTypes } from "./ProcedureListingController.web";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    classes: ClassNameMap<"listContainer" | "cardWrapper" | "templateImg" | "noTicketOuterContainer" |
    "noTicketContainer" | "procedureTitleTxt" | "dateTxt" | "favouriteIcn" | "menuImg" | "menuTxt" | "menuPopper" | 
    "departSelect" | "listItem" | "tableHeader" | "listMain" | "tableItem" | "imgContainer" | "svgIcon" | 
    "deleteModalDesc" | "btn" | "btn1" | "actionButton" | "imgBoxContainer" | "textContainer">;
    date: FavouriteDateData;
    searchQuery: string;
    formData: FormValues;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    checklist_list: DataObj[];
    anchorEl: HTMLElement | null;
    checklist_id: string;
    header_list: string[];
    isOpen: {
        [keyItem: string]: boolean;
    };
    openDeleteModal: boolean;
    search_procedure_list: DataObj[] | null;
    meta: {
        total_pages: number;
        total_count: number;
        current_page: number;
        next_page: null | number;
        previous_page: null | number;
    },
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;

    // Customizable Area End
}
// Customizable Area Start


interface ResponseErrorData {
    errors: string[]
}

interface FormValues {
    branch_id: string;
    department_id: string;
}


interface ResponseProcedure {
    checklists: {
        data: DataObj[]
    };
    meta: MetaObj
}

export interface MetaObj {
    total_pages: number;
    total_count: number;
    current_page: number;
    next_page: number | null;
    previous_page: number | null;
}

export interface DataObj {
    attributes: {
        id: string;
        title: string;
        creator?: CreatorTypes;
        favorite: boolean;
        created_at: string;
        branch_id: number;
    }
}
// Customizable Area End

export default class FavouriteChecklistListingController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getChecklistsApiId: string = ""
    deleteChecklistApiId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            checklist_list: [],
            anchorEl: null,
            loading: false,
            header_list: configJSON.headerList,
            checklist_id: "",
            search_procedure_list: null,
            openDeleteModal: false,
            isOpen: {},
            meta: {
                total_count: 1,
                next_page: null,
                total_pages: 1,
                current_page: 1,
                previous_page: null
            },
        }
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
      // Customizable Area Start
        const { date: { from_date, to_date, time_range }, searchQuery, formData } = this.props
        this.setState({ loading: true })
        this.getFavouriteChecklists(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
        // Customizable Area End
    }
    // Customizable Area Start
    componentDidUpdate(FavChecklistPrevProps: Props, previousState: S) {
        if ((FavChecklistPrevProps.date.time_range !== this.props.date.time_range) || (FavChecklistPrevProps.date.from_date !== this.props.date.from_date) || (FavChecklistPrevProps.date.to_date !== this.props.date.to_date) || this.props.formData.department_id !== FavChecklistPrevProps.formData.department_id || this.props.formData.branch_id !== FavChecklistPrevProps.formData.branch_id) {
            const { date: { from_date, to_date, time_range }, searchQuery, formData } = this.props
            if (from_date && to_date) {
                this.setState({ loading: true })

                this.getFavouriteChecklists(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
            }
            if (time_range !== "custom") {
                this.setState({ loading: true })
                this.getFilteredFavouriteChecklists(from_date, to_date, time_range, searchQuery)
            }
        }
        if (FavChecklistPrevProps.searchQuery !== this.props.searchQuery) {
            const { date: { from_date, to_date, time_range }, searchQuery, formData } = this.props
            this.setState({ loading: true })
            this.getFavouriteChecklists(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
        }
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let favFormResjson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            handleExpiredToken(favFormResjson, this.props.navigation);
            this.setState({ loading: false });

            if (apiCallId && favFormResjson) {
                if (apiCallId === this.getChecklistsApiId) {
                    this.getChecklistsResponse(favFormResjson);
                } else if (apiCallId === this.deleteChecklistApiId) {
                    this.handleFavChecklistDeleteResponse(favFormResjson);
                }
            }
        }
    }

    getChecklistsResponse = (responseD: ResponseProcedure & ResponseErrorData) => {
        this.closeDropDown()
        if (responseD.errors) {
            handleApiError(responseD.errors)
        } else {
            this.setState(prev => ({ checklist_list: responseD.checklists.data, meta: responseD.meta ?? prev.meta }))
        }
    }

    handleFavChecklistDeleteResponse = (responseD: ResponseErrorData) => {
        const { date: { from_date, to_date, time_range }, formData } = this.props
        if (responseD.errors) {
            handleApiError(responseD.errors)
        } else {
            this.getFavouriteChecklists(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id)
            toast.success(configJSON.checklistDeleteMessage)
        }
    }

    getFormattedDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString().split(",")[0]
    }

    toggleDropdown = (itemId: string) => {
        this.setState((prevState) => {
            const updatedIsOpen = { ...prevState.isOpen };

            for (const keyItem in updatedIsOpen) {
                updatedIsOpen[keyItem] = false;
            }

            updatedIsOpen[itemId] = !prevState.isOpen[itemId];

            return {
                isOpen: updatedIsOpen,
                checklist_id: itemId,
            };
        });
    };

    handleDeleteClick = () => {
        this.deleteChecklistApiId = apiCall({ method: configJSON.deleteMethod, endPoint: `${configJSON.checkListApiEndPoint}${this.state.checklist_id}/remove_from_favorite`, token: true });
        this.handleCloseDeleteModal()
    }

    getFilteredFavouriteChecklists = (fav_from_date: string | null, fav_to_date: string | null, fav_time_range: string | null, searchQuery: string = '') => {
        const department_id = this.props.formData.department_id
        switch (fav_time_range) {
            case configJSON.today:
                this.getFavouriteChecklists(this.state.meta.current_page, fav_from_date, fav_to_date, "today", department_id, searchQuery)
                break
            case configJSON.yesterday:
                this.getFavouriteChecklists(this.state.meta.current_page, fav_from_date, fav_to_date, "yesterday", department_id, searchQuery)
                break
            case configJSON.lastDays:
                this.getFavouriteChecklists(this.state.meta.current_page, fav_from_date, fav_to_date, "15_days", department_id, searchQuery)
                break
            default:
                this.getFavouriteChecklists(this.state.meta.current_page, fav_from_date, fav_to_date, "", department_id, searchQuery)
                break
        }
    }

    getFavouriteChecklists = (page: number, from_date: string | null, to_date: string | null, time_range: string | null, department_id: string, query?: string) => {
        let endPointfavChecklistfiter: string = `${configJSON.checkListApiEndPoint}?favorite=true&`
        this.setState({loading:true})
        const {branch_id} = this.props.formData
        let allData = {page, custom_start_date: from_date ? moment(from_date).format("DD/MM/YYYY") : "", custom_end_date: to_date ? moment(to_date).format("DD/MM/YYYY") : "", time_range, branch_id, department_id, query};
        this.getChecklistsApiId = apiCall({ method: configJSON.getMethod, endPoint: endPointfavChecklistfiter + filterNullObj(allData), token: true })
    }

    handleCloseDeleteModal = () => {
        this.setState({ openDeleteModal: false })
    }

    handleOpenDeleteModal = () => {
        this.setState({ openDeleteModal: true })
        this.closeDropDown()
    }

    handlePageChange = (pageNumber: number) => {
        const { date: { from_date, to_date, time_range }, formData } = this.props
        this.setState({
            meta: {
                ...this.state.meta,
                current_page: pageNumber
            }
        });
        this.getFavouriteChecklists(pageNumber, from_date, to_date, time_range, formData.department_id)
    };

    closeDropDown = () => {
        this.setState((prevState) => {
            const updatedIsOpen = { ...prevState.isOpen };

            for (const keyItem in updatedIsOpen) {
                updatedIsOpen[keyItem] = false;
            }

            return {
                isOpen: updatedIsOpen,
            };
        });
    }

    handleView = (idVar: string ) => {
        this.props.navigation.history.push(`ChecklistForm?id=${idVar}`, {from: configJSON.fromLibrary, action: configJSON.fill})
    }

    // Customizable Area End


}
