// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  createUserprofileSchema1,
  createUserprofileSchema2
} from "../../../components/src/utils/validationSchema";
import {
  apiCall,
  checkTokenBeforeLogin,
  convertAllFormData,
  handleApiError,
  handleExpiredToken,
} from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isEdit: boolean;
  id: string | number;
  activeStep: number;
  profileID: string;
  national_id: string | File;
  nationalidUrl: DocUrl;
  passport: string | File;
  passportUrl: DocUrl;
  vaccination_certificate: string | File;
  vaccinationcertificateUrl: DocUrl;
  school_degree: string | File;
  schooldegreeUrl: DocUrl;
  university_degree: string | File;
  universitydegreeUrl: DocUrl;
  certificate: string | File;
  certificateUrl: DocUrl;
  driving_license: string | File;
  drivinglicenseUrl: DocUrl;
  // Customizable Area End
}
// Customizable Area Start
interface DocUrl {
  id?: string | number;
  name: string; 
  url: string; 
  size: string;
  uploaded_date: string;
}

export interface CompanyProfile {
  data: {
    id: string;
    attributes: {
        id: number | string;
        name: string;
        account_id: number | string;
        owner_name: string;
        owner_email: string;
        phone_number: string;
        head_office: string;
        employee_count: number | string;
        creation_date: string;
        from_working_hours: string;
        to_working_hours: string;
        description: string;
        country_code:string | null;
        country_code_and_flag_id:string | null;
        photo: string;
        industry_id:string;
        business_type_id:string
    };
  }
}

export interface ProfileData {
  data: {
    id: string;
    attributes: {
      owner_name: string;
      owner_email: string;
      phone_number: string;
      photo: string;
      country_id: string | number;
      country_code_and_flag_id: string | number;
      national_id: DocUrl
      driving_license: DocUrl
      passport: DocUrl
      school_degree: DocUrl
      university_degree: DocUrl
      vaccination_certificate: DocUrl
      certificate: DocUrl
      date_of_birth: string
    }
  }
}
interface UserData {
  data: {
    id: string;
    type?: string;
    attributes: {
        id: number | string;
        email: string;
        name: string;
        device_id: string[];
        company_profile: CompanyProfile;
        profile: ProfileData;
        role_id: string;
        status:string;
        activated:string
    };
  }
}
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserPofileApiCallId: string = "";
  saveUserprofileApiId: string = "";
  inialDocUrlObj = { name: "", url: "", size: "", uploaded_date: ""}
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];


    this.state = {
      loading: false,
      isEdit: false,
      id: "",
      activeStep: 1,
      national_id:"",
      passport: "",
      vaccination_certificate: "",
      school_degree: "",
      university_degree: "",
      certificate: "",
      driving_license: "",
      nationalidUrl: this.inialDocUrlObj,
      passportUrl: this.inialDocUrlObj,
      vaccinationcertificateUrl: this.inialDocUrlObj,
      schooldegreeUrl:this.inialDocUrlObj,
      universitydegreeUrl: this.inialDocUrlObj,
      certificateUrl: this.inialDocUrlObj,
      drivinglicenseUrl: this.inialDocUrlObj,
      profileID: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({ loading: true });
    checkTokenBeforeLogin(this.props.navigation)
    this.getUserPofileApiCallId = apiCall({
      method: "GET",
      endPoint: configJSON.Companyuserdetails,
      token: true,
    });
  }

  handleDocUpload = (event: React.ChangeEvent<HTMLInputElement>, stateName: string, stateNameUrl: string, setFieldValue: (arg0: string[], arg1: File | undefined) => void) => {
    const fileData = event.target.files?.[0];
    setFieldValue([stateName], fileData)
    const allowedFileTypes = ['image/png', 'image/jpeg', 'application/pdf', 'image/jpg'];
    if (fileData) {
      if (allowedFileTypes.includes(fileData.type)) {
        const fileSize = fileData.size / (1024 * 1024)
        if (fileSize <= 5) {
          this.setState({
            ...this.state,
            [stateName]: fileData,
            [stateNameUrl] : {
              name: fileData.name, 
              url:  URL.createObjectURL(fileData), 
              size: fileSize, 
              uploaded_date: fileData.lastModified
            }
          });
        } 
      }
    }
  };

  handleClearDoc = (stateName: string, stateNameUrl: string, setFieldValue: (arg0: string[], arg1: string) => void) => {
    setFieldValue([stateName], "")
    this.setState({
      ...this.state,
      [stateName]: "",
      [stateNameUrl] : this.inialDocUrlObj
    });
  };

  handleNextfunction = (event: Pick<S, never> | null) => {
    if (this.state.activeStep < 2) {
      this.setState((prev) => ({
        ...prev,
        ...event,
        activeStep: prev.activeStep + 1,
      }));
    } else {
      this.setState(
        {
          ...event,
        },
        () => this.createuserprofileFunction()

      );
    }
  };

  handleGoBack = () => {
    if (this.state.activeStep === 2) {
      this.setState((prev) => ({
        ...prev,
        activeStep: prev.activeStep - 1,
      }));
    } else {
      this.props.navigation.navigate("JoiningForm");
    }
  };

  getValidationSchemafunction = () => {
    if (this.state.activeStep === 1) {
      return createUserprofileSchema1;
    } else {
      return createUserprofileSchema2;
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(responseJson, this.props.navigation);

      if (apiRequestCallId && responseJson) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.getUserPofileApiCallId) {
          this.getUserProfileResponse(responseJson);
        } else if (apiRequestCallId === this.saveUserprofileApiId) {
          this.createUserProfileResponse(responseJson);
        }
      }

    }
  }
  createUserProfileResponse = (responseData: { data: object; errors: string[]; }) => {
    if (responseData.data) {
      toast.success(`User Profile has been saved successfully`);
      this.props.navigation.navigate("TermAndConditions");
    } else {
      handleApiError(responseData.errors);
    }
  };

  getUserProfileResponse = (responseDaata: UserData) => {
    if (responseDaata.data) {
      const CompanyData = responseDaata.data.attributes.company_profile.data;
      const UserData = responseDaata.data.attributes.profile.data;
      if (UserData) {
        const { id } = CompanyData;
        const UserdefaultData = {
          id,
          national_id:"",
          passport: "",
          vaccination_certificate: "",
          school_degree: "",
          university_degree: "",
          certificate: "",
          driving_license: "",
          nationalidUrl: UserData.attributes.national_id,
          passportUrl: UserData.attributes.passport,
          vaccinationcertificateUrl: UserData.attributes.vaccination_certificate,
          schooldegreeUrl: UserData.attributes.school_degree,
          universitydegreeUrl: UserData.attributes.university_degree,
          certificateUrl: UserData.attributes.certificate,
          drivinglicenseUrl: UserData.attributes.driving_license,
          date_of_birth: UserData.attributes.date_of_birth,
          profileID: responseDaata.data.attributes.profile.data.id,
          isEdit: UserData ? true : false,
        };

        this.setState({
          ...this.state,
          ...UserdefaultData,
        });
      }
    }
  };


  createuserprofileFunction = () => {
    this.setState({ loading: true });

    const { isEdit, national_id, nationalidUrl, passport, passportUrl, vaccination_certificate, vaccinationcertificateUrl, school_degree, schooldegreeUrl, university_degree, universitydegreeUrl,certificate, certificateUrl, driving_license, drivinglicenseUrl } = this.state;

    const attrs = {
      ...(national_id ? { national_id: national_id } :  nationalidUrl?.url ? {} : {national_id: ""}),
      ...(passport ? { passport: passport } :  passportUrl?.url ? {} : {passport: ""}),
      ...(vaccination_certificate ? { vaccination_certificate: vaccination_certificate } :  vaccinationcertificateUrl?.url ? {} : {vaccination_certificate: ""}),
      ...(school_degree ? { school_degree: school_degree } :  schooldegreeUrl?.url ? {} : {school_degree: ""}),
      ...(university_degree ? { university_degree: university_degree } :  universitydegreeUrl?.url ? {} : {university_degree: ""}),
      ...(certificate ? { certificate: certificate } :  certificateUrl?.url ? {} : {certificate: ""}),
      ...(driving_license ? { driving_license: driving_license } :  drivinglicenseUrl?.url ? {} : {driving_license: ""}),
    };

    let formData = convertAllFormData(attrs, "data[attributes]")
    if([...formData.entries()].length > 0){
      this.saveUserprofileApiId = apiCall({
        method: isEdit ? "PUT" : "POST",
        body: formData,
        endPoint: isEdit ? `bx_block_profile/profiles/${this.state.profileID}` : configJSON.CreateUserProfileEndPoint,
        token: true,
      });
    } else {
      toast.success(`User Profile has been saved successfully`);
      this.props.navigation.navigate("TermAndConditions");
    }
  };

  // Customizable Area End
}