import { Theme, createStyles } from '@material-ui/core/styles';
import { colors } from '../../utils/commonDesign';
import { commonInputField } from '../../utils/commonInputStyle';

export const styles: any = (theme: Theme) => createStyles({
  ...commonInputField,
  modalWrapper: {
    width: "100%",
  },
  customModal: {
    "& .titleContainer": {
      marginBottom: "22px",
      display: "flex",
      justifyContent: "space-between",
      "& .MuiDialogTitle-root": {
        "& .MuiTypography-root": {
          fontSize: "16px",
          lineHeight: "21px",
          fontWeight: "500",
          color: "#020202;",
          margin: "0px",
        },
      },
      "& .closeIcon": {
        height: 18,
        marginLeft: 10,
        width: 18,
        "& img": {
          height: "100%",
          cursor: "pointer",
          width: "100%",
        }
      }
    },
    "& .array-description": {
      color: "#020202; !important",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      lineHeight: "18px !important",
      margin: "0 0 12px 0 !important",
      "& .star-list" : {
        color: colors.errorText
      },
    },
    "& .comment-ul" : {
      margin: "0px",
      "& li::marker" : {
        fontSize: "20px",
        color: colors.secondaryColor1,
      },
      "& .commentsDetails" : {
        fontSize: "16px",
        fontWeight: 300,
        lineHeight: "21px",
        color: colors.text,
        margin: "0 !important",
      },
      "& .detailsContainer" : {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& img" : {
          cursor: "pointer",
          marginRight: "10px",
        }
      },
    },
    "& .attachment":{
      color: "#4196F0",
      textDecoration: "none",
      cursor:"pointer",
      fontSize:"14px !important",
      borderBottom: "1px solid #4196F0",
    },
    "& .MuiDialog-paperWidthSm": {
      boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.24)",
      borderRadius: "16px",
      padding: "32px 32px 40px 32px",
      maxWidth: "650px",
      width: "100%",
      "@media(max-width:567px)": {
        width: "auto",
        padding: "25px 15px",
        margin: "0 15px",
      },
      "& .MuiDialogContent-root,& .MuiDialogActions-root, & .MuiDialogTitle-root": {
        padding: "0",
        justifyContent: "center",
      },
      "& .MuiDialogTitle-root": {
        "& .MuiTypography-root": {
          fontWeight: "700",
          fontSize: "24px",
          lineHeight: "32px",
          color: colors.primaryColor2,
          marginBottom: "12px",
        },
      },
      "& .MuiDialogContent-root": {
        "& .MuiTypography-root": {
          color: colors.text,
          fontSize: 16,
          fontWeight: 300,
          lineHeight: "21px",
          margin: "0 0 36px 0",
        },
      },
      "& .MuiDialogActions-root": {
        "@media(max-width:374px)": {
          flexWrap: "wrap",
        },
        "& button": {
          margin: "8px",
          boxShadow: "none",
          fontWeight: 700,
          height: "56px",
          width: "350px",
          borderRadius: "10px",
          fontSize: "16px",
          lineHeight: "21px",
          color: colors.primaryColor2,
          textTransform: "capitalize",
          backgroundColor: colors.secondaryColor2,
          "&:hover": {
            backgroundColor: colors.secondaryColor2,
          },
          [theme.breakpoints.between('xs', 'sm')]: {
            width: "300px",
            height: "50px"
          },
          "@media(max-width:374px)": {
            maxWidth: "100%",
            flex: "0 0 100%",
            height: "40px"
          },
        },
      },
    },
  },
});
