import { colors } from "./commonDesign";

const inputLabel = {
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: 400,
  color: colors.primaryColor2,
}

export const helperText = {
  margin: 0,
  fontSize: "14px",
  lineHeight: "18px",
  marginTop: "5px",
  marginBottom: "-23px",
  color: colors.errorText,
}

export const inputField = {
  fieldWrapper: {
    margin: "20px 0 3px",
  },
  inputLabel,
  inputField: {
    margin: 0,
    marginTop: "15px",
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      height: "56px",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "21px",
        "& .Mui-disabled": {
          borderRadius: "10px",
          backgroundColor: "#F5F5F5",
          color: colors.text,
        },
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors?.secondaryColor1,
      },
    },
    "& .MuiFormHelperText-root": {
      color: colors?.errorText,
      margin: 0,
      marginTop: "5px",
    },
  },
}

export const commonInputField = {
  fieldWrapper: {
    margin: "25px 0 0px",
    position: "relative" as any,
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      backgroundColor: "#fff",
      height: "56px",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "21px",
        "& .Mui-disabled": {
          color: colors.text,
          backgroundColor: "#F5F5F5",
          borderRadius: "10px",
        },
      },
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.borderColor,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.borderColor,
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${colors.borderColor}`
      },
    },
    "& .attachment": {
      textAlign: "right",
      color: colors.urlText,
      cursor: "pointer",
      marginTop: 2,
      "& span,a": {
        borderBottom: `1px solid ${colors.urlText}`,
        fontSize: "14px !important",
        textDecoration: "none",
        color: colors.urlText,
      }
    }
  },
  inputLabel,
  inputField: {
    marginTop: "10px",
    margin: 0,
    "& .MuiFormHelperText-root": helperText,
  },
  helperText: {
    ...helperText,
    marginBottom: "-23px",
  },
  dateField: {
    "& .Mui-focused": {
      borderColor: colors.secondaryColor1,
    },
    "& .MuiInputBase-root": {
      padding: "0 14px",
      border: `1px solid ${colors.borderColor}`,
    },
  },
  textAreaField: {
    height: "165px",
    "&.ticketTextAreaField" : {
      height: "167px", 
    },
    "& .MuiInputBase-root": {
      height: "100% !important",
      "& .MuiInputBase-input": {
        overflow: "auto !important",
        height: "100% !important",
      },
    },
  },
  textAreaInputField: {
    height: "278px",
  },
  fileInput: {
    position: "relative" as any,
    display: "block",
    width: "100%",
    "& .MuiFormControl-root": {
      position: "absolute" as any,
      overflow: "hidden",
      right: "0",
      left: "0",
      opacity: 0,
      "& input": {
        cursor: "pointer",
        fontSize: "0",
      },
    },
    "& .label-block": {
      backgroundColor: "transparent",
      border: `1px solid ${colors.borderColor}`,
      height: "56px",
      display: "flex",
      alignItems: "center",
      margin: 0,
      marginTop: "10px",
      transition: "all 0.3s",
      lineHeight: "56px",
      cursor: "pointer",
      justifyContent: "center",
      fontSize: "16px",
      borderRadius: "10px",
      "& .file-text": {
        display: "block",
        color: colors.borderColor,
        lineHeight: "1.321",
        overflow: "hidden",
        whiteSpace: "nowrap",
        marginLeft: "10px",
        fontSize: "16px",
        fontWeight: 400,
        textOverflow: "ellipsis",
      },
      "& img": {
        margin: "5px",
        width: "25px",
        height: "25px",
      },
      "& .close-icon": {
        width: "20px",
        height: "20px",
        zIndex: 10,
      },
    },
    "& .error-block": {
      border: `1px solid ${colors.errorText}`,
    },
    "& .file-name": {
      color: `${colors.text} !important`,
      fontWeight: "700 !important",
    },
  },
  phoneGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "15px"
  },
  inputWidth: {
    width: "80% !important"
  },
  dropdownWidth: {
    width: "145px"
  },
  marginWrap: {
    margin: 0
  },
}

export const commonButton = {
  primaryBtn: {
    backgroundColor: colors.secondaryColor2,
    boxShadow: "none",
    margin: "0 0 0 16px",
    textTransform: "capitalize" as any,
    height: "52px",
    width: "300px",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "21px",
    borderRadius: "10px",
    color: colors.primaryColor2,
    "&:hover": {
      backgroundColor: colors.secondaryColor2,
    },
  },
  secondaryBtn: {
    boxShadow: "none",
    margin: "0 16px",
    textTransform: "capitalize" as any,
    height: "52px",
    width: "300px",
    borderRadius: "10px",
    border: `1px solid ${colors.primaryColor2}`,
    fontSize: "16px",
    fontWeight: 700,
    color: colors.primaryColor2,
    lineHeight: "21px",
    "&:hover": {
      border: "none",
      backgroundColor: colors.secondaryColor2,
    }
  }
}

export const singleValueCss = {
  color: `${colors.text} !important`,
  fontWeight: 600,
  lineHeight: "21px",
  fontSize: "16px",
}
export const commonTemplateCss = {
  formContainer: {
    paddingTop: "10px",
    color: `${colors.text} !important`,
    "& .gridContainer": {
      marginTop: 0,
      marginBottom: 0,
    },
    "&.textColor": {
      "& .Mui-disabled": {
        color: colors.text,
        borderRadius: "10px",
      },
    },
    "&.removePadding": {
      padding: 0,
    },
  },
  tempHeight: {
    height: "410px !important",
  },
  approvalContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: "25px",
    "& .MuiSwitch-root": {
      "& .MuiSwitch-track": {
        backgroundColor: colors.secondaryColor1
      },
      "& .MuiSwitch-switchBase.Mui-disabled": {
        color: colors.secondaryColor1
      },
    },
    "&.visibility": {
      visibility: "hidden",
    },
  },
  templateBtnWrapper: {
    display: "flex",
    paddingBottom: "20px",
    justifyContent: "center",
    marginTop: "30px",
    "&.productBtnContainer": {
      marginTop: "50px",
    },
  },
  inputLabel2: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    "&.title": {
      fontWeight: 700,
    },
    "&.labetText": {
      fontSize: 16
    },
    "&.approvalText": {
      fontSize: 20
    }
  },
  dropdownWrapper: {
    height: "42px",
    marginTop: "-19px",
    marginBottom: "11px",
    width: "100%",
    maxWidth: "199px",
  },
  uploadImageContainer: {
    paddingBottom: 10,
    height: "410px",
    border: `2px solid ${colors.borderColor}`,
    borderRadius: "10px",
    padding: "10px",
    marginTop: 30,
    "&.templateHeight9": {
      height: "160px !important"
    },
    "&.templateHeight3": {
      height: "385px !important",
    },
    "&.templateHeight4": {
      height: "164px !important"
    },
    "&.templateHeight5": {
      height: "380px !important"
    },
    "&.templateHeight6": {
      height: "139px !important"
    },
    "&.templateHeight7": {
      height: "186px !important"
    },
    "&.templateHeight10": {
      height: "245px !important"
    },
    "&.templateHeight12": {
      height: "131px"
    },
    "&.templateHeight11": {
      height: "128px !important",
      marginTop: "0px !important",
    },
    "&.productTempHeight1": {
      height: "371px !important"
    },
    "&.productTempHeight6": {
      height: "285px !important"
    },
    "&.productTempWidth4": {
      width: "80% !important",
    },
    "&.menuTempWidth4": {
      width: "80% !important",
    },
    "&.menuTempHeight1": {
      height: "409px !important"
    },
    "&.menuTempHeight2": {
      height: "190px !important"
    },
    "&.menuTempHeight3": {
      height: "334px !important"
    },
    "&.menuTempHeight4": {
      height: "367px !important"
    },
    "&.menuTempHeight6": {
      height: "300px !important"
    },
  },

  titleText: {
    lineHeight: "32px",
    fontSize: "24px",
    fontWeight: 700,
  },
  templateFooterContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap" as const,
    "&.marginTop40": {
    },
  },
  gridItem: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    "&.flex": {
      flex: "1 1 calc(50% - 50px)",
    },
    "& .marginTitle": {
      margin: 0
    },
    "&.marginTop": {
      marginTop: 25
    },
    "&.marginTop12": {
      marginTop: 40
    },
    "&.marginTop22": {
      marginTop: 22
    },
    "& .paddingBottom": {
      paddingBottom: 0
    },
    "&.actionBtn": {
      textAlign: "end",
      height: "24px",
      paddingRight: 0
    },
    "&.visibility": {
      visibility: "hidden",
    },
    "&.gridItem6, &.gridItem11": {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "end",
      gap: "18px",
    },
  },
  reverseContainer: {
    flexDirection: "row-reverse" as const,
  },
  gridContainer: {
    marginTop: "0px",
    marginBottom: "0px",
    "&.columnGap": {
      columnGap: "40px",
    }
  },
  dropdownContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap" as const,
    marginTop: "33px",
    gap: "28px",
  },
  fieldWrapper6: {
    flex: "1",
    margin: "0",
  },
  textAreaInputHeight: {
    height: "160px !important",
    "&.textAreaInputHeight6": {
      height: "170px !important"
    },
    "&.menuTextAreaInputHeight1": {
      height: "125px !important"
    },
    "&.menuTextAreaInputHeight3": {
      height: "88px !important"
    },
    "&.menuTextAreaInputHeight4": {
      height: "105px !important"
    },
  },
  productGrid2: {
    display: "flex",
    gap: "40px",
  },
  productGrid3: {
    display: "flex",
    gap: "13px",
  },
  paddingTop18: {
    paddingTop: "18px",
  },
  actionBtnContainer: {
    textAlign: "end" as const,
    height: "10px",
    marginRight: "-20px",
    "& .positionIndex":{
      zIndex:999
    }
  },
  menuTypeContainer: {
    display: "flex",
    gap: "14px",
    flexWrap: "wrap" as const,
  },
  usdSymbole: {
    marginRight: "0px",
    "& p": {
      lineHeight: "21px",
      color: "inherit !important",
    }
  }
}

export const libraryCommonCss = {
  noTicketOuterContainer: {
    display: "flex",
    justifyContent: "center"
  },
  noTicketContainer: {
    top: "50%",
    position: "absolute" as const,
    textAlign: "center" as const,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column" as const,
    background: colors.primaryColor1,
  },
  cardWrapper: {
    backgroundColor: "#fff",
    borderRadius: "13px",
    border: "1px solid transparent",
    cursor: "pointer",
    "&:hover": {
      border: `1px solid ${colors.secondaryColor2}`
    },
    "& .cursor":{
      cursor:"pointer"
    }
  },
  templateImg: {
    width: "50%",
    "&.miscDocImg": {
      width: "25%",
    }
  },
  procedureTitleTxt: {
    color: colors.text,
    fontWeight: 500,
    width: "100%",
    textAlign: "center" as const
  },
  procedureNameTxt: {
    color: colors.text,
    fontWeight: 300,
    width: "100%",
    textAlign: "center" as const,
    justifyContent: "center",
    alignItems: "center",
    "& img" : {
      marginRight: "10px",
      marginBottom: "-2px",
    }
  },
  dateTxt: {
    color: colors.text,
    fontWeight: 400,
    width: "100%",
    textAlign: "center" as const
  },
  favouriteIcn: {
    color: "#DD7271",
  },
  menuImg: {
    width: "16px",
    height: "16px",
    marginRight: "10px",
    color: colors.text,
  },
  menuTxt: {
    fontSize: "14px !important",
    color: colors.text,
  },
  menuPopper: {
    borderRadius: "8px"
  },
  departSelect: {
    width: "13rem",
    marginTop: 40
  },
  listItem: {
    margin: "0px -24px",
    marginTop: 20,
    background: colors.primaryColor1,
  },
  tableHeader: {
    padding: "0.7rem 24px",
    display: "grid",
    background: `${colors.secondaryColor1}40`,
    gridTemplateColumns: "repeat(6,1fr)",
    wordBreak: "break-all" as const,
    "& p": {
      color: "#020202",
      fontSize: "1.1rem",
      fontWeight: 600
    },
    "& .miscHeaderWidth": {
      width: "270%",
    },
  },
  listMain: {
    alignItems: "center",
    height: "calc(100vh - 300px)",
    display: "flex",
    justifyContent: "center",
  },
  tableItem: {
    borderBottom: "1px solid #E8E8E8",
    alignItems: "center",
    padding: "1.2rem 24px",
    display: "grid",
    wordBreak: "break-all" as const,
    gridTemplateColumns: "repeat(6,1fr)",
    "& p": {
      fontWeight: 500,
      color: "#020202",
      marginRight: "1rem",
      fontSize: "1rem",
    },
    "& .miscItemWidth": {
      width: "270%",
    },
  },
  imgContainer: {
    // border: "1px solid #02020240",
    width: "50px",
    height: "44px",
    "& img": {
      width: "100%",
      height: "100%"
    },
    "&.miscImgContainer": {
      width: "40px",
    },
  },
  svgIcon: {
    marginLeft: 12
  },
  deleteModalDesc: {
    fontSize: "20px !important",
  },
  btn: {
    boxShadow: "none",
    margin: "0 10px",
    textTransform: "capitalize" as any,
    fontWeight: 700,
    fontSize: "16px",
    borderRadius: "10px",
    color: colors.primaryColor2,
  },
  btn1: {
    backgroundColor: colors.secondaryColor2,
    "&:hover": {
      backgroundColor: colors.secondaryColor2,
    },
  },
  paginationBox: {
    "& .paginationPadding": {
      padding: "1.5rem 0rem !important"
    }
  },
  actionButton:{
    display:"flex",
    justifyContent:"flex-end"
  },
  imgBoxContainer:{
    display: "flex", 
    justifyContent: "center"
  },
  textContainer:{
    padding: 8,
    display: "flex",
    justifyContent: "center", 
    flexDirection: "column" as const,
    alignItems: "center"
  }
}

export const libraryContainerCss = {
  tabHeading: {
    color: colors.text,
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  filterBtn: {
    borderRadius: "8px",
    background: "#FFF",
    "&:hover": {
      background: "#FFF",
    }
  },
  filterBtnTxt: {
    color: colors.text,
    fontWeight: 600,
    textTransform: "capitalize",
  },
  inputField: {
    margin: 0,
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "21px",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.secondaryColor2,
      },
    },
  },
  dateField: {
    "& .MuiInputBase-root": {
      border: `1px solid #0000003b`,
      padding: "0 10px",
    },
    "& .Mui-focused": {
      borderColor: colors.secondaryColor2,
    },
  },
  dateRangeTxt: {
    fontSize: "14px",
    fontWeight: 500,
  },
  filterDayItem: {
    border: `1px solid #0000003b`,
    width: "100%",
    borderRadius: "8px",
    paddingBlock: "3px",
    textAlign: "center",
    "&:hover": {
      border: `1px solid ${colors.secondaryColor2}`,
    }
  },
  filterDayItemHighlight: {
    border: `1px solid ${colors.secondaryColor2}`,
  },
  filterTxt: {
    fontSize: "14px",
  },
  filterTxtHighlight: {
    fontWeight: 600,
  },
  menuItems: {
    "& .MuiMenu-paper": {
      top: "140px !important",
      left: "240px !important",
      borderRadius: "8px"
    }
  }
}

export const commonListingCss = {
  listContainer: {
    flexDirection: "column" as const,
    display: "flex",
    background: colors.primaryColor1,
    "& .directoryHeader":{
      gridTemplateColumns: "repeat(7,1fr)"
    },
    "& .listPadding":{
      padding:"0.8rem 24px"
    }
    
  },
  paginationContainer:{
		"& .paginationPadding":{
      padding:"0 24px"
    }
	},
  headerSection: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    ["& button"]: {
      padding: "0.7rem 2rem",
      fontWeight: 600,
      textTransform: "capitalize",
      fontSize: "1rem",
      color: colors.primaryColor2,
      borderRadius: 8,
      background: colors.secondaryColor2,
      "&:hover": {
        background: colors.secondaryColor2,
      },
    },
    ["& h4"]: {
      fontSize: "1.4rem"
    },
  },
  tableHeader: {
    background: `${colors.secondaryColor1}40`,
    marginTop: "2rem",
    display: "grid",
    padding: "0.7rem 24px",
    gridTemplateColumns: "repeat(5,1fr)",
    "& p": {
      fontWeight: 600,
      color: "#020202"
    }
  },
  btnGroup: {
    display: "flex"
  },
  btnInner: {
    alignItems: "center",
    marginRight: "2rem",
    display: "flex",
    "& button": {
      minWidth: "unset",
      color: "#020202;",
      fontWeight: 400,
      padding: 0,
      textTransform: "capitalize"
    }
  },
  avatarImage: {
    height: "1.3rem",
    borderRadius: "unset",
    width: "1.3rem",
    marginRight: 8,
    cursor: "pointer",
    "& img": {
      width: "unset",
      height: "unset"
    }
  },
  listText: {
    textAlign: "center" as const,
    padding: "1rem"
  },
  listMain: {
    overflow: "auto"
  },
  noList: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "calc(100vh - 200px)",
  },
  listItem: {
    margin: "0px -24px",
    background: colors.primaryColor1,
  },
  tableItem: {
    display: "grid",
    gridTemplateColumns: "repeat(5,1fr)",
    padding: "1.2rem 24px",
    borderBottom: "1px solid #E8E8E8",
    alignItems: "center",
    "& p": {
      color: "#020202;",
      paddingRight: "1rem",
      wordBreak: "break-all",
      fontWeight: 400,
      "&.item-width":{
        width:"90% !important"
      },
      "&.status":{
        textTransform:"capitalize"
      }
    },
    "&:hover": {
      background: `${colors.secondaryColor1}10`,
      cursor:"pointer"
    }
  },
  backBtn: {
    margin: "1rem",
    justifyContent: "center",
    "& button": {
      width: "17rem"
    }
  },
}