// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  apiCall,
  checkTokenBeforeLogin,
  handleExpiredToken,
  removeLoginToken,
  removeToken,
} from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading : boolean;
  apiError: string;
  title: string;
  description: string;
  terms_pdf_url: string | null;
  disableBtn: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
// Customizable Area Start
interface ProfileData {
  data: {
    id: string;
  }
}

interface UserData {
  data: {
    id: string;
    type?: string;
    attributes: {
        id: number | string;
        email: string;
        company_profile: ProfileData;
        profile: ProfileData;
        activated:string
    };
  }
}
// Customizable Area End
export default class TermAndConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  termAndConditionApiDetailsId: string = "";
  getAccountDataApiId: string = "";
  termAndConditionApiAgreeId: string = "";
  downloadApiId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
  // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      loading: false,
      apiError: "",
      title: "",
      description: "",
      terms_pdf_url: "",
      disableBtn: false,
    };
  // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.setState({ loading: true });
    checkTokenBeforeLogin(this.props.navigation)
    this.getTermsAndCondtions();
    this.getAccountDataApiId = apiCall({ method: configJSON.getBusinessOwnerDataMethodType, endPoint: configJSON.getBusinessOwnerDataEndPoint, token: true });
  }

  async receive(_: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(responseJson, this.props.navigation);

      if (apiRequestCallId && responseJson) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.termAndConditionApiDetailsId) {
          this.getTermsAndConditionResponse(responseJson);
        } else if (apiRequestCallId === this.getAccountDataApiId) {
          this.getAccountDataResponse(responseJson);
        } else if (apiRequestCallId === this.termAndConditionApiAgreeId) {
          this.agreeTermsAndConditionResponse();
        }else if (apiRequestCallId === this.downloadApiId) {
          if (responseJson.pdf_url) window.open(responseJson.pdf_url, "_blank");
        }
      }
    }
  }

  getAccountDataResponse = (response: UserData) => {
    if (response.data) {
      const companyData = response.data.attributes.company_profile.data;
      const profileData = response.data.attributes.profile.data;
      if (profileData && companyData) {
        this.setState({disableBtn: false});
      }
    } else {
      toast.error(configJSON.errorMessage)
      this.setState({disableBtn: true});
    }
  };

  handleLoginRedirection = () => {
    this.props.navigation.navigate("EmailLogin");
  };

  agreeTermsAndConditionResponse = () => {
    toast.success(configJSON.agreenTermAndConditionsSuccessMsg);
    removeToken();
    removeLoginToken();
    this.handleLoginRedirection();
  };

  getTermsAndConditionResponse = (response: { data: { attributes: { title: string; description: string; terms_pdf_url: string; }; }; }) => {
    this.setState({ title: response.data.attributes.title, description: response.data.attributes.description, terms_pdf_url: response.data.attributes.terms_pdf_url});
  };

  getTermsAndCondtions = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.termAndConditionApiDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onDownloadTerm = () => {
    this.setState({ loading: true });
    this.downloadApiId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.downloadTermEndpoint, token: false });
  };
  onAcceptTerm = () => {
    this.setState({ loading: true });
    const httpBody = {
      data: {
        is_terms_accepted: true,
      }
    };
    this.termAndConditionApiAgreeId = apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.putMethod,
      body: JSON.stringify(httpBody),
      endPoint: configJSON.agreeTermEndpoint,
      token: true,
    });
    
  };

  // Customizable Area End
}
