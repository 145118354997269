// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonProductTemplateController from "./CommonProductTemplateController.web";
import { handleExpiredToken, checkToken, getUserRoleID } from "../../../components/src/utils/commonFunctions";
import { ClassNameMap } from "@material-ui/styles";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"formContainer" | "inputField" | "dateField" | "reverseContainer"
    | "fieldWrapper" | "inputLabel" | "helperText" | "textAreaField" | "textAreaInputField" | "templateBtnWrapper"
    | "primaryBtn" | "fileInput" | "secondaryBtn" | "inputLabel2" | "approvalContainer" | "dropdownWrapper" | "uploadImageContainer" | "titleText" | "gridItem" | "templateFooterContainer"
  >;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class Template6Controller extends CommonProductTemplateController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.state = {
      ...this.state,
      ...this.initProductState,
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidMount(): any {
    // Customizable Area Start
    checkToken();
    this.getCategoriesData();
    this.getBranchesData()

    let { state } = this.props.navigation.history.location;

    if (state) {
      this.setDefaultProduct(state);
    } else if(![2,3].includes(Number(getUserRoleID()))) {
      this.props.navigation.goBack();
    }
    
    // Customizable Area End
  }

  async receive(from6: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resData6 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resData6, this.props.navigation);
      this.setState({ loading: false });
      if (requestCallId && resData6) {
        this.setState({ loading: false });
        switch (requestCallId) {
          case this.createProductTempApiId:
            this.addProductTempResponse(resData6, configJSON.productSuccessMessage);
            break;
          case this.duplicateProductTempApiId:
            this.addProductTempResponse(resData6, configJSON.productDuplicateMessage);
            break;
          case this.deleteProductTempApiId:
            this.deleteProductTempResponse(resData6);
            break;
          case this.branchesDataApiId:
            this.getBranchesDataResponse(resData6);
            break;
          case this.categoriesDataApiId:
            this.getCategoriesDataResponse(resData6);
            break;
          case this.departmentsDataApiId:
            this.getDepartmentsDataResponse(resData6);
            break;
          case this.favouriteProductTempApiId:
            const toastMessage = this.state.productData.favorite ? configJSON.productUnFavMessage : configJSON.productFavMessage;
            this.addProductTempResponse(resData6, toastMessage);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
