// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { apiCall, checkTokenBeforeLogin, convertFormData, handleApiError, handleExpiredToken, handleUndefinedData, isBusinessOwner } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
// Customizable Area End
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  id: string | number;
  profilePicture: string;
  owner_name: string;
  phone_number: string;
  country_code_and_flag_id:string;
  owner_email: string;
  placeHolderName: string;
  placeHolderPhoneNumber: string;
  placeHolderEmail: string;
  isEdit: boolean;
  countryCode:{
    value:string;
    label:string
  }[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
// Customizable Area Start
export interface Industry {
  data: {
    attributes: {
      id: number | string;
      name: string;
    };
  };
}

export interface BusinessType {
  data: {
    attributes: {
      id: number | string;
      name: string;
    };
  };
}
export interface CompanyProfile {
  data: {
    id: string;
    attributes: {
        id: number | string;
        name: string;
        account_id: number | string;
        owner_name: string;
        owner_email: string;
        phone_number: string;
        head_office: string;
        employee_count: number | string;
        creation_date: string;
        from_working_hours: string;
        to_working_hours: string;
        description: string;
        industry: Industry;
        business_type: BusinessType;
        country_code:string | null;
        country_code_and_flag_id:string | null;
        photo: string;
        industry_id:string;
        business_type_id:string
    };
  }
}

export interface ProfileData {
  data: {
    id: string;
    attributes: {
      owner_name: string;
      owner_email: string;
      phone_number: string;
      photo: string;
      country_id: string | number;
      country_code_and_flag_id: string | number;
      current_password: string;
      new_password: string;
      confirm_new_password: string;
    }
  }
}
interface UserData {
  data: {
    id: string;
    type?: string;
    attributes: {
        id: number | string;
        email: string;
        name: string;
        device_id: string[];
        company_profile: CompanyProfile;
        profile: ProfileData;
        role_id: string;
        status:string;
        activated:string
    };
  }
}
// Customizable Area End

export default class MyProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addMyProfileApiCallId: string = "";
  getMyProfileApiId: string = "";
  countryCodeApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      loading: false,
      id: "",
      profilePicture: "",
      owner_name: "",
      phone_number: "",
      country_code_and_flag_id: "",
      owner_email: "",
      isEdit: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderName: configJSON.placeHolderName,
      placeHolderPhoneNumber: configJSON.placeHolderPhoneNumber,
      countryCode: []
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.setState({ loading: true });
    checkTokenBeforeLogin(this.props.navigation)
    this.getMyProfileApiId = apiCall({ method: "GET", endPoint: "account_block/accounts/get_business_owner", token: true });
    this.countryCodeApiCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.countryCodeApiEndPoint });
  }
  handleChange = (event: OptionType, setFieldValue: { (field: string, value: OptionType | undefined, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, key: string) => {
    setFieldValue(key, event.value);
  };

  handleResponseForCountryCode = (from: string, message: Message) => {
    if (this.countryCodeApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.data) {
        const newCountryData = apiResponse.data.map((item: { id: string; name: string; alpha2: string; country_code: string }) => {
          const { country_code, id, alpha2 } = item
          return { value: Number(id), label: `${country_code} (${alpha2})` }
        });
        this.setState({
          countryCode: newCountryData
        })

      } else {
        handleApiError(apiResponse.errors);
      }

    }
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(responseJson, this.props.navigation)

      if (apiRequestCallId && responseJson) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.getMyProfileApiId) {
          this.getUserResponse(responseJson);
        } else if (apiRequestCallId === this.addMyProfileApiCallId) {
          this.addMyProfileResponse(responseJson);
        }
      }
    }
    this.handleResponseForCountryCode(from, message)
  }
  getUserResponse = (response: UserData) => {
    if (response.data) {
      isBusinessOwner(this.props.navigation, response.data);
      const profileData = response.data.attributes.profile.data;
      this.setState({ isEdit: profileData ? true : false });
      if (profileData) {
        const { attributes, id } = profileData
        const defaultData = {
          id,
          owner_name: handleUndefinedData(attributes.owner_name, ""),
          owner_email: handleUndefinedData(attributes.owner_email, ""),
          phone_number: handleUndefinedData(attributes.phone_number, ""),
          profilePicture: handleUndefinedData(attributes.photo, ""),
          country_code_and_flag_id: handleUndefinedData(attributes.country_code_and_flag_id, "")
        }
        this.setState({ ...defaultData });
      }
    }
  };
  addMyProfileResponse = (response: { data: object; errors: string[]; }) => {
    if (response.data) {
      this.handleUserRedirection();
      toast.success(configJSON.addEditProfileSuccessMsg);
    } else {
      handleApiError(response.errors);
    }
  };

  handleUserRedirection = () => {
    this.props.navigation.navigate("CompanyProfile")
  };


  handleFileUpload = (event: { target: { files: File[]; }; }) => {
    const file = event.target.files[0];
    this.setState({
      profilePicture: URL.createObjectURL(file),
    });
  };

  handleRemoveImage = () => {
    this.setState({
      profilePicture: '',
    });
  }


  setMyProfile = async (event: { photo: string | File | null; owner_email: string; owner_name: string; phone_number: string; country_code_and_flag_id: string; }) => {
    this.setState({ loading: true });

    const attrs = {
      owner_name: event.owner_name,
      owner_email: event.owner_email,
      phone_number: event.phone_number,
      country_code_and_flag_id: event.country_code_and_flag_id,
      ...(event.photo ? { photo: event.photo } : this.state.profilePicture ? {} : { photo: "" }),
    };

    this.addMyProfileApiCallId = apiCall({ method: this.state.isEdit ? "PUT" : "POST", body: convertFormData(attrs), endPoint: `bx_block_profile/profiles/${this.state.id}`, token: true, });

  };
  // Customizable Area End
}