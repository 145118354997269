// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  JoiningformSchema2,
  JoiningformSchema1
} from "../../../components/src/utils/validationSchema";
import {
  apiCall,
  checkTokenBeforeLogin,
  convertFormData,
  handleApiError,
  handleExpiredToken,
  handleUndefinedData,
} from "../../../components/src/utils/commonFunctions";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { FormikErrors, FormikTouched } from "formik";
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  classes: Record<string, string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  activefunction: number;
  photoimageUrl: string;
  profileID: string;
  isEdit: boolean;
  company_name: number | string;
  owner_name: string;
  family_name: string;
  photo: string | null | File;
  owner_email: string;
  country_code_and_flag_id: string | null;
  phone_number: string | number;
  date_of_birth: string | Date | null;

  countryCodeListfunction: {
    label: string;
    value: string
  }[],
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface ResponseData {
  data: {
    attributes: {
      profile: {
        data: {
          id: string,
          attributes: {
            id: string,
            owner_name: string
            , owner_email: string,
            phone_number: string,
            country_code_and_flag_id: string,
            family_name: string,
            date_of_birth: string;
            photo: string;

          }
        }
      }, company_profile: {
        data: {
          attributes: {
            name: string
          }
        }
      }
    }
  }
}

export type RenderFormProps = {
  errors: FormikErrors<Partial<S>>,
  touched: FormikTouched<Partial<S>>,
  handleBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
  handleChange: (field: string | React.ChangeEvent<Element>) => void,
  setFieldValue: (field: string, value: OptionType | string | MaterialUiPickersDate | File | undefined | string[] | null, shouldValidate?: boolean | undefined) => void,
  values: Partial<S>
};
// Customizable Area End

export default class JoiningFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileApiId: string = "";
  countryCodeListApiCallId: string = "";
  joiningFormApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      loading: false,
      profileID: '',
      activefunction: 1,
      photoimageUrl: "",
      isEdit: false,
      owner_name: "",
      owner_email: "",
      family_name: "",

      phone_number: "",
      country_code_and_flag_id: "",
      company_name: "",
      date_of_birth: new Date(),
      photo: null,
      countryCodeListfunction: []
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({ loading: true });
    checkTokenBeforeLogin(this.props.navigation)
    this.getUserProfileApiId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.Companyuserdetails,
      token: true,
    });
    this.countryCodeListApiCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.countryCodeApiEndPoint });
  }

  handleChangeSelect = (event: OptionType, setFieldValue: { (field: string, value: string, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, keyItem: string) => {
    setFieldValue(keyItem, event.value);
    this.setState({ ...this.state, ...{ [keyItem]: event.value } })
  };

  handlephotoUpload = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: File | null) => void,) => {
    const files = event.target.files;

    if (!files) {
      return;
    }

    const fileItem = files[0];
    setFieldValue("photo", fileItem);
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (fileItem) {
      if (allowedFileTypes.includes(fileItem.type)) {
        const fileSize = fileItem.size / (1024 * 1024)
        if (fileSize <= 5) {
          const displayUrl = URL.createObjectURL(fileItem);
          this.setState({
            photo: fileItem,
            photoimageUrl: displayUrl,
          });
        } else {
          toast.error(configJSON.fileSizeError);
        }
      } else {
        toast.error(configJSON.fileSelected)
      }
    }
  };


  handledeleteImage = () => {
    this.setState({
      photo: "",
      photoimageUrl: "",
    });
  }
  handleNextfunction = (event: Pick<S, never> | null) => {
    if (this.state.activefunction < 2) {
      this.setState((previous) => ({
        ...previous,
        ...event,
        activefunction: previous.activefunction + 1,
      }));
    } else {
      this.setState(
        {
          ...event,
        },
        () => this.joiningFormFunction()

      );
    }
  };
  handleGoBack = () => {
    if (this.state.activefunction === 2) {
      this.setState((previous) => ({
        ...previous,
        activefunction: previous.activefunction - 1,
      }));
    }
  };

  handleFieldChange = (keyItem: string, value: string, setFieldValue: { (field: string, value: string, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }) => {
    setFieldValue(keyItem, value);
    this.setState({ ...this.state, ...{ [keyItem]: value } })
  }
  getValidationSchemafunction = () => {
    if (this.state.activefunction === 1) {
      return JoiningformSchema1;
    } else {
      return JoiningformSchema2;
    }
  };

  handleResponseForCountryList = (from: string, message: Message) => {
    if (this.countryCodeListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponseData, this.props.navigation);
      if (apiResponseData.data) {
        const countrycodeListData = apiResponseData.data.map((item: { id: string; name: string; alpha2: string; country_code: string }) => {
          const { country_code, id, alpha2 } = item
          return { value: Number(id), label: `${country_code} (${alpha2})` }
        });
        this.setState({
          countryCodeListfunction: countrycodeListData
        })

      } else {
        handleApiError(apiResponseData.errors);
      }

    }
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(responseJsonData, this.props.navigation);

      if (apiRequestCallId && responseJsonData) {
        this.setState({ loading: false });

        if (apiRequestCallId === this.getUserProfileApiId) {
          this.getUserProfileResponse(responseJsonData);
        } else if (apiRequestCallId === this.joiningFormApiId) {
          this.JoiningFormResponse(responseJsonData)

        }
      }
    }
    this.handleResponseForCountryList(from, message)

  }

  JoiningFormResponse = (response: { data: object, meta: object, errors: string[] }) => {
    if (response.data || response.meta) {
      toast.success(configJSON.joiningSuccessMessage);
      this.props.navigation.navigate("UserProfile");
    } else {
      handleApiError(response.errors);
    }
  };

  handlenavigationscreen = () => {
    this.props.navigation.navigate("UserProfile");
  };

  getUserProfileResponse = (response: ResponseData) => {
    if (response.data) {
      const companyProfileData = response.data.attributes.company_profile.data;
      if (companyProfileData) {
        const { attributes } = companyProfileData;
        const defaultData = {
          company_name: handleUndefinedData(attributes.name, ""),
        };
        this.setState({ ...defaultData });
      }
      const userProfileData = response.data.attributes.profile.data

      if (userProfileData) {
        const UserdefaultData = {
          owner_name: userProfileData.attributes.owner_name,
          owner_email: userProfileData.attributes.owner_email,
          phone_number: userProfileData.attributes.phone_number,
          country_code_and_flag_id: handleUndefinedData(userProfileData.attributes.country_code_and_flag_id, ""),
          family_name: userProfileData.attributes.family_name,
          date_of_birth: userProfileData.attributes.date_of_birth,
          photo: "",
          photoimageUrl: userProfileData.attributes.photo,
          profileID: response.data.attributes.profile.data.id,
          isEdit: userProfileData ? true : false
        };

        this.setState({ ...UserdefaultData });
      }
    }
  };

  joiningFormFunction = () => {
    this.setState({ loading: true });

    const { isEdit } = this.state;
    const data = {
      owner_email: this.state.owner_email,
      owner_name: this.state.owner_name,
      family_name: this.state.family_name,
      date_of_birth: this.state.date_of_birth,
      phone_number: this.state.phone_number,
      country_code_and_flag_id: this.state.country_code_and_flag_id,
      ...(this.state.photo ? { photo: this.state.photo } : this.state.photoimageUrl ? {} : { photo: "" }),
    };

    this.joiningFormApiId = apiCall({
      method: isEdit ? configJSON.putMethod : configJSON.apiMethodTypeAddDetail,
      body: convertFormData(data),
      endPoint: isEdit ? `${configJSON.JoiningFormEndpoint}/${this.state.profileID}` : configJSON.JoiningFormEndpoint,
      token: true,
    });

  };

  // Customizable Area End
}


