
import React, { Component } from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import { ClassNameMap } from "@material-ui/styles";
import { Box, Button, Typography, Dialog, DialogContent } from "@material-ui/core";
export const configJSON = require("./config");
import { colors } from "../utils/commonDesign";
import CloseIcon from '@material-ui/icons/Close';

// Customizable Area End


// Customizable Area Start
export const styles: Styles<Theme, {}, string> = () => ({
    dialogBox: {
        borderRadius: "16px !important"
    },
    dialoginnerContent: {
        padding: "3rem 6rem !important",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        "& p": {
            fontWeight: 400,
            color: "#020202;",
        },
        "& h4": {
            fontWeight: 700,
            fontSize: "1.6rem",
            color: "#020202",
            margin: "1rem 0"
        }
    },
    buttonContainer: {
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
        ["& button"]: {
            textTransform: "none",
            padding: "0.7rem",
            fontWeight: 600,
            fontSize: "1rem",
            borderRadius: 8,
            minWidth: "300px",
            width:"auto",
            minHeight:"56px",
            height:"auto",
            color: "#020202"
        },
    },
    okButton: {
        backgroundColor: colors.secondaryColor2,
        border: "1px solid transparent",
        ["&:hover"]: {
            backgroundColor: colors.secondaryColor2,
        },
    },
    circleIcon: {
        "& svg": {
            fontSize: "5rem",
            color: "#6D807E"
        }
    },
    closeIcon: {
        position: "absolute",
        right: "26px",
        top: "26px",
        cursor: "pointer",
        "& svg": {
            color: "#010101"
        }
    }

});

interface SuccessModalProps {
    open: boolean;
    onClose?: () => void;
    title: string;
    message: string;
    handleClick?: () => void;
    buttonText?: string;
    closeIcon?: boolean;
    classes: ClassNameMap<"dialogBox" | "dialoginnerContent" | "buttonContainer" | "okButton" | "circleIcon" | "closeIcon"

    >
}

// Customizable Area End

export class SuccessModal extends Component<SuccessModalProps> {
    constructor(props: SuccessModalProps) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { open, onClose, title, classes, message, buttonText, handleClick, closeIcon } = this.props;

        return (

            <Dialog open={open} classes={{ paper: classes.dialogBox }}>
                <DialogContent className={classes.dialoginnerContent}>
                    {closeIcon && <Box data-test-id="close_icon_button" className={classes.closeIcon} onClick={onClose}>
                        <CloseIcon />
                    </Box>}
                    <Box className={classes.circleIcon}>
                        <img src={require("../assets/tick_icon.png")} alt="check" />
                    </Box>
                    <Typography component="h4">{title}</Typography>
                    <Typography data-test-id="title-content">
                        {message}
                    </Typography>
                    {buttonText && <Box className={classes.buttonContainer}>
                        <Button data-test-id="ok-btn" className={classes.okButton} onClick={handleClick}>{buttonText}</Button>
                    </Box>}
                </DialogContent>
            </Dialog>

        );
    }
    // Customizable Area End
}
export default withStyles(styles)(SuccessModal);

