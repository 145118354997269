// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getCompanyOwnerName, getNavigationMessage, getUser } from "../../../components/src/utils/commonFunctions";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: Record<string, string>;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    companyName: string;
    ownerName: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
export default class CreateController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
      // Customizable Area Start
      this.subScribedMessages = [
          getName(MessageEnum.CountryCodeMessage),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.ReciveUserCredentials),
          getName(MessageEnum.RestAPIRequestMessage),
      ];

      this.state = {
          // Customizable Area Start
          companyName: "",
          ownerName: "",
          // Customizable Area End
      };
      // Customizable Area End
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount() {
      const { companyName } = getCompanyOwnerName();
      const user = getUser();
      this.setState({ companyName, ownerName: user?.data.attributes.profile.data.attributes.owner_name });
    }
    handleNavigate = (path: string) => {
      if (path) {
          this.send(getNavigationMessage(path, this.props));
      }
    }
    // Customizable Area End

}

