import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, Avatar, DialogContent, Button, Paper, DialogActions, Modal } from "@material-ui/core";
export const configJSON = require("./config");
import NotificationsModalController, { Props } from "./NotificationsModalController.web";
import Loader from "../../../components/src/Loader";
import { commonButton } from "../../../components/src/utils/commonInputStyle";
import CloseIcon from '@material-ui/icons/Close';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { colors } from "../../../components/src/utils/commonDesign";
import { toRelativeTime } from "../../../components/src/utils/commonFunctions";
// Customizable Area End

// Customizable Area Start
export const styles = {
  ...commonButton,
  roleModel: {
    top: '9vh',
    position: 'absolute' as const,
    right: '3vw',
    maxWidth: 500,
    borderRadius: '8px',
    width: '100%',
    overflowY: 'auto' as const,
    padding: '1rem',
    minHeight: '91vh',
  },
  dialogContent: {
    padding: "0 !important",
    height: `calc(90vh - 90px)`,
  },
  groupBtn: {
    justifyContent: "space-between",
    paddingTop: "1rem",
    borderTop: `1px solid ${colors.secondaryColor1}40`,
    "& button": {
      fontSize: 14,
      textTransform: "unset",
      margin: 0,
      width: "auto",
      maxWidth: 260
    }
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&.notificationHeader": {
     position: "sticky",
     top: "0",
     backgroundColor: "#fff",
     zIndex: "9999",
     borderBottom: `1px solid ${colors.secondaryColor1}40`,
     marginBottom: "25px",
     paddingBottom: "10px",
    },
    "&.notificationList": {
      padding: "12px 10px",
      justifyContent: "start",
      cursor: "pointer",
    },
    "&.unreadNotif": {
      backgroundColor: `${colors.secondaryColor1}30`,
      "& h3": {
        fontWeight: 600
      },
      "&:hover": {
        backgroundColor: `${colors.secondaryColor1}50`,
      }
    },
    "& svg": {
      cursor: "pointer"
    },
    "& h4": {
      fontSize: 24,
      fontWeight: 600
    },
    "& h5": {
      fontSize: 16,
      fontWeight: 600
    },
  },
  profileImg: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    marginRight: 12
  },
  notificationText: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "21.12px",
  },
  duration: {
    fontWeight: 600,
    lineHeight: "18px",
    opacity: 0.4,
    fontSize: "14px",
    "&.markAllAsRead": {
      textTransform: "none",
      fontWeight: 500,
      color: colors.secondaryColor1,
      opacity: 1,
    }
  },
  clickMeLink: {
    cursor: "pointer",
    color: "#4196F0",
    padding: 0,
    fontSize: "1rem",
    "&:hover" : {
      borderBottom: "1px solid #4196F0",
    }
  }
};
// Customizable Area End
export class NotificationsModal extends NotificationsModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Startr
    const { classes, openNotificationModel, handleClose } = this.props;
    const { loading, notification_list } = this.state
    return (
      <>
        <Loader loading={loading} />
        <Modal
          open={openNotificationModel}
          onClose={handleClose}
          aria-labelledby="edit-modal-title"
          aria-describedby="edit-modal-description"
          data-test-id="dialog_box"
        >
          <Paper className={classes.roleModel}>
            <DialogContent className={classes.dialogContent}>
              <Box className={`${classes.content} notificationHeader`}>
                <Typography data-test-id="notification" component="h4">{configJSON.headerTitle}</Typography>
                <CloseIcon onClick={handleClose} />
              </Box>
              {notification_list.length > 0 ? notification_list.map((item) => (
                <Box data-test-id="read-notification" className={`${classes.content} notificationList ${!item.is_read && "unreadNotif"}`} key={item.id} onClick={() => this.handleReadNoti(item)}>
                  <Avatar
                    src={item.creator.photo}
                    className={classes.profileImg}
                  />
                  <Box>
                    <Box>
                    <Typography className={classes.notificationText} data-test-id="notification-title" variant="h3" >
                      {item.remarks}
                      {item.link && 
                        <>
                          {" "}
                          <Typography className={classes.clickMeLink} data-test-id="click-to-view-btn" component="a" onClick={() => this.handleClickToView(item)}>
                            {configJSON.clickToView}
                          </Typography>
                        </>
                      }
                    </Typography>
                    </Box>
                    <Typography component="p" className={classes.duration}>
                        {toRelativeTime(item.created_at)}
                      </Typography>
                  </Box>
                </Box>
              )) : <Typography component="h5" data-test-id="no_data_text">{configJSON.noListMessage}</Typography>}
            </DialogContent>
            <DialogActions className={classes.groupBtn}>
              <Button
                startIcon={<DoneAllIcon />}
                data-test-id="mark-all-btn"
                onClick={this.handleMarkAll}
                className={`${classes.duration} markAllAsRead`}
              >
                {configJSON.markAllAsRead}
              </Button>
              <Button
                data-test-id="view_notification"
                variant="contained"
                onClick={this.handleViewAllClick}
                className={classes.primaryBtn}
              >
                {configJSON.viewNotificationBtn}
              </Button>
            </DialogActions>
          </Paper>

        </Modal>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(NotificationsModal);
// Customizable Area End
