import React from "react";
// Customizable Area Start
import { commonInputField, commonButton, singleValueCss, commonTemplateCss } from "../../../components/src/utils/commonInputStyle";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, TextField, Button, Grid, Switch } from "@material-ui/core";
import Template16Controller, { Props, configJSON } from "./Template16Controller.web"
import { Formik, Form } from 'formik';
import { Wrapper } from "../../../components/src/Wrapper";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import AttachFileInput from "../../../components/src/commonComponents/AttachFileInput.web";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import DropdownSearch from "../../../components/src/commonComponents/DropdownSearch.web";
import { EditTemplate16Schema } from "../../../components/src/utils/validationSchema";
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonButton,
  ...commonInputField,
  ...commonTemplateCss

});
// Customizable Area End

export class Template16 extends Template16Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { attachmentError,loading, attachment, branch_data,formData, department_data, isDownload, templateAction, openMenu, opeDeleteModal } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper backbtn={true} navigation={this.props.navigation} divider title={this.headerTitle()} bellIcn={true} homeIcn={true} child={
          <Box>
            <Formik
              enableReinitialize={true}
              initialValues={this.template16InitialValues()}
              validateOnBlur={true}
              onSubmit={this.addEditTemplate16}
              validationSchema={EditTemplate16Schema}
            >
              {({ errors, handleBlur,touched, handleChange, setFieldValue, values }) => (
                <Form className={`${classes.formContainer} ${isDownload && "textColor"}`} translate>
                  <Grid direction="row" ref={this.pdfContentRef} alignItems="stretch" className={classes.gridContainer} container spacing={5}>
                    <Grid item sm={5} xs={10}  className={classes.gridItem}>
                      {this.isViewOnly() ?
                        <Typography data-test-id="title" variant="h4" className={`${classes.inputLabel2} title`}>
                          {values.title}
                        </Typography> :
                        <Box className={`${classes.fieldWrapper} marginTitle`}>
                          <InputLabel required className={`${classes.inputLabel2} labetText`}>
                            {configJSON.templateTitleLabel}
                          </InputLabel>
                          <TextField
                            type="text"
                            onChange={handleChange}
                            data-test-id="title_input_field"
                            name={configJSON.templateTitleName}
                            error={handleInvoiceErrors(
                              errors.title,
                              touched.title,
                            )}
                            fullWidth
                            variant="outlined"
                            value={values.title}
                            className={classes.inputField}
                            onBlur={handleBlur}
                            helperText={handleInvoiceHelperText(
                              errors.title,
                              touched.title
                            )}
                          />
                        </Box>
                      }

                    </Grid>
                    <Grid sm={7}  item xs={2} className={`${classes.gridItem} actionBtn ${isDownload && "visibility"}`}>
                      {templateAction !== configJSON.create &&
                        <ActionButton
                          edit={this.allowEdit()}
                          deleteVisible={this.allowEdit()}
                          toggleDropdown={this.toggleMenu}
                          download
                          handleEdit={() => this.handleActions(configJSON.edit)}
                          duplicate={this.allowEdit()}
                          handleDownload={this.handleDownloadPdf}
                          isOpen={openMenu}
                          mark_as_favourite
                          favourite={formData.favorite}
                          handleFavourite={this.handleFavouriteClick}
                          handleDuplicate={this.handleDuplicateTemplate}
                          handleDelete={this.handleDelete}
                        />
                      }

                    </Grid>
                    <Grid item className={classes.gridItem} xs={12}>
                      <div className={classes.dropdownContainer}>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            singleValueCss={isDownload && singleValueCss}
                            options={branch_data}
                            value={values.branch_id}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                            isDisabled={this.isViewOnly()}
                            id={configJSON.templateBranchName}
                            isSearchable={true}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            noOptionsMessage={configJSON.templateNoBranchMessage}
                            placeholder={configJSON.templateBranchPlaceholder}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            isMyProfile={true}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            value={values.department_id}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                            singleValueCss={isDownload && singleValueCss}
                            id={configJSON.templateDepartmentName}
                            options={department_data}
                            isSearchable={true}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id,
                            )}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            noOptionsMessage={configJSON.templateNoDepartmentMessage}
                            errorText={handleInvoiceHelperText(
                              errors.department_id,
                              touched.department_id,
                            )}
                            isMyProfile={true}
                            placeholder={configJSON.templateDepartmentPlaceholder}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid sm={6} item className={classes.gridItem}>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          type="text"
                          name={configJSON.description1}
                          variant="outlined"
                          fullWidth
                          value={values.description1}
                          onChange={handleChange}
                          error={handleInvoiceErrors(
                            errors.description1,
                            touched.description1
                            )}
                            onBlur={handleBlur}
                            className={classes.inputField}
                            disabled={this.isViewOnly()}
                          helperText={handleInvoiceHelperText(
                            errors.description1,
                            touched.description1
                          )}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel required className={classes.inputLabel}>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          onChange={handleChange}
                          name={configJSON.description2}
                          value={values.description2}
                          fullWidth
                          variant="outlined"
                          className={classes.inputField}
                          error={handleInvoiceErrors(
                            errors.description2,
                            touched.description2,
                          )}
                          type="text"
                          disabled={this.isViewOnly()}
                          helperText={handleInvoiceHelperText(
                            errors.description2,
                            touched.description2
                            )}
                            onBlur={handleBlur}
                        />
                      </Box>
                     
                    </Grid>
                    <Grid item className={classes.gridItem} sm={6}>
                    <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          type="text"
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          disabled={this.isViewOnly()}
                          value={values.description3}
                          name={configJSON.description3}
                          helperText={handleInvoiceHelperText(
                            errors.description3,
                            touched.description3
                            )}
                          error={handleInvoiceErrors(
                            errors.description3,
                            touched.description3
                          )}
                            className={classes.inputField}
                          onBlur={handleBlur}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          name={configJSON.description4}
                          type="text"
                          disabled={this.isViewOnly()}
                          value={values.description4}
                          className={classes.inputField}
                          onChange={handleChange}
                          error={handleInvoiceErrors(
                            errors.description4,
                            touched.description4
                            )}
                            fullWidth
                          onBlur={handleBlur}
                          helperText={handleInvoiceHelperText(
                            errors.description4,
                            touched.description4,
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={classes.templateFooterContainer}>
                    <Box className={classes.approvalContainer}>
                      <Typography variant="h4" className={`${classes.inputLabel2} approvalText`}>
                        {configJSON.sendForApproval}
                      </Typography>
                      <Switch
                        onChange={handleChange}
                        name="send_for_approval"
                        color="primary"
                        checked={values.send_for_approval}
                        disabled={this.state.templateAction !== configJSON.create}
                      />
                    </Box>
                    <AttachFileInput
                      clearAttachment={this.clearAttachment.bind(null, setFieldValue)}
                      attachment={attachment}
                      isViewOnly={this.isViewOnly}
                      attachmentError={attachmentError}
                      templateAction={templateAction}
                      setFieldValue={setFieldValue}
                      handleFileUpload={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(e, setFieldValue) }}
                    />
                  </Box>
                  <Box className={classes.templateBtnWrapper}>
                    {!this.isViewOnly() &&
                      <Button
                      variant="contained"
                      data-test-id="create-btn"
                      type="submit"
                        className={classes.primaryBtn}
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              open={opeDeleteModal}
              confirmDelete={this.handleDeleteTemplate}
              from={configJSON.procedureFrom}
              title={configJSON.templateDeleteTitle}
              onClose={this.handleDeleteClose}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(Template16);
// Customizable Area End

