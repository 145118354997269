Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";
exports.deleteMethod = "DELETE"

exports.btnExampleTitle = "CLICK ME";

exports.tablist = [
  { label: "My Logs", value: "my_logs" },
  { label: "User Logs", value: "user_logs" },
];
exports.filterMenuList = ["Today","Yesterday","Last 15 days"];
exports.filterByData = "Filter By Date";
exports.yesterday = "Yesterday";
exports.today = "Today";
exports.lastDays = "Last 15 days";
exports.productTemplateText = "Product Info Templates";
exports.menuTemplateText = "Menu Templates"
exports.logTitle = "Logs";
exports.submittedFormText = "Submitted Forms"
exports.submittedChecklistText = "Submitted Checklists";
exports.commonMenuItems = [
  { label: "Submitted Forms", action: "submitted_form" },
  { label: "Submitted Checklists", action: "submitted_checklist" },
];
exports.getBranchApiEndpoint = "bx_block_branch/branches";
exports.getDeptApiEndpoint = "bx_block_departments/selected_departments/";
exports.branchPlaceholder = "Select Branch";
exports.noBranchMessage = "No Branch Found";
exports.noDepartmentMessage = "No Department Found";
exports.departSelect = "Select Department";
exports.getLogsApiEndpoint = "bx_block_activitylog2/logs?";
exports.deleteLogsApiEndpoint = "bx_block_activitylog2/logs";
exports.headerList = ["Name", "Department", "Role","Title","Type", "Submitted Date", "Action"];
exports.headerListMyLogs = ["Title","Department","Type", "Submitted Date", "Action"];
exports.title = "Currently No logs Found";
exports.logFrom = "from my logs";
exports.logDeleteTitle = "log";
exports.formDeleteEndPoint = "bx_block_multipageforms2/form_answers"
exports.checklistDeleteEndPoint = "bx_block_multipageforms2/checklist_answers"
exports.deleteSuccessMessage = "Logs Deleted Successfully";
exports.searchResult = "No search result found for";
exports.noDepartmentMessage = "No Department Found";
exports.editResponse = "Fill";
exports.view = "View";
exports.fromUserLogs = "from userlogs";
exports.date = "Date- ";
exports.clearAll = "Clear All Filter";
// Customizable Area End