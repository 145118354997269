// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  InputLabel,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Formik } from "formik";
import EmailLoginController, { Props } from "./EmailLoginController.web";
import LoginSignupRightSection from "../../../components/src/LoginSignupRightSection.web";
import { styles } from "./EmailLoginStyles.web";
import LoginSignupBackgroundImgWeb from "../../../components/src/LoginSignupBackgroundImg.web";
import {
  handleInvoiceErrors,
  handleInvoiceHelperText,
} from "../../../components/src/utils/commonFunctions";
import { LoginSchema } from "../../../components/src/utils/validationSchema";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start
const logo = require("../../../components/src/qwipLogo.svg");
// Customizable Area End

export class EmailLogin extends EmailLoginController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      email,
      placeHolderEmail,
      password,
      placeHolderPassword,
      enablePasswordField,
      remember_me
    } = this.state;
    return (
      // Required for all blocks
      <React.Fragment>
        <Loader loading={this.state.loading} />
        <LoginSignupBackgroundImgWeb />
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Box className={classes.formContainer}>
              <Box className={classes.headerLogo}>
                <img src={logo} alt="qwipLogo" />
              </Box>
              <Typography variant="h4" className={classes.title} data-test-id="login_text">
                Login
              </Typography>
              <Formik
                enableReinitialize={true}
                validateOnBlur={true}
                initialValues={{
                  email: email,
                  password: password,
                  remember_me:remember_me
                }}
                validationSchema={LoginSchema}
                onSubmit={this.doEmailLogIn}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel}>
                          Email
                        </InputLabel>
                        <TextField
                          data-test-id="txtInputEmail"
                          type="email"
                          name="email"
                          variant="outlined"
                          placeholder={placeHolderEmail}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          className={classes.inputField}
                          error={handleInvoiceErrors(
                            errors.email,
                            touched.email
                          )}
                          helperText={handleInvoiceHelperText(
                            errors.email,
                            touched.email
                          )}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel}>
                          Password
                        </InputLabel>
                        <TextField
                          data-test-id="txtInputPassword"
                          type={enablePasswordField ? "password" : "text"}
                          name="password"
                          variant="outlined"
                          placeholder={placeHolderPassword}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          className={ `${classes.inputField} ${classes.inputPassword}`}
                          error={handleInvoiceErrors(
                            errors.password,
                            touched.password
                          )}
                          helperText={handleInvoiceHelperText(
                            errors.password,
                            touched.password
                          )}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  data-test-id="password-visibility"
                                  onClick={this.handleClickShowPassword}
                                  edge="end"
                                >
                                  {enablePasswordField ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <div className={classes?.rememberContainer}>
                        <FormControlLabel
                          control={
                            <Checkbox
                            data-test-id = "remember_me"
                              name="remember_me"
                              checked={values.remember_me}
                              value={values.remember_me}
                              onChange={handleChange}
                            />
                          }
                          label="Remember me"
                        />
                        <Box
                          id={"btnForgotPassword"}
                          data-test-id={"btnForgotPassword"}
                          onClick={this.goToForgotPassword}
                          className={classes.forgotPass}
                        >
                          Forgot Password?
                        </Box>
                      </div>
                      <Box className={classes.btnWrapper}>
                        <Button
                          data-test-id={"btnEmailLogIn"}
                          variant="contained"
                          fullWidth
                          className={classes.btn}
                          type="submit"
                        >
                          Log In
                        </Button>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </Box>
            <Box className={classes.imageContainer} >
              <LoginSignupRightSection />
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(EmailLogin);
// Customizable Area End
