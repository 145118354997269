import React from "react";

import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  TextField,
  Grid,
  Avatar,
  FormHelperText,
  InputAdornment,
  IconButton
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import EditMyProfileController, {
  Props,
  configJSON,
} from "./EditMyProfileController";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Formik } from 'formik';
import { commonButton, commonInputField } from "../../../components/src/utils/commonInputStyle";
import Loader from "../../../components/src/Loader";
import { Wrapper } from "../../../components/src/Wrapper";
import { MyProfileSchema } from "../../../components/src/utils/validationSchema";
import { allowNumberWithSpaceValidation, handleInvoiceErrors, handleInvoiceHelperText, isBO } from "../../../components/src/utils/commonFunctions";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { GreenEditIcon, RedDeleteIcon } from "../../../components/src/commonComponents/assets";

// Customizable Area End


export class EditMyProfile extends EditMyProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { loading, profileData, countryList, isEdit, position, imgError, profilePicture, countryCodeList, enablePassword, enableNewPassword, enableConfirmNewPassword, isEditPassword } = this.state;
    const { classes } = this.props;

    const passwordContent = (
      values: any,
      touched: any,
      errors: any,
      handleBlur: any,
      handleChange: any,
    ) => (
      <>

        <Box className={classes.fieldWrapper}>
          <InputLabel className={classes.inputLabel}>
            {configJSON.passwordLabel}
          </InputLabel>
          <TextField
            type={enablePassword ? "password" : "text"}
            variant="outlined"
            placeholder={configJSON.passwordPlaceHolder}
            name={configJSON.passwordName}
            value={values.current_password}
            onChange={handleChange}
            fullWidth
            disabled={this.checkDisabledPass()}
            className={classes.inputField}
            error={handleInvoiceErrors(
              errors.current_password,
              touched.current_password
            )}
            onBlur={handleBlur}
            helperText={handleInvoiceHelperText(
              errors.current_password,
              touched.current_password
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!this.checkDisabledPass() && <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    edge="end"
                  >
                    {enablePassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>}
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box className={classes.fieldWrapper}>
          <InputLabel className={classes.inputLabel}>
            {configJSON.newPasswordLabel}
          </InputLabel>
          <TextField
            type={enableNewPassword ? "password" : "text"}
            variant="outlined"
            placeholder={configJSON.newPasswordPlaceHolder}
            name={configJSON.newPasswordName}
            value={values.new_password}
            onChange={handleChange}
            fullWidth
            disabled={this.checkDisabledPass()}
            className={classes.inputField}
            error={handleInvoiceErrors(
              errors.new_password,
              touched.new_password
            )}
            onBlur={handleBlur}
            helperText={handleInvoiceHelperText(
              errors.new_password,
              touched.new_password
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!this.checkDisabledPass() && <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowNewPassword}
                    edge="end"
                  >
                    {enableNewPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>}
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box className={classes.fieldWrapper}>
          <InputLabel className={classes.inputLabel}>
            {configJSON.confirmPasswordLabel}
          </InputLabel>
          <TextField
            type={enableConfirmNewPassword ? "password" : "text"}
            variant="outlined"
            placeholder={configJSON.confirmPasswordPlaceHolder}
            name={configJSON.confirmPasswordName}
            value={values.confirm_new_password}
            onChange={handleChange}
            fullWidth
            disabled={this.checkDisabledPass()}
            className={classes.inputField}
            error={handleInvoiceErrors(
              errors.confirm_new_password,
              touched.confirm_new_password
            )}
            onBlur={handleBlur}
            helperText={handleInvoiceHelperText(
              errors.confirm_new_password,
              touched.confirm_new_password
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!this.checkDisabledPass() && <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {enableConfirmNewPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </>
    )

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Loader loading={loading} />
        <Wrapper data-test-id="edit-profile-wrapper-test" navigation={this.props.navigation} divider backbtn={true} title={isEdit ? configJSON.headerEditTitle : configJSON.headerViewTitle} bellIcn={true} homeIcn={true} child={
          <>
            <Box className={classes.headerContainer}>
              <Typography variant="h4" className={classes.userDetailTitle} data-test-id="user_details">
                {configJSON.userDetails}
              </Typography>
              {!isEdit && <Button
                data-test-id="enable-edit-btn"
                variant="outlined"
                className={`${classes.secondaryBtn} ${classes.editBtn}`}
                startIcon={<img src={GreenEditIcon} alt="GreenEditIcon" style={{ marginRight: "9px" }} />}
                onClick={this.handleEnableEditProfile}
              >
                {configJSON.edit}
              </Button>}
            </Box>

            <Container maxWidth={"xl"} disableGutters style={{marginLeft: 0}}>
              <Box>
                <Formik
                  enableReinitialize={true}
                  validateOnBlur={true}
                  validateOnMount={true}
                  initialValues={profileData}
                  validationSchema={MyProfileSchema}
                  onSubmit={this.editProfile}
                >
                  {({ errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit, handleReset, values }) => (
                    <form className={classes.form} onSubmit={handleSubmit}>
                      {isEdit && <Box className={classes.personalInfoContainer}>
                        <Typography variant="h4" className={classes.infoTitle}>
                          {configJSON.personalInfo}
                        </Typography>
                        <Typography variant="body1" className={classes.infoDescription}>
                          {configJSON.personalInfoDesc}
                        </Typography>
                      </Box>}

                      <Box className={classes.profileImageContainer}>
                        <div className={classes.profileImageOuter}>
                          <Avatar alt="Profile Picture" src={profilePicture} className={classes.avatar} />
                        </div>
                        <Box className={classes.nameDesignationContainer}>
                          <Typography variant="h4" className={classes.userDetailTitle}>
                            {values.owner_name}
                          </Typography>
                          <Typography variant="body1" className={classes.position}>
                            {configJSON.position}{position}
                          </Typography>
                          {(isEdit && !isEditPassword) && <Box><span className={`attachment`} onClick={this.toggleEditPassword}>Change Password</span></Box>}
                        </Box>

                        {isEdit && 
                          <Box className={classes.profileImageContainer}>
                            <div>
                              <input
                                key={profilePicture}
                                accept="image/*"
                                className={""}
                                style={{ display: "none" }}
                                id="profile_picture"
                                type="file"
                                onChange={(e: any) => { this.handleProfileChange(e, setFieldValue) }}
                              />
                              <label htmlFor="profile_picture">
                                <Button
                                  data-test-id="upload-picture-btn"
                                  className={`${classes.primaryBtn} ${classes.uploadPicBtn}`}
                                  component="span"
                                >
                                  {configJSON.uploadPic}
                                </Button>
                              </label>
                            </div>
                            {(isBO() && profilePicture) && <Button
                              data-test-id="delete-btn"
                              variant="outlined"
                              className={`${classes.secondaryBtn} ${classes.editBtn} ${classes.deleteBtn}`}
                              startIcon={<img src={RedDeleteIcon} style={{ marginRight: "15px" }} alt="RedDeleteIcon" />}
                              onClick={this.handleClearProfileImg.bind(this, setFieldValue)}
                            >
                              {configJSON.delete}
                            </Button>}
                          </Box>
                        }
                      </Box>
                      {
                        (!isBO() && !profilePicture) &&
                        <FormHelperText className={`error-text ${classes.helperText}`}>
                          {imgError}
                        </FormHelperText>
                      }

                      <Grid alignItems="stretch" direction="row" container spacing={3}>
                        <Grid xs={12} md={5} item>
                          <Box className={classes.fieldWrapper}>
                            <InputLabel required className={classes.inputLabel}>
                              {configJSON.ownerNameLabel}
                            </InputLabel>
                            <TextField
                              type="text"
                              variant="outlined"
                              placeholder={configJSON.ownerNamePlaceHolder}
                              name={configJSON.ownerNameName}
                              value={values.owner_name}
                              onChange={handleChange}
                              fullWidth
                              disabled={!isEdit}
                              className={classes.inputField}
                              error={handleInvoiceErrors(
                                errors.owner_name,
                                touched.owner_name
                              )}
                              onBlur={handleBlur}
                              helperText={handleInvoiceHelperText(
                                errors.owner_name,
                                touched.owner_name
                              )}
                            />
                          </Box>
                          <Box className={classes.fieldWrapper}>
                            <InputLabel required className={classes.inputLabel}>
                              {configJSON.ownerEmailLabel}
                            </InputLabel>
                            <TextField
                              type="email"
                              variant="outlined"
                              placeholder={configJSON.ownerEmailPlaceHolder}
                              name={configJSON.ownerEmailName}
                              value={values.owner_email}
                              onChange={handleChange}
                              fullWidth
                              disabled={!isEdit}
                              className={classes.inputField}
                              error={handleInvoiceErrors(
                                errors.owner_email,
                                touched.owner_email
                              )}
                              onBlur={handleBlur}
                              helperText={handleInvoiceHelperText(
                                errors.owner_email,
                                touched.owner_email
                              )}
                            />
                          </Box>
                          {
                            isEdit && passwordContent(
                              values,
                              touched,
                              errors,
                              handleBlur,
                              handleChange,
                            )
                          }
                          <Box className={classes.fieldWrapper}>
                            <InputLabel required className={classes.inputLabel}>
                              {configJSON.phoneNumberLabel}
                            </InputLabel>
                            <Box className={classes.phoneGroup}>
                              <Box className={classes.dropdownWidth}>
                                <DropdownSearch
                                  options={countryCodeList}
                                  value={values.country_code_and_flag_id}
                                  onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "country_code_and_flag_id")}
                                  onBlur={handleBlur}
                                  id="country_code_and_flag_id"
                                  isSearchable={true}
                                  placeholder={configJSON.countryCodePlaceholder}
                                  noOptionsMessage={configJSON.noCountryCodeMessage}
                                  isDisabled={!isEdit}
                                  showError={handleInvoiceErrors(
                                    errors.country_code_and_flag_id,
                                    touched.country_code_and_flag_id
                                  )}
                                />
                              </Box>
                              <TextField
                                type="text"
                                variant="outlined"
                                placeholder={configJSON.phoneNumberPlaceHolder}
                                name={configJSON.phoneNumberName}
                                value={values.phone_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                disabled={!isEdit}
                                className={`${classes.inputField} ${classes.inputWidth}`}
                                onKeyPress={
                                  allowNumberWithSpaceValidation
                                }
                                error={handleInvoiceErrors(
                                  errors.phone_number,
                                  touched.phone_number
                                )}
                              />
                            </Box>
                            {(handleInvoiceErrors(errors.phone_number, touched.phone_number) ||
                              handleInvoiceErrors(errors.country_code_and_flag_id, touched.country_code_and_flag_id)) && (
                                <FormHelperText
                                  data-test-id={"errorMessage"}
                                  className={classes.helperText}
                                >
                                  {handleInvoiceHelperText(errors.country_code_and_flag_id, touched.country_code_and_flag_id)}
                                  {handleInvoiceHelperText(errors.phone_number, touched.phone_number)}
                                </FormHelperText>
                              )}
                          </Box>
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel}>
                              {configJSON.countryLabel}
                            </InputLabel>
                            <DropdownSearch
                              options={countryList}
                              value={values.country_id}
                              onChangeValue={(event: any) => this.handleSelectChange(event, setFieldValue, "country_id")}
                              onBlur={handleBlur}
                              id={configJSON.countryName}
                              placeholder={configJSON.countryPlaceHolder}
                              noOptionsMessage={configJSON.noCountryMessage}
                              isSearchable={true}
                              isDisabled={!isEdit}
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      {isEdit &&
                        <Box className={classes.editProfileBtnWrapper}>
                          <Button
                            data-test-id="cancel-btn"
                            variant="outlined"
                            className={classes.secondaryBtn}
                            onClick={() => this.handleDisableEditProfile({ resetForm: handleReset })}
                          >
                            {configJSON.cancel}
                          </Button>
                          <Button
                            data-test-id="edit-btn"
                            variant="contained"
                            className={classes.primaryBtn}
                            type="submit"
                          >
                            {configJSON.save}
                          </Button>
                        </Box>
                      }
                    </form>
                  )}
                </Formik>

              </Box>
            </Container>
          </>
        } />
      </>
      // Customizable Area End
    );
  }
}



// Customizable Area Start
export const styles = {
  ...commonInputField,
  ...commonButton,
  editProfileBtnWrapper: {
    display: "flex",
    marginTop: "100px",
    paddingBottom: "24px",
    justifyContent: "center",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  userDetailTitle: {
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.5rem",
  },
  editBtn: {
    width: "103px",
    height: "42px",
    margin: "0",
    backgroundColor: "white",
    fontWeight: 600,
    border: "1px solid rgba(2, 2, 2, 0.40)",
    "&:hover": {
      backgroundColor: "white !important",
      border: "1px solid rgba(2, 2, 2, 0.40)",
    },
  },
  form: {
    marginTop: "17px",
  },
  personalInfoContainer: {
    padding: "12px 0 10px 0",
  },
  infoTitle: {
    fontSize: "16px",
    lineHeight: "21px",
    fontWeight: 500,
  },
  infoDescription: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    opacity: "0.6"
  },
  avatar: {
    height: "100%",
    width: "100%",
    "& img": {
      objectFit: "contain",
      textAlign: "center",
    }
  },
  position: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 400,
  },
  deleteBtn: {
    width: "142px",
    fontWeight: 500,
  },
  uploadPicBtn: {
    width: "100%",
    maxWidth: "225px",
    height: "42px",
    padding: "0px 30px",
    margin: 0
  },
  profileImageContainer: {
    "& .attachment": {
      color: "#4196F0",
      cursor: "pointer",
      textDecoration: "none",
      borderBottom: "1px solid #4196F0",
      fontSize: "14px !important"
    },
    display: "flex",
    gap: "21px",
    alignItems: "center",
    flexWrap: "wrap" as const,
  },
  profileImageOuter: {
    overflow: "hidden",
    backgroundColor: "white",
    height: "99px",
    width: "99px",
    borderRadius: "50%",
  },
  nameDesignationContainer: {
    marginRight: "16px",
  }
};

export default withStyles(styles)(EditMyProfile);
// Customizable Area End
