// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall, handleApiError, handleExpiredToken, checkToken, sortArrayAlphabetically, isManager, isBO, userBranchId, usersDepartments } from "../../../components/src/utils/commonFunctions";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { selectAllOption } from "../../../components/src/utils/constant";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: Record<string,string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  description: number[];
  department: Lists[];
  roles: OptionType[];
  role: number | null;
  branches: OptionType[];
  branchId: string;
  email: string;
  loading: boolean;
  openModal: boolean;
  managerId: number | null;
  managerName: string;
  supervisor: OptionType[];
  parent_id: number | null;
  departData:DepartmentData[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
interface DepartmentData {
  label:string;
  value: number;
}
// Customizable Area Start
interface Response {
  data: ResData[];

}
interface ResData {
  attributes: {
    id: string | number;
    email: string;
  };
  id: string;
}
interface BranchesData  {
  id:string;
  attributes:{
    branch_name:string;
    location_of_point_of_sale:string
  }
}
interface RoleData  {
  id:string;
  name:string;
}
interface ResponseDepartment {
  id: string;
  departments: {
      id: string;
      name: string;
  }[]
}

interface ResponseErrorData {
  errors: string[]
}

interface Lists {
  id?: number,
  value: string;
  label: string;
}
// Customizable Area End

export default class ManualInviteController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  selectedDepaApiId: string = "";
  getRoleApiId: string = "";
  getBranchesApiId: string = "";
  sendInviteApiId: string = "";
  managerRoleApiId: string = "";
  supervisorRoleApiId: string = "";

  urlParams = new URLSearchParams(window.location.search);
  dataString = this.urlParams.get('data') as string
  decodeData = JSON.parse(decodeURIComponent(this.dataString));    

  initialData = {
    email: "",
    branchId:  this.decodeData ? String(this.decodeData.branch_id) : isManager() ? String(userBranchId) : "",
    description:this.decodeData ? this.decodeData.department_id : [],
    role: null,
    parent_id: null,
    openModal: false,
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      ...this.initialData,
      department: [],
      roles: [],
      branches: [],
      loading: false,
      managerId: null,
      managerName: "",
      supervisor: [],
      departData:[],
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    checkToken(this.props.navigation);
    this.setState({ loading: true });
    this.getBranchesApiId = apiCall({ method: "GET", endPoint: "bx_block_branch/branches", token: true });
    this.getRoleApiId = apiCall({ method: "GET", endPoint: "bx_block_roles_permissions/roles", token: true });
 
    if(!!this.state.branchId){
      this.selectedDepaApiId = apiCall({ method: "GET", endPoint: `bx_block_departments/selected_departments/${this.state.branchId}`, token: true })

    }

    // Customizable Area End
  }
  // Customizable Area Start

  componentDidUpdate(prevProps: Props, previousState: S) {
    if (this.state.branchId !== previousState.branchId && !!this.state.branchId) {
      this.setState({ loading: true, department: [], description: [] });
      this.selectedDepaApiId = apiCall({ method: "GET", endPoint: `bx_block_departments/selected_departments/${this.state.branchId}`, token: true })
    }
    const {role, description, branchId} = this.state;
    if ((role && branchId && (typeof description === "object" ? description.length > 0 : description) ) && (role !== previousState.role || description !== previousState.description)) {
      if (role === 5) {
        this.setState({ loading: true});
        const httpBody = {
          role_id: 4,
          status: "active",
          download: true,
          department_id: description,
          branch_id: parseInt(branchId)
        };
        this.supervisorRoleApiId = apiCall({
          contentType: "application/json",
          method: "POST",
          body: JSON.stringify(httpBody),
          endPoint: `account_block/accounts/get_employees_list`,
          token: true,
        });
      }
      if (role !== 3) {
        const httpBody = {
          role_id: 3,
          status: "active",
          download: true,
          department_id: typeof description === "object" ? description : [description],
          branch_id: parseInt(branchId)
        };
        this.managerRoleApiId = apiCall({
          contentType: "application/json",
          method: "POST",
          body: JSON.stringify(httpBody),
          endPoint: `account_block/accounts/get_employees_list`,
          token: true,
        });
      }
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resJson, this.props.navigation);
      this.setState({ loading: false });

      if (apiCallId && resJson) {
        if (apiCallId === this.selectedDepaApiId) {
          this.getDepartmentsResponse(resJson);
        } else if (apiCallId === this.getRoleApiId) {
          this.getRoleResponse(resJson)
        } else if (apiCallId === this.getBranchesApiId) {
          this.getBranchesResponse(resJson);
        } else if (apiCallId === this.sendInviteApiId) {
          this.getInvitationResponse(resJson);
        } else if (apiCallId === this.managerRoleApiId) {
          this.handleResponseForManager(resJson);
        } else if (apiCallId === this.supervisorRoleApiId) {
          this.handleResponseForSupervisor(resJson);
        }
      }
    }
  }

  getDepartmentsResponse = (response: ResponseDepartment[] & ResponseErrorData) => {
    if (response.length > 0) {
        let finalDapartsData: Lists[] = [];
      if(isBO()){
        response.forEach((item) => {
            item.departments.forEach((department) => {
                const { id, name } = department;
                finalDapartsData.push({ label: name, value: id });
            })
        })
      }else {
        usersDepartments.forEach((department:{attributes:{name:string;id:string}}) => {
            finalDapartsData.push({ label: department.attributes.name, value: department.attributes.id });
        })
      }
        finalDapartsData = sortArrayAlphabetically(finalDapartsData, 'label');
        this.setState({ department: finalDapartsData });
    } else if (response.errors) {
        this.setState({ department: [] });
        handleApiError(response.errors)
    } else {
        this.setState({ department: [] });
        toast.error(configJSON.departmentErrorMessage)
    }
}

  getRoleResponse = (response: RoleData[]) => {
    const formateRole =  response.map(item => ({value: item.id, label: this.capitalizeFirstLetter(item.name)}))
    isManager() ? this.setState({ roles: formateRole.slice(1,3) }) : this.setState({ roles: formateRole })
  }

  getBranchesResponse = (resValue: { branches: { data: BranchesData[]}}) => {
    const branchData = resValue.branches.data;
    let dataItem = resValue.branches.data.map((item) => ({ value: item.id, label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }));
    dataItem = sortArrayAlphabetically(dataItem, 'label');
    const filteredBranch = branchData.filter((branch: { id: string; }) => branch.id === String(userBranchId)).map((item: { id: string | number; attributes: { branch_name: string; location_of_point_of_sale: string; }; }) => ({ value: item.id, label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }));
    if (branchData.length > 0) {
      if (isBO()) {
        this.setState({
          branches: dataItem
        })
      } else {
        this.setState({
          branches: filteredBranch
        })
      }
    } else {
      toast.error(configJSON.branchErrorMessage)
    }
  };

  getInvitationResponse = (response: { data: object; meta: object; errors: string[]; }) => {
    if (response.data && response.meta) {
      this.setState({ openModal: true })
    } else {
      handleApiError(response.errors);
    }
  }

  capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  handleSubmit = (values: { email: string; description: number[]; role: number | null; parent_id: number | null; }) => {
    this.setState({ loading: true });
    const payloadData = {
      attributes: {
        email: values.email,
        assigned_department_ids: (Array.isArray(values.description)) ? this.state.description.filter((item) => String(item) !== selectAllOption.value) : [this.state.description],
        role_id: values.role,
        branch_id: Number(this.state.branchId),
        parent_user_id: (values.role === 5 && values.parent_id) ? values.parent_id : this.state.managerId
      }
    };
    
    const httpBody = {
      data: payloadData,
    };
    this.sendInviteApiId = apiCall({
      contentType: "application/json",
      method: "POST",
      body: JSON.stringify(httpBody),
      endPoint: `account_block/accounts/add_employee`,
      token: true,
    });
  }

  goToHome = () => {
    this.setState({ openModal: false })
    this.props.navigation.history.push(`/HomePage`);
  }

  handleCloseModal = () => {
    this.setState({
      ...this.initialData
    });
  }

  handleResponseForManager = (response: Response) => {
    const isManager = response.data[0]
    if (isManager) this.setState({ managerId: parseInt(isManager.id), managerName: isManager.attributes.email })
    else {
      this.setState({ managerId: null, managerName: "" })
      toast.error(configJSON.addManagerText)
    }
  }

  handleResponseForSupervisor = (response: Response) => {
    const isSupervisor = response.data.map(item  => ({value: Number(item.id), label: item.attributes.email}))
    if (isSupervisor.length > 0) this.setState({ supervisor: isSupervisor })
    else {
      this.setState({ supervisor: [] })
      toast.error(configJSON.noSupervioserText)
    }
  }
  
  handleDeptChange = (event: OptionType, setFieldValue: { (field: string, value: OptionType | string | number[], shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, keyValue: string) => {
    let finalData: OptionType | string | number[] = event;
    if (finalData && typeof finalData === "object" && Array.isArray(finalData)) {
      finalData = event.filter((item: OptionType) => Boolean(item.value !== null)).map((item: OptionType) => item.value)
    }
    else {
      finalData = event?.value
    }

    setFieldValue(keyValue, finalData ?? []);
    this.setState({ ...this.state, [keyValue]: (finalData ?? []) as number[] })
  };

  handleRoleChange = (event: OptionType, setFieldValue: { (field: string, value: number | number[], shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }) => {
    setFieldValue("role", event.value as number)
    isBO() && setFieldValue("description", [])
    this.setState({role: event.value as number, description: isBO() ? [] : this.state.description})
  }

  handleEmailChange = (event: React.ChangeEvent<{ value: string }>, setFieldValue: { (field: string, value: string, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }) => {
    setFieldValue("email", event.target.value)
    this.setState({email: event.target.value})
  }

  // Customizable Area End
}

