const company = require("./company.png")
const branch = require("./branch.png")
const department = require("./department.png")
const employee = require("./employee.png")
const arrowDown = require("./arrowDown.png")
export const treeData = [
    {
        "id": 1,
        "name": "Company",
        "src": company,
        allowedUsers: [2,3,4,5],
        "children": [
            {
                "id": 2,
                "name": "Company Profile",
                "src": arrowDown,
                "children": [],
                "href": "/ViewCompanyProfile",
                allowedUsers: [2,3,4,5]
            },
            {
                "id": 3,
                "name": "Company Docs",
                "src": arrowDown,
                "children": [],
                "href": "/CompanyDoc",
                allowedUsers: [2,3,4,5]
            },
            {
                "id": 4,
                "name": "Hierarchy",
                "href": "/CompanyHierarchy",
                "src": arrowDown,
                "children": [],
                allowedUsers: [2,3,4,5]
            },
            {
                "id": 5,
                "name": "Employee Directory",
                "src": arrowDown,
                "children": [],
                "href":"/EmployeeDirectory",
                allowedUsers: [2,3,4,5]
            }
        ]
    },
    {
        "id": 4,
        "name": "Branch",
        "src": branch,
        allowedUsers: [2,3,4,5],
        "children": [
            {
                "id": 5,
                "name": "Add",
                "src" : arrowDown,
                "children": [],
                "href": "/AddNewBranch",
                allowedUsers: [2]
            },
            {
                "id": 6,
                "name": "Existing",
                "src": arrowDown,
                "children": [],
                "href":"/ExistingBranch",
                allowedUsers: [2,3,4,5]
            }
        ]
    },
    {
        "id": 7,
        "name": "Departments",
        "src": department,
        allowedUsers: [2,3,4,5],
        "children": [
            {
                "id": 8,
                "name": "Add",
                "src": arrowDown,
                "children": [],
                "href": "/AddDepartment",
                allowedUsers: [2]
            },
            {
                "id": 9,
                "name": "Existing",
                "src": arrowDown,
                "children": [],
                "href": "/ExistingDepartment",
                allowedUsers: [2,3,4,5]
            }
        ]
    },
    {
        "id": 10,
        "name": "Invite Employee",
        "src": employee,
        allowedUsers: [2,3],
        "children": [
            {
                "id": 11,
                "name": "Invite",
                "src": arrowDown,
                "children": [],
                "href": "/Invite",
                allowedUsers: [2,3]
            },
            {
                "id": 12,
                "name": "Bulk Invite",
                "src": arrowDown,
                "children": [],
                "isModal": "bulkInvite",
                allowedUsers: [2,3]
            }
        ]
    },
]