import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgChecklist, imgDropdown, imgEmail, imgFileUpload, imgLinearScale, imgLinkUrl, imgMultipleChoice, imgParagraph, imgPhone, imgSingleAnswer } from "./assets";
import { ClassNameMap } from "@material-ui/styles";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { apiCall, checkToken, commonDownloadPdfFunc, convertAllFormData, getUserBranchId, getUserRoleID, handleApiError, handleExpiredToken, handleUndefinedData, isBO, isManager, loggedUserId, ownerName, sortArrayAlphabetically, userBranchId, usersDepartments } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
import React, { RefObject } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    // Customizable Area Start
    classes: ClassNameMap<string>;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    department_list: Lists[];
    branch_list: Lists[];
    formData: FormValues;
    defaultFormData: FormValues;
    loading: boolean;
    isOpen: { [key: string]: boolean };
    action: string;
    openDeleteModal: boolean;
    favorite: boolean;
    openViewMenu: boolean;
    stateValue: FormFillValues;
    defaultStateValue: FormFillValues;
    code_list: DataList[];
    country_code_and_flag_id: string;
    fromLibrary: string;
    fromUserLogs: string;
    is_download:boolean;
    isManager: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start

    // Customizable Area End
}

// Customizable Area Start

export interface DataList {
    id?: number,
    value: string;
    label: string;
}
interface DataPara {
    file_url?: {name: string, url: string};
    file?: File | null;
    text: string;
    value?: null | string;
    id?: string | number;
    country_code_and_flag_id?: string | null;
    phone_number?:string | null

}

interface ResponseErrorData {
    errors: string[]
}

interface FormValues {
    department_id: string;
    branch_id: string;
    form_phone_numbers_attributes: DataPara[];
    form_single_answers_attributes: DataPara[];
    form_paragraphs_attributes: DataPara[];
    form_emails_attributes: DataPara[];
    id: string;
    form_file_uploads_attributes: DataPara[];
    form_link_urls_attributes: DataPara[];
    form_checkboxes_attributes: DataOptions[];
    title: string;
    form_dropdowns_attributes: DataOptions[];
    form_multiple_choices_attributes: DataOptions[];
    form_linear_scales_attributes: DataOptionLinear[];
    creator?:Creator;
    filled_by?:string
}

interface Creator {
    id: string | number;
    email: string;
    name: string;
    designation: string;
}
interface FormFillValues {
    id?: number;
    filled_by: string;
    creator: { name: string, id?: number | string},
    form_phone_number_answers_attributes: DataPara[];
    form_single_answer_answers_attributes: DataPara[];
    form_paragraph_answers_attributes: DataPara[];
    form_email_answers_attributes: DataPara[];
    form_file_upload_answers_attributes: DataPara[];
    form_link_url_answers_attributes: DataPara[];
    form_checkbox_answers_attributes: DataOptions[];
    form_dropdown_answers_attributes: DataOptions[];
    form_multiple_choice_answers_attributes: DataOptions[];
    form_linear_scale_answers_attributes: DataOptionLinear[];
    [keyData: string]: any
}

export interface FormCreator {
  id: number | string;
  name: string;
  designation: string;
  email: string
}
interface ClearValue {
  [key: string]: string
}
interface BranchData {
    attributes: {
        location_of_point_of_sale: string;
        branch_name: string;
    }
    id: string;
}

interface ResponseBranchData {
    branches: {
        data: BranchData[]
    }
}

interface DataOptions {
    options_attributes: {
        option: string,
        id?: any
    }[];
    text: string;
    id?: string | number;
    selected_option?:string | null;
    selected_options?:{id: string | number}[];
}

interface ResponseDepartment {
    departments: {
        id: string;
        name: string;
    }[]
    id: string;
}

export interface Paragraph {
    onChange: (arg: React.ChangeEvent<{ value: string }>) => void;
    isIndex: number
    value: string;
}
export interface OptionField {
    onChangeText: (arg: React.ChangeEvent<{ value: string }>) => void;
    option: string[];
    value: string;
    isKey: keyof FormValues;
    isIndex: number;
}

interface Lists {
    label: string;
    value: string | number;
    id?: number;
    isDisabled?: boolean;
}

interface DataOptionLinear {
    lowest_value: number;
    text: string;
    lowest: string;
    highest_value: number;
    highest: string;
    id?: string | number;
    value?:string | null
}

interface FormResponse {
    data: {
        attributes: {
            favorite: boolean;
            creator: FormCreator;
        } & FormValues
        id: string;
    }
}

// Customizable Area End

export default class FormController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getBranchesApicallId: string = ""
    getDepaApicallId: string = ""
    createFormApiId: string = ""
    getFormApiId: string = ""
    favouriteFormApiId: string = ""
    deleteFormApiId: string = ""
    duplicateFormApiId: string = ""
    countryCodelistCallId: string = ""
    formFillApiCallId: string = ""
    pdfContentRef: RefObject<HTMLDivElement>
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.pdfContentRef = React.createRef();
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        const initialData = {
            branch_id: getUserBranchId() || "",
            department_id: "",
            form_paragraphs_attributes: [],
            form_single_answers_attributes: [],
            title: "",
            form_checkboxes_attributes: [],
            form_multiple_choices_attributes: [],
            form_dropdowns_attributes: [],
            form_linear_scales_attributes: [],
            form_emails_attributes: [],
            form_phone_numbers_attributes: [],
            form_link_urls_attributes: [],
            form_file_uploads_attributes: [],
            id: "",
            filled_by:""

        }
        const formFillInitialData = {
            filled_by: ownerName,
            creator: {
              name: "",
            },
            form_email_answers_attributes: [],
            form_phone_number_answers_attributes: [],
            form_file_upload_answers_attributes: [],
            form_link_url_answers_attributes: [],
            form_paragraph_answers_attributes: [],
            form_single_answer_answers_attributes: [],
            form_checkbox_answers_attributes: [],
            form_multiple_choice_answers_attributes: [],
            form_dropdown_answers_attributes: [],
            form_linear_scale_answers_attributes: []
        }


        this.state = {
            branch_list: [],
            loading: false,
            formData: initialData,
            defaultFormData: initialData,
            department_list: [],
            action: configJSON.create,
            isOpen: {},
            openViewMenu: false,
            favorite: false,
            openDeleteModal: false,
            stateValue: formFillInitialData,
            defaultStateValue: formFillInitialData,
            code_list: [],
            country_code_and_flag_id: "",
            fromLibrary: "",
            fromUserLogs: "",
            is_download: false,
            isManager: isManager()
        };
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            handleExpiredToken(responseJson, this.props.navigation);
            this.setState({ loading: false });

            if (apiCallId && responseJson) {
                if (apiCallId === this.getBranchesApicallId) {
                    this.handleGetBrancheResponse(responseJson);
                } else if (apiCallId === this.getDepaApicallId) {
                    this.handleGetDepartmentsResponse(responseJson);
                } else if (apiCallId === this.createFormApiId) {
                    this.handleCreateFormResponse(responseJson);
                } else if (apiCallId === this.getFormApiId) {
                    this.handleGetFromResponse(responseJson);
                } else if (apiCallId === this.favouriteFormApiId) {
                    this.handleFavouriteResponse(responseJson);
                } else if (apiCallId === this.deleteFormApiId) {
                    this.handleDeleteApiResponse(responseJson);
                } else if (apiCallId === this.duplicateFormApiId) {
                    this.handleDuplicateFormResponse(responseJson);
                }
            }
        }
        this.handleResponseForCountryCodeData(from, message)
        this.handleResponseForFormFill(from, message)
        // Customizable Area End
    }

    // Customizable Area Start

    addFormPanel = [
        {
            src: imgEmail,
            title: configJSON.email
        },
        {
            src: imgPhone,
            title: configJSON.phone
        },
        {
            src: imgFileUpload,
            title: configJSON.fileUpload
        },
        {
            src: imgLinkUrl,
            title: configJSON.linkUrl
        },
        {
            src: imgParagraph,
            title: configJSON.paragraph
        },
        {
            src: imgSingleAnswer,
            title: configJSON.singleAnswer
        },
        {
            src: imgChecklist,
            title: configJSON.checkbox
        },
        {
            src: imgMultipleChoice,
            title: configJSON.multipleChoice
        },
        {
            src: imgDropdown,
            title: configJSON.dropdown
        },
        {
            src: imgLinearScale,
            title: configJSON.linearScale
        }
    ];

    async componentDidMount() {
        super.componentDidMount();
        checkToken();
        const urlParameters = new URLSearchParams(window.location.search);
        const urlId = urlParameters.get('id') as string
        !isBO() && this.getAllDepartment()
        let { state } = this.props.navigation.history.location;
        if (state?.from && state?.from === configJSON.fromLibrary) {
          this.setState({fromLibrary: state?.from, action : state?.action ?? configJSON.view});
        } else if (state?.from && state?.from === configJSON.fromUserLogs) {
          this.setState({fromUserLogs: configJSON.fromUserLogs, action : state?.action ?? configJSON.view});
          this.loadFilledResponse(state.data)
        } else if(!!urlId) {
          this.setState({action: configJSON.fill, fromLibrary: "from library"});
        } else if(![2,3].includes(Number(getUserRoleID()))) {
          this.props.navigation.goBack();
        }

        this.setState({ loading: true })
        if (urlId) {
            this.getFormApiId = apiCall({
                method: "GET",
                endPoint: `${configJSON.createFormApiEndpoint}/${urlId}`,
                contentType: "application/json",
                token: true,
            });
        }
        this.getAllBranches()
        this.countryCodelistCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.countryCodeApiEndPoint, token: true, });

    }

    componentDidUpdate(previousProps: Props, isPrevState: S) {
        if (this.state.formData.branch_id !== isPrevState.formData.branch_id && this.state.formData.branch_id) {
            this.setState({ loading: true, department_list: [] });
            this.getAllDepartment()
        }

    }
    
    addIdInAnswerField = (object: { id: string}) => {
      const dataID = object?.id;
      return dataID ? { id: dataID } : {};
    }

    handleUpdate = (data?:any) => {
      const { creator, id: dataID, form_email_answers_attributes, form_phone_number_answers_attributes, form_file_upload_answers_attributes, form_link_url_answers_attributes, form_paragraph_answers_attributes, form_single_answer_answers_attributes, form_checkbox_answers_attributes, form_multiple_choice_answers_attributes, form_dropdown_answers_attributes, form_linear_scale_answers_attributes
      } = data;
      
      const emailAnswers = this.state.formData.form_emails_attributes.map((item, index) => ({ text: item.text, form_email_id: item.id, value: handleUndefinedData(form_email_answers_attributes[index]?.value, null) , ...(this.addIdInAnswerField(form_email_answers_attributes[index])) }));

      const phoneAnswers = this.state.formData.form_phone_numbers_attributes.map((item, index) => ({ text: item.text, form_phone_number_id: item.id, country_code_and_flag_id: handleUndefinedData(form_phone_number_answers_attributes[index]?.country_code_and_flag_id, null), phone_number: handleUndefinedData(form_phone_number_answers_attributes[index]?.phone_number, null), ...(this.addIdInAnswerField(form_phone_number_answers_attributes[index]))}));

      const fileUploadAnswer = this.state.formData.form_file_uploads_attributes.map((item, index) => ({ text: item.text, form_file_upload_id: item.id, file: null, file_url: form_file_upload_answers_attributes[index]?.file?.url ? form_file_upload_answers_attributes[index].file : null, ...(this.addIdInAnswerField(form_file_upload_answers_attributes[index])) }));
      const urlAnswers = this.state.formData.form_link_urls_attributes.map((item, index) => ({ text: item.text, form_link_url_id: item.id, value: handleUndefinedData(form_link_url_answers_attributes[index]?.value, null), ...(this.addIdInAnswerField(form_link_url_answers_attributes[index])) }));
      
      const paragraphAnswers = this.state.formData.form_paragraphs_attributes.map((item, index) => ({ text: item.text, form_paragraph_id: item.id, value: handleUndefinedData(form_paragraph_answers_attributes[index]?.value, null), ...(this.addIdInAnswerField(form_paragraph_answers_attributes[index])) }));
      
      const singleAnswers = this.state.formData.form_single_answers_attributes.map((item, index) => ({ text: item.text, form_single_answer_id: item.id, value: handleUndefinedData(form_single_answer_answers_attributes[index]?.value, null), ...(this.addIdInAnswerField(form_single_answer_answers_attributes[index])) }));

        const checkboxAnswers = this.state.formData.form_checkboxes_attributes.map((item, index) => ({
            text: item.text, form_checkbox_id: item.id, options_attributes: item.options_attributes,
            selected_options: handleUndefinedData(form_checkbox_answers_attributes[index]?.options_attributes.map(({id: itemId}:{id: number}) => itemId), []), ...(this.addIdInAnswerField(form_checkbox_answers_attributes[index]))
            
        }));
        const multipleChoiceAnswers = this.state.formData.form_multiple_choices_attributes.map((item, index) => ({
            text: item.text, form_multiple_choice_id: item.id, options_attributes: item.options_attributes,
            selected_option: handleUndefinedData(form_multiple_choice_answers_attributes[index]?.options_attributes[0]?.id, null), ...(this.addIdInAnswerField(form_multiple_choice_answers_attributes[index]))
        }));
        const dropdownAnswers = this.state.formData.form_dropdowns_attributes.map((item, index) => ({
            text: item.text, form_dropdown_id: item.id, options_attributes: item.options_attributes,
            selected_option: handleUndefinedData(form_dropdown_answers_attributes[index]?.options_attributes[0]?.id, null), ...(this.addIdInAnswerField(form_dropdown_answers_attributes[index]))
        }));
        const linearAnswers = this.state.formData.form_linear_scales_attributes.map((item, index) => ({ text: item.text, form_linear_scale_id: item.id, highest: item.highest, lowest: item.lowest, highest_value: item.highest_value, lowest_value: item.lowest_value, value: handleUndefinedData(form_linear_scale_answers_attributes[index]?.value, null), ...(this.addIdInAnswerField(form_linear_scale_answers_attributes[index])) }));

        const finalFilledFormData = {
          ...this.state.stateValue,
          creator,
          id: dataID,
          form_email_answers_attributes: emailAnswers,
          form_phone_number_answers_attributes: phoneAnswers,
          form_file_upload_answers_attributes: fileUploadAnswer,
          form_link_url_answers_attributes: urlAnswers,
          form_paragraph_answers_attributes: paragraphAnswers,
          form_single_answer_answers_attributes: singleAnswers,
          form_checkbox_answers_attributes: checkboxAnswers,
          form_multiple_choice_answers_attributes: multipleChoiceAnswers,
          form_dropdown_answers_attributes: dropdownAnswers,
          form_linear_scale_answers_attributes: linearAnswers
      }
        this.setState({
            stateValue: finalFilledFormData,
        })
    }

    loadFilledResponse = (data: FormFillValues) => { 
      const { id : dataID, creator, form_email_answers_attributes, form_phone_number_answers_attributes, form_file_upload_answers_attributes, form_link_url_answers_attributes, form_paragraph_answers_attributes, form_single_answer_answers_attributes, form_checkbox_answers_attributes, form_multiple_choice_answers_attributes, form_dropdown_answers_attributes, form_linear_scale_answers_attributes
      } = data
      
      const finalData = {
        ...this.state.stateValue,
        id : dataID,
        creator,
        form_email_answers_attributes,
        form_phone_number_answers_attributes,
        form_file_upload_answers_attributes,
        form_link_url_answers_attributes,
        form_paragraph_answers_attributes,
        form_single_answer_answers_attributes,
        form_checkbox_answers_attributes,
        form_multiple_choice_answers_attributes,
        form_dropdown_answers_attributes,
        form_linear_scale_answers_attributes
    }

      this.setState({
        defaultStateValue: finalData
    })

    }

    handleInputChange = (keyData: string, index: number, value: string | Blob | MediaSource | number, type?: string) => {

        let dataValue = this.state.stateValue[keyData]
        if (type === "single_checkbox") {
            dataValue[index].selected_option = value
        } else if (type === "multiple_checkbox") {
            let arrays = dataValue[index].selected_options
            const findIndex = arrays?.indexOf(value)
            if (findIndex >= 0) {
                arrays.splice(findIndex, 1)
            } else {
                arrays.push(value)
            }
        } else if (type === "phone_number") {
            dataValue[index].phone_number = value
        } else if (type === "country_code_and_flag_id") {
            dataValue[index].country_code_and_flag_id = value
        } else if (type === "file_attach") {
            if (value instanceof File) {
                dataValue[index].file = value
                dataValue[index].file_url = { name: value.name, url: URL.createObjectURL(value) }
            } else {
                dataValue[index].file = value
                dataValue[index].file_url = ""
            }
        }
        else {
            dataValue[index].value = value
        }
        this.setState({
            stateValue: {
                ...this.state.stateValue,
                [keyData]: dataValue
            }
        })

    };

    getAllBranches = () => {
        this.getBranchesApicallId = apiCall({ method: "GET", endPoint: configJSON.getBranchApiEndpoint, token: true });
    }

    getAllDepartment = () => {
        this.getDepaApicallId = apiCall({ method: "GET", endPoint: `${configJSON.getDeptApiEndpoint}${this.state.formData.branch_id}`, token: true })
    }
    formateFormRrsponse = (formRes: FormResponse) => { 
      const { data: { id: dataID, attributes: { branch_id, department_id, creator, title, form_paragraphs_attributes, form_single_answers_attributes, form_checkboxes_attributes, form_multiple_choices_attributes, form_linear_scales_attributes, form_dropdowns_attributes, favorite, form_emails_attributes, form_phone_numbers_attributes, form_file_uploads_attributes, form_link_urls_attributes } } } = formRes;
      const userName = this.state.fromLibrary ? ownerName : this.state.fromUserLogs ? formRes.data.attributes.creator.name : ""

      const itemData = { branch_id: branch_id.toString(), department_id, creator,filled_by:userName, title, form_paragraphs_attributes, form_single_answers_attributes, form_multiple_choices_attributes, form_checkboxes_attributes, form_dropdowns_attributes, form_linear_scales_attributes, id: dataID, form_emails_attributes, form_file_uploads_attributes, form_link_urls_attributes, form_phone_numbers_attributes };
      this.setState({ formData: itemData, defaultFormData: itemData, favorite })
      this.handleUpdate(this.state.defaultStateValue);

    }
    handleGetFromResponse = (responseItem: FormResponse & ResponseErrorData) => {
      if (responseItem.data) {
        this.formateFormRrsponse(responseItem);
      } else {
        this.handleCancelClick();
        handleApiError(responseItem.errors);
      }
    }
    handleGetDepartmentsResponse = (resData: ResponseDepartment[] & ResponseErrorData) => {
      const defaultItem = { value: "", label: configJSON.departSelect, isDisabled: true }
      if (resData.length > 0) {
          let finalDeptData: { label: string; value: string | number; isDisabled?: boolean; }[] = [];
          isBO() ? resData.map((item) => {
            item.departments.forEach((depItem) => {
                const { id : dataID, name } = depItem;
                return finalDeptData.push({ label: name, value: Number(dataID) });
            })
          }) : usersDepartments.map((depItem: { attributes: { id: number; name: string; }; }) => {
            const { id: dataID, name } = depItem.attributes;
            return finalDeptData.push({ label: name, value: Number(dataID) });
          })
          finalDeptData = sortArrayAlphabetically(finalDeptData, 'label');
          finalDeptData.unshift(defaultItem);
          this.setState({ department_list: finalDeptData });
      } else {
          this.setState({ department_list: [defaultItem] });
          toast.error(configJSON.templateNoDepartmentMessage);
      }
  };

    handleCreateFormResponse = (responseItem: FormResponse & ResponseErrorData) => {
        if (responseItem.errors) {
            handleApiError(responseItem.errors);
        } else {
          this.formateFormRrsponse(responseItem);
          toast.success(configJSON.createFromSuccessMsg);
          if( this.state.action === configJSON.edit) {
            this.setState({action: configJSON.fill});
          }else {
            this.props.navigation.history.push(`Library`, 2)
          }
        }
    }

    handleFavouriteResponse = (response: { message: string[] } & ResponseErrorData) => {
        if (response.errors) {
            handleApiError(response.errors)
        } else {
            this.setState(prev => ({ favorite: !prev.favorite }))
            toast.success(response.message[0])
        }
    }

    handleDeleteApiResponse = (resValue: ResponseErrorData) => {
        if (resValue.errors) {
            handleApiError(resValue.errors)
        } else {
            if(this.state.fromUserLogs) {
              toast.success(configJSON.deleteLogsForm)
              this.props.navigation.history.push(`/UserLogsListing/my_logs/submitted_form`)
            }else {
              toast.success(configJSON.deleteLibraryForm)
              this.props.navigation.history.push(`Library`, 2)
            }
            
        }
    }

    handleGetBrancheResponse = (resData: ResponseBranchData & ResponseErrorData) => {
      const { data } = resData.branches;
      const defaultItem = { value: 0, label: configJSON.branchPlaceholder, isDisabled: true, data: { location_of_point_of_sale: "", branch_name: "" } }
      if (data.length > 0) {
        let updateBranchData1 = isBO() ? data : data.filter((item)=> Number(item.id) === Number(userBranchId));
        const updateBranchData2 = updateBranchData1.map((item: { id: string; attributes: { location_of_point_of_sale: string, branch_name: string; }; }) => {
              const { id: dataID, attributes } = item;
              const { branch_name, location_of_point_of_sale } = attributes;
              return { label: `${branch_name} - ${location_of_point_of_sale}`, value: Number(dataID), data: attributes };
          })
          let updateBranchData3 = sortArrayAlphabetically(updateBranchData2, 'label');
          updateBranchData3.unshift(defaultItem);
          this.setState({ branch_list: updateBranchData3 });
      } else {
          this.setState({ branch_list: [] });
          toast.error(configJSON.branchErrorMessage);
      }
  };

    handleDuplicateFormResponse = (resValue: FormResponse & ResponseErrorData) => {
        if (resValue.errors) {
            handleApiError(resValue.errors)
        } else {
            this.props.navigation.history.push(`Library`, 2)
            toast.success(configJSON.duplicateFormSuccessMsg)
        }
    }

    handleBackClick = () => {
        this.props.navigation.goBack();
    }

    handleAddFormPanelClick = (label: string) => {
        switch (label) {
            case configJSON.checkbox:
                this.addOptionTextField("form_checkboxes_attributes")
                break
            case configJSON.singleAnswer:
                this.addParaText("form_single_answers_attributes")
                break
            case configJSON.paragraph:
                this.addParaText("form_paragraphs_attributes")
                break
            case configJSON.email:
                this.addParaText("form_emails_attributes")
                break
            case configJSON.multipleChoice:
                this.addOptionTextField("form_multiple_choices_attributes")
                break
            case configJSON.dropdown:
                this.addOptionTextField("form_dropdowns_attributes")
                break
            case configJSON.linearScale:
                this.addOptionTextLinearField("form_linear_scales_attributes")
                break
            case configJSON.phone:
                this.addParaText("form_phone_numbers_attributes")
                break
            case configJSON.fileUpload:
                this.addParaText("form_file_uploads_attributes")
                break
            case configJSON.linkUrl:
                this.addParaText("form_link_urls_attributes")
                break
            default:
                break
        }
    }

    addParaText = (keyData: keyof FormValues) => {
        const itemData: DataPara = { text: "" }
        const updatedAttributes = [...this.state.formData[keyData] as DataPara[]]
        updatedAttributes.push(itemData)
        this.setState({ formData: { ...this.state.formData, [keyData]: updatedAttributes } })
    }

    addOptionTextField = (keyData: keyof FormValues) => {
        const itemData: DataOptions = { text: "", options_attributes: [{ option: "" }] }
        const updatedAttributes = [...this.state.formData[keyData] as DataOptions[]]
        updatedAttributes.push(itemData)
        this.setState({ formData: { ...this.state.formData, [keyData]: updatedAttributes } })
    }

    addOptionTextLinearField = (keyData: keyof FormValues) => {
        const itemData: DataOptionLinear = { text: "", highest_value: 2, lowest_value: 0, lowest: "", highest: "" }
        const updatedAttributes = [...this.state.formData[keyData] as DataOptionLinear[]]
        updatedAttributes.push(itemData)
        this.setState({ formData: { ...this.state.formData, [keyData]: updatedAttributes } })
    }

    addOptionField = (isIndex: number, keyData: keyof FormValues) => {
        const newAttribute = [...this.state.formData[keyData] as DataOptions[]]
        const itemData = newAttribute[isIndex]
        const newOption = { option: "" }
        const options_attributes = [...itemData.options_attributes, newOption]
        newAttribute[isIndex] = { ...itemData, options_attributes }
        this.setState({ formData: { ...this.state.formData, [keyData]: newAttribute } })
    }

    deleteOption = (isIndex: number, optionIndex: number, keyData: keyof FormValues) => {
        const updatedAttributes = [...this.state.formData[keyData] as DataOptions[]]
        const itemData = updatedAttributes[isIndex]
        const options_attributes = [...itemData.options_attributes]
        options_attributes.splice(optionIndex, 1)
        updatedAttributes[isIndex] = { ...itemData, options_attributes }
        this.setState({ formData: { ...this.state.formData, [keyData]: updatedAttributes } })
    }

    handleParaChange = (event: React.ChangeEvent<{ value: string }>, isIndex: number, keyData: keyof FormValues) => {
        event.stopPropagation()
        const itemData = { text: event.target.value }
        const updatedAttributes = [...this.state.formData[keyData] as DataPara[]]
        const prevData = updatedAttributes[isIndex]
        updatedAttributes[isIndex] = {...prevData, ...itemData}
        this.setState({ formData: { ...this.state.formData, [keyData]: updatedAttributes } })
    }

    handleOptionFieldTitleTextChange = (event: React.ChangeEvent<{ value: string }>, isIndex: number, keyData: keyof FormValues) => {
        event.stopPropagation()
        const updatedAttributes = [...this.state.formData[keyData] as DataPara[]] as DataOptions[] | DataOptionLinear[]
        const itemData = updatedAttributes[isIndex]
        updatedAttributes[isIndex] = { ...itemData, text: event.target.value }
        this.setState({ formData: { ...this.state.formData, [keyData]: updatedAttributes } })
    }

    handleOptionFieldOptionTextChange = (event: React.ChangeEvent<{ value: string }>, isIndex: number, optionIndex: number, keyData: keyof FormValues) => {
        event.stopPropagation()
        const updatedAttributes = [...this.state.formData[keyData] as DataOptions[]]
        const itemData = updatedAttributes[isIndex]
        const options_attributes = [...itemData.options_attributes]
        options_attributes[optionIndex] = { ...options_attributes[optionIndex], option: event.target.value }
        updatedAttributes[isIndex] = { ...itemData, options_attributes }
        this.setState({ formData: { ...this.state.formData, [keyData]: updatedAttributes } })
    }

    handleLinearFieldChange = (event: React.ChangeEvent<{ value: string }>, isIndex: number, keyData: keyof FormValues, optionalField: keyof { highest: string; lowest: string }) => {
        event.stopPropagation()
        const updatedAttributes = [...this.state.formData[keyData] as DataOptionLinear[]]
        const itemData = updatedAttributes[isIndex]
        itemData[optionalField] = event.target.value
        this.setState({ formData: { ...this.state.formData, [keyData]: updatedAttributes } })
    }

    toggleDropdownMenu = (isKey: keyof FormValues, itemId: number) => {
        this.setState((prevState) => {
            const newIsOpen = { ...prevState.isOpen };

            for (const keyData in newIsOpen) {
                newIsOpen[keyData] = false;
            }

            newIsOpen[`${isKey}-${itemId}`] = !prevState.isOpen[`${isKey}-${itemId}`];

            return {
                isOpen: newIsOpen,
            };
        })

    };

    handleScaleChange = (eventItem: OptionType, isIndex: number, keyData: keyof { highest_value: number; lowest_value: number }) => {
        let finalData: OptionType | string = eventItem;
        if (finalData && typeof finalData === "object" && Array.isArray(finalData)) {
            finalData = eventItem.map((item: OptionType) => item.value)
        } else {
            finalData = eventItem?.value
        }
        const newAttribute = [...this.state.formData.form_linear_scales_attributes] as DataOptionLinear[]
        const itemData = newAttribute[isIndex]
        itemData[keyData] = parseInt(finalData as string)
        this.setState({ formData: { ...this.state.formData, form_linear_scales_attributes: newAttribute } });
    };

    closeDropDown = () => {
        this.setState((prevState) => {
            const newIsOpen = { ...prevState.isOpen };

            for (const keyData in newIsOpen) {
                newIsOpen[keyData] = false;
            }

            return {
                isOpen: newIsOpen,
            };
        });
    }

    removeIdField = (item : any) => {
      const { id, options_attributes, ...rest } = item;
      return {
        ...rest,
        ...(options_attributes && { options_attributes: options_attributes.map(this.removeIdField) }),
      };
      };

    handleFieldDuplicate = (isIndex: number, keyData: keyof FormValues) => {
        const newAttribute = [...this.state.formData[keyData] as DataPara[]];
        newAttribute.splice(isIndex + 1, 0, this.removeIdField(newAttribute[isIndex]))
        this.setState({ formData: { ...this.state.formData, [keyData]: newAttribute } })
        this.closeDropDown()
    }

    handleFieldDelete = (isIndex: number, keyData: keyof FormValues) => {
        const newAttribute = [...this.state.formData[keyData] as DataPara[]]
        newAttribute.splice(isIndex, 1)
        this.setState({ formData: { ...this.state.formData, [keyData]: newAttribute } })
        this.closeDropDown()
    }

    handleDeletedItem = (oldArray: any, newArray: any[]) => {
      const arr3 = oldArray.map((item1: { id: number; options_attributes: {id: string}; }) => {
        const matchingItem = newArray.find((item2: {id: number}) => item1.id === item2.id);
        if (matchingItem) {
          if (item1.options_attributes && matchingItem.options_attributes) {
            const updatedOptions = this.handleDeletedItem(
              item1.options_attributes,
              matchingItem.options_attributes
            );
            return { ...matchingItem, options_attributes: updatedOptions };
          }
          return { ...matchingItem };
        } else {
          return { ...item1, _destroy: true };
        }
      }).concat(newArray.filter((item2: {id: number}) => !item2.id));

      return arr3;
    }

    handleCreateForm = () => {
        const { formData: { title, department_id, branch_id, form_paragraphs_attributes, form_single_answers_attributes, form_multiple_choices_attributes, form_checkboxes_attributes, form_dropdowns_attributes, form_linear_scales_attributes, form_emails_attributes, form_file_uploads_attributes, form_link_urls_attributes, form_phone_numbers_attributes, id: dataID }, action , defaultFormData} = this.state
        const itemData = {
            attributes: {
                title,
                branch_id,
                department_id,
                form_emails_attributes: this.handleDeletedItem(defaultFormData.form_emails_attributes, form_emails_attributes),
                form_file_uploads_attributes: this.handleDeletedItem(defaultFormData.form_file_uploads_attributes, form_file_uploads_attributes),
                form_link_urls_attributes: this.handleDeletedItem(defaultFormData.form_link_urls_attributes, form_link_urls_attributes),
                form_phone_numbers_attributes : this.handleDeletedItem(defaultFormData.form_phone_numbers_attributes, form_phone_numbers_attributes),
                form_paragraphs_attributes : this.handleDeletedItem(defaultFormData.form_paragraphs_attributes, form_paragraphs_attributes),
                form_single_answers_attributes : this.handleDeletedItem(defaultFormData.form_single_answers_attributes, form_single_answers_attributes),
                form_multiple_choices_attributes : this.handleDeletedItem(defaultFormData.form_multiple_choices_attributes, form_multiple_choices_attributes),
                form_checkboxes_attributes : this.handleDeletedItem(defaultFormData.form_checkboxes_attributes, form_checkboxes_attributes),
                form_dropdowns_attributes : this.handleDeletedItem(defaultFormData.form_dropdowns_attributes, form_dropdowns_attributes),
                form_linear_scales_attributes : this.handleDeletedItem(defaultFormData.form_linear_scales_attributes, form_linear_scales_attributes)
            }
        };

        const data2 = {
          attributes: {
              title,
              branch_id,
              department_id,
              form_emails_attributes: form_emails_attributes?.map(item => this.removeIdField(item)),
              form_file_uploads_attributes: form_file_uploads_attributes?.map(item => this.removeIdField(item)),
              form_link_urls_attributes: form_link_urls_attributes?.map(item => this.removeIdField(item)),
              form_phone_numbers_attributes : form_phone_numbers_attributes?.map(item => this.removeIdField(item)),
              form_paragraphs_attributes : form_paragraphs_attributes?.map(item => this.removeIdField(item)),
              form_single_answers_attributes : form_single_answers_attributes?.map(item => this.removeIdField(item)),
              form_multiple_choices_attributes : form_multiple_choices_attributes?.map(item => this.removeIdField(item)),
              form_checkboxes_attributes : form_checkboxes_attributes?.map(item => this.removeIdField(item)),
              form_dropdowns_attributes : form_dropdowns_attributes?.map(item => this.removeIdField(item)),
              form_linear_scales_attributes : form_linear_scales_attributes?.map(item => this.removeIdField(item))
          }
      };
        const httpBody = {
            data: action === configJSON.duplicate ? data2 :  itemData,
        };

        this.createFormApiId = apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.edit === action ?  configJSON.putMethod : configJSON.exampleAPiMethod,
            body: JSON.stringify(httpBody),
            endPoint: `${configJSON.createFormApiEndpoint}/${configJSON.edit === this.state.action ? dataID : ""}`,
            token: true,
        });
    }

    handleFormFill = () => {
        const { formData } = this.state
        const { stateValue: {
            id: dataID,
            filled_by,
            form_checkbox_answers_attributes,
            form_dropdown_answers_attributes,
            form_email_answers_attributes,
            form_file_upload_answers_attributes,
            form_linear_scale_answers_attributes,
            form_link_url_answers_attributes,
            form_multiple_choice_answers_attributes,
            form_paragraph_answers_attributes,
            form_phone_number_answers_attributes,
            form_single_answer_answers_attributes

        } } = this.state

        const payload = {
            filled_by,
            form_id: formData.id,
            form_checkbox_answers_attributes,
            form_dropdown_answers_attributes,
            form_email_answers_attributes,
            form_file_upload_answers_attributes,
            form_linear_scale_answers_attributes,
            form_link_url_answers_attributes,
            form_multiple_choice_answers_attributes,
            form_paragraph_answers_attributes,
            form_phone_number_answers_attributes,
            form_single_answer_answers_attributes
        }

        if (Object.keys(payload).length) {
            this.setState({ loading: true });
            this.formFillApiCallId = apiCall({
                method: dataID ?  configJSON.putMethod : configJSON.exampleAPiMethod,
                body: convertAllFormData(payload, "data[attributes]"),
                endPoint: `${configJSON.formFillApiEndpoint}/${dataID ? dataID : ""}`,
                token: true,
            });
        }
    }


    toggleViewMenu = () => {
        this.setState(prev => ({ openViewMenu: !prev.openViewMenu }))
    }

    handleFavouriteClick = () => {
        const {formData : {id: form_id}, favorite} = this.state;
        this.favouriteFormApiId = favorite ?
        apiCall({
          method: configJSON.deleteMethod,
          endPoint: `${configJSON.createFormApiEndpoint}/${form_id}${configJSON.removeFavorite}`,
          token: true,
        })
        :
        apiCall({
          method: configJSON.putMethod,
          endPoint: `${configJSON.createFormApiEndpoint}/${form_id}${configJSON.addFavorite}`,
          token: true,
        });
        this.toggleViewMenu()
    }

    handleDuplicate = () => {
      this.setState(prev => ({ action: configJSON.duplicate, formData: { ...prev.formData, title: `${prev.formData.title} [${configJSON.duplicate}]` } }))
      this.toggleViewMenu()
  }

    handleSave = () => {
        this.handleFormFill()
    }

    handleSubmitForm = () => {
      const {action} = this.state;
      if ([configJSON.create, configJSON.edit, configJSON.duplicate].includes(action)) {
        this.handleCreateForm();
      }else {
        this.handleSave();
      }
    }

    handleCloseDeleteModal = () => {
        this.setState({ openDeleteModal: false })
    }

    handleOpenDeleteModal = () => {
        this.setState({ openDeleteModal: true, openViewMenu: false })
    }

    handelEditForm = () => {
      this.setState({ action: this.state.fromLibrary ? configJSON.edit : configJSON.fill, openViewMenu: false })
    }

    handleDownloadPdf = () => {
        const elements = this.pdfContentRef.current;
        this.setState({is_download:true,loading:true,openViewMenu:false})
        const handleLoading = () => {
        this.setState({loading:false,is_download:false})
    }
    commonDownloadPdfFunc(elements,handleLoading,`Form`,`${this.state.formData.title}`)

    };

    handleDeleteClick = () => {
      const {formData , stateValue, fromUserLogs} = this.state
        const endPoint = fromUserLogs ? `${configJSON.formFillApiEndpoint}/${stateValue.id}` : `${configJSON.createFormApiEndpoint}/${formData.id}`
        this.deleteFormApiId = apiCall({ method: "DELETE", endPoint, token: true });
    }

    handleCancelClick = () => {
        this.setState({
            formData: {
                branch_id: "",
                department_id: "",
                form_paragraphs_attributes: [],
                form_single_answers_attributes: [],
                title: "",
                form_checkboxes_attributes: [],
                form_multiple_choices_attributes: [],
                form_dropdowns_attributes: [],
                form_linear_scales_attributes: [],
                id: "",
                form_emails_attributes: [],
                form_file_uploads_attributes: [],
                form_link_urls_attributes: [],
                form_phone_numbers_attributes: [],
            }
        })
    }
    handleCancel = () => {
        this.props.navigation.history.push(`Library`, 2)
    }
    handleCancelForm = () => {
      const {action, defaultFormData, fromLibrary, defaultStateValue} = this.state;
      if (action === configJSON.create) {
        this.handleCancelClick();
      } else if (action === configJSON.edit) {
        this.setState({action: fromLibrary ? configJSON.fill  : configJSON.view, formData: defaultFormData})
      }else if (action === configJSON.fill) {
        this.handleUpdate(defaultStateValue);
        this.setState({action: fromLibrary ? configJSON.fill  : configJSON.view})
      } else {
        this.handleCancel();
      }
    }

    handleSelectChange = (eventItem: OptionType, keyData: keyof FormValues, clearValue?: ClearValue) => {
        let finalDataValue: OptionType | string = eventItem?.value;
        if (clearValue && (this.state.formData[keyData] !== finalDataValue)) {
            this.setState({ formData: { ...this.state.formData, [keyData]: finalDataValue, ...clearValue } });
        } else {
            this.setState({ formData: { ...this.state.formData, [keyData]: finalDataValue } });
        }
    };
    handleResponseForCountryCodeData = (from: string, message: Message) => {
        if (this.countryCodelistCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseItem = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(responseItem, this.props.navigation);
            if (responseItem.data) {
                const itemdata = responseItem.data.map((item: { id: string; name: string; alpha2: string; country_code: string }) => {
                    const { id: dataID, country_code, alpha2 } = item
                    return { value: Number(dataID), label: `${country_code} (${alpha2})` }
                });
                this.setState({
                    code_list: itemdata
                })

            } else {
                handleApiError(responseItem.errors);
            }

        }
    }
    handleResponseForFormFill = (from: string, message: Message) => {
        if (this.formFillApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseItem = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(responseItem, this.props.navigation);
            if (responseItem.data) {
              if(this.state.defaultStateValue.id) {
                toast.success(configJSON.submitEditFormSuccessMsg);
                this.loadFilledResponse(responseItem.data.attributes);
                this.handleUpdate(this.state.defaultStateValue);
              } else {
                  toast.success(configJSON.submitFormSuccessMsg)
                  this.props.navigation.history.push(`Library`, 2)
                }
            }  else {
                handleApiError(responseItem.errors)
            }
        }
    }

    formHeaderTitle = () => {
      const { action, defaultStateValue } = this.state;
      let start = '';

      if (action === configJSON.create) {
        start = configJSON.create;
      } else if (action === configJSON.view) {
        start = configJSON.view;
      } else if (action === configJSON.edit) {
        start = configJSON.edit;
      } else if (action === configJSON.duplicate) {
        start = configJSON.duplicate;
      } else if (action === configJSON.fill && defaultStateValue.id) {
        start = "Edit " + configJSON.fill;
      } else if (action === configJSON.fill) {
        start = configJSON.fill;
      }


      return `${start} ${configJSON.formTitle}`;
    }

    allowEditForm = () => {
      const {formData, fromUserLogs, stateValue} = this.state
      const itemData = formData
      return !!((fromUserLogs ? stateValue?.creator?.id === loggedUserId : ((itemData?.creator?.id === loggedUserId) || isBO())));
    }

allowEditBranchDepart = () => {
      const {formData, action} = this.state
      const itemData = formData

      if(configJSON.create === action) {
        return false
      } else if(configJSON.edit === action) {
        return (itemData?.creator?.id !== loggedUserId)
      } else {
        return true
      }
    }

    // Customizable Area End
}
