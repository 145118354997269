// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ClassNameMap } from "@material-ui/styles";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    classes:ClassNameMap<"rootSearch" | "input" | "iconButton" | "divider">
    setQuery: React.Dispatch<React.SetStateAction<string>>;
    handleEnter:(e: { key: string; })=> void;
    query:string;
    searchHandleClick:(query:string)=> void;
    searchCloseClick:()=> void;
    querySearch:string | undefined;
    searchData?:any;
    autoFocus?:boolean
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start

// Customizable Area End

export default class AdvanceSearchBarController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount(): any {
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
  
    // Customizable Area End

}
