
import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { Favorite } from '@material-ui/icons';
export const configJSON = require("./config");
import ChecklistController, { Props } from "./ChecklistController.web";
import { libraryIcon, check_List_Bold_Icon } from "./assets";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import NoTickets from "../../../components/src/commonComponents/NoTickets.web";
import Pagination from "../../../components/src/commonComponents/Pagination.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import Loader from "../../../components/src/Loader";
import {styles} from "./ProcedureListing.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
class Checklist extends ChecklistController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    checkListView = (classes: Record<string, string>) => (
        <Box data-test-id="pdf-content" className={classes.listItem}>
            <Box className={classes.tableHeader}>
                {this.state.header_list.map((item, index) => (
                    <Typography component="p" key={index}>
                        {item}
                    </Typography>
                ))}
            </Box>
            <Box className={this.state.checkListData.length === 0 ? classes.listMain : ""}>
                {this.state.checkListData.length > 0 ? (
                    this.state.checkListData.map((item, index) => {
                        return (
                            <Box key={item.attributes.id} className={classes.tableItem}>
                                <Box className={classes.imgContainer}>
                                    <img className={classes.templateImgListView} src={check_List_Bold_Icon} alt="check_List_Bold_Icon" />
                                </Box>
                                <Typography component="p" data-test-id="checklist_title">
                                    {item.attributes.title}
                                </Typography>
                                <Typography component="p"></Typography>
                                <Typography component="p">
                                    {this.getFormattedDate(item.attributes.created_at)}
                                </Typography>
                                <Box display={"flex"} >
                                    <ActionButton 
                                   deleteVisible={this.props.allowEditDelete(item.attributes.creator?.id)}
                                    handleDelete={this.handleOpenDeleteModal} 
                                    mark_as_favourite 
                                    handleFavourite={() => this.handleFavouriteClick(item.attributes.favorite)} 
                                    moreVert 
                                    isOpen={this.state.isOpen[item.attributes.id]} 
                                    toggleDropdown={() => this.toggleDropdown(item.attributes.id)} 
                                    view 
                                    handleView={() => this.handleViewClick(item.attributes.id)} 
                                    favourite={item.attributes.favorite} data-test-id="activeButtonListTestId" />
                                </Box>
                                {
                                    Boolean(item.attributes.favorite) &&
                                    <Box>
                                        <Box
                                            borderRadius={"50%"}
                                            border={"1px solid #0000003b"}
                                            height={"fit-content"}
                                            width={"fit-content"}
                                            padding={"6px"}>
                                            <Favorite className={classes.favouriteIcn} />
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        );
                    })
                ) : (
                    <NoTickets title={configJSON.checklistNoDataTxt} imagePath={libraryIcon} />
                )}
            </Box>
        </Box>
    )
    checkGridView = (classes: Record<string, string>) => (
        <Grid container spacing={2}  className={`${this.state.checkListData.length > 0 ? "" : classes.noTicketOuterContainer}`}>
            {(Boolean(this.state.checkListData.length)) ?
                this.state.checkListData.map(item => (
                    <Grid key={item.attributes.id} item lg={3} md={4} sm={6} xs={12}>
                        <Box className={classes.cardWrapper}>
                            <Box display={"flex"} justifyContent={"flex-end"}>
                                <ActionButton
                                    handleDelete={this.handleOpenDeleteModal}
                                    deleteVisible={this.props.allowEditDelete(item.attributes.creator?.id)}
                                    mark_as_favourite
                                    handleFavourite={() => this.handleFavouriteClick(item.attributes.favorite)}
                                    isOpen={this.state.isOpen[item.attributes.id]}
                                    toggleDropdown={() => this.toggleDropdown(item.attributes.id)}
                                    view
                                    handleView={() => this.handleViewClick(item.attributes.id)}
                                    favourite={item.attributes.favorite}
                                    data-test-id="activeButtonTestId"
                                />
                            </Box>
                            <Box className="cursor" onClick={() => this.handleViewClick(item.attributes.id)}>
                                <Box display={"flex"} justifyContent={"center"}>
                                    <img className={classes.templateImg} src={check_List_Bold_Icon} alt={item.attributes.title} />
                                </Box>
                                <Box p={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box width={"90%"}>
                                        <Typography noWrap data-test-id="title" className={classes.procedureTitleTxt}>{item.attributes.title}</Typography>
                                        <Typography className={classes.dateTxt}>{configJSON.date}{this.getFormattedDate(item.attributes.created_at)}</Typography>
                                    </Box>
                                    {Boolean(item.attributes.favorite) && <Favorite className={classes.favouriteIcn} />}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                )) :
                <Box className={classes.noTicketContainer} >
                    <NoTickets
                        title={configJSON.checklistNoDataTxt}
                        imagePath={libraryIcon}
                    />
                </Box>
            }
        </Grid>
    )



    ProcedureViewer = (classes: Record<string, string>) => {

      const { searchQuery } = this.props;
      const { checkListData, loading, meta } = this.state

        if (checkListData.length > 0) {
          return (
            <>
              {this.props.gridView ? this.checkGridView(classes) : this.checkListView(classes)}
              <Box className={classes.paginationBox}>
                  <Pagination onPageChange={this.handlePageChange} meta={meta} />
              </Box>
          </>
          )
      } else if (checkListData.length === 0 && !loading && Boolean(searchQuery)) {
          return (
              <Box className={classes.noTicketContainer}>
                  <Typography variant="h5">No search result found for <strong>{searchQuery}</strong>.</Typography>
              </Box>
          )
      } else if (checkListData.length === 0 && !loading) {
          return (
              <Box className={classes.noTicketContainer} >
                  <NoTickets
                      title={configJSON.checklistNoDataTxt}
                      imagePath={libraryIcon}
                  />
              </Box>
          )
      }
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Loader loading={this.state.loading} />
                <Box className={`${this.state.checkListData.length > 0 ? "" : classes.noTicketOuterContainer}`} pt={2}>
                    {this.ProcedureViewer(classes)}
                </Box>
                {this.state.openDeleteModal &&
                    <ConfirmationModal
                        open={this.state.openDeleteModal}
                        onClose={this.handleCloseDeleteModal}
                        confirmDelete={this.handleDeleteClick}
                        title={configJSON.checklistDeleteTitle}
                        from={configJSON.checklistFrom}
                        data-test-id="confirmModalTestId"
                    />}
            </React.Fragment>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default withStyles(styles)(Checklist);
export { Checklist };
// Customizable Area End
