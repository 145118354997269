import { colors } from "../../../components/src/utils/commonDesign";
import { commonButton } from "../../../components/src/utils/commonInputStyle";
export const styles: any = {
    ...commonButton,
    fieldWrapper: {
        margin: "10px 0 0px",
        position: "relative" as any,
        "& .MuiInputBase-root": {
            borderRadius: "10px",
            backgroundColor: "#fff",
            height: "56px",
            "& .MuiInputBase-input": {
                fontSize: "16px",
                lineHeight: "21px",
                "& .Mui-disabled": {
                    color: "#020202",
                    backgroundColor: "#F5F5F5",
                    borderRadius: "10px",
                },
            },
        },
        "& .MuiOutlinedInput-root.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.borderColor,
            },
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.borderColor,
        },
        "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${colors.borderColor}`
            },
        },
        "& .attachment": {
            textAlign: "right",
            color: "#4196F0",
            cursor: "pointer",
            marginTop: 2,
            "& span,a": {
                borderBottom: "1px solid #4196F0",
                fontSize: "14px !important",
                textDecoration: "none",
                color: "#4196F0",
            }
        },
        "& .MuiFormHelperText-root": {
          color: colors?.errorText,
          margin: "5px 0px -23px",
          fontSize: "14px",
          lineHeight: "18px",
        },
    },
    label: {
        margin: "10px 0px ",
        color: colors.text,
        fontSize: "14px",
    },
    elabel: {
        color: colors.text,
        fontSize: "14px",
    },
    input: {
        borderRadius: "10px",
        background: "#fff",
        "& ::placeholder": {
            color: "black",
            fontWeight: 300,
        },
        "& .MuiInputBase-root": {
            borderRadius: "10px",
        },
    },
    checkImg: {
        width: "10rem",
        height: "10rem"
    },
    modalSuccessTxt: {
        padding: "20px 0px",
        fontFamily: "switzer",
        color: "#020202",
        margin: "0px !important"
    },
    modalSuccessHeadline: {
        color: colors.text,
        fontSize: "25px !important",
        fontWeight: "600 !important",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        margin: "10px 0px 0px !important",
    },
    iconSelectStyle: {
        marginRight: "9px",
        color: colors.text
    },
    menuItem:{
        fontWeight: 300,
        color: "#6d827f"
    },
    menuItemDisplay:{
        display:"none"
    },
    buttonBox:{
        display:"flex", 
        justifyContent:"center",
        width:"100%",
        paddingTop:30
    },
    formContainer:{
        width:"60%",
         height: "100%",
         marginTop:"20px"
    },
    successModelBox:{
        display:"flex",
         flexDirection:"column",
          alignItems:"center",
         paddingTop: 4
    }

}