// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { ClassNameMap } from "@material-ui/styles";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import CommonTemplateController from "./CommonTemplateController.web";
import { handleApiError, handleExpiredToken, checkToken, getDiffs, handleUndefinedData, getUserRoleID } from "../../../components/src/utils/commonFunctions";
import { CreateTemplate3Schema, EditTemplate3Schema } from "../../../components/src/utils/validationSchema";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"formContainer" | "inputField" | "dateField"
    | "fieldWrapper" | "inputLabel" | "helperText" | "textAreaField" | "textAreaInputField" | "templateBtnWrapper"
     | "fileInput" | "primaryBtn" | "secondaryBtn" | "inputLabel2" | "approvalContainer" | "dropdownWrapper" | "uploadImageContainer" | "titleText" | "gridItem" | "templateFooterContainer"
  >;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class Template3Controller extends CommonTemplateController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      ...this.initialState,
      ...this.state
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidMount(): any {
    // Customizable Area Start
    this.getBranchData();
    checkToken();
    let { state } = this.props.navigation.history.location;
    if (state) {
      this.setDefaultData(state)
    } else if(![2,3].includes(Number(getUserRoleID()))) {
      this.props.navigation.goBack();
    }
    // Customizable Area End
  }

  setDefaultData(data: any, action?: string) {
    const { id, attachment, descriptions_attributes, creator } = data
    this.setState({
      id,
      defaultData: data,
      formData: data,
      imageUrls: {
        image1: handleUndefinedData(descriptions_attributes[0].images[0], ""),
        image2: handleUndefinedData(descriptions_attributes[1].images[0], "")
      },
      attachment: attachment.url ? attachment : "",
      templateAction: action ?? configJSON.view,
      creator
    })
  }

  template3InitialValues = () => {
    const { defaultData, formData, templateAction } = this.state;
    const isCreateTemplate = templateAction === configJSON.create;
    const data = {
      branch_id: isCreateTemplate ? defaultData.branch_id : handleUndefinedData(formData?.branch_id, ""),
      department_id: isCreateTemplate ? defaultData.department_id : handleUndefinedData(formData?.department_id, ""),
      title: isCreateTemplate ? defaultData.title : handleUndefinedData(formData?.title, ""),
      attachment: null,
      description1: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[0]?.description, ""),
      send_for_approval: isCreateTemplate ? defaultData.send_for_approval : handleUndefinedData(formData?.send_for_approval, false),
      description2: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[1]?.description, ""),
      description3: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[2]?.description, ""),
      description4: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[3]?.description, ""),
      image1: null,
      image2: null,
      image3: null,
      image4: null,
    }
    return data;
  }
  template3ValidationSchema = () => {
    const { templateAction } = this.state;
    return templateAction === configJSON.edit ? EditTemplate3Schema : CreateTemplate3Schema
  }

  addEditTemplate3 = (values: any) => {
    const { formData, defaultData, template_id } = this.state;

    const { department_id,title, send_for_approval, attachment, branch_id, descriptions_attributes } = defaultData;
    const {description3, description1, description2, description4, image1, image2,image3, image4 } = values;

    const { id } = formData;

    const createDescriptionsAttr = [
      {
        description: description1,
        images: [image1]
      }, {
        description: description2,
        images: [image2]
      },{
        description: description3,
        images: [image3]
      },{
        description: description4,
        images: [image4]
      }
    ];

    const editDescriptionsAttr = () => {
      return [
        {
          description: description1,
          id: this.state.formData.descriptions_attributes[0].id,
          ...(image1 ? { images: [image1] } : {}),
        }, {
          description: description2,
          id: this.state.formData.descriptions_attributes[1].id,
          ...(image2 ? { images: [image2] } : {}),
        },
        {
          description: description3,
          id: this.state.formData.descriptions_attributes[2].id,
          ...(image3 ? { images: [image3] } : {}),
        },
        {
          description: description4,
          id: this.state.formData.descriptions_attributes[3].id,
          ...(image4 ? { images: [image4] } : {}),
        }
      ];

    }


    const orriginalData: any = {
      title,
      branch_id,
      department_id,
      descriptions_attributes,
      send_for_approval,
      ...(attachment ? { attachment } : {}),
    };

    const attrs: any = {
      title: values.title,
      send_for_approval: values.send_for_approval,
      department_id: values.department_id,
      ...(values.attachment ? { attachment: values.attachment } : this.checkEmptyAttachment()),
      ...(!id ? { template_id } : {}),
      branch_id: values.branch_id,
      descriptions_attributes: id ? editDescriptionsAttr() : createDescriptionsAttr,
    };

    let templatePayload: any = getDiffs(orriginalData, attrs);
    this.addEditTemplateApiCall(templatePayload)
  }

  addTemplate3Response = (responseData: any, message: string) => {
    if (responseData.data) {
      if (this.state.id) {
        const dataItem3 = responseData.data.attributes
        this.setStateData(dataItem3);
        this.setDefaultData(dataItem3)
      } else {
        this.setState({ ...this.initialState });
        this.navigateToListingPage()
      }
      this.handleActions(configJSON.view)
      toast.success(message);
    } else if (responseData.message) {
      const {defaultData} = this.state
      const dataItem3 = {...defaultData, favorite: !defaultData.favorite};
      this.setStateData(dataItem3);
      this.setDefaultData(dataItem3)
      toast.success(message);
    } else {
      handleApiError(responseData.errors);
    }
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const requestMessageCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resData, this.props.navigation);
      this.setState({ loading: false });
      if (requestMessageCallId && resData) {
        this.setState({ loading: false });
        switch (requestMessageCallId) {
          case this.createTemplateApiId:
            this.addTemplate3Response(resData, configJSON.procedureSuccessMessage);
            break;
          case this.duplicateTemplateApiId:
            this.addTemplate3Response(resData, configJSON.procedureDuplicateMessage);
            break;
          case this.deleteTemplateApiId:
            this.deleteTemplateResponse(resData);
            break;
          case this.branchDataApiId:
            this.getBranchesResponse(resData);
            break;
          case this.departmentsApiId:
            this.getDepartmentsResponse(resData);
            break;
          case this.favouriteTemplateApiId:
            const toastMessage = this.state.formData.favorite ? configJSON.procedureUnFavMessage : configJSON.procedureFavMessage;
            this.addTemplate3Response(resData, toastMessage);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
