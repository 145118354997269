// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { Priorites, selectAllOption } from "../../../components/src/utils/constant";
import { apiCall, convertFormData, handleApiError, handleExpiredToken, checkToken, sortArrayAlphabetically, getNavigationMessage, isBO, userBranchId, usersDepartments, handleUndefinedData } from "../../../components/src/utils/commonFunctions";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: Record<string,string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  attach_image: {name: string ,url: File | string} | null;
  apiError: string;
  branch_data: ListData[];
  department_data: ListData[];
  employees_Data: ListData[];
  priorities_data: ListData[];
  formData: FormValues;
  open: boolean
  defaultData: FormValues,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
// Customizable Area Start
export interface FormValues {
  title: string;
  description: string;
  branch_id: string;
  department_id: string;
  assignee_ids?: string[];
  due_date: string | Date;
  attach_image?: File | null;
  priority: string;
}
export interface ListData {
  id?: number,
  value: string;
  label: string;
}
interface ClearValue {
  [key: string]: string | string[]
}
// Customizable Area End

export default class CreateTaskController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createTaskCallId: string = "";
  ticketTypeDataCallId: string = "";
  branchDataCallId: string = "";
  departmentDataCallId: string = "";
  employeeDataApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    const initialData = {
      title: "",
      description: "",
      priority: "",
      due_date: new Date(),
      ticket_type_id: "",
      branch_id: "",
      department_id: "",
      assignee_ids: [],
      attach_image: null,
    }

    this.state = {
      loading: false,
      attach_image: null,
      apiError: "",
      branch_data: [],
      department_data: [],
      employees_Data: [],
      priorities_data: Priorites,
      formData: initialData,
      defaultData: initialData,
      open: false

    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    checkToken();
    this.branchDataCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: `${configJSON.createBranchApiEndpoint}`, token: true, });
    const { state } = this.props.navigation.history.location;
    const formData = {
      priority: handleUndefinedData(state?.priority, ""),
      title: handleUndefinedData(state?.title, ""),
      department_id: handleUndefinedData(state?.department_id, ""),
      assignee_ids:[],
      description: handleUndefinedData(state?.description, ""),
      due_date: handleUndefinedData(state?.due_date, new Date()),
      attach_image: null,
      branch_id: handleUndefinedData(state?.branch_id, ""),
    }
    this.setState({ formData: { ...this.state.formData, ...formData }, defaultData: formData })
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: Props, previousState: S){
    const { formData } = this.state;
    const { branch_id, department_id } = formData;
    if (branch_id && formData.branch_id !== previousState.formData.branch_id) {
      this.setState({ loading: true });
      this.getDepartmentData()
    }
    if ((branch_id && department_id) && (formData.department_id !== previousState.formData.department_id || formData.branch_id !== previousState.formData.branch_id)) {
      this.setState({ loading: true });
      this.getEmployeeData()
    }
  }
  handleOkClick = () => {
    this.send(getNavigationMessage('TaskListView', this.props));
  }

  handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: File | null) => void
  ): void => {
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];

      if (file) {
        const allowFile = file.size <= 5 * 1024 * 1024;

        if (allowFile) {
          this.setState({
            attach_image: {
              name: file.name,
              url: URL.createObjectURL(file),
            },
            apiError: "",
          });
          setFieldValue("attach_image", file);
        } else {
          this.handleClearValue(setFieldValue);
          this.setState({ apiError: configJSON.fileSizeError});
        }
      }
    }
  };

  handleClearValue = (setFieldValue: { (field: string, value:File, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: null): void; }) => {
    this.setState({
      attach_image: null,
      apiError: "",
    });
    setFieldValue("attach_image", null)
  };
  handleSelectChange = (event: OptionType, setFieldValue: { (field: string, value: string | string[] | undefined | OptionType, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, keyValue: keyof FormValues, clearValue?: ClearValue | null | undefined) => {
    let finalResult: OptionType | string = event;
    if (finalResult && typeof finalResult === "object" && Array.isArray(finalResult)) {
      finalResult = event.map((item: OptionType) => item.value)
    } else {
      finalResult = event?.value
    }

    if (clearValue && (this.state.formData[keyValue] !== finalResult)) {
      setFieldValue(keyValue, finalResult);
      this.setState({ formData: { ...this.state.formData, [keyValue]: finalResult, ...clearValue } });
      for (let clearItem of Object.keys(clearValue)) {
        setFieldValue(clearItem, clearValue[clearItem]);
      }
    } else {
      setFieldValue(keyValue, finalResult);
      this.setState({ formData: { ...this.state.formData, [keyValue]: finalResult } });
    }
  };
  handleResponseForBranchData = (from: string, message: Message) => {
    if (this.branchDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.branches.data) {
        let updatedData = !isBO() ? apiResponse.branches.data.filter((item: { id: number; })=> Number(item.id) === Number(userBranchId)) : apiResponse.branches.data;
        updatedData = updatedData.map((item: { id: string; attributes: { location_of_point_of_sale: string, branch_name: string; }; }) => {
          return { value: Number(item.id), label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }
        });
        updatedData = sortArrayAlphabetically(updatedData, 'label');
        this.setState({
          branch_data: updatedData
        })

      } else {
        handleApiError(apiResponse.errors);

      }


    }
  }
  getDepartmentData = () => {
    const { formData } = this.state
    this.departmentDataCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: `${configJSON.deparmentDataApiEndPoint}/${formData.branch_id}`, token: true, });
  }
  handleResponseForDepartmentData = (from: string, message: Message) => {
    if (this.departmentDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false })
      const allDepartments = apiResponse.reduce((accum: string | string[], category: { departments: string; }) => {
        return accum.concat(category.departments);
      }, []);
      handleExpiredToken(apiResponse, this.props.navigation);
      const defaultValue = { value: "", label: configJSON.departmentSelect, isDisabled: true };
      if (allDepartments) {
        if (allDepartments.length > 0) {
          let finalValue = isBO() ? allDepartments.map((item: { id: string; name: string }) => {
            const { id, name } = item;
            return { label: name, value: Number(id) };
          }) : usersDepartments.map((item: { attributes: { id: number; name: string; }; }) => {
            const { id, name } = item.attributes;
            return { label: name, value: Number(id) };
          })
          finalValue = sortArrayAlphabetically(finalValue, 'label');
          finalValue.unshift(defaultValue);
          this.setState({ department_data: finalValue });
        } else {
          this.setState({ department_data: [defaultValue], formData: { ...this.state.formData, department_id: "", assignee_ids: [] } });
          toast.error(configJSON.departmentErrorMessage);
        }

      } else {
        this.setState({ department_data: [defaultValue], formData: { ...this.state.formData, department_id: "", assignee_ids: [] } });
        handleApiError(apiResponse.errors);
      }


    }
  }
  getEmployeeData = () => {
    const { formData: { department_id, branch_id } } = this.state
    this.employeeDataApiId = apiCall({ method: configJSON.validationApiMethodType, endPoint: `${configJSON.employeeListApiEndPoint}department_id=${department_id}&branch_id=${branch_id}`, token: true })
  }
  handleResponseForEmployeeListData = (from: string, message: Message) => {
    if (this.employeeDataApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false })
      handleExpiredToken(apiResponse, this.props.navigation);
      this.setState({ loading: false })
      if (apiResponse.data) {
        const { data } = apiResponse;
        if (data.length > 0) {
          const finalValue = data.map((item: { id: string; attributes: { email: string }; }) => {
            const { id, attributes } = item;
            return { label: attributes.email, value: Number(id) };
          })
          this.setState({ employees_Data: finalValue });
        } else {
          this.setState({ employees_Data: [], formData: { ...this.state.formData, assignee_ids: [] } });
          toast.error(configJSON.employeeErrorMessage);
        }

      } else {
        this.setState({ employees_Data: [] });
        handleApiError(apiResponse.errors);
      }

    }
  }
  handleSave = (values: FormValues) => {
    this.createTask(values)
  };
  createTask = (values: FormValues) => {
    this.setState({ loading: true });
    const attrs: FormValues = {
      title: values.title,
      description: values.description,
      branch_id: values.branch_id,
      department_id: values.department_id,
      assignee_ids: values.assignee_ids?.filter(item => item !== selectAllOption.value),
      due_date: values.due_date,
      ...(values.attach_image ? { attachment: values.attach_image } : {}),
      priority: values.priority,
    };
    this.createTaskCallId = apiCall({
      method: configJSON.exampleAPiMethod,
      body: convertFormData(attrs),
      endPoint: configJSON.createTaskApiEndPoint,
      token: true,
    });
  }
  handleResponseForCreateTask = (from: string, message: Message) => {
    if (this.createTaskCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      this.setState({ loading: false })
      if (apiResponse.message) {

        this.setState({ open: true })

      } else {
        handleApiError(apiResponse.errors);
      }
    }
  }
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForBranchData(from, message)
    this.handleResponseForDepartmentData(from, message)
    this.handleResponseForEmployeeListData(from, message)
    this.handleResponseForCreateTask(from, message)
    // Customizable Area End
  }
}
