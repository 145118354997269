Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.deleteAPiMethod = "DELETE";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.logoutText = "Logout";
exports.logoutApiEndpoint = "bx_block_logout/logout";
exports.invalidMessage = "Invalid Data!!";
exports.headerTitle = "Create";
exports.welcomeText = "Welcome to"

exports.customizeWlcomeText = "Welcome to Qwip"
exports.customizeDescriptionText = "Lets create you work space"


exports.getUserDataApiEndpoint = "account_block/accounts/get_business_owner";
// Customizable Area End