// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { checkToken, getNavigationMessage } from "../../../components/src/utils/commonFunctions";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
}

interface SS {
  id: any;
}

export default class HomePageController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    checkToken();
  }

  handleProductInfoNavigation = (path: string) => {
    if (path) {
      this.send(getNavigationMessage(path, this.props));
    }
  }


}
// Customizable Area End
