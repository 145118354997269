// Customizable Area Start
import { commonButton, commonInputField } from "../../../components/src/utils/commonInputStyle";
import { colors } from "../../../components/src/utils/commonDesign";

export const styles = {
  ...commonInputField,
  ...commonButton,
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  innerHeaderContainer : {
    marginBottom: "21px",
    "& .MuiFormHelperText-root": {
      marginBottom: "0px !important",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    flexWrap: "wrap" as const,
    gap: "30px",
  },
  innerBtnContainer: {
    display: "flex",
    flexWrap: "wrap" as const,
    justifyContent: "end",
    gap: "15px",
  },
  secondaryBtn2: {
    width: "125px",
    height: "35px",
    margin: "0",
    fontWeight: 600,
  },
  primaryBtn2: {
    width: "auto",
    minWidth: "125px",
    margin: "0px",
    height: "35px",
    fontWeight: 300,
    color: "#FFF"
  },
  btnGroup: {
    borderRadius: "10px",
    "& .inprogress": {
      background:"#DD7271"
    },
    "& .completed": {
      background:"#3E9D98"
    },
    "& .pending": {
      background:"#E59430"
    },
    "& .reopen": {
      background:"#F2A5C7"
    },
    "& .resolve": {
      background:"#8DB5E1"
    },
  },
  formContainer: {
    marginTop: "10px",
    "& .gridMargin":{
      margin:0
    }
  },
  editTicketBtnWrapper : {
    display: "flex",
    marginTop: "50px",
    paddingBottom: "20px",
    justifyContent: "center",
  },
  inputLabel2 : {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 700,
    color: colors.primaryColor2,
    wordBreak: "break-all" as const,
  },
  ticketDetailsText : {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 500,
    color: colors.text,
    "&.ticketDetailFont":{
      fontSize: "16px", 
      fontWeight: 600
    },
    "& .ticketOpacity":{
      opacity:"0.6",
      fontFamily: "Bitter-VariableFont", 
      fontWeight: 500
    }
  },
  commentsContainer : {
    marginTop: "40px",
    "& .comment-ul" : {
      margin: "0px",
      "& li::marker" : {
        color: colors.secondaryColor1,
        fontSize: "20px",
      }
    },
    "& .attachment":{
      color: "#4196F0",
      cursor:"pointer",
      textDecoration: "none",
      borderBottom: "1px solid #4196F0",
      fontSize:"14px !important"
    }
  },
  commentsDetails : {
    lineHeight: "21px",
    fontSize: "16px",
    fontWeight: 300,
    color: colors.text,
    "& .textOpacity":{
      opacity:"0.6"
    },
    "& .fontFamily":{
      fontFamily: "Bitter-VariableFont" 
    },
    "&.comment":{
      display:"flex"
    }
  },
  contentWithIcon :{
    display: "flex",
    alignItems: "center",
    gap: "20px",
    "& .textSize":{
      fontSize:18
    },
    "& img" : {
      height: "20px",
      width: "20px",
      cursor: "pointer",
    }
  },
  textFiedContainer : {
    display: "flex",
    gap: "21px",
    "& input":{
      display:"none"
    },
    alignItems: "center",
    "& img" : {
      cursor: "pointer",
    },
    "& .attachIcon" : {
      height: "24px",
      width: "25px",
    },
  }, 
  commentAndIcon : {
    display: "flex",
    gap: "30px",
    alignItems: "center",
    "& img:nth-child(2)" : {
      marginLeft: "10px",
    },
    "& img" : {
      cursor: "pointer",
    },
  },
  editField:{
    margin: 0, 
    marginBottom: "10px",
    "& .gridMargin":{
      margin:0
    },
    "& .textField":{
      marginTop:0
    }
  },
  imageMargin:{
    marginRight:10
  },
  fieldHeight:{
    height:80
  },
  boxAlign:{
    textAlign: "end" as const
  },
  imageCursor:{
    margin: "0 0 -7px 10px",
     cursor: "pointer" 
  },
  maxSizeTicket: {
    fontSize: 12,
    display: "block",
    color: "#020202",
    opacity: 0.6,
    position: "absolute" as const,
    right: 0
  },
  fileUploadInput:{
    "& .containerWidth":{
      maxWidth:"unset !important"
    }
  }
};
// Customizable Area End