// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { ExpandMoreRounded, DateRange } from '@material-ui/icons';
import { Grid, Tabs, Tab, Typography, Button, Box, Menu, MenuItem } from "@material-ui/core";
import UserLogsListingController, { Props, configJSON } from "./UserLogsListingController.web";
import { Wrapper } from "../../../components/src/Wrapper";
import { colors } from "../../../components/src/utils/commonDesign";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { check_List_Bold_Icon, form_Icon, imgFilter, imgGridList, imgListGrid, libraryIcon, userIcon } from "./assets";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { commonButton, libraryCommonCss } from "../../../components/src/utils/commonInputStyle";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import Pagination from "../../../components/src/commonComponents/Pagination.web";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import NoTickets from "../../../components/src/commonComponents/NoTickets.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import { isBO, isManager } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";

// Customizable Area End


// Customizable Area Start

export const styles = {
    ...commonButton,
    ...libraryCommonCss,
    mainContainerBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    rightContainerBox: {
        display: "flex",
        alignItems: "center",
        "& .flex-item": {
            marginRight: "1rem"
        },
        "& .branch": {
            marginRight: "1.5rem",
            marginTop: "1.5rem",
            width: 300
        },

    },
    tabHeading: {
        fontWeight: 700,
        fontSize: "18px",
        textTransform: "capitalize" as const,
        color: colors.text,
        "& .iconDisplay": {
            display: "none"
        }
    },
    tabBtn: {
        marginRight: "30px",
        minWidth: "auto",
        minHeight: "unset",
        "& .MuiTab-wrapper": {
            flexDirection: "row-reverse" as const
        },
        "& .expand": {
            marginBottom: "0px !important",
            marginLeft: 6
        }
    },
    arrowIcon: {
        paddingRight: "0px",
    },
    filterBtn: {
        background: "#FFF",
        borderRadius: "8px",
        width: 250,
        "&:hover": {
            background: "#FFF",
        }
    },
    filterBtnTxt: {
        textTransform: "capitalize" as const,
        fontWeight: 600,
        color: colors.text,
    },
    inputField: {
        margin: 0,
        "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.secondaryColor2,
            },
        },
        "& .MuiInputBase-root": {
            borderRadius: "8px",
            "& .MuiInputBase-input": {
                fontSize: "16px",
                lineHeight: "21px",
            },
        }
    },
    dateField: {
        "& .Mui-focused": {
            borderColor: colors.secondaryColor2,
        },
        "& .MuiInputBase-root": {
            padding: "0 10px",
            border: `1px solid #0000003b`,
        },
    },
    dateRangeTxt: {
        fontWeight: 500,
        fontSize: "14px",
    },
    filterDayItem: {
        width: "100%",
        borderRadius: "8px",
        textAlign: "center" as const,
        border: `1px solid #0000003b`,
        paddingBlock: "3px",
        "&:hover": {
            border: `1px solid ${colors.secondaryColor2}`,
        }
    },
    filterDayItemHighlight: {
        border: `1px solid ${colors.secondaryColor2}`,
    },
    filterTxtHighlight: {
        fontWeight: 600,
    },
    filterTxt: {
        fontSize: "14px",
    },
    menuItems: {
        "& .MuiMenu-paper": {
            marginTop: 7,
            marginLeft: 37,
            borderRadius: "8px"
        }
    },
    dateBox: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    gridBox: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
        cursor: "pointer"
    },
    recentCreated: {
        marginTop: "1.5rem",
        "& h4": {
            fontSize: "1.1rem",
            color: "#020202;",
            marginBottom: "0.7rem",
            fontWeight: 500,

        },
    },
    tableContainer: {
        gridTemplateColumns: "repeat(7,1fr)",
    },
    userLogView:{
        gridTemplateColumns: "repeat(5,1fr)",
    },
    userTtitle: {
        color: "#4196F0 !important",
        borderBottom: "1px solid #4196F0",
        maxWidth: "90%",
        textAlign:"left" as const,
        cursor:"pointer",
        width: "fit-content"
    },
    noText: {
        display: "flex",
        justifyContent: "center"
    },
    flexBox:{
        display: "flex",
        justifyContent: "flex-end",
        "&.flexCenter":{
            justifyContent: "center",
            alignItems:"center",
            padding:16
        },
        "& .boxWidth":{
            width:"90%"
        }
    },
    feedbackBtn: {
        alignItems: "flex-end",
        justifyContent: "flex-end",
        display: "flex",
        width:"24%",
        "& p": {
            textTransform: "capitalize" as const,
            marginLeft: 6
        },
        "&.clearAll":{
            marginTop:"16px",
            width:"99%"
        },
        "& button": {
            textTransform: "unset" as const,
            color: "#4196F0",
            borderRadius: "unset",
            padding: 0,
            fontSize: "1rem",
            borderBottom: "1px solid #4196F0",
            background:"transparent",
            "&:disabled": {
                color: "#4196F080",
                borderBottom: "1px solid #4196F080",
            },
            "&:hover": {
                background:"transparent",
            },
        },
        
    },
    clearSection:{
        display:"flex",
        justifyContent:"space-between" 
    }
}
// Customizable Area End

export class UserLogsListing extends UserLogsListingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderMenuItems = () => {
        const { value } = this.state;
        let menuItems = [configJSON.commonMenuItems, configJSON.commonMenuItems];
        return menuItems[value]?.map((menuItem: { label: string, action: string }, index: number) => (
            <MenuItem key={index} data-test-id="action_button" onClick={() => this.handleAction(value, menuItem.action, menuItem.label)}>
                {menuItem.label}
            </MenuItem>
        ));
    };
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { value, openMenu, gridView, date, menuAnchorEl, tabMenuOpen,
            menuLabel, branch_list, formData, department_list, logs_list,
            searchQuery, meta, openDeleteModal, loading,
        } = this.state
        const LogGridView = () => (
            <Grid container spacing={2}>
                {(Boolean(logs_list.length)) ?
                    logs_list.map((logData) => (
                        <Grid key={logData.attributes.id} item lg={3} md={4} sm={6} xs={12}>
                            <Box className={classes.cardWrapper}>
                                <Box className={classes.flexBox}>
                                    <ActionButton
                                        deleteVisible = {this.allowEditDeleteView()}
                                        handleDelete={() => this.handleOpenDeleteModal(logData)}
                                        handleView={() => this.handleViewEditClick(logData, configJSON.view)}
                                        handleEdit={() => this.handleViewEditClick(logData, configJSON.editResponse)}
                                        isOpen={this.state.isOpen[logData.attributes.id]}
                                        toggleDropdown={() => this.toggleDropdown(logData.attributes.id)}
                                        view
                                        edit = {this.allowEditDeleteView()}
                                    />
                                </Box>
                                <Box onClick={() => this.handleViewEditClick(logData, configJSON.view)}>
                                    <Box className={classes.noText}>
                                        <img src={logData.attributes.type === "Form" ? form_Icon : check_List_Bold_Icon} className={classes.templateImg} alt={logData.attributes.title} />
                                    </Box>
                                    <Box className={`${classes.flexBox} flexCenter`}>
                                        <Box className="boxWidth">
                                            <Typography data-test-id="log_title" noWrap className={classes.procedureTitleTxt}>{logData.attributes.title}</Typography>
                                            {this.state.user_logs === "user_logs" && 
                                              <Typography data-test-id="log_name" noWrap className={classes.procedureNameTxt}>
                                                <img src={userIcon} alt={"User"} /> {logData.attributes.name} - {logData.attributes.role_name}
                                              </Typography>
                                            }
                                            <Typography className={classes.dateTxt}>{configJSON.date}{this.getFormattedDate(logData.attributes.created_at)}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    )) :
                    <Box display={"flex"} justifyContent={"center"} width={"100%"} mt={"100px"} >
                        <NoTickets
                            title={configJSON.title}
                            imagePath={libraryIcon}
                        />
                    </Box>
                }
            </Grid>
        )

        const LogListView = () => (
            <Box id="pdf-content" className={classes.listItem}>
                <Box className={`${classes.tableHeader} ${classes.tableContainer} ${this.state.user_logs === "my_logs" && classes.userLogView}`}>
                    {(this.state.user_logs !== "my_logs" ? configJSON.headerList : configJSON.headerListMyLogs).map((item: string, index: number) => (
                        <Typography component="p" key={index}>
                            {item}
                        </Typography>
                    ))}
                </Box>
                <Box className={logs_list.length === 0 ? classes.listMain : ""}>
                    {logs_list.length > 0 ? (
                        logs_list.map((item) => {
                            return (
                                <Box key={item.attributes.id} className={`${classes.tableItem} ${classes.tableContainer} ${this.state.user_logs === "my_logs" && classes.userLogView}`}>
                                    {this.state.user_logs === "user_logs" ? <>
                                        <Typography component="p">{item.attributes.name}</Typography>
                                        <Typography component="p">{item.attributes.department_name}</Typography>
                                        <Typography component="p">{item.attributes.role_name}</Typography>
                                        <Typography onClick={() => this.handleViewEditClick(item, configJSON.view)} className={`${classes.procedureTitleTxt} ${classes.userTtitle}`} noWrap data-test-id="title">{item.attributes.title}</Typography>
                                        <Typography component="p">{item.attributes.type}</Typography>
                                        <Typography component="p">{this.getFormattedDate(item.attributes.created_at)}</Typography>
                                    </> : <>
                                        <Typography onClick={() => this.handleViewEditClick(item, configJSON.view)} className={`${classes.procedureTitleTxt} ${classes.userTtitle}`} noWrap data-test-id="title">{item.attributes.title}</Typography>
                                        <Typography component="p">{item.attributes.department_name}</Typography>
                                        <Typography component="p">{item.attributes.type}</Typography>
                                        <Typography component="p">{this.getFormattedDate(item.attributes.created_at)}</Typography>
                                    </>}
                                    <Box display={"flex"} >
                                        <ActionButton
                                            deleteVisible = {this.allowEditDeleteView()}
                                            handleDelete={() => this.handleOpenDeleteModal(item)}
                                            handleView={() => this.handleViewEditClick(item, configJSON.view)}
                                            handleEdit={() => this.handleViewEditClick(item, configJSON.editResponse)}
                                            moreVert
                                            isOpen={this.state.isOpen[item.attributes.id]}
                                            toggleDropdown={() => this.toggleDropdown(item.attributes.id)}
                                            view
                                            edit = {this.allowEditDeleteView()}
                                        />

                                    </Box>

                                </Box>
                            );
                        })
                    ) : (
                        <NoTickets
                            title={configJSON.title}
                            imagePath={libraryIcon}
                        />
                    )}
                </Box>
            </Box>
        )

        const LogViewer = () => {
            if (logs_list.length === 0 && Boolean(searchQuery)) {
                return (
                    <Box className={classes.noTicketContainer}>
                        <Typography variant="h5">{configJSON.searchResult} <strong>{searchQuery}</strong>.</Typography>
                    </Box>
                )
            } else if (logs_list.length === 0) {
                return (
                    <Box className={classes.noTicketContainer} >
                        <NoTickets
                            title={configJSON.title}
                            imagePath={libraryIcon}
                        />
                    </Box>
                )
            } else {
                return (
                    <>
                        {gridView ? LogGridView() : LogListView()}
                        <Box className={classes.paginationBox}>
                            <Pagination meta={meta} onPageChange={this.handlePageChange} />
                        </Box>
                    </>
                )
            }
        }
        return (
            <React.Fragment>
                <Wrapper
                    navigation={this.props.navigation}
                    data-test-id="wrapper-comp"
                    divider
                    bellIcn={true}
                    backbtn={true}
                    title={configJSON.logTitle}
                    searchHandleClick={this.handleSearch}
                    homeIcn={true}
                    querySearch={this.state.searchQuery}
                    searchData={this.state.logs_list}
                    search
                    child={
                        <>
                            <Loader loading={loading} />
                            <Box className={classes.mainContainerBox}>
                                <Box >
                                    <Tabs value={value} data-test-id="tabs">
                                        {((isBO() || isManager()) ? configJSON.tablist : [configJSON.tablist[0]]).map((tabData : {label: string, value: string}, index: number) => (
                                            <Tab className={classes.tabBtn}
                                                data-test-id="user_tab"
                                                onClick={(event) => {
                                                  const isArrowIconClicked = event.target.closest("svg") !== null;
                                                    if (isArrowIconClicked) {
                                                        this.handleTabMenuOpen(event, tabData.value, index);
                                                    } else {
                                                      this.handleTabChange(tabData.value)
                                                    }
                                                }}
                                                icon={tabMenuOpen[index] ? <ExpandLessIcon className="expand" /> : <ExpandMoreIcon className="expand" />} id={tabData.label} label={<Typography className={classes.tabHeading}>
                                                    {tabData.label}
                                                </Typography>} />
                                        ))}
                                    </Tabs>
                                    <Menu
                                        anchorEl={menuAnchorEl}
                                        open={Boolean(menuAnchorEl)}
                                        className={classes.menuItems}
                                        onClose={this.handleTabMenuClose}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        getContentAnchorEl={null}
                                        transformOrigin={{ vertical: "top", horizontal: "right" }}>
                                        {this.renderMenuItems()}
                                    </Menu>
                                </Box>
                                <Box className={classes.rightContainerBox}>
                                    <Box className="flex-item" >
                                        <Button data-test-id="menu-filter" fullWidth variant="outlined" size="large" className={classes.filterBtn} onClick={this.handleMenuOpen}>
                                            <Box className={classes.dateBox}>
                                                <img alt="filter-icon" src={imgFilter} />
                                                <Typography className={classes.filterBtnTxt} noWrap>{configJSON.filterByData}</Typography>
                                                <ExpandMoreRounded fontSize="small" />
                                            </Box>
                                        </Button>
                                        <Menu
                                            open={Boolean(openMenu)}
                                            onClose={this.handleMenuClose}
                                            anchorEl={openMenu}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <MenuItem>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        variant="inline"
                                                        margin="dense"
                                                        data-test-id="from-date"
                                                        className={`${classes.inputField} ${classes.dateField}`}
                                                        InputProps={{
                                                            endAdornment: <DateRange />,
                                                            disableUnderline: true,
                                                        }}
                                                        autoOk
                                                        value={date.from_date}
                                                        format="MM/dd/yyyy"
                                                        disableFuture={true}
                                                        name="creation_date"
                                                        onChange={(eventData) => this.handleDateChange(eventData, "from_date")}
                                                        allowKeyboardControl={false}
                                                        placeholder={"From"}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </MenuItem>
                                            <MenuItem>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        variant="inline"
                                                        data-test-id="to-date"
                                                        margin="dense"
                                                        className={`${classes.inputField} ${classes.dateField}`}
                                                        InputProps={{
                                                            endAdornment: <DateRange />,
                                                            disableUnderline: true,
                                                        }}
                                                        name="creation_date"
                                                        value={date.to_date}
                                                        format="MM/dd/yyyy"
                                                        autoOk
                                                        allowKeyboardControl={false}
                                                        minDate={date.from_date}
                                                        placeholder={"To"}
                                                        onChange={(eventData) => this.handleDateChange(eventData, "to_date")}
                                                        disableFuture={true}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </MenuItem>
                                            {configJSON.filterMenuList.map((item: string) => (
                                                <MenuItem>
                                                    <Box className={`${classes.filterDayItem} ${item === date.time_range && classes.filterDayItemHighlight}`}
                                                        onClick={() => this.handleDayChange(item)}>
                                                        <Typography data-test-id="date_range" className={`${classes.filterTxt} ${item === date.time_range && classes.filterTxtHighlight}`}>{item}</Typography></Box>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Box>
                                    <Box >
                                        <Box data-test-id="toggle-view" className={classes.gridBox} onClick={this.toggleView}>
                                            {gridView ? <img src={imgGridList} alt="grid-icon" /> : <img src={imgListGrid} alt="list-icon" />}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={classes.clearSection}>
                            {(isBO() || isManager()) && <Box className={classes.rightContainerBox}>
                                <Box className="branch">
                                    <DropdownSearch
                                        options={branch_list}
                                        value={formData.branch_id}
                                        onChangeValue={(event: OptionType) => this.handleSelectChange(event, "branch_id", { department_id: "" })}
                                        id="branch_id"
                                        isSearchable={true}
                                        placeholder={configJSON.branchPlaceholder}
                                        noOptionsMessage={configJSON.noBranchMessage}
                                    />
                                </Box>
                                <Box className="branch">
                                    <DropdownSearch
                                        options={department_list}
                                        value={formData.department_id}
                                        onChangeValue={(event: OptionType) => this.handleSelectChange(event, "department_id")}
                                        id="department_id"
                                        isSearchable={true}
                                        placeholder={configJSON.departSelect}
                                        noOptionsMessage={configJSON.noDepartmentMessage}
                                    />
                                </Box>
                            </Box>}
                            <Box onClick={this.handleClearAllFilter} data-test-id="clearall_button" className={`${classes.feedbackBtn} ${(isBO() || isManager()) ? "" : "clearAll"}`}>
                                    <Button disabled={this.handleDisable()}>{configJSON.clearAll}</Button>
                                </Box>
                            </Box>
                            <Box className={classes.recentCreated}>
                                {logs_list.length !== 0 && <Typography component="h4">{menuLabel}</Typography>}
                            </Box>
                            <Box className={logs_list.length === 0 ? classes.noText : ""} pt={2}>
                                {LogViewer()}
                            </Box>
                            {openDeleteModal &&
                                <ConfirmationModal
                                    onClose={this.handleCloseDeleteModal}
                                    confirmDelete={this.handleDeleteClick}
                                    from={configJSON.logFrom}
                                    open={openDeleteModal}
                                    title={configJSON.logDeleteTitle}
                                />}
                        </>
                    }
                />
            </React.Fragment>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(UserLogsListing);
// Customizable Area End
