Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";

exports.btnExampleTitle = "CLICK ME";

exports.headerViewTitle= "My Profile";
exports.headerEditTitle= "Edit Profile";
exports.userDetails= "User Details";
exports.position = "Position - ";

exports.cancel= "Cancel";
exports.save= "Save";
exports.edit= "Edit";
exports.uploadPic= "Upload Picture";
exports.delete= "Delete";

exports.personalInfo= "Personal Info";
exports.personalInfoDesc= "Update your personal details here";

exports.getMyProfileMethod= "GET";
exports.getMyProfileEndpoint= "bx_block_profile/get_user_profile";

exports.getCountryListMethod= "GET";
exports.getCountryListEndpoint= "account_block/country_code_and_flags/countries_with_phone_formats";

exports.editProfileMethod= "PUT";
exports.editProfileEndpoint= "bx_block_profile/profiles";

exports.ownerNameLabel= "Name";
exports.ownerNameName= "owner_name";
exports.ownerNamePlaceHolder= "Add your name";

exports.ownerEmailLabel= "Email";
exports.ownerEmailName= "owner_email";
exports.ownerEmailPlaceHolder= "Add your email";

exports.passwordLabel= "Current Password";
exports.passwordName= "current_password";
exports.passwordPlaceHolder= "Add your current password";

exports.newPasswordLabel= "New Password";
exports.newPasswordName= "new_password";
exports.newPasswordPlaceHolder= "Add your new password";

exports.confirmPasswordLabel= "Confirm New Password";
exports.confirmPasswordName= "confirm_new_password";
exports.confirmPasswordPlaceHolder= "Add your confirm password";

exports.phoneNumberLabel= "Phone Number";
exports.phoneNumberName= "phone_number";
exports.phoneNumberPlaceHolder= "Add your phone number";

exports.countryLabel= "Country";
exports.countryName= "country_id";
exports.countryPlaceHolder= "Select Country";
exports.noCountryMessage= "No country Found";

exports.countryCodePlaceholder = "Code";
exports.noCountryCodeMessage = "No data"

// Customizable Area End