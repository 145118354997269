import React from "react";
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import { ClassNameMap } from "@material-ui/styles";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled ,Box} from "@material-ui/core";
import { colors } from "../utils/commonDesign";
export const configJSON = require("./config");
import { LeftArrowIcon, RightArrowIcon } from "../commonComponents/assets";
export const styles: Styles<Theme, {}, string> = () => ({
    mainBox:{
        height:"430px"
    },
    carouselContainer: {
        position: "relative",
        paddingBottom:"60px",
        "& img": {
            width: '460px',
            height: '400px',
            color: 'black',
            transform: 'scale(0.9)',
            transition: 'transform 0.5s',
             boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            filter: 'brightness(0.8)',
            cursor: 'pointer',
        },
       
    },
    activeSlide: {
        "& img": {
          transform: "scale(1)",
          transition: "transform 0.5s",
          opacity: 1,
          filter: "none",
          marginTop : "2px",
        },
      },

});

interface CarouselState {
    currentSlide: number;
}

// Define a type for the props
interface CarouselProps {
    data: {
        id: number;
        text: string;
        name: string;
        url: string;
    }[];
    handleNavigate:any;
    classes: ClassNameMap<"carouselContainer" |  "activeSlide" | "mainBox">;
}

const StyledArrow = styled("div")({
    display: "flex",
    width: "32px",
    height: "32px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 10,
    backgroundColor: colors.secondaryColor2,
    "&:hover": {
        backgroundColor: colors.secondaryColor2,
    },
});

const StyledLeftArrow = styled(StyledArrow)({
    left: "26px",
});

const StyledRightArrow = styled(StyledArrow)({
    right: "22px", 
});

const SampleNextArrow: React.FC<{ style?: React.CSSProperties; onClick?: () => void }> = (props) => {
    const { style, onClick } = props;
    return (
        <Box style={{ ...style, display: "block" }} onClick={onClick}>
            <StyledRightArrow><img src={RightArrowIcon} alt="Next Arrow" /></StyledRightArrow>
        </Box>
    );
};

const SamplePrevArrow: React.FC<{ style?: React.CSSProperties; onClick?: () => void }> = (props) => {
    const { style, onClick } = props;
    return (
        <Box style={{ ...style, display: "block" }} onClick={onClick}>
            <StyledLeftArrow> <img src={LeftArrowIcon} alt="Previous Arrow" /></StyledLeftArrow>
        </Box>
    );
};

export class CommonCarousel extends React.Component<CarouselProps, CarouselState> {
    slider: Slider | null = null;

    constructor(props: CarouselProps) {
        super(props);
        this.state = {
            currentSlide: 0,
        };
    }

    settings: Settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
        focusOnSelect: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        afterChange: (current) => this.setState({ currentSlide: current }),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    nextSlide = () => {
        if (this.slider) {
            this.slider.slickNext();
        }
    };

    prevSlide = () => {
        if (this.slider) {
            this.slider.slickPrev();
        }
    };

    render() {
        const { classes,data ,handleNavigate} = this.props
        return (
            <Box className={classes.mainBox}>
                <Slider ref={(c) => (this.slider = c)} {...this.settings}>
                    {data.map((item, index: number) => (
                        <Box onClick={(e) => {e.stopPropagation()}} key={index}  className={`${classes.carouselContainer} ${
                            index === this.state.currentSlide ? classes.activeSlide : ""
                          }`}>
                            <img
                                onClick={() => handleNavigate(item.id)}
                                src={item?.url}
                                alt={`Image ${index + 1}`}
                                style={{ width: '100%' }}
                            />
                        </Box>
                    ))}
                </Slider>
            </Box>
        );
    }
}

export default withStyles(styles)(CommonCarousel);
