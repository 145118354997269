// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { ClassNameMap } from "@material-ui/styles";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonMenuTemplateController from "./CommonMenuTemplateController.web";
import { handleExpiredToken, checkToken, getUserRoleID } from "../../../components/src/utils/commonFunctions";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"formContainer" |"dropdownWrapper" |  "inputField" | "dateField" | "reverseContainer"
    | "fieldWrapper" | "textAreaInputField" | "inputLabel" | "helperText" | "textAreaField" | "templateBtnWrapper"
    | "primaryBtn" | "fileInput" | "secondaryBtn" | "inputLabel2" | "approvalContainer" | "uploadImageContainer" | "titleText" | "gridItem" | "templateFooterContainer"
  >;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class MenuTemplate2Controller extends CommonMenuTemplateController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      ...this.initMenuState,
      ...this.state,
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidMount(): any {
    // Customizable Area Start
    checkToken();
    this.getCategoriesData();
    this.getBranchesData();

    let { state } = this.props.navigation.history.location;
    if (state) {
      this.setDefaultMenu(state)
    } else if(![2,3].includes(getUserRoleID())) {
      this.props.navigation.goBack();
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let menuResData2 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(menuResData2, this.props.navigation);
      this.setState({ loading: false });
      if (requestCallId && menuResData2) {
        this.setState({ loading: false });
        switch (requestCallId) {
          case this.createMenuTempApiId:
            this.addMenuTempResponse(menuResData2, configJSON.menuSuccessMessage);
            break;
          case this.duplicateMenuTempApiId:
            this.addMenuTempResponse(menuResData2, configJSON.menuDuplicateMessage);
            break;
          case this.deleteMenuTempApiId:
            this.deleteMenuTempResponse(menuResData2);
            break;
          case this.branchesDataApiId:
            this.getBranchesDataResponse(menuResData2);
            break;
          case this.categoriesDataApiId:
            this.getCategoriesDataResponse(menuResData2);
            break;
          case this.departmentsDataApiId:
            this.getDepartmentsDataResponse(menuResData2);
            break;
          case this.favouriteMenuTempApiId:
            const toastMessage = this.state.menuData.favorite ? configJSON.menuUnFavMessage : configJSON.menuFavMessage;
            this.addMenuTempResponse(menuResData2, toastMessage);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
