// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ClassNameMap } from "@material-ui/styles";
import { apiCall, filterNullObj, handleApiError, handleExpiredToken } from "../../../components/src/utils/commonFunctions";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import { FavouriteDateData } from "./FavouriteController.web";
import moment from "moment";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    classes: ClassNameMap<"listContainer" | "cardWrapper" | "noTicketOuterContainer" | "templateImg" 
    |"noTicketContainer" | "procedureTitleTxt" | "dateTxt" | "favouriteIcn" | "menuImg" | "menuTxt" 
    | "menuPopper" | "departSelect" | "listItem" | "tableHeader" | "listMain" | "tableItem" 
    | "imgContainer" | "svgIcon" | "deleteModalDesc" | "btn" | "btn1" | "actionButton" | "imgBoxContainer" | "textContainer">;
    date: FavouriteDateData;
    searchQuery: string;
    formData: FormValues;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    procedure_list: DataObj[];
    anchorEl: HTMLElement | null;
    procedure_id: string;
    header_list: string[];
    isOpen: {
        [dataKey: string]: boolean;
    };
    openDeleteModal: boolean;
    search_procedure_list: DataObj[] | null;
    meta: {
        total_pages: number;
        total_count: number;
        current_page: number;
        next_page: null | number;
        previous_page: null | number;
    },
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;

    // Customizable Area End
}
// Customizable Area Start


interface ResponseErrorData {
    errors: string[]
}

interface FormValues {
    branch_id: string;
    department_id: string;
}


interface ResponseProcedure {
    procedures: {
        data: DataObj[]
    };
    meta: MetaObj
}

export interface MetaObj {
    total_pages: number;
    total_count: number;
    current_page: number;
    next_page: number | null;
    previous_page: number | null;
}

export interface DataObj {
    attributes: {
        id: string;
        title: string;
        creator?: CreatorTypes;
        created_at: string;
        favorite: boolean;
        template_id: number;
        procedure_template: {
            data: {
                attributes: {
                    template_image: {
                        url: string;
                        name: string;
                    }
                }
            }
        }
    }
}
export interface CreatorTypes {
    id?: number | string;
    name: string;
    designation: string;
    email: string
  } 
  
// Customizable Area End

export default class ProcedureListingController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getProceduresApiId: string = ""
    deleteProcedureApiId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            loading: false,
            procedure_list: [],
            anchorEl: null,
            procedure_id: "",
            header_list: configJSON.headerList,
            isOpen: {},
            openDeleteModal: false,
            search_procedure_list: null,
            meta: {
                total_pages: 1,
                total_count: 1,
                current_page: 1,
                next_page: null,
                previous_page: null
            },
        }
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        const { date: { from_date, to_date, time_range }, searchQuery, formData } = this.props
        this.setState({ loading: true })
        this.getFavouriteProcedures(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
        // Customizable Area End
    }
    // Customizable Area Start
    componentDidUpdate(prevProps: Props, previousState: S) {
        if ((prevProps.date.time_range !== this.props.date.time_range) || (prevProps.date.from_date !== this.props.date.from_date) || (prevProps.date.to_date !== this.props.date.to_date) || this.props.formData.department_id !== prevProps.formData.department_id || this.props.formData.branch_id !== prevProps.formData.branch_id) {
            const { date: { from_date, to_date, time_range }, searchQuery, formData } = this.props
            if (from_date && to_date) {
                this.setState({ loading: true })

                this.getFavouriteProcedures(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
            }
            if (time_range !== "custom") {
                this.setState({ loading: true })
                this.getFilteredFavouriteProcedures(from_date, to_date, time_range, searchQuery)
            }
        }
        if (prevProps.searchQuery !== this.props.searchQuery) {
            const { date: { from_date, to_date, time_range }, searchQuery, formData } = this.props
            this.setState({ loading: true })
            this.getFavouriteProcedures(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
        }
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let resJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            handleExpiredToken(resJson, this.props.navigation);
            this.setState({ loading: false });

            if (apiCallId && resJson) {
                if (apiCallId === this.getProceduresApiId) {
                    this.getProceduresResponse(resJson);
                } else if (apiCallId === this.deleteProcedureApiId) {
                    this.handleDeleteApiResponse(resJson);
                }
            }
        }
    }

    getFilteredFavouriteProcedures = (fav_from_date: string | null, fav_to_date: string | null, fav_time_range: string | null, searchQuery: string = '') => {
        const department_id = this.props.formData.department_id
        switch (fav_time_range) {
            case configJSON.today:
                this.getFavouriteProcedures(this.state.meta.current_page, fav_from_date, fav_to_date, "today", department_id, searchQuery)
                break
            case configJSON.yesterday:
                this.getFavouriteProcedures(this.state.meta.current_page, fav_from_date, fav_to_date, "yesterday", department_id, searchQuery)
                break
            case configJSON.lastDays:
                this.getFavouriteProcedures(this.state.meta.current_page, fav_from_date, fav_to_date, "15_days", department_id, searchQuery)
                break
            default:
                this.getFavouriteProcedures(this.state.meta.current_page, fav_from_date, fav_to_date, "", department_id, searchQuery)
                break
        }
    }

    getFavouriteProcedures = (page: number, from_date: string | null, to_date: string | null, time_range: string | null, department_id: string, query?: string) => {
        this.setState({loading:true})
        const {branch_id} = this.props.formData
        let allData = {page, custom_start_date: from_date ? moment(from_date).format("DD/MM/YYYY") : "", custom_end_date: to_date ? moment(to_date).format("DD/MM/YYYY") : "", time_range, branch_id, department_id, query};
        this.getProceduresApiId = apiCall({ method: configJSON.getMethod, endPoint: configJSON.getProcedureListApiEndPoint + filterNullObj(allData), token: true })
    }

    getProceduresResponse = (responseItem: ResponseProcedure & ResponseErrorData) => {
        this.closeDropDown()
        if (responseItem.errors) {
            handleApiError(responseItem.errors)
        } else {
            this.setState(previousState => ({ procedure_list: responseItem.procedures.data, meta: responseItem.meta ?? previousState.meta }))
        }
    }

    getFormattedDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString().split(",")[0]
    }

    handleDeleteClick = () => {
        this.deleteProcedureApiId = apiCall({ method: configJSON.deleteMethod, endPoint: `${configJSON.deleteProcedureApiEndPoint}${this.state.procedure_id}/remove_from_favorite`, token: true });
        this.handleCloseDeleteModal()
    }

    handleDeleteApiResponse = (responseItem: ResponseErrorData) => {
        const { date: { from_date, to_date, time_range }, formData } = this.props
        if (responseItem.errors) {
            handleApiError(responseItem.errors)
        } else {
            this.getFavouriteProcedures(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id)
            toast.success(configJSON.procedureDeleteMessage)
        }
    }

    toggleDropdown = (itemId: string) => {
        this.setState((prevState) => {
            const updatedIsOpen = { ...prevState.isOpen };

            for (const dataKey in updatedIsOpen) {
                updatedIsOpen[dataKey] = false;
            }

            updatedIsOpen[itemId] = !prevState.isOpen[itemId];

            return {
                isOpen: updatedIsOpen,
                procedure_id: itemId,
            };
        });
    };

    handleCloseDeleteModal = () => {
        this.setState({ openDeleteModal: false })
    }

    handleOpenDeleteModal = () => {
        this.setState({ openDeleteModal: true })
        this.closeDropDown()
    }

    handleView = (attr: { template_id: number }) => {
        const dataId = attr.template_id
        this.props.navigation.history.push(`${configJSON.procedureTemplateNavigation}${dataId}`, attr)
    }

    closeDropDown = () => {
        this.setState((prevState) => {
            const updatedIsOpen = { ...prevState.isOpen };

            for (const dataKey in updatedIsOpen) {
                updatedIsOpen[dataKey] = false;
            }

            return {
                isOpen: updatedIsOpen,
            };
        });
    }

    handlePageChange = (pageNumber: number) => {
        const { date: { from_date, to_date, time_range }, formData } = this.props
        this.setState({
            meta: {
                ...this.state.meta,
                current_page: pageNumber
            }
        });
        this.getFavouriteProcedures(pageNumber, from_date, to_date, time_range, formData.department_id)
    };


    // Customizable Area End


}
