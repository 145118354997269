// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { apiCall, sortArrayAlphabetically, convertAllFormData, getLastDigitsFromString, handleUndefinedData, handleApiError, getDiffs, trimStringValues, getUserRoleID, isBO, loggedUserId, userBranchId, usersDepartments, getUserBranchId, commonDownloadPdfFunc, handleValuesToDescArr } from "../../../components/src/utils/commonFunctions";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import React from "react";
import { CreateProductTemplateSchema, EditProductTemplateSchema } from "../../../components/src/utils/validationSchema";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  branchData: ListData[];
  categoryData: ListData[];
  departmentData: ListData[];
  isDownload:boolean
  openMenu: boolean;
  opeDeleteModal: boolean;
  loading: boolean;
  id: string | number | null;
  imageUrl: any;
  template_id: number;
  productData: any;
  defaultProductData: any;
  productTempAction: string;
  creator?: Creator;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
export interface ListData {
  id?: number,
  value: string;
  label: string;
}

export interface Document {
  id?: number,
  name: string;
  url: string;
  uploaded_date?: string
}

export interface Creator {
  id: number,
  name: string;
  designation: string;
  email: string
}

// Customizable Area End

export default class CommonProductTemplateController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createProductTempApiId: string = "";
  duplicateProductTempApiId: string = "";
  deleteProductTempApiId: string = "";
  branchesDataApiId: string = "";
  departmentsDataApiId: string = "";
  categoriesDataApiId: string = "";
  favouriteProductTempApiId: string = "";
  pdfRef:any = ""

  initialProductData = {
    id: null,
    name: "",
    description: "",
    faq: "",
    branch_id: "",
    department_id: "",
    product_info_type_id: "",
    image: "",
    usd: null,
    item_code: "",
    pair_withs_attributes: [],
    send_for_approval:false
  }

  initProductState = {
    openMenu: false,
    opeDeleteModal: false,
    loading: false,
    id: "",
    imageUrl: "",
    template_id: getLastDigitsFromString(window.location.pathname),
    productData: this.initialProductData,
    defaultProductData: this.initialProductData,
    productTempAction: configJSON.create,
  };

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.pdfRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      ...this.initProductState,
      branchData: [],
      categoryData: [],
      departmentData: [],
      isDownload:false
    }
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Props, previousState: S): any {
    const { productData } = this.state;
    const { branch_id } = productData;
    if (branch_id && (productData.branch_id !== previousState.productData.branch_id)) {
      this.setState({ loading: true });
      this.getDepartmentsData(branch_id);
    }
  }

  productTempValidationSchema = () => {
    const { productTempAction } = this.state;
    return productTempAction === configJSON.edit ? EditProductTemplateSchema : CreateProductTemplateSchema
  }

  setDefaultProduct(data: any, action?: string) {
    const { id, image, creator } = data
    this.setState({
      id,
      productData: data,
      defaultProductData: data,
      imageUrl: image.url ? image : "",
      productTempAction: action ?? configJSON.view,
      creator
    })
  }

  productTempInitialValues = () => {
    const { defaultProductData, productData, productTempAction } = this.state;
    const isCreateTemplate = productTempAction === configJSON.create;
    const data = {
      name : isCreateTemplate ? defaultProductData.name  : handleUndefinedData(productData?.name , ""),
      send_for_approval : isCreateTemplate ? defaultProductData.send_for_approval  : handleUndefinedData(productData?.send_for_approval , this.initialProductData.send_for_approval),
      branch_id: isCreateTemplate ? defaultProductData.branch_id : handleUndefinedData(productData?.branch_id, ""),
      department_id: isCreateTemplate ? defaultProductData.department_id : handleUndefinedData(productData?.department_id, ""),
      product_info_type_id: isCreateTemplate ? defaultProductData.product_info_type_id : handleUndefinedData(productData?.product_info_type_id, ""),
      description: isCreateTemplate ? defaultProductData.description : handleUndefinedData(productData?.description, ""),
      faq: isCreateTemplate ? defaultProductData.faq : handleUndefinedData(productData?.faq, ""),
      usd: isCreateTemplate ? defaultProductData.usd : handleUndefinedData(productData?.usd, 0),
      item_code: isCreateTemplate ? defaultProductData.item_code : handleUndefinedData(productData?.item_code, ""),
      image: null,
      pair_with1: isCreateTemplate ? "" : handleUndefinedData(productData?.pair_withs_attributes[0]?.pair_with, ""),
      pair_with2: isCreateTemplate ? "" : handleUndefinedData(productData?.pair_withs_attributes[1]?.pair_with, ""),
      pair_with3: isCreateTemplate ? "" : handleUndefinedData(productData?.pair_withs_attributes[2]?.pair_with, ""),
    }
    return data;
  }

  getBranchesData = () => { 
    this.branchesDataApiId = apiCall({ method: configJSON.getMethod, endPoint: configJSON.getBranchesUrl, token: true });
  }
  getCategoriesData = () => { 
    this.categoriesDataApiId = apiCall({ method: configJSON.getMethod, endPoint: configJSON.getCategoriesUrl, token: true });
  }
  getDepartmentsData = (branch_id: number) => { 
    this.departmentsDataApiId = apiCall({ method: configJSON.getMethod, endPoint: `${configJSON.deparmentDataApiEndPoint}${branch_id}`, token: true })
  }

  headerTitle = () => {
    const { productTempAction, template_id } = this.state;
    let start = '';

    if (productTempAction === configJSON.create) {
      start = configJSON.create;
    } else if (productTempAction === configJSON.view) {
      start = configJSON.view;
    } else if (productTempAction === configJSON.edit) {
      start = configJSON.edit;
    } else if (productTempAction === configJSON.duplicate) {
      start = configJSON.duplicate;
    }

    return `${start} ${configJSON.productHeaderTitle} ${template_id}`;
  };

  isViewOnly = () => {
    return this.state.productTempAction === configJSON.view || [4,5].includes(Number(getUserRoleID()))
  }

  toggleMenu = () => {
    this.setState({openMenu : !this.state.openMenu})
  }

  closeMenu = () => {
    this.setState({openMenu : false})
  }

  handleImageChange = (event: { target: { name: string; files: any[]; }; }, setFieldValue: any) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFileTypes = ["image/jpg", "image/jpeg", "image/png"];
      const fileAllow = allowedFileTypes.includes(file.type);
      if (fileAllow) {
        this.setState({
          imageUrl: { name: file.name, url: URL.createObjectURL(file) },
        });
        setFieldValue("image", file);
      } else {
        toast.error(configJSON.imagerrorMessage);
      }
    }
  };

  handleSelectChange = (values: { name: any; send_for_approval: any; branch_id: any; department_id: any; product_info_type_id: any; description: any; faq: any; usd: any; item_code: any; image: null; pair_with1: any; pair_with2: any; pair_with3: any; }, event: OptionType, setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, key: any, clearValue?: any) => {
    this.setState({openMenu:false})
    const { productData } = this.state
    const finalData = { ...productData, ...values, pair_withs_attributes: handleValuesToDescArr(values, productData.pair_withs_attributes, "pair_with"), [key]: event.value }
    if (clearValue && (productData[key] !== event.value)) {
      this.setState({ productData: { ...finalData, ...clearValue } });
      setFieldValue(key, event.value);
      for (let item of Object.keys(clearValue)) {
        setFieldValue(item, clearValue[item]);
      }
    } else {
      this.setState({ productData: finalData });
      setFieldValue(key, event.value);
    }
  };
  
  getBranchesDataResponse = (res: any) => {
    const { data } = res.branches;
    const defaultItem = { value: "", label: configJSON.templateBranchPlaceholder, isDisabled: true }
    if (data.length > 0) {
      let finalBranchesData = !isBO() ? data.filter((item: any)=> Number(item.id) === Number(userBranchId)) : data;
       finalBranchesData = finalBranchesData.map((item: any) => {
        const { id, attributes } = item;
        const { branch_name, location_of_point_of_sale } = attributes;
        return { label: `${branch_name} - ${location_of_point_of_sale}`, value: Number(id), data: attributes };
      })
      finalBranchesData = sortArrayAlphabetically(finalBranchesData, 'label');
      finalBranchesData.unshift(defaultItem);
      const branch_id = getUserBranchId() || this.state.productData.branch_id;
      const productData = {...this.state.productData, branch_id}
      const defaultProductData = {...this.state.defaultProductData, branch_id}
      this.setState({ branchData: finalBranchesData, productData, defaultProductData });
    } else {
      this.setState({ branchData: [] });
      toast.error("No branches found. Please add a branch first");
    }
  };

  getCategoriesDataResponse = (res: any) => {
    const { data } = res;
    if (data.length > 0) {
      let finalCategoriesData: any = [];
      data.map((item: any) => {
        const { attributes } = item;
        const { id, name } = attributes;
          return finalCategoriesData.push({ label: name, value: Number(id) });
      })
      finalCategoriesData = sortArrayAlphabetically(finalCategoriesData, 'label');
      this.setState({ categoryData: finalCategoriesData });
    } else {
      this.setState({ categoryData: [] });
      toast.error(configJSON.productTempNoCategoryMessage);
    }
  };

  getDepartmentsDataResponse = (resData: any) => {
    const defaultItem = { value: "", label: configJSON.templateDepartmentPlaceholder, isDisabled: true }
    if (resData.length > 0) {
      let finalDatas: any = [];
      isBO() ? resData.map((item: any) => {
        item.departments.map((dep: any) => {
          const { id, name } = dep;
          return finalDatas.push({ label: name, value: Number(id) });
        })
      }) : usersDepartments.map((dep: { attributes: { id: number; name: string; }; }) => {
        const { id, name } = dep.attributes;
        return finalDatas.push({ label: name, value: Number(id) });
      })
      finalDatas = sortArrayAlphabetically(finalDatas, 'label');
      finalDatas.unshift(defaultItem);
      this.setState({ departmentData: finalDatas });
    } else {
      this.setState({ departmentData: [defaultItem] });
      toast.error(configJSON.templateNoDepartmentMessage);
    }
  };

  handleActions = (name: string) => {
    this.closeMenu()
    this.setState({ productTempAction: name});
  }

  checkEmptyAttachment = () => {
    return this.state.imageUrl ? {} : { image: "" }
  }

  addEditProductTempApiCall = (payload: any, { resetForm }: { resetForm: () => void }) => {
    const { id, productData } = this.state;
    payload = {...payload, branch_id: productData.branch_id }
    if (Object.keys(payload).length) {
      this.setState({ loading: true });
      this.createProductTempApiId = apiCall({
        method: id ? configJSON.putMethod : configJSON.exampleAPiMethod,
        body: convertAllFormData(payload, "data[attributes]"),
        endPoint: `${configJSON.productTemplateUrl}/${id}`,
        token: true,
      });
    }
  }

  addEditTemplate = (values: any, { resetForm }: { resetForm: () => void }) => {
    const { productData, defaultProductData, template_id } = this.state;

    const { description, faq, usd, item_code,  image, product_info_type_id, department_id, name, branch_id, pair_withs_attributes } = defaultProductData;
    const {pair_with1, pair_with2, pair_with3 } = values;

    const { id } = productData;

    const createPairWithAttr = [
      {
        pair_with: pair_with1,
      }, {
        pair_with: pair_with2,
      },{
        pair_with: pair_with3,
      }
    ];

    const editPairWithAttr = () => {
      return [
        {
          pair_with: pair_with1,
          id: this.state.productData.pair_withs_attributes[0].id,
        }, {
          pair_with: pair_with2,
          id: this.state.productData.pair_withs_attributes[1].id,
        },
        {
          pair_with: pair_with3,
          id: this.state.productData.pair_withs_attributes[2].id,
        },
      ];

    }

    const orriginalData: any = {
      name,
      product_info_type_id,
      branch_id,
      department_id,
      description,
      faq,
      usd: +usd,
      item_code,
      pair_withs_attributes,
      ...(image ? { image } : {}),
    };

    const newData: any = {
      name: values.name,
      product_info_type_id: values.product_info_type_id,
      branch_id: values.branch_id,
      department_id: values.department_id,
      description: values.description,
      faq: values.faq,
      usd: +values.usd,
      item_code: values.item_code,
      ...(values.image ? { image: values.image } : this.checkEmptyAttachment()),
      ...(!id ? { template_id } : {}),
      pair_withs_attributes: id ? editPairWithAttr() : createPairWithAttr,
      send_for_approval: values?.send_for_approval
    };

    let templatePayload: any = getDiffs(orriginalData, newData);
    templatePayload = trimStringValues(templatePayload);
    this.addEditProductTempApiCall(templatePayload, { resetForm })
  }

	downloadProductTemp = () => {
		const ele = this.pdfRef.current;
    this.setState({isDownload:true,openMenu:false,loading:true})
    const handleLoading = () => {
      this.setState({loading:false,isDownload:false})
    }
    commonDownloadPdfFunc(ele,handleLoading,`Product Template ${this.state.template_id}`,`${this.state.defaultProductData.name}`)
      
	};

  navigateToListing = () => {
    this.props.navigation.history.push(`Library`, 1)
  }

  handleDelete = () => {
    this.toggleMenu();
    this.setState({ opeDeleteModal: true });
  };
  handleDeleteClose = () => {
    this.setState({ opeDeleteModal: false });
  };
  handleDeleteTemplate = () => {
    this.setState({ loading: true });
    this.deleteProductTempApiId = apiCall({ method: configJSON.deleteMethod, endPoint: `${configJSON.productTemplateUrl}/${this.state.id}`, token: true });
  }
  deleteProductTempResponse = (res: any) => {
    const { product_info } = res;
    if (product_info.length > 0) {
      toast.success(configJSON.productDeleteSuccessMessage);
      this.handleDeleteClose();
      this.navigateToListing()
    }
  };

  handleProductFavouriteClick = () => {
    this.toggleMenu();
    this.setState({ loading: true });
    const {  productData, id } = this.state;
    this.favouriteProductTempApiId = productData.favorite ?
    apiCall({
      method: configJSON.deleteMethod,
      endPoint: `${configJSON.productTemplateUrl}/${id}${configJSON.removeFavorite}`,
      token: true,
    })
    :
    apiCall({
      method: configJSON.putMethod,
      endPoint: `${configJSON.productTemplateUrl}/${id}${configJSON.addFavorite}`,
      token: true,
    });
}

  addProductTempResponse = (apiResponse: { data: { attributes: object; }; message: string[]; errors: string[]; }, message: string) => {
    if (apiResponse.data) {
      if (this.state.id) {
        const dataItem = apiResponse.data.attributes
        this.setProductStateData(dataItem);
        this.setDefaultProduct(dataItem)
      } else {
        this.setState({ ...this.initProductState });
        this.navigateToListing()
      }
      this.handleActions(configJSON.view)
      toast.success(message);
    } else if (apiResponse.message) {
      const {defaultProductData} = this.state
      const dataItem = {...defaultProductData, favorite: !defaultProductData.favorite};
      this.setProductStateData(dataItem);
      this.setDefaultProduct(dataItem)
      toast.success(message);
    } else {
      handleApiError(apiResponse.errors);
    }
  };

  handleDuplicateProductTemp = () => {
    this.setState({ loading: true });
    this.duplicateProductTempApiId = apiCall({ method: configJSON.exampleAPiMethod, endPoint: `${configJSON.productTemplateUrl}/${this.state.id}/duplicate`, token: true });
  }

  setProductStateData = (response: object) => {
    const { template_id } = this.state;
    this.props.navigation.history.push(`${configJSON.ProductTempNavigation}${template_id}`, response)
  }

  allowProductEdit = () => {
    return !!((this.state.creator?.id === loggedUserId) || isBO());
  }

  // Customizable Area End
  // Customizable Area End
}
