import { colors } from "../../../components/src/utils/commonDesign";

export const styles: any = {
  outerBox: {
    height: "100%",
    width: "100%",
  },
  innerBox: {
    padding: "0 !important",
    margin: 0,
    overflowY: "auto",
    height: "100%",
    "& .depContainer": {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      marginTop: "30px",
      "& .depName": {
        fontSize: "22px",
        lineHeight: "29px",
        fontWeight: 400,
        marginBottom: "15px",
        color: colors.primaryColor2,
      },
    },
    "& .title-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .righ-title-container": {
        display: "flex",
        alignItems: "center",
        margin: "5px",
        gap: "10px",
        "& .fieldWrapper": {
          height: "46px",
          marginTop : "-19px",
          width: "200px",
          "& .inputField": {
            margin: 0,
            "& .Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.secondaryColor1,
              },
            },
          },
          "& .MuiInputBase-root": {
            height: "42px",
            borderRadius: "10px",
            backgroundColor: "#FFFFFF",
            "& .MuiInputBase-input": {
              lineHeight: "21px",
              fontSize: "16px",
              padding: "10.5px 14px",
              "& .Mui-disabled": {
                color: colors.primaryColor2,
                backgroundColor: "#F5F5F5",
                borderRadius: "10px",
              },
            },
          },
          "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.secondaryColor1,
            },
          },

        },
        "& .btn2": {
          height: "42px !important",
          margin: "0 !important"
        },
      },
      "& .title1": {
        color: colors.text,
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "32px",
      },
    },
    "& .btnWrapper1": {
      display: "flex",
      justifyContent: "center",
      marginTop: "65px",
    },
    "& .btnWrapper_1": {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      bottom: "20px",
      width: "93%"
    },
  },
  btn: {
    margin: "0 16px",
    boxShadow: "none",
    fontWeight: 700,
    textTransform: "capitalize",
    height: "56px",
    width: "300px",
    borderRadius: "10px",
    fontSize: "16px",
    lineHeight: "21px",
    color: colors.primaryColor2,
  },
  primaryBtn: {
    backgroundColor: colors.secondaryColor2,
    "&:hover": {
      backgroundColor: colors.secondaryColor2,
    }
  },
  secondaryBtn: {
    border: `1px solid ${colors.primaryColor2}`,
    width: "auto !important",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: colors.primaryColor1,
    }
  },
  helperText: {
    color: colors.errorText,
    margin: "-22px 0 22px 30px",
  },
  boxCard: {
    height: 170,
    width: 170,
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "16px",
    cursor: "pointer",
    border: `1px solid ${colors.secondaryColor2} !important`,
  },
  innerBoxCard: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    "& .imgContainer img": {
      height: "auto",
      width: "auto",
      maxWidth: "130px",
      maxHeight: "90px",
      objectFit: "contain",
    },
    "& .depName": {
      color: colors.text,
      fontSize: "16px",
      fontWeight: "600 !important",
      lineHeight: "19px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      margin: "0 !important",
      textAlign: "center",
    }
  },
  employeeBtn: {
    minHeight: "50px",
    textTransform: "capitalize",
    borderRadius: "10px"
  },
  departViewer:{
    width:"95%", 
    position:"absolute", 
    top:"50%",
    textAlign:"center"
  }
};
