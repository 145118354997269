// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { apiCall, sortArrayAlphabetically, convertAllFormData, getLastDigitsFromString, handleUndefinedData, handleApiError, getDiffs, trimStringValues, userBranchId, getUserBranchId, isBO, loggedUserId, usersDepartments, commonDownloadPdfFunc, handleValuesToDescArr } from "../../../components/src/utils/commonFunctions";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import React from "react";
import { CreateMenuTemplateSchema, EditMenuTemplateSchema } from "../../../components/src/utils/validationSchema";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  branchData: ListData[];
  categoryData: ListData[];
  departmentData: ListData[];
  isDownload: boolean
  openMenu: boolean;
  opeDeleteModal: boolean;
  loading: boolean;
  id: string | number | null;
  imageUrl: any;
  template_id: number;
  menuData: any;
  defaultMenuData: any;
  menuTempAction: string;
  creator?: Creator;
  // Customizable Area End
}

export interface Creator {
  id: number,
  name: string;
  designation: string;
  email: string
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
export interface ListData {
  id?: number,
  value: string;
  label: string;
}

export interface Document {
  id?: number,
  name: string;
  url: string;
  uploaded_date?: string
}

// Customizable Area End

export default class CommonMenuTemplateController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createMenuTempApiId: string = "";
  duplicateMenuTempApiId: string = "";
  deleteMenuTempApiId: string = "";
  branchesDataApiId: string = "";
  departmentsDataApiId: string = "";
  categoriesDataApiId: string = "";
  favouriteMenuTempApiId: string = "";
  pdfRef: any = ""

  initialMenuData = {
    id: null,
    name: "",
    department_id: "",
    branch_id: "",
    menu_type_id: "",
    veg: false,
    gf: false,
    k: false,
    image: "",
    description: "",
    faq: "",
    usd: null,
    recipe_code: "",
    pair_withs_attributes: [],
    addons_attributes: [],
    send_for_approval: false,
  }

  initMenuState = {
    openMenu: false,
    opeDeleteModal: false,
    loading: false,
    id: "",
    imageUrl: "",
    template_id: getLastDigitsFromString(window.location.pathname),
    menuData: this.initialMenuData,
    defaultMenuData: this.initialMenuData,
    menuTempAction: configJSON.create,
  };

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.pdfRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      ...this.initMenuState,
      branchData: [],
      categoryData: [],
      departmentData: [],
      isDownload: false
    }
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Props, previousState: S): any {
    const { menuData } = this.state;
    const { branch_id } = menuData;
    if (branch_id && (menuData.branch_id !== previousState.menuData.branch_id)) {
      this.setState({ loading: true });
      this.getDepartmentsData(branch_id);
    }
  }

  menuTempValidationSchema = () => {
    const { menuTempAction } = this.state;
    return menuTempAction === configJSON.edit ? EditMenuTemplateSchema : CreateMenuTemplateSchema
  }

  setDefaultMenu(data: any, action?: string) {
    const { id, creator, image } = data
    this.setState({
      id,
      creator,
      menuData: data,
      defaultMenuData: data,
      imageUrl: image.url ? image : "",
      menuTempAction: action ?? configJSON.view
    })
  }

  menuTempInitialValues = () => {
    const { defaultMenuData, menuData, menuTempAction } = this.state;
    const isCreateTemplate = menuTempAction === configJSON.create;
    const data = {
      name: isCreateTemplate ? defaultMenuData.name : handleUndefinedData(menuData?.name, ""),
      department_id: isCreateTemplate ? defaultMenuData.department_id : handleUndefinedData(menuData?.department_id, ""),
      branch_id: isCreateTemplate ? defaultMenuData.branch_id : handleUndefinedData(menuData?.branch_id, ""),
      menu_type_id: isCreateTemplate ? defaultMenuData.menu_type_id : handleUndefinedData(menuData?.menu_type_id, ""),
      veg: isCreateTemplate ? defaultMenuData.veg : handleUndefinedData(menuData?.veg, ""),
      gf: isCreateTemplate ? defaultMenuData.gf : handleUndefinedData(menuData?.gf, ""),
      k: isCreateTemplate ? defaultMenuData.k : handleUndefinedData(menuData?.k, ""),
      description: isCreateTemplate ? defaultMenuData.description : handleUndefinedData(menuData?.description, ""),
      faq: isCreateTemplate ? defaultMenuData.faq : handleUndefinedData(menuData?.faq, ""),
      usd: isCreateTemplate ? defaultMenuData.usd : handleUndefinedData(menuData?.usd, 0),
      recipe_code: isCreateTemplate ? defaultMenuData.recipe_code : handleUndefinedData(menuData?.recipe_code, ""),
      image: null,
      pair_with1: isCreateTemplate ? "" : handleUndefinedData(menuData?.pair_withs_attributes[0]?.pair_with, ""),
      pair_with2: isCreateTemplate ? "" : handleUndefinedData(menuData?.pair_withs_attributes[1]?.pair_with, ""),
      pair_with3: isCreateTemplate ? "" : handleUndefinedData(menuData?.pair_withs_attributes[2]?.pair_with, ""),
      add_on1: isCreateTemplate ? "" : handleUndefinedData(menuData?.addons_attributes[0]?.add_on, ""),
      add_on2: isCreateTemplate ? "" : handleUndefinedData(menuData?.addons_attributes[1]?.add_on, ""),
      add_on3: isCreateTemplate ? "" : handleUndefinedData(menuData?.addons_attributes[2]?.add_on, ""),
      send_for_approval: isCreateTemplate ? defaultMenuData?.send_for_approval : handleUndefinedData(menuData?.send_for_approval, ""),
    }
    return data;
  }

  getBranchesData = () => {
    this.branchesDataApiId = apiCall({ method: configJSON.getMethod, endPoint: configJSON.getBranchesUrl, token: true });
  }
  getCategoriesData = () => {
    this.categoriesDataApiId = apiCall({ method: configJSON.getMethod, endPoint: configJSON.getMenuCategoriesUrl, token: true });
  }
  getDepartmentsData = (branch_id: number) => {
    this.departmentsDataApiId = apiCall({ method: configJSON.getMethod, endPoint: `${configJSON.deparmentDataApiEndPoint}${branch_id}`, token: true })
  }

  headerTitle = () => {
    const { menuTempAction, template_id } = this.state;
    let start = '';

    if (menuTempAction === configJSON.create) {
      start = configJSON.create;
    } else if (menuTempAction === configJSON.view) {
      start = configJSON.view;
    } else if (menuTempAction === configJSON.edit) {
      start = configJSON.edit;
    } else if (menuTempAction === configJSON.duplicate) {
      start = configJSON.duplicate;
    }

    return `${start} ${configJSON.menuHeaderTitle} ${template_id}`;
  };

  isViewOnly = () => {
    return this.state.menuTempAction === configJSON.view
  }

  closeMenu = () => {
    this.setState({ openMenu: false })
  }

  toggleMenu = () => {
    this.setState({ openMenu: !this.state.openMenu })
  }

  handleImageChange = (event: { target: { name: string; files: any[]; }; }, setFieldValue: any) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      const allowedFileTypes = ["image/jpg", "image/jpeg", "image/png"];
      const fileAllow = allowedFileTypes.includes(imageFile.type);
      if (fileAllow) {
        this.setState({
          imageUrl: { name: imageFile.name, url: URL.createObjectURL(imageFile) },
        });
        setFieldValue("image", imageFile);
      } else {
        toast.error(configJSON.imagerrorMessage);
      }
    }
  };

  handleSelectChange = (values: { name: any; department_id: any; branch_id: any; menu_type_id: any; veg: any; gf: any; k: any; description: any; faq: any; usd: any; recipe_code: any; image: null; pair_with1: any; pair_with2: any; pair_with3: any; add_on1: any; add_on2: any; add_on3: any; send_for_approval: any; }, event: OptionType, setFieldValue: { (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, key: any, clearValue?: any) => {
    this.setState({ openMenu: false })
    const { menuData } = this.state
    const finalData = { ...this.state.menuData, 
      ...values,  
      pair_withs_attributes: handleValuesToDescArr(values, menuData.pair_withs_attributes, "pair_with"),
      addons_attributes: handleValuesToDescArr(values, menuData.addons_attributes, "add_on"),
      [key]: event.value 
    }
    if (clearValue && (menuData[key] !== event.value)) {
      this.setState({ menuData: { ...finalData, ...clearValue } });
      setFieldValue(key, event.value);
      for (let item of Object.keys(clearValue)) {
        setFieldValue(item, clearValue[item]);
      }
    } else {
      this.setState({ menuData: finalData });
      setFieldValue(key, event.value);
    }
  };

  getBranchesDataResponse = (res: any) => {
    const { data } = res.branches;
    const defaultItem = { value: "", label: configJSON.templateBranchPlaceholder, isDisabled: true }
    if (data.length > 0) {
      let finalBranchesArr = !isBO() ? data.filter((item: any)=> Number(item.id) === Number(userBranchId)) : data;
      finalBranchesArr = finalBranchesArr.map((item: any) => {
        const { id, attributes } = item;
        const { branch_name, location_of_point_of_sale } = attributes;
        return { label: `${branch_name} - ${location_of_point_of_sale}`, value: Number(id), data: attributes };
      })
      finalBranchesArr = sortArrayAlphabetically(finalBranchesArr, 'label');
      finalBranchesArr.unshift(defaultItem);
      const branch_id = getUserBranchId() || this.state.menuData.branch_id;;
      const menuData = {...this.state.menuData, branch_id}
      const defaultMenuData = {...this.state.defaultMenuData, branch_id}
      this.setState({ branchData: finalBranchesArr, menuData, defaultMenuData });
    } else {
      this.setState({ branchData: [] });
      toast.error("No branches found. Please add a branch first");
    }
  };

  getCategoriesDataResponse = (response: any) => {
    const { data } = response;
    if (data.length > 0) {
      let finalCatArray: any = [];
      data.map((item: any) => {
        const { attributes } = item;
        const { id, name } = attributes;
        return finalCatArray.push({ label: name, value: Number(id) });
      })
      finalCatArray = sortArrayAlphabetically(finalCatArray, 'label');
      this.setState({ categoryData: finalCatArray });
    } else {
      this.setState({ categoryData: [] });
      toast.error(configJSON.menuTempNoCategoryMessage);
    }
  };

  getDepartmentsDataResponse = (deptResData: any) => {
    const defaultItem = { value: "", label: configJSON.templateDepartmentPlaceholder, isDisabled: true }
    if (deptResData.length > 0) {
      let finalDeptArray: any = [];
      isBO() ? deptResData.map((item: any) => {
        item.departments.map((dep: any) => {
          const { id, name } = dep;
          return finalDeptArray.push({ label: name, value: Number(id) });
        })
      }) : usersDepartments.map((dep: { attributes: { id: number; name: string; }; }) => {
        const { id, name } = dep.attributes;
        return finalDeptArray.push({ label: name, value: Number(id) });
      })

      finalDeptArray = sortArrayAlphabetically(finalDeptArray, 'label');
      finalDeptArray.unshift(defaultItem);
      this.setState({ departmentData: finalDeptArray });
    } else {
      this.setState({ departmentData: [defaultItem] });
      toast.error(configJSON.templateNoDepartmentMessage);
    }
  };

  handleActions = (name: string) => {
    this.closeMenu()
    this.setState({ menuTempAction: name });
  }

  checkEmptyAttachment = () => {
    return this.state.imageUrl ? {} : { image: "" }
  }

  addEditMenuTempApiCall = (payload: any, { resetForm }: { resetForm: () => void }) => {
    const { id, menuData} = this.state;
    payload = {...payload, branch_id: menuData.branch_id}
    if (Object.keys(payload).length) {
      this.setState({ loading: true });
      this.createMenuTempApiId = apiCall({
        method: id ? configJSON.putMethod : configJSON.exampleAPiMethod,
        body: convertAllFormData(payload, "data[attributes]"),
        endPoint: `${configJSON.menuTemplateUrl}/${id}`,
        token: true,
      });
    }
  }

  addEditTemplate = (values: any, { resetForm }: { resetForm: () => void }) => {
    const { menuData, defaultMenuData, template_id } = this.state;

    const { description, faq, usd, recipe_code, veg, gf, k, image, menu_type_id, department_id, name, branch_id, pair_withs_attributes, addons_attributes, send_for_approval } = defaultMenuData;
    const { pair_with1, pair_with2, pair_with3, add_on1, add_on2, add_on3 } = values;

    const { id } = menuData;

    const createPairWithAttr = [
      {
        pair_with: pair_with1,
      }, {
        pair_with: pair_with2,
      }, {
        pair_with: pair_with3,
      }
    ];

    const editPairWithAttr = () => {
      return [
        {
          pair_with: pair_with1,
          id: this.state.menuData.pair_withs_attributes[0].id,
        }, {
          pair_with: pair_with2,
          id: this.state.menuData.pair_withs_attributes[1].id,
        },
        {
          pair_with: pair_with3,
          id: this.state.menuData.pair_withs_attributes[2].id,
        },
      ];

    }

    const createAddOnsAttr = [
      {
        add_on: add_on1,
      }, {
        add_on: add_on2,
      }, {
        add_on: add_on3,
      }
    ];

    const editAddOnsAttr = () => {
      return [
        {
          add_on: add_on1,
          id: this.state.menuData.addons_attributes[0].id,
        }, {
          add_on: add_on2,
          id: this.state.menuData.addons_attributes[1]?.id,
        },
        {
          add_on: add_on3,
          id: this.state.menuData.addons_attributes[2]?.id,
        },
      ];

    }

    const orriginalData: any = {
      name,
      department_id,
      branch_id,
      menu_type_id,
      description,
      faq,
      veg,
      gf,
      k,
      usd: +usd,
      pair_withs_attributes,
      addons_attributes,
      ...(image ? { image } : {}),
      recipe_code,
      send_for_approval
    };

    const newData: any = {
      ...(!id ? { template_id } : {}),
      name: values.name,
      department_id: values.department_id,
      branch_id: values.branch_id,
      menu_type_id: values.menu_type_id,
      description: values.description,
      faq: values.faq,
      veg: values.veg,
      gf: values.gf,
      k: values.k,
      usd: +values.usd,
      send_for_approval: values.send_for_approval,
      recipe_code: values.recipe_code,
      ...(values.image ? { image: values.image } : this.checkEmptyAttachment()),
      pair_withs_attributes: id ? editPairWithAttr() : createPairWithAttr,
      addons_attributes: id ? editAddOnsAttr() : createAddOnsAttr,
    };

    let templatePayload: any = getDiffs(orriginalData, newData);
    templatePayload = trimStringValues(templatePayload);

    this.addEditMenuTempApiCall(templatePayload, { resetForm })
  }

  navigateToListing = () => {
    this.props.navigation.history.push(`Library`, 1.1)
  }

  downloadMenuTemp = () => {
    this.setState({ openMenu: false, isDownload: true })
		const ele = this.pdfRef.current;
    this.setState({loading:true,isDownload:true,openMenu:false})
    const handleLoading = () => {
      this.setState({isDownload:false,loading:false})
    }
    commonDownloadPdfFunc(ele,handleLoading,`Menu Template ${this.state.template_id}`,`${this.state.defaultMenuData.name}`)
  };

  handleDeleteClose = () => {
    this.setState({ opeDeleteModal: false });
  };
  handleDelete = () => {
    this.toggleMenu();
    this.setState({ opeDeleteModal: true });
  };
  handleDeleteTemplate = () => {
    this.setState({ loading: true });
    this.deleteMenuTempApiId = apiCall({ method: configJSON.deleteMethod, endPoint: `${configJSON.menuTemplateUrl}/${this.state.id}`, token: true });
  }
  deleteMenuTempResponse = (res: any) => {
    const { menu } = res;
    if (menu.length > 0) {
      toast.success(configJSON.menuDeleteSuccessMessage);
      this.handleDeleteClose();
      this.navigateToListing()
    }
  };

  handleMenuFavouriteClick = () => {
    this.toggleMenu();
    this.setState({ loading: true });
    const {  menuData, id } = this.state;
    this.favouriteMenuTempApiId = menuData.favorite ?  
    apiCall({
      method: configJSON.deleteMethod,
      endPoint: `${configJSON.menuTemplateUrl}/${id}${configJSON.removeFavorite}`,
      token: true,
    })
    : apiCall({
      method: configJSON.putMethod,
      endPoint: `${configJSON.menuTemplateUrl}/${id}${configJSON.addFavorite}`,
      token: true,
    });
}

  addMenuTempResponse = (apiResponse: { data: { attributes: object; }; message: string[]; errors: string[]; }, message: string) => {
    if (apiResponse.data) {
      if (this.state.id) {
        const dataItem = apiResponse.data.attributes
        this.setMenuStateData(dataItem);
        this.setDefaultMenu(dataItem)
      } else {
        this.setState({ ...this.initMenuState });
        this.navigateToListing()
      }
      this.handleActions(configJSON.view)
      toast.success(message);
    } else if (apiResponse.message) {
      const {defaultMenuData} = this.state
      const dataItem = {...defaultMenuData, favorite: !defaultMenuData.favorite};
      this.setMenuStateData(dataItem);
        this.setDefaultMenu(dataItem)
      toast.success(message);
    } else {
      handleApiError(apiResponse.errors);
    }
  };

  handleDuplicateMenuTemp = () => {
    this.setState({ loading: true });
    this.duplicateMenuTempApiId = apiCall({ method: configJSON.exampleAPiMethod, endPoint: `${configJSON.menuTemplateUrl}/${this.state.id}/duplicate`, token: true });
  }

  setMenuStateData = (response: object) => {
    const { template_id } = this.state;
    this.props.navigation.history.push(`${configJSON.MenuTempNavigation}${template_id}`, response)
  }

  allowMenuEdit = () => {
    return !!((this.state.creator?.id === loggedUserId) || isBO());
  }

  // Customizable Area End
  // Customizable Area End
}
