import React, { Component } from "react";
import { Popper, Paper, Grow, ClickAwayListener, MenuList, MenuItem, Radio } from '@material-ui/core';
import { Lists } from "../../../../blocks/ticketsystem/src/EditTicketController.web";

interface Props {
  classes?: any;
  list: Lists[];
  selected: string | number;
  open: boolean;
  anchorRef: any;
  handleClose: any;
  handleSelect: any;
}



class RadioList extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { list, selected, open, classes, anchorRef, handleClose, handleSelect } = this.props;

    return (
      <Popper style={{ zIndex: 999 }} className={classes} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {list.map((item: any) => (
                    <MenuItem
                      data-test-id="status-item"
                      key={item.id}
                      selected={item.value === selected}
                      onClick={() => handleSelect(item)}
                      disabled={item.isDisabled}
                      style={{height: list.length === 1 ? "35px" : "auto"}}
                    >
                      <Radio
                        key={item.id}
                        checked={item.value === selected}
                        name="status-list"
                      />
                      {item.name}
                    </MenuItem>
                  ))}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  }
}

export default RadioList;
