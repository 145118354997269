
import React, { Component } from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Typography, Box, Button, Backdrop,CircularProgress } from "@material-ui/core";
import { Styles } from "@material-ui/styles";
import { ClassNameMap } from "@material-ui/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { colors } from "../utils/commonDesign";
export const configJSON = require("./config");
// Customizable Area End


// Customizable Area Start
const styles: Styles<Theme, {}, string> = () => ({
    timeDropdown: {
        position: "relative",

    },
    inputField: {
        padding: "12px",
        border: "2px solid #ccc",
        cursor: "pointer",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "8px",
        background: "#ffffff"
    },
    paraSelect: {
        fontSize: "1rem",
        color: "#020202",
        display: "flex",
        alignItems: "center",
        fontWeight: 600,
        "& img": {
            marginRight: "1rem"
        }
    },
    arrowIcon: {
        marginLeft: "5px",
    },
    dropdownModal: {
        width: "100%",
        position: "absolute",
        top: "100%",
        left: "0",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        padding: "12px 10px",
        display: "inline-block",
        borderRadius: "8px",
        marginTop: "3px",
        zIndex: 999
    },
    modalContent: {
        maxHeight: "192px",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        ["& button"]: {
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: "1rem",
            borderRadius: 8,
            width: "10rem",
            color: "#020202",
            padding: "6px"

        },
    },
    selectItem: {
        padding: 6,
        cursor: "pointer",
        fontSize: 16,
        display: "flex",
        alignItems: "center"
    },
    imgSection: {
        display: "flex",
        alignItems: "center",
        textTransform: "capitalize",
        "& svg": {
            color: colors.secondaryColor1,
            marginRight: "1rem",
        },
        "& p": {
            fontSize: "0.9rem",
            fontWeight: 500
        }
    },
    exportButton: {
        backgroundColor: colors.secondaryColor2,
        border: "1px solid transparent",
        marginTop: "10px",
        marginLeft: "5px",
        width: "7rem !important",
        borderRadius: "10px !important",
        ["&:hover"]: {
            backgroundColor: colors.secondaryColor2,
        },
        "& .secondary":{
            width:"30px !important",
            height:"30px !important",
            color:"#6d827f"
        }
    },
    backdrop: {
        zIndex: 0,
        backgroundColor: "transparent",
    },

});
interface DropdownProps {
    selectedValue: string | null | boolean;
    title: string;
    isModalOpen: boolean;
    isIcon?: boolean;
    imagePath?: string;
    data?: {
        id: number;
        name: string;
        text: string
    }[];
    hasButton?: boolean;
    toggleModal: any;
    onClick?: () => void;
    onValueSelect: (value: {
        id: number;
        name: string;
        text: string
    }) => void;
    classes: ClassNameMap<"timeDropdown" | "inputField" | "errorDropdownField" | "arrowIcon" | "dropdownModal" | "backdrop"
        | "modalContent" | "selectItem" | "errorMessage" | "imgSection" | "paraSelect" | "exportButton"

    >
    downloadData?: any
}
// Customizable Area End

export class CustomRadioSelect extends Component<DropdownProps> {
    constructor(props: DropdownProps) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { selectedValue, title, isModalOpen, downloadData, hasButton, toggleModal, onClick, data, classes, isIcon, imagePath } = this.props;
        return (
            <Box className={classes.timeDropdown}>
                <Box data-test-id="toggle_button" className={classes.inputField} onClick={toggleModal}>
                    <Typography data-test-id="selectedValue" className={classes.paraSelect}>{selectedValue || <React.Fragment>
                        {isIcon ? <React.Fragment > <img src={imagePath} alt="filter_export" /> <span>{title}</span> </React.Fragment> : <span>{title}</span>}
                    </React.Fragment>}</Typography>
                    {isModalOpen ? <KeyboardArrowUpIcon className={classes.arrowIcon} /> : <KeyboardArrowDownIcon className={classes.arrowIcon} />}
                </Box>
                <Backdrop
                    className={classes.backdrop}
                    open={isModalOpen}
                    onClick={() => toggleModal(false)}
                />
                {isModalOpen && (
                    <Box className={classes.dropdownModal}>
                        <Box className={`${classes.modalContent} filter`}>
                            {data?.map((value) => (
                                <Box key={value.id} className={classes.selectItem} data-test-id="value_select" onClick={() => this.props.onValueSelect(value)}>
                                    {selectedValue === value.name ? (
                                        <Box className={classes.imgSection}>
                                            <RadioButtonCheckedIcon />
                                            <Typography data-test-id="value_name">{value.name}</Typography>
                                        </Box>
                                    ) : (
                                        <Box className={classes.imgSection}>
                                            <RadioButtonUncheckedIcon />
                                            <Typography>{value.name}</Typography>
                                        </Box>
                                    )}
                                </Box>
                            ))}
                            {hasButton && <Button
                                data-test-id="download_btn"
                                disabled={downloadData?.length === 0}
                                onClick={onClick}
                                className={classes.exportButton}
                            >
                                {downloadData?.length === 0 ? <CircularProgress className="secondary" /> : configJSON.exportBtn}
                            </Button>}
                        </Box>
                    </Box>
                )}
            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default withStyles(styles)(CustomRadioSelect);
// Customizable Area End

