// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall, passwordEncryption, handleApiError, handleUndefinedData, checkTokenBeforeLogin } from "../../../components/src/utils/commonFunctions";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  alreadySignedUp: boolean;
  password: string;
  confirmPassword: string;
  email: string;
  enablePasswordField: boolean;
  enableConfirmPassword: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  placeHolderConfirmPassword: string;
  imgPasswordVisible: boolean;
  imgPasswordInVisible: boolean;
  isBusinessOwner: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class EmailRegistrationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserApiId: string = "";
  signUpApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      loading: false,
      alreadySignedUp: false,
      email: "",
      password: "",
      confirmPassword: "",
      enablePasswordField: true,
      enableConfirmPassword: true,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      placeHolderConfirmPassword: configJSON.placeHolderConfirmPassword,
      imgPasswordVisible: imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      isBusinessOwner: false,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.setState({ loading: true });
    checkTokenBeforeLogin(this.props.navigation)
    this.getUserApiId = apiCall({ method: "GET", endPoint: "account_block/accounts/get_business_owner", token: true });
  }

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPassword: !this.state.enableConfirmPassword,
    });
  };

  goToLogin = () => {
    this.props.navigation.navigate("EmailLogin");
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.getUserApiId) {
          this.getUserResponse(responseJson);
        } else if (apiRequestCallId === this.signUpApiId) {
          this.getSignUpResponse(responseJson);
        }
      }
    }
  }
  getUserResponse = (response: { business_owner_email: string; data: { attributes: { email: string; }; }; errors: string[]; signup: boolean; }) => {
    if (response.errors) {
      this.setState({ alreadySignedUp: false, email: "" })
      handleApiError(response.errors);
    } else {
      const email = response.business_owner_email ?? response.data.attributes.email;
      if (response.data?.attributes.email) {
        this.props.navigation.goBack();
      } else {
        this.setState({ alreadySignedUp: !email ? true : false, isBusinessOwner: response.signup , email: handleUndefinedData(email, "") })
      }
    }
  };
  getSignUpResponse = (response: { data: object; errors: string[]; }) => {
    if (response.data) {
      this.handleUserRedirection();
    } else {
      handleApiError(response.errors)
    }
  };

  handleUserRedirection = () => {
    this.props.navigation.navigate(this.state.isBusinessOwner ? "MyProfile" : "JoiningForm")
  };

  handleSumbit = (event: { email: string; password: string; confirmPassword?: string; encryptedPassword?: string; }) => {
    if (this.state.alreadySignedUp) {
      this.handleUserRedirection();
    } else {
      const {isBusinessOwner} = this.state;
      passwordEncryption(event.password).then((res) => 
      {
        event.encryptedPassword = res
        isBusinessOwner ? this.doEmailSignUp(event) : this.otherUserSignup(event);
      }
    )
    }
  }

  doEmailSignUp = (event: { email: string; password: string; confirmPassword?: string; encryptedPassword?: string; }) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
    };

    const attrs = {
      email: event.email,
      password: event.encryptedPassword,
      role_id: 2
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signUpApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  otherUserSignup = (event: { email: string; encryptedPassword?: string; }) => {
    const attrs = {
      email: event.email,
      password: event?.encryptedPassword,
    };

    const data = {
      attributes: attrs,
    };

    const body = {
      data
    };

    this.signUpApiId = apiCall({ contentType: configJSON.validationApiContentType, method: configJSON.putMethod, body: JSON.stringify(body), endPoint: configJSON.registerOtherUserEndPoint, token: false });
  }
  // Customizable Area End
}