// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { apiCall, capitalizeFirstLetter, filterNullObj, handleApiError, handleExpiredToken } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
import moment from "moment";
import { DateData } from "./LibraryController.web";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    classes: Record<string, string>;
    gridView: boolean;
    date: DateData;
    searchQuery: string;
    // Customizable Area Start
    navigation: any;
    allowEditDelete: (creatorId: number | string | undefined) => void;
    formData:FormValues
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    checklist_list: CheckListData[];
    anchorEl: HTMLElement | null;
    checklist_id: string;
    header_list: string[];
    isOpen: { [key: string]: boolean };
    openDeleteModal: boolean;
    search_checklist_list: CheckListData[] | null;
    checkListData: CheckListData[];
    meta: {
        total_pages: number;
        total_count: number;
        current_page: number;
        next_page: null | number;
        previous_page: null | number;
    },
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

// Customizable Area Start
interface ValidDataObj {
    attributes: {
        id: string;
        title: string;
        favorite: boolean;
        created_at: string;
        branch_id: number;
    }
}

interface ValidData {
    data: Array<ValidDataObj>;
}
export interface ValidResponseType {
    checklists: ValidData;
}
export interface InvalidResponseType {
    errors: string;
}
interface FormValues {
    branch_id: string;
    department_id: string;
}
interface ResponseCheckList {
    checklists: {
        data: Array<ValidDataObj>;
    },
    meta: {
        total_pages: number;
        total_count: number;
        current_page: number;
        next_page: number | null;
        previous_page: number | null;
    }
}
interface ResponseErrorData {
    errors: string[]
}
export interface CheckListData {
    attributes: {
        id: string;
        creator?: ChecklistCreator;
        title: string;
        favorite: boolean;
        created_at: string;
        branch_id: number;
    }
}
export interface ChecklistCreator {
  id: number | string;
  name: string;
  designation: string;
  email: string
} 
interface Favourate {
    message: [string];
}
// Customizable Area End
export default class ChecklistController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    deleteChecklistApiId: string = "";
    favouriteChecklistApiId: string = "";
    getCheckListApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            // Customizable Area End
        ];
        this.state = {
            // Customizable Area Start
            loading: false,
            checklist_list: [],
            anchorEl: null,
            checklist_id: "",
            header_list: configJSON.checkListHeaderList,
            isOpen: {},
            openDeleteModal: false,
            search_checklist_list: null,
            checkListData: [],
            meta: {
                total_pages: 1,
                total_count: 1,
                current_page: 1,
                next_page: null,
                previous_page: null
            },
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let resJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            handleExpiredToken(resJson, this.props.navigation);
            this.setState({ loading: false });

            if (apiCallId && resJson) {
                if (apiCallId === this.getCheckListApiCallId) {
                    this.getChecklistsResponse(resJson);
                }
                else if (apiCallId === this.deleteChecklistApiId) {
                    this.handleDeleteApiResponse(resJson);
                }
                else if (apiCallId === this.favouriteChecklistApiId) {
                    this.handleFavouriteResponse(resJson);
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        const { date: { from_date, to_date, time_range }, searchQuery,formData } = this.props
        this.setState({ loading: true })
        this.getChecklists(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
    }

    componentDidUpdate(previousProps: Props, prevState: S) {
        const { date: { from_date, to_date, time_range }, searchQuery,formData } = this.props
        if ((previousProps.date.time_range !== this.props.date.time_range) || (previousProps.date.from_date !== this.props.date.from_date) || (previousProps.date.to_date !== this.props.date.to_date) || formData.branch_id !== previousProps.formData.branch_id || formData.department_id !== previousProps.formData.department_id) {
            if (from_date && to_date) {
                this.setState({ loading: true })
                this.getChecklists(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
            }
            if (time_range !== "custom") {
                this.setState({ loading: true })
                this.getFilteredChecklists(from_date, to_date, time_range, searchQuery)
            }
        }
        if (previousProps.gridView !== this.props.gridView) {
            this.closeDropDown()
        }
        if (previousProps.searchQuery !== this.props.searchQuery) {
            this.setState({ loading: true })
            this.getChecklists(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
        }
    }

    getChecklistsResponse = (response: ResponseCheckList & ResponseErrorData) => {
        this.closeDropDown()
        if (response.errors) {
            this.setState({loading:false})
            handleApiError(response.errors)
        } else {
            this.setState(previous => ({loading:false, checkListData: response.checklists.data, meta: response.meta ?? previous.meta }))
        }
    }

    handleDeleteApiResponse = (deleteRes: ResponseErrorData) => {
        const { date: { from_date, to_date, time_range } ,formData} = this.props
        if (deleteRes.errors) {
            handleApiError(deleteRes.errors)
        } else {
            this.getChecklists(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id)
            toast.success(configJSON.checklistDeleteMessage)
        }
    }

    handleFavouriteResponse = (response: { message: string[], errors: string[]}) => {
        const { date: { from_date, to_date, time_range } ,formData} = this.props
        this.getChecklists(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id)
        response.message ? toast.success(capitalizeFirstLetter(response.message[0])) : handleApiError(response.errors)
    }

    getChecklists = (page: number, from_date: string | null, to_date: string | null, time_range: string | null, department_id: string, query?: string) => {
        this.setState({loading:true})
        let endPointfiter: string = `${configJSON.checkListApiEndPoint}`
        const {branch_id} = this.props.formData
        let allData = {page, custom_start_date: from_date ? moment(from_date).format("DD/MM/YYYY") : "", custom_end_date: to_date ? moment(to_date).format("DD/MM/YYYY") : "", time_range, branch_id, department_id, query};
    
        this.getCheckListApiCallId = apiCall({ method: "GET", endPoint: endPointfiter + filterNullObj(allData), token: true })
    }

    getFilteredChecklists = (from_date: string | null, to_date: string | null, time_range: string | null, searchQuery: string = '') => {
        const department_id = this.props.formData.department_id
        switch (time_range) {
            case configJSON.today:
                this.getChecklists(this.state.meta.current_page, from_date, to_date, "today", department_id, searchQuery)
                break
            case configJSON.yesterday:
                this.getChecklists(this.state.meta.current_page, from_date, to_date, "yesterday", department_id, searchQuery)
                break
            case configJSON.lastDays:
                this.getChecklists(this.state.meta.current_page, from_date, to_date, "15_days", department_id, searchQuery)
                break
            default:
                this.getChecklists(this.state.meta.current_page, from_date, to_date, "", department_id, searchQuery)
                break
        }
    }

    getCheckListDataShow = () => {
        this.setState({ loading: true });
        this.getCheckListApiCallId = apiCall({
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.checkListApiEndPoint,
            token: true
        });
    };

    getCheckListSuccessCallBack = (responseJson: ResponseCheckList) => {
        this.setState({
            checkListData: responseJson.checklists.data
        })
    };

    handleFavouriteClick = (favourite: boolean) => {
        this.setState({ loading: true });
        this.favouriteChecklistApiId = favourite ?
        apiCall({
          method: configJSON.deleteMethod,
          endPoint: `${configJSON.deletecheckListApiEndPoint}${this.state.checklist_id}${configJSON.removeFavorite}`,
          token: true,
        })
        :
        apiCall({
          method: configJSON.putMethod,
          endPoint: `${configJSON.deletecheckListApiEndPoint}${this.state.checklist_id}${configJSON.addFavorite}`,
          token: true,
        });
    };

    favourateSuccessCallBack = (responseJson: Favourate) => {
        this.getCheckListDataShow();
    };

    handleOpenDeleteModal = () => {
        this.setState({ openDeleteModal: true });
        this.closeDropDown();
    };

    handleCloseDeleteModal = () => {
        this.setState({ openDeleteModal: false });
    };

    toggleDropdown = (itemId: string) => {
        this.setState((prevState) => {
            const updatedIsOpen = { ...prevState.isOpen };
            for (const keyValue in updatedIsOpen) {
                updatedIsOpen[keyValue] = false;
            }
            updatedIsOpen[itemId] = !prevState.isOpen[itemId];
            return {
                isOpen: updatedIsOpen,
                checklist_id: itemId
            };
        });
    };

    getFormattedDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString().split(",")[0];
    };

    closeDropDown = () => {
        this.setState((prevState) => {
            const updatedIsOpen = { ...prevState.isOpen };

            for (const keyItem in updatedIsOpen) {
                updatedIsOpen[keyItem] = false;
            }
            return {
                isOpen: updatedIsOpen,
            };
        });
    };

    handleDeleteClick = () => {
        this.setState({ loading: true });
        this.deleteChecklistApiId = apiCall({ method: configJSON.deleteMethod, endPoint: `${configJSON.deletecheckListApiEndPoint}${this.state.checklist_id}`, token: true });
        this.handleCloseDeleteModal();
    };

    handleViewClick = (itemId: string) => {
        this.props.navigation.history.push(`ChecklistForm?id=${itemId}`, {from: configJSON.fromLibrary, action: configJSON.fill})
    };

    handlePageChange = (page: number) => {
        const { date: { from_date, to_date, time_range } ,formData} = this.props
        this.setState({
            meta: {
                ...this.state.meta,
                current_page: page
            }
        });
        this.getChecklists(page, from_date, to_date, time_range, formData.department_id)
    };
    // Customizable Area End
}