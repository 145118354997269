import React, { FC, useEffect } from 'react'
import { Avatar, Box, Typography, Divider, useTheme, Drawer,Badge, IconButton, List, ListItem, Toolbar, AppBar, CssBaseline } from "@material-ui/core";
import { TreeView, TreeItem } from '@material-ui/lab';
import { ExpandMore, ChevronRight, ExpandMoreSharp } from "@material-ui/icons";
import { colors } from "./utils/commonDesign";
import { treeData } from './assets/contant';
import clsx from 'clsx';
import { UseStyle } from './WrapperStyle.web';
import { getUser, getUserRoleID, loggedUserId, userProfileId } from "./utils/commonFunctions";
import BulkInvite from '../../blocks/BulkUploading/src/BulkInvite.web';
import Logout from '../../blocks/dashboard/src/Logout.web';
import { logoutImage } from "../../blocks/dashboard/src/assets"
import AdvanceSearchBar from '../../blocks/advancedsearch/src/AdvanceSearchBar.web';
import NotificationsIcon from '@material-ui/icons/Notifications';
import  Notifications  from '../../blocks/notifications/src/NotificationsModal.web';
const user: any = getUser();

const companyName = user?.data.attributes.company_profile.data.attributes.name;
const ownerPhoto = user?.data.attributes.profile.data.attributes.photo;
const userName = user?.data.attributes.profile.data.attributes.owner_name;
const companyPhoto = user?.data.attributes.company_profile.data.attributes.photo;

interface TreeNode {
    id: number;
    name: string;
    children?: TreeNode[];
    src?: string;
    href?: string;
    isModal?: string;
    allowedUsers: number[];
}

interface PropType {
    backbtn?: boolean;
    backHandleClick?: () => void;
    bellIcn?: boolean;
    homeIcn?: boolean;
    title?: string | any;
    divider?: boolean
    child: React.ReactNode;
    navigation?: any,
    search?: React.ReactNode,
    searchHandleClick?: any,
    querySearch?: string | undefined,
    searchData?: any
    autoFocus?:boolean
    newNotificationCount?: number;
    markSingleNotificationRead?: (count: number) => void;
    markAllNotificationRead?: () => void;
}

export const Wrapper: FC<PropType> = ({ markSingleNotificationRead, markAllNotificationRead, backbtn, backHandleClick, searchData,
    querySearch, title, bellIcn, homeIcn, child, divider, navigation, search, searchHandleClick,autoFocus, newNotificationCount }) => {
    const classes = UseStyle()
    const theme = useTheme()
    const roleID = getUserRoleID();
    const [open, setOpen] = React.useState(false);
    const [openNotificationModel, setopenNotificationModel] = React.useState(false);
    const [notificationCount, setNotificationCount] = React.useState(0);
    const [openModal, setOpenModal] = React.useState("");
    const [query, setQuery] = React.useState("");
    const [expanded, setExpanded] = React.useState<any[]>([]);
    const countRecieveValue = (count:number) => {
        setNotificationCount(count)
    }
    const handleDrawerClose = () => {
        setOpen(prev => !prev);
        setExpanded([])
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const closeModal = () => {
        setOpenModal("");
    };
    const handleNotificationModel = () => {
        setopenNotificationModel(true)
    }
    const searchCloseClick = () => {
        setQuery("")
        searchHandleClick("")
    }

    const handleEnter = (e: { key: string; }) => {
        if (e.key === 'Enter') {
            searchHandleClick(query.trim())
        }
    }
    useEffect(() => {
        if (querySearch === "") {
            setQuery("")
        }
    }, [querySearch])

    useEffect(() => {
      if (newNotificationCount !== notificationCount) {
        countRecieveValue(newNotificationCount ?? 0);
      }
  }, [newNotificationCount])

  const readSingleNotificationInList = (notificationId: number) => {
    markSingleNotificationRead && markSingleNotificationRead(notificationId)
  }
  const readAllNotificationInList = () => {
    markAllNotificationRead && markAllNotificationRead()
  }

    const handleMenuClick = (nodes: TreeNode) => {
        if (nodes.isModal) {
            setOpenModal(nodes.isModal);
        } else {
            return nodes.href && navigation.history.push(nodes.href);
        }
        
    }
    const defaultProps = {
        color: 'secondary',
        children: <NotificationsIcon />,
      };
    const userRoleName = () => {
      let result;
      switch (roleID) {
        case 2:
          result = "Owner";
          break;
        case 3:
          result = "Manager";
          break;
        case 4:
          result = "Supervisor";
          break;
        case 5:
          result = "Agent";
          break;
        default:
          break;
      }
      return result;
  }

    const renderTree = (nodes: TreeNode) => {
        const isHighlight = expanded.includes(nodes.id.toString());
        const haveAChild = nodes.children?.filter(child => child?.allowedUsers?.includes(roleID));
        return (
            (nodes?.allowedUsers?.includes(roleID) && <ListItem onClick={() => handleMenuClick(nodes)} className={`${classes.treeWrapper} ${isHighlight ? classes.treeWrapperBorder : ""}`} >
                <TreeItem onClick={handleDrawerOpen} key={nodes.id} nodeId={nodes.id.toString()}
                    label={
                        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                            <Typography style={{ fontWeight: isHighlight ? 700 : 400 }} className={open ? classes.treeViewTextExpand : classes.treeViewText}>
                                {nodes.name}
                            </Typography>
                            {haveAChild && haveAChild.length > 0 && <ExpandMoreSharp className={classes.expandIcn} />}
                        </Box>
                    }
                    icon={<div><img src={nodes.src} className={classes.drawerIcon} /></div>}
                    style={{ width: "100%" }}
                >
                    {Array.isArray(nodes.children) ? nodes.children.map((node) => renderChildTree(node)) : null}
                </TreeItem>
            </ListItem>)
        )
    };

    const renderChildTree = (nodes: TreeNode) => {
      const isHighlight = expanded.includes(nodes.id.toString())
      const hasChild = nodes.children?.filter(child => child?.allowedUsers?.includes(roleID))

      return (
          (nodes?.allowedUsers?.includes(roleID) && <ListItem onClick={() => handleMenuClick(nodes)} className={classes.treeWrapper} >
              <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                <Typography style={{ fontWeight: isHighlight ? 700 : 400, marginLeft: "0" }} className={open ? classes.treeViewTextExpand : classes.treeViewText}>
                    {nodes.name}
                </Typography>
                {hasChild && hasChild.length > 0 && <ExpandMoreSharp className={classes.expandIcn} />}
              </Box>
          </ListItem>)
      )
  };

    const Header = () => (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >
            <Toolbar >
                <Box width={"100%"}>
                    <Box style={{padding:"0", minHeight: "42px", height: "auto"}} display={'flex'} justifyContent={'space-between'} alignItems={"center"} width={"100%"} pb={"5px"} pt={"10px"} >
                        <Box display={'flex'} alignItems={"center"}>
                            {backbtn &&
                                <img data-test-id="back-btn" onClick={backHandleClick ? backHandleClick : navigation.goBack} src={require('./assets/backIcon.png')} className={classes.backImage} />
                            }
                            {title &&
                                <Typography className={classes.headline}>{title}</Typography>
                            }
                        </Box>
                        <Box className={classes.rightBox} display={'flex'} alignItems={"center"} >
                            {search && <AdvanceSearchBar
                                setQuery={setQuery}
                                query={query}
                                handleEnter={(e) => handleEnter(e)}
                                searchHandleClick={() => searchHandleClick(query)}
                                searchCloseClick={searchCloseClick}
                                querySearch={querySearch}
                                searchData={searchData}
                                autoFocus={autoFocus}

                            />}
                            {homeIcn &&
                                <img src={require('./assets/home.png')} onClick={() => navigation.navigate("HomePage")} className={classes.headerImage} />
                            }
                            {bellIcn &&
                            <Badge onClick={handleNotificationModel} className={classes.badge} badgeContent={notificationCount}>
                                 <img src={require('./assets/bell.png')} className={classes.headerImage} />
                            </Badge>
                            }
                            <Avatar className={classes.drawerIcon} src={companyPhoto} onClick={() => navigation.navigate("ViewCompanyProfile")} />
                        </Box>
                    </Box>
                    {divider && <Divider className={classes.divider} />}
                </Box>
            </Toolbar>
        </AppBar>
    )

    const SideBar = () => (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <Box pt={2} textAlign={"center"} onClick={() => navigation.navigate("HomePage")} style={{cursor: "pointer"}}>
                {open ?
                    <img src={require('./assets/QWIP_Emblem.png')} className={classes.closeImage} /> :
                    <img src={require('./assets/Q_Emblem.png')} className={classes.openImage} />
                }
            </Box>
            <Box height={"100%"} marginTop={'14px'} bgcolor={colors.secondaryColor1} borderRadius={"0px 20px 0px 0px"}>
                <Box className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        <img className={`${open && classes.iconRotate}`} src={require('./assets/openIcon.png')} />
                    </IconButton>
                </Box>
                <Box className={`${classes.drawerContainer} ${classes.drawerContainerCover}`}>
                    <Box className={classes.sideBarCompany} onClick={() => navigation.navigate("ViewCompanyProfile")}>
                        <Avatar className={classes.drawerIcon} src={companyPhoto} />
                        <Typography className={open ? classes.sideViewTextExpand : classes.sideViewText}>{companyName}</Typography>
                    </Box>
                    <TreeView
                        className={classes.treeRoot}
                        defaultCollapseIcon={<ExpandMore />}
                        defaultExpandIcon={<ChevronRight />}
                        expanded={expanded}
                        onNodeSelect={(event: any, nodeId: any) => {
                            const index = expanded.indexOf(nodeId as never);
                            const copyExpanded = [...expanded];
                            if (index === -1) {
                                copyExpanded.length = 0
                                copyExpanded.push(nodeId as never);
                            } else {
                                copyExpanded.splice(index, 1);
                            }
                            setExpanded(copyExpanded);
                        }}
                    >
                        <List key={1}>
                            {treeData.map((node: any) => renderTree(node))}
                        </List>
                    </TreeView>
                </Box>
                <Box position={"absolute"} bottom={30} className={classes.drawerContainer}>
                    <Box className={classes.sideBarCompany} py={"8px"} onClick={() => navigation.history.push(`/EmployeeProfileDetails/${userProfileId}`)}>
                        <Avatar className={classes.drawerIcon} src={ownerPhoto} />
                        <Box marginLeft={open ? "8px" : "20px"}>
                            <Typography className={classes.ownerTxt}>{userName}</Typography>
                            <Typography className={classes.ownerHeadline}>{userRoleName()}</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.sideBarCompany} py={"8px"} onClick={() => navigation.history.push("/Setting")}>
                        <img className={classes.drawerIcon} src={require('./assets/setting.png')} />
                        <Box marginLeft={open ? "8px" : "20px"}><Typography className={classes.ownerTxt}>Settings</Typography></Box>
                    </Box>
                    <Box onClick={() => setOpenModal("logout")} data-test-id="open-modal" className={classes.sideBarCompany} py={"8px"}>
                        <img className={classes.drawerIcon} src={logoutImage} alt="logout" />
                        <Box marginLeft={open ? "8px" : "20px"}><Typography className={classes.ownerTxt}>Logout</Typography></Box>
                    </Box>

                </Box>
            </Box>
        </Drawer>
    )
                        
    return (
        <>
            <Box className={classes.root}>
                <CssBaseline />
                <Header />
                <SideBar />
                <main className={classes.content}>
                    {/* <Box className={classes.mainToolbar} /> */}
                    <Box className={classes.childStyle}>
                        {child}
                    </Box>
                </main>

            </Box>
            {openModal === "bulkInvite" && 
              <BulkInvite
                  open={openModal === "bulkInvite"}
                  handleClose={closeModal}
              />
            }
            <Logout
                open={openModal === "logout"}
                handleClose={closeModal}
            />
            <Notifications 
            navigation={navigation}
             countValueFunc={countRecieveValue}
             openNotificationModel = {openNotificationModel}
             handleClose = {() => setopenNotificationModel(false)}
             readSingleNotificationInList={readSingleNotificationInList}
             readAllNotificationInList={readAllNotificationInList}
            />
        </>
    )
}

