import { colors } from "../../../components/src/utils/commonDesign";
import { commonInputField } from "../../../components/src/utils/commonInputStyle";
export const helperText = {
  margin: 0,
  fontSize: "14px",
  lineHeight: "18px",
  marginTop: "5px",
  marginBottom: "-23px",
  color: colors.errorText,
}
const inputLabel = {
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: 400,
  color: colors.primaryColor2,
}
export const joiningformstyles: any = {
  ...commonInputField,
  joiningformcard: {
    borderRadius: 16,
    margin: "auto",
    overflow: "auto"
  },
  joiningformcardContent: {
    padding: "35px 45px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  joiningformtimeContainer: {
    marginTop: 10,
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: 80,
    width: "95%"
  },
  joiningformInputSection: {
    display: "grid",
    gridTemplateColumns: '1fr',
    justifyItems: "center"
  },
  joiningformLeftSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  phoneGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "15px",
  },
  joiningformtitle: {
    color: colors.secondaryColor1,
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "42px",
    marginTop: 20,
  },
  joiningformcontainer: {
    flexWrap: "nowrap",
    gap: "67px",
   
  },
  fieldWrapper: {
    margin: "25px 0 0px",
    position: "relative" as any,
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      backgroundColor: "#fff",
      height: "56px",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "21px",
        "& .Mui-disabled": {
          color: colors.text,
          backgroundColor: "#F5F5F5",
          borderRadius: "10px",
        },
      },
    },
  },
  Dropdownstyles:{
      borderRadius: "10px",
      boxShadow: "none",

      height:"45px",
      marginTop: "11px",
      cursor: "pointer",
      fontSize: "16px",
      lineHeight: "21px",
      "&:hover": {
        borderColor: "#969492",
      },
     
    
    },

  inputLabel,
  inputField: {
    marginTop: "10px",
    margin: 0,
  },
  helperText: {
    ...helperText,
    marginBottom: "-23px",
  },
    joiningformuploadImage1: {
      width: "100% !important",
      border: "1px dashed #969492 !important",
    },
    joiningformuploadDocuments: {
      height: "140px !important",
      marginTop: "30px !important",
      "& .uploadImage": {
        width: "56px !important",
        height: "55px !important",
      },
    },
    joiningformform: {
      margin: "25px 72px 0",
    },
    joiningformform1: {
      margin: "35px 72px 0",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column"
    },
    joiningformfieldWrapper: {
      margin: "25px 0 0px",
      width: "438px",
      "& .MuiInputBase-root": {
        borderRadius: "10px",
        height: "56px",
        "& .MuiInputBase-input": {
          fontSize: "16px",
          lineHeight: "21px",
          "& .Mui-disabled": {
            backgroundColor: "#F5F5F5",
            color: "#020202",
            borderRadius: "10px",
          },
        },
      },
      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: colors.secondaryColor1,
        },
      },
      "& .MuiFormHelperText-root": helperText,
    },
    joiningforminputLabel: {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: 400,
      color: colors.primaryColor2,
    },
    joiningforminputField: {
      margin: 0,
      marginTop: "10px",
      "& .MuiInputBase-root": {
        borderRadius: "10px",
        height: "50px",
        "& .MuiInputBase-input": {
          fontSize: "16px",
          lineHeight: "21px",
        },
      },
      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: colors.secondaryColor1,
        },
      },
    },
    joiningformdateField: {
      "& .MuiInputBase-root": {
        border: "1px solid #0000003b",
        padding: "0 14px",
      },
      "& .Mui-focused": {
        borderColor: colors.secondaryColor1,
      },
    },
    joiningformtextAreaField: {
      height: "145px !important",
      "& .MuiInputBase-root": {
        height: "100% !important",
        "& .MuiInputBase-input": {
          height: "100% !important",
          overflow: "auto !important",
        },
      },
    },
    joiningformdocList: {
      justifyContent: "space-between",
      padding: "0px 10px",
      height: "48px",
      border: "1px solid #969492",
      display: "flex",
      alignItems: "center",
      borderRadius: "10px",
      marginTop: "40px",
      "& .docName": {
        fontSize: "18px",
        lineHeight: "24px",
        textDecoration: "none",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginRight: 20,
      },
      "& .deleteIcon": {
        height: 24,
        width: 21,
        cursor: "pointer",
        marginRight: 20
      }
    },
    joiningformhelperText: {
      color: colors.errorText,
      margin: 0,
      marginTop: "5px",
    },
    joiningformerrorMsg: {
      color: colors.errorText,
      paddingBottom: "5px",
      marginBottom: "-20px",
      marginTop: "10px",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "21px",
      letterSpacing: "0.03333em",
    },
    joiningformprogressStepper: {
      padding: "0px",
      marginTop: 15,
      background: "transparent",
      color: colors.primaryColor2,
      "& .MuiMobileStepper-progress": {
        height: 8,
        width: 457,
        backgroundColor: "#bec3c1",
        borderRadius: 4,
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: colors.secondaryColor1,
      }
    },
    joiningformtextStepper: {
      padding: "0px",
      background: "transparent",
      width: 436,
      color: colors.primaryColor2,
    },
    joiningformbtnWrapper: {
      marginTop: "50px",
      display: "flex",
      justifyContent: "center",
      gap: "20px",
    },
    joiningformbtn: {
      boxShadow: "none",
      fontWeight: 700,
      textTransform: "capitalize",
      height: "50px",
      width: "150px",
      borderRadius: "10px",
      fontSize: "16px",
      lineHeight: "21px",
      color: colors.primaryColor2,
      margin: '0px',
    },
    joiningformprimaryBtn: {
      backgroundColor: colors.secondaryColor2,
      "&:hover": {
        backgroundColor: colors.secondaryColor2,

      }
    },
    joiningformsecondaryBtn: {
      border: "1px solid black",
      "&:hover": {
        backgroundColor: colors.primaryColor1,
      }
    },
    joiningformuploadBox: {
      paddingTop: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

    },
    joiningformrightSection: {
      paddingTop: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    joiningformrightSection2: {
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
      position: "relative",
    },
    joiningformuploadWrapper: {
      width: "100%",
      marginTop: "",
      "& > div:nth-of-type(2)": {
        marginTop: "43px"
      }
    },

    joiningformuploadValidation: {
      color: "#969492",
      fontSize: "12px",
    },
    joiningformcloseIcn: {
      zIndex: 1000,
      position: "absolute",
      top: 0,
      right: 0,
      padding: "2px 4px",
      "&:hover": {
        backgroundColor: "#beb8bc",
        borderRadius: 8,
      }
    },
    joiningformPhoneGroup: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "50px",
      marginTop: 6,
      // gap: "15px"
    },
    joiningforminputWidth: {
      width: "64% !important",
      marginTop: 11,
      position: "relative",
      "& p": {
        position: "absolute",
        bottom: "-20px"
      }
    },
    joiningformdropdownWidth: {
      width: "145px",
      height:'56px'
    },
    dropdownWidth: {
      width: "145px",
      backgroung:'red'
    },
    joiningformuploadImage: {
      border: "1px solid #969492",
      width: 176,
      bottom: "0",
      height: 150,
      right: "0",
      borderRadius: 8,
      position: "relative",
      cursor: "pointer",
      "&.error" : {
        borderColor: colors.errorText
      },
      "& .joingingforminnerContent": {
        width: "100%",
        top: "50%",
        textAlign: "center",
        left: "50%",
        placeItems: "center",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        "& .joingingformfilePreview": {
          objectFit: "contain",
          height: "auto",
          maxWidth: 130,
          maxHeight: 130,
          aspectRatio: "3/2",
          width: "auto",

        },
        "& .uploadImage": {
          marginBottom: 9,
          width: 56,

          paddingTop: 4,
          height: 55,
        },
        "& .joingingformuploadText": {
          lineHeight: "18px",
          color: colors.primaryColor2,
          margin: "8px 10px 0",
          fontWeight: "400",
          fontSize: "14px",



        },
      },
      "& .MuiFormControl-root": {
        height: "100%",
        zIndex: "100",
        width: "100%",

        "& .MuiInputBase-formControl": {
          borderRadius: "50%",
          height: "100%",
          border: "none",
          width: "100%",


          "& input": {

            height: "100%",
            opacity: "0",
            width: "100%",
            zIndex: "99",
            padding: "0",

            position: "relative",
            cursor: "pointer",
          },
          "& fieldset": {
            display: "none",
          },
        },
      },
    },
  };
