import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, Avatar, List, ListItem, InputLabel, FormHelperText, Button, TextField, Grid } from "@material-ui/core";
import { Wrapper } from "../../../components/src/Wrapper";
export const configJSON = require("./config");
import EmployeeProfileDetailsController, { Documents, Props, RenderFormProps } from "./EmployeeProfileDetailsController.web";
import Loader from "../../../components/src/Loader";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import { Formik, Form } from 'formik';
import { profileSchema, profileSchemaWork } from "../../../components/src/utils/validationSchema";
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import { commonInputField, commonButton, commonTemplateCss, libraryCommonCss } from "../../../components/src/utils/commonInputStyle";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { DateRange } from '@material-ui/icons';
import { GenderList } from "../../../components/src/utils/constant";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { allowNumberWithSpaceValidation, handleInvoiceErrors, handleInvoiceHelperText, isBO, isManager } from "../../../components/src/utils/commonFunctions";
import AttachFileInput from "../../../components/src/commonComponents/AttachFileInput.web";
import { uploadDocIcon, docImage } from "../../OrganisationHierarchy/src/assets";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";

// Customizable Area End

// Customizable Area Start
export const styles = {
    ...commonInputField,
    ...commonButton,
    ...commonTemplateCss,
    ...libraryCommonCss,
    listContainer: {
        alignItems: "flex-start"
    },
    profileImageContainer: {
        gap: "21px",
        display: "flex",
        alignItems: "center",
    },
    profileImageOuter: {
        backgroundColor: "white",
        height: "99px",
        overflow: "hidden" as const,
        borderRadius: "50%",
        width: "99px",
    },
    avatar: {
        width: "100%",
        height: "100%",
        "& img": {
            textAlign: "center",
            objectFit: "contain",
        }
    },
    position: {
        lineHeight: "18px",
        fontWeight: 400,
        fontSize: "16px",
        display: "flex",
        marginTop: 6,
        alignItems: "center",
        "& .strong": {
            fontWeight: 500,
            marginRight: 3,
        }
    },
    userDetailTitle: {
        fontWeight: 700,
        lineHeight: "1.5rem",
        fontSize: "1.125rem",
    },
    rightSection: {
        marginLeft: "200px"
    },
    boxContainer: {
        border: '1px solid grey',
        maxHeight: 'calc(100vh - 190px)',
        display: 'flex',
        overflowY: 'auto' as const,
        borderRadius: '10px',
        justifyContent: 'space-between',
        height: 'auto',
        "&.downloadDisplay": {
            maxHeight: 'unset',
            overflowY: 'unset' as const,
            height: 'unset',
        }
    },
    leftSection: {
        position: "fixed" as const,
        marginTop: "-18px",
        "& p": {
            fontSize: 18,
            fontWeight: 500
        }
    },
    listDropdown: {
        "& svg": {
            fontSize: 20
        },
        "& p": {
            fontSize: 18,
            fontWeight: 300
        }
    },
    mainConatiner: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '25px',
        maxHeight: '100%',
    },
    headerConatiner: {
        display: 'flex',
        justifyContent: "space-between"
    },
    leftContainer: {
        margin: "20px",
        overflow: "auto" as const,
        width: "100%",
        position: "relative" as const,
        marginBottom: 0
    },
    rightInnerSection: {
        border: "1px solid grey",
        borderRadius: 3,
        display: "flex",
        flexDirection: "column" as const,
        marginBottom: 20,
        "& .docTitle": {
            border: "none",
            fontWeight: 400,
            paddingBottom: 15
        }
    },
    titleText: {
        fontSize: 18,
        fontWeight: 500,
        padding: 10,
        borderBottom: "1px solid grey",
        display: "block",
        flexBasis: "50%"
    },
    viewSection: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
        alignItems: "center",
        padding: 10,
        paddingBottom: 0,
        marginBottom: 25,
        gap: 30,
        "@media screen and (min-width: 1200px)": {
            gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
        },
        "& p": {
            fontSize: 16
        },
        "& .error-text": {
            fontSize: 14
        },
        "&.gap": {
            paddingBottom: 10,
            margin: 0,
            "& p": {
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            },
        },
        "& .capitalize": {
            textTransform: "capitalize"
        }
    },
    createTicketBtnWrapper: {
        display: "flex",
        marginBottom: "-20px",
        justifyContent: "center",
    },
    marginValue: {
        margin: 0,
        maxWidth: 400
    },
    fileUploadInput: {
        marginTop: "-25px",
        position: "relative" as const,
        maxWidth: 400
    },
    maxSizeTicket: {
        fontSize: 12,
        display: "block",
        color: "#020202",
        opacity: 0.6,
        position: "absolute" as const,
        right: 0
    },
    gridContainer: {
        padding: "20px 10px",
        paddingTop: 0,
        position: "relative" as const,
        "& .boxWidth": {
            minWidth: "145px"
        },
        "&.documentHeight": {
            minHeight: 200,
            height: "auto"
        }
    },
    imageBox: {
        "& img": {
            height: "100px"
        }
    },
    uploadedDocs: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: 30,
    },
    imageUpload: {
        width: "250px",
        height: "160px",
        border: "1px solid grey",
        borderRadius: 10,
        padding: "10px",
        marginTop: 5,
        marginBottom: 30
    },
    imageSize: {
        display: 'block',
        opacity: 0.6,
        fontSize: '16px',
        marginTop: '12px',
    },
    gridGroup: {
        maxWidth: "50%",
        flexBasis: "50%",
        "@media screen and (min-width: 1200px)": {
            maxWidth: "21%",
            flexBasis: "21%",
        },
    },
    noFile: {
        top: "50%",
        position: "absolute" as const,
        textAlign: "center" as const,
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    docImage: {
        width: "70px",
        height: "70px",
        margin: "auto",
    },
    disabledBoxStyle: {
        opacity: 0.5,
        pointerEvents: 'none' as const,
    },
    displayNone: {
        display: "none"
    },
    textFont: {
        "& p,h4": {
            "@media screen and (min-width: 1200px)": {
                fontSize: 24
            },
        },
    },
    viewDownload: {
        display: "flex",
        alignItems: "unset",
        flexDirection: "column" as const,
        "& p": {
            "@media screen and (min-width: 1200px)": {
                fontSize: 24
            },
        },
    },
 

    };
// Customizable Area End

    export class EmployeeProfileDetails extends EmployeeProfileDetailsController {
        constructor(props: Props) {
            super(props);
            // Customizable Area Start
            // Customizable Area End
        }

// Customizable Area Start

render() {
    const { classes } = this.props;
    const { loading, show, data, openMenu, countryList, edit,
        statusList, reporting_employee, countryCodeList, branch_list, department_lists,
        roleList, my_documents, currentImage, documents, allow_edit, openDeleteModal, openConfirmationModal,
        document_name, is_download,new_reporting_employee,defaultData,showReplaceUserInOverview, showReplaceUserInWork
    } = this.state
    const renderOverview = (
        { errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            values }: RenderFormProps
    ) => {
        return (
            <Box id="overview" className={classes.rightInnerSection} >
                <Typography data-test-id="overview_text" className={classes.titleText}>{configJSON.overview}</Typography>
                {(edit && !this.loggedUserFunc() && allow_edit) ? <>
                    <Box className={classes.viewSection}>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`} >
                            <InputLabel required className={classes.inputLabel}>
                                {configJSON.joiningDate}
                            </InputLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    InputProps={{
                                        endAdornment: <DateRange />,
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                    margin="normal"
                                    variant="inline"
                                    className={`${classes.inputField} ${classes.dateField}`}
                                    onBlur={handleBlur}
                                    autoOk
                                    name="joining_date"
                                    value={values.joining_date}
                                    allowKeyboardControl={false}
                                    format="dd/MM/yyyy"
                                    disabled
                                    data-test-id="joining_date"
                                    placeholder={"dd/mm/yyyy"}
                                    onChange={setFieldValue.bind(null, "joining_date")}
                                />
                            </MuiPickersUtilsProvider>
                            {(touched.joining_date && errors.joining_date) && <FormHelperText className={`error-text ${classes.helperText}`}>
                                {errors.joining_date}
                            </FormHelperText>}
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                            <InputLabel className={classes.inputLabel} required>
                                {configJSON.reportingTo}
                            </InputLabel>
                            <DropdownSearch
                                onChangeValue={(event: OptionType) => this.handleReportingChange(values, event)}
                                value={values.reporting_to}
                                options={reporting_employee}
                                id="reporting_to"
                                onBlur={handleBlur}
                                placeholder={configJSON.reportingUser}
                                isSearchable={false}
                                isDisabled={values.status === "inactive"}
                                noOptionsMessage={configJSON.noDataMessage}
                                showError={!values.reporting_to}
                            />
                            {(touched.reporting_to && errors.reporting_to) && <FormHelperText className={`error-text ${classes.helperText}`}>
                                {errors.reporting_to}
                            </FormHelperText>}
                        </Box>

                    </Box>
                    <Box className={classes.viewSection}>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                            <InputLabel className={classes.inputLabel} required>
                                {configJSON.textStatus}
                            </InputLabel>
                            <DropdownSearch
                                options={statusList.slice(1, 3)}
                                onChangeValue={(event: OptionType) => this.handleStatusChange(values, event)}
                                value={values.status}
                                onBlur={handleBlur}
                                placeholder={configJSON.statusText}
                                noOptionsMessage={configJSON.noDataMessage}
                                id="status"
                                isSearchable={false}
                            />
                        </Box>
                        {(showReplaceUserInOverview && defaultData?.role_id !== 5) && <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                            <InputLabel className={classes.inputLabel}>
                                {configJSON.newReporting}
                            </InputLabel>
                            <DropdownSearch
                                options={new_reporting_employee}
                                onChangeValue={(event: OptionType) => this.handleSelectChange(values, event, setFieldValue, "new_reporting_to")}
                                value={values.new_reporting_to}
                                onBlur={handleBlur}
                                placeholder={configJSON.newReportingText}
                                noOptionsMessage={configJSON.noDataMessage}
                                id="new_reporting_to"
                                isSearchable={false}
                            />
                        </Box>}
                        
                    </Box>
                </> : <React.Fragment>
                    <Box className={`${classes.viewSection} gap ${is_download && classes.viewDownload}`}>
                        <Typography variant="body2">{configJSON.joiningDate} - {data?.joining_date}</Typography>
                        <Typography variant="body2">{configJSON.reportingTo} - {data?.parent_user_details?.owner_name}</Typography>
                    </Box>
                    <Box className={`${classes.viewSection} gap`}>
                        <Typography className="capitalize" variant="body2">{configJSON.textStatus} - {data?.status}</Typography>
                    </Box>

                </React.Fragment>}
            </Box>
        )
    }
    const renderBasicInfo = (
        { errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            values }: RenderFormProps
    ) => {
        return (
            <Box id="basic_info" className={classes.rightInnerSection} >
                <Typography className={classes.titleText}>{configJSON.basic_info}</Typography>
                {(edit && this.loggedUserFunc()) ? <>
                    <Box className={classes.viewSection}>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`} >
                            <InputLabel required className={classes.inputLabel}>
                                {configJSON.fullName}
                            </InputLabel>
                            <TextField
                                name={"owner_name"}
                                type="text"
                                variant="outlined"
                                placeholder={configJSON.fullName}
                                onChange={handleChange}
                                value={values.owner_name}
                                fullWidth
                                error={handleInvoiceErrors(
                                    errors.owner_name,
                                    touched.owner_name
                                )}
                                className={classes.inputField}
                                helperText={handleInvoiceHelperText(
                                    errors.owner_name,
                                    touched.owner_name
                                )}
                                onBlur={handleBlur}
                            />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                            <InputLabel className={classes.inputLabel} required>
                                {configJSON.email}
                            </InputLabel>
                            <TextField
                                placeholder={configJSON.emailPlaceholderText}
                                type="email"
                                variant="outlined"
                                name={"owner_email"}
                                onChange={handleChange}
                                value={values.owner_email}
                                fullWidth
                                error={handleInvoiceErrors(
                                    errors.owner_email,
                                    touched.owner_email
                                )}
                                className={classes.inputField}
                                helperText={handleInvoiceHelperText(
                                    errors.owner_email,
                                    touched.owner_email
                                )}
                                onBlur={handleBlur}
                            />
                        </Box>

                    </Box>
                    <Box className={classes.viewSection}>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                            <InputLabel required className={classes.inputLabel}>
                                {configJSON.phoneNumber}
                            </InputLabel>
                            <Box className={classes.phoneGroup}>
                                <Box className={classes.dropdownWidth}>
                                    <DropdownSearch
                                        value={values.country_code_and_flag_id}
                                        onChangeValue={(event: OptionType) => this.handleSelectChange(values, event, setFieldValue, "country_code_and_flag_id")}
                                        onBlur={handleBlur}
                                        placeholder={configJSON.countryCodePlaceholder}
                                        options={countryCodeList}
                                        id="country_code_and_flag_id"
                                        isSearchable={true}
                                        noOptionsMessage={configJSON.noCountryCodeMessage}
                                        showError={handleInvoiceErrors(
                                            errors.country_code_and_flag_id,
                                            touched.country_code_and_flag_id
                                        )}
                                    />
                                </Box>
                                <TextField
                                    type="text"
                                    placeholder={configJSON.phoneNumberPlaceHolder}
                                    name="phone_number"
                                    value={values.phone_number}
                                    variant="outlined"
                                    className={`${classes.inputField} ${classes.inputWidth}`}
                                    onBlur={handleBlur}
                                    fullWidth
                                    onChange={handleChange}
                                    onKeyPress={
                                        allowNumberWithSpaceValidation
                                    }
                                    error={handleInvoiceErrors(
                                        errors.phone_number,
                                        touched.phone_number
                                    )}
                                />
                            </Box>
                            {(handleInvoiceErrors(errors.phone_number, touched.phone_number) ||
                                handleInvoiceErrors(errors.country_code_and_flag_id, touched.country_code_and_flag_id)) && (
                                    <FormHelperText
                                        data-test-id={"errorMessage"}
                                        className={classes.helperText}
                                    >
                                        {handleInvoiceHelperText(errors.country_code_and_flag_id, touched.country_code_and_flag_id)}
                                        {handleInvoiceHelperText(errors.phone_number, touched.phone_number)}
                                    </FormHelperText>
                                )}
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                            <InputLabel className={classes.inputLabel}>
                                {configJSON.address}
                            </InputLabel>
                            <TextField
                                placeholder={configJSON.locationPlaceholder}
                                name={"address"}
                                type="text"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                                fullWidth
                                className={classes.inputField}
                            />
                        </Box>
                    </Box>
                </> : <React.Fragment>
                    <Box className={`${classes.viewSection} gap ${is_download && classes.viewDownload}`}>
                        <Typography variant="body2">{configJSON.fullName} - {data?.owner_name}</Typography>
                        <Typography variant="body2">{configJSON.email} - {data?.owner_email}</Typography>
                    </Box>
                    <Box className={`${classes.viewSection} gap ${is_download && classes.viewDownload}`}>
                        <Typography variant="body2">{configJSON.phoneNumber} - {data?.country_code} {data?.phone_number}</Typography>
                        <Typography variant="body2">{configJSON.address} - {data?.address}</Typography>
                    </Box>

                </React.Fragment>}
            </Box>
        )
    }
    const renderPersonalInfo = (
        { errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            values }: RenderFormProps
    ) => {
        return (
            <Box id="personal-details" className={classes.rightInnerSection} >
                <Typography className={classes.titleText}>{configJSON.personal}</Typography>
                {(edit && this.loggedUserFunc()) ? <>
                    <Box className={classes.viewSection}>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`} >
                            <InputLabel required className={classes.inputLabel}>
                                {configJSON.dob}
                            </InputLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    InputProps={{
                                        endAdornment: <DateRange />,
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                    variant="inline"
                                    className={`${classes.inputField} ${classes.dateField}`}
                                    margin="normal"
                                    onBlur={handleBlur}
                                    autoOk
                                    name="date_of_birth"
                                    value={values.date_of_birth}
                                    allowKeyboardControl={false}
                                    data-test-id="date_of_birth"
                                    placeholder={"dd/mm/yyyy"}
                                    onChange={setFieldValue.bind(null, "date_of_birth")}
                                    format="dd/MM/yyyy"
                                />
                            </MuiPickersUtilsProvider>
                            {(touched.date_of_birth && errors.date_of_birth) && <FormHelperText className={`error-text ${classes.helperText}`}>
                                {errors.date_of_birth}
                            </FormHelperText>}
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                            <InputLabel className={classes.inputLabel} required>
                                {configJSON.country}
                            </InputLabel>
                            <DropdownSearch
                                options={countryList}
                                value={values.country_id}
                                onChangeValue={(event: OptionType) => this.handleSelectChange(values, event, setFieldValue, "country_id")}
                                onBlur={handleBlur}
                                id="country_id"
                                placeholder={configJSON.countryPlaceHolder}
                                noOptionsMessage={configJSON.noCountryMessage}
                                isSearchable={true}
                            />
                        </Box>

                    </Box>
                    <Box className={classes.viewSection}>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                            <InputLabel className={classes.inputLabel} required>
                                {configJSON.genderText}
                            </InputLabel>
                            <DropdownSearch
                                onChangeValue={(event: OptionType) => this.handleSelectChange(values, event, setFieldValue, "gender")}
                                value={values.gender}
                                options={GenderList}
                                onBlur={handleBlur}
                                isSearchable={false}
                                placeholder={configJSON.genderPlaceholderText}
                                id="gender"
                                noOptionsMessage={configJSON.noDataMessage}
                                showError={!values.gender}
                                errorText={configJSON.genderErrorText}
                            />
                        </Box>
                        <Box className={classes.fileUploadInput}>
                            <AttachFileInput
                                attachmentTitle={configJSON.national_id}
                                attachmentId="national_id"
                                placeholderText={configJSON.attachmentPlaceholder}
                                accept={configJSON.acceptImageType}
                                placeholderIcon={uploadDocIcon}
                                attachment={values.nationalidUrl}
                                setFieldValue={setFieldValue}
                                handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDocUpload(event, "national_id", "nationalidUrl", setFieldValue)}
                                clearAttachment={() => this.handleClearDoc("national_id", "nationalidUrl", setFieldValue)}
                                attachmentError={errors.national_id}
                            />
                            {(!values.nationalidUrl || !values.nationalidUrl.name) && !errors.national_id && <Typography className={classes.maxSizeTicket} component="span">{configJSON.imageSize}</Typography>}
                        </Box>
                    </Box>
                    <Box className={classes.viewSection}>
                        <Box className={classes.fileUploadInput}>
                            <AttachFileInput
                                attachmentTitle={configJSON.drivingLicense}
                                attachmentId="driving_license"
                                placeholderText={configJSON.attachmentPlaceholder}
                                accept={configJSON.acceptImageType}
                                placeholderIcon={uploadDocIcon}
                                attachment={values.drivingLicenseUrl}
                                setFieldValue={setFieldValue}
                                handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDocUpload(event, "driving_license", "drivingLicenseUrl", setFieldValue)}
                                clearAttachment={() => this.handleClearDoc("driving_license", "drivingLicenseUrl", setFieldValue)}
                                attachmentError={errors.driving_license}
                            />
                            {(!values.drivingLicenseUrl || !values.drivingLicenseUrl.name) && !errors.driving_license && <Typography className={classes.maxSizeTicket} component="span">{configJSON.imageSize}</Typography>}
                        </Box>
                        <Box className={classes.fileUploadInput}>
                            <AttachFileInput
                                attachmentTitle={configJSON.passport}
                                attachmentId="passport"
                                placeholderText={configJSON.attachmentPlaceholder}
                                accept={configJSON.acceptImageType}
                                placeholderIcon={uploadDocIcon}
                                attachment={values.passportUrl}
                                setFieldValue={setFieldValue}
                                handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDocUpload(event, "passport", "passportUrl", setFieldValue)}
                                clearAttachment={() => this.handleClearDoc("passport", "passportUrl", setFieldValue)}
                                attachmentError={errors.passport}
                            />
                            {(!values.passportUrl || !values.passportUrl.name) && !errors.passport && <Typography className={classes.maxSizeTicket} component="span">{configJSON.imageSize}</Typography>}
                        </Box>
                    </Box>
                    <Box className={classes.viewSection}>
                        <Box className={classes.fileUploadInput}>
                            <AttachFileInput
                                attachmentTitle={configJSON.schoolDegree}
                                attachmentId="school_degree"
                                placeholderText={configJSON.attachmentPlaceholder}
                                accept={configJSON.acceptImageType}
                                placeholderIcon={uploadDocIcon}
                                attachment={values.schoolDegreeUrl}
                                setFieldValue={setFieldValue}
                                handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>
                                ) => this.handleDocUpload(event, "school_degree", "schoolDegreeUrl", setFieldValue)}
                                clearAttachment={() => this.handleClearDoc("school_degree", "schoolDegreeUrl", setFieldValue)}
                                attachmentError={errors.school_degree}
                            />
                            {(!values.schoolDegreeUrl || !values.schoolDegreeUrl.name) && !errors.school_degree && <Typography className={classes.maxSizeTicket} component="span">{configJSON.imageSize}</Typography>}
                        </Box>
                        <Box className={classes.fileUploadInput}>
                            <AttachFileInput
                                attachmentTitle={configJSON.universityDegree}
                                attachmentId="university_degree"
                                placeholderText={configJSON.attachmentPlaceholder}
                                accept={configJSON.acceptImageType}
                                placeholderIcon={uploadDocIcon}
                                attachment={values.universityDegreeUrl}
                                setFieldValue={setFieldValue}
                                handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDocUpload(event, "university_degree", "universityDegreeUrl", setFieldValue)}
                                clearAttachment={() => this.handleClearDoc("university_degree", "universityDegreeUrl", setFieldValue)}
                                attachmentError={errors.university_degree}
                            />
                            {(!values.universityDegreeUrl || !values.universityDegreeUrl.name) && !errors.university_degree && <Typography className={classes.maxSizeTicket} component="span">{configJSON.imageSize}</Typography>}
                        </Box>
                    </Box>
                    <Box className={classes.viewSection}>
                        <Box className={classes.fileUploadInput}>
                            <AttachFileInput
                                attachmentTitle={configJSON.vaccineCert}
                                attachmentId="vaccination_certificate"
                                placeholderText={configJSON.attachmentPlaceholder}
                                accept={configJSON.acceptImageType}
                                placeholderIcon={uploadDocIcon}
                                attachment={values.vaccinationCertificateUrl}
                                setFieldValue={setFieldValue}
                                handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDocUpload(event, "vaccination_certificate", "vaccinationCertificateUrl", setFieldValue)}
                                clearAttachment={() => this.handleClearDoc("vaccination_certificate", "vaccinationCertificateUrl", setFieldValue)}
                                attachmentError={errors.vaccination_certificate}
                            />
                            {(!values.vaccinationCertificateUrl || !values.vaccinationCertificateUrl.name) && !errors.vaccination_certificate && <Typography className={classes.maxSizeTicket} component="span">{configJSON.imageSize}</Typography>}
                        </Box>
                        <Box className={classes.fileUploadInput}>
                            <AttachFileInput
                                attachmentTitle={configJSON.cert}
                                attachmentId="certificate"
                                placeholderText={configJSON.attachmentPlaceholder}
                                accept={configJSON.acceptImageType}
                                placeholderIcon={uploadDocIcon}
                                attachment={values.certificateUrl}
                                setFieldValue={setFieldValue}
                                handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDocUpload(event, "certificate", "certificateUrl", setFieldValue)}
                                clearAttachment={() => this.handleClearDoc("certificate", "certificateUrl", setFieldValue)}
                                attachmentError={errors.certificate}
                            />
                            {(!values.certificateUrl || !values.certificateUrl.name) && !errors.certificate && <Typography className={classes.maxSizeTicket} component="span">{configJSON.imageSize}</Typography>}
                        </Box>
                    </Box>
                </> : <React.Fragment>
                    <Box className={`${classes.viewSection} gap ${is_download && classes.viewDownload}`}>
                        <Typography variant="body2">{configJSON.dob} - {data?.date_of_birth}</Typography>
                        <Typography variant="body2">{configJSON.country} - {data?.country_info?.name}</Typography>
                    </Box>
                    <Box className={`${classes.viewSection} gap ${is_download && classes.viewDownload}`}>
                        <Typography className="capitalize" variant="body2">{configJSON.genderText} - {data?.gender}</Typography>
                        <Typography variant="body2">{configJSON.national_id} - {data?.nationalidUrl?.name}</Typography>
                    </Box>
                    <Box className={`${classes.viewSection} gap ${is_download && classes.viewDownload}`}>
                        <Typography variant="body2">{configJSON.drivingLicense} - {data?.drivingLicenseUrl?.name}</Typography>
                        <Typography variant="body2">{configJSON.passport} - {data?.passportUrl?.name}</Typography>
                    </Box>
                    <Box className={`${classes.viewSection} gap ${is_download && classes.viewDownload}`}>
                        <Typography variant="body2">{configJSON.schoolDegree} - {data?.schoolDegreeUrl?.name}</Typography>
                        <Typography variant="body2">{configJSON.universityDegree} - {data?.universityDegreeUrl?.name}</Typography>
                    </Box>
                    <Box className={`${classes.viewSection} gap ${is_download && classes.viewDownload}`}>
                        <Typography variant="body2">{configJSON.vaccineCert} - {data?.vaccinationCertificateUrl?.name}</Typography>
                        <Typography variant="body2">{configJSON.cert} - {data?.certificateUrl?.name}</Typography>
                    </Box>

                </React.Fragment>}
            </Box>
        )
    }
    const renderWork = (
        { errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            values }: RenderFormProps
    ) => {
        return (
            <Box id="work" className={classes.rightInnerSection} >
                <Typography className={classes.titleText}>{configJSON.work}</Typography>
                {(edit && !this.loggedUserFunc() && allow_edit) ? <>
                    <Box className={classes.viewSection}>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                            <InputLabel className={classes.inputLabel} required>
                                {configJSON.email}
                            </InputLabel>
                            <TextField
                                placeholder={configJSON.emailPlaceholderText}
                                type="email"
                                variant="outlined"
                                name={"working_email"}
                                onChange={handleChange}
                                disabled
                                value={values.working_email}
                                fullWidth
                                error={handleInvoiceErrors(
                                    errors.working_email,
                                    touched.working_email
                                )}
                                className={classes.inputField}
                                helperText={handleInvoiceHelperText(
                                    errors.working_email,
                                    touched.working_email
                                )}
                                onBlur={handleBlur}
                            />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`} >
                            <InputLabel required className={classes.inputLabel}>
                                {configJSON.joiningDate}
                            </InputLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    InputProps={{
                                        endAdornment: <DateRange />,
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                    variant="inline"
                                    className={`${classes.inputField} ${classes.dateField}`}
                                    margin="normal"
                                    onBlur={handleBlur}
                                    autoOk
                                    disabled
                                    name="joining_date"
                                    value={values.joining_date}
                                    allowKeyboardControl={false}
                                    format="dd/MM/yyyy"
                                    data-test-id="joining_date"
                                    placeholder={"dd/mm/yyyy"}
                                    onChange={setFieldValue.bind(null, "joining_date")}
                                />
                            </MuiPickersUtilsProvider>
                            {(touched.joining_date && errors.joining_date) && <FormHelperText className={`error-text ${classes.helperText}`}>
                                {errors.joining_date}
                            </FormHelperText>}
                        </Box>
                    </Box>
                    <Box className={classes.viewSection}>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                            <InputLabel className={classes.inputLabel}>
                                {configJSON.tenure}
                            </InputLabel>
                            <TextField
                                placeholder={configJSON.tenurePlaceholder}
                                name={"tenure"}
                                type="string"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.tenure}
                                fullWidth
                                className={classes.inputField}
                                disabled={true}
                            />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                            <InputLabel className={classes.inputLabel} required>
                                {configJSON.branch}
                            </InputLabel>
                            <DropdownSearch
                                options={branch_list}
                                value={String(values.branch_id)}
                                onChangeValue={(event: OptionType) => this.handleSelectChange(values, event, setFieldValue, "branch_id")}
                                id="branch_id"
                                isSearchable={true}
                                placeholder={configJSON.branchPlaceholder}
                                noOptionsMessage={configJSON.noBranchMessage}
                                isDisabled={values.status === "inactive"}
                            />
                        </Box>

                    </Box>
                    <Box className={classes.viewSection}>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                            <InputLabel className={classes.inputLabel} required>
                                {configJSON.departmentText}
                            </InputLabel>
                            <DropdownSearch
                                options={department_lists}
                                value={values.assigned_department_ids}
                                onChangeValue={(event: OptionType) => this.handleSelectChange(values, event, setFieldValue, "assigned_department_ids")}
                                id="assigned_department_ids"
                                isSearchable={true}
                                isDisabled={values.status === "inactive"}
                                placeholder={configJSON.departSelect}
                                isMulti={data?.role_id === 3 ? true : false}
                                showError={data?.role_id !== 3 || (values.assigned_department_ids && values.assigned_department_ids.length > 0) ? false : true}
                                errorText={values.assigned_department_ids && values.assigned_department_ids.length <= 0 ? configJSON.departMessage : ""}
                            />
                        </Box>
                        <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                            <InputLabel className={classes.inputLabel} required>
                                {configJSON.position}
                            </InputLabel>
                            <DropdownSearch
                                options={roleList}
                                onChangeValue={(event: OptionType) => this.handleSelectChange(values, event, setFieldValue, "role_id")}
                                value={values.role_id}
                                onBlur={handleBlur}
                                isDisabled={values.status === "inactive"}
                                placeholder={configJSON.selectRoleText}
                                id="role_id"
                                isSearchable={false}
                                noOptionsMessage={configJSON.noDataMessage}
                            />
                        </Box>
                    </Box>

                    <Box className={classes.viewSection}>
                    {showReplaceUserInWork && 
                      <Box className={`${classes.fieldWrapper} ${classes.marginValue}`}>
                        <InputLabel className={classes.inputLabel}>
                            {configJSON.newReporting}
                        </InputLabel>
                        <DropdownSearch
                            id="new_reporting_to"
                            onChangeValue={(event: OptionType) => this.handleSelectChange(values, event, setFieldValue, "new_reporting_to")}
                            value={values.new_reporting_to}
                            options={new_reporting_employee}
                            placeholder={configJSON.newReportingText}
                            noOptionsMessage={configJSON.noDataMessage}
                            isSearchable={false}
                            onBlur={handleBlur}
                        />
                      </Box>}
                    </Box>

                </> : <React.Fragment>
                    <Box className={`${classes.viewSection} gap ${is_download && classes.viewDownload}`}>
                        <Typography variant="body2">{configJSON.email} - {data?.working_email}</Typography>
                        <Typography variant="body2">{configJSON.joiningDate} - {data?.joining_date}</Typography>
                    </Box>
                    <Box className={`${classes.viewSection} gap ${is_download && classes.viewDownload}`}>
                        <Typography className="capitalize" variant="body2">{configJSON.tenure} - {data?.tenure}</Typography>
                        <Typography variant="body2">{configJSON.branch} - {data?.branch_name}</Typography>
                    </Box>
                    <Box className={`${classes.viewSection} gap ${is_download && classes.viewDownload}`}>
                        <Typography className="capitalize" variant="body2">{configJSON.departmentText} - {data?.department_names !== null && data?.department_names?.join(",")}</Typography>
                        <Typography variant="body2">{configJSON.position} - {data?.role_name}</Typography>
                    </Box>

                </React.Fragment>}
            </Box>
        )
    }
    const ProcedureGridView = (documents: (Documents | null | undefined)[], cardItem?: boolean) => {
        return (<Grid container spacing={2} className={`${classes.gridContainer} ${cardItem ? "" : "documentHeight"}`}>
            {(Boolean(documents?.length)) ?
                documents.map((item, index) => (
                    <Grid className={cardItem ? classes.gridGroup : ""} key={item?.id} item lg={2} md={2} sm={6} xs={12}>
                        <Box className={classes.cardWrapper}>
                            <Box className={`${classes.actionButton} ${!edit && classes.disabledBoxStyle}`}>
                                <ActionButton
                                    download={!cardItem}
                                    isOpen={item && item.id !== undefined ? this.state.isOpen[item?.id] : false}
                                    toggleDropdown={() => this.toggleDropdown(item?.id)}
                                    view
                                    deleteVisible={cardItem}
                                    handleDelete={() => this.handleOpenDeleteModal(item)}
                                    handleDownload={() => this.handleDownload(item?.url, item?.name)}
                                    handleView={() => this.handleViewImage(item?.url)}
                                />
                            </Box>
                            <Box className="cursor">
                                <Box className={`${classes.imgBoxContainer} ${classes.imageBox}`}>
                                    <img className={classes.docImage} src={docImage} alt={item?.name} />
                                </Box>
                                <Box className={classes.textContainer}>
                                    <Typography noWrap data-test-id="title" className={classes.procedureTitleTxt}>{item?.name}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                )) :
                <Box className={classes.noFile}>
                    <Typography component="p" className={`${classes.titleText} docTitle`}>{cardItem ? "" : configJSON.noDocs}</Typography>
                </Box>
            }
        </Grid>)
    }
    return (
        <>
            <Loader loading={loading} />
            <Wrapper
                data-test-id="wrapper-comp"
                navigation={this.props.navigation}
                divider
                backbtn
                title={configJSON.employeeDetailTitle}
                bellIcn
                homeIcn
                child={
                    <>
                        <Formik
                            initialValues={this.setFormikInitialData()}
                            validationSchema={(edit && !this.loggedUserFunc() && allow_edit) ? profileSchemaWork : profileSchema}
                            onSubmit={this.handleOpenConfirmationModal}
                            enableReinitialize={true}
                        >
                            {({ errors, touched, handleBlur, handleChange, setFieldValue, values }) => (
                                <Form translate={undefined}>
                                    <div ref={this.pdfContentRef} className={classes.mainConatiner} >
                                        <Box className={classes.headerConatiner}>
                                            <Box className={`${classes.profileImageContainer} ${is_download && classes.textFont }`}>
                                                <Box className={classes.profileImageOuter}>
                                                    <Avatar alt="Profile Picture" src={data?.photo} className={classes.avatar} />
                                                </Box>
                                                <Box>
                                                    <Typography data-test-id="owner_name" variant="h4" className={classes.userDetailTitle}>
                                                        {data?.owner_name}
                                                    </Typography>
                                                    <Typography variant="body1" className={classes.position}>
                                                        <Typography className="strong">{configJSON.position} - </Typography> {data?.role_name}
                                                    </Typography>
                                                    {(this.checkShowDepartments()) && <Typography variant="body1" className={classes.position}>
                                                        <Typography className="strong">{configJSON.departmentText} - </Typography> {data?.department_names !== null && data?.department_names?.join(",")}
                                                    </Typography>}
                                                </Box>

                                            </Box>
                                            <Box className={`${(edit || is_download) && classes.displayNone}`}>
                                                <ActionButton
                                                    edit={(this.loggedUserFunc() && allow_edit) || isBO() || (isManager() && allow_edit)}
                                                    isOpen={openMenu}
                                                    download
                                                    handleDownload={this.handleDownloadPdf}
                                                    toggleDropdown={this.toggleMenu}
                                                    moreVert
                                                    handleEdit={this.handleEdit}
                                                />
                                            </Box>
                                        </Box>

                                        <Box className={`${is_download ? "downloadDisplay" : classes.boxContainer}`}>
                                            <Box className={classes.leftContainer}>
                                                <Box className={`${is_download ? classes.displayNone : classes.leftSection}`}>
                                                    <List component="nav">
                                                        <ListItem button data-test-id="overview" onClick={() => this.scrollToSection('overview')}>
                                                            <Typography variant="body1">{configJSON.overview}</Typography>
                                                        </ListItem>
                                                        <ListItem button data-test-id="basic_info" onClick={() => this.scrollToSection('basic_info')}>
                                                            <Typography variant="body1">{configJSON.basic_info}</Typography>
                                                        </ListItem>
                                                        <ListItem button data-test-id="personal" onClick={() => this.scrollToSection('personal-details')}>
                                                            <Typography variant="body1">{configJSON.personal}</Typography>
                                                        </ListItem>
                                                        <ListItem button data-test-id="work" onClick={() => this.scrollToSection('work')}>
                                                            <Typography variant="body1">{configJSON.work}</Typography>
                                                        </ListItem>
                                                        <ListItem button data-test-id="docs" onClick={() => this.handleDropdownChange('doc')}>
                                                            <Typography variant="body1">{configJSON.docsText}</Typography> <KeyboardArrowDownIcon />
                                                        </ListItem>
                                                        {show && (
                                                            <Box className={classes.listDropdown}>
                                                                <ListItem button onClick={() => this.scrollToSection('my_docs')}>
                                                                    <PlayArrowIcon /> <Typography variant="body2">{configJSON.my_docs}</Typography>
                                                                </ListItem>
                                                                <ListItem button onClick={() => this.scrollToSection('shared_docs')}>
                                                                    <PlayArrowIcon /> <Typography variant="body2">{configJSON.shared_docs}</Typography>
                                                                </ListItem>
                                                            </Box>
                                                        )}
                                                    </List>
                                                </Box>

                                                <Box className={`${is_download ? classes.textFont : classes.rightSection}`}>
                                                    {renderOverview({ errors, touched, handleBlur, handleChange, setFieldValue, values })}
                                                    {renderBasicInfo({ errors, touched, handleBlur, handleChange, setFieldValue, values })}
                                                    {renderPersonalInfo({ errors, touched, handleBlur, handleChange, setFieldValue, values })}
                                                    {renderWork({ errors, touched, handleBlur, handleChange, setFieldValue, values })}
                                                    <Box id="doc" className={`${is_download ? classes.displayNone : ""}`} >
                                                        <Box id="my_docs" data-test-id="my_docs" className={classes.rightInnerSection}>
                                                            <Typography className={classes.titleText}>{configJSON.docsText}</Typography>
                                                            <Typography className={`${classes.titleText} docTitle`}>{configJSON.myDocsText}</Typography>
                                                            {ProcedureGridView(my_documents)}
                                                        </Box>
                                                        <Box id="shared_docs" className={classes.rightInnerSection}>
                                                            <Typography className={classes.titleText}>{configJSON.docsText}</Typography>
                                                            <Typography className={`${classes.titleText} docTitle`}>{configJSON.uploadedText}</Typography>
                                                            <Box data-test-id="uploaded_docs" className={classes.uploadedDocs}>
                                                                {ProcedureGridView(documents, true)}
                                                                <Box className={classes.imageUpload}>
                                                                    <ImageUploadView
                                                                        setFieldValue={setFieldValue}
                                                                        name={"photo"}
                                                                        uploadTexts={["Upload File"]}
                                                                        handleImageChange={this.handleImageChange}
                                                                        currentImage={currentImage}
                                                                        isDisabled={(edit && values.status === "active") ? false : true}
                                                                    />
                                                                    <Typography className={classes.imageSize} component="span">{configJSON.imageSize}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {edit && <Box className={classes.createTicketBtnWrapper}>
                                            <Button
                                                className={classes.secondaryBtn}
                                                variant="outlined"
                                                onClick={this.handleCancel}
                                                data-test-id="cancel_button"
                                            >
                                                {configJSON.cancel}
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                data-test-id="save"
                                                className={classes.primaryBtn}
                                            >
                                                {configJSON.save}
                                            </Button>
                                        </Box>}

                                    </div>
                                </Form>
                            )}
                        </Formik>
                        {openDeleteModal &&
                            <ConfirmationModal
                                open={openDeleteModal}
                                onClose={this.handleCloseDeleteModal}
                                confirmDelete={this.handleDeleteClick}
                                title={String(document_name)}
                                from={configJSON.documentList}
                            />}
                        <ConfirmationModal
                          open={openConfirmationModal}
                          onClose={this.handleCloseConfirmationModal}
                          confirmDelete={this.handleConfirmSaveClick}
                          startingText={configJSON.confirmationStartingText}
                          title={`to ${this.state.data?.parent_user_details?.owner_name}`}
                          from={configJSON.confirmationFromText}
                        />
                    </>
                } />
        </>
    );
}
    // Customizable Area End
}
export default withStyles(styles)(EmployeeProfileDetails);
