import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { apiCall, getNavigationMessage, handleApiError, handleExpiredToken } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  // Customizable Area Start
  classes: Record<string,string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  open: boolean;
  loading: boolean;
  ticketTypes: TicketTypes[];
  searchTicketTypes: TicketTypes[] | null;
  searchQuery: string;
  data: DataType;
  index: number;
  isEdit: boolean;
  isDuplicate: boolean;
  openDeleteModal: boolean;
  ticket_type_id: string | number;
  ticket_name: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start

  // Customizable Area End
}

// Customizable Area Start
interface TicketTypes {
  issue_name: string;
  ticket_type_id: number;
  departments: Departments[]
}

interface Departments {
  department_id: number;
  department_name: string
}
interface DataType {
  issue_name: string;
  ticket_type_id:number;
  department_id: {id: number; name: string}[];
}
// Customizable Area End

export default class TicketTypeListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTicketTypesApiId: string = ""
  searchDepartmentApiId: string = ""
  deleteTicketTypeApiId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      open: false,
      loading: false,
      ticketTypes: [],
      searchQuery: "",
      searchTicketTypes: null,
      data: { issue_name: "",ticket_type_id:0, department_id: [] },
      index: 0,
      isEdit: false,
      isDuplicate: false,
      openDeleteModal: false,
      ticket_type_id:"",
      ticket_name: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resJson, this.props.navigation);
      this.setState({ loading: false });

      if (apiCallId && resJson) {
        if (apiCallId === this.getTicketTypesApiId) {
          this.getTicketTypesApiResponse(resJson);
        } else if (apiCallId === this.searchDepartmentApiId) {
          this.getSearchDeptResponse(resJson);
        } else if (apiCallId === this.deleteTicketTypeApiId) {
          this.handleDeleteResponse(resJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getTicketTypes()
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.searchQuery !== prevState.searchQuery) {
      this.setState({ loading: true });
      if (!this.state.searchQuery) {
        this.getTicketTypes()
        this.setState({ searchTicketTypes: null })
      } else {
        this.searchDepartmentApiId = apiCall({ method: configJSON.getApiMethodType, endPoint: `${configJSON.searchTicketTypeEndPoint}issue_name=${this.state.searchQuery}`, token: true })
      }
    }
    if (this.state.open !== prevState.open && !this.state.open) {
      this.getTicketTypes()
    }
  }

  getTicketTypes = () => {
    this.setState({ loading: true });
    this.getTicketTypesApiId = apiCall({ method: configJSON.getApiMethodType, endPoint: `${configJSON.ticketTypeApiEndPoint}`, token: true })
  }

  handleModalVisible = () => {
    this.setState({ open: true });
  };

  handleModalClose = () => {
    this.setState({ open: false, isEdit: false, isDuplicate: false });
  };
  handleCloseDeleteModal = () => {
    this.setState({ openDeleteModal: false })
}
  getTicketTypesApiResponse = (response: {data: TicketTypes[]; errors: string[]}) => {
    if(response.data) {
      this.setState({ ticketTypes: response.data })
    } else {
      this.setState({ ticketTypes: [] })
      handleApiError(response.errors);
    }
  }

  handleSearch = (query: string) => {
    this.setState({ searchQuery: query })
  }

  handleEditClick = (ticketType: TicketTypes, isDuplicate: boolean = false) => {
    const { issue_name, departments, ticket_type_id } = ticketType
    const ticketData = {
      issue_name, ticket_type_id, department_id: departments.map((department) => {
        return {
          id: department.department_id,
          name: department.department_name,
        }
      })
    }
    this.setState({ open: true, data: ticketData, isEdit: true, isDuplicate: isDuplicate })
  }

  getSearchDeptResponse = (response: {data: TicketTypes[]}) => {
    this.setState({ searchTicketTypes: (response.data && response.data.length > 0) ? response.data : null });
  }
  handleOpenDeleteModal = (item: TicketTypes) => {
    const { ticket_type_id, issue_name } = item
    this.setState({ openDeleteModal: true, ticket_name: issue_name, ticket_type_id: ticket_type_id })
}
  handleDeleteClick = () => {
    const { ticket_type_id } = this.state
    this.setState({ loading: true })
    this.deleteTicketTypeApiId = apiCall({ method: configJSON.deleteApiMethodType, endPoint: `${configJSON.ticketTypeApiEndPoint}/${ticket_type_id}`, token: true })
    this.handleCloseDeleteModal()
  }

  handleDeleteResponse = (response: {message: string[];errors: string[]}) => {
    if (response.message && response.message.length) {
      this.getTicketTypes()
      toast.success(response.message[0])
    } else if (response.errors) {
      handleApiError(response.errors)
    }
  }

  goToCreateTicket = () => {
    this.send(getNavigationMessage('CreateTicket', this.props));
  }
  // Customizable Area End
}
