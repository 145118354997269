// Customizable Area Start
import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, Switch, Button } from "@material-ui/core";
import SettingController, { Props, configJSON } from "./SettingController.web";
import { Wrapper } from "../../../components/src/Wrapper";
import { commonButton } from "../../../components/src/utils/commonInputStyle";

import { colors } from "../../../components/src/utils/commonDesign";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { isBO } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";


// Customizable Area End


// Customizable Area Start

export const styles = () => createStyles({
    ...commonButton,
    checkboxContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "3rem",
        "& .themeBtn": {
            background: "transparent",
            border: "1px solid #020202",
            width: 300
        },
        "& h5": {
            fontSize: "22px",
            fontWeight: 600
        },
        "& p": {
            fontSize: "18px",
            fontWeight: 300
        }
    },
    approvalContainer: {
        display: "flex",
        alignItems: "center",
        "& .MuiSwitch-root": {
            "& .MuiSwitch-track": {
                backgroundColor: colors.secondaryColor1
            },
            "& .MuiSwitch-switchBase.Mui-disabled": {
                color: colors.secondaryColor1
            },
        }
    },
    btnContainer: {
        display: "flex",
        justifyContent: "center",
    },
    dropdownContainer: {
        width: 300
    }

})
// Customizable Area End

export class Setting extends SettingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }


    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { checkedNotification, checkedEmailNotification, time_zone, timezone_data, loading } = this.state

        return (
            <>
                <Loader loading={loading} />
                <Wrapper
                    data-test-id="wrapper-comp"
                    divider
                    bellIcn={true}
                    backbtn={true}
                    navigation={this.props.navigation}
                    title={configJSON.settingTitle}
                    homeIcn={true}
                    child={
                        <>
                            <Box className={classes.checkboxContainer}>
                                <Box>
                                    <Typography variant="h5">
                                        {configJSON.updateInformation}
                                    </Typography>
                                    <Typography component={"p"}>
                                        {configJSON.profileCustomizeText}
                                    </Typography>
                                </Box>

                                <Button
                                    className={`${classes.primaryBtn} themeBtn`}
                                    data-test-id="profile-btn"
                                    variant="contained"
                                    onClick={() => this.handleNavigateClick("EditMyProfile")}
                                >
                                    {configJSON.editBioText}
                                </Button>
                            </Box>
                            <Box className={`${classes.checkboxContainer} ${classes.approvalContainer}`}>
                                <Box>
                                    <Typography variant="h5">
                                        {configJSON.notificationText}
                                    </Typography>
                                    <Typography component={"p"}>
                                        {configJSON.paraText}
                                    </Typography>
                                </Box>
                                <Switch
                                    name="checkedNotification"
                                    data-test-id="notification"
                                    color="primary"
                                    checked={checkedNotification}
                                    onChange={(e) => this.handleChange(e, "checkedNotification")}

                                />
                            </Box>
                            <Box className={`${classes.checkboxContainer} ${classes.approvalContainer}`}>
                                <Box>
                                    <Typography variant="h5">
                                        {configJSON.emailText}
                                    </Typography>
                                    <Typography component={"p"}>
                                        {configJSON.emailParaText}
                                    </Typography>
                                </Box>
                                <Switch
                                    name="checkedEmailNotification"
                                    color="primary"
                                    data-test-id="email_notification"
                                    checked={checkedEmailNotification}
                                    onChange={(e) => this.handleChange(e, "checkedEmailNotification")}
                                />
                            </Box>
                            {isBO() && <Box className={classes.checkboxContainer}>
                                <Box>
                                    <Typography variant="h5">
                                        {configJSON.changeThemeText}
                                    </Typography>
                                    <Typography component={"p"}>
                                        {configJSON.customizeText}
                                    </Typography>
                                </Box>

                                <Button
                                    className={`${classes.primaryBtn} themeBtn`}
                                    data-test-id="customize-btn"
                                    variant="contained"
                                    onClick={() => this.handleNavigateClick('Customize')}
                                >
                                    {configJSON.changeTheme}
                                </Button>
                            </Box>}
                            <Box className={classes.checkboxContainer}>
                                <Box>
                                    <Typography variant="h5">
                                        {configJSON.timezoneText}
                                    </Typography>
                                </Box>

                                <Box className={classes.dropdownContainer}>
                                    <DropdownSearch
                                        options={timezone_data}
                                        value={time_zone}
                                        onChangeValue={(event: OptionType) => this.handleSelectChange(event)}
                                        id="branch_id"
                                        isSearchable={true}
                                        placeholder={configJSON.branchPlaceholder}
                                        noOptionsMessage={configJSON.noBranchMessage}
                                    />
                                </Box>
                            </Box>
                            <Box className={classes.btnContainer}>
                                <Button
                                    className={classes.primaryBtn}
                                    data-test-id="save_btn"
                                    variant="contained"
                                    onClick={this.updateSetting}
                                >
                                    {configJSON.saveBtn}
                                </Button>
                            </Box>
                        </>
                    }
                />
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(Setting);
// Customizable Area End
