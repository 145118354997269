
import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { Favorite } from '@material-ui/icons';
import ProcedureListingController, { Props } from "./ProcedureListingController.web";
export const configJSON = require("./config");
import { commonButton ,libraryCommonCss} from "../../../components/src/utils/commonInputStyle";
import { libraryIcon } from "./assets";
import NoTickets from "../../../components/src/commonComponents/NoTickets.web";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import Loader from "../../../components/src/Loader";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import Pagination from "../../../components/src/commonComponents/Pagination.web";
// Customizable Area End


export const styles = () => createStyles({
    ...commonButton,
    ...libraryCommonCss
});
// Customizable Area End

export class ProcedureListing extends ProcedureListingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes, gridView, searchQuery } = this.props;
        const { procedure_list, header_list, loading, openDeleteModal, meta } = this.state

        const ProcedureGridView = () => (
            <Grid container spacing={2}>
                {(Boolean(procedure_list.length)) ?
                    procedure_list.map(procedure => (
                        <Grid key={procedure.attributes.id} item lg={3} md={4} sm={6} xs={12}>
                            <Box className={classes.cardWrapper}>
                                <Box display={"flex"} justifyContent={"flex-end"}>
                                    <ActionButton
                                        deleteVisible={this.props.allowEditDelete(procedure.attributes.creator?.id)}
                                        handleDelete={this.handleOpenDeleteModal}
                                        mark_as_favourite
                                        handleFavourite={() => this.handleFavouriteClick(procedure.attributes.favorite)}
                                        isOpen={this.state.isOpen[procedure.attributes.id]}
                                        toggleDropdown={() => this.toggleDropdown(procedure.attributes.id)}
                                        view
                                        handleView={() => this.handleView(procedure.attributes)}
                                        favourite={procedure.attributes.favorite}
                                    />
                                </Box>
                                <Box className="cursor" onClick={() => this.handleView(procedure.attributes)}>
                                    <Box display={"flex"} justifyContent={"center"}>
                                        <img className={classes.templateImg} src={procedure.attributes.procedure_template.data.attributes.template_image.url} alt={procedure.attributes.procedure_template.data.attributes.template_image.name} />
                                    </Box>
                                    <Box p={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Box width={"90%"}>
                                            <Typography noWrap data-test-id="title" className={classes.procedureTitleTxt}>{procedure.attributes.title}</Typography>
                                            <Typography className={classes.dateTxt}>{configJSON.date}{this.getFormattedDate(procedure.attributes.created_at)}</Typography>
                                        </Box>
                                        {Boolean(procedure.attributes.favorite) && <Favorite className={classes.favouriteIcn} />}
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    )) :
                    <Box className={classes.noTicketContainer}>
                        <NoTickets
                            title={configJSON.title}
                            imagePath={libraryIcon}
                        />
                    </Box>
                }
            </Grid>
        )

        const ProcedureListView = () => (
            <Box id="pdf-content" className={classes.listItem}>
                <Box className={classes.tableHeader}>
                    {header_list.map((item, index) => (
                        <Typography component="p" key={index}>
                            {item}
                        </Typography>
                    ))}
                </Box>
                <Box className={procedure_list.length === 0 ? classes.listMain : ""}>
                    {procedure_list.length > 0 ? (
                        procedure_list.map((item) => {
                            return (
                                <Box key={item.attributes.id} className={classes.tableItem}>
                                    <Box className={classes.imgContainer}>
                                        <img src={item.attributes.procedure_template.data.attributes.template_image.url} alt={item.attributes.procedure_template.data.attributes.template_image.name} />
                                    </Box>
                                    <Typography data-test-id="procedure_title" component="p">{item.attributes.title}</Typography>
                                    <Typography component="p"></Typography>
                                    <Typography component="p">{this.getFormattedDate(item.attributes.created_at)}</Typography>
                                    <Box display={"flex"} >
                                        <ActionButton
                                            deleteVisible={this.props.allowEditDelete(item.attributes.creator?.id)}
                                            handleDelete={this.handleOpenDeleteModal}
                                            mark_as_favourite
                                            handleFavourite={() => this.handleFavouriteClick(item.attributes.favorite)}
                                            moreVert
                                            isOpen={this.state.isOpen[item.attributes.id]}
                                            toggleDropdown={() => this.toggleDropdown(item.attributes.id)}
                                            view
                                            handleView={() => this.handleView(item.attributes)}
                                            favourite={item.attributes.favorite}
                                        />

                                    </Box>
                                    {Boolean(item.attributes.favorite) &&
                                        <Box>
                                            <Box borderRadius={"50%"} border={"1px solid #0000003b"} height={"fit-content"} width={"fit-content"} padding={"6px"}>
                                                <Favorite className={classes.favouriteIcn} />
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            );
                        })
                    ) : (
                        <NoTickets
                            title={configJSON.title}
                            imagePath={libraryIcon}
                        />
                    )}
                </Box>
            </Box>
        )

        const ProcedureViewer = () => {
              if (procedure_list.length > 0 ) {
                return (
                  <>
                      {gridView ? ProcedureGridView() : ProcedureListView()}
                      <Box className={classes.paginationBox}>
                          <Pagination meta={meta} onPageChange={this.handlePageChange} />
                      </Box>
                  </>
                )
            } else if (procedure_list.length === 0 && !loading && Boolean(searchQuery)) {
                return (
                    <Box className={classes.noTicketContainer}>
                        <Typography variant="h5">No search result found for <strong>{searchQuery}</strong>.</Typography>
                    </Box>
                )
            } else if (procedure_list.length === 0 && !loading) {
                return (
                    <Box className={classes.noTicketContainer} >
                        <NoTickets
                            title={configJSON.title}
                            imagePath={libraryIcon}
                        />
                    </Box>
                )
            }
        }
        
        return (
            <React.Fragment>
                <Loader loading={loading} />
                <Box className={`${procedure_list.length > 0 ? "" : classes.noTicketOuterContainer}`} pt={2}>
                    {ProcedureViewer()}
                </Box>
                {openDeleteModal &&
                    <ConfirmationModal
                        open={openDeleteModal}
                        onClose={this.handleCloseDeleteModal}
                        confirmDelete={this.handleDeleteClick}
                        title={configJSON.templateDeleteTitle}
                        from={configJSON.procedureFrom}
                    />}
            </React.Fragment>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default withStyles(styles)(ProcedureListing);
// Customizable Area End
