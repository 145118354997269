Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "BulkUploading";
exports.labelBodyText = "BulkUploading Body";

exports.btnExampleTitle = "CLICK ME";

exports.bulkInviteEndpoint = "account_block/accounts/bulk_invite";
exports.infoTextEndpoint = "bx_block_settings/bulk_invite_info";
exports.downloadSampleEndpoint = "account_block/accounts/get_template";

exports.uploadFileError = "Please upload a valid CSV file";
exports.title = "Bulk Invite Employees";
exports.downloadText = "1. Download Template";
exports.downloadSampleBtn = "Download";
exports.editFileText = "2. Edit Your File";
exports.attachFileText = "3. Attach Your file";
exports.attachFilePlaceholder = "Attach your File here";
exports.checkMoreText = " Check More";
exports.nextBtn = "Next";
exports.errorModalTitle = "Csv File Errors";

exports.inviteSuccessMessage = "All users has been invited successfully";
exports.inviteErrorMessage = "Please check email, department, and role any duplicate email are not accepted.";
exports.fileUploadErrorMessage = "Upload valid file";
// Customizable Area End