export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const uploadPicture = require("../assets/uploadpicture.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const editIcon = require("../assets/editIcon.png");
export const editImage = require("../assets/edit_image.png");
export const uploadDocIcon = require("../assets/uploadDocIcon.png");
export const downloadIcon = require("../assets/download.png");
export const docImage = require("../assets/doc-icon.png");
export const noBranchImage = require("../assets/noBranchImg.png");
export const exportImage = require("../assets/export_arrow.png");
export const cancelImage = require("../assets/cancel.png");
export const checkImage = require("../assets/check.png");
export const deleteImage = require("../assets/deleteImage.png");
export const profilePic = require("../assets/Oval.png");
export const singleEmployee = require("../assets/single_employee.png");
export const multiEmployee = require("../assets/multi_employee.png");
export const upArrow = require("../assets/arrow-up.png");
export const downArrow = require("../assets/arrow-down.png");
