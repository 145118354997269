import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControl, TextField, Box, Dialog, DialogTitle, DialogContent, InputLabel, DialogActions, Button } from '@material-ui/core';
import { styles } from "./ModalStyles.web";
import { closeIcon } from "../assets";
import { Formik } from "formik";

interface Props {
  classes?: any;
  open: boolean;
  handleClose?: () => void;
  editComment: any;
  comment: any;
}

class EditComment extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { open, classes, handleClose, editComment, comment } = this.props;
    return (
      <React.Fragment>
        <div className={classes.bulkInviteWrapper}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // className={classes.bulkInvieModal}
            className={classes?.customModal}
          >
            <Box className={"titleContainer"}>
              <DialogTitle id="bulk-info-modal-title">Edit Comment</DialogTitle>
              <Box className={"closeIcon"}>
                <img src={closeIcon} onClick={handleClose} alt="close-icon" />
              </Box>
            </Box>
            <Formik
              enableReinitialize={true}
              initialValues={{ comment: comment.comment }}
              onSubmit={editComment}
            >
              {({ handleChange, handleSubmit, values }) => (
                <form className={classes.formContainer} onSubmit={handleSubmit}>

                  <DialogContent>
                    <Box style={{marginBottom : "30px"}}>
                      <InputLabel className={classes.inputLabel} required>
                        Comment
                      </InputLabel>
                      <TextField
                        type="text"
                        variant="outlined"
                        name={"comment"}
                        value={values.comment}
                        placeholder="Add text here"
                        onChange={handleChange}
                        fullWidth
                        multiline
                        className={`${classes.inputField} ${classes.textAreaField}`}
                        style={{ height: "80px" }}
                      />
                      {!values.comment && <Box className={classes.helperText}>Comment is required</Box>}
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      className={`${classes.primaryBtn} next-btn`}
                      type="submit"
                      disabled={!values.comment}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(EditComment);
