// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  apiCall,
  checkToken,
  convertFormData,
  getUser,
  handleApiError,
  handleExpiredToken,
  isBusinessOwner,
  setUser,
} from "../../../components/src/utils/commonFunctions";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import { colors } from "../../../components/src/utils/commonDesign";
import { ChangeEvent } from "react";

export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  openModal: boolean;
  primary_colour: string;
  secondary_colour: string;
  background_colour?: string;
  info_text: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
interface ColorTheme {
  primary_colour: string,
  secondary_colour: string,
  background_colour?: string,
}
// Customizable Area End

export default class Customize extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  customizeThemeApiId: string = "";
  infoTextApiId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      loading: false,
      openModal: false,
      primary_colour: '',
      secondary_colour: '',
      background_colour: '#f1eeea',
      info_text: "",
    };

    // Customizable Area End

    isBusinessOwner(this.props.navigation);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    await checkToken(this.props.navigation);
    const {secondaryColor1, secondaryColor2, primaryColor1} = colors;
    this.setState({ loading: true, primary_colour : secondaryColor1, secondary_colour : secondaryColor2, background_colour : primaryColor1 });
    this.infoTextApiId = apiCall({ method: "GET", endPoint: "bx_block_settings/admin_setting", token: false });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(responseJson, this.props.navigation);
      this.setState({ loading: false });

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.infoTextApiId) {
          this.getInfoTextResponse(responseJson);
        } else if (apiRequestCallId === this.customizeThemeApiId) {
          this.addThemeResponse(responseJson);
        }
      }
    }
  }

  getInfoTextResponse = (response: { setting: { template_info: string; }; }) => {
    if (response.setting) {
      this.setState({ info_text: response.setting.template_info });
    }
  };
  addThemeResponse = (response: { data: object; errors: string[]; }) => {
    if (response.data) {
      let allData = getUser();
      allData.data.attributes.company_profile.data = response.data;
      setUser(allData);
      window.location.href = "/HomePage";
      toast.success(`Theme has been saved successfully`);
    } else {
      handleApiError(response.errors);
    }
  };


  handleTemplateChange = (template: ColorTheme) => {
    this.setState({...template });
  }

  getSelectedClass = (template: ColorTheme) => {
    const { primary_colour, secondary_colour, background_colour } = this.state;
    if (template.primary_colour.toLowerCase() === primary_colour && template.secondary_colour === secondary_colour && template.background_colour === background_colour){
      return "selectedTheme";
    }
    return "";
  }

  handleColorChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const {name , value} = event.target;
    let color = value.startsWith("#") ? value.toLowerCase() : "#" + value.toLowerCase();
    name === "primary_colour" ? this.setState({ "primary_colour": color }) : this.setState({ "secondary_colour": color });
    return color;
  }
  openPopup = () => { 
    this.setState({ openModal: true });
  };
  closePopup = () => { 
    this.setState({ openModal: false });
  };

  getCompanyID = () => {
    const user = getUser();
	  return user?.data.attributes.company_profile.data.id;
  }

  onSetTheme = () => {
    const attrs = {
      primary_colour: this.state.primary_colour,
      secondary_colour: this.state.secondary_colour,
      background_colour: this.state.background_colour,
    };
    this.setState({ loading: true });
    this.customizeThemeApiId = apiCall({
      method: "PUT",
      body: convertFormData(attrs),
      endPoint: `bx_block_profile/companies/${this.getCompanyID()}`,
      token: true,
    });
  };

  onSkipTheme = () => {
    this.setState({ loading: true });
    const attrs = {
      primary_colour: colors.secondaryColor1,
      secondary_colour: colors.secondaryColor2,
      background_colour: colors.primaryColor1,
    };
    this.customizeThemeApiId = apiCall({
      endPoint: `bx_block_profile/companies/${this.getCompanyID()}`,
      method: "PUT",
      token: true,
      body: convertFormData(attrs),
    });
  };
  // Customizable Area End
}
