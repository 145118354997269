// Customizable Area Start
import React from "react";
import ManualInviteController, { Props, configJSON } from "./ManualInviteController.web";
import { Box, Grid, withStyles, TextField, InputLabel, Button, Typography } from "@material-ui/core";
import { Wrapper } from "../../../components/src/Wrapper";
import { Formik, Form } from 'formik';
import { handleInvoiceErrors, handleInvoiceHelperText, isBO } from "../../../components/src/utils/commonFunctions";
import { styles } from "./ManualInviteStyle.web";
import { InviteEmpoyeesSchema } from "../../../components/src/utils/validationSchema";
import InfoModalWeb from "../../../components/src/commonComponents/modals/InfoModal.web";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import Loader from "../../../components/src/Loader";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class ManualInvite extends ManualInviteController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { classes } = this.props
        const { department, roles, description, role,email, branchId, branches, openModal, managerName, supervisor, parent_id, managerId } = this.state
        const succesModalDesc = () => (
            <Box className={classes.successModelBox}>
                <img src={require("../assets/check.png")} alt="check" className={classes.checkImg} />
                <Typography className={classes.modalSuccessHeadline} paragraph={false}>{configJSON.greatTitle}</Typography>
                <Typography data-test-id="invitation_message" className={classes.modalSuccessTxt} paragraph={false}>{configJSON.invitationSendMessage}</Typography>
            </Box>
        )

        return (
            <>
            <Loader loading={this.state.loading}/>
                <InfoModalWeb 
                  data-test-id='home-btn' 
                  open={openModal} 
                  description={succesModalDesc()} 
                  cancelText={configJSON.goToHome} 
                  handleClose={this.goToHome} 
                  closeIcn 
                  handleCloseModal={this.handleCloseModal}
                />
                <Wrapper 
                navigation={this.props.navigation} 
                divider data-test-id="invite-wrapper-test" 
                backbtn 
                title={configJSON.inviteEmployeeText} 
                bellIcn
                homeIcn
                child={
                    <Box className={classes.formContainer}>
                            <Formik
                                initialValues={{
                                    email,
                                    description,
                                    role,
                                    branchId,
                                    parent_id,
                                }}
                                onSubmit={this.handleSubmit}
                                validationSchema={InviteEmpoyeesSchema}
                                enableReinitialize={true}
                            >
                                {({
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    handleBlur,
                                    setFieldValue,
                                    isValid,
                                }) => (

                                    <Form translate={undefined}>
                                        <Grid container spacing={3} >
                                            <Grid item sm={12}>
                                                <InputLabel required className={classes.elabel}>{configJSON.emailText}</InputLabel>
                                                <TextField
                                                    data-test-id="txtInputEmail"
                                                    type="email"
                                                    variant="outlined"
                                                    placeholder={configJSON.enterTextEmail}
                                                    name={"email"}
                                                    value={values.email}
                                                    onChange={(event) => this.handleEmailChange(event, setFieldValue)}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={handleInvoiceErrors(
                                                        errors.email,
                                                        touched.email
                                                    )}
                                                    helperText={handleInvoiceHelperText(
                                                        errors.email,
                                                        touched.email
                                                    )}
                                                    className={classes.fieldWrapper}
                                                />
                                            </Grid>
                                            <Grid item sm={12}>
                                                <InputLabel className={classes.label} required>{configJSON.branch}</InputLabel>
                                                <DropdownSearch
                                                    data-test-id="input-branchId"
                                                    options={branches}
                                                    value={values.branchId}
                                                    onChangeValue={(event: OptionType) => this.handleDeptChange(event, setFieldValue, "branchId")}
                                                    onBlur={handleBlur}
                                                    id="branchId"
                                                    isSearchable={true}
                                                    placeholder={configJSON.selectbranch}
                                                    noOptionsMessage={configJSON.noBranchMessage}
                                                    showError={handleInvoiceErrors(
                                                        errors.branchId,
                                                        touched.branchId
                                                    )}
                                                    errorText={handleInvoiceHelperText(
                                                        errors.branchId,
                                                        touched.branchId
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item sm={6}>
                                                <InputLabel className={classes.label} required>{configJSON.role}</InputLabel>
                                                <DropdownSearch
                                                    data-test-id="role-field"
                                                    options={roles}
                                                    value={values.role}
                                                    onChangeValue={(event: OptionType) => this.handleRoleChange(event, setFieldValue)}
                                                    onBlur={handleBlur}
                                                    id="role"
                                                    isSearchable={true}
                                                    placeholder={configJSON.selectRole}
                                                    noOptionsMessage={configJSON.noBranchMessage}
                                                    showError={handleInvoiceErrors(
                                                        errors.role,
                                                        touched.role
                                                    )}
                                                    errorText={handleInvoiceHelperText(
                                                        errors.role,
                                                        touched.role
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item sm={6}>
                                                <InputLabel className={classes.label} required>{configJSON.department}</InputLabel>
                                                <DropdownSearch
                                                    data-test-id="input-dept"
                                                    options={department}
                                                    value={values.description}
                                                    onChangeValue={(event: OptionType) => this.handleDeptChange(event, setFieldValue, "description")}
                                                    onBlur={handleBlur}
                                                    id="description"
                                                    isSearchable={true}
                                                    placeholder={configJSON.departSelect}
                                                    noOptionsMessage={configJSON.deprtMessage}
                                                    isMulti={values.role === 3}
                                                    showError={handleInvoiceErrors(
                                                        errors.description,
                                                        touched.description
                                                    )}
                                                    errorText={handleInvoiceHelperText(
                                                        errors.description,
                                                        touched.description
                                                    )}
                                                />
                                            </Grid>

                                            {(values.role === 5 && supervisor.length > 0 && managerName) &&
                                                <Grid item sm={12}>
                                                    <InputLabel className={classes.label} data-test-id="label-supervisor">{configJSON.supervisor}</InputLabel>
                                                    <DropdownSearch
                                                        data-test-id="input-supervisor"
                                                        options={supervisor}
                                                        value={values.parent_id}
                                                        onChangeValue={(event: OptionType) => setFieldValue("parent_id", event.value)}
                                                        onBlur={handleBlur}
                                                        id="parent_id"
                                                        isSearchable={true}
                                                        placeholder={configJSON.selectSupervisor}
                                                        noOptionsMessage={configJSON.noSupervioserText}
                                                    />
                                                </Grid>
                                            }

                                            {(values.role && values.role !== 3 && managerName) &&
                                                <Grid item sm={12}>
                                                    <InputLabel className={classes.label}>{configJSON.manager}</InputLabel>
                                                    <TextField
                                                        name={configJSON.superParent}
                                                        data-test-id="txtInputManager"
                                                        type="text"
                                                        id={configJSON.superParent}
                                                        variant="outlined"
                                                        value={managerName}
                                                        fullWidth
                                                        className={classes.input}
                                                        contentEditable={false}
                                                    />
                                                </Grid>
                                            }
                                            <Box className={classes.buttonBox}>
                                              <Button type="submit" fullWidth  className={classes.primaryBtn} variant="contained" disabled={!Boolean(managerId || values.parent_id || values.role === 3) || Boolean(!isValid)}>{configJSON.sendInvite}</Button>
                                            </Box>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                } />
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default withStyles(styles)(ManualInvite);
// Customizable Area End