// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, handleExpiredToken, checkToken, handleApiError, isBO } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: Record<string,string>

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  branch_list: BranchData[];
  header_list: string[];
  meta: {
    total_pages: number;
    total_count: number;
    current_page: number;
    next_page: null | number;
    previous_page: null | number;
  },
  title: string;
  open: boolean;
  branch_id: number | string;
  loading:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
// Customizable Area Start
interface BranchData {
  id: string | number
  attributes: {
    branch_name: string;
    type_of_point_of_sale: string;
    location_of_point_of_sale: string;
    time_zone: null | { label: string; value: string } | string;
    from_working_hours: null | string;
    to_working_hours: null | string;
    phone_number: string;
    email: string;
    photo?: null | File;
    description: string;
    important_information: string;
  }
}


// Customizable Area End

export default class ExistingBranchController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  branchListDataCallId: string = "";
  deleteDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      branch_list: [],
      header_list: isBO() ? configJSON.branchHeaderData : configJSON.otherUserBranchHeaderData,
      meta: {
        total_pages: 1,
        total_count: 1,
        current_page: 1,
        next_page: null,
        previous_page: null
      },
      title: "",
      open: false,
      branch_id: "",
      loading:false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    checkToken(this.props.navigation);
    this.branchListData(1)
    // Customizable Area End
  }
  // Customizable Area Start
  handlePageChange = (pageNumber: number) => {
    this.setState({
      meta: {
        ...this.state.meta,
        current_page: pageNumber
      }
    });
    this.branchListData(pageNumber)
  };
  handleBranchClick = () => {
    this.props.navigation.history.push("/AddNewBranch")
  }

  handleEditClick = (item: BranchData) => {
    this.props.navigation.history.push(`edit-branch/${item.id}`, { state: item ,isEdit:true})
  }
  handleViewClick = (item: BranchData) => {
    this.props.navigation.history.push(`edit-branch/${item.id}`, { state: item ,isEdit:false})
  }
  handleDelete = (item: BranchData) => {
    this.setState({ open: true, title: item.attributes.branch_name, branch_id: item.id });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleDepartment = (item: BranchData) => {
    this.props.navigation.history.push(`ExistingDepartment?id=${item.id}`);
  }
  goToHome = () => {
    this.props.navigation.history.push(`/HomePage`);
  }
  handleBackClick = () => {
    this.props.navigation.goBack();
  }
  branchListData = (page: number) => {
    this.setState({loading:true})
    this.branchListDataCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: `${configJSON.createBranchApiEndpoint}?page=${page}`, token: true, });
  }
  handleDeleteBranch = () => {
    this.deleteDataCallId = apiCall({ method: configJSON.deleteMethodType, endPoint: `${configJSON.createBranchApiEndpoint}/${this.state.branch_id}`, token: true, });

  }
  handleResponseForBranchListData = (from: string, message: Message) => {
    if (this.branchListDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiResponseError = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse) {
        this.setState({
          branch_list: apiResponse.branches.data,
          meta: apiResponse.meta,
          loading:false
        })

      }
      if (apiResponseError) {
        this.setState({loading:false})
        toast.error(configJSON.errorMessage)

      }

    }
  }
  handleResponseForDelete = (from: string, message: Message) => {
    if (this.deleteDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiResponseError = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.message) {
        toast.success(`${apiResponse.message}`)
        this.branchListData(this.state.meta.current_page)
        this.setState({ open: false })
      } else {
        this.setState({ open: false })
        handleApiError(apiResponse.error ?? configJSON.invalidMessage);
        
      }
      if (apiResponseError) {
        toast.error(configJSON.errorMessage)

      }

    }
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForBranchListData(from, message)
    this.handleResponseForDelete(from, message)
    // Customizable Area End
  }
  // Customizable Area End


}
