import Cookies from "js-cookie";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ToastOptions, toast } from "react-toastify";
import { CompletedIcon, InProgressIcon, PendingIcon, ReOpenedIcon, RedolvedIcon, profileImage } from "../commonComponents/assets"
import { downloadExcel } from "react-export-table-to-excel";
import autoTable from "jspdf-autotable";
import moment from "moment";
import domtoimage from "dom-to-image";
import jsPDF, { RGBAData } from "jspdf";
import { selectAllOption } from "./constant";

export const obfuscateData = (data: any) => {
  const obfuscatedData = btoa(JSON.stringify(data));
  return obfuscatedData;
};

export const deobfuscateData = (obfuscatedData: any) => {
  const decodedData = atob(obfuscatedData);
  return JSON.parse(decodedData);
};

export const setToken = (token: any) => {
  localStorage.setItem("qwipbeforeiv", obfuscateData(token));
};

export const getToken = () => {
  const data = localStorage.getItem("qwipbeforeiv");
  if (data) {
    const deobfuscated = deobfuscateData(data);
    return deobfuscated;
  }
};

export const removeToken = () => {
  return localStorage.removeItem("qwipbeforeiv");
};

export const setDeviceToken = (deviceToken: any) => {
  localStorage.setItem("qwipdeviceid", obfuscateData(deviceToken));
};

export const getDeviceToken = () => {
  const data = localStorage.getItem("qwipdeviceid");
  const deobfuscated = data ?  deobfuscateData(data) : "";
  return deobfuscated;
};

export const removeDeviceToken = () => {
  return localStorage.removeItem("qwipdeviceid");
};

export const setLoginToken = (meta: any) => {
  if (meta.remember_me) {
    localStorage.setItem("qwipafteriv", obfuscateData(meta.auth_token));
  } else {
    localStorage.setItem("qwipafteriv", obfuscateData(meta.token));
  }

};

export const getLoginToken = () => {
  const data = localStorage.getItem("qwipafteriv");
  if (data) {
    const deobfuscated = deobfuscateData(data);
    return deobfuscated;
  }
};

export const removeLoginToken = () => {
  return localStorage.removeItem("qwipafteriv");
};

export const setUser = (user: any) => {
  localStorage.setItem("qwipobfuscateData", obfuscateData(user));
};

export const getUser = () => {
  const userData = localStorage.getItem("qwipobfuscateData")
  if (userData) {
    try {
      if (userData) {
        const deobfuscated = deobfuscateData(userData);
        return deobfuscated;
      }
    } catch (error) {
      return null
    }
  } else {
    return null
  }

};

export const removeUser = () => {
  return localStorage.removeItem("qwipobfuscateData");
};


export const allowNumberWithSpaceValidation = (e: any) => {
  const keyCode = e.keyCode || e.which;
  const pattern = /^[+\d\-\s]+$/i;
  const isValid = pattern.test(String.fromCharCode(keyCode));
  if (!isValid) {
    e.preventDefault();
  }
};
export const allowOnlyPhoneNumber = (e: any) => {
  const keyCode = e.keyCode || e.which;
  const pattern = /^[\d\-\s]+$/i;
  const isValid = pattern.test(String.fromCharCode(keyCode));
  if (!isValid) {
    e.preventDefault();
  } else {
    e.target.value = e.target.value
      .replace(/[^\d]/g, "")
      .replace(/(.{3})/, "$1-")
      .trim();
  }
};
export const allowOnlyNumbers = (event: any) => {
  const charCode = event.which ? event.which : event.keyCode;
  if (event.target.value.length >= 10) {
    event.preventDefault();
    return;
  }
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    event.preventDefault();
  }
};
export const allowHoursMinutes = (event: any) => {
  const value = event.target.value;
  const key = event.key;
  const caretPosition = event.target.selectionStart;
  const finalValue = value.slice(0, caretPosition) + key + value.slice(caretPosition)
  const isValidInput = /^\d{0,2}(:\d{0,2})?$/.test(finalValue) || key === 'Backspace';

  if (!isValidInput) {
    event.preventDefault();
    return;
  }

  const [hoursPart, minutesPart] = (finalValue).split(':').map((part: string) => parseInt(part));
  if (hoursPart > 23) {
    event.preventDefault();
    return;
  }
  if (minutesPart && minutesPart > 59) {
    event.preventDefault();
  }
};

export const allowOnlyPrice = (e: any) => {
  const keyCode = e.keyCode || e.which;
  const pattern = /^[0-9.]+$/i;
  const isValid = pattern.test(String.fromCharCode(keyCode));
  if (!isValid) {
    e.preventDefault();
  }
  const { value } = e.target;
  if (value.includes('.') && e.key === '.') {
    e.preventDefault();
  }
};


export const handleInvoiceErrors = (error: any, touch: any) => {
  return touch && Boolean(error);
};

export const handleInvoiceHelperText = (text: any, touch: any) => {
  return touch && text;
};
export const handleLoginRedirection = (navigation: any) => {
  navigation.navigate("EmailLogin");
};

export const afterLoginRedirection = (user: any) => {
  const primary_colour = user.data.attributes.company_profile.data?.attributes.primary_colour;
  const secondary_colour = user.data.attributes.company_profile.data?.attributes.secondary_colour;
  const background_colour = user.data.attributes.company_profile.data?.attributes.background_colour;
  if (user.data.attributes.role_id == 2) {
    if (!primary_colour && !secondary_colour && !background_colour) {
      window.location.href = "/Customize";
    } else {
      window.location.href = "/HomePage";
    }
  } else {
    window.location.href = "/HomePage";
  }
};

export const getCompanyID = () => {
  const user: any = getUser();
  return user.data.attributes.company_profile.data?.id;
};

export const getUserRoleID = () => {

  const user: any = getUser();
  return Number(user?.data.attributes.role_id);
};

export const handleLandingRedirection = (data: any, navigation: any) => {
  if (data?.business_owner_email) {
    navigation.navigate("EmailRegistration");
  } else {
    if (!data?.attributes?.activated) {
      if (data.attributes?.role_id == 2) {
        navigation.navigate("MyProfile");
      } else {
        navigation.navigate("JoiningForm");
      }
    } else {
      navigation.navigate("EmailLogin");
    }
  }
};
export const handleRemoveAllLocalStorage = () => {
  removeLoginToken();
  removeUser();
  Cookies.remove('rememberMe');
};
export const handleRemoveAllFromLocal = () => {
  removeToken();
  removeLoginToken();
  removeUser();
  Cookies.remove('rememberMe');
  window.location.href = "/EmailLogin";
};
export const handleExpiredToken = (response: any, navigation: any) => {
  if (
    response &&
    response.errors &&
    response.errors.length
  ) {

    const isArrayOfStirng = typeof response.errors[0] === 'string';

    const errorMessages = isArrayOfStirng ? response.errors.join(", ") : response.errors?.map((error: any) => Object.values(error)).flat().join(", ");
    if (response.errors[0].token) {

      toast.error(response.errors[0].token ?? "Invalid Token OR Expired Token");
      handleRemoveAllFromLocal();
    } else {
      if (errorMessages.includes("Your password has been changed")) {
        toast.error(response.errors[0] ?? "Invalid Token OR Expired Token");
        handleRemoveAllFromLocal();
      }
      return false
    }
  }

  return false;
};
export const checkToken = (navigation?: any) => {
  if (!getLoginToken() || !getUser()) {
    toast.error("Invalid Token");
    handleRemoveAllFromLocal();
  }
  return true;
};

export const checkTokenBeforeLogin = (navigation: any) => {
  if (getLoginToken()) {
    navigation.navigate("HomePage");
  }else if(!getToken()) {
    navigation.navigate("EmailLogin");
  }
  return true;
};

export const handleApiError = (errors: any) => {
  const isArrayOfStirng = typeof errors[0] === 'string';

  const errorMessages = isArrayOfStirng ? errors.join(", ") : errors?.map((error: any) => Object.values(error)).flat().join(", ");
  if (errorMessages.includes("Token has Expired") || errorMessages.includes("Invalid token") || errorMessages.includes("Login again to continue")) {
    handleRemoveAllFromLocal();
  }
  toast.error(errorMessages);
  return "";
};

export const handleUndefinedData = (value: any, elseValue: any) => {
  if (Array.isArray(value)) {
    return value.length > 0 ? value : elseValue;
  } else {
    return !!value ? value : elseValue;
  }
}

export const apiCall = (data: {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: any;
  token?: boolean;
}) => {
  const { contentType, method, endPoint, body, token } = data;
  const header: any = {
    token: token && (getLoginToken() ?? getToken()),
  };
  if (contentType) {
    header["content-type"] = contentType;
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

export const mergeFiles = async (array1: Array<any>, array2: Array<any>): Promise<Array<File>> => {
  const mergedFiles: File[] = [...array2];

  let files = Array.from(array1);
  let uniqueArray1 = files.filter(
    (file: any) => !array2.map(({ name }: any) => name).includes(file.name)
  );

  await Promise.all(uniqueArray1.map(async (obj) => {
    if (obj) {
      const fileExtension = obj.name.split('.').pop().toLowerCase();
      const response = await fetch(obj.url);
      const blob = await response.blob();
      const file = new File([blob], obj.name, { type: fileExtension });
      mergedFiles.push(file);
    }
  }));
  return mergedFiles;
};

export const convertFormData = (attrs: any) => {
  let formData = new FormData();
  Object.keys(attrs).forEach((key: any) => {
    if (attrs[key] instanceof File) {
      formData.append(
        `data[attributes][${key}]`,
        attrs[key],
        attrs[key].name
      );
    } else if (Array.isArray(attrs[key])) {
      const arrField = attrs[key];
      if (arrField.length) {
        arrField.forEach((document: any) => {
          formData.append(`data[attributes][${key}][]`, document);
        });
      } else {
        formData.append(`data[attributes][${key}]`, arrField);
      }
    } else {
      formData.append(`data[attributes][${key}]`, attrs[key]);
    }
  });

  return formData;
}

export const convertAllFormData = (data: any, parentKey: any = null, formData: any = undefined) => {
  if (formData === undefined) {
    formData = new FormData();
  }
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      let propName = parentKey ? `${parentKey}[${key}]` : key;
      if (data[key] instanceof File) {
        formData.append(propName, data[key]);
      } else if (Array.isArray(data[key])) {
        if (Array.isArray(data[key]) && data[key].every((item: any) => item instanceof File)) {
          for (let file of data[key]) {
            formData.append(`${propName}[]`, file);
          }
        } else if (data[key].every((item: any) => typeof item === 'object')) {
          for (let i = 0; i < data[key].length; i++) {
            convertAllFormData(data[key][i], `${propName}[${i}]`, formData);
          }
        } else {
          for (let value of data[key]) {
            formData.append(`${propName}[]`, value);
          }
        }
      } else if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
        convertAllFormData(data[key], propName, formData);
      } else {
        formData.append(propName, data[key]);
      }
    }
  }
  return formData;
};

export const fakeApiCallMessage = (FakeResponse: any): Message => {
  const response = new Message(
    getName(MessageEnum.RestAPIResponceMessage)
  );
  response.addData(
    getName(MessageEnum.RestAPIResponceDataMessage),
    response.messageId
  )
  response.addData(
    getName(MessageEnum.RestAPIResponceSuccessMessage),
    FakeResponse
  )
  return response
}

export const getColoursFromLocal = () => {
  const user: any = getUser();

  const primary_colour = user?.data.attributes.company_profile.data.attributes.primary_colour;
  const secondary_colour = user?.data.attributes.company_profile.data.attributes.secondary_colour;
  const background_colour = user?.data.attributes.company_profile.data.attributes.background_colour;

  return { primary_colour, secondary_colour, background_colour };
};

export const getCompanyOwnerName = () => {
  const user: any = getUser();

  const ownerName = user?.data.attributes.company_profile.data.attributes.owner_name;
  const companyName = user?.data.attributes.company_profile.data.attributes.name;

  return { ownerName, companyName };
};

export const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 B';

  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
}
export const mockApiCallData = (instance: any, apiCallId: string, apiData: any, responseType: number) => {
  const msgDeviceTokenAPI = new Message(
    getName(MessageEnum.RestAPIResponceMessage)
  );
  msgDeviceTokenAPI.addData(
    getName(MessageEnum.RestAPIResponceDataMessage),
    msgDeviceTokenAPI.messageId
  );
  msgDeviceTokenAPI.addData(
    getName(responseType),
    apiData
  );
  instance[apiCallId] = msgDeviceTokenAPI.messageId;

  runEngine.sendMessage("Api Test", msgDeviceTokenAPI);
};

export const getStatusIcon = (item: string) => {
  let result;

  switch (item) {
    case "pending":
    case "mark_as_incomplete":
      result = PendingIcon;
      break;
    case "inprogress":
      result = InProgressIcon;
      break;
    case "reopen":
      result = ReOpenedIcon;
      break;
    case "completed":
    case "mark_as_complete":
      result = CompletedIcon;
      break;
    case "resolve":
      result = RedolvedIcon;
      break;
    default:
      break;
  }
  return result;
}

// returns a object only containing diff key/values
export function getDiffs(original: any, diff: any): any {
  const diffKeys = Object.keys(diff);
  let obj: any = {};
  for (let key of diffKeys) {
    if (original[key] !== diff[key] && diff[key] !== undefined) {
      if (diff[key] instanceof Array) {
        if (isDiff(original[key], diff[key])) obj[key] = diff[key];
      } else if ((!original.hasOwnProperty(key) && diff[key] != null)
        || (original.hasOwnProperty(key) && (String(original[key]) !== String(diff[key])))) {
        obj[key] = diff[key];
      }
    }
  }
  return obj;
}

export function filterNullObj(data: any): any {
  const keys = Object.keys(data);
  let url: any = "";
  for (let key of keys) {
    if (!!data[key]) {
      if (url) url = url + "&" + `${key}=${data[key]}`;
      else url = url + `${key}=${data[key]}`;
    }
  }
  return url;
}

// check if two variables are the same or not
export function isDiff(original: any, diff: any) {
  if (typeof original === 'string') return false;
  let t = (original === null || original === undefined) ? [] : original;
  if (t.length !== diff.length) return true;
  if (t.length === 0 && diff.length === 0) return false;

  for (let i = 0; i < t.length; i++) {
    if (!t.includes(diff[i])) return true;
  }
  return false;
}

export const getNavigationMessage = (route: string, props: any, payload?: any): Message => {

  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), route);

  message.addData(getName(MessageEnum.NavigationPropsMessage), props);

  if (payload) {
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), payload);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  }
  return message;
}
export const convertTo12HourFormat = (timeStr: any) => {
  const [hours, minutes] = timeStr?.split(':');

  const inputTime = new Date(2000, 0, 1, hours, minutes);

  const morningBoundary = new Date(2000, 0, 1, 11, 30);
  const eveningBoundary = new Date(2000, 0, 1, 12, 0);

  let formattedTime;
  if (inputTime < morningBoundary) {
    formattedTime = inputTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  } else if (inputTime >= morningBoundary && inputTime < eveningBoundary) {
    formattedTime = inputTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  } else {
    formattedTime = inputTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  }

  return formattedTime.toLowerCase();
}

export const sortArrayAlphabetically = (arr: any, property: any) => {
  return arr.slice().sort((a: any, b: any) => {
    const valueA = String(a[property]).toUpperCase();
    const valueB = String(b[property]).toUpperCase();

    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  });
};

export const getLastDigitsFromString = (inputString: string) => {
  const matches = inputString.match(/\d+$/);
  if (matches) {
    return Number(matches[0]);
  }
  return 1;
};

export const trimStringValues = (data: any) => {
  for (const key in data) {
    if (typeof data[key] === 'string') {
      data[key] = data[key].trim();
    } else if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
      trimStringValues(data[key]);
    } else {
      data[key] = data[key]
    }
  }
  return data;
};

export const isBusinessOwner = (navigation: any, data?: any) => {
  const role = data?.attributes?.role_id ?? getUserRoleID()
  if (role == 2) {
    return;
  } else {
    return navigation.goBack();
  }
};
const userData = getUser()
export const userProfileAccount = [{value: userData?.data.attributes.profile.data?.attributes.account_id,label: userData?.data.attributes.profile.data?.attributes.owner_email}]
export const loggedUserId = Number(userData?.data.id)
export const userProfileId = userData?.data.attributes.profile.data?.id
export const userRoleType = userData?.data.attributes.role_name
export const userBranchId = userData?.data.attributes.branch_id
export const usersDepartments = userData?.data.attributes?.assigned_departments.data
const companyProfile = userData?.data.attributes?.company_profile?.data.attributes.photo
const companyName = userData?.data.attributes?.company_profile?.data.attributes.name
export const ownerName = userData?.data.attributes?.profile?.data.attributes.owner_name


export const isBO = () => {
  const role = getUserRoleID();
  return role == 2;
};

export const isManager = () => {
  const role = getUserRoleID();
  return role == 3
};
export const isAgent = () => {
  const role = getUserRoleID();
  return role == 5
};
export function getFormattedDate(): string {
  const date = new Date();
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
  const formattedDate: string = new Intl.DateTimeFormat('en-GB', options).format(date);
  return formattedDate;
}

const dateValue = getFormattedDate()
export const handleExcelDownload = (header: string[], body: Array<{ [key: string]: string | number | boolean }> | string[][], title: string) => {
  downloadExcel({
    sheet: `${title}-table-to-excel`,
    fileName: `${title}_${companyName}_${dateValue}`,
    tablePayload: {
      header: header,
      body: body,
    },
  });
};
const convertToCSV = (data: (string | number)[][], headers: string[]) => {
  const headerRow = headers.join(",");
  const rows = data.map((row) => row.join(","));
  return [headerRow, ...rows].join("\n");
};

export const downloadCSV = (header: string[], body: any, title: string) => {
  const csvContent = convertToCSV(body, header);
  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${title}_${companyName}_${dateValue}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadPDF = (data: Array<{ [key: string]: string | number | boolean | any }>, header: string[], title: string, saveTitle: string) => {
  const doc = new jsPDF("l", "mm");
  const logo = new Image();
  logo.src = companyProfile === null ? profileImage : companyProfile;

  const diameter = 13;

  const addHeader = (pageNumber: number) => {
    const x = 15;
    const y = 10;
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const marginBottom = 10;
    companyProfile && doc.setFillColor("255");
    companyProfile && doc.circle(x + diameter / 2, y + diameter / 2, diameter / 2, "FD");
    companyProfile && doc.addImage(logo, "PNG", x, y, diameter, diameter)

    doc.setFontSize(10);
    doc.text(`${title} - Sheet`, pageWidth / 2, 21, { align: "center" });
    doc.text(`Date: ${moment(new Date()).format("DD/MM/YYYY")}`, 266, 20, { align: "center" });
    doc.text(`${pageNumber}`, pageWidth / 2, pageHeight - marginBottom, { align: "center" });
    doc.setFontSize(20);
    doc.text(companyName, pageWidth / 2, 15, { align: "center" });
  };
  const processedData = data.map((row) =>
    row.map((cell: string) => {
      if (cell && cell.length > 30) {
        return cell.substring(0, 30) + "\n" + cell.substring(30);
      }
      return cell;
    })
  );
  const countLongCells = (data: string[][]): number => {
    let count = 0;
    for (const row of data) {
      for (const cell of row) {
        if (cell && cell.length > 30) {
          count++;
        }
      }
    }
    return count;
  };

  const longCellCount = countLongCells(processedData);
  let pageNumber = 1;
  let rowsPerPage = 20;
  if (longCellCount < 3) {
    rowsPerPage = 18;
  } else if (longCellCount <= 5) {
    rowsPerPage = 18;
  } else if (longCellCount >= 10) {
    rowsPerPage = 14;
  }
  for (let i = 0; i < processedData.length; i += rowsPerPage) {
    if (i > 0) {
      doc.addPage();
    }
    addHeader(pageNumber);
    pageNumber++;

    autoTable(doc, {
      startY: 30,
      head: [header],
      body: processedData.slice(i, i + rowsPerPage),
      theme: "grid",
    });
  }

  doc.save(`${saveTitle}_${companyName}_${dateValue}`);
};

export const getUserBranchId = (id?: number | string) => {
  return isBO() ? id ?? "" : userBranchId;
};

export async function passwordEncryption(password: string): Promise<string> {
  const textEncoder = new TextEncoder();
  const passBuffer = textEncoder.encode(password);
  const passHashBuffer = await crypto.subtle.digest("SHA-256", passBuffer);
  const passHashArray = Array.from(new Uint8Array(passHashBuffer));
  const passHashHex = passHashArray.map((byte) => byte.toString(8).padStart(2, "0")).join("p@P").slice(0, 100);;
  return passHashHex;
}

export const generateTimeValues = () => {
  const timeValues: string[] = [];
    for (let hours = 0; hours <= 24; hours++) {
        const formattedHours = hours.toString().padStart(2, "0");
        timeValues.push(`${formattedHours}:00`);
    }
    return timeValues;
};
export const commonDownloadPdfFunc = (el: HTMLDivElement | null, handleLoading: () => void,title:string,saveTitle:string,height?:boolean) => {
  const element = el

  if (!element) {
    toast.error("PDF content element not found. Aborting PDF generation.");
    return;
  }

  const doc = new jsPDF("p", "mm");
  const margin = 10;
  const logo = new Image();
  logo.src = companyProfile === null ? profileImage : companyProfile;
  
  const diameter = 13;
  const addHeader = (pageNumber: number) => {
    const x = 10;
    const y = 10;
    const pageHeight = doc.internal.pageSize.height;
    const marginBottom = 10;
    companyProfile && doc.addImage(logo, "PNG", x, y, diameter, diameter)

    doc.setFontSize(10);
    doc.text(title, 105, 21, { align: "center" });
    doc.text(`Date: ${moment(new Date()).format("DD/MM/YYYY")}`, 177, 20, { align: "center" });
    doc.text(`${pageNumber}`, doc.internal.pageSize.width / 2, pageHeight - marginBottom, { align: "center" });
    doc.setFontSize(20);
    doc.text(companyName, 105, 15, { align: "center" });
  };
  addHeader(1)
  domtoimage
  .toPng(element)
  .then((dataUrl) => {
    const imgWidth = doc.internal.pageSize.width - 2 * margin; 
    const pageHeight = doc.internal.pageSize.height;
    const imgHeight = (height ? 1 : 0.5) * (pageHeight - 2 * margin);

    const yPos = 30;
    const maxHeight = doc.internal.pageSize.height - 2 * margin - yPos;

    const finalImgHeight = Math.min(maxHeight, imgHeight);

    const xPos = (doc.internal.pageSize.width - imgWidth) / 2;
    
    doc.addImage(dataUrl, "PNG", xPos, yPos, imgWidth, finalImgHeight);
    doc.save(`${saveTitle.toLowerCase().split(" ").join("_")}_${companyName}_${dateValue}`);
    handleLoading();
  })
  .catch((error) => {
    toast.error("Error generating PDF:", error);
  });


};

export function removeNullFromObj(data: any) {
  const result: any = {};
  const keys = Object.keys(data);
  for (let key of keys) {
    if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
      result[key] = data[key];
    }
  }
  return result;
}

export function capitalizeFirstLetter(str:string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function handleValuesToDescArr (values: any, dataArr: any, findStr: string) {
  Object.keys(values).forEach(key => {
    if (key.includes(findStr)) {
      const index = Number(key.slice(-1)) - 1;
      if (!isNaN(index) && index >= 0 && index < dataArr.length) {
        dataArr[index] = { ...dataArr[index], [findStr]: values[key] };
      }
    }
  });
  return dataArr;
}

export const toRelativeTime = (dateStr: string) => {
  const date = moment(dateStr);
  const now = moment();

  const diffMinutes = now.diff(date, 'minutes');
  const diffHours = now.diff(date, 'hours');
  const diffDays = now.diff(date, 'days');

  if (diffMinutes < 60) {
    return `${diffMinutes}m ago`;
  } else if (diffHours < 24) {
    return `${diffHours}h ago`;
  } else {
    return `${diffDays}d ago`;
  }
};

export const handleDownloadImage = async (urlLink: string | undefined, name: string | undefined,handleLoading:() => void) => {
  if (!urlLink) {
      console.error('URL is not defined');
      return;
  }

  const nameValue = name?.replace(/[ .]/g, '');
  try {
      const response = await fetch(urlLink);
      const blob = await response.blob();

      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = nameValue || 'image';

      link.click();

      window.URL.revokeObjectURL(blobUrl);
      handleLoading()
  } catch (error) {
      console.error('Error downloading image:', error);
      handleLoading()
  }
};

export const compareArrays = (array1: any, array2: any) => {
  let newArray1 = array1.filter((item: any) => item !== selectAllOption.value);
  let newArray2 = array2.filter((item: any) => item !== selectAllOption.value);
  const array2Sorted = newArray2 ? newArray2?.slice().sort() : [];
  let compare = newArray1?.length === newArray2?.length && newArray1?.slice().sort().every(function (value: any, index: any) {
      return value === array2Sorted[index];
  })
  return !!compare;
};
