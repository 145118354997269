// Customizable Area Start
import React from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  InputLabel,
  Card,
  CardContent,
  MobileStepper,
  FormHelperText,

} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";

import { DateRange, Close } from '@material-ui/icons';
import { Formik } from "formik";
import CompanyProfileController, { Props, RenderFormProps, configJSON } from "./CompanyProfileController.web";
import { styles } from "./CompanyProfileStyles.web";
import LoginSignupBackgroundImgWeb from "../../../components/src/LoginSignupBackgroundImg.web";
import { allowNumberWithSpaceValidation, handleInvoiceErrors, handleInvoiceHelperText, allowOnlyNumbers, allowHoursMinutes } from "../../../components/src/utils/commonFunctions";
import { uploadDocIcon, deleteIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
const logo = require("../../../components/src/qwipLogo.svg");
import CustomAutoComplete from "../../../components/src/commonComponents/fields/CustomAutoComplete.web";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class CompanyProfile extends CompanyProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      apiError,
      photoUrl,
      activeStep,
      documentList,
      name,
      owner_name,
      owner_email,
      phone_number,
      industry_id,
      business_type_id,
      creation_date,
      employee_count,
      head_office,
      description,
      photo,
      countryCodeList,
      country_code_and_flag_id,
      from_working_hours,
      to_working_hours
    } = this.state;

    const stepperOne = (
      { errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        handleBlur }: RenderFormProps
    ) => (
      <Grid container spacing={8}>
        <Grid item xs={12} className={classes.uploadBox}>
          <Box className={classes.uploadImage}>
            <TextField
              key={photoUrl}
              id="photo"
              name="photo"
              variant="outlined"
              type="file"
              fullWidth
              onChange={(event:  React.ChangeEvent<HTMLInputElement>) => this.handlephotoUpload(event, setFieldValue)}
              InputProps={{
                inputProps: {
                  accept: "image/png, image/jpeg",
                },
              }}
            />
            <Box className="innerContent">
              {photoUrl ? (
                <img
                  src={photoUrl}
                  alt="uploadDocIcon"
                  className="filePreview"
                />
              ) : (
                <React.Fragment>
                  <img
                    src={uploadDocIcon}
                    alt="uploadDocIcon"
                    className="uploadImage"
                  />
                  <Typography
                    variant="body1"
                    className="uploadText"
                  >
                    {configJSON.companyLogoText}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="uploadText"
                  >
                    ({configJSON.uploadText})
                  </Typography>
                </React.Fragment>
              )}
            </Box>
            {photoUrl && <Box
              className={classes.closeIcn}
              onClick={() => {
                setFieldValue("photo", null)
                this.handleRemoveImage()
              }
              } >
              <Close />
            </Box>}
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          className={`${classes.companyProfileLeftSection} step1PaddingTop`}
        >
          <Box className={`${classes.fieldWrapper} step1Grid`}>
            <InputLabel required className={classes.inputLabel}>
              {configJSON.companyName}
            </InputLabel>
            <TextField
              data-test-id="txtInputName"
              type="text"
              variant="outlined"
              placeholder={configJSON.companyName}
              name={"name"}
              value={values.name}
              onChange={handleChange}
              fullWidth
              className={classes.inputField}
              error={handleInvoiceErrors(
                errors.name,
                touched.name
              )}
              helperText={handleInvoiceHelperText(
                errors.name,
                touched.name
              )}
              onBlur={handleBlur}
            />
          </Box>
          <Box className={`${classes.fieldWrapper} step1Grid`}>
            <InputLabel required className={classes.inputLabel}>
              {configJSON.ownerEmailText}
            </InputLabel>
            <TextField
              data-test-id="txtInputEmail"
              type="email"
              variant="outlined"
              placeholder={configJSON.ownerEmailText}
              name={"owner_email"}
              value={values.owner_email}
              onChange={handleChange}
              fullWidth
              className={classes.inputField}
              error={handleInvoiceErrors(
                errors.owner_email,
                touched.owner_email
              )}
              helperText={handleInvoiceHelperText(
                errors.owner_email,
                touched.owner_email
              )}
              onBlur={handleBlur}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          className={`${classes.rightSection2} step1PaddingTop`}
        >
          <Box className={`${classes.fieldWrapper} step1Grid`}>
            <InputLabel required className={classes.inputLabel}>
              {configJSON.fullName}
            </InputLabel>
            <TextField
              data-test-id="txtInputName"
              type="text"
              variant="outlined"
              placeholder={configJSON.fullName}
              name={"owner_name"}
              value={values.owner_name}
              onChange={handleChange}
              fullWidth
              className={classes.inputField}
              error={handleInvoiceErrors(
                errors.owner_name,
                touched.owner_name
              )}
              helperText={handleInvoiceHelperText(
                errors.owner_name,
                touched.owner_name
              )}
              onBlur={handleBlur}
            />
          </Box>
          <Box className={`${classes.fieldWrapper} step1Grid`}>
            <InputLabel required className={classes.inputLabel}>
              {configJSON.profilePhoneLabel}
            </InputLabel>
            <Box className={classes.CompanyPhoneGroup}>
              <Box className={classes.dropdownWidth}>
                <CustomAutoComplete
                  options={countryCodeList}
                  onChange={(event: { target: { name: string } }, newValue: OptionType) => this.handleChangeSelect(newValue, setFieldValue, "country_code_and_flag_id")}
                  handleBlur={handleBlur}
                  value={values.country_code_and_flag_id || this.state.country_code_and_flag_id}
                  placeholder=""
                  name="country_code_and_flag_id"
                  classes={classes}
                  removeArrow
                  error={handleInvoiceErrors(
                    errors.country_code_and_flag_id,
                    touched.country_code_and_flag_id
                  )}
                />
              </Box>
              <TextField
                data-test-id="txtInputPhone"
                type="text"
                variant="outlined"
                placeholder={configJSON.profilePhoneLabel}
                value={values.phone_number}
                name={"phone_number"}
                onChange={handleChange}
                onKeyPress={allowNumberWithSpaceValidation}
                fullWidth
                className={`${classes.inputField} ${classes.inputWidth}`}
                onBlur={handleBlur}
                error={handleInvoiceErrors(
                  errors.phone_number,
                  touched.phone_number
                )}
              />

            </Box>
            {(handleInvoiceErrors(errors.phone_number, touched.phone_number) ||
              handleInvoiceErrors(errors.country_code_and_flag_id, touched.country_code_and_flag_id)) && (
                <FormHelperText
                  data-test-id={"errorMessage"}
                  className={classes.helperText}
                >
                  {handleInvoiceHelperText(errors.country_code_and_flag_id, touched.country_code_and_flag_id)}
                  {handleInvoiceHelperText(errors.phone_number, touched.phone_number)}
                </FormHelperText>
              )}
          </Box>
        </Grid>
      </Grid>
    )

    const stepperTwo = (
      { errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        handleBlur }: RenderFormProps
    ) => (
      <Grid container className={classes.container}>
        <Grid
          item
          xs={12}
          className={classes.companyProfileLeftSection}
        >
          <Box className={classes.fieldWrapper}>
            <InputLabel data-test-id="industry_text" className={classes.inputLabel}>
              {configJSON.industryText}
            </InputLabel>
            <CustomAutoComplete
              options={this.state.industries}
              onChange={(event: { target: { name: string } }, newValue:OptionType) => this.handleChangeSelect(newValue, setFieldValue, "industry_id")}
              handleBlur={handleBlur}
              value={values.industry_id ?? ""}
              placeholder={configJSON.industryPlaceholder}
              name="industry_id"
              classes={classes}
            />
          </Box>
          <Box className={classes.fieldWrapper}>
            <InputLabel className={classes.inputLabel}>
              {configJSON.businessTypeText}
            </InputLabel>
            <CustomAutoComplete
              options={this.state.typeOfBusiness}
              onChange={(event: { target: { name: string } }, newValue: OptionType) => this.handleChangeSelect(newValue, setFieldValue, "business_type_id")}
              handleBlur={handleBlur}
              value={values.business_type_id ?? ""}
              placeholder={configJSON.businessTypePlaceholder}
              name="business_type_id"
              classes={classes}
            />
          </Box>
        </Grid>
      </Grid>
    )

    const stepperThree = (
      { errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        handleBlur }: RenderFormProps
    ) => (
      <Grid container className={classes.container}>
        <Grid
          item
          xs={6}
          className={classes.companyProfileLeftSection}
        >
          <Box className={classes.fieldWrapper}>
            <InputLabel required className={classes.inputLabel}>
              {configJSON.creationDate}
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                variant="inline"
                margin="normal"
                fullWidth
                className={`${classes.inputField} ${classes.dateField}`}
                InputProps={{
                  endAdornment: <DateRange />,
                  disableUnderline: true,
                }}
                autoOk
                name="creation_date"
                value={values.creation_date}
                format="dd/MM/yyyy"
                allowKeyboardControl={false}
                placeholder={configJSON.creationDatePlaceholderText}
                onChange={setFieldValue.bind(null, "creation_date")}
                onBlur={handleBlur}
                disableFuture={true}
              />
            </MuiPickersUtilsProvider>
            {(touched.creation_date && errors.creation_date) && <FormHelperText className="error-text">
              {errors.creation_date}
            </FormHelperText>}
          </Box>
          <Box className={classes.fieldWrapper}>
            <InputLabel data-test-id="employee_text" required className={classes.inputLabel}>
              {configJSON.noOfEmployeeText}
            </InputLabel>
            <TextField
              data-test-id="txtInputeEployeeCount"
              type="text"
              variant="outlined"
              placeholder={configJSON.employeeWorkingPlaceholderText}
              name={"employee_count"}
              value={values.employee_count}
              onChange={handleChange}
              onKeyPress={allowOnlyNumbers}
              fullWidth
              className={classes.inputField}
              error={handleInvoiceErrors(
                errors.employee_count,
                touched.employee_count
              )}
              helperText={handleInvoiceHelperText(
                errors.employee_count,
                touched.employee_count
              )}
              onBlur={handleBlur}
            />
          </Box>
          <Box className={classes.fieldWrapper}>
            <InputLabel required className={classes.inputLabel}>
              {configJSON.workingHoursText}
            </InputLabel>

            <Box className={classes.timeContainer}>
              <CustomAutoComplete
                options={this.state.hoursList}
                onChange={(event: { target: { name: string; }; }, newEvent: string | null) => this.handleFromWorkingSelect(event, newEvent, values)}
                handleBlur={handleBlur}
                value={this.state.from_working_hours}
                placeholder={configJSON.fromPlaceholderText}
                name="from_working_hours"
                classes={classes}
                freeSolo
                error={handleInvoiceErrors(
                  errors.from_working_hours,
                  touched.from_working_hours
                )}
                handleKeyPress={allowHoursMinutes}
              />
              <CustomAutoComplete
                options={this.state.hoursList}
                onChange={(event: { target: { name: string; }; }, newEvent: string | null) => this.handleToWorkingSelect(event, newEvent, values)}
                handleBlur={handleBlur}
                value={this.state.to_working_hours}
                placeholder={configJSON.toWorkingPlaceholderText}
                name="to_working_hours"
                classes={classes}
                freeSolo
                error={handleInvoiceErrors(
                  errors.to_working_hours,
                  touched.to_working_hours
                )}
                handleKeyPress={allowHoursMinutes}
              />
            </Box>
            {(handleInvoiceErrors(errors.from_working_hours, touched.from_working_hours) ||
              handleInvoiceErrors(errors.to_working_hours, touched.to_working_hours)) && (
                <FormHelperText
                  data-test-id={"formToErrorMessage"}
                >
                  {handleInvoiceHelperText(errors.from_working_hours, touched.from_working_hours)}
                  {handleInvoiceHelperText(errors.to_working_hours, touched.to_working_hours)}
                </FormHelperText>
              )}
          </Box>
          <Box className={classes.fieldWrapper}>
            <InputLabel required className={classes.inputLabel}>
             {configJSON.headOfficeText}
            </InputLabel>
            <TextField
              data-test-id="head_office"
              type="text"
              variant="outlined"
              placeholder={configJSON.headOfficePlaceholderText}
              name={"head_office"}
              value={values.head_office}
              onChange={handleChange}
              fullWidth
              className={classes.inputField}
              error={handleInvoiceErrors(
                errors.head_office,
                touched.head_office
              )}
              helperText={handleInvoiceHelperText(
                errors.head_office,
                touched.head_office
              )}
              onBlur={handleBlur}
            />
          </Box>
        </Grid>
        <Grid xs={6} className={classes.rightSection2}>
          <Box className={classes.fieldWrapper}>
            <InputLabel className={classes.inputLabel}>
              {configJSON.descriptionText}
            </InputLabel>
            <TextField
              data-test-id="txtInputDescription"
              type="text"
              variant="outlined"
              name={"description"}
              value={values.description}
              placeholder={configJSON.descriptionPlaceholderText}
              onChange={handleChange}
              fullWidth
              multiline
              className={`${classes.inputField} ${classes.textAreaField
                }`}
            />
          </Box>

          <div className={`${classes.fieldWrapper} ${classes.fileInput}`}>

            <InputLabel className={`${classes.inputLabel} marginBottom10`}>
              {configJSON.documentText}
            </InputLabel>
          </div>
          {!!documentList.length &&
            <Box className={classes.uploadWrapper}>
              {
                documentList.map((item, index) => (
                  <Box className={classes.docList} key={`${index + 1}-${item}`}>
                    <div className="docName">
                      {item.name}
                    </div>
                    <img
                      src={deleteIcon}
                      alt="deleteIcon"
                      className="deleteIcon"
                      data-test-id="delete_docs"
                      onClick={() => this.handleRemoveFile(item)}
                    />
                  </Box>
                ))
              }
            </Box>
          }
          {documentList.length < 2 &&
            <>
              <div className={`${classes.fileInput} ${documentList.length > 0 && "marginTop51"}`}>
                <TextField
                  key={documentList.length}
                  id="documents"
                  variant="outlined"
                  type="file"
                 onChange= {(event:  React.ChangeEvent<HTMLInputElement>) => this.handleDocumentsUpload(event, setFieldValue,values)}

                />
                <div className="label-block">
                  <img src={uploadDocIcon} alt="Upload" />
                  <span data-test-id="upload_documents_text" className="file-name" id="upload-file-name">
                   {configJSON.uploadDocumentsText}
                  </span>
                </div>
              </div>
            </>
          }
        </Grid>
      </Grid>
    )



    const renderActiveForm = (
      { errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        handleBlur }: RenderFormProps
    ) => {
      switch (activeStep) {
        case 1:
          return (
            <>
              {
                stepperOne(
                  {errors,
                  touched,
                  values,
                  handleChange,
                  setFieldValue,
                  handleBlur}
                )
              }
            </>
          )
        case 2:
          return (
            <>
              {
                stepperTwo(
                 { errors,
                  touched,
                  values,
                  handleChange,
                  setFieldValue,
                  handleBlur}
                )
              }
            </>
          )
        default:
          return (
            <>
              {
                stepperThree(
                 { errors,
                  touched,
                  values,
                  handleChange,
                  setFieldValue,
                  handleBlur}
                )
              }
            </>
          )
      }
    };

    const getInitialValue = () => {
      if (activeStep === 1) {
        return {
          name,
          owner_name,
          phone_number,
          owner_email,
          photo,
          country_code_and_flag_id
        };
      }
      else if (activeStep === 2) {
        return {
          industry_id,
          business_type_id,
        };
      }
      else {
        return {
          creation_date,
          employee_count,
          head_office,
          description,
          documents: [],
          from_working_hours,
          to_working_hours
        };
      }
    };

    return (
      // Required for all blocks
      <React.Fragment>
        <Loader loading={this.state.loading} />
        <LoginSignupBackgroundImgWeb />
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Box>
              <img src={logo} alt="qwipLogo" style={{ height: "45px" }} />
            </Box>
            <Typography data-test-id="company_profile" variant="h4" className={classes.title}>
             {configJSON.myCompanyProfileText}
            </Typography>

            <MobileStepper
              variant="progress"
              steps={4}
              position="static"
              activeStep={activeStep}
              className={classes.progressStepper}
              nextButton={
                <Button
                  size="small"
                  disabled={activeStep === 3}
                  style={{ display: "none" }}
                >
                  {configJSON.nextButton}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  style={{ display: "none" }}
                >
                   {configJSON.backButton}
                </Button>
              }
            />
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={getInitialValue()}
              validationSchema={this.getValidationSchema}
              onSubmit={(event) => this.handleNext(event)}
              validateOnMount={true}
            >
              {({
                errors,
                touched,
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                handleBlur,
                isValid,
                dirty,
              }) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                    className={
                      activeStep === 2
                        ? classes.form1
                        : classes.form
                    }
                  >
                    {renderActiveForm(
                     { errors,
                      touched,
                      values,
                      handleChange,
                      setFieldValue,
                      handleBlur}
                    )}
                    {!!apiError && (
                      <Box
                        data-test-id={"errorMessage"}
                        className={classes.errorMsg}
                      >
                        {apiError}
                      </Box>
                    )}
                    <Box
                      className={
                        activeStep === 2
                          ? classes.btnWrapper1
                          : classes.btnWrapper
                      }
                    >
                      <Button
                        size="small"
                        data-test-id="back_button"
                        onClick={this.handleBack}
                        className={`${classes.btn} ${classes.secondaryBtn
                          }`}
                      >
                         {configJSON.backButton}
                      </Button>
                      <Button
                        size="small"
                        className={`${classes.btn} ${classes.primaryBtn
                          }`}
                        type={"submit"}
                        disabled={!isValid}
                      >
                         {configJSON.nextButton}
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(CompanyProfile);
// Customizable Area End
