// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleExpiredToken, handleApiError, apiCall, getNavigationMessage, isBO, userBranchId, isManager, handleExcelDownload, downloadCSV, downloadPDF, usersDepartments, sortArrayAlphabetically, removeNullFromObj, handleUndefinedData } from "../../../components/src/utils/commonFunctions";
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes:Record<string,string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  select_department: string | null;
  selectedDepartmentId: number | null;
  select_filterTYpe: string;
  status: string | null;
  select_downloadTYpe: string | null;
  is_open_department: boolean;
  is_open_filter: boolean;
  is_open_download: boolean;
  department_list: DepartmentList[];
  meta: {
    total_pages: number;
    total_count: number;
    current_page: number;
    next_page: null | number;
    previous_page: null | number;
  },
  isCreatedActive: boolean;
  isAssignedActive: boolean;
  created_header_list: string[];
  assign_header_list: string[];
  ticket_list: Ticket[];
  status_list: DepartmentList[];
  searchQuery: string
  open: boolean;
  title: string;
  ticket_id: string | number;
  ticket_issue: TicketIssue[];
  loading: boolean;
  isBO: boolean;
  isManager: boolean;
  all_ticket_list: Array<{ [key: string]: string | number | boolean }>
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
// Customizable Area Start
export interface Ticket {
  id: string;
  type: string;
  attributes: {
    ticket_number: string;
    title: string;
    description: string;
    priority: string;
    due_date: string;
    status: string;
    status_name: string;
    completion_date: string | null;
    creator: {
      id: number;
      email: string;
      name: string;
      designation: string;
    },
    assignee_ids: {
      id: number;
      email: string;
      name: string;
      designation: string;
    },
    attachments: {
      name: string;
      url: string;
    }[];
    issue_date: string;
    rating?: string | number | null;
    feedback?: string | null;
  };
}

interface AllTicket {
  id: string;
  type: string;
  attributes: {
    ticket_number: string;
    title: string;
    due_date: string;
    issue_date: string;
    status: string;
    status_name: string;
    creator: {
      id: number;
      email: string;
      name: string;
      designation: string;
    },
    assignee: {
      id: number;
      email: string;
      name: string;
      designation: string;
    },
  };
}
export interface DepartmentList {
  id: number;
  name: string;
  text: string;
}
interface Department {
  department_id: number;
  ticket_type_id: number;
  department_name: string;
}

interface TicketIssue {
  issue_name: string;
  departments: Department[];
}
// Customizable Area End

export default class TicketTableViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  departmentListCallId: string = "";
  filterListApiCallId: string = "";
  statusListApiCallId: string = "";
  deleteTicketListCallId: string = "";
  ticketTypeListCallId: string = "";
  getAllTicketsListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      // Customizable Area Start
      select_department: "",
      selectedDepartmentId: null,
      select_filterTYpe: "",
      status: "",
      status_list: [],
      select_downloadTYpe: "",
      is_open_department: false,
      is_open_download: false,
      is_open_filter: false,
      department_list: [],
      isCreatedActive: true,
      isAssignedActive: false,
      created_header_list: configJSON.headerList,
      assign_header_list: configJSON.assignheaderList,
      meta: {
        total_pages: 1,
        total_count: 1,
        current_page: 1,
        next_page: null,
        previous_page: null
      },
      ticket_list: [

      ],
      searchQuery: "",
      open: false,
      title: "",
      ticket_id: "",
      ticket_issue: [],
      loading: false,
      isBO: isBO(),
      isManager: isManager(),
      all_ticket_list: []
      // Customizable Area End
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getDepartmentList()
    this.filterList(this.state.meta.current_page)
    this.getStatusList()
    this.getTicketTypeList()
    this.getAllTicketsList()
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.selectedDepartmentId !== prevState.selectedDepartmentId
      || this.state.status !== prevState.status
      || this.state.isAssignedActive !== prevState.isAssignedActive
      || this.state.searchQuery !== prevState.searchQuery

    ) {
      this.filterList(this.state.meta.current_page)
      this.getAllTicketsList()
    }
  }
  onClickRaiseTicket = () => {
    this.send(getNavigationMessage('CreateTicket', this.props));
  }
  onClickTicketType = () => {
    this.send(getNavigationMessage('TicketTypeList', this.props));
  }
  handleSearch = (query: string) => {
    this.setState({ searchQuery: query })
  }
  handlePageChange = (pageNumber: number) => {
    this.setState({
      meta: {
        ...this.state.meta,
        current_page: pageNumber
      }
    });
    this.filterList(pageNumber)
  };
  handleClearAllFilter = () => {
    this.setState({
      select_department: "",
      select_filterTYpe: "",
      select_downloadTYpe: "",
      selectedDepartmentId: null,
      searchQuery: "",
      status: "",
      is_open_department: false,
      is_open_download: false,
      is_open_filter: false
    })
  }
  handleBackClick = () => {
    this.props.navigation.goBack();
  }
  handleSelectDepartment = (value: DepartmentList) => {
    this.setState({
      select_department: value.name,
      selectedDepartmentId: value.id,
      is_open_department: false
    });
  };
  handleSelectFilter = (value: DepartmentList) => {
    this.setState({
      select_filterTYpe: value.name,
      status: value.text,
      is_open_filter: false
    });
  };
  toggleSelectDepartmentModal = () => {
    this.setState((prevState) => ({
      is_open_department: !prevState.is_open_department,
      is_open_download: false,
      is_open_filter: false

    }));
  };
  toggleSelectFilterModal = () => {
    this.setState((prevState) => ({
      is_open_filter: !prevState.is_open_filter,
      is_open_department: false,
      is_open_download: false,


    }));
  };
  handleSelectDownload = (value: DepartmentList) => {
    this.setState({
      select_downloadTYpe: value.name,
      is_open_download: true
    });
  };

  handleExportClick = () => {
    const { select_downloadTYpe, created_header_list, all_ticket_list, isAssignedActive, assign_header_list } = this.state
    if (select_downloadTYpe === configJSON.downloadType[2].name) {
      downloadPDF(all_ticket_list, isAssignedActive ? assign_header_list : created_header_list, "Ticket List", "Ticket_List")
      this.setState({ is_open_download: false, select_downloadTYpe: "" })
    } else if (select_downloadTYpe === configJSON.downloadType[0].name) {
      handleExcelDownload(isAssignedActive ? assign_header_list : created_header_list, all_ticket_list, "Ticket_List")
      this.setState({ is_open_download: false, select_downloadTYpe: "" })
    } else {
      downloadCSV(isAssignedActive ? assign_header_list : created_header_list, all_ticket_list, "Ticket_List")
      this.setState({ is_open_download: false, select_downloadTYpe: "" })
    }

  }

  toggleSelectDownloadModal = () => {
    this.setState((prevState) => ({
      is_open_download: !prevState.is_open_download,
      is_open_filter: false,
      is_open_department: false,

    }));
  };
  handleButtonClick = (buttonNumber: number) => {
    if (buttonNumber === 1) {
      this.setState({
        isCreatedActive: true,
        isAssignedActive: false,
        is_open_department: false,
        is_open_filter: false,
        is_open_download: false
      });
    } else {
      this.setState({
        isCreatedActive: false,
        isAssignedActive: true,
        is_open_department: false,
        is_open_filter: false,
        is_open_download: false
      });
    }
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleDelete = (item: Ticket) => {
    this.setState({ open: true, title: item.attributes.title, ticket_id: item.id });
  };
  handleDeleteTicketList = () => {
    this.deleteTicketListCallId = apiCall({
      method: configJSON.deleteMethodType,
      endPoint: `${configJSON.ticketListApiEndPoint}/${this.state.ticket_id}`,
      token: true
    });

  }
  getStatusList = () => {
    this.statusListApiCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.statusListApiEndPoint,
      token: true
    });
  }
  getTicketTypeList = () => {
    this.ticketTypeListCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.ticketTypeApiEndPoint,
      token: true
    });
  }
  getDepartmentList = () => {
    this.departmentListCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: this.state.isBO ? configJSON.departmentListApiEndpoint : `${configJSON.selectedDepartmentApiEndPoint}/${userBranchId}`,
      token: true
    });
  }
  buildParams = ():string => {
    let param = "";
  
    if (this.state.isAssignedActive) {
      param += '&type=assigned';
    } else if (this.state.isCreatedActive) {
      param += '&type=created';
    }

    if (this.state.status) {
      if (param) {
        param += '&';
      }
      param += `status=${this.state.status}`;
    }
    if (this.state.searchQuery) {
      if (param) {
        param += '&';
      }
      param += `query=${this.state.searchQuery}`;
    }
    if (this.state.selectedDepartmentId) {
      if (param) {
        param += '&';
      }
      param += `department_id=${this.state.selectedDepartmentId}`;
    }
    return param
  }
  filterList = (page: number) => {
    this.setState({ loading: true })
    const baseEndpoint = configJSON.ticketFilterListApiEndPoint + page;

    let queryParam = this.buildParams();

    const endpoint = queryParam ? `${baseEndpoint}${queryParam}` : baseEndpoint;

    this.filterListApiCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: endpoint,
      token: true,
    });
  }
  getAllTicketsList = () => {
    const baseEndpoint = configJSON.allTicketsListApiEndPoint;

    let paramsValue =  this.buildParams();;

    const endpoint = paramsValue ? `${baseEndpoint}${paramsValue}` : baseEndpoint;

    this.getAllTicketsListApiCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: endpoint,
      token: true,
    });
  }

  handleResponseForDepartmentListData = (from: string, message: Message) => {
    if (this.departmentListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse) {
        let departData;
        if (isBO()) {
          departData = apiResponse.map((item: { departments: { id: string, name: string } }) => item.departments).flat().map((item: { id: string; name: string; }) => {
            return {
              id: item.id,
              text: item.name,
              name: item.name
            }
          })
        } else {
          departData = usersDepartments.map((department: { attributes: { name: string; id: string } }) => {
            return {
              id: department.attributes.id,
              text: department.attributes.name,
              name: department.attributes.name,
            }
          })
        }
        const sortedData = sortArrayAlphabetically(departData, "name")

        this.setState({
          department_list: sortedData,
        })

      } else {
        handleApiError(apiResponse.errors);
      }

    }
  }

  handleResponseForStatusListData = (from: string, message: Message) => {
    if (this.statusListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(response, this.props.navigation);
      if (response.data) {
        const allStatus = [...response.data, { id: 6, name: "All", text: "" }]

        this.setState({
          status_list: allStatus,
        })

      } else {
        handleApiError(response.errors);
      }

    }
  }
  handleResponseForFilterListData = (from: string, message: Message) => {
    if (this.filterListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const resData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(resData, this.props.navigation);
      if (resData.tickets) {
        this.setState((prev) => ({
          loading: false,
          ticket_list: resData.tickets.data,
          meta: resData.meta ?? prev.meta,
        }))

      } else {
        this.setState({ loading: false })
        handleApiError(resData.errors);
      }

    }
  }
  mapTicketAttributes = (item: AllTicket) => {
    return [
      item.attributes.ticket_number,
      item.attributes.title,
      `${this.state.isAssignedActive ? handleUndefinedData(item.attributes.creator.name, "") : handleUndefinedData(item.attributes.assignee.name, "")}`,
      `${this.state.isAssignedActive ? item.attributes.creator.designation : item.attributes.assignee.designation}`,
      moment(item.attributes.issue_date).format(configJSON.dateFormat),
      item.attributes.due_date ? moment(item.attributes.due_date).format(configJSON.dateFormat) : "",
      item.attributes.status_name,
    ];
  }
  handleResponseForAllTicketsList = (from: string, message: Message) => {
    if (this.getAllTicketsListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(response, this.props.navigation);
      if (response.tickets) {
        const result = response.tickets.data.map((item: AllTicket) => this.mapTicketAttributes(item));
        this.setState({
          all_ticket_list: result,
        })

      } else {
        this.setState({ all_ticket_list: [] })
      }

    }
  }
  handleResponseForDeleteTicket = (from: string, message: Message) => {
    if (this.deleteTicketListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.ticket) {
        toast.success(`${apiResponse.ticket[0].message}`)
        this.filterList(this.state.meta.current_page)
        this.setState({ open: false })
      } else {
        this.setState({ open: false })
        handleApiError(apiResponse.error);

      }

    }
  }
  handleResponseForTicketType = (from: string, message: Message) => {
    if (this.ticketTypeListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.data) {
        this.setState({ ticket_issue: apiResponse.data })
      } else {
        handleApiError(apiResponse.error);

      }

    }
  }
  redirectEditTicket = (itemId: string) => {
    this.props.navigation.history.push(`EditTicket/${itemId}`);
  }

  handleDisableClear = () => {
    const { status, select_department, select_filterTYpe , selectedDepartmentId, searchQuery  } = this.state
    let allItems = { select_department, status, selectedDepartmentId, select_filterTYpe, query: searchQuery };
    const item = removeNullFromObj(allItems)
    return (Object.keys(item).length <= 0)
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForDepartmentListData(from, message)
    this.handleResponseForFilterListData(from, message)
    this.handleResponseForStatusListData(from, message)
    this.handleResponseForDeleteTicket(from, message)
    this.handleResponseForTicketType(from, message)
    this.handleResponseForAllTicketsList(from, message)
    // Customizable Area End
  }

}
