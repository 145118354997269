// Customizable Area Start
import React from "react";
import { Grid, Box, IconButton, Typography, InputLabel, TextField, Button, Checkbox, FormControlLabel, Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import FormController, { Props, configJSON } from "./FormController.web";
import { Wrapper } from "../../../components/src/Wrapper";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { Close, CheckBoxOutlineBlank, RadioButtonUnchecked } from '@material-ui/icons';
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import { singleValueCss } from "../../../components/src/utils/commonInputStyle";
import Loader from "../../../components/src/Loader";
import ConfirmationModalWeb from "../../../components/src/commonComponents/ConfirmationModal.web";
import { styles } from "./ChecklistForm.web";
import { attachIcon, closeIcon } from "../../../components/src/commonComponents/assets";
// Customizable Area End


// Customizable Area Start
// Customizable Area End

export class Form extends FormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { formData, branch_list, department_list,is_download, action, 
            openViewMenu, isOpen, loading, favorite, openDeleteModal, stateValue, fromLibrary,
         } = this.state

        const isValid = Boolean(formData.form_paragraphs_attributes.length || formData.form_single_answers_attributes.length || formData.form_checkboxes_attributes.length || formData.form_multiple_choices_attributes.length || formData.form_dropdowns_attributes.length || formData.form_linear_scales_attributes.length || formData.form_emails_attributes || formData.form_phone_numbers_attributes || formData.form_file_uploads_attributes || formData.form_link_urls_attributes) && Boolean(formData.title && formData.branch_id && formData.department_id)
        const create = () => (
            <>
                <Box py={1}>
                    <InputLabel data-test-id="create_title" className={classes.label}>{configJSON.title}</InputLabel>
                    <TextField
                        variant="outlined"
                        data-test-id="txtInputTitle"
                        value={formData.title}
                        onChange={(event) => this.setState({ formData: { ...this.state.formData, title: event.target.value } })}
                        placeholder="Enter Title"
                        className={classes.textfield}
                        fullWidth
                    />
                </Box>
                {formData.form_emails_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            key={`form_emails_attributes-${index}`}
                            data-test-id="form_emails_attributes-input"
                            variant="outlined"
                            value={item.text}
                            placeholder={`${configJSON.email}`}
                            onChange={event => this.handleParaChange(event, index, "form_emails_attributes")}
                            fullWidth
                            InputProps={{
                                endAdornment: <ActionButton
                                    deleteVisible
                                    isOpen={isOpen[`form_emails_attributes-${index}`]}
                                    handleDelete={() => { this.handleFieldDelete(index, "form_emails_attributes") }}
                                    toggleDropdown={() => this.toggleDropdownMenu("form_emails_attributes", index)}
                                    duplicate
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "form_emails_attributes")}
                                />
                            }}
                            className={classes.textfield}
                        />
                    </Box>
                ))}
                {formData.form_phone_numbers_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            data-test-id="form_phone_numbers_attributes-input"
                            placeholder={`${configJSON.phone}`}
                            value={item.text}
                            key={`form_phone_numbers_attributes-${index}`}
                            onChange={event => this.handleParaChange(event, index, "form_phone_numbers_attributes")}
                            fullWidth
                            className={classes.textfield}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <ActionButton
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "form_phone_numbers_attributes")}
                                    isOpen={isOpen[`form_phone_numbers_attributes-${index}`]}
                                    handleDelete={() => { this.handleFieldDelete(index, "form_phone_numbers_attributes") }}
                                    deleteVisible
                                    duplicate
                                    toggleDropdown={() => this.toggleDropdownMenu("form_phone_numbers_attributes", index)}
                                />
                            }}
                        />
                    </Box>
                ))}
                {formData.form_file_uploads_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            data-test-id="form_file_uploads_attributes-input"
                            placeholder={`${configJSON.fileUpload}`}
                            variant="outlined"
                            value={item.text}
                            key={`form_file_uploads_attributes-${index}`}
                            onChange={event => this.handleParaChange(event, index, "form_file_uploads_attributes")}
                            fullWidth
                            className={classes.textfield}
                            InputProps={{
                                endAdornment: <ActionButton
                                    isOpen={isOpen[`form_file_uploads_attributes-${index}`]}
                                    toggleDropdown={() => this.toggleDropdownMenu("form_file_uploads_attributes", index)}
                                    handleDelete={() => { this.handleFieldDelete(index, "form_file_uploads_attributes") }}
                                    duplicate
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "form_file_uploads_attributes")}
                                    deleteVisible
                                />
                            }}
                        />
                    </Box>
                ))}
                {formData.form_link_urls_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            key={`form_link_urls_attributes-${index}`}
                            data-test-id="form_link_urls_attributes-input"
                            placeholder={`${configJSON.linkUrl}`}
                            onChange={event => this.handleParaChange(event, index, "form_link_urls_attributes")}
                            variant="outlined"
                            className={classes.textfield}
                            InputProps={{
                                endAdornment: <ActionButton
                                    isOpen={isOpen[`form_link_urls_attributes-${index}`]}
                                    duplicate
                                    deleteVisible
                                    toggleDropdown={() => this.toggleDropdownMenu("form_link_urls_attributes", index)}
                                    handleDelete={() => { this.handleFieldDelete(index, "form_link_urls_attributes") }}
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "form_link_urls_attributes")}
                                />
                            }}
                            fullWidth
                            value={item.text}
                        />
                    </Box>
                ))}
                {formData.form_paragraphs_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            placeholder={`${configJSON.paragraph}`}
                            InputProps={{
                                endAdornment: <ActionButton
                                    deleteVisible
                                    handleDelete={() => { this.handleFieldDelete(index, "form_paragraphs_attributes") }}
                                    isOpen={isOpen[`form_paragraphs_attributes-${index}`]}
                                    duplicate
                                    toggleDropdown={() => this.toggleDropdownMenu("form_paragraphs_attributes", index)}
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "form_paragraphs_attributes")}
                                />
                            }}
                            key={`form_paragraphs_attributes-${index}`}
                            value={item.text}
                            data-test-id="form_paragraphs_attributes-input"
                            variant="outlined"
                            fullWidth
                            onChange={event => this.handleParaChange(event, index, "form_paragraphs_attributes")}
                            className={classes.textfield}
                        />
                    </Box>
                ))}
                {formData.form_single_answers_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            data-test-id="form_single_answers_attributes-input"
                            placeholder={`${configJSON.singleAnswer}`}
                            InputProps={{
                                endAdornment: <ActionButton
                                    deleteVisible
                                    handleDelete={() => { this.handleFieldDelete(index, "form_single_answers_attributes") }}
                                    isOpen={isOpen[`form_single_answers_attributes-${index}`]}
                                    duplicate
                                    toggleDropdown={() => this.toggleDropdownMenu("form_single_answers_attributes", index)}
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "form_single_answers_attributes")}
                                />
                            }}
                            onChange={event => this.handleParaChange(event, index, "form_single_answers_attributes")}
                            variant="outlined"
                            key={`form_single_answers_attributes-${index}`}
                            value={item.text}
                            className={classes.textfield}
                            fullWidth
                        />
                    </Box>
                ))}
                {formData.form_checkboxes_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            placeholder={`${configJSON.checkbox}`}
                            data-test-id="form_checkboxes_attributes-input"
                            InputProps={{
                                endAdornment: <ActionButton
                                    deleteVisible
                                    toggleDropdown={() => this.toggleDropdownMenu("form_checkboxes_attributes", index)}
                                    handleDelete={() => { this.handleFieldDelete(index, "form_checkboxes_attributes") }}
                                    isOpen={isOpen[`form_checkboxes_attributes-${index}`]}
                                    duplicate
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "form_checkboxes_attributes")}
                                />
                            }}
                            key={`form_checkboxes_attributes-${index}`}
                            variant="outlined"
                            className={classes.textfield}
                            onChange={event => this.handleOptionFieldTitleTextChange(event, index, "form_checkboxes_attributes")}
                            value={item.text}
                            fullWidth
                        />
                        {item.options_attributes.map((item, optionIndex) => (
                            <Box pt={1} key={`form_checkboxes_attributes-${index}-${optionIndex}`}>
                                <TextField
                                    placeholder={`${configJSON.option} ${optionIndex + 1}`}
                                    value={item.option}
                                    fullWidth
                                    onChange={(event) => this.handleOptionFieldOptionTextChange(event, index, optionIndex, "form_checkboxes_attributes")}
                                    InputProps={{
                                        startAdornment:<IconButton><CheckBoxOutlineBlank fontSize="small" /></IconButton>,
                                        endAdornment: <IconButton data-test-id="delete_checkbox_option" onClick={() => this.deleteOption(index, optionIndex, "form_checkboxes_attributes")}><Close fontSize="small" /></IconButton>
                                    }}
                                    data-test-id="form_checkboxes_attributes-option"
                                />
                            </Box>
                        ))}
                        <Box pt={2}>
                            <Button data-test-id="add_checkbox_option" size="large" onClick={() => this.addOptionField(index, "form_checkboxes_attributes")} className={classes.addOptionBtn}>{configJSON.addOption}</Button>
                        </Box>
                    </Box>
                ))}
                {formData.form_multiple_choices_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            key={`form_multiple_choices_attributes-${index}`}
                            data-test-id="form_multiple_choices_attributes-input"
                            placeholder={`${configJSON.multipleChoice}`}
                            onChange={event => this.handleOptionFieldTitleTextChange(event, index, "form_multiple_choices_attributes")}
                            variant="outlined"
                            value={item.text}
                            fullWidth
                            InputProps={{
                                endAdornment: <ActionButton
                                    deleteVisible
                                    isOpen={isOpen[`form_multiple_choices_attributes-${index}`]}
                                    handleDelete={() => { this.handleFieldDelete(index, "form_multiple_choices_attributes") }}
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "form_multiple_choices_attributes")}
                                    duplicate
                                    toggleDropdown={() => this.toggleDropdownMenu("form_multiple_choices_attributes", index)}
                                />
                            }}
                            className={classes.textfield}
                        />
                        {item.options_attributes.map((item, optionIndex) => (
                            <Box pt={1} key={`form_multiple_choices_attributes-${index}-${optionIndex}`}>
                                <TextField
                                    fullWidth
                                    onChange={(event) => this.handleOptionFieldOptionTextChange(event, index, optionIndex, "form_multiple_choices_attributes")}
                                    value={item.option}
                                    InputProps={{
                                        startAdornment: <IconButton><RadioButtonUnchecked fontSize="small" /></IconButton>,
                                        endAdornment: <IconButton data-test-id="delete_multichoice_option" onClick={() => this.deleteOption(index, optionIndex, "form_multiple_choices_attributes")}><Close fontSize="small" /></IconButton>,
                                    }}
                                    data-test-id="form_multiple_choices_attributes-option"
                                    placeholder={`${configJSON.option} ${optionIndex + 1}`}
                                />
                            </Box>
                        ))}
                        <Box pt={2}>
                            <Button data-test-id="add_multichoice_option" className={classes.addOptionBtn} size="large" onClick={() => this.addOptionField(index, "form_multiple_choices_attributes")}>{configJSON.addOption}</Button>
                        </Box>
                    </Box>
                ))}
                {formData.form_dropdowns_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            data-test-id="form_dropdowns_attributes-input"
                            placeholder={`${configJSON.dropdown}`}
                            onChange={event => this.handleOptionFieldTitleTextChange(event, index, "form_dropdowns_attributes")}
                            value={item.text}
                            fullWidth
                            variant="outlined"
                            className={classes.textfield}
                            key={`form_dropdowns_attributes-${index}`}
                            InputProps={{
                                endAdornment: <ActionButton
                                    handleDelete={() => { this.handleFieldDelete(index, "form_dropdowns_attributes") }}
                                    toggleDropdown={() => this.toggleDropdownMenu("form_dropdowns_attributes", index)}
                                    deleteVisible
                                    duplicate
                                    isOpen={isOpen[`form_dropdowns_attributes-${index}`]}
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "form_dropdowns_attributes")}
                                />
                            }}
                        />
                        {item.options_attributes.map((item, optionIndex) => (
                            <Box pt={1} key={`form_dropdowns_attributes-${index}-${optionIndex}`}>
                                <TextField
                                    fullWidth
                                    onChange={(event) => this.handleOptionFieldOptionTextChange(event, index, optionIndex, "form_dropdowns_attributes")}
                                    InputProps={{
                                        startAdornment: <IconButton><Typography>{`${optionIndex + 1}`}</Typography></IconButton>,
                                        endAdornment: <IconButton data-test-id="delete_dropdown_option" onClick={() => this.deleteOption(index, optionIndex, "form_dropdowns_attributes")}><Close fontSize="small" /></IconButton>
                                    }}
                                    value={item.option}
                                    data-test-id="form_dropdowns_attributes-option"
                                    placeholder={`${configJSON.dropdown} ${optionIndex + 1}`}
                                />
                            </Box>
                        ))}
                        <Box pt={2}>
                            <Button data-test-id="add_dropdown_option" className={classes.addOptionBtn} size="large" onClick={() => this.addOptionField(index, "form_dropdowns_attributes")}>{configJSON.addOption}</Button>
                        </Box>
                    </Box>
                ))}
                {formData.form_linear_scales_attributes?.map((item, index) => (
                    <Box py={1} key={item.id}>
                        <InputLabel className={classes.label}>{configJSON.insertText}</InputLabel>
                        <TextField
                            InputProps={{
                                endAdornment: <ActionButton
                                    deleteVisible
                                    duplicate
                                    isOpen={isOpen[`form_linear_scales_attributes-${index}`]}
                                    handleDelete={() => { this.handleFieldDelete(index, "form_linear_scales_attributes") }}
                                    toggleDropdown={() => this.toggleDropdownMenu("form_linear_scales_attributes", index)}
                                    handleDuplicate={() => this.handleFieldDuplicate(index, "form_linear_scales_attributes")}
                                />
                            }}
                            onChange={event => this.handleOptionFieldTitleTextChange(event, index, "form_linear_scales_attributes")}
                            data-test-id="form_linear_scales_attributes-input"
                            placeholder={`${configJSON.linearScale}`}
                            className={classes.textfield}
                            key={`form_linear_scales_attributes-${index}`}
                            value={item.text}
                            variant="outlined"
                            fullWidth
                        />
                        <Box pt={1}>
                            <Grid container>
                                <Grid item xs={2}>
                                    <DropdownSearch
                                        onChangeValue={(event: OptionType) => this.handleScaleChange(event, index, "lowest_value")}
                                        value={item.lowest_value}
                                        options={configJSON.scaleLowestItems}
                                        placeholder={configJSON.lowest}
                                        isSearchable={true}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Box textAlign={"center"} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}><Typography>to</Typography></Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <DropdownSearch
                                        onChangeValue={(event: OptionType) => this.handleScaleChange(event, index, "highest_value")}
                                        placeholder={configJSON.highest}
                                        options={configJSON.scaleHighestItems}
                                        value={item.highest_value}
                                        isSearchable={true}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box pt={1}>
                            <TextField
                                value={item.lowest}
                                placeholder={`${configJSON.scaleLabel}`}
                                fullWidth
                                data-test-id="scale_initial_title"
                                onChange={event => this.handleLinearFieldChange(event, index, "form_linear_scales_attributes", "lowest")}
                                InputProps={{
                                    startAdornment: <IconButton><Typography>1.</Typography></IconButton>,
                                }}
                            />
                        </Box>
                        <Box pt={1}>
                            <TextField
                                InputProps={{
                                    startAdornment: <IconButton><Typography>2.</Typography></IconButton>,
                                }}
                                data-test-id="scale_final_title"
                                onChange={event => this.handleLinearFieldChange(event, index, "form_linear_scales_attributes", "highest")}
                                placeholder={`${configJSON.scaleLabel}`}
                                fullWidth
                                value={item.highest}
                            />
                        </Box>
                    </Box>
                ))}
            </>
        )

        const view = () => (
            <>
                <Box py={2}>
                    <Typography className={classes.titleTxt}>{formData.title}</Typography>
                </Box>
                <Box py={2}>
                    <Typography className={classes.queLabel}>{configJSON.filledBy}</Typography>
                    <Box pt={1}>
                        <TextField
                            className={classes.textfield}
                            data-test-id = "filled_by"
                            variant="outlined"
                            fullWidth 
                            InputProps={{ readOnly: true }}
                            value={fromLibrary ? formData.filled_by : stateValue.creator.name}
                        />
                    </Box>
                </Box>
                {stateValue.form_email_answers_attributes?.map((item, index: number) => (
                    <Box py={2} key={item.id}>
                        <Typography data-test-id = "email_text" className={classes.queLabel}>{item.text}</Typography>
                        <Box pt={1}>
                            <TextField
                                className={classes.textfield}
                                variant="outlined"
                                data-test-id = "form_email_answers_attributes"
                                type="email"
                                fullWidth 
                                disabled={configJSON.view === action}
                                onChange={(event) => this.handleInputChange("form_email_answers_attributes", index, event.target.value)}
                                value={item.value}
                            />
                        </Box>
                    </Box>
                ))}
                {stateValue.form_phone_number_answers_attributes?.map((item, index: number) => (
                    <Box py={2} key={item.id}>
                        <Typography data-test-id = "phone_text" className={classes.queLabel}>{item.text}</Typography>
                        <Box pt={1}>
                            <Grid container >
                                <Grid item xs={3}>
                                    <Box width={"80%"} mt={"-10px"}>
                                        <DropdownSearch
                                            isDisabled={[configJSON.view].includes(action)}
                                            options={this.state.code_list}
                                            value={item.country_code_and_flag_id}
                                            onChangeValue={(event: { value: string; }) => this.handleInputChange("form_phone_number_answers_attributes", index, event.value, "country_code_and_flag_id")}
                                            id="country_code_and_flag_id"
                                            isSearchable={true}
                                            placeholder={""}
                                            noOptionsMessage={configJSON.noCountryCode}
                                            singleValueCss={(configJSON.view !== action || is_download) && singleValueCss}
                                            
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        className={classes.textfield}
                                        variant="outlined"
                                        type="number"
                                        data-test-id = "form_phone_number_answers_attributes"
                                        fullWidth 
                                        disabled={configJSON.view === action}
                                        onChange={(event) => this.handleInputChange("form_phone_number_answers_attributes", index, event.target.value, "phone_number")}
                                        value={item.phone_number}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                ))}
                {stateValue.form_file_upload_answers_attributes?.map((item, index: number) => (
                    <Box py={2} key={item.id}>
                        <Box className={`${classes.fieldWrapper} ${classes.fileInput}`}>
                            <Typography data-test-id="image_text" className={classes.queLabel}>
                                {item.text}
                            </Typography>
                            <TextField
                                name={item.text}
                                variant="outlined"
                                data-test-id = "form_file_upload_answers_attributes"
                                type="file"
                                disabled={configJSON.view === action}
                                className={classes.inputField}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const files = event.target.files
                                    if(files && files.length > 0){
                                        
                                        this.handleInputChange("form_file_upload_answers_attributes", index, files[0], "file_attach")}
                                    }
                                }

                            />
                            {item.file_url ?
                                <Box className={`label-block`} style={{ justifyContent: "space-between" }}>
                                    <Typography component="span" className="file-text file-name" id="upload-file-name">
                                        {item.file_url.name}
                                    </Typography>
                                    {action !== configJSON.view && <img className="close-icon" id="close-icon" src={closeIcon} alt="Close" onClick={() => this.handleInputChange("form_file_upload_answers_attributes", index, "", "file_attach")} />}
                                </Box>
                                :
                                <Box className={`label-block`}>
                                    <img src={attachIcon} alt="Attach" />
                                    <Typography component="span" className="file-text" id="upload-file-name">
                                        {configJSON.attachFileText}
                                    </Typography>
                                </Box>
                            }
                            {item.file_url && <Box className={`${classes.helperText} attachment`}><Typography target="_blank" component="a" href={item.file_url.url}>{configJSON.viewAttachmentText}</Typography></Box>}

                        </Box>
                    </Box>
                ))}
                {stateValue.form_link_url_answers_attributes?.map((item, index: number) => (
                    <Box py={2} key={item.id}>
                        <Typography className={classes.queLabel}>{item.text}</Typography>
                        <Box pt={1}>
                            <TextField
                                className={classes.textfield}
                                variant="outlined"
                                type="text"
                                data-test-id = "form_link_url_answers_attributes"
                                fullWidth
                                disabled={configJSON.view === action}
                                onChange={(event) => this.handleInputChange("form_link_url_answers_attributes", index, event.target.value)}
                                value={item.value}
                            />
                        </Box>
                    </Box>
                ))}
                {stateValue.form_paragraph_answers_attributes?.map((item, index: number) => (
                    <Box py={2} key={item.id}>
                        <Typography className={classes.queLabel}>{item.text}</Typography>
                        <Box pt={1}>
                            <TextField
                                fullWidth
                                data-test-id = "form_paragraph_answers_attributes"
                                disabled={configJSON.view === action}
                                multiline
                                id="myTextarea"
                                placeholder={!is_download && configJSON.paragraphAnswer}
                                onChange={(event) => this.handleInputChange("form_paragraph_answers_attributes", index, event.target.value)}
                                value={item.value}
                            />
                        </Box>
                    </Box>
                ))}

                {stateValue.form_single_answer_answers_attributes?.map((item, index: number) => (
                    <Box py={2} key={item.id}>
                        <Typography className={classes.queLabel}>{item.text}</Typography>
                        <Box pt={1}>
                            <TextField
                                fullWidth
                                disabled={configJSON.view === action}
                                data-test-id = "form_single_answer_answers_attributes"
                                placeholder={!is_download && configJSON.singleAnswer}
                                onChange={(event) => this.handleInputChange("form_single_answer_answers_attributes", index, event.target.value)}
                                value={item.value}
                            />
                        </Box>
                    </Box>
                ))}
                {stateValue.form_checkbox_answers_attributes?.map((checkbox, index: number) => (
                    <Box py={2} key={checkbox.id}>
                        <Typography className={classes.queLabel}>{checkbox.text}</Typography>
                        <Box pt={1}>
                            {checkbox.options_attributes?.map((option) => (
                                <Box className={classes.underLine} key={option.id}>
                                    <FormControlLabel
                                        key={option.id}
                                        data-test-id = "radio_form_control"
                                        control={
                                            <Checkbox
                                                data-test-id ="form_checkbox_answers_attributes"
                                                disabled={configJSON.view === action}
                                                checked={checkbox.selected_options?.includes(option.id)}
                                                onChange={() => this.handleInputChange("form_checkbox_answers_attributes", index, option.id, "multiple_checkbox")}
                                            />
                                        }
                                        label={option.option}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ))}
                {stateValue.form_multiple_choice_answers_attributes?.map((item, index: number) => (
                    <Box py={2} key={item.id}>
                        <Typography className={classes.queLabel}>{item.text}</Typography>
                        <Box pt={1}>
                            {item.options_attributes?.map((option) => (
                                <Box className={classes.underLine} key={option.id}>
                                    <FormControlLabel
                                        key={option.id}
                                        data-test-id = "multiple_checkbox"
                                        control={
                                            <Radio
                                            data-test-id ="form_multiple_choice_answers_attributes"
                                                disabled={configJSON.view === action}
                                                checked={item.selected_option === option.id}
                                                onChange={() => this.handleInputChange("form_multiple_choice_answers_attributes", index, option.id, "single_checkbox")}
                                            />
                                        }
                                        label={option.option}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ))}
                {stateValue.form_dropdown_answers_attributes?.map((item, index: number) => {
                    const options = item.options_attributes.map((option) => ({
                        label: option.option, value: option.id
                    }))
                    return (
                        <Box py={2} key={item.id}>
                            <Typography className={classes.queLabel}>{item.text}</Typography>
                            <Box maxWidth={"50%"}>
                                <DropdownSearch
                                    singleValueCss={(configJSON.view !== action || is_download) && singleValueCss}
                                    isDisabled={[configJSON.view].includes(action)}
                                    placeholder={configJSON.chooseOption}
                                    options={options}
                                    value={item.selected_option}
                                    onChangeValue={(event: { value: string; }) => this.handleInputChange("form_dropdown_answers_attributes", index, event.value, "single_checkbox")}
                                />
                            </Box>
                        </Box>
                    )
                })}
                {stateValue.form_linear_scale_answers_attributes?.map((item, index: number) => {
                    const options = Array.from({ length: (item.highest_value || 2) - (item.lowest_value || 0) + 1 }, (_, index) => item.lowest_value + index)
                    return (
                        <Box py={2} key={item.id}>
                            <Typography className={classes.queLabel}>{item.text}</Typography>
                            <Box display={"flex"} pt={1} alignItems={"flex-end"}>
                                <Typography>{item.lowest}</Typography>
                                <Box display={"flex"} px={1} ml={2}>
                                    {options?.map(option => (
                                        <FormControlLabel
                                            className={classes.flexDir}
                                            data-test-id = "linear_control"
                                            key={option}
                                            control={
                                                <Radio
                                                data-test-id ="form_linear_scale_answers_attributes"
                                                    disabled={configJSON.view === action}
                                                    checked={(item.value ? Number(item.value) : item.value) === option}
                                                    onChange={() => this.handleInputChange("form_linear_scale_answers_attributes", index, option)}
                                                />
                                            }
                                            label={option}
                                        />
                                    ))}
                                </Box>
                                <Typography>{item.highest}</Typography>
                            </Box>
                        </Box>
                    )
                })}
            </>
        )
        const shouldEdit = this.allowEditForm() ? (fromLibrary ? configJSON.editFormFields : configJSON.editFilledForm) : false;
        return (
            <React.Fragment>
                <Loader loading={loading} />
                <Wrapper
                    data-test-id="wrapper-form"
                    navigation={this.props.navigation}
                    backHandleClick={this.handleBackClick}
                    divider
                    backbtn={true}
                    title={this.formHeaderTitle()}
                    bellIcn={true}
                    homeIcn={true}
                    child={
                            <Grid container className={classes.mainContainer}>
                                <Grid item xs={10} ref={this.pdfContentRef} className={is_download ? classes.textColor : ""} >
                                    <Grid container spacing={1}>
                                        <Grid item sm={3}>
                                            <DropdownSearch
                                                id="branch_id"
                                                value={Number(formData.branch_id)}
                                                onChangeValue={(event: OptionType) => this.handleSelectChange(event, "branch_id", { department_id: "" })}
                                                options={branch_list}
                                                isSearchable={true}
                                                singleValueCss={(configJSON.view !== action || is_download) && singleValueCss}
                                                noOptionsMessage={configJSON.noBranchMessage}
                                                isDisabled={this.allowEditBranchDepart()}
                                                placeholder={configJSON.branchPlaceholder}
                                            />
                                        </Grid>
                                        <Grid item sm={3}>
                                            <DropdownSearch
                                                options={department_list}
                                                id="department_id"
                                                isDisabled={this.allowEditBranchDepart()}
                                                onChangeValue={(event: OptionType) => this.handleSelectChange(event, "department_id")}
                                                isSearchable={true}
                                                noOptionsMessage={configJSON.noDepartmentMessage}
                                                placeholder={configJSON.departSelect}
                                                singleValueCss={(configJSON.view !== action || is_download) && singleValueCss}
                                                value={formData.department_id}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid xs={8} item >
                                            {[configJSON.create, configJSON.edit, configJSON.duplicate].includes(action) ? create() : view()}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box className={classes.panelSection}>
                                        {![configJSON.view, configJSON.fill].includes(action) ?
                                            <Box className={classes.panelPos}>
                                                <Box className={classes.addPanel}>
                                                {this.addFormPanel.map((item: { src: string, title: string }) => (
                                                    <Box data-test-id="addpanel-form" className={classes.addPanelItems} onClick={() => this.handleAddFormPanelClick(item.title)}>
                                                        <img src={item.src} alt={item.title} />
                                                        <Typography className={classes.addPanelItemTxt}>{item.title}</Typography>
                                                    </Box>
                                                ))}
                                                </Box>
                                            </Box>
                                            :
                                            <ActionButton
                                                data-test-id="actionbtn-form_connect_for_me_to _take_open_action"
                                                isOpen={openViewMenu}
                                                toggleDropdown={this.toggleViewMenu}
                                                download
                                                handleDownload={this.handleDownloadPdf}
                                                mark_as_favourite={!!fromLibrary}
                                                handleFavourite={this.handleFavouriteClick}
                                                duplicate={!!fromLibrary && this.allowEditForm() } 
                                                handleDuplicate={this.handleDuplicate}
                                                favourite={favorite}
                                                deleteVisible ={this.allowEditForm()}
                                                handleDelete={this.handleOpenDeleteModal}
                                                edit={shouldEdit}
                                                handleEdit={this.handelEditForm}
                                            />
                                        }
                                    </Box>
                                </Grid>
                                {Boolean(action !== configJSON.view) &&
                                    <Grid item xs={12}>
                                        <Box className={classes.btnGroup}>
                                          <Button data-test-id="cancel-form" size="large" variant="outlined" className={classes.secondaryBtn} onClick={this.handleCancelForm}>{configJSON.cancel}</Button>
                                          <Button data-test-id="create-form" disabled={!isValid} size="large" className={classes.primaryBtn} onClick={this.handleSubmitForm}>{configJSON.save}</Button>
                                        </Box>
                                    </Grid>
                                }
                            </Grid>
                    }
                />
                {openDeleteModal &&
                    <ConfirmationModalWeb
                        data-test-id="delete-form"
                        open={openDeleteModal}
                        onClose={this.handleCloseDeleteModal}
                        confirmDelete={this.handleDeleteClick}
                        title={configJSON.formDeleteTitle}
                        from={fromLibrary ? configJSON.fromLibrary : configJSON.fromLogs}
                    />}
            </React.Fragment>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(Form);
// Customizable Area End
