// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleExpiredToken, handleApiError, apiCall, handleExcelDownload, downloadCSV, getNavigationMessage, checkToken, sortArrayAlphabetically, isAgent, isBO, userBranchId, isManager, usersDepartments, downloadPDF, removeNullFromObj, capitalizeFirstLetter, handleUndefinedData } from "../../../components/src/utils/commonFunctions";
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: Record<string,string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  select_department: string | null;
  is_open_filter: boolean;
  is_open_download: boolean;
  department_list: DepartmentList[];
  selectedDepartmentId: number | null;
  select_filterTYpe: string;
  status: string | null;
  select_downloadTYpe: string | null;
  is_open_department: boolean;
  meta: {
    total_pages: number;
    next_page: null | number;
    previous_page: null | number;
    total_count: number;
    current_page: number;
  },
  isCreatedActive: boolean;
  title: string;
  task_id: string | number;
  assignStaus: DepartmentList[];
  searchQuery: string;
  isAssignedActive: boolean;
  isManageActive: boolean;
  created_header_list: string[];
  task_list: Task[];
  statusList: DepartmentList[];
  open: boolean;
  loading: boolean;
  isAgent: boolean;
  isBO: boolean;
  isManager: boolean;
  all_task_list: Array<{ [key: string]: string | number | boolean }>
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
// Customizable Area Start
export interface Task {
  id: string;
  type: string;
  attributes: {
    id: number;
    task_id: string;
    title: string;
    description: string;
    priority: string;
    due_date: string;
    status: string;
    completion_date: string;
    feedback: string;
    rating: string;
    account_id: number;
    assign_date: string;
    attachment: {
      name: string | null;
      url: string | null;
    };
    status_name: string;
    issue_date: string;
    creator: {
      id: number;
      email: string;
      name: string | null;
      designation: string;
    };
    assignee_ids: {
      id: number;
      email: string;
      name: string;
      designation: string;
    },
  };
}

export interface TaskListForPDF {
  id: string;
  type: string;
  attributes: {
    id: number;
    task_id: string;
    title: string;
    status: string;
    status_name: string;
    due_date: string;
    issue_date: string;
    creator: {
      id: number;
      email: string;
      name: string | null;
      designation: string;
    };
    assignee_ids: {
      id: number;
      email: string;
      name: string;
      designation: string;
    },
  };
}
export interface DepartmentList {
  id: number;
  text: string;
  name: string;
}
// Customizable Area End

export default class TaskListViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  departmentDataApiCallId: string = "";
  filterTaskListCallId: string = "";
  deleteTaskApiCallId: string = "";
  statusDataListApiCallId: string = "";
  allTaskListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      // Customizable Area Start
      select_department: "",
      selectedDepartmentId: null,
      select_filterTYpe: "",
      status: "",
      statusList: [],
      assignStaus: [],
      select_downloadTYpe: "",
      is_open_department: false,
      is_open_download: false,
      is_open_filter: false,
      department_list: [],
      isCreatedActive: !isAgent() ?? true,
      isAssignedActive: isAgent() ?? false,
      created_header_list: configJSON.headerList,
      meta: {
        total_pages: 1,
        total_count: 1,
        current_page: 1,
        next_page: null,
        previous_page: null
      },
      task_list: [

      ],
      searchQuery: "",
      open: false,
      title: "",
      task_id: "",
      isManageActive: false,
      loading: false,
      isAgent: isAgent(),
      isBO: isBO(),
      isManager: isManager(),
      all_task_list: []
      // Customizable Area End
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    checkToken()
    this.getDepartmentData()
    this.filterTaskList(this.state.meta.current_page)
    this.getStatusDataList()
    this.getAllTaskList()
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.selectedDepartmentId !== prevState.selectedDepartmentId
      || this.state.status !== prevState.status
      || this.state.isAssignedActive !== prevState.isAssignedActive
      || this.state.isCreatedActive !== prevState.isCreatedActive
      || this.state.isManageActive !== prevState.isManageActive
      || this.state.searchQuery !== prevState.searchQuery

    ) {
      this.filterTaskList(this.state.meta.current_page)
      this.getAllTaskList()
    }
  }
  onClickCreateTask = () => {
    this.send(getNavigationMessage('CreateTask', this.props));
  }
  handleTitle = () => {
    const { isAssignedActive, isAgent, isCreatedActive } = this.state
    let title;
    if (isAssignedActive) {
      if (isAgent) {
        title = configJSON.myTaskText;
      } else {
        title = configJSON.taskTitleAssign;
      }
    } else {
      title = isCreatedActive ? configJSON.taskTitleCreated : configJSON.taskTitleManage;
    }
    return title
  }
  handleSearch = (query: string) => {
    this.setState({ searchQuery: query })
  }
  handlePageChange = (pageNumber: number) => {
    this.setState({
      meta: {
        ...this.state.meta,
        current_page: pageNumber
      }
    });
    this.filterTaskList(pageNumber)
  };
  handleClearFilter = () => {
    this.setState({
      status: "",
      select_department: "",
      select_filterTYpe: "",
      select_downloadTYpe: "",
      selectedDepartmentId: null,
      searchQuery: "",
      is_open_department: false,
      is_open_download: false,
      is_open_filter: false
    })
  }
  handleBackClick = () => {
    this.props.navigation.goBack();
  }
  handleSelectDepart = (value: DepartmentList) => {
    this.setState({
      select_department: value.name,
      is_open_department: false,
      selectedDepartmentId: value.id,
    });
  };
  handleSelectFilter = (value: DepartmentList) => {
    this.setState({
      select_filterTYpe: value.name,
      status: value.text,
      is_open_filter: false
    });
  };
  toggleSelectDepart = () => {
    this.setState((prevState) => ({
      is_open_department: !prevState.is_open_department,
      is_open_filter: false,
      is_open_download: false

    }));
  };
  toggleSelectFilter = () => {
    this.setState((prevState) => ({
      is_open_filter: !prevState.is_open_filter,
      is_open_download: false,
      is_open_department: false,

    }));
  };
  handleSelectDownload = (value: DepartmentList) => {
    this.setState({
      select_downloadTYpe: value.name,
      is_open_download: true
    });
  };
  handleExportClick = () => {
    const { select_downloadTYpe, created_header_list, all_task_list } = this.state
    if (select_downloadTYpe === configJSON.downloadType[2].name) {
      downloadPDF(all_task_list, created_header_list, "Task List", "Task_List")
      this.setState({ is_open_download: false, select_downloadTYpe: "" })
    } else if (select_downloadTYpe === configJSON.downloadType[0].name) {
      handleExcelDownload(created_header_list, all_task_list, "task-list")
      this.setState({ is_open_download: false, select_downloadTYpe: "" })
    } else {
      downloadCSV(created_header_list, all_task_list, "task-list")
      this.setState({ is_open_download: false, select_downloadTYpe: "" })
    }

  }

  toggleSelectDownloadModal = () => {
    this.setState((prevState) => ({
      is_open_download: !prevState.is_open_download,
      is_open_department: false,
      is_open_filter: false,
    }));
  };
  handleButtonClick = (buttonNumber: number) => {
    if (buttonNumber === 1) {
      this.setState({
        isCreatedActive: true,
        isAssignedActive: false,
        isManageActive: false,
        is_open_department: false,
        is_open_filter: false,
        is_open_download: false
      });
    } else if (buttonNumber === 2) {
      this.setState({
        isManageActive: true,
        isCreatedActive: false,
        isAssignedActive: false,
        is_open_department: false,
        is_open_filter: false,
        is_open_download: false
      });
    }
    else {
      this.setState({
        isCreatedActive: false,
        isAssignedActive: true,
        isManageActive: false,
        is_open_department: false,
        is_open_filter: false,
        is_open_download: false
      });
    }
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleDelete = (item: Task) => {
    this.setState({ open: true, title: item.attributes.title, task_id: item.id });
  };
  handleDeleteTask = () => {
    this.deleteTaskApiCallId = apiCall({ method: configJSON.deleteMethodType, endPoint: `${configJSON.taskListApiEndPoint}/${this.state.task_id}`, token: true, });

  }
  getStatusDataList = () => {
    this.statusDataListApiCallId = apiCall({
       method: configJSON.validationApiMethodType, 
       endPoint: configJSON.statusListApiEndPoint, 
       token: true
      });
  }

  getDepartmentData = () => {
    this.departmentDataApiCallId = apiCall({ 
      method: configJSON.validationApiMethodType, 
      endPoint: isBO() ? configJSON.departmentListApiEndpoint : `${configJSON.selectedDepartApiEndpoint}/${userBranchId}`, 
      token: true
    });
  }
   tasklistParams = ():string => {
    let params = "";
    if (this.state.isAssignedActive) {
      params += '&type=assigned';
    } else if (this.state.isCreatedActive) {
      params += '&type=created';
    } else if (this.state.isManageActive) {
      params += '&type=manage';
    }
    if (this.state.selectedDepartmentId) {
      if (params) {
        params += '&';
      }
      params += `department_id=${this.state.selectedDepartmentId}`;
    }
    if (this.state.searchQuery) {
      if (params) {
        params += '&';
      }
      params += `query=${this.state.searchQuery}`;
    }
    if (this.state.status) {
      if (params) {
        params += '&';
      }
      params += `status=${this.state.status}`;
    }

    return params
  }
  getAllTaskList = () => {
    const baseEndpoint = configJSON.allTaskListApiEndPoint
    let params = this.tasklistParams();
 
    const endpoint = params ? `${baseEndpoint}${params}` : baseEndpoint;

    this.allTaskListApiCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: endpoint,
      token: true
    });
  }
  filterTaskList = (page: number) => {
    this.setState({ loading: true })
    const baseEndpoint = configJSON.ticketFilterListApiEndPoint + page;

    let queryParam = this.tasklistParams();

    const endpoint = queryParam ? `${baseEndpoint}${queryParam}` : baseEndpoint;

    this.filterTaskListCallId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: endpoint,
      token: true,
    });
  }

  handleDepartmentResponse = (from: string, message: Message) => {
    if (this.departmentDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(responseData, this.props.navigation);
      if (responseData) {
        let departmentData;
        if (isBO()) {
          departmentData = responseData.map((item: { departments: { id: string, name: string } }) => item.departments).flat().map((item: { id: string; name: string; }) => {
            return {
              id: item.id,
              name: item.name,
              text: item.name
            }
          })
        } else {
          departmentData = usersDepartments.map((deprt: { attributes: { name: string; id: string } }) => {
            return {
              id: deprt.attributes.id,
              name: deprt.attributes.name,
              text: deprt.attributes.name,
            }
          })
        }
        const sortedData = sortArrayAlphabetically(departmentData, "name")
        this.setState({
          department_list: sortedData,
        })

      } else {
        handleApiError(responseData.errors);
      }

    }
  }


  handleStatusDataListResponse = (from: string, message: Message) => {
    if (this.statusDataListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(response, this.props.navigation);
      if (response.data) {
        const filteredData = response.data.filter((item: { name: string; }) => item.name === "Pending" || item.name === "Completed");
        const allStatus = [...filteredData, { id: 6, name: "All", text: "" }]
        const assignData = response.data.filter((item: { name: string; }) => item.name === "Pending" || item.name === "Completed" || (!isBO() && item.name === "Submit for Review")).map((item: { name: string; }) => item.name === "Submit for Review" ? {...item, name: configJSON.inReview} : item);

        this.setState({
          statusList: allStatus,
          assignStaus: assignData
        })

      } else {
        handleApiError(response.errors);
      }

    }
  }
  handleResponseForFilterTaskListData = (from: string, message: Message) => {
    if (this.filterTaskListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.tasks) {
        this.setState((prev) => ({
          loading: false,
          task_list: apiResponse.tasks.data,
          meta: apiResponse.meta ?? prev.meta,
        }))

      } else {
        this.setState({ loading: true })
        handleApiError(apiResponse.errors);
      }

    }
  }
  handleResponseForAllTaskList = (from: string, message: Message) => {
    if (this.allTaskListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.tasks) {
        const result = apiResponse.tasks.data.map((item: TaskListForPDF) => [
          item.attributes.task_id,
          capitalizeFirstLetter(item.attributes.title),
          `${this.state.isAssignedActive ? handleUndefinedData(item.attributes.creator.name, "") : handleUndefinedData(item.attributes.assignee_ids.name, "")}`,
          `${this.state.isAssignedActive ? item.attributes.creator.designation : item.attributes.assignee_ids.designation}`,
          moment(item.attributes.issue_date).format(configJSON.dateFormat),
          item.attributes.due_date ? moment(item.attributes.due_date).format(configJSON.dateFormat) : "",
          item.attributes.status_name === "Submit for Review" ? configJSON.inReview : item.attributes.status_name
        ])
        this.setState({
          all_task_list: result,
        })

      } else {
        this.setState({ all_task_list: [] })
      }

    }
  }
  handleDeleteResponse = (from: string, message: Message) => {
    if (this.deleteTaskApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(response, this.props.navigation);
      if (response.message) {
        toast.success(`${response.message}`)
        this.getAllTaskList();
        this.filterTaskList(this.state.meta.current_page)
        this.setState({ open: false })
      } else {
        this.setState({ open: false })
        handleApiError(response.errors);

      }

    }
  }

  redirectEditTask = (pathId: string) => {
    this.props.navigation.history.push(`EditTask/${pathId}`);
  }

  handleDisable = () => {
    const { select_department, status, select_filterTYpe , selectedDepartmentId, searchQuery,  } = this.state
    let allData = { select_department, status, selectedDepartmentId, select_filterTYpe, query: searchQuery };
    const dataItem = removeNullFromObj(allData)
    return (Object.keys(dataItem).length <= 0)
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleDepartmentResponse(from, message)
    this.handleResponseForFilterTaskListData(from, message)
    this.handleDeleteResponse(from, message)
    this.handleStatusDataListResponse(from, message)
    this.handleResponseForAllTaskList(from, message)
    // Customizable Area End
  }

}
