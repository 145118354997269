// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ClassNameMap } from "@material-ui/styles";
import { toast } from "react-toastify";
import { handleApiError, handleExpiredToken, checkToken, getDiffs, handleUndefinedData, getUserRoleID } from "../../../components/src/utils/commonFunctions";
import { CreateTemplate4Schema, EditTemplate4Schema } from "../../../components/src/utils/validationSchema";
import CommonTemplateController from "./CommonTemplateController.web";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"formContainer" | "inputField"
    | "fieldWrapper" | "inputLabel" | "helperText" | "textAreaField" | "textAreaInputField" | "templateBtnWrapper"
    | "dateField" | "fileInput" | "primaryBtn" | "secondaryBtn" | "inputLabel2" | "approvalContainer" | "dropdownWrapper" | "imageUploadContainer" | "titleText" | "gridItem" | "templateFooterContainer"
  >;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class Template4Controller extends CommonTemplateController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      ...this.state,
      ...this.initialState
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let res = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(res, this.props.navigation);
      this.setState({ loading: false });
      if (requestCallId && res) {
        this.setState({ loading: false });
        switch (requestCallId) {
          case this.createTemplateApiId:
            this.addTemplate4Response(res, configJSON.procedureSuccessMessage);
            break;
          case this.duplicateTemplateApiId:
            this.addTemplate4Response(res, configJSON.procedureDuplicateMessage);
            break;
          case this.deleteTemplateApiId:
            this.deleteTemplateResponse(res);
            break;
          case this.branchDataApiId:
            this.getBranchesResponse(res);
            break;
          case this.departmentsApiId:
            this.getDepartmentsResponse(res);
            break;
          case this.favouriteTemplateApiId:
            const toastMessage = this.state.formData.favorite ? configJSON.procedureUnFavMessage : configJSON.procedureFavMessage;
            this.addTemplate4Response(res, toastMessage);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  componentDidMount(): any {
    // Customizable Area Start
    checkToken();

    this.getBranchData();
    let { state } = this.props.navigation.history.location;
    if (state) {
      this.setDefaultData(state)
    } else if(![2,3].includes(Number(getUserRoleID()))) {
      this.props.navigation.goBack();
    }
    // Customizable Area End
  }

  setDefaultData(item: any, action?: string) {
    const { creator, attachment, descriptions_attributes, id } = item
    this.setState({
      id,
      formData: item,
      defaultData: item,
      attachment: attachment.url ? attachment : "",
      imageUrls: {
        image1: handleUndefinedData(descriptions_attributes[0].images[0], ""),
        image2: handleUndefinedData(descriptions_attributes[1].images[0], ""),
        image3: handleUndefinedData(descriptions_attributes[2].images[0], ""),
        image4: handleUndefinedData(descriptions_attributes[3].images[0], ""),
        image5: handleUndefinedData(descriptions_attributes[4].images[0], ""),
        image6: handleUndefinedData(descriptions_attributes[5].images[0], ""),
        image7: handleUndefinedData(descriptions_attributes[6].images[0], ""),
        image8: handleUndefinedData(descriptions_attributes[7].images[0], ""),
      },
      creator,
      templateAction: action ?? configJSON.view
    })
  }

  template4InitialValues = () => {
    const { defaultData, formData, templateAction } = this.state;
    const isCreateTemplate = templateAction === configJSON.create;
    const data = {
      title: isCreateTemplate ? defaultData.title : handleUndefinedData(formData?.title, ""),
      department_id: isCreateTemplate ? defaultData.department_id : handleUndefinedData(formData?.department_id, ""),
      branch_id: isCreateTemplate ? defaultData.branch_id : handleUndefinedData(formData?.branch_id, ""),
      attachment: null,
      send_for_approval: isCreateTemplate ? defaultData.send_for_approval : handleUndefinedData(formData?.send_for_approval, false),
      description1: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[0]?.description, ""),
      description2: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[1]?.description, ""),
      description3: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[2]?.description, ""),
      description4: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[3]?.description, ""),
      description5: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[4]?.description, ""),
      description6: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[5]?.description, ""),
      description7: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[6]?.description, ""),
      description8: isCreateTemplate ? "" : handleUndefinedData(formData?.descriptions_attributes[7]?.description, ""),
      image1: null,
      image2: null,
      image3: null,
      image4: null,
      image5: null,
      image6: null,
      image7: null,
      image8: null,
    }
    return data;
  }

  isTemplate6 = () => {
    const { template_id } = this.state;
    return template_id === 6;
  }

  mdItemsWidth = () => {
    return this.isTemplate6() ? 6 : 4;
  }

  lgItemsWidth = () => {
    return this.isTemplate6() ? 6 : 3;
  }
  
  template4ValidationSchema = () => {
    const { templateAction } = this.state;
    return templateAction === configJSON.edit ? EditTemplate4Schema : CreateTemplate4Schema
  }

  addEditTemplate4 = (values: any) => {
    const { formData, defaultData, template_id } = this.state;

    const { title, send_for_approval, attachment, branch_id, department_id, descriptions_attributes } = defaultData;
    const { description1, description2, description3, description4, description5, description6, description7, description8, image1, image2, image3, image4, image5, image6, image7, image8 } = values;

    const { id } = formData;

    const createDescriptionsAttr = [
      {
        description: description1,
        images: [image1]
      }, {
        description: description2,
        images: [image2]
      },
      {
        description: description3,
        images: [image3]
      }, {
        description: description4,
        images: [image4]
      },
      {
        description: description5,
        images: [image5]
      }, {
        description: description6,
        images: [image6]
      },
      {
        description: description7,
        images: [image7]
      }, {
        description: description8,
        images: [image8]
      },
    ];

    const editDescriptionsAttr = () => {
      return [
        {
          id: this.state.formData.descriptions_attributes[0].id,
          description: description1,
          ...(image1 ? { images: [image1] } : {}),
        }, {
          id: this.state.formData.descriptions_attributes[1].id,
          description: description2,
          ...(image2 ? { images: [image2] } : {}),
        },
        {
          id: this.state.formData.descriptions_attributes[2].id,
          description: description3,
          ...(image3 ? { images: [image3] } : {}),
        }, {
          id: this.state.formData.descriptions_attributes[3].id,
          description: description4,
          ...(image4 ? { images: [image4] } : {}),
        },
        {
          id: this.state.formData.descriptions_attributes[4].id,
          description: description5,
          ...(image5 ? { images: [image5] } : {}),
        }, {
          id: this.state.formData.descriptions_attributes[5].id,
          description: description6,
          ...(image6 ? { images: [image6] } : {}),
        },
        {
          id: this.state.formData.descriptions_attributes[6].id,
          description: description7,
          ...(image7 ? { images: [image7] } : {}),
        }, {
          id: this.state.formData.descriptions_attributes[7].id,
          description: description8,
          ...(image8 ? { images: [image8] } : {}),
        },
      ];
    }
    const orriginalData4: any = {
      title,
      branch_id,
      department_id,
      send_for_approval,
      ...(attachment ? { attachment } : {}),
      descriptions_attributes
    };

    const changedData4: any = {
      title: values.title,
      branch_id: values.branch_id,
      department_id: values.department_id,
      send_for_approval: values.send_for_approval,
      ...(values.attachment ? { attachment: values.attachment } : this.checkEmptyAttachment()),
      ...(!id ? { template_id } : {}),
      descriptions_attributes: id ? editDescriptionsAttr() : createDescriptionsAttr,
    };

    let differenceData4: any = getDiffs(orriginalData4, changedData4);
    this.addEditTemplateApiCall(differenceData4)
  }

  addTemplate4Response = (responseData4: any, message: string) => {
    if (responseData4.data) {
      if (this.state.id) {
        const dataItem4 = responseData4.data.attributes
        this.setStateData(dataItem4);
        this.setDefaultData(dataItem4)
      } else {
        this.setState({ ...this.initialState });
        this.navigateToListingPage()
      }
      this.handleActions(configJSON.view)
      toast.success(message);
    } else if (responseData4.message) {
      const {defaultData} = this.state
      const dataItem4 = {...defaultData, favorite: !defaultData.favorite};
      this.setStateData(dataItem4);
      this.setDefaultData(dataItem4)
      toast.success(message);
    } else {
      handleApiError(responseData4.errors);
    }
  };
  // Customizable Area End
  // Customizable Area End
}
