import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core/styles";
import Template17Controller, { Props, configJSON } from "./Template17Controller.web"
import { commonInputField, commonButton, singleValueCss, commonTemplateCss } from "../../../components/src/utils/commonInputStyle";
import { Formik, Form } from 'formik';
import { Typography, Box, InputLabel, TextField, Button, Grid, Switch } from "@material-ui/core";
import { Wrapper } from "../../../components/src/Wrapper";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import { EditTemplate17Schema } from "../../../components/src/utils/validationSchema";
import AttachFileInput from "../../../components/src/commonComponents/AttachFileInput.web";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import DropdownSearch from "../../../components/src/commonComponents/DropdownSearch.web";
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonTemplateCss,
  ...commonButton,
  ...commonInputField

});
// Customizable Area End

export class Template17 extends Template17Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const {isDownload, attachmentError, formData, attachment, loading, branch_data, department_data, templateAction, openMenu, opeDeleteModal } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper backbtn={true}  title={this.headerTitle()} navigation={this.props.navigation} divider bellIcn={true} homeIcn={true} child={
          <Box>
            <Formik
              enableReinitialize={true}
              initialValues={this.template17InitialValues()}
              validateOnBlur={true}
              validationSchema={EditTemplate17Schema}
              onSubmit={this.addEditTemplate17}
            >
              {({ errors,handleChange, touched, handleBlur,  setFieldValue, values }) => (
                <Form translate className={`${isDownload && "textColor"} ${classes.formContainer}`}>
                  <Grid direction="row" alignItems="stretch"  className={classes.gridContainer} ref={this.pdfContentRef} container spacing={5}>
                    <Grid  sm={5}  item xs={10}className={classes.gridItem}>
                      {this.isViewOnly() ?
                        <Typography variant="h4" data-test-id="title" className={`${classes.inputLabel2} title`}>
                          {values.title}
                        </Typography> :
                        <Box className={`${classes.fieldWrapper} marginTitle`}>
                          <InputLabel className={`${classes.inputLabel2} labetText`} required>
                            {configJSON.templateTitleLabel}
                          </InputLabel>
                          <TextField
                            type="text"
                            data-test-id="title_input_field"
                            onChange={handleChange}
                            name={configJSON.templateTitleName}
                            value={values.title}
                            fullWidth
                            variant="outlined"
                            className={classes.inputField}
                            onBlur={handleBlur}
                            helperText={handleInvoiceHelperText(
                              errors.title,
                              touched.title
                            )}
                            error={handleInvoiceErrors(
                              errors.title,
                              touched.title,
                            )}
                          />
                        </Box>
                      }

                    </Grid>
                    <Grid item sm={7} xs={2} className={`${classes.gridItem} actionBtn ${isDownload && "visibility"}`}>
                      {templateAction !== configJSON.create &&
                        <ActionButton
                        edit={this.allowEdit()}
                        deleteVisible={this.allowEdit()}
                        toggleDropdown={this.toggleMenu}
                          download
                          handleEdit={() => this.handleActions(configJSON.edit)}
                          isOpen={openMenu}
                          duplicate={this.allowEdit()}
                          mark_as_favourite
                          favourite={formData.favorite}
                          handleFavourite={this.handleFavouriteClick}
                          handleDelete={this.handleDelete}
                          handleDownload={this.handleDownloadPdf}
                          handleDuplicate={this.handleDuplicateTemplate}
                        />
                      }

                    </Grid>
                    <Grid item className={classes.gridItem} xs={12}>
                      <div className={classes.dropdownContainer}>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            singleValueCss={isDownload && singleValueCss}
                            options={branch_data}
                            value={values.branch_id}
                            isSearchable={true}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                            id={configJSON.templateBranchName}
                            isDisabled={this.isViewOnly()}
                            placeholder={configJSON.templateBranchPlaceholder}
                            isMyProfile={true}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            noOptionsMessage={configJSON.templateNoBranchMessage}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                            )}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            value={values.department_id}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                            singleValueCss={isDownload && singleValueCss}
                            options={department_data}
                            isSearchable={true}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            noOptionsMessage={configJSON.templateNoDepartmentMessage}
                            errorText={handleInvoiceHelperText(
                              errors.department_id,
                              touched.department_id,
                              )}
                              placeholder={configJSON.templateDepartmentPlaceholder}
                              id={configJSON.templateDepartmentName}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id,
                            )}
                            isMyProfile={true}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid item className={classes.gridItem} sm={6}>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          type="text"
                          name={configJSON.description1}
                          value={values.description1}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                          disabled={this.isViewOnly()}
                          error={handleInvoiceErrors(
                            errors.description1,
                            touched.description1
                          )}
                          className={classes.inputField}
                          onBlur={handleBlur}
                          helperText={handleInvoiceHelperText(
                            errors.description1,
                            touched.description1
                          )}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel required className={classes.inputLabel}>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          onChange={handleChange}
                          name={configJSON.description2}
                          value={values.description2}
                          variant="outlined"
                          disabled={this.isViewOnly()}
                          type="text"
                          fullWidth
                          helperText={handleInvoiceHelperText(
                            errors.description2,
                            touched.description2
                          )}
                          className={classes.inputField}
                          error={handleInvoiceErrors(
                            errors.description2,
                            touched.description2,
                          )}
                          onBlur={handleBlur}
                        />
                      </Box>
                     
                    </Grid>
                    <Grid item sm={6} className={`${classes.gridItem}`}>
                    <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          type="text"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.description3}
                          fullWidth
                          name={configJSON.description3}
                          error={handleInvoiceErrors(
                            errors.description3,
                            touched.description3
                            )}
                            disabled={this.isViewOnly()}
                          className={classes.inputField}
                          helperText={handleInvoiceHelperText(
                            errors.description3,
                            touched.description3
                          )}
                          onBlur={handleBlur}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          name={configJSON.description4}
                          type="text"
                          disabled={this.isViewOnly()}
                          onChange={handleChange}
                          value={values.description4}
                          fullWidth
                          helperText={handleInvoiceHelperText(
                            errors.description4,
                            touched.description4,
                          )}
                          className={classes.inputField}
                          error={handleInvoiceErrors(
                            errors.description4,
                            touched.description4
                          )}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={6} className={`${classes.gridItem}`}>
                    <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          type="text"
                          variant="outlined"
                          value={values.description5}
                          onChange={handleChange}
                          name={configJSON.description5}
                          fullWidth
                          disabled={this.isViewOnly()}
                          error={handleInvoiceErrors(
                            errors.description5,
                            touched.description5
                          )}
                          helperText={handleInvoiceHelperText(
                            errors.description5,
                            touched.description5
                          )}
                          className={classes.inputField}
                          onBlur={handleBlur}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          name={configJSON.description6}
                          type="text"
                          disabled={this.isViewOnly()}
                          onChange={handleChange}
                          value={values.description6}
                          fullWidth
                          className={classes.inputField}
                          error={handleInvoiceErrors(
                            errors.description6,
                            touched.description6
                          )}
                          helperText={handleInvoiceHelperText(
                            errors.description6,
                            touched.description6,
                            )}
                            onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                    <Grid item className={`${classes.gridItem}`} sm={6}>
                    <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          type="text"
                          variant="outlined"
                          value={values.description7}
                          fullWidth
                          className={classes.inputField}
                          onChange={handleChange}
                          disabled={this.isViewOnly()}
                          name={configJSON.description7}
                          error={handleInvoiceErrors(
                            errors.description7,
                            touched.description7
                          )}
                          onBlur={handleBlur}
                          helperText={handleInvoiceHelperText(
                            errors.description7,
                            touched.description7
                          )}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          name={configJSON.description8}
                          error={handleInvoiceErrors(
                            errors.description8,
                            touched.description8
                          )}
                          type="text"
                          value={values.description8}
                          disabled={this.isViewOnly()}
                          fullWidth
                          className={classes.inputField}
                          onChange={handleChange}
                          helperText={handleInvoiceHelperText(
                            errors.description8,
                            touched.description8,
                            )}
                            onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={classes.templateFooterContainer}>
                    <Box className={classes.approvalContainer}>
                      <Typography className={`${classes.inputLabel2} approvalText`} variant="h4">
                        {configJSON.sendForApproval}
                      </Typography>
                      <Switch
                        onChange={handleChange}
                        name="send_for_approval"
                        disabled={this.state.templateAction !== configJSON.create}
                        color="primary"
                        checked={values.send_for_approval}
                      />
                    </Box>
                    <AttachFileInput
                      attachment={attachment}
                      attachmentError={attachmentError}
                      isViewOnly={this.isViewOnly}
                      clearAttachment={this.clearAttachment.bind(null, setFieldValue)}
                      templateAction={templateAction}
                      setFieldValue={setFieldValue}
                      handleFileUpload={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(e, setFieldValue) }}
                    />
                  </Box>
                  <Box className={classes.templateBtnWrapper}>
                    {!this.isViewOnly() &&
                      <Button
                      variant="contained"
                      className={classes.primaryBtn}
                      type="submit"
                        data-test-id="create-btn"
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              title={configJSON.templateDeleteTitle}
              open={opeDeleteModal}
              from={configJSON.procedureFrom}
              confirmDelete={this.handleDeleteTemplate}
              onClose={this.handleDeleteClose}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(Template17);
// Customizable Area End

