Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cftemplatebuilder6";
exports.labelBodyText = "cftemplatebuilder6 Body";

exports.btnExampleTitle = "CLICK ME";

exports.TemplateNavigation = "/Template";
exports.ProductTempNavigation = "/ProductTemplate";

exports.create = "Create";
exports.view = "View";
exports.edit = "Edit";
exports.duplicate = "Duplicate";


exports.headerTitle = "Procedure Template";
exports.text1Label = "Text1";
exports.descriptionLabel = "Description";

exports.description1 = "description1";
exports.description2 = "description2";
exports.description3 = "description3";
exports.description4 = "description4";
exports.description5 = "description5";
exports.description6 = "description6";
exports.description7 = "description7";
exports.description8 = "description8";
exports.description9 = "description9";
exports.description10 = "description10";
exports.description11 = "description11";
exports.description12 = "description12";

exports.image1 = "image1";
exports.image2 = "image2";
exports.image3 = "image3";
exports.image4 = "image4";
exports.image5 = "image5";
exports.image6 = "image6";
exports.image7 = "image7";
exports.image8 = "image8";
exports.image9 = "image9";
exports.image10 = "image10";
exports.image11 = "image11";
exports.image12 = "image12";

exports.uploadImageText = ["Upload Image"];

exports.templateTitleLabel = "Title";
exports.templateTitleName = "title";
exports.templateDescriptionName = "description";

exports.attachmentTitle = "Related Document";
exports.attachmentName = "attachment";

exports.templateBranchLabel = "Branch";
exports.templateBranchName = "branch_id";
exports.templateBranchPlaceholder = "Select Branch";
exports.templateNoBranchMessage = "No Branch Found"

exports.templateDepartmentLabel = "Department";
exports.templateDepartmentName = "department_id";
exports.templateDepartmentPlaceholder = "Select Department";
exports.templateNoDepartmentMessage = "No Department Found"

exports.viewAttachmentText = "View Attachment";
exports.attachFileText= "Attach File";
exports.fileTypeErrorMessage = "File type should be PDF, CSV, DOC, JPEG, or PNG" ;
exports.imagerrorMessage = "Only JPG, JPEG, or PNG image are allowed" ;

exports.sendForApproval = "Send For Approval";
exports.saveBtnText = "Save";

exports.getMethod = "GET";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.getBranchesUrl = "bx_block_branch/branches";
exports.deparmentDataApiEndPoint = "bx_block_departments/selected_departments/";
exports.templateUrl = "bx_block_cftabletview/procedures";

exports.templateDeleteTitle = "Procedure Template";
exports.procedureFrom = "from the procedures";

exports.procedureSuccessMessage = "Procedure has been saved successfully";
exports.procedureDuplicateMessage = "Procedure has been duplicated successfully";
exports.productFavMessage = "Product info marked as favorite";
exports.productUnFavMessage = "Product info unmarked as favorite";
exports.procedureFavMessage = "Procedure marked as favorite";
exports.procedureUnFavMessage = "Procedure unmarked as favorite";
exports.menuFavMessage = "Menu marked as favorite";
exports.menuUnFavMessage = "Menu unmarked as favorite";
exports.procedureDeleteSuccessMessage = "Procedure has been deleted successfully";
exports.doText = "Do";
exports.doNotText= "Don't"

// Product Template

exports.productTemplateUrl = "bx_block_cftemplatebuilder6/product_infos";
exports.getCategoriesUrl = "bx_block_cftemplatebuilder6/get_product_info_type";

exports.productSuccessMessage = "Product catalogue has been saved successfully";
exports.productDuplicateMessage = "Product catalogue has been duplicated successfully";
exports.productDeleteSuccessMessage = "Product catalogue has been deleted successfully";

exports.productHeaderTitle = "Product Catalogue Template";

exports.productTempCategoryLabel = "Category";
exports.productTempCategoryName = "product_info_type_id";
exports.productTempCategoryPlaceholder = "Select Category";
exports.productTempNoCategoryMessage = "No Category Found"

exports.productTempNameLabel = "Name";
exports.productTempNameName = "name";

exports.faqLabel = "FAQ";
exports.faqName = "faq";

exports.usdLabel = "USD";
exports.usdName = "usd";

exports.itemCodeLabel = "Item code";
exports.itemCodeName = "item_code";

exports.pairWithLabel = "Pair with";
exports.pair_with1 = "pair_with1";
exports.pair_with2 = "pair_with2";
exports.pair_with3 = "pair_with3";

exports.productTempDeleteTitle = "Product Template";
exports.productFrom = "from the product catalogues";

// Menu Template

exports.menuTemplateUrl = "bx_block_cftemplatebuilder6/menus";
exports.getMenuCategoriesUrl = "bx_block_cftemplatebuilder6/menus/get_menu_type";

exports.MenuTempNavigation = "/MenuTemplate";

exports.menuSuccessMessage = "Menu template has been saved successfully";
exports.menuDuplicateMessage = "Menu template has been duplicated successfully";
exports.menuDeleteSuccessMessage = "Menu template has been deleted successfully";

exports.menuHeaderTitle = "Menu Template";

exports.menuTempDeleteTitle = "Menu Template";
exports.menuFrom = "from the menus";

exports.menuTempCategoryLabel = "Category";
exports.menuTempCategoryName = "menu_type_id";
exports.menuTempCategoryPlaceholder = "Select Category";
exports.menuTempNoCategoryMessage = "No Category Found";

exports.menuTempNameLabel = "Name";
exports.menuTempNameName = "name";

exports.recipeCodeLabel = "Recipe code";
exports.recipeCodeName = "recipe_code";

exports.vegLabel = "Veg";
exports.vegName = "veg";

exports.gfLabel = "GF";
exports.gfName = "gf";

exports.kLabel = "K";
exports.kName = "k";

exports.addOnLabel = "Add On";
exports.add_on1 = "add_on1";
exports.add_on2 = "add_on2";
exports.add_on3 = "add_on3";

exports.removeFavorite = "/remove_from_favorite";
exports.addFavorite = "/favorite";
// Customizable Area End