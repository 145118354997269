export const clipboard = require("../assets/clipboard.png")
export const filterImage = require("../assets/filter.png");
export const exportImage = require("../assets/export_arrow.png");
export const noTicketImage = require("../assets/noTicketImg.png");
export const inprogress = require("../assets/inprogress.png");
export const completed = require("../assets/completed.png");
export const pending = require("../assets/pending.png");
export const reopen = require("../assets/reopened.png");
export const resolve = require("../assets/resolved.png");
export const edit = require("../assets/edit.png");
export const duplicate = require("../assets/duplicate.png");
export const deleted = require("../assets/delete.png")
