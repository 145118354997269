import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import moment from "moment";
import { apiCall, checkToken, handleApiError, handleExpiredToken, isBO, isManager, loggedUserId, removeNullFromObj, sortArrayAlphabetically, userBranchId, usersDepartments } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  // Customizable Area Start
 classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  value: number;
  openMenu: null | HTMLElement;
  gridView: boolean;
  date: DateData;
  searchQuery: string;
  menuAnchorEl:HTMLElement | null,
  loading:boolean;
  formData:FormValues;
  branch_list: Lists[];
  department_list: Lists[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;

  // Customizable Area End
}

// Customizable Area Start
export interface DateData {
  from_date: null | string;
  to_date: null | string;
  time_range: null | string;
}
interface FormValues {
  branch_id: string;
  department_id: string;
}
interface BranchData {
  id: string;
  attributes: {
      branch_name: string;
      location_of_point_of_sale: string;
  }
}

interface ResponseDepartment {
  id: string;
  departments: {
      id: string;
      name: string;
  }[]
}
interface ResponseBranchData {
  branches: {
      data: BranchData[]
  }
}

interface ResponseErrorData {
  errors: string[]
}

interface Lists {
  id?: number,
  value: string;
  label: string;
}
// Customizable Area End

export default class LibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBranchesApiId: string = ""
  getDepaApiId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    const initialData = {
      branch_id: isManager() ? String(userBranchId) : "",
      department_id: "",
  }
    this.state = {
      value: 0,
      openMenu: null,
      gridView: true,
      date: {
        from_date: null,
        to_date: null,
        time_range: null,
      },
      searchQuery: "",
      menuAnchorEl: null,
      loading:false,
      formData: initialData,
      department_list: [],
      branch_list: [],
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resJson, this.props.navigation);
      this.setState({ loading: false });

      if (apiCallId && resJson) {
          if (apiCallId === this.getBranchesApiId) {
              this.getBranchesResponse(resJson);
          } else if (apiCallId === this.getDepaApiId) {
              this.getDepartmentResponse(resJson);
          } 
      }
  }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    checkToken()
    if(isBO() || isManager()){
      this.getBranch()
    }
    isManager() && this.getDepartment()
    let { state } = this.props.navigation.history.location;
    if (state) {
      this.setState({ value: state });
    }
  }
  componentDidUpdate(prevProps: Props, previousState: S) {
    if (this.state.formData.branch_id !== previousState.formData.branch_id && this.state.formData.branch_id) {
        this.setState({ loading: true, department_list: [], formData: { department_id: "", branch_id: this.state.formData.branch_id } });
        this.getDepartment()
    }
    if (this.state.formData.branch_id !== previousState.formData.branch_id) {
        this.setState({department_list: [] });
    }
}
  handleTabMenuOpen = (event: { currentTarget:HTMLElement | null; }) => {
    this.setState({ menuAnchorEl: event.currentTarget });
  };

  handleClearAll = () => {
    if(isBO()) {
    this.setState({
      formData: { branch_id: "", department_id: "" },
      searchQuery: "",
      date: { time_range: null, to_date: null, from_date: null }
    });
  }else {
    this.setState(prev => ({
      formData: { branch_id: prev.formData.branch_id, department_id: "" },
      searchQuery: "",
      date: { time_range: null, to_date: null, from_date: null }
     }))
    }
  }
  
  handleDisable = () => {
    const {branch_id,department_id } = this.state.formData
    let allBOData = {...this.state.date, branch_id, department_id, query: this.state.searchQuery};
    let allManagerData = {...this.state.date, department_id, query: this.state.searchQuery};
    const dataForBO = removeNullFromObj(allBOData)
    const dataForManager = removeNullFromObj(allManagerData)
    if(isBO()){
      return (Object.keys(dataForBO).length <= 0)
    }else {
      return (Object.keys(dataForManager).length <= 0)
    }
  }
  handleTabMenuClose = () => {
    this.setState({ menuAnchorEl: null });
  };
  handleProductMenu = (value: number) => {
    if(isManager()){
      this.setState((previous) => ({ value,menuAnchorEl: null, gridView: previous.gridView, date: { from_date: null, to_date: null, time_range: null, },formData: { branch_id: previous.formData.branch_id, department_id: "" },searchQuery:"" }));
    }else {
      this.setState((previous) => ({ value,menuAnchorEl: null, gridView: previous.gridView, date: { from_date: null, to_date: null, time_range: null, },searchQuery:"",formData: { branch_id: "", department_id: "" }, }));
    }
  };
  handleBackClick = () => {
    this.props.navigation.goBack();
  }
  getBranch = () => {
    this.setState({ loading: true })
    this.getBranchesApiId = apiCall({ method: configJSON.getMethod, endPoint: configJSON.getBranchApiEndpoint, token: true });
}

getDepartment = () => {
    this.setState({ loading: true })
    this.getDepaApiId = apiCall({ method: configJSON.getMethod, endPoint: `${configJSON.getDeptApiEndpoint}${this.state.formData.branch_id}`, token: true })
}

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const target = event?.target as HTMLElement;
    if (target && !target.closest('.MuiIconButton-root')) {
      if(isManager()){
        this.setState((previous) => ({ value: newValue, gridView: previous.gridView, date: { from_date: null, to_date: null, time_range: null, },searchQuery:"",formData: { branch_id: previous.formData.branch_id, department_id: "" } }));
      }else {
        this.setState((previous) => ({ value: newValue, gridView: previous.gridView, date: { from_date: null, to_date: null, time_range: null, },searchQuery:"",formData: { branch_id: "", department_id: "" }, }));
      }
    }
};

  handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ openMenu: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ openMenu: null });
  };

  toggleView = () => {
    this.setState(previous => ({ gridView: !previous.gridView }))
  }

  handleDateChange = (event: string | number | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | null | undefined, keyValue: keyof DateData) => {
    const isDate = moment(event).format("MM/DD/YYYY")
    this.setState({
      date: {
        ...this.state.date,
        [keyValue]: isDate,
        time_range: "custom",
      }
    })
    if ((this.state.date.from_date && keyValue === "to_date") || (this.state.date.to_date && keyValue === "from_date")) {
      this.handleMenuClose()
    }
  }

  handleDayChange = (time_range: string) => {
    this.setState({ date: { time_range: time_range, from_date: null, to_date: null } })
    this.handleMenuClose()
  }

  handleSearch = (query: string) => {
    this.setState({ searchQuery: query })
  }

  allowEditDelete = (creatorId: number | string | undefined) => {
    return !!(creatorId === loggedUserId || isBO());
  }

  getBranchesResponse = (response: ResponseBranchData & ResponseErrorData) => {
    if (response.branches.data) {
        const defaultItem = { value: "", label: configJSON.branchPlaceholder, isDisabled: true };
        let branchData = response.branches.data.map(item => ({ value: item.id, label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }));
        branchData = [defaultItem, ...branchData]
        let filteredBranch = response.branches.data.filter((branch: { id: string; }) => branch.id === String(userBranchId)).map(item => ({ value: item.id, label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }));
        if (isBO()) {
            this.setState({
                branch_list: branchData
            })
        } else {
            this.setState({
                branch_list: filteredBranch
            })
        }

    } else {
        handleApiError(response.errors);

    }
}

handleSelectChange = (event: OptionType, keyValue: keyof FormValues, clearValue?:Partial<FormValues>| undefined) => {
  let finalData: OptionType | string = event?.value;
  if (clearValue && (this.state.formData[keyValue] !== finalData)) {
      this.setState({ formData: { ...this.state.formData, [keyValue]: finalData, ...clearValue } });
  } else {
      this.setState({ formData: { ...this.state.formData, [keyValue]: finalData } });
  }
};
getDepartmentResponse = (response: ResponseDepartment[] & ResponseErrorData) => {
    if (response.length > 0) {
        let finalDapartsData: Lists[] = [];
        const defaultItem = { value: "", label: configJSON.departSelect, isDisabled: true };
      if(isBO()){
        response.forEach((item) => {
            item.departments.forEach((department) => {
                const { id, name } = department;
                finalDapartsData.push({ label: name, value: id });
            })
        })
      }else {
        usersDepartments.forEach((department:{attributes:{name:string;id:string}}) => {
            finalDapartsData.push({ label: department.attributes.name, value: department.attributes.id });
        })
      }
        finalDapartsData = sortArrayAlphabetically(finalDapartsData, 'label');
        finalDapartsData = [defaultItem, ...finalDapartsData]
        this.setState({ department_list: finalDapartsData });
    } else if (response.errors) {
        this.setState({ department_list: [] });
        handleApiError(response.errors)
    } else {
        this.setState({ department_list: [] });
        toast.error(configJSON.departError)
    }
}

  // Customizable Area End
}



