import React from "react";
// Customizable Area Start
import { Typography, Box, Button } from "@material-ui/core";
import { Wrapper } from "../../../components/src/Wrapper";
import CommonCarousel from "../../../components/src/commonComponents/CommonCarousel.web";
import { withStyles, createStyles } from "@material-ui/core/styles";
import {  commonButton } from "../../../components/src/utils/commonInputStyle";

import CarouselDisplayController, {
  Props,
  configJSON,
} from "./CarouselDisplayController";
import { colors } from "../../../components/src/utils/commonDesign";
import Loader from "../../../components/src/Loader";
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonButton,
  headerSection:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    margin:"20px 0",
    "& h2":{
      fontSize:"24px",
      fontWeight:700
    }
  },
  btnGroup: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gridGap: "25px",
    marginRight:18,
    "& button": {
      cursor: "pointer",
      backgroundColor: "transparent",
      outline: "none",
      fontSize: "1rem",
      border: "none",
      textTransform: "unset",
      color: "#020202",
      fontWeight: 700,
      padding: "5px 10px",
    },
    "& .active": {
      borderRadius: "8px",
      border: `2px solid ${colors.secondaryColor2}`,
    },
  },

});
// Customizable Area End

export class DisplayCarousel extends CarouselDisplayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { classes } = this.props;
    return (
      <>
        <Loader loading={this.state.loading} />
        <Wrapper
          data-test-id="wrapper-comp"
          navigation={this.props.navigation}
          backbtn={true}
          divider
          title={configJSON.headerTitle}
          child={
            <>
              <Box className={classes.headerSection}>
                <Typography data-test-id = "title" component="h2">{configJSON.navTitle}</Typography>
                <Box className={classes.btnGroup}>
                  <Button data-test-id="all_button" disableRipple className={this.state.isAll ? "active" : ""} onClick={() => this.handleButtonClick(1)}>
                    {configJSON.allText} 
                  </Button>
                  <Button data-test-id="images_button" disableRipple className={this.state.isWithImage ? "active" : ""} onClick={() => this.handleButtonClick(2)}>
                    {configJSON.withImage}
                  </Button>
                  <Button data-test-id="withoutImage_button" disableRipple className={`${this.state.isWithEmpty ? "active" : ""} `} onClick={() => this.handleButtonClick(3)}>
                    {configJSON.withoutImage}
                  </Button>
                </Box>
                
              </Box>
              <Box style={{ padding: "60px 0", position: "relative" }}>
                  <CommonCarousel
                    handleNavigate={this.handleNavigate}
                    data={this.state.cimages_list}
                  />
                </Box>
            </>
          }
        />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(DisplayCarousel);
// Customizable Area End

