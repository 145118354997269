export const themeColors = [
  {
    primary_colour: '#cd2e2d',
    secondary_colour: '#154977',
    background_colour: '#f1eeea',
  },
  {
    primary_colour: '#966041',
    secondary_colour: '#ab5c5c',
    background_colour: '#f1eeea',
  },
  {
    primary_colour: '#0b66b0',
    secondary_colour: '#779378',
    background_colour: '#f1eeea',
  },
  {
    primary_colour: '#e7a9c6',
    secondary_colour: '#584a4a',
    background_colour: '#f1eeea',
  },
  {
    primary_colour: '#c21718',
    secondary_colour: '#ffcf02',
    background_colour: '#f1eeea',
  },
  {
    primary_colour: '#134b6d',
    secondary_colour: '#92b3bf',
    background_colour: '#f1eeea',
  },
  {
    primary_colour: '#3c7a43',
    secondary_colour: '#9466a8',
    background_colour: '#f1eeea',
  },
  {
    primary_colour: '#db7b12',
    secondary_colour: '#704f21',
    background_colour: '#f1eeea',
  },
  {
    primary_colour: '#743a8f',
    secondary_colour: '#cd93c1',
    background_colour: '#f1eeea',
  },
  {
    primary_colour: '#404a9a',
    secondary_colour: '#8dc044',
    background_colour: '#f1eeea',
  },
  {
    primary_colour: '#f39322',
    secondary_colour: '#ee7aaa',
    background_colour: '#f1eeea',
  },
  {
    primary_colour: '#e83f3f',
    secondary_colour: '#0b66b0',
    background_colour: '#f1eeea',
  },
];

const createIcon = require("../../../components/src/assets/create_Icons.png");
const librayIcon = require("../../../components/src/assets/library_Icons.png");
const favouritesIcon = require("../../../components/src/assets/favourites_Icons.png");
const taskManagerIcon = require("../../../components/src/assets/task_manager_Icons.png");
const ticketsIcon = require("../../../components/src/assets/tickets_Icons.png");
const chatsIcon = require("../../../components/src/assets/chats_Icons.png");
const userLogsIcon = require("../../../components/src/assets/user_logs_Icons.png");
const dashboardIcon = require("../../../components/src/assets/dashboard_Icons.png");
const procedureIcon = require("../../../components/src/assets/procedure.png");
const formIcon = require("../../../components/src/assets/formImg.png");
const checklistIcon = require("../../../components/src/assets/checklist.png");
const productIcon = require("../../../components/src/assets/product.png");
const cookBookIcon = require("../../../components/src/assets/cook_book.png");

export const HomePageContent = [
  {
    name: "Create",
    photo: createIcon,
    path: "CreatePage",
    allowedUsers: [2,3]
  },
  {
    name: "Library",
    photo: librayIcon,
    path: "Library",
    allowedUsers: [2,3,4,5]
  },
  {
    name: "Favourites",
    photo: favouritesIcon,
    path: "Favourite",
    allowedUsers: [2,3,4,5]
  },
  {
    name: "Task Manager",
    photo: taskManagerIcon,
    path: "TaskListView",
    allowedUsers: [2,3,4,5]
  },
  {
    name: "Tickets",
    photo: ticketsIcon,
    path: "TicketTableView",
    allowedUsers: [2,3,4,5]
  },
  {
    name: "Chats",
    photo: chatsIcon,
    path: "",
    allowedUsers: [2,3,4,5]
  },
  {
    name: "User Logs",
    photo: userLogsIcon,
    path: "UserLogsListing/my_logs/submitted_form",
    allowedUsers: [2,3,4,5]
  },
  {
    name: "Dashboard",
    photo: dashboardIcon,
    path: "",
    allowedUsers: [2,3]
  },
]
export const createPageContent = [
  {
    name: "Procedure",
    photo: procedureIcon,
    path: "DisplayCarousel",
  },
  {
    name: "Forms",
    photo: formIcon,
    path: "Form",
  },
  {
    name: "Checklist",
    photo: checklistIcon,
    path: "ChecklistForm",
  },
  {
    name: "Product info",
    photo: productIcon,
    path: "ProductInfo", 
  },
  {
    name: "Cook book",
    photo: cookBookIcon,
    path: "",
  }

]

export const ProductInfoContent = [
  {
    name: "Menu",
    photo: createIcon,
    path: "MenuCarousel",
  },
  {
    name: " Product Catalogue",
    photo: createIcon,
    path: "ProductCarousel",
  },
]

export const Priorites = [
  {
    label: "P1 High",
    value: "P1_High",
  },
  {
    label: "P2 Medium",
    value: "P2_Medium",
  },
  {
    label: "P3 Low",
    value: "P3_Low",
  },
]

export const statusList = [
  {
    id:1,
    name: "Pending",
    text: "pending",
  },
  {
    id:2,
    name: "Active",
    text: "active",
  },
  {
    id:3,
    name: "Inactive",
    text: "inactive",
  },
]


export const GenderList = [
  {
    id:1,
    label: "Male",
    value: "male",
  },
  {
    id:2,
    label: "Female",
    value: "female",
  },
  {
    id:3,
    label: "Other",
    value: "other",
  },
]

export const selectAllOption = { label: "Select All", value: "select_all"}