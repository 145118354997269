import React, { Component, RefObject } from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { colors } from "../utils/commonDesign";
import {
  Box,
  Typography,
} from "@material-ui/core";

const arrowLeft = require("../assets/arrow-left.png");
const arrowRight = require("../assets/arrow-right.png");

interface Props {
  classes?: any;
  items?: any;
  selectedItems?: string[];
  handleSelect: (item: any) => void;
}

const styles: any = (theme: Theme) => createStyles({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    "& .previousNextButton": {
      height: "32px",
      width: "32px",
      cursor: "pointer",
      border: `1px solid ${colors.primaryColor2}`,
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        border: `1px solid ${colors.secondaryColor2}`,
        backgroundColor: colors.secondaryColor2,
      },
    },
    "& .departContainer": {
      display: "flex",
      gap: "25px",
      width: "fit-content",
      "& .selected": {
        border: `3px solid ${colors.secondaryColor2} !important`,
      },
      "& .boxCard": {
        height: 170,
        width: 170,
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "16px",
        cursor: "pointer",
        border: `3px solid white`,
        "&:hover": {
          border: `3px solid ${colors.secondaryColor2}`,
        },
        "& .innerBoxCard": {
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "center",
          "& .imgContainer img": {
            height: "auto",
            width: "auto",
            maxWidth: "130px",
            maxHeight: "90px",
            objectFit: "contain",
          },
          "& .depName": {
            color: colors.text,
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
            margin: 0,
            textAlign: "center",
          },
        },
      },
    },
  },
});


class Slider extends Component<Props> {
  scrollContainerRef: RefObject<HTMLDivElement>;
  isDragging: boolean;
  startScrollX: number;

  constructor(props: Props) {
    super(props);

    this.scrollContainerRef = React.createRef();
    this.isDragging = false;
    this.startScrollX = 0;
  }


  handlePrevClick = () => {
    const scrollAmount = 150;
    const scrollContainer = this.scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.scrollLeft -= scrollAmount;
    }
  };

  handleNextClick = () => {
    const scrollAmount = 150;
    const scrollContainer = this.scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.scrollLeft += scrollAmount;
    }
  };

  handleMouseDown = (event: React.MouseEvent) => {
    this.isDragging = true;
    this.startScrollX = event.clientX;
  };

  handleMouseMove = (event: React.MouseEvent) => {
    if (this.isDragging && this.scrollContainerRef.current) {
      const scrollAmount = event.clientX - this.startScrollX;
      this.scrollContainerRef.current.scrollLeft -= scrollAmount;
      this.startScrollX = event.clientX;
    }
  };

  handleMouseUp = () => {
    this.isDragging = false;
  };

  render() {
    const { classes, items, selectedItems, handleSelect } = this.props;
    items.sort(function (a: any, b: any) {
      return a.id - b.id;
    });
  
    const showArrows = items.length >= 6
  
    return (
      <div className={classes.mainContainer}>
        {showArrows && (
          <div className="previousNextButton" onClick={this.handlePrevClick}>
            <img src={arrowLeft} alt="PreviousArrow" />
          </div>
        )}
        <div
          ref={this.scrollContainerRef}
          style={{ minWidth: '100px', overflow: 'hidden', whiteSpace: 'nowrap' }}
          onMouseDown={this.handleMouseDown}
          onMouseMove={this.handleMouseMove}
          onMouseUp={this.handleMouseUp}
        >
          <Box className="departContainer">
            {items.map((item: any) => (
              <Box className={`boxCard ${selectedItems?.includes(item.id) ? "selected" : ""}`} onClick={() => handleSelect(item)} key={item.id}>
                <div className="innerBoxCard">
                  <div className="imgContainer">
                    {item.photo && <img src={item.photo} alt="Image" />}
                  </div>
                  <Typography variant="h4" className={"depName"}>
                    {item.name}
                  </Typography>
                </div>
              </Box>
            ))}
          </Box>
        </div>
        {showArrows && (
          <div className="previousNextButton" onClick={this.handleNextClick}>
            <img src={arrowRight} alt="NextArrow" />
          </div>
        )}
      </div>
    );
  }
  
}

export default withStyles(styles)(Slider);
