// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, capitalizeFirstLetter, filterNullObj, handleApiError, handleExpiredToken, isBO, isManager } from "../../../components/src/utils/commonFunctions";
import { Message } from "../../../framework/src/Message";
import { ClassNameMap } from "@material-ui/styles";
import moment from "moment";
import { toast } from "react-toastify";

import { DateData } from "./LibraryController.web";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    classes: ClassNameMap<"listContainer" | "cardWrapper" | "templateImg" | "procedureTitleTxt"
        | "dateTxt" | "favouriteIcn" | "menuImg" | "menuTxt" | "menuPopper" | "departSelect"
        | "listItem" | "tableHeader" | "listMain" | "tableItem" | "imgContainer" | "svgIcon" |
        "noTicketContainer" | "deleteModalDesc" | "btn" | "btn1" | "noTicketOuterContainer" | "paginationBox">;
    gridView: boolean;
    date: DateData;
    searchQuery: string;
    allowEditDelete: (creatorId: number | string | undefined) => void;
    formData: FormValues
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    product_list: ProductData[];
    isOpen: { [key: string]: boolean };
    anchorEl: HTMLElement | null;
    search_product_list: ProductData[] | null;
    product_id: string;
    header_list: string[];
    openDeleteModal: boolean;
    meta: {
        total_count: number;
        current_page: number;
        previous_page: null | number;
        total_pages: number;
        next_page: null | number;
    },
    isBO: boolean;
    isManager: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;

    // Customizable Area End
}
// Customizable Area Start
interface ResponseErrorData {
    errors: string[]
}
interface FormValues {
    branch_id: string;
    department_id: string;
}

interface Responseproduct {
    product_infos: {
        data: {
            attributes: {
                id: string;
                template_id: number;
                name: string;
                favorite: boolean;
                created_at: string;
                product_catalogue_template: {
                    data: {
                        attributes: {
                            template_image: {
                                name: string;
                                url: string;
                            }
                        }
                    }
                }
            }
        }[]
    };
    meta: {
        total_count: number;
        current_page: number;
        total_pages: number;
        previous_page: number | null;
        next_page: number | null;
    }
}

interface ProductData {
    attributes: {
        id: string;
        name: string;
        template_id: number;
        created_at: string;
        creator?: ProductCreator;
        favorite: boolean;
        product_catalogue_template: {
            data: {
                attributes: {
                    template_image: {
                        name: string;
                        url: string;
                    }
                }
            }
        }
    }
}
export interface ProductCreator {
    id: number | string;
    name: string;
    designation: string;
    email: string
}
// Customizable Area End

export default class ProductTemplateListingController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getProductListApiId: string = ""
    favouriteproductApiId: string = ""
    deleteproductApiId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];


        this.state = {
            loading: false,
            product_list: [],
            anchorEl: null,
            product_id: "",
            header_list: configJSON.productHeaderList,
            search_product_list: null,
            isOpen: {},
            openDeleteModal: false,
            meta: {
                total_count: 1,
                total_pages: 1,
                current_page: 1,
                previous_page: null,
                next_page: null
            },
            isBO: isBO(),
            isManager: isManager()
        }
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount()
        const { date: { from_date, to_date, time_range }, searchQuery, formData } = this.props
        this.setState({ loading: true })
        this.getproducts(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
        // Customizable Area End
    }
    // Customizable Area Start
    componentDidUpdate(prevProps: Props, previousState: S) {

        const { date: { from_date, to_date, time_range }, searchQuery, formData } = this.props
        if ((prevProps.date.time_range !== this.props.date.time_range) || (prevProps.date.from_date !== this.props.date.from_date) || (prevProps.date.to_date !== this.props.date.to_date) || formData.branch_id !== prevProps.formData.branch_id || formData.department_id !== prevProps.formData.department_id) {
            if (from_date && to_date) {
                this.setState({ loading: true })

                this.getproducts(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
            }
            if (time_range !== "custom") {
                this.setState({ loading: true })
                this.getFilteredproducts(from_date, to_date, time_range, searchQuery)
            }
        }
        if (prevProps.gridView !== this.props.gridView) {
            this.closeDropDown()
        }
        if (prevProps.searchQuery !== this.props.searchQuery) {
            this.setState({ loading: true })
            this.getproducts(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
        }
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let resJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            handleExpiredToken(resJson, this.props.navigation);
            this.setState({ loading: false });

            if (apiCallId && resJson) {
                if (apiCallId === this.getProductListApiId) {
                    this.getproductsResponse(resJson);
                } else if (apiCallId === this.deleteproductApiId) {
                    this.handleDeleteApiResponse(resJson);
                } else if (apiCallId === this.favouriteproductApiId) {
                    this.handleFavouriteResponse(resJson);
                }
            }
        }
    }

    getproducts = (page: number, from_date: string | null, to_date: string | null, time_range: string | null, department_id: string, query?: string) => {
      this.setState({ loading: true })  
      let endPointfiter: string = `${configJSON.productTemplateListApiEndPoint}`
        const { branch_id } = this.props.formData
        let allData = { page, custom_start_date: from_date ? moment(from_date).format("DD/MM/YYYY") : "", custom_end_date: to_date ? moment(to_date).format("DD/MM/YYYY") : "", time_range, branch_id, department_id, query };

        this.getProductListApiId = apiCall({ method: configJSON.getMethod, endPoint: endPointfiter + filterNullObj(allData), token: true })
    }

    getFilteredproducts = (from_date: string | null, to_date: string | null, time_range: string | null, searchQuery: string = '') => {
        const department_id = this.props.formData.department_id
        switch (time_range) {
            case configJSON.today:
                this.getproducts(this.state.meta.current_page, from_date, to_date, "today", department_id, searchQuery)
                break
            case configJSON.yesterday:
                this.getproducts(this.state.meta.current_page, from_date, to_date, "yesterday", department_id, searchQuery)
                break
            case configJSON.lastDays:
                this.getproducts(this.state.meta.current_page, from_date, to_date, "15_days", department_id, searchQuery)
                break
            default:
                this.getproducts(this.state.meta.current_page, from_date, to_date, "", department_id, searchQuery)
                break
        }
    }

    getproductsResponse = (response: Responseproduct & ResponseErrorData) => {
        this.closeDropDown()
        if (response.errors) {
            this.setState({ loading: false })
            handleApiError(response.errors)
        } else {
            this.setState(previous => ({ loading: false, product_list: response.product_infos.data, meta: response.meta ?? previous.meta }))
        }
    }

    getFormattedDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString().split(",")[0]
    }

    handleDeleteClick = () => {
        this.setState({ loading: true })
        this.deleteproductApiId = apiCall({ method: configJSON.deleteMethod, endPoint: `${configJSON.productTempApiEndPoint}${this.state.product_id}`, token: true });
        this.handleCloseDeleteModal()
    }

    handleFavouriteClick = (favourite: boolean) => {
        this.setState({ loading: true, isOpen: {} })
        this.favouriteproductApiId = favourite ?
            apiCall({
                method: configJSON.deleteMethod,
                endPoint: `${configJSON.productTempApiEndPoint}${this.state.product_id}${configJSON.removeFavorite}`,
                token: true,
            })
            :
            apiCall({
                method: configJSON.putMethod,
                endPoint: `${configJSON.productTempApiEndPoint}${this.state.product_id}${configJSON.addFavorite}`,
                token: true,
            });
    }

    handleDeleteApiResponse = (response: ResponseErrorData) => {
        const { date: { from_date, to_date, time_range }, formData } = this.props
        if (response.errors) {
            handleApiError(response.errors)
        } else {
            this.getproducts(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id)
            toast.success(configJSON.deleteSuccessMessage)
        }
    }

    handleFavouriteResponse = (response: { message: string[] }) => {
        const { date: { from_date, to_date, time_range }, formData } = this.props
        this.getproducts(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id)
        toast.success(capitalizeFirstLetter(response.message[0]))
    }

    handleCloseDeleteModal = () => {
        this.setState({ openDeleteModal: false })
    }

    handleOpenDeleteModal = () => {
        this.setState({ openDeleteModal: true })
        this.closeDropDown()
    }

    toggleDropdown = (itemId: string) => {
        this.setState((prevState) => {
            const updatedOpen = { ...prevState.isOpen };

            for (const keyValue in updatedOpen) {
                updatedOpen[keyValue] = false;
            }

            updatedOpen[itemId] = !prevState.isOpen[itemId];

            return {
                isOpen: updatedOpen,
                product_id: itemId,
            };
        });
    };

    closeDropDown = () => {
        this.setState((prevState) => {
            const updatedOpen = { ...prevState.isOpen };

            for (const keyValue in updatedOpen) {
                updatedOpen[keyValue] = false;
            }

            return {
                isOpen: updatedOpen,
            };
        });
    }

    handleView = (attributes: { template_id: number }) => {
        const templateId = attributes.template_id
        this.props.navigation.history.push(`${configJSON.productTemplateNavigation}${templateId}`, attributes)
    }

    handlePageChange = (page: number) => {
        const { date: { from_date, to_date, time_range }, formData } = this.props
        this.setState({
            meta: {
                ...this.state.meta,
                current_page: page
            }
        });
        this.getproducts(page, from_date, to_date, time_range, formData.department_id)
    };


    // Customizable Area End


}
