// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { ClassNameMap } from "@material-ui/styles";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonTemplateController from "./CommonTemplateController.web";
import { handleApiError, handleExpiredToken, checkToken, getDiffs, handleUndefinedData, getUserRoleID } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"formContainer" | "inputField" | "dateField" | "approvalContainer" 
    | "fieldWrapper" | "inputLabel" | "helperText" | "textAreaField" | "textAreaInputField" | "templateBtnWrapper"
     | "fileInput" | "primaryBtn" | "secondaryBtn" | "inputLabel2" | "dropdownWrapper" | "uploadImageContainer" | "titleText" | "gridItem" | "templateFooterContainer"
  >;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class Template20Controller extends CommonTemplateController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      ...this.initialState,
      ...this.state
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidMount(): any {
    // Customizable Area Start
    let { state } = this.props.navigation.history.location;
    checkToken();
    this.getBranchData();
    if (state) {
      this.setDefaultData20(state)
    } else if(![2,3].includes(getUserRoleID())) {
      this.props.navigation.goBack();

    }
    // Customizable Area End
  }

  setDefaultData20(data: any, action?: string) {
    const { id, creator, attachment } = data
    this.setState({
      id,
      formData: data,
      creator,
      templateAction: action ?? configJSON.view,
      defaultData: data,
      attachment: attachment.url ? attachment : "",
    })
  }

  template20InitialValues = () => {
    const { defaultData, formData, templateAction } = this.state;
    const isCreateTemplate20 = templateAction === configJSON.create;
  
    const getDescription = (index:number) => {
      return isCreateTemplate20 ? "" : handleUndefinedData(formData?.descriptions_attributes[index]?.description, "");
    };
  
    const data = {
      department_id: isCreateTemplate20 ? defaultData.department_id : handleUndefinedData(formData?.department_id, ""),
      title: isCreateTemplate20 ? defaultData.title : handleUndefinedData(formData?.title, ""),
      branch_id: isCreateTemplate20 ? defaultData.branch_id : handleUndefinedData(formData?.branch_id, ""),
      attachment: null,
      send_for_approval: isCreateTemplate20 ? defaultData.send_for_approval : handleUndefinedData(formData?.send_for_approval, false),
      description1: getDescription(0),
      description2: getDescription(1),
      description3: getDescription(2),
      description4: getDescription(3),
      description5: getDescription(4),
      description6: getDescription(5),
      description7: getDescription(6),
      description8: getDescription(7),
      description9: getDescription(8),
      description10: getDescription(9),
      description11: getDescription(10),
      description12: getDescription(11),
    };
  
    return data;
  };
  

  addEditTemplate20 = (values: any) => {
    const { formData,template_id, defaultData } = this.state;

    const {title,branch_id,descriptions_attributes, department_id,attachment, send_for_approval } = defaultData;
    const {description1, description2,description3, description4 ,
      description5,description6,description7,description8,
      description9,description10,description11,description12
    } = values;

    const { id } = formData;

    const createDescriptionsAttr = [
      {
        description: description1,
      }, {
        description: description2,
      },{
        description: description3,
      },{
        description: description4,
      },{
        description: description5,
      }, {
        description: description6,
      },{
        description: description7,
      },{
        description: description8,
      },{
        description: description9,
      }, {
        description: description10,
      },{
        description: description11,
      },{
        description: description12,
      }
    ];

    const editDescriptionsAttr = () => {
      return [
        {
          description: description1,
          id: this.state.formData.descriptions_attributes[0].id,
        }, {
          id: this.state.formData.descriptions_attributes[1].id,
          description: description2,
        },
        {
          description: description3,
          id: this.state.formData.descriptions_attributes[2].id,
        },
        {
          description: description4,
          id: this.state.formData.descriptions_attributes[3].id,
        },
        {
          id: this.state.formData.descriptions_attributes[4].id,
          description: description5,
        },
        {
          id: this.state.formData.descriptions_attributes[5].id,
          description: description6,
        },
        {
          id: this.state.formData.descriptions_attributes[6].id,
          description: description7,
        },
        {
          id: this.state.formData.descriptions_attributes[7].id,
          description: description8,
        },
        {
          description: description9,
          id: this.state.formData.descriptions_attributes[8].id,
        },
        {
          description: description10,
          id: this.state.formData.descriptions_attributes[9].id,
        },
        {
          description: description11,
          id: this.state.formData.descriptions_attributes[10].id,
        },
        {
          description: description12,
          id: this.state.formData.descriptions_attributes[11].id,
        }
      ];

    }


    const orriginalData = {
      title,
      branch_id,
      department_id,
      ...(attachment ? { attachment } : {}),
      send_for_approval,
      descriptions_attributes,
    };

    const attrs = {
      title: values.title,
      send_for_approval: values.send_for_approval,
      descriptions_attributes: id ? editDescriptionsAttr() : createDescriptionsAttr,
      department_id: values.department_id,
      branch_id: values.branch_id,
      ...(!id ? { template_id } : {}),
      ...(values.attachment ? { attachment: values.attachment } : this.checkEmptyAttachment()),
    };

    let templatePayload = getDiffs(orriginalData, attrs);
    this.addEditTemplateApiCall(templatePayload)
  }

  addTemplate20Response = (responseData20: any, message: string) => {
    if (responseData20.data) {
      if (this.state.id) {
        const dataItem = responseData20.data.attributes
        this.setStateData(dataItem);
        this.setDefaultData20(dataItem)
      } else {
        this.setState({ ...this.initialState });
        this.navigateToListingPage()
      }
      this.handleActions(configJSON.view)
      toast.success(message);
    } else if (responseData20.message) {
      const {defaultData} = this.state
      const dataItem = {...defaultData, favorite: !defaultData.favorite};
      this.setStateData(dataItem);
      this.setDefaultData20(dataItem)
      toast.success(message);
    } else {
      handleApiError(responseData20.errors);
    }
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resData20 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const requestMessageCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resData20, this.props.navigation);
      this.setState({ loading: false });
      if (requestMessageCallId && resData20) {
        this.setState({ loading: false });
        switch (requestMessageCallId) {
          case this.createTemplateApiId:
            this.addTemplate20Response(resData20, configJSON.procedureSuccessMessage);
            break;
          case this.duplicateTemplateApiId:
            this.addTemplate20Response(resData20, configJSON.procedureDuplicateMessage);
            break;
          case this.deleteTemplateApiId:
            this.deleteTemplateResponse(resData20);
            break;
          case this.branchDataApiId:
            this.getBranchesResponse(resData20);
            break;
          case this.departmentsApiId:
            this.getDepartmentsResponse(resData20);
            break;
          case this.favouriteTemplateApiId:
            const toastMessage = this.state.formData.favorite ? configJSON.procedureUnFavMessage : configJSON.procedureFavMessage;
            this.addTemplate20Response(resData20, toastMessage);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
