// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ClassNameMap } from "@material-ui/styles";
import { apiCall, convertFormData, getDiffs, getUser, handleApiError, handleExpiredToken, setUser } from "../../../components/src/utils/commonFunctions";
import { Company } from "./ViewCompanyProfileController.web";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { toast } from "react-toastify";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    classes: Record<string,string>

    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profileDetail: Company;
    industryDetail: Lists[];
    business_type_list: Lists[];
    currentImage: string | ArrayBuffer | null;
    country_code_list: Lists[];
    loading:boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start
export interface Lists {
    id?: number,
    value: string;
    label: string;
}
export interface FormValues {
    name: string;
    owner_name: string;
    owner_email: string;
    phone_number: string;
    industry_id: string | number;
    business_type_id: string | number;
    creation_date: string;
    employee_count: string | number;
    head_office: string;
    description: string;
    photo?: File | null;
    from_working_hours: string | null;
    to_working_hours: string | null;
    country_code_and_flag_id: string | number | null;
}
// Customizable Area End

export default class EditCompanyProfileController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    industryDataCallId: string = "";
    businessTypeCallId: string = "";
    editCompanyDetailCallId: string = "";
    countryCodeCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.SessionSaveMessage),
        ];

        this.state = {
            profileDetail: this.props.navigation.history.location.state,
            industryDetail: [],
            business_type_list: [],
            currentImage: null,
            country_code_list: [],
            loading:false
        }
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getIndustryData()
        this.getBusinessTypeList()
        this.handleDataValue()
        this.getCountryCodeList()
        // Customizable Area End
    }
    // Customizable Area Start
    handleDataValue = () => {
        const { profileDetail } = this.state
        this.setState({
            currentImage: profileDetail?.attributes.photo
        })
    }
    handleSelectChange = (event: OptionType, setFieldValue: { (field: string, value: FormValues, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, keyItem: string) => {
        setFieldValue(keyItem, event.value);
    };
    getIndustryData = () => {
      this.setState({loading:true})
        this.industryDataCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.industryApiEndPoint, token: true, });
    }
    handleResponseForIndustryData = (from: string, message: Message) => {
        if (this.industryDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(apiResponse, this.props.navigation);
            if (apiResponse.data) {
                const industryData = apiResponse.data.map((item: { attributes: { id: string; name: string } }) => {
                    const { attributes: { id, name } } = item;
                    return { value: Number(id), label: name }
                });
                this.setState({
                    industryDetail: industryData
                })

            } else {
                handleApiError(apiResponse.errors);
            }

        }
    }
    getBusinessTypeList = () => {
        this.setState({loading:true})
        this.businessTypeCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.businessTypeApiEndPoint, token: true, });
    }
    handleResponseForBussinessTypeList = (from: string, message: Message) => {
        if (this.businessTypeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(apiResponse, this.props.navigation);
            if (apiResponse.data) {
                const businessList = apiResponse.data.map((item: { attributes: { id: string; name: string } }) => {
                    const { attributes: { id, name } } = item;
                    return { value: Number(id), label: name }
                });
                this.setState({
                    business_type_list: businessList
                })

            } else {
                handleApiError(apiResponse.errors);
            }

        }
    }
    getCountryCodeList = () => {
        this.setState({loading:true})
        this.countryCodeCallId = apiCall({ method: configJSON.validationApiMethodType, endPoint: configJSON.countryCodeApiEndPoint, token: true, });
    }
    handleResponseForCountryCodeList = (from: string, message: Message) => {
        if (this.countryCodeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(apiResponse, this.props.navigation);
            if (apiResponse.data) {
                const newData = apiResponse.data.map((item: { id: string; name: string; alpha2: string; country_code: string }) => {
                    const { country_code, alpha2, id } = item
                    return { value: Number(id), label: `${country_code} (${alpha2})` }
                });
                this.setState({
                    country_code_list: newData
                })

            } else {
                handleApiError(apiResponse.errors);
            }

        }
    }
    handleImageChange = (event: { target: { name: string; files: File[]; }; }, setFieldValue: (arg0: string, arg1: File) => void) => {
        const file = event.target.files[0];
        if (file) {
            setFieldValue(event.target.name, file)
            this.setState({
                currentImage: URL.createObjectURL(event.target.files[0])
            });
        }
    };
    handleSave = (values: FormValues) => {
        this.editCompanyDetail(values)
    }
    editCompanyDetail = (values: FormValues) => {
        this.setState({loading:true})
        const { profileDetail } = this.state
        const { name,
            owner_name, owner_email, phone_number, industry_id, business_type_id,
            creation_date, employee_count, head_office, description,
        } = profileDetail.attributes
        const originalData = {
            name,
            owner_name, owner_email, phone_number, industry_id, business_type_id,
            creation_date, employee_count, head_office, description,
        }
        const attrs: FormValues = {
            name: values.name,
            owner_name: values.owner_name,
            owner_email: values.owner_email,
            phone_number: values.phone_number,
            industry_id: values.industry_id,
            business_type_id: values.business_type_id,
            creation_date: values.creation_date,
            employee_count: values.employee_count,
            head_office: values.head_office,
            description: values.description,
            from_working_hours: values.from_working_hours,
            to_working_hours: values.to_working_hours,
            country_code_and_flag_id: values.country_code_and_flag_id,
            ...(typeof values.photo === "object" && values.photo instanceof File ? { photo: values.photo } : {}),

        };
        let payload = getDiffs(originalData, attrs);
        this.editCompanyDetailCallId = apiCall({ method: configJSON.updateApiMethodType, body: convertFormData(payload), endPoint: `${configJSON.companyUpdateApiEndPoint}/${this.state.profileDetail.id}`, token: true, });
    }
    handleResponseForEditCompanyDetail = (from: string, message: Message) => {

        if (this.editCompanyDetailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const response = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(response, this.props.navigation);
            if (response.data) {
                let companyData = getUser();
                companyData.data.attributes.company_profile = response;
                toast.success(configJSON.updateSuccessMessage);

                this.props.navigation.history.push(`/ViewCompanyProfile`);
                location.reload()
                setUser(companyData);
            } else {
                handleApiError(response.errors);
            }

        }
    }


    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.setState({loading:false})
        this.handleResponseForIndustryData(from, message)
        this.handleResponseForBussinessTypeList(from, message)
        this.handleResponseForEditCompanyDetail(from, message)
        this.handleResponseForCountryCodeList(from, message)
        // Customizable Area End
    }
}
