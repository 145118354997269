/** @format */

// Customizable Area Start
import React from "react";
import { Box, Button, Typography, TextField, InputLabel, Card, CardContent, InputAdornment, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import NewPasswordController, { Props } from "./NewPasswordController.web";
import LoginSignupRightSection from "../../../components/src/LoginSignupRightSection.web";
import { styles } from "../../../components/src/utils/Styles.web";
import LoginSignupBackgroundImgWeb from "../../../components/src/LoginSignupBackgroundImg.web";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import { resetPasswordSchema } from "../../../components/src/utils/validationSchema";
const logo = require("../../../components/src/qwipLogo.svg");
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class NewPassword extends NewPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { password, confirmPassword, enablePasswordField, enableConfirmPassword } = this.state;

        return (
            <React.Fragment>
                <LoginSignupBackgroundImgWeb />
                <Card className={classes.mainCard}>
                    <CardContent className={classes.cardContentInner}>
                        <Box className={classes.mainContainer}>
                            <Box className={classes.logoHeader}>
                                <img src={logo} alt="qwipLogo" />
                            </Box>
                            <Typography variant="h4" className={classes.headerTitle}>
                                {configJSON.setPasswordText}
                            </Typography>
                            <Formik
                                enableReinitialize={true}
                                validateOnBlur={true}
                                initialValues={{
                                    password: password,
                                    confirmPassword: confirmPassword,
                                }}
                                validationSchema={resetPasswordSchema}
                                onSubmit={this.handleSave}>
                                {({ errors, touched, values, handleChange, handleBlur }) => {
                                    const isButtonDisabled = !values.password || !values.confirmPassword;
                                    return (
                                        <Form translate={undefined}>
                                            <Box className={classes.fieldWrapper}>
                                                <InputLabel className={classes.inputLabel}>{configJSON.placeHolderPassword}</InputLabel>
                                                <TextField
                                                    data-test-id="txtInputPassword"
                                                    name="password"
                                                    type={enablePasswordField ? "password" : "text"}
                                                    variant="outlined"
                                                    placeholder={configJSON.placeHolderPassword}
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    className={classes.inputField}
                                                    error={handleInvoiceErrors(errors.password, touched.password)}
                                                    helperText={handleInvoiceHelperText(errors.password, touched.password)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    data-test-id ="visibility_icon"
                                                                    onClick={this.handleClickShowPassword}
                                                                    edge="end">
                                                                    {enablePasswordField ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                            <Box className={classes.fieldWrapper}>
                                                <InputLabel className={classes.inputLabel}>{configJSON.placeHolderConfirmPassword}</InputLabel>
                                                <TextField
                                                    data-test-id="txtInputConfirmPassword"
                                                    name="confirmPassword"
                                                    type={enableConfirmPassword ? "password" : "text"}
                                                    variant="outlined"
                                                    placeholder={configJSON.placeHolderConfirmPassword}
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    className={classes.inputField}
                                                    error={handleInvoiceErrors(errors.confirmPassword, touched.confirmPassword)}
                                                    helperText={handleInvoiceHelperText(errors.confirmPassword, touched.confirmPassword)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                   data-test-id="visibility_icon"
                                                                    aria-label="toggle password visibility"
                                                                    onClick={this.handleClickShowConfirmPassword}
                                                                    edge="end">
                                                                    {enableConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                            <Box className={classes.btnWrapper}>
                                                <Button
                                                    data-test-id="confirm_btn"
                                                    variant="contained"
                                                    fullWidth
                                                    className={classes.innerBtn}
                                                    type="submit"
                                                    disabled={isButtonDisabled}>
                                                    {configJSON.confirmBtn}
                                                </Button>
                                            </Box>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Box>
                        <Box className={classes.imageBox}>
                            <LoginSignupRightSection />
                        </Box>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
        // Customizable Area End
    }
}

export default withStyles(styles)(NewPassword);
