// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, handleExpiredToken, handleApiError, handleRemoveAllFromLocal, setUser, getUser, getDeviceToken } from "../../../components/src/utils/commonFunctions";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start

  open: boolean;
  handleClose: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
// Customizable Area Start

// Customizable Area End

export default class LogoutController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  logoutCallId: string = "";
  userDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  
  async componentDidMount() {
    this.getUaserData();
  }
  handleLogout = () => {
    const device_id = getDeviceToken();
    const endPoint  = `${configJSON.logoutApiEndpoint}?device_id=${device_id}`
    this.logoutCallId = apiCall({ method: configJSON.deleteAPiMethod, endPoint: endPoint, token: true, });
  }

  getUaserData = () => {
    this.userDataCallId = apiCall({ method: configJSON.dashboarApiMethodType, endPoint: configJSON.getUserDataApiEndpoint, token: true, });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleExpiredToken(responseJson, this.props.navigation);
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.logoutCallId) {
          this.handleLogoutResponse(responseJson);
        } else if (apiRequestCallId === this.userDataCallId) {
          this.handleUserDataResponse(responseJson);
        }
      }
    }
  }
  handleLogoutResponse = (apiResponse: { message: string; errors: string[]; }) => {
    if (apiResponse.message) {
      this.props.handleClose()
      handleRemoveAllFromLocal()
    } else {
      handleApiError(apiResponse.errors);
    }
  };
  handleUserDataResponse = (apiResponse: { data: object; errors: string[]; }) => {
    if (apiResponse.data) {
      setUser(getUser());
    } else {
      handleApiError(apiResponse.errors);
    }
  };
  // Customizable Area End


}
