import React, { Component } from "react";
import {TextField, TextFieldProps } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DownArrowBlack } from "../assets";

interface OptionType {
  [x: string]: any;
  label: string;
  value: any;
  data?: any;
}
interface Props {
  classes?: any;
  onChange: any;
  handleBlur: any;
  value: any;
  options: string[] | OptionType[];
  placeholder: string;
  name: string;
  freeSolo?: boolean;
  removeArrow?:boolean;
  error?:boolean;
  helperText?: string;
  disabled?:boolean;
  handleKeyPress?: any
}



class CustomAutoComplete extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, onChange, handleBlur, value, options, name, placeholder, freeSolo, removeArrow, error, helperText,disabled, handleKeyPress } = this.props;

    return (
      <div>
        <Autocomplete
          fullWidth
          data-test-id={name}
          freeSolo={freeSolo}
          onChange={onChange}
          onInputChange={freeSolo ? onChange : undefined}
          options={options}
          getOptionLabel={(option) => typeof option === "object" ? option.label : option}
          disabled={disabled}
          onBlur={handleBlur}
          classes={{
            paper: classes?.paperStyle,
            listbox: classes?.listBox,
            popper: classes?.popperStyle,
            option: classes?.options,
            clearIndicator: classes?.clearIndicatorStyle,
            input: classes?.input,
          }}
          value={
            (options.length && typeof options[0] === "object")
              ? (options as OptionType[]).find((item: OptionType) => item.value === value)
              : value
          }
          popupIcon={<img
            src={DownArrowBlack}
            alt="arrow"
          />}
          forcePopupIcon={!removeArrow}
          disableClearable
          closeIcon={false}
          getOptionDisabled={(option) => option.isDisabled}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
            return (
              <TextField
                name={name}
                {...params}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress ? handleKeyPress : undefined}
                className={classes?.inputField}
                variant="outlined"
                placeholder={placeholder}
                error={error}
                helperText={helperText}
              />
            );
          }}
        />
      </div>
    );
  }
}

export default CustomAutoComplete;
