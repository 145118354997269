/** @format */

import React from "react";
// Customizable Area Start
import { commonInputField, commonButton, helperText, commonTemplateCss, singleValueCss } from "../../../components/src/utils/commonInputStyle";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, TextField, Button, Grid, Switch, FormHelperText } from "@material-ui/core";
import Template1Controller, { Props, configJSON } from "./Template1Controller.web"
import { Formik, Form } from 'formik';
import { Wrapper } from "../../../components/src/Wrapper";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import DropdownSearch from "../../../components/src/commonComponents/DropdownSearch.web";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import AttachFileInput from "../../../components/src/commonComponents/AttachFileInput.web";
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonButton,
  ...commonInputField,
  ...commonTemplateCss,
});
// Customizable Area End

export class Template1 extends Template1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { classes } = this.props;
    const { attachment, attachmentError, loading, branch_data, isDownload, imageUrls, department_data, templateAction, openMenu, opeDeleteModal, template_id, formData} = this.state;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper backbtn={true} navigation={this.props.navigation} divider title={this.headerTitle()} bellIcn={true} homeIcn={true} child={
          <Box>
            <Formik
              validationSchema={this.template1ValidationSchema()}
              enableReinitialize={true}
              validateOnBlur={true}
              onSubmit={this.addEditTemplate1}
              initialValues={this.template1InitialValues()}
            >
              {({ errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, values }) => (
                <Form translate className={`${classes.formContainer} ${isDownload && "textColor"}`} onSubmit={handleSubmit}>
                  <Grid ref={this.pdfContentRef} direction="row" container spacing={5} className={classes.gridContainer}>
                  <Grid item className={classes.gridItem} xs={10} sm={5}>
                      {this.isViewOnly() ?
                        <Typography variant="h4" className={classes.inputLabel2} data-test-id="title" style={{ fontWeight: 700 }}>
                          {values.title}
                        </Typography> :
                        <Box className={classes.fieldWrapper} style={{ margin: "0" }}>
                          <InputLabel required className={classes.inputLabel2} style={{ fontSize: "16px" }}>
                            {configJSON.templateTitleLabel}
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            value={values.title}
                            onChange={handleChange}
                            data-test-id="title_input_field"
                            name={configJSON.templateTitleName}
                            fullWidth
                            className={classes.inputField}
                            helperText={handleInvoiceHelperText(
                              errors.title,
                              touched.title
                            )}
                            error={handleInvoiceErrors(
                              touched.title,
                              errors.title,
                            )}
                            onBlur={handleBlur}
                          />
                        </Box>
                      }
                    </Grid>
                    <Grid item className={`${classes.gridItem} actionBtn ${isDownload && "visibility"}`} xs={2} sm={7}>
                      {templateAction !== configJSON.create &&
                        <ActionButton
                          edit={this.allowEdit()}
                          download
                          deleteVisible={this.allowEdit()}
                          handleDownload={this.handleDownloadPdf}
                          isOpen={openMenu}
                          toggleDropdown={this.toggleMenu}
                          handleDuplicate={this.handleDuplicateTemplate}
                          duplicate={this.allowEdit()}
                          handleEdit={() => this.handleActions(configJSON.edit)}
                          handleDelete={this.handleDelete}
                          mark_as_favourite
                          favourite={formData.favorite}
                          handleFavourite={this.handleFavouriteClick}
                        />
                      }
                    </Grid>
                    <Grid item className={classes.gridItem} xs={12}>
                      <div
                        className={classes.dropdownContainer}
                      >
                        <Box className={classes.dropdownWrapper}>
                        <DropdownSearch
                            singleValueCss={isDownload && singleValueCss}
                            options={branch_data}
                            value={values.branch_id}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                            noOptionsMessage={configJSON.templateNoBranchMessage}
                            id={configJSON.templateBranchName}
                            placeholder={configJSON.templateBranchPlaceholder}
                            isSearchable={true}
                            isDisabled={this.isViewOnly()}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            isMyProfile={true}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            value={values.department_id}
                            singleValueCss={isDownload && singleValueCss}
                            options={department_data}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            id={configJSON.templateDepartmentName}
                            isSearchable={true}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                            errorText={handleInvoiceHelperText(
                              errors.department_id,
                              touched.department_id
                            )}
                            noOptionsMessage={configJSON.templateNoDepartmentMessage}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id
                            )}
                            placeholder={configJSON.templateDepartmentPlaceholder}
                            isMyProfile={true}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid item className={classes.gridItem} xs={12}>
                      <Grid container className={`${template_id === 2 && classes.reverseContainer} ${classes.gridContainer}`} spacing={5}>
                        <Grid item className={`${classes.gridItem} marginTop`}  xs={12} sm={6}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={classes.uploadImageContainer}  >
                              <ImageUploadView
                                handleImageChange={this.handleImageChange}
                                uploadTexts={["Upload Image"]}
                                isDisabled={this.isViewOnly()}
                                setFieldValue={setFieldValue}
                                name={configJSON.image1}
                                currentImage={imageUrls[configJSON.image1]?.url}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              touched.image1,
                              errors.image1,
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image1,
                                    touched.image1
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                        <Grid item className={`${classes.gridItem} flex`}  xs={12} sm={6}>
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel} required>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              type="text"
                              variant="outlined"
                              disabled={this.isViewOnly()}
                              className={`${classes.inputField} ${classes.tempHeight} ${classes.textAreaField}`}
                              name={configJSON.description1}
                              value={values.description1}
                              onChange={handleChange}
                              fullWidth
                              multiline
                              helperText={handleInvoiceHelperText(
                                errors.description1,
                                touched.description1
                              )}
                              error={handleInvoiceErrors(
                                errors.description1,
                                touched.description1
                              )}
                              onBlur={handleBlur}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box className={`${classes.templateFooterContainer} marginTop40`} >
                    <Box className={classes.approvalContainer}>
                      <Typography className={`${classes.inputLabel2} approvalText`} variant="h4">
                        {configJSON.sendForApproval}
                      </Typography>
                      <Switch
                        name="send_for_approval"
                        color="primary"
                        disabled={this.state.templateAction !== configJSON.create}
                        checked={values.send_for_approval}
                        onChange={handleChange}
                      />
                    </Box>
                    <AttachFileInput
                      attachment={attachment}
                      setFieldValue={setFieldValue}
                      isViewOnly={this.isViewOnly}
                      templateAction={templateAction}
                      attachmentError={attachmentError}
                      handleFileUpload={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(e, setFieldValue) }}
                      clearAttachment={this.clearAttachment.bind(null, setFieldValue)}
                    />
                  </Box>
                  <Box className={classes.templateBtnWrapper}>
                    {!this.isViewOnly() &&
                      <Button
                        className={classes.primaryBtn}
                        type="submit"
                        data-test-id="create-btn"
                        variant="contained"
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              title={configJSON.templateDeleteTitle}
              from={configJSON.procedureFrom}
              open={opeDeleteModal}
              onClose={this.handleDeleteClose}
              confirmDelete={this.handleDeleteTemplate}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(Template1);
// Customizable Area End

