// Customizable Area Start
import React from "react";
import { Box, Button, Typography, List, ListItem, IconButton, Grid, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./TicketTypeListStyle.web";
import TicketTypeListController, {
  Props,
  configJSON
} from "./TicketTypeListController.web";
import { Wrapper } from "../../../components/src/Wrapper";
import { clipboard, deleted, duplicate, edit } from "./assets";
import TicketTypeForm from "./TicketTypeForm.web";
import Loader from "../../../components/src/Loader";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class TicketTypeList extends TicketTypeListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { open, ticketTypes, loading, searchTicketTypes, data, isEdit, ticket_name, searchQuery, isDuplicate ,openDeleteModal} = this.state;
    const isEmptyText = configJSON.emptyListText.split("|");
    return (
      <React.Fragment>
        <Loader loading={loading} />
        <Wrapper
          navigation={this.props.navigation}
          divider
          data-test-id="ticket-type-wrapper-test"
          backbtn={true}
          title={configJSON.ticket}
          bellIcn={true}
          homeIcn={true}
          search
          searchHandleClick={this.handleSearch}
          searchData={ticketTypes}
          child={
            <Box className={classes.mainContainer}>
              <Box className={classes.innerSection}>
                <Box>
                  {ticketTypes.length > 0 &&
                    <Button
                      data-test-id="ticket-type-list-modal"
                      className={`${classes.secondaryBtn} ${classes.raiseButton}`}
                      size="large"
                      onClick={this.goToCreateTicket}
                      variant="outlined"
                    >
                      {configJSON.ticketRaiseBtn}
                    </Button>
                  }
                </Box>
                <Button
                  data-test-id="ticket-type-list-type-modal"
                  className={`${classes.primaryBtn} ${classes.raiseButton}`}
                  size="large"
                  onClick={this.handleModalVisible}
                >
                  {configJSON.createTicketType}
                </Button>
              </Box>
              {(ticketTypes.length == 0 && !loading) &&
                <Box className={classes.ticketListBox}>
                  <img src={clipboard} />
                  <Typography className={classes.emptyListTextStyle}>
                    {isEmptyText[0]}
                    <strong>{isEmptyText[1]}</strong>
                    {isEmptyText[2]}
                  </Typography>
                </Box>}
              <Box py={2}>
                {(!Boolean(searchTicketTypes) && Boolean(searchQuery) && !loading) ? (
                  <Box className={classes.noSearchResult}>
                    <Typography data-test-id="search_result" variant="h5">{configJSON.noSearchResult} <strong>{searchQuery}</strong>.</Typography>
                  </Box>
                ) :
                  ticketTypes.length > 0 &&
                  <Grid container spacing={2}>
                    {(searchTicketTypes || ticketTypes).map((ticketType) => {
                      return (
                        <Grid item md={4} sm={6} xs={12}>
                          {
                            ticketType.departments.length > 0 &&
                            <Box className={classes.ticketBox}>
                              <Box className={classes.iconBtnGroup}>
                                <IconButton className={classes.btnStyle} data-test-id="ticket-type-list-edit" onClick={() => this.handleEditClick(ticketType)}>
                                  <img src={edit} alt={configJSON.edit} />
                                </IconButton>
                                <IconButton className={classes.btnStyle} data-test-id="ticket-type-list-duplicate" onClick={() => this.handleEditClick(ticketType, true)}>
                                  <img src={duplicate} alt={configJSON.duplicate} />
                                </IconButton>
                                <IconButton className={classes.btnStyle} data-test-id="ticket-type-list-delete" onClick={() => this.handleOpenDeleteModal(ticketType)}>
                                  <img src={deleted} alt={configJSON.delete} />
                                </IconButton>
                              </Box>
                              <Box >
                                <Box className={classes.nameSection}>
                                  <List disablePadding>
                                    <ListItem dense>
                                      <Box className={classes.nameSectionInner}>
                                        <Box><Typography className={classes.smallTxt}>{configJSON.issueName}</Typography></Box>
                                        <Box><Typography data-test-id="issue_name" className={classes.smallTxt}>{ticketType.issue_name}</Typography></Box>
                                      </Box>
                                    </ListItem>
                                  </List>
                                </Box>
                                <Divider variant="middle" />

                                <List>
                                  <ListItem><Typography className={classes.selectedDepart}>{configJSON.selectedDept}</Typography></ListItem>
                                  <Box className={classes.departmentWrapper}>
                                    {ticketType.departments.map((department) => {
                                      return (
                                        <ListItem><Typography className={classes.issueWrapperTxt}>{department.department_name}</Typography></ListItem>
                                      )
                                    })}
                                  </Box>
                                </List>
                              </Box>
                            </Box>
                          }
                        </Grid>
                      )
                    })}
                  </Grid>
                }
              </Box>
            </Box>
          }
        />
        {open &&
          <TicketTypeForm
            open={open}
            handleClose={this.handleModalClose}
            data={data}
            isEdit={isEdit}
            isDuplicate={isDuplicate}
          />
        }
        {openDeleteModal &&
          <ConfirmationModal
            open={openDeleteModal}
            onClose={this.handleCloseDeleteModal}
            confirmDelete={this.handleDeleteClick}
            title={String(ticket_name)}
            from={configJSON.ticketTypeList}
          />}
      </React.Fragment>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(TicketTypeList);
