import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { apiCall, checkToken, handleApiError, handleExpiredToken } from "../../../components/src/utils/commonFunctions";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import React from "react";
export const configJSON = require("./config");

// Customizable Area End

export interface Props {
  navigation?: any;
  // Customizable Area Start
  classes: Record<string, string>;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  notificationList: NotificationList[];
  read_count: number;
  unread_count: number;
  meta: Meta,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface NotificationList {
  id: number;
  title: string;
  remarks: string;
  created_at: string;
  is_read: boolean;
  creator: {
    id: number;
    name: string;
    designation: string;
    email: string
    photo: string
  };
  link?: string;
}

export interface NotificationListRespone {
  read_count: number;
  unread_count: number;
  notification: NotificationList[];
  meta: Meta;
  errors?: string[];
}

export interface SingleNotificationRespone {
  read_count: number;
  unread_count: number;
  notification: NotificationList;
  meta: Meta;
  errors?: string[];
  message?: string[];
}

interface Meta {
  total_pages: number;
  total_count: number;
  current_page: number;
  next_page: number;
  previous_page: number;
}
// Customizable Area End

export default class NotificationListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotificationListApi: string = "";
  readSingleNotificationApi: string = "";
  readAllNotificationApi: string = "";
  getdeprtApi: string = "";
  scrollRef: React.RefObject<HTMLDivElement>;

  meta = {
    total_pages: 1,
    total_count: 1,
    current_page: 0,
    next_page: 1,
    previous_page: 0
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      loading: false,
      notificationList: [],
      read_count: 0,
      unread_count: 0,
      meta: this.meta,
    }

    this.scrollRef = React.createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    checkToken();
    this.getNotificationList();
  }

  async componentDidUpdate() {
    const scrollElement = this.scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', this.handleScroll);
    }
  }

  async componentWillUnmount() {
    const scrollElement = this.scrollRef.current;
    if (scrollElement) {
      scrollElement.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = (event: Event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target as HTMLDivElement;
    if (Math.floor(clientHeight + scrollTop) >= (scrollHeight - 30)) {
      if(this.state.meta.next_page && !this.state.loading) {
        this.getNotificationList()
      }
    }
  }

  getNotificationList = () => {
    this.setState({ loading: true });
    const {next_page} = this.state.meta;
    this.getNotificationListApi = apiCall({ method: configJSON.getMethod, endPoint: `${configJSON.getNotificationEndpoint}?page=${next_page}`, token: true });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(responseJsonData, this.props.navigation);
      this.setState({ loading: false });

      if (apiCallId && responseJsonData) {
        if (apiCallId === this.getNotificationListApi) {
          this.getNotificationsResponse(responseJsonData);
        } else if (apiCallId === this.readSingleNotificationApi) {
          this.readSingleNotificationResponse(responseJsonData);
        } else if (apiCallId === this.readAllNotificationApi) {
          this.readAllNotificationResponse(responseJsonData);
        }
      }
    }
  }

  getNotificationsResponse = (response: NotificationListRespone) => {
    if (response.notification) {
      this.setState((prev) => ({
        notificationList: [...prev.notificationList,...response.notification],
        read_count: response.read_count,
        unread_count: response.unread_count,
        meta: response.meta
      }))
    } else {
      handleApiError(response.errors);
    }
  }

  markSingleNotificationRead = (notificationID: number) => {
    const newNotificationList = this.state.notificationList
    const itemIndex = newNotificationList.findIndex(item => item.id === notificationID);
    if (itemIndex !== -1) {
      newNotificationList[itemIndex].is_read = true;
    }
    return this.setState({notificationList: newNotificationList })
  }

  markAllNotificationRead = () => {
    const newNotificationList = this.state.notificationList
    const newList = newNotificationList.map(item =>({ ...item, is_read: true }));
    return this.setState({notificationList: newList })
  }

  handleClickHere = (notification: NotificationList) => {
    this.props.navigation.history.push(notification.link);
  }

  handleReadNotification = (notification: NotificationList) => {
    if (!notification.is_read) {
      this.readSingleNotificationApi = apiCall({ method: configJSON.putMethod, endPoint: `${configJSON.getNotificationEndpoint}/${notification.id}${configJSON.isRead}`, token: true });
    }
  }

  readSingleNotificationResponse = (response: SingleNotificationRespone) => {
    if ('errors' in response) {
      handleApiError(response.errors);
    } else {
      this.setState({
        read_count: response.read_count,
        unread_count: response.unread_count,
      })
      this.markSingleNotificationRead(response.notification.id)
    }
  }

  handleMarkALlRead = () => {
    this.readAllNotificationApi = apiCall({ method: configJSON.putMethod, endPoint: `${configJSON.getNotificationEndpoint}${configJSON.isRead}`, token: true });
  }

  readAllNotificationResponse = (response: { message: string } | { errors: string[] }) => {
    if ('errors' in response) {
      handleApiError(response.errors);
    } else {
      this.markAllNotificationRead();
      toast.success(response.message);
    }
  }

  // Customizable Area End
}

