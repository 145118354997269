// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  apiCall,
  getDeviceToken,
  getLoginToken,
  handleApiError,
  handleLandingRedirection,
  handleRemoveAllLocalStorage,
  setToken,
} from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  classes: Record<string,string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token : string | null;
  userData: object | null;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBusineessOwnerApiId: string = "";
  logoutApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      token: "",
      userData: null,
      loading: false
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

   // Customizable Area Start

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    this.setState({ token });
    this.getBusinessOwner(token ?? "");
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.getBusineessOwnerApiId) {
          this.getBusinessOwnerResponse(responseJson);
        } else if (apiRequestCallId === this.logoutApiId) {
          this.handleLogoutRes(responseJson);
        } 
      }
    }
  }

  handleLogoutRes = (apiResponse: { message: string; errors: string[]; }) => {
    if (apiResponse.message) {
      handleRemoveAllLocalStorage();
      setToken(this.state.token);
    } else {
      handleApiError(apiResponse.errors);
    }
  };

  handleLogout = () => {
    const deviceId = getDeviceToken();
    const endPoint  = `${configJSON.logoutApiEndpoint}?device_id=${deviceId}`
    this.logoutApiId = apiCall({ method: configJSON.deleteAPiMethod, endPoint: endPoint, token: true, });
  }

  getBusinessOwnerResponse = async (response: { data: object; business_owner_email: string; errors: string[]; }) => {
    if (response.data || response.business_owner_email) {
      this.setState({
        userData: response.business_owner_email
          ? { business_owner_email: response.business_owner_email }
          : response.data
      });
      !!getLoginToken() && this.handleLogout();
      setToken(this.state.token);
    } else if (response.errors){
      handleApiError(response.errors);
      this.props.navigation.navigate('EmailLogin')
    }else {
      toast.error(configJSON.landingPageUserNotFound)
      this.props.navigation.navigate('EmailLogin')
    }
  };

  handleUserRedirection = () => {
    handleLandingRedirection(this.state.userData, this.props.navigation)
  };

  getBusinessOwner = (token: string) => {
    this.setState({ loading: true });
  
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusineessOwnerApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBusinessOwnerDataEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBusinessOwnerDataMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
  
}
