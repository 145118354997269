// Customizable Area Start
import React from "react";
import {Box , Typography} from "@material-ui/core";
import CreatePageController, { Props } from "./CreatePageController.web";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./HomePageStyles.web";
import { Wrapper } from "../../../components/src/Wrapper";
import { createPageContent } from "../../../components/src/utils/constant";
export const configJSON = require("./config");
// Customizable Area End

export class CreatePage extends CreatePageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { ownerName, companyName } = this.state;

    return (
        <React.Fragment>
            <Wrapper
                navigation={this.props.navigation}
                divider
                data-test-id="home-wrapper-test"
                bellIcn
                homeIcn
                backbtn
                title={configJSON.headerTitle}
                child={
                    <Box className={classes.homeOuterBox}>
                        <Box className={classes.homeInnerBox}>
                            <Typography variant="h4" className={"homeTitle"} data-test-id="welcome_text">
                                {configJSON.welcomeText} {companyName}
                            </Typography>
                            <Typography className={"homeBody"} style={{ marginTop: 5 }}>
                                {ownerName}
                            </Typography>
                            <Box className="createContainer">
                                    {createPageContent.map((list) => (
                                        <Box className="homeInnerBoxCard" data-test-id = "navigate_btn" onClick={() => this.handleNavigate(list.path)} key={list.name}>
                                            <div className="imgContainer">
                                                {list.photo && <img src={list.photo} alt="Image" />}
                                            </div>
                                            <Typography variant="h4" className={"titleName"}>
                                                {list.name}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                        </Box>
                    </Box>
                }
            />
        </React.Fragment>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(CreatePage);
// Customizable Area End
