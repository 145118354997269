import React from "react";
// Customizable Area Start
import { Typography, Box, InputLabel, TextField, Button, Grid, Switch } from "@material-ui/core";
import { commonInputField, commonButton, singleValueCss, commonTemplateCss } from "../../../components/src/utils/commonInputStyle";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Formik, Form } from 'formik';
import Template19Controller, { Props, configJSON } from "./Template19Controller.web"
import { Wrapper } from "../../../components/src/Wrapper";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import { EditTemplate18Schema } from "../../../components/src/utils/validationSchema";
import AttachFileInput from "../../../components/src/commonComponents/AttachFileInput.web";
import Loader from "../../../components/src/Loader";
import DropdownSearch from "../../../components/src/commonComponents/DropdownSearch.web";
// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonTemplateCss,
  ...commonButton,
  ...commonInputField,
  doText:{
    fontSize:"18px",
    fontWeight:600,
    color:"#020202",
    marginBottom:"0.7rem"
  }

});
// Customizable Area End

export class Template19 extends Template19Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { loading, department_data, formData, attachmentError, attachment, branch_data, isDownload, templateAction, openMenu, opeDeleteModal } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper backbtn={true} bellIcn={true} divider navigation={this.props.navigation} title={this.headerTitle()}  homeIcn={true} child={
          <Box>
            <Formik
              enableReinitialize={true}
              initialValues={this.template19InitialValues()}
              validateOnBlur={true}
              validationSchema={EditTemplate18Schema}
              onSubmit={this.addEditTemplate19}
            >
              {({ errors, values, handleBlur,setFieldValue , touched, handleChange}) => (
                <Form className={`${classes.formContainer} ${isDownload && "textColor"}`} translate>
                  <Grid direction="row" ref={this.pdfContentRef} className={classes.gridContainer} container alignItems="stretch"  spacing={5}>
                    <Grid item sm={5} xs={10} className={classes.gridItem}>
                      {this.isViewOnly() ?
                        <Typography className={`${classes.inputLabel2} title`} data-test-id="title" variant="h4">
                          {values.title}
                        </Typography> :
                        <Box className={`marginTitle ${classes.fieldWrapper}`}>
                          <InputLabel required className={`${classes.inputLabel2} labetText`}>
                            {configJSON.templateTitleLabel}
                          </InputLabel>
                          <TextField
                            data-test-id="title_input_field"
                            type="text"
                            onChange={handleChange}
                            error={handleInvoiceErrors(
                              errors.title,
                              touched.title,
                            )}
                            fullWidth
                            variant="outlined"
                            value={values.title}
                            className={classes.inputField}
                            onBlur={handleBlur}
                            name={configJSON.templateTitleName}
                            helperText={handleInvoiceHelperText(
                              errors.title,
                              touched.title
                            )}
                          />
                        </Box>
                      }

                    </Grid>
                    <Grid xs={2}  sm={7} item className={`${classes.gridItem} actionBtn ${isDownload && "visibility"}`}>
                      {templateAction !== configJSON.create &&
                        <ActionButton
                        toggleDropdown={this.toggleMenu}
                        download
                        deleteVisible={this.allowEdit()}
                          edit={this.allowEdit()}
                          handleEdit={() => this.handleActions(configJSON.edit)}
                          handleDelete={this.handleDelete}
                          handleDownload={this.handleDownloadPdf}
                          isOpen={openMenu}
                          duplicate={this.allowEdit()}
                          mark_as_favourite
                          favourite={formData.favorite}
                          handleFavourite={this.handleFavouriteClick}
                          handleDuplicate={this.handleDuplicateTemplate}
                        />
                      }

                    </Grid>
                    <Grid className={classes.gridItem} item xs={12}>
                      <div className={classes.dropdownContainer}>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            singleValueCss={isDownload && singleValueCss}
                            value={values.branch_id}
                            options={branch_data}
                            id={configJSON.templateBranchName}
                            isSearchable={true}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                            isDisabled={this.isViewOnly()}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            placeholder={configJSON.templateBranchPlaceholder}
                            isMyProfile={true}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                              )}
                              noOptionsMessage={configJSON.templateNoBranchMessage}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            singleValueCss={isDownload && singleValueCss}
                            id={configJSON.templateDepartmentName}
                            value={values.department_id}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id,
                            )}
                            options={department_data}
                            errorText={handleInvoiceHelperText(
                              errors.department_id,
                              touched.department_id,
                            )}
                            isSearchable={true}
                            noOptionsMessage={configJSON.templateNoDepartmentMessage}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            placeholder={configJSON.templateDepartmentPlaceholder}
                            isMyProfile={true}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid sm={6} item className={classes.gridItem}>
                      <Box className={classes.fieldWrapper}>
                        <Typography className={classes.doText} component="h2">{configJSON.doText}</Typography>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                          name={configJSON.description1}
                          type="text"
                          helperText={handleInvoiceHelperText(
                            errors.description1,
                            touched.description1
                          )}
                          error={handleInvoiceErrors(
                            errors.description1,
                            touched.description1
                            )}
                            value={values.description1}
                            multiline
                          className={`${classes.inputField} ${classes.tempHeight} ${classes.textAreaField}`}
                          onBlur={handleBlur}
                          disabled={this.isViewOnly()}
                        />
                      </Box>
                    </Grid>
                    <Grid item className={classes.gridItem} sm={6}>
                      <Box className={classes.fieldWrapper}>
                      <Typography className={classes.doText} component="h2">{configJSON.doNotText}</Typography>
                        <InputLabel required className={classes.inputLabel}>
                          {configJSON.descriptionLabel}
                        </InputLabel>
                        <TextField
                          value={values.description2}
                          error={handleInvoiceErrors(
                            errors.description2,
                            touched.description2,
                            )}
                            name={configJSON.description2}
                          className={`${classes.inputField} ${classes.tempHeight} ${classes.textAreaField}`}
                          fullWidth
                          onChange={handleChange}
                          type="text"
                          multiline
                          onBlur={handleBlur}
                          variant="outlined"
                          helperText={handleInvoiceHelperText(
                            errors.description2,
                            touched.description2
                            )}
                            disabled={this.isViewOnly()}
                            />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={classes.templateFooterContainer}>
                    <Box className={classes.approvalContainer}>
                      <Typography variant="h4" className={`${classes.inputLabel2} approvalText`}>
                        {configJSON.sendForApproval}
                      </Typography>
                      <Switch
                        onChange={handleChange}
                        name="send_for_approval"
                        color="primary"
                        disabled={this.state.templateAction !== configJSON.create}
                        checked={values.send_for_approval}
                      />
                    </Box>
                    <AttachFileInput
                      handleFileUpload={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(e, setFieldValue) }}
                      attachment={attachment}
                      clearAttachment={this.clearAttachment.bind(null, setFieldValue)}
                      attachmentError={attachmentError}
                      templateAction={templateAction}
                      setFieldValue={setFieldValue}
                      isViewOnly={this.isViewOnly}
                    />
                  </Box>
                  <Box className={classes.templateBtnWrapper}>
                    {!this.isViewOnly() &&
                      <Button
                        data-test-id="create-btn"
                        className={classes.primaryBtn}
                        type="submit"
                        variant="contained"
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              confirmDelete={this.handleDeleteTemplate}
              open={opeDeleteModal}
              title={configJSON.templateDeleteTitle}
              from={configJSON.procedureFrom}
              onClose={this.handleDeleteClose}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(Template19);
// Customizable Area End

