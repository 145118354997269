Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfTabletView";
exports.labelBodyText = "CfTabletView Body";

exports.btnExampleTitle = "CLICK ME";
exports.libraryTitle = "My Library";
exports.tablist = ["Procedures", "Product Info", "Forms", "Checklists", "Miscellaneous Docs"];
exports.filterByData = "Filter By Date";
exports.today = "Today";
exports.yesterday = "Yesterday";
exports.lastDays = "Last 15 days";
exports.filterMenuList = ["Today","Yesterday","Last 15 days"];
exports.getBranchApiEndpoint = "bx_block_branch/branches";
exports.getDeptApiEndpoint = "bx_block_departments/selected_departments/";
exports.checkListApiEndPoint = "bx_block_multipageforms2/checklists/?";
exports.deletecheckListApiEndPoint = "bx_block_multipageforms2/checklists/";
exports.getProcedureListApiEndPoint= "bx_block_cftabletview/procedures/?";
exports.branchPlaceholder = "Select Branch";
exports.noBranchMessage = "No Branch Found";
exports.noDepartmentMessage = "No Department Found";
exports.departSelect = "Select Department";
exports.date = "Date-";
exports.delete = "Delete";
exports.duplicate = "Duplicate";
exports.edit = "Edit";
exports.view = "View";
exports.create = "Create";
exports.fill = "Fill";
exports.favouriteMark = "Mark as Favourite";
exports.favouriteUnmark = "Unmark as Favourite";
exports.deleteProcedureApiEndPoint = "bx_block_cftabletview/procedures/"
exports.departSelect = "Select Department";
exports.departmentListApiEndpoint = "bx_block_departments/all_selected_departments";
exports.headerList = ["Procedures", "Procedure Name", "", "Date", "Action", "Favourite"];
exports.checkListHeaderList = ["Checklists", "Checklist Name", "", "Date", "Action", "Favourite"];
exports.formHeaderList = ["Forms", "Form Name", "", "Date", "Action", "Favourite"];
exports.title = "Currently No Procedure Found";
exports.libraryTitle = "My Library";
exports.procedureList = [
  {
    id: 1,
    procedure_name: "Example 1",
    date: "10/06/2023"
  },
  {
    id: 2,
    procedure_name: "Example 2",
    date: "10/06/2023"
  },
  {
    id: 3,
    procedure_name: "Example 3",
    date: "10/06/2023"
  },
  {
    id: 4,
    procedure_name: "Example 4",
    date: "10/06/2023"
  },
]
exports.from = "From";
exports.to = "To";
exports.favouriteProcedureApiEndPoint = "bx_block_cftabletview/procedures/";
exports.clearAllFilters = "Clear All Filter";
exports.TemplateNavigation = "/Template";
exports.deleteModalTxt = ["Are you sure you want to delete ","This Procedure Template"," from the library?"]
exports.yes = "yes";
exports.no = "no";
exports.templateDeleteTitle = "Procedure";
exports.procedureFrom = "from library";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.checklistDeleteTitle = "Checklist";
exports.checklistFrom = "from library";
exports.checklistNoDataTxt = "Currently No Checklist Found";
exports.FormApiEndPoint = "bx_block_multipageforms2/forms/?";
exports.deleteFormApiEndPoint = "bx_block_multipageforms2/forms/";
exports.formDeleteTitle = "Form";
exports.formNoDataTxt = "Currently No Form Found";
exports.productTemplateText = "Product Info Templates";
exports.menuTemplateText = "Menu Templates"
exports.fromLibrary = "from library";
// Customizable Area End