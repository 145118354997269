// Customizable Area Start
import React, { RefObject } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall, convertFormData, handleApiError, handleExpiredToken, checkToken, getDiffs, handleUndefinedData, sortArrayAlphabetically, isBO, userBranchId, usersDepartments, loggedUserId } from "../../../components/src/utils/commonFunctions";
import { Priorites } from "../../../components/src/utils/constant";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import moment from "moment";
export const configJSON = require("./config");
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { FormikErrors, FormikTouched } from "formik";
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: Record<string,string>

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  id: number;
  userId: number;
  loading: boolean;
  branches: OptionType[];
  departments: OptionType[];
  employees: OptionType[];
  priorites: OptionType[];
  statusList: Lists[];
  attachment: { name: string, url: File | string } | null | undefined;
  defaultAttachment: { name: string, url: File | string } | null | undefined;
  document: { name: string, url: File | string } | null ;
  comments: Comment[] | undefined;
  apiError: string;
  attachError: string;
  taskData: TaskRes;
  defaultTaskData: TaskRes;
  openStatus: boolean;
  isEditTask: boolean;
  commentId: {id: number | string};
  openCommentsList: boolean;
  openEdtiComment: boolean;
  opeDeleteComment: boolean;
  openSuccess: boolean;
  isEditTitle: boolean;
  isEditDescription: boolean;
  isEditDueDate: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
// Customizable Area Start
export interface TaskFormValues {
  title: string;
  description?: string;
  branch_id: string | number;
  department_id: string | number;
  assignee_ids:{id: string};
  due_date: string | Date;
  attachment?: {name: string ,url: File | string} | null;
  priority: string;
}
export interface TaskRes extends TaskFormValues {
  task_id: string | number;
  document?: {name: string ,url: File | string} | null;
  issue_date: string;
  status: string;
  status_name: string;
  comment: string;
  creator: {id: string | number};

  creatorData?: Creator;
  assignee_idsData?: Creator;
  feedback: string;
  rating: number | null;
  procedure?: ProcedureData | null;
  procedure_template?: ProcedureTemp | null;
  menu?: ProcedureData | null;
  menu_template?: ProcedureTemp | null;
  product_info?: ProcedureData | null;
  product_template?: ProcedureTemp | null;
  active?: boolean;
  comments?: Comment[];
}

export interface Creator {
  id: number | string,
  name: string;
  designation: string;
  email: string
}

interface ProcedureTemp {
  id: string | number;
  template_image: {
      url: string;
      name: string;
  }
}
interface ProcedureData {
  data : {
    type: string;
    attributes: {
        id: string | number;
        title: string;
        created_at: string;
        favorite: boolean;
        template_id: number;
        active: boolean;
        procedure_template: {
          data: {
            attributes: {
              template_image: {
                url: string;
                name: string;
              }
            }
          }
        }
    }
  }
}
export interface Priorities {
  label: string;
  value: string;
}
export interface Lists {
  name: string;
  value: string;
  isDisabled?: boolean;
  data?: object;
  id?: string | number;
}

export interface Employee {
  id: string | number;
  name: string;
  email: string;
  designation: string;
}
export interface Status {
  id?: string | number;
  name: string;
  text: string;
}

export interface Attachment {
  name: string
  url: string
}

export interface Comment {
  id: string
  comment: string
  created_at: string
  updated_at: string
  owner_name: string
  owner_id: number
  document?: Document
}

export interface Document {
  name: string
  url: string
}

type ValueType = OptionType | string | MaterialUiPickersDate | File | undefined | string[] | null
export interface ClearValue {
  [key: string]: string | string[]
}

export type RenderFormProps = {
  errors: FormikErrors<Partial<TaskRes>>,
  touched: FormikTouched<Partial<TaskRes>>,
  handleBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
  handleChange: (field: string | React.ChangeEvent<Element>) => void,
  setFieldValue: (field: string, value: OptionType | string | MaterialUiPickersDate | File | undefined | string[] | null, shouldValidate?: boolean | undefined) => void,
  values: TaskRes
};
// Customizable Area End

export default class EditTaskController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  anchorRef: RefObject<HTMLDivElement>;

  getTaskApiId: string = "";
  branchesApiCallId: string = "";
  departmentsApiCallId: string = "";
  employeesApiCallId: string = "";
  statusListApiCallId: string = "";
  editTaskCallId: string = "";

  deleteCommentCallId: string = "";
  editCommentCallId: string = "";
  addCommentCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    // Customizable Area Start
    this.anchorRef = React.createRef();
    const urlId = Number(this.props.navigation.getParam("id"));
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    const initialValues = {
      task_id: "",
      title: "",
      description: "",
      priority: "",
      due_date: new Date(),
      issue_date: "",
      status: "pending",
      status_name: "Pending",
      branch_id: "",
      department_id: "",
      assignee_ids: {id:""},
      creator: {id:""},
      attachment: {url:"",name:""},
      comment: "",
      document: {url:"",name:""},
      feedback: "",
      rating: 0,
      procedure: null,
      procedure_template: null,
      active: false,
    }

    this.state = {
      id: urlId,
      userId: Number(loggedUserId),
      loading: false,
      branches: [],
      departments: [],
      employees: [],
      priorites: Priorites,
      statusList: [],
      comments: [],
      attachment: null,
      document: null,
      apiError: "",
      attachError: "",
      taskData: initialValues,
      defaultTaskData: initialValues,
      openStatus: false,
      isEditTask: false,
      commentId: {id: ""},
      openCommentsList: false,
      openEdtiComment: false,
      opeDeleteComment: false,
      openSuccess: false,
      isEditTitle: false,
      isEditDescription: false,
      isEditDueDate: false,
      defaultAttachment:null
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    checkToken();
    this.setState({ loading: true });
    this.getTaskDetails();
    this.branchesApiCallId = apiCall({ method: configJSON.getMethod, endPoint: configJSON.getBranchesUrl, token: true });
    this.statusListApiCallId = apiCall({ method: configJSON.getMethod, endPoint: configJSON.getStatusUrl, token: true });
    // Customizable Area End
  }
  // Customizable Area Start


  componentDidUpdate(prevProps: Props, previousState: S) {
    const { taskData } = this.state;
    const { branch_id, department_id } = taskData;
    if ((branch_id && department_id) && (taskData.department_id !== previousState.taskData.department_id || taskData.branch_id !== previousState.taskData.branch_id)) {
      this.setState({ loading: true });
      this.employeesApiCallId = apiCall({ method: configJSON.getMethod, endPoint: `${configJSON.getEmployeesUrl}?department_id=${department_id}&branch_id=${branch_id}`, token: true })
    }
    if (branch_id && (taskData.branch_id !== previousState.taskData.branch_id)) {
      this.setState({ loading: true });
      this.departmentsApiCallId = apiCall({ method: configJSON.getMethod, endPoint: `${configJSON.getDepartmentsUrl}${branch_id}`, token: true })
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resJsonDataItem = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resJsonDataItem, this.props.navigation);
      this.setState({ loading: false });
      if (apiRequestCallId && resJsonDataItem) {
        this.setState({ loading: false });
        switch (apiRequestCallId) {
          case this.getTaskApiId:
            this.getTaskResponse(resJsonDataItem);
            break;
          case this.editTaskCallId:
            this.editTaskRes(resJsonDataItem);
            break;
          case this.statusListApiCallId:
            this.getStatusListRes(resJsonDataItem);
            break;
          case this.departmentsApiCallId:
            this.getDepartmentsResData(resJsonDataItem);
            break;
          case this.employeesApiCallId:
            this.getEmployeesRes(resJsonDataItem);
            break;
          case this.editCommentCallId:
            this.editCommentRes(resJsonDataItem);
            break;
          case this.deleteCommentCallId:
            this.deleteCommentRes(resJsonDataItem);
            break;
          default:
            break;
        }
      }
    }
    this.handleResponseComment(from,message)
    this.handleResponseForBranchListDropdown(from,message)
    // Customizable Area End
  }

  getTaskDetails = () => {
    this.setState({ loading: true });
    this.getTaskApiId = apiCall({ method: configJSON.getMethod, endPoint: `${configJSON.taskUrl}/${this.state.id}`, token: true });
  }

  duplicateTask = () => {
    this.props.navigation.history.push(`/CreateTask`, {...this.state.defaultTaskData, title: `${this.state.defaultTaskData.title} [Duplicate]`})
  }

  getTaskResponse = (responseData: {data: {attributes:TaskRes}}) => {
    const { data } = responseData;
    if (data) {
      const { task_id, title, description, priority, due_date, issue_date, status, status_name, branch_id, department_id, assignee_ids, creator, attachment, feedback, rating, comments, procedure, procedure_template, menu, menu_template, product_info, product_template } = data.attributes;
      const template = (procedure_template || menu_template || product_template);
      const TemplateData = procedure || menu || product_info;
      const objData = {
        task_id,
        title,
        description,
        priority,
        due_date,
        issue_date,
        status,
        status_name : status === "submit_for_review" ? "In Review" : status_name,
        branch_id,
        department_id: handleUndefinedData(department_id, ""),
        assignee_ids: handleUndefinedData(assignee_ids?.id, ""),
        assignee_idsData: handleUndefinedData(assignee_ids, null),
        creator: handleUndefinedData(creator, null),
        creatorData: handleUndefinedData(creator, null),
        attachment: null,
        feedback: handleUndefinedData(feedback, ""),
        rating: handleUndefinedData(rating, 0),
        comment: "",
        document: null,
        procedure: TemplateData,
        procedure_template : template,
        active: procedure?.data?.attributes?.active || menu?.data?.attributes?.active || product_info?.data?.attributes?.active
      }
      this.setState({
        taskData: objData,
        defaultTaskData: objData,
        attachment,
        defaultAttachment: attachment,
        document: null,
        comments
      });
    }
  };

  handleClearFile = (
    setFieldValue: { (field: string, value:File, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: null): void; },
    attachmentKey: keyof S,
    attachErrorKey: keyof S,
    ) => {
    this.setState({
      [attachmentKey]: {name:null,url:null},
      [attachErrorKey]: ""
    } as unknown as Pick<S, keyof S>)
    setFieldValue(attachmentKey, null)
  };
  handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: File | null) => void,
    attachmentKey: keyof S,
    attachErrorKey: keyof S,
  ): void => {
    const fileValue = event.target;
    if (fileValue.files && fileValue.files.length > 0) {
      const file = fileValue.files[0];

      if (file) {
        const allowFile = file.size <= 5 * 1024 * 1024;

        if (allowFile) {
          this.setState({
            [attachmentKey]: { name: file.name, url: URL.createObjectURL(file) },
            [attachErrorKey]: ""
          } as unknown as Pick<S, keyof S>)
          setFieldValue(attachmentKey, file)
        } else {
          this.handleClearFile(setFieldValue,attachmentKey,attachErrorKey);
          this.setState({ [attachErrorKey]: configJSON.fileSizeError } as unknown as Pick<S, keyof S>);
        }
      }
    }
  };

  getStatusListRes = (resData: { data: Status[]; }) => {
    if (resData.data) {
      const dataItem = resData.data.map(({ text, ...item }: Status) => ({ ...item, value: text, isDisabled: false }));
      this.setState({ statusList: dataItem });
    }
  };

  handleResponseForBranchListDropdown = (from: string, message: Message) => {
    if (this.branchesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.branches.data) {
        let branchDataList = !isBO() ? apiResponse.branches.data.filter((item:{id:number})=> Number(item.id) === Number(userBranchId)) : apiResponse.branches.data;
        branchDataList = branchDataList.map((item: { id: string; attributes: { location_of_point_of_sale: string, branch_name: string; }; }) => {
          return { value: Number(item.id), label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }
        });
        branchDataList = sortArrayAlphabetically(branchDataList, 'label');
        this.setState({
          branches: branchDataList
        })

      } else {
        this.setState({ branches: [] });
        toast.error(configJSON.branchError);
      }


    }
  }
  getEmployeesRes = (resData: { data: {id: string | number, attributes: {email : string}}[]; errors: string[]; }) => {
    if (resData.data) {
      const finalEmployeeData = resData.data.map((item) => {
        const { id, attributes } = item;
        return { label: attributes.email, value: Number(id) };
      })
      this.setState({ employees: finalEmployeeData });
    } else {
      this.setState({ employees: [] });
      handleApiError(resData.errors)
    }
  };

  deleteCommentRes = (resData: { message: string }) => {
    const { message } = resData;
    if (message) {
      toast.success(configJSON.commentDeletedMessage);
      this.getTaskDetails();
      this.handleCloseDelete();
    }
  };

  editCommentRes = (resData: { data: string; }) => {
    const { data } = resData;
    if (data) {
      toast.success(configJSON.commentEditedMessage);
      this.getTaskDetails();
      this.handleCloseEdit();
    }
  };

  editTaskRes = (resData: { data: {attributes:TaskRes}; errors: string[]; }) => {
    if (resData.data) {
      this.setState({ openSuccess: true });
      this.getTaskResponse(resData);
      this.handleDisableEdit();
    } else {
      handleApiError(resData.errors);
    }
  };
  handleResponseComment = (from: string, message: Message) => {
    if (this.addCommentCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      this.setState({ loading: false })
      if (apiResponse.data) {

        this.setState({ comments: apiResponse.data.attributes.comments,document:null })

      } else {
        handleApiError(apiResponse.errors);
      }
    }
  }

  checkCreator = () => {
    const { taskData } = this.state;
    return loggedUserId === taskData.creator.id;
  }

  checkTemplates = () => {
    const { procedure_template } = this.state.taskData;
    return procedure_template !== null;
  }

  checkDisabled = () => {
    const { isEditTask } = this.state;
    return !(this.checkCreator() && isEditTask);
  }

  editTask = (values: TaskRes, { resetForm }: { resetForm: () => void }) => {
    const { taskData, defaultTaskData } = this.state;

    const { title, description, priority, due_date, comment, status, branch_id, department_id, assignee_ids, feedback, rating, procedure } = defaultTaskData;

    const orriginalData = {
      status,
      title,
      description,
      department_id,
      branch_id,
      priority,
      assignee_ids,
      due_date: moment(due_date).format("YYYY-MM-DD"),
      ...((comment || document) ? { comment } : {}),
      ...(document ? { document } : {}),
      ...(rating ? { rating } : {}),
      ...(feedback ? { feedback } : {}),
    };

    const attrs = {
      status: taskData.status,
      title: values.title,
      description: values.description,
      department_id: values.department_id,
      branch_id: values.branch_id,
      priority: values.priority,
      assignee_ids: values.assignee_ids,
      due_date: moment(values.due_date).format("YYYY-MM-DD"),
      ...(values.attachment ? { attachment: values.attachment } : this.checkEmptyAttachment()),
      ...((values.comment || values.document) ? { comment: values.comment } : {}),
      ...(values.document ? { document: values.document } : {}),
      ...(values.rating ? { rating: values.rating } : {}),
      ...(values.feedback ? { feedback: values.feedback } : {}),
      ...(procedure ? { active : values.active} : {}),
    };

    let taskPayload = getDiffs(orriginalData, attrs);
    this.editTaskApiCall(taskPayload, { resetForm })
  }

  checkEmptyAttachment = () => {
    return this.state.attachment ? {} : { attachment: "" }
  }

  editTaskApiCall = (payload: object, { resetForm }: { resetForm: () => void }) => {
    const { id } = this.state;
    if (Object.keys(payload).length) {
      this.setState({ loading: true });
      this.editTaskCallId = apiCall({
        method: configJSON.putMethod,
        body: convertFormData(payload),
        endPoint: `${configJSON.taskUrl}/${id}`,
        token: true,
      });
      resetForm();
    }
  }
  addCommentApiCall = (payload: object, { resetForm }: { resetForm: () => void }) => {
    this.setState({ loading: true });
    const { id } = this.state;
    if (Object.keys(payload).length) {
      this.addCommentCallId = apiCall({
        method: configJSON.putMethod,
        body: convertFormData(payload),
        endPoint: `${configJSON.taskUrl}/${id}`,
        token: true,
      });
      resetForm();
    }
  }

  addComment = (values: TaskRes, { resetForm }: { resetForm: () => void }) => {
    const { defaultTaskData } = this.state;
    const { comment, document } = defaultTaskData;

    const currentData = {
      ...(values.document ? { document: values.document } : {}),
      ...((values.comment || values.document) ? { comment: values.comment } : {}),
    };
    const defaultData = {
      ...(document ? { document } : {}),
      ...((comment || document) ? { comment } : {}),
    };
    let payload = getDiffs(defaultData, currentData);
    this.addCommentApiCall(payload, { resetForm })
  }

  handleSelectStatus = (status: Lists) => {
    let name = status.name;
    if(status.value === "mark_as_complete") {
      name = "Completed";
    } else if(status.value === "mark_as_incomplete") {
      name = "Pending";
    } else if(status.value === "submit_for_review") {
      name = "In Review";
    }
    this.setState({ taskData: { ...this.state.taskData, status: status.value, status_name: name }, openStatus: false });
  }

  getAllowedStatusList = (): Lists[] => {
    let list = [...this.state.statusList];
    if (list.length) {
      if (this.checkCreator()) {
        if (this.state.defaultTaskData.status === "submit_for_review") {
          list = this.state.statusList.filter((item) => item.value === "mark_as_complete" || item.value === "mark_as_incomplete");
        } else if (this.state.defaultTaskData.status === "pending") {
          list = this.state.statusList.filter((item) => item.value === "pending");
          list[0].isDisabled = true;
        }
      } else if (!this.checkCreator() && this.state.taskData.procedure) {
        list = this.state.statusList.filter((item) => item.value === "mark_as_complete" || item.value === "mark_as_incomplete");
      } else {
          list = this.state.statusList.filter((item) => item.value === "pending" || item.value === "submit_for_review");
          list[0].isDisabled = true;
          list[1].isDisabled = this.state.defaultTaskData.status === "submit_for_review" ? true : false;
        }
    }
    return list;
  }

  getDepartmentsResData = (responseData: {id: string,departments:{name: string,id:string;photo:string}[]}[] ) => {
    const defaultData = { value: "", label: configJSON.taskDepartmentPlaceholder, isDisabled: true }
    if (responseData.length > 0) {
      let finalDepartDataItem: OptionType[] = [];
      isBO() ? responseData.map((item) => {
        item.departments.forEach((depart) => {
          const { id, name } = depart;
          return finalDepartDataItem.push({ label: name, value: Number(id) });
        })
      }) : usersDepartments.map((depart: { attributes: { id: number; name: string; }; }) => {
        const { id, name } = depart.attributes;
        return finalDepartDataItem.push({ label: name, value: Number(id) });
      })
      finalDepartDataItem = sortArrayAlphabetically(finalDepartDataItem, 'label');
      finalDepartDataItem.unshift(defaultData);
      this.setState({ departments: finalDepartDataItem });
    } else {
      this.setState({ departments: [defaultData] });
      toast.error(configJSON.taskDepartmentErrorMessage);
    }
  };

  handleEnableEdit = () => {
    this.setState({ isEditTask: true });
  }

  handleDisableEdit = () => {
    this.setState({ isEditTask: false, taskData: this.state.defaultTaskData,attachment:this.state.defaultAttachment, document: null, isEditTitle: false, isEditDescription: false, isEditDueDate: false });
  }

  toggleStatusModal = () => {
    this.setState({ openStatus: !this.state.openStatus });
  }
  closeStatusModal = (event: { target: Node | null; }) => {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }
    this.setState({ openStatus: false });
  }

  handleSelectChange = (eventItem: OptionType, setFieldValue: { (field: string, value: ValueType, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, keyItem: keyof TaskFormValues, clearValue?: ClearValue) => {
    let finalDataItem: OptionType | string = eventItem;
    if (finalDataItem && typeof finalDataItem === "object" && Array.isArray(finalDataItem)) {
      finalDataItem = eventItem.map((item: OptionType) => item.value)
    } else {
      finalDataItem = eventItem.value
    }

    if (clearValue && (this.state.taskData[keyItem] !== finalDataItem)) {
      this.setState({ taskData: { ...this.state.taskData, [keyItem]: finalDataItem, ...clearValue } });
      setFieldValue(keyItem, finalDataItem);
      for (let item of Object.keys(clearValue)) {
        setFieldValue(item, clearValue[item]);
      }
    } else {
      this.setState({ taskData: { ...this.state.taskData, [keyItem]: finalDataItem } });
      setFieldValue(keyItem, finalDataItem);
    }
  };
  
  openImageInNewTab = () => {
    const { document } = this.state;
    if (document && document.url instanceof File) {
      const itemUrl = URL.createObjectURL(document.url);
      window.open(itemUrl, '_blank');
    } else if (document && typeof document.url === 'string') {
      window.open(document.url, '_blank');
    }
  };
  
  handleRetingChange = (rating: number | null, setFieldValue: { (field: string, value: number | null, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }) => {
    setFieldValue("rating", rating);
    this.setState({ taskData: { ...this.state.taskData, rating } });
  }

  
  handleCloseComments = () => {
    this.setState({ openCommentsList: false });
  };
  handleOpenComments = () => {
    this.setState({ openCommentsList: true });
  };
  
  handleCloseEdit = () => {
    this.setState({ commentId:{id:""}, openEdtiComment: false });
  };
  handleOpenEdit = (data: {id:string}) => {
    this.setState({ commentId: data, openEdtiComment: true });
  };

  handleEditComment = (comment: string) => {
    this.setState({ loading: true });
    this.editCommentCallId = apiCall({
      method: configJSON.putMethod,
      body: convertFormData(comment),
      endPoint: `${configJSON.taskCommentUrl}/${this.state.commentId.id}`,
      token: true,
    });
  }

  handleOpenDelete = (data: {id: string}) => {
    this.setState({ commentId: data, opeDeleteComment: true });
  };

  handleCloseDelete = () => {
    this.setState({ commentId: {id:""}, opeDeleteComment: false });
  };

  handleDeleteComment = () => {
    this.setState({ loading: true });
    this.deleteCommentCallId = apiCall({ method: configJSON.deleteMethod, endPoint: `${configJSON.taskCommentUrl}/${this.state.commentId.id}`, token: true });
  }

  navigateToTaskList = () => {
    this.props.navigation.history.push("/TaskListView");
  }

  navigateToProcedure = () => {
    const {procedure} = this.state.taskData
    if(procedure?.data) {
      const { attributes, type } = procedure.data
      let template;
      if(type === "menu") {
        template = "/MenuTemplate"
      }else if(type === "product_info") {
        template = "/ProductTemplate" 
      } else if(type === "procedure") {
        template = "/Template" 
      }
      this.props.navigation.history.push(`${template}${attributes.template_id}`, attributes);
    } else {
      toast.error(configJSON.noProcedureTxt)
    }
  }

  handleCloseSuccess = () => {
    this.setState({ openSuccess: false, isEditTask: false });
  };

  
  toggleDescription = () => {
    this.setState({ isEditDescription: !this.state.isEditDescription });
  }
  
  toggleTitle = () => {
    this.setState({ isEditTitle: !this.state.isEditTitle });
  }

  toggleDueDate = () => {
    this.setState({ isEditDueDate: !this.state.isEditDueDate });
  }

  taskHeaderTitle = () => {
    return !this.checkDisabled() ? " Edit Created Task" : " Task Details"
  }

  checkDisabledWithProcedure = () => {
    const { isEditTask, taskData } = this.state;
    return !(this.checkCreator() && isEditTask) || !!taskData.procedure_template;
  }
  // Customizable Area End
}
