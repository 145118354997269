import { colors } from "../../../components/src/utils/commonDesign";

const errorMsg = {
  margin: 0,
  lineHeight: "18px",
  fontSize: "14px",
  marginBottom: "-23px",
  marginTop: "5px",
  color: colors.errorText,
}

export const styles: any = {

  card: {
    borderRadius: 16,
    margin: "auto",
    overflow: "auto"
  },
  cardContent: {
    padding: "35px 45px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  timeContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: 16,
  },
  companyProfileInputSection: {
    display: "grid",
    gridTemplateColumns: '1fr 1fr',
    justifyItems: "center"
  },
  companyProfileLeftSection: {
    "&.step1PaddingTop": {
      paddingTop: "15px"
    },
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  title: {
    color: colors.secondaryColor1,
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "42px",
    marginTop: 20,
  },
  container: {
    flexWrap: "nowrap",
    gap: "67px",
  },
  uploadImage: {
    position: "relative",
    bottom: "0",
    right: "0",
    height: 150,
    width: 176,
    border: "1px solid #969492",
    borderRadius: 8,
    cursor: "pointer",
    "& .innerContent": {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "100%",
      textAlign: "center",
      placeItems: "center",
      transform: "translate(-50%, -50%)",
      "& .filePreview": {
        width: "auto",
        height: "auto",
        maxHeight: 130,
        maxWidth: 130,
        objectFit: "contain",
        aspectRatio: "3/2"
      },
      "& .uploadImage": {
        width: 56,
        height: 55,
        paddingTop: 4,
        marginBottom: 9,
      },
      "& .uploadText": {
        color: colors.primaryColor2,
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "18px",
        margin: "8px 10px 0",
      },
    },
    "& .MuiFormControl-root": {
      height: "100%",
      width: "100%",
      zIndex: "100",
      "& .MuiInputBase-formControl": {
        height: "100%",
        width: "100%",
        borderRadius: "50%",
        border: "none",
        "& input": {
          padding: "0",
          position: "relative",
          zIndex: "99",
          opacity: "0",
          height: "100%",
          width: "100%",
          cursor: "pointer",
        },
        "& fieldset": {
          display: "none",
        },
      },
    },
  },
  uploadImage1: {
    width: "100% !important",
    border: "1px dashed #969492 !important",
  },
  uploadDocuments: {
    height: "140px !important",
    marginTop: "30px !important",
    "& .uploadImage": {
      width: "56px !important",
      height: "55px !important",
    },
  },
  form: {
    margin: "25px 72px 0",
  },
  form1: {
    margin: "35px 72px 0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  fieldWrapper: {
    margin: "25px 0 0px",
    width: "438px",
    "&.step1Grid": {
      width: "100%",
      maxWidth: "438px",
    },
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      height: "56px",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "21px",
        "& .Mui-disabled": {
          backgroundColor: "#F5F5F5",
          color: "#020202",
          borderRadius: "10px",
        },
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.secondaryColor1,
      },
    },
    "& .MuiFormHelperText-root": errorMsg,
  },
  inputLabel: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 400,
    color: colors.primaryColor2,
  },
  inputField: {
    margin: 0,
    marginTop: "10px",
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      height: "50px",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "21px",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.secondaryColor1,
      },
    },
  },
  dateField: {
    "& .MuiInputBase-root": {
      border: "1px solid #0000003b",
      padding: "0 14px",
    },
    "& .Mui-focused": {
      borderColor: colors.secondaryColor1,
    },
  },
  textAreaField: {
    height: "155px !important",
    "& .MuiInputBase-root": {
      height: "100% !important",
      "& .MuiInputBase-input": {
        height: "100% !important",
        overflow: "auto !important",
      },
    },
  },
  docList: {
    justifyContent: "space-between",
    padding: "0px 10px",
    height: "48px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    marginTop: "0",
    "& .docName": {
      fontSize: "16px",
      lineHeight: "21px",
      textDecoration: "none",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginRight: 20,
      fontWeight: "600",
    },
    "& .deleteIcon": {
      height: 24,
      width: 21,
      cursor: "pointer",
      marginRight: 20
    }
  },
  helperText: {
    color: colors.errorText,
    margin: 0,
    marginTop: "10px !important",
  },
  errorMsg,
  progressStepper: {
    padding: "0px",
    marginTop: 15,
    background: "transparent",
    color: colors.primaryColor2,
    "& .MuiMobileStepper-progress": {
      height: 8,
      width: 457,
      backgroundColor: "#bec3c1",
      borderRadius: 4,
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: colors.secondaryColor1,
    }
  },
  textStepper: {
    padding: "0px",
    background: "transparent",
    width: 436,
    color: colors.primaryColor2,
  },
  btnWrapper: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
    gap: "32px",
  },
  btnWrapper1: {
    marginTop: "65px",
    display: "flex",
    justifyContent: "center",
    gap: "32px",
    bottom: 47,
  },
  btn: {
    boxShadow: "none",
    fontWeight: 700,
    textTransform: "capitalize",
    height: "52px",
    width: "150px",
    borderRadius: "10px",
    fontSize: "16px",
    lineHeight: "21px",
    color: colors.primaryColor2,
    margin: '0px',
  },
  primaryBtn: {
    backgroundColor: colors.secondaryColor2,
    "&:hover": {
      backgroundColor: colors.secondaryColor2,
    }
  },
  secondaryBtn: {
    border: "1px solid black",
    "&:hover": {
      backgroundColor: colors.primaryColor1,
    }
  },
  uploadBox: {
    paddingTop: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "0 !important",
  },
  rightSection: {
    paddingTop: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  rightSection2: {
    "&.step1PaddingTop": {
      paddingTop: "15px"
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    position: "relative",
  },
  uploadWrapper: {
    maxWidth: "436px",
    width: "100%",
    marginTop: "",
    "& > div:nth-of-type(2)": {
      marginTop: "50px"
    }
  },
  uploadBtnStyle: {
    height: "56px",
    border: "1px solid #969492 !important",
    textTransform: "capitalize",
    width: "100%",

    "& input": {
      padding: "0",
      position: "relative",
      zIndex: "99",
      opacity: "0",
      height: "100%",
      width: "100%",
      cursor: "pointer",
    },
    "& .docItems": {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "100%",
      textAlign: "center",
      placeItems: "center",
      transform: "translate(-50%, -50%)",
      "& .uploadImage": {
        width: "25px",
        height: "25px",
        filter: "brightness(0.1)",
        margin: "5px"
      },
    }
  },
  fileInput: {
    display: "block",
    position: "relative",
    width: "100%",
    "& .marginBottom10": {
      marginBottom: "10px",
    },
    "&.marginTop51": {
      marginTop: "51px",
    },
    "& .MuiFormControl-root": {
      opacity: 0,
      overflow: "hidden",
      position: "absolute",
      right: "0",
      left: "0",
      "& input": {
        cursor: "pointer",
        fontSize: "0",
      },
    },
    "& .label-block": {
      border: " 1px solid rgba(0, 0, 0, 0.23)",
      height: "48px",
      margin: 0,
      display: "flex",
      alignItems: "center",
      padding: "0 15px",
      transition: "all 0.3s",
      lineHeight: "48px",
      borderRadius: "10px",
      cursor: "pointer",
      justifyContent: "center",
      fontSize: "16px",
      "& .file-name": {
        color: colors.primaryColor2,
        display: "block",
        marginLeft: "10px",
        lineHeight: "1.321",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "& img": {
        width: "25px",
        height: "25px",
        filter: "brightness(0.1)",
        margin: "5px"
      },
    },
  },
  uploadValidation: {
    color: "#969492",
    fontSize: "12px",
  },
  closeIcn: {
    zIndex: 1000,
    position: "absolute",
    top: 0,
    right: 0,
    padding: "2px 4px",
    "&:hover": {
      backgroundColor: "#beb8bc",
      borderRadius: 8,
    }
  },
  CompanyPhoneGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "50px",
    marginTop: 6,
    gap: 16,
  },
  inputWidth: {
    width: "72% !important",
    marginTop: 11,
    position: "relative",
    "& p": {
      position: "absolute",
      bottom: "-20px"
    }
  },
  dropdownWidth: {
    width: "145px"
  },

  paperStyle: {
    maxHeight: "220px",
  },

  listBox: {
    padding: 0,
    maxHeight: "220px",
  },
  clearIndicatorStyle : {
    display: 'none',
  },
  inputRoot : {
    paddingRight: '0px 16px !important',
  },
  input: {
    padding: '0 0 0 6px!important',
  },
};
