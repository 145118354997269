import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {
  apiCall,
  handleApiError,
  handleExpiredToken
} from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  // Customizable Area Start
  classes: Record<string,string>;
  open: boolean;
  handleClose: () => void;
  data: DataTypes;
  isEdit: boolean;
  isDuplicate: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  department: DepartmentIdObj[];
  issue_name: string;
  department_id: DepartmentIdObj[];
  ticket_type_id: number;
  allSelect: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface DepartmentIdObj {
  id: number;
  name: string;
  photo?: string;
}
interface FormValues {
  issue_name: string;
  department_id: DepartmentIdObj[];
}
interface DataTypes {
  issue_name: string;
  department_id: DepartmentIdObj[];
  ticket_type_id : number
}
interface Response {
  id: number;
  name: string;
  departments: DepartmentIdObj[];
}

// Customizable Area End

export default class TicketTypeFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  selectedDeptApiId: string = "";
  createTicketTypeApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      loading: false,
      department: [],
      department_id: [],
      issue_name: "",
      ticket_type_id: 0,
      allSelect: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resJson, this.props.navigation);
      this.setState({ loading: false });

      if (apiCallId && resJson) {
        if (apiCallId === this.selectedDeptApiId) {
          this.getDepartmentsResponse(resJson);
        } else if (apiCallId === this.createTicketTypeApiId) {
          this.getTicketTypeResponse(resJson);
        }
      }
      // Customizable Area End
    }
  }

  // Customizable Area Start
  async componentDidMount() {
    const { issue_name, department_id, ticket_type_id } = this.props.data
    if (this.props.isEdit) {
      this.setState({ issue_name: issue_name, department_id: department_id, ticket_type_id: ticket_type_id });
    }
    this.setState({ loading: true })
    this.selectedDeptApiId = apiCall({
      method: configJSON.getApiMethodType,
      endPoint: configJSON.departmentListApiEndpoint,
      token: true
    });
  }

  getDepartmentsResponse = (response: Response[]) => {
    let result: DepartmentIdObj[] = [];
    response.forEach(item => {
      result = result.concat(item.departments);
    });
    this.setState({ department: result });
  };

  getTicketTypeResponse = (response: {errors: string[]}) => {
    if (response.errors) {
      handleApiError(response.errors);
    } else {
      this.props.handleClose();
      toast.success(`Successfully ${this.props.isEdit ? "edited" : "created"} ticket type`);
    }
  };

  handleCreateTicket = (values: FormValues) => {
    let { issue_name, department_id } = values;
    const isDepartment_id: number[] = department_id.map(
      (item: DepartmentIdObj) => item.id
    );
    const isData = { issue_name, department_id: isDepartment_id };
    const httpBody = {
      data: { attributes: isData }
    };
    const baseUrl = configJSON.ticketTypeApiEndPoint
    this.createTicketTypeApiId = apiCall({
      contentType: configJSON.validationApiContentType,
      method: (this.props.isEdit && !this.props.isDuplicate) ? configJSON.putApiMethodType : configJSON.postApiMethodType,
      body: JSON.stringify(httpBody),
      endPoint: Boolean(this.props.isEdit && !this.props.isDuplicate) ? `${baseUrl}/${this.state.ticket_type_id}` : baseUrl,
      token: true
    });
  };

  handleSelectAll = (setFieldValue: { (field: string, value: DepartmentIdObj[], shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string[]): void; }) => {

    this.setState((prev) => {
      const value = !prev.allSelect ? this.state.department : []
      setFieldValue("department_id", value)
      return {
        allSelect: !prev.allSelect
      }
    })

  }

  handleChange = (event: React.ChangeEvent<{}>, values: {department_id: DepartmentIdObj[]}, setFieldValue: { (field: string, value: FormValues | DepartmentIdObj[], shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string[]): void; }) => {
    const inputEl = event as React.ChangeEvent<HTMLInputElement>
    const idAttributes = inputEl.target.getAttribute("id")
    const newVal = idAttributes ? JSON.parse(idAttributes) : null
    const check = values.department_id.find((item) => newVal.id === item.id)
    if (check) {
      setFieldValue("department_id", values.department_id.filter((item) => item.id !== newVal.id));
    } else {
      setFieldValue("department_id", [...values.department_id, newVal]);
    }
    this.setState({ allSelect: false })
  }

  // Customizable Area End


}
