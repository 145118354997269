import React, { Component, ReactNode, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Backdrop,
} from "@material-ui/core";
import { MoreHoriz as MoreHorizIcon, MoreVert as MoreVertIcon } from "@material-ui/icons";
import {
  DeleteIcon,
  CopyIcon,
  EditIcon,
  favouriteIcon,
  downloadIcon,
  ViewIcon,
} from "./assets";
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import { ClassNameMap } from "@material-ui/styles";
export const configJSON = require("./config");

export const styles: Styles<Theme, {}, string> = () => ({
  actionDropdown: {
    position: "relative",
    display: "inline-block",
  },
  dropdownContent: {
    position: "absolute",
    backgroundColor: "#f9f9f9",
    minWidth: "190px",
    boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
    zIndex: 1,
    right: "20px",
    top: "30px",
    padding: "10px",
    borderRadius: "8px",
    "&.position": {
      right: 28,
      top: 30,
    },
    "& .MuiButton-startIcon":{
      width:"25px",
      justifyContent: "center",
    },
    "& img": {
      marginRight: 10,
    },
    "& button": {
      alignItems: "center",
      width: "100%",
      justifyContent: "flex-start",
      textTransform: "none",
      "& p": {
        fontSize: 14,
        fontWeight:"400 !important",
        color:"#020202 !important"
      },
    },
  },
  backdrop: {
    zIndex: 0,
    backgroundColor: "transparent",
  },
});

interface ActionDropdownProps {
  isOpen: Record<number, boolean> | boolean | any;
  toggleDropdown: (item: any) => void;
  moreVert?: boolean;
  handleEdit?: () => void;
  handleDelete?: () => void;
  edit?: boolean | string;
  view?: boolean | string;
  download?: boolean | string;
  deleteVisible?: boolean | string | any;
  duplicate?: boolean | string;
  mark_as_favourite?: boolean | string;
  handleDuplicate?: () => void;
  handleDownload?: () => void;
  handleFavourite?: () => void;
  handleView?: (item: any) => void;
  favourite?: boolean;
  classes: ClassNameMap<"actionDropdown" | "dropdownContent" | "backdrop">;
}

export class  ActionButton extends Component<ActionDropdownProps> {
  constructor(props: ActionDropdownProps) {
    super(props);
  }

  render(): ReactNode {
    const {
      isOpen,
      toggleDropdown,
      moreVert,
      handleEdit,
      handleDelete,
      edit,
      download,
      deleteVisible,
      duplicate,
      mark_as_favourite,
      handleDuplicate,
      handleDownload,
      handleFavourite,
      view,
      handleView,
      classes,
      favourite,
    } = this.props;

    return (
      <Box className={`${classes.actionDropdown} positionIndex`}>
        <Button data-test-id="morevert" onClick={toggleDropdown}>
          {moreVert ? <MoreVertIcon /> : <MoreHorizIcon />}
        </Button>
        <Backdrop
          className={classes.backdrop}
          open={isOpen}
          onClick={() => toggleDropdown(false)}
        />
        {isOpen && (
          <Box className={`${classes.dropdownContent} ${moreVert && "position"} boxWidth`}>
            {edit && (
              <Button
                data-test-id="edit"
                onClick={handleEdit} 
                startIcon={<img src={EditIcon} />}
              >
                <Typography>{typeof edit === "string" ? edit : configJSON.editBtn}</Typography>
              </Button>
            )}
                 {view && (
              <Button
                data-test-id="view"
                onClick={handleView}
                startIcon={<img src={ViewIcon} />}
              >
                <Typography>{typeof view === "string" ? view : configJSON.viewBtn}</Typography>
              </Button>
            )}
            {download && (
              <Button
                data-test-id="download"
                onClick={handleDownload}
                startIcon={<img src={downloadIcon} />}
              >
                <Typography>{typeof download === "string" ? download : configJSON.downloadBtn}</Typography>
              </Button>
            )}
          
            {duplicate && (
              <Button
                data-test-id="duplicate"
                onClick={handleDuplicate}
                startIcon={<img src={CopyIcon} />}
              >
                <Typography>{typeof duplicate === "string" ? duplicate : configJSON.duplicateBtn}</Typography>
              </Button>
            )}
       
            {mark_as_favourite && (
              <Button
                data-test-id="favorite"
                onClick={handleFavourite}
                startIcon={<img src={favouriteIcon} />}
              >
                <Typography noWrap>
                  {favourite ? configJSON.UnfavouriteBtn : configJSON.favouriteBtn}
                </Typography>
              </Button>
            )}
              {deleteVisible && (
              <Button
                onClick={handleDelete}
                data-test-id="delete"
                startIcon={<img src={DeleteIcon} />}
              >
                <Typography>{typeof deleteVisible === "string" ? deleteVisible : configJSON.deleteBtn}</Typography>
              </Button>
            )}
          </Box>
        )}
      </Box>
    );
  }
}

export default withStyles(styles)(ActionButton);
