// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCall, checkToken, convertFormData, handleApiError, handleExpiredToken } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  // Customizable Area Start
  classes: Record<string,string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModal: boolean;
  file: File | null;
  isValid: boolean;
  errMsg: string;
  msg: string;
  loading: boolean;
  docList: DocData[];
  anchorEl: HTMLElement | null;
  searchQuery: string;
  searchDocList: DocData[] | null;
  currentDoc: DocData | null;
  open: boolean;
  title: string;
  docId: number | string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;

  // Customizable Area End
}

// Customizable Area Start
interface DocData {
  id: number
  name: string
  size: string
  url: string
  uploaded_date: string
}
interface Response {
  data: {
    attributes: {
      documents: DocData[]
    }
  }
}
// Customizable Area End

export default class CompanyDocController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadDocApiId: string = ""
  getDocApiId: string = ""
  deleteDocApiId: string = ""
  searchDocApiId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      openModal: false,
      file: null,
      isValid: false,
      errMsg: "",
      msg: "",
      loading: false,
      docList: [],
      anchorEl: null,
      searchQuery: "",
      searchDocList: null,
      currentDoc: null,
      open: false,
      title: "",
      docId: ""
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resJson, this.props.navigation);
      this.setState({ loading: false });

      if (apiCallId && resJson) {
        if (apiCallId === this.uploadDocApiId) {
          this.handleuploaddocresponse(resJson);
        } else if (apiCallId === this.getDocApiId) {
          this.handleGetDocResponse(resJson);
        } else if (apiCallId === this.deleteDocApiId) {
          this.handleDeleteApiResponse(resJson);
        } else if (apiCallId === this.searchDocApiId) {
          this.getSearchDeptResponse(resJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    checkToken(this.props.navigation);
    this.setState({ loading: true });
    this.getDocList()
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.searchQuery !== prevState.searchQuery) {
      this.setState({ loading: true });
      this.searchDocApiId = apiCall({
        method: configJSON.validationApiMethodType,
        endPoint: `${configJSON.serachDocEndPoint}${this.state.searchQuery}`,
        token: true
      })
    }
  }

  getDocList = () => {
    this.setState({ loading: true })
    this.getDocApiId = apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCompanyDocApiEndPoint,
      token: true
    });
  }

  handleOpenModal = (): void => {
    this.setState({ openModal: true })
  }

  handleCloseModal = (): void => {
    this.setState({ openModal: false })
    this.handleUploadRemove()
  }

  handleUploadBoxClick = (): void => {
    document.getElementById("upload-doc-file-input")?.click()
  }

  handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileType = file.type;
      if (
        fileType === "application/pdf" ||
        fileType === "application/msword" ||
        fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        const fileSize = file.size / (1024 * 1024);
        if (fileSize <= 5) {
          this.setState({
            file: file,
            msg: configJSON.uploadSuccessMsg,
            errMsg: "",
            isValid: true,
          });
        } else {
          this.setState({
            file: file,
            errMsg: configJSON.fileSizeErrorMsg,
            msg: "",
            isValid: false,
          });
        }
      } else {
        this.setState({ file: null, openModal: false });
        toast.error(configJSON.fileErrorMsg);
      }
    } else {
      this.setState({ file: null });
    }
  };



  handleUpload = () => {
    this.setState({ loading: true })
    const payload = {
      documents: [this.state.file]
    };

    this.uploadDocApiId = apiCall({
      method: configJSON.exampleAPiMethod,
      body: convertFormData(payload),
      endPoint: configJSON.uploadDocApiEndPoint,
      token: true,
    });
    this.handleCloseModal()
  }

  handleUploadRemove = () => {
    let isValue = document.getElementById("upload-doc-file-input");
    if (isValue) {
      (isValue as HTMLInputElement).value = "";
    }
    this.setState({ file: null, isValid: false });
  };


  handleuploaddocresponse = (response: { data: DocData, errors: string[] }) => {
    if (response.data) {
      this.setState({ loading: false })
      this.getDocList()
      toast.success(configJSON.docUploadedMsg)
    } else {
      this.setState({ loading: false })
      handleApiError(response.errors)
    }
  }

  handleGetDocResponse = (response: { data: { attributes: { documents: DocData[]; }; }; errors: string | object; }) => {
    if (response.data) {
      this.setState({ docList: response.data.attributes.documents, loading: false })
    } else {
      this.setState({ loading: false })
      handleApiError(response.errors)
    }
  }

  handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, currentDoc: DocData) => {
    this.setState({ anchorEl: event.currentTarget, currentDoc })
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  };

  handleDeleteClick = (item: DocData | null) => {
    if (item) {
      this.setState({ open: true, title: item.name.replace(/\(\d+\)\.pdf$|\.pdf$/, ''), docId: item.id });
    }

  }
  handleDeleteTicketList = () => {
    this.setState({ loading: true })
    this.deleteDocApiId = apiCall(
      {
        method: configJSON.deleteMethodType,
        endPoint: `${configJSON.docDeleteEndPoint}/${this.state.docId}`,
        token: true
      });
  }

  handleDeleteApiResponse = (response: { message: string[]; errors: string[] }) => {
    this.handleMenuClose()
    if (response.message) {
      this.setState({ loading: false, open: false })
      this.getDocList()
      toast.success(configJSON.docDeleteMsg)
    } else {
      this.setState({ loading: false, open: false })
      handleApiError(response.errors)
    }
  }

  handleSearch = (query: string) => {
    this.setState({ searchQuery: query })
  }

  getSearchDeptResponse = (response: Response) => {
    this.setState({ searchDocList: response.data.attributes.documents.length > 0 ? response.data.attributes.documents : null });
  }

  handleClose = () => {
    this.setState({ open: false, anchorEl: null });
  };


  // Customizable Area End
}



