import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, TextField, Button, Grid, FormHelperText, InputAdornment,Switch } from "@material-ui/core";
import ProductTemplate1Controller, { Props, configJSON } from "./ProductTemplate1Controller.web"
import { commonInputField, commonButton, helperText, singleValueCss, commonTemplateCss } from "../../../components/src/utils/commonInputStyle";
import { Formik, Form } from 'formik';
import { Wrapper } from "../../../components/src/Wrapper";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import { allowOnlyPrice, handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import DropdownSearch from "../../../components/src/commonComponents/DropdownSearch.web";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import Loader from "../../../components/src/Loader";

// Customizable Area End

// Customizable Area Start
export const styles = () => createStyles({
  ...commonButton,
  ...commonTemplateCss,
  ...commonInputField

});
// Customizable Area End

export class ProductTemplate1 extends ProductTemplate1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { productData, branchData, categoryData, imageUrl, template_id, loading, departmentData, productTempAction, openMenu, opeDeleteModal, isDownload } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper bellIcn backbtn={true} navigation={this.props.navigation} divider title={this.headerTitle()} homeIcn={true} child={
          <Box>
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              validationSchema={this.productTempValidationSchema()}
              initialValues={this.productTempInitialValues()}
              onSubmit={this.addEditTemplate}
            >
              {({ touched, handleBlur, handleChange, setFieldValue, values, errors }) => (
                <Form className={`${classes.formContainer} removePadding ${isDownload && "textColor"}`} translate>
                  <div className={`${classes.actionBtnContainer} ${isDownload && "visibility"}`}>
                    {productTempAction !== configJSON.create &&
                      <ActionButton
                        toggleDropdown={this.toggleMenu}
                        deleteVisible={this.allowProductEdit()}
                        handleDownload={this.downloadProductTemp}
                        edit={this.allowProductEdit()}                       
                        download
                        handleEdit={() => this.handleActions(configJSON.edit)}
                        duplicate={this.allowProductEdit()}
                        handleDelete={this.handleDelete}
                        isOpen={openMenu}
                        handleDuplicate={this.handleDuplicateProductTemp}
                        mark_as_favourite
                        favourite={productData.favorite}
                        handleFavourite={this.handleProductFavouriteClick}
                      />
                    }
                  </div>
                  <Grid ref={this.pdfRef} direction="row" alignItems="stretch" className={classes.gridContainer} container spacing={5}>
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      {this.isViewOnly() ?
                        <Typography data-test-id="title" variant="h4" className={`${classes.inputLabel2} title`}>
                          {values.name}
                        </Typography> :
                        <Box className={`${classes.fieldWrapper} marginTitle`}>
                          <InputLabel className={`${classes.inputLabel}`} required>
                            {configJSON.productTempNameLabel}
                          </InputLabel>
                          <TextField
                            onChange={handleChange}
                            type="text"
                            name={configJSON.productTempNameName}
                            data-test-id="title_input_field"
                            variant="outlined"
                            value={values.name}
                            className={classes.inputField}
                            fullWidth
                            error={handleInvoiceErrors(
                              errors.name,
                              touched.name,
                            )}
                            helperText={handleInvoiceHelperText(
                              errors.name,
                              touched.name
                            )}
                            onBlur={handleBlur}
                          />
                        </Box>
                      }
                      <div className={classes.dropdownContainer}>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            value={values.branch_id}
                            singleValueCss={isDownload && singleValueCss}
                            id={configJSON.templateBranchName}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "branch_id", { department_id: "" })}
                            noOptionsMessage={configJSON.templateNoBranchMessage}
                            isSearchable={true}
                            options={branchData}
                            placeholder={configJSON.templateBranchPlaceholder}
                            showError={handleInvoiceErrors(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            errorText={handleInvoiceHelperText(
                              errors.branch_id,
                              touched.branch_id
                            )}
                            isDisabled={this.isViewOnly()}
                            isMyProfile={true}
                          />
                        </Box>
                        <Box className={classes.dropdownWrapper}>
                          <DropdownSearch
                            value={values.department_id}
                            onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "department_id")}
                            singleValueCss={isDownload && singleValueCss}
                            isDisabled={this.isViewOnly() || !values.branch_id}
                            noOptionsMessage={configJSON.templateNoDepartmentMessage}
                            options={departmentData}
                            id={configJSON.templateDepartmentName}
                            errorText={handleInvoiceHelperText(
                              errors.department_id,
                              touched.department_id,
                            )}
                            placeholder={configJSON.templateDepartmentPlaceholder}
                            isMyProfile={true}
                            showError={handleInvoiceErrors(
                              errors.department_id,
                              touched.department_id,
                            )}
                            isSearchable={true}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                      <Box className={`${classes.fieldWrapper} marginTitle`}>
                        <InputLabel required className={classes.inputLabel}>
                          {configJSON.productTempCategoryLabel}
                        </InputLabel>
                        <DropdownSearch
                          value={values.product_info_type_id}
                          options={categoryData}
                          onChangeValue={(event: any) => this.handleSelectChange(values, event, setFieldValue, "product_info_type_id")}
                          id={configJSON.productTempCategoryName}
                          noOptionsMessage={configJSON.productTempNoCategoryMessage}
                          isSearchable={true}
                          isDisabled={this.isViewOnly()}
                          placeholder={""}
                          showError={handleInvoiceErrors(
                            errors.product_info_type_id,
                            touched.product_info_type_id
                          )}
                          errorText={handleInvoiceHelperText(
                            errors.product_info_type_id,
                            touched.product_info_type_id
                          )}
                          singleValueCss={isDownload && singleValueCss}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                    <Grid item className={classes.gridItem} xs={12}>
                      <Grid container className={`${template_id === 2 && classes.reverseContainer} ${classes.gridContainer}`} spacing={5}>
                        <Grid item className={`${classes.gridItem} marginTop`} sm={6}>
                          <Box className={classes.fieldWrapper}>
                            <Box className={`${classes.uploadImageContainer} productTempHeight1`}>
                              <ImageUploadView
                                name={configJSON.image}
                                handleImageChange={this.handleImageChange}
                                isDisabled={this.isViewOnly()}
                                currentImage={imageUrl?.url}
                                setFieldValue={setFieldValue}
                                uploadTexts={["Upload Image"]}
                              />
                            </Box>
                            {handleInvoiceErrors(
                              errors.image,
                              touched.image
                            ) && (
                                <FormHelperText
                                  style={helperText}
                                >
                                  {handleInvoiceHelperText(
                                    errors.image,
                                    touched.image,
                                  )}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel}>
                              {configJSON.descriptionLabel}
                            </InputLabel>
                            <TextField
                              fullWidth
                              type="text"
                              disabled={this.isViewOnly()}
                              value={values.description}
                              variant="outlined"
                              multiline
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={configJSON.templateDescriptionName}
                              className={`${classes.inputField} ${classes.textAreaInputHeight} ${classes.textAreaField}`}
                            />
                          </Box>
                          <Box className={classes.fieldWrapper}>
                            <InputLabel className={classes.inputLabel}>
                              {configJSON.faqLabel}
                            </InputLabel>
                            <TextField
                              fullWidth
                              type="text"
                              disabled={this.isViewOnly()}
                              value={values.faq}
                              variant="outlined"
                              multiline
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={configJSON.faqName}
                              className={`${classes.inputField} ${classes.textAreaInputHeight} ${classes.textAreaField}`}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item className={`${classes.gridItem}`} sm={6}>
                     <Box className={`${classes.gridItem} ${classes.productGrid2}`}>
                     <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                        <InputLabel required className={classes.inputLabel}>
                          {configJSON.usdLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.usd}
                          name={configJSON.usdName}
                          fullWidth
                          onChange={handleChange}
                          InputProps={{
                            startAdornment: productTempAction !== configJSON.create && <InputAdornment className={classes.usdSymbole} position="start">$</InputAdornment>,
                          }}
                          className={classes.inputField}
                          onKeyPress={allowOnlyPrice}
                          disabled={this.isViewOnly()}
                          helperText={handleInvoiceHelperText(
                            errors.usd,
                            touched.usd
                          )}
                          error={handleInvoiceErrors(
                            errors.usd,
                            touched.usd
                          )}
                          onBlur={handleBlur}
                        />
                      </Box>
                      <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                        <InputLabel className={classes.inputLabel}>
                          {configJSON.itemCodeLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.item_code}
                          name={configJSON.itemCodeName}
                          fullWidth
                          onChange={handleChange}
                          className={classes.inputField}
                          disabled={true}
                          onBlur={handleBlur}
                        />
                      </Box>
                     </Box>
                      <Box className={`${classes.approvalContainer} ${isDownload && "visibility"}`}>
                      <Typography className={`${classes.inputLabel2} approvalText`} variant="h4">
                        {configJSON.sendForApproval}
                      </Typography>
                      <Switch
                        disabled={this.state.productTempAction !== configJSON.create}
                        name="send_for_approval"
                        color="primary"
                        onChange={handleChange}
                        checked={values.send_for_approval}
                      />
                    </Box>
                    </Grid>
                    <Grid item className={`${classes.gridItem} ${classes.productGrid3}`} sm={6}>
                      <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                        <InputLabel className={classes.inputLabel}>
                          {configJSON.pairWithLabel}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.pair_with1}
                          name={configJSON.pair_with1}
                          fullWidth
                          onChange={handleChange}
                          className={classes.inputField}
                          disabled={this.isViewOnly()}
                          onBlur={handleBlur}
                        />
                      </Box>
                      <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.pair_with2}
                          name={configJSON.pair_with2}
                          fullWidth
                          onChange={handleChange}
                          className={`${classes.inputField} ${classes.paddingTop18}`}
                          disabled={this.isViewOnly()}
                          onBlur={handleBlur}
                        />
                      </Box>
                      <Box className={`${classes.fieldWrapper} ${classes.fieldWrapper6}`}>
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.pair_with3}
                          name={configJSON.pair_with3}
                          fullWidth
                          onChange={handleChange}
                          className={`${classes.inputField} ${classes.paddingTop18}`}
                          disabled={this.isViewOnly()}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={`${classes.templateBtnWrapper} productBtnContainer`}>
                    {!this.isViewOnly() &&
                      <Button
                        type="submit"
                        data-test-id="create-btn"
                        variant="contained"
                        className={classes.primaryBtn}
                      >
                        {configJSON.saveBtnText}
                      </Button>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              onClose={this.handleDeleteClose}
              open={opeDeleteModal}
              from={configJSON.productFrom}
              title={configJSON.productTempDeleteTitle}
              confirmDelete={this.handleDeleteTemplate}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(ProductTemplate1);
// Customizable Area End

