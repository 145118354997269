// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Typography,
    TextField,
    InputLabel,
    Card,
    CardContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import LoginSignupRightSection from "../../../components/src/LoginSignupRightSection.web";
import { styles } from "../../../components/src/utils/Styles.web";
import LoginSignupBackgroundImgWeb from "../../../components/src/LoginSignupBackgroundImg.web";
import {
    handleInvoiceErrors,
    handleInvoiceHelperText,
} from "../../../components/src/utils/commonFunctions";
import { forgotPasswordSchema } from "../../../components/src/utils/validationSchema";
import SuccessModal from "../../../components/src/commonComponents/SuccessModal.web";
const logo = require("../../../components/src/qwipLogo.svg");
export const configJSON = require("./config");
// Customizable Area End


// Customizable Area Start
// Customizable Area End

export class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { email , open } = this.state
        return (
            <React.Fragment>
                <LoginSignupBackgroundImgWeb />
                <Card className={classes.mainCard}>
                    <CardContent className={classes.cardContentInner}>
                        <Box className={classes.mainContainer}>
                            <Box className={classes.logoHeader}>
                                <img src={logo} alt="qwipLogo" />
                            </Box>
                            <Typography variant="h4" className={classes.headerTitle}>
                                {configJSON.forgotPasswordText}
                            </Typography>
                            <Formik
                                enableReinitialize={true}
                                validateOnBlur={true}
                                initialValues={{
                                    email: email,
                                }}
                                validationSchema={forgotPasswordSchema}
                                onSubmit={this.handleSave}
                            >
                                {({
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    handleBlur,
                                }) => {
                                    return (
                                        <Form translate={undefined}>
                                            <Box className={classes.fieldWrapper}>
                                                <InputLabel className={classes.inputLabel}>
                                                    {configJSON.firstInputPlaceholder}
                                                </InputLabel>
                                                <TextField
                                                    data-test-id="txtInputEmail"
                                                    type="email"
                                                    name="email"
                                                    variant="outlined"
                                                    placeholder={configJSON.firstInputPlaceholder}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    className={classes.inputField}
                                                    error={handleInvoiceErrors(
                                                        errors.email,
                                                        touched.email
                                                    )}
                                                    helperText={handleInvoiceHelperText(
                                                        errors.email,
                                                        touched.email
                                                    )}
                                                />
                                            </Box>

                                            <Box className={classes.btnWrapper}>
                                                <Button
                                                    data-test-id={"otp_send_btn"}
                                                    variant="contained"
                                                    fullWidth
                                                    className={classes.innerBtn}
                                                    type="submit"
                                                    disabled={values.email === "" ? true : false}
                                                >
                                                    {configJSON.sendOtpText}
                                                </Button>
                                            </Box>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Box>
                        <Box className={classes.imageBox} >
                            <LoginSignupRightSection />
                        </Box>
                    </CardContent>
                </Card>
                <SuccessModal
                    title={configJSON.greatTitle}
                    message={configJSON.confirmMessage}
                    open={open}
                    handleClick={this.handleOkClick}
                    buttonText={configJSON.okBtnText}
                />
            </React.Fragment>
        );
        // Customizable Area End
    }
}

export default withStyles(styles)(ForgotPassword);
