// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, handleExpiredToken, handleApiError } from "../../../components/src/utils/commonFunctions";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    classes: Record<string,string>
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    email: string;
    open: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}
// Customizable Area Start

// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    sendOtpCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            email: "",
            open: false
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    handleSave = (values: { email: string }) => {
        this.handleOtp(values)
        this.setState({email:values.email})
    }
    handleOkClick = () => {
        this.props.navigation.history.push("/ForgotPasswordOTP",{email:this.state.email})
    }
    handleOtp = (values: { email: string }) => {
        const httpBody = {
            data: {
                attributes: {
                    email: values.email
                }
            }
        }
        this.sendOtpCallId = apiCall({ method: configJSON.httpPostMethod, body: JSON.stringify(httpBody), contentType: configJSON.forgotPasswordAPiContentType, endPoint: configJSON.senOtpApiEndPoint });
    }

    handleResponseForSendOtp = async (from: string, message: Message) => {
        if (this.sendOtpCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(apiResponse, this.props.navigation);
            if (apiResponse.data) {
                this.setState({ open: true })
                await setStorageData(configJSON.tokenType, apiResponse.meta.token)  
            } else {
                handleApiError(apiResponse.errors);
            }
        }
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResponseForSendOtp(from, message)
        // Customizable Area End
    }
    // Customizable Area End


}
