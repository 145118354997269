// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, handleExpiredToken, checkToken, handleApiError,capitalizeFirstLetter, sortArrayAlphabetically, convertAllFormData, getNavigationMessage, downloadPDF, handleExcelDownload, downloadCSV, removeNullFromObj, isBO, usersDepartments } from "../../../components/src/utils/commonFunctions";
import moment from "moment";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { selectAllOption } from "../../../components/src/utils/constant";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: Record<string,string>

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  employee_list: EmployeeDetails[];
  meta: {
    total_pages: number;
    next_page: null | number;
    previous_page: null | number;
    total_count: number;
    current_page: number;
  },
  department_list: DepartmentList[];
  select_department: string | null;
  is_open_department: boolean;
  selectedDepartmentId: number | null;
  is_open_download: boolean;
  select_downloadTYpe: string | null;
  is_open_role: boolean;
  select_role: string | null;
  select_edit_role: string | null;
  selectedRoleId: number | null;
  loading: boolean;
  openEditModel: boolean
  item: Partial<EmployeeDetails>
  searchQuery: string;
  is_edit: boolean;
  is_open_edit_role: boolean;
  roleId: number | null;
  select_edit_department: string | null | boolean;
  is_open_edit_department: boolean;
  assigned_department_ids: number[] | string[] | OptionType;
  selected_departments: {
    id: number | string;
    value: number | string;
    label: string
  }[];
  autoFocus: boolean;
  openSuccessModel: boolean;
  select_edit_status: string;
  is_open_edit_status: boolean;
  company_name: string;
  all_employee_list: string[][]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start

export interface DepartmentList {
  id: number;
  text: string;
  name: string;
}

export interface RoleList {
  id: number;
  text: string;
  name: string;
}

export interface EmployeeDetails {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    country_code: string | null;
    email: string;
    first_name: string;
    full_phone_number: string;
    last_name: string;
    phone_number: string | null;
    role_id: number;
    photo: null | string
    location_of_point_of_sale: string
    joining_date: string | number | null
    status: string
    role_name: string;
    branch_name: string | null;
    branch_id: string | number | undefined;
    department_names: string[];
    gender: string;
    assigned_departments: {
      data: Daum[]
    },
    profile: {
      data: {
        id: string | number;
        attributes: {
          owner_name: string;
          phone_number: string;
          owner_email: string;
          photo: string
        }
      } | null
    },
    team_members: TeamMember[]

  };
}

export interface EmployeeDataForPdf {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    email: string;
    joining_date: string | null;
    created_at: string | null;
    updated_at: string | null;
    role_id: number;
    branch_id: string | number;
    role_name: string;
    country_code?: string | null;
    phone_number: string | null;
    location_of_point_of_sale: string
    status: string
    owner_name: string;
  };
}
export interface TeamMember {
  id: number
  role: string
  photo: string | null
}
export interface Daum {
  id: string
  type: string
  attributes: Attributes2
}

export interface Attributes2 {
  id: number
  name: string
  category_name: string
  photo: string
}

// Customizable Area End

export default class EmployeeDirectoryController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  employeeListDataCallId: string = "";
  departmentDataApiCallId: string = "";
  editemployeeDataApiCallId: string = "";
  allEmployeesListDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      employee_list: [],
      meta: {
        total_pages: 1,
        total_count: 1,
        current_page: 1,
        next_page: null,
        previous_page: null
      },
      select_department: "",
      selectedDepartmentId: null,
      is_open_department: false,
      is_open_download: false,
      select_downloadTYpe: "",
      department_list: [],
      is_open_role: false,
      select_role: "",
      select_edit_role: "",
      selectedRoleId: null,
      loading: false,
      openEditModel: false,
      item: {},
      searchQuery: "",
      is_edit: false,
      is_open_edit_role: false,
      roleId: null,
      select_edit_department: "",
      is_open_edit_department: false,
      assigned_department_ids: [],
      selected_departments: [],
      autoFocus: true,
      openSuccessModel: false,
      select_edit_status: "",
      is_open_edit_status: false,
      company_name: "",
      all_employee_list: []

    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    checkToken(this.props.navigation);
    this.employeeListData(this.state.meta.current_page)
    this.allEmployeesList()
    this.getDepartmentData()
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: S) {
    const { selectedDepartmentId, searchQuery, selectedRoleId, meta, item } = this.state
    if (searchQuery !== prevState.searchQuery || selectedRoleId !== prevState.selectedRoleId || selectedDepartmentId !== prevState.selectedDepartmentId) {
      this.employeeListData(meta.current_page)
      this.allEmployeesList()
    }
    if (item.attributes?.branch_id !== prevState.item.attributes?.branch_id) {
      this.getDepartmentData()
    }
  }
  handleSelectStatus = (value: { name: string; id: string | number }) => {
    this.setState({
      select_edit_status: value.name,
      is_open_edit_status: false,
    });
  };

  toggleSelectStatus = () => {
    this.setState((prevState) => ({
      is_open_edit_status: !prevState.is_open_edit_status
    }));
  };
  handleSearch = (query: string) => {
    this.setState({ searchQuery: query })
  }
  handleOkClick = () => {
    this.send(getNavigationMessage('HomePage', this.props));
  }
  handleCancel = () => {
    this.setState({
      openEditModel: false,
      item: {}
    })
  }
  handleSelectDepart = (value: DepartmentList) => {
    this.setState({
      select_department: value.name,
      is_open_department: false,
      selectedDepartmentId: value.id,
    });
  };
  handleClearAllFilter = () => {
    this.setState({
      searchQuery: "",
      select_department: "",
      select_role: "",
      selectedDepartmentId: null,
      selectedRoleId: null
    })
  }
  handleDisable = () => {
    const { select_department, select_role, selectedDepartmentId, selectedRoleId } = this.state
    let allData = { select_department, select_role, selectedDepartmentId, selectedRoleId, query: this.state.searchQuery };
    const disableData = removeNullFromObj(allData)
    return (Object.keys(disableData).length <= 0)
  }
  handleSelectEditDepart = (value: DepartmentList) => {
    this.setState({
      select_edit_department: value.name,
      is_open_edit_department: false,
      assigned_department_ids: [value.id],
    });
  };
  handleEditable = () => {
    this.setState({
      is_edit: true,
    });
    this.props.navigation.history.push(`EmployeeProfileDetails/${this.state.item.attributes?.profile.data?.id}`, true)
  }
  toggleSelectDepart = () => {
    this.setState((prevState) => ({
      is_open_department: !prevState.is_open_department,
      is_open_role: false,
      is_open_download: false

    }));
  };
  toggleSelectEditDepart = () => {
    this.setState((prevState) => ({
      is_open_edit_department: !prevState.is_open_edit_department,
      is_open_edit_role: false,

    }));
  };
  handleSelectDownload = (value: DepartmentList) => {
    this.setState({
      select_downloadTYpe: value.name,
      is_open_download: true
    });
  };

  toggleSelectDownloadModal = () => {
    this.setState((prevState) => ({
      is_open_download: !prevState.is_open_download,
      is_open_department: false,
      is_open_role: false,
    }));
  };
  handleExport = () => {
    const { select_downloadTYpe, all_employee_list } = this.state
    if (select_downloadTYpe === configJSON.downloadType[2].name) {
      downloadPDF(all_employee_list, configJSON.employeeHeaderData, "Employee Directory", "Employee_Directory")
      this.setState({ is_open_download: false, select_downloadTYpe: "" })
    } else if (select_downloadTYpe === configJSON.downloadType[0].name) {
      handleExcelDownload(configJSON.employeeHeaderData, all_employee_list, "Employee_Directory")
      this.setState({ is_open_download: false, select_downloadTYpe: "" })
    } else {
      downloadCSV(configJSON.employeeHeaderData, all_employee_list, "Employee_Directory")
      this.setState({ is_open_download: false, select_downloadTYpe: "" })
    }

  }

  handleSelectRole = (value: RoleList) => {
    this.setState({
      select_role: value.name,
      is_open_role: false,
      selectedRoleId: value.id,
      employee_list: []
    });
  };
  handleSelectEditRole = (value: RoleList) => {
    this.setState({
      select_edit_role: value.name,
      is_open_edit_role: false,
      roleId: value.id
    });
  };

  toggleSelectRole = () => {
    this.setState((prevState) => ({
      is_open_role: !prevState.is_open_role,
      is_open_download: false,
      is_open_department: false,
    }));
  };
  toggleSelectEditRole = () => {
    this.setState((prevState) => ({
      is_open_edit_role: !prevState.is_open_edit_role,
      is_open_edit_department: false
    }));
  }
  handlePageChange = (pageNumber: number) => {
    this.setState({
      meta: {
        ...this.state.meta,
        current_page: pageNumber
      }
    });
    this.employeeListData(pageNumber)
  };
  handleClose = () => {
    this.setState({ openEditModel: false, is_edit: false, item: {} })
  }

  handleSelectChange = (event: OptionType | string[] | number[]) => {
    let finalResult = event;
    if (finalResult && typeof finalResult === "object" && Array.isArray(finalResult)) {
      finalResult = event.map((item: OptionType) => item.value)
    }
    this.setState({ assigned_department_ids: finalResult ?? [] });
  }

  handleEdit = (item: EmployeeDetails) => {
    this.setState({
      openEditModel: true,
      item: item,
      select_edit_role: item.attributes.role_name,
      roleId: item.attributes.role_id,
      select_edit_department: item.attributes.department_names != null && item.attributes.department_names[0],
      assigned_department_ids: item.attributes.assigned_departments.data.map(item => item.attributes.id).filter((item:string| number) => item !== selectAllOption.value),
      autoFocus: false,
      select_edit_status: item.attributes.status
    })
  }
  employeeListParams = ():string => {
    let queryParam = "";
    const { selectedDepartmentId, searchQuery, selectedRoleId } = this.state
    if (selectedDepartmentId) {
      queryParam += `&department_id=${selectedDepartmentId}`;
    }
    if (searchQuery) {
      queryParam += `&query=${searchQuery}`;
    }
    if (selectedRoleId) {
      queryParam += `&role_id=${selectedRoleId}`;
    }
    return queryParam
  }

  employeeListData = (page: number) => {
    this.setState({ loading: true })
    const baseEndpoint = configJSON.getEmployeeListingApiEndpoint + page;
    
    let queryParam = this.employeeListParams();

    const endpoint = queryParam ? `${baseEndpoint}${queryParam}` : baseEndpoint;
    this.employeeListDataCallId = apiCall({
      method: configJSON.postApiMethod,
      endPoint: endpoint,
      token: true,
    });
  }

  allEmployeesList = () => {
    const baseEndpoint = configJSON.getAllEmployeeListingApiEndpoint;
    let queryParam = this.employeeListParams();

    const endpoint = queryParam ? `${baseEndpoint}${queryParam}` : baseEndpoint;
    this.allEmployeesListDataCallId = apiCall({
      method: configJSON.postApiMethod,
      endPoint: endpoint,
      token: true,
    });
  }

  getDepartmentData = () => {
    const branchId = this.state.item?.attributes?.branch_id;
    if (branchId !== undefined && branchId !== null && branchId !== "") {
      this.departmentDataApiCallId = apiCall({
        method: configJSON.getApiMethod,
        endPoint: `${configJSON.selectedDepartmentApiEndpoint}/${branchId}`,
        token: true
      });
    } else {
      this.departmentDataApiCallId = apiCall({
        method: configJSON.getApiMethod,
        endPoint: configJSON.departmentListApiEndpoint,
        token: true
      });
    }
  };
  mapAllEmployeesAttributes = (item: EmployeeDataForPdf) => {
    return [
      item.attributes.owner_name,
      item.attributes.email,
      item.attributes.role_name,
      item.attributes.phone_number?.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3'),
      item.attributes.joining_date ? moment(item.attributes.joining_date).format("DD/MM/YYYY") : "",
      capitalizeFirstLetter(item.attributes.status),
      item.attributes.location_of_point_of_sale.replace(/,/g, '')
    ];
  }
  editEmployee = () => {
    const { roleId, item, select_edit_status } = this.state
    const attrs = {
      role_id: roleId,
      employee_id: item.id,
      assigned_department_ids: this.state.assigned_department_ids,
      status: select_edit_status.toLowerCase().replace(/\s/g, '')
    };

    this.editemployeeDataApiCallId = apiCall({ method: configJSON.putApiMethod, body: convertAllFormData(attrs, "data[attributes]"), endPoint: configJSON.geteditEmployeeListingApiEndpoint, token: true, });

  }

  handleResponseForEmployeeListData = (from: string, message: Message) => {
    if (this.employeeListDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.data) {
        this.setState((prev) => ({
          loading: false,
          employee_list: apiResponse.data,
          meta: apiResponse.meta ?? prev.meta,
          company_name: apiResponse.data[0]?.attributes.company_profile.data.attributes.name
        }))
      } else {
        this.setState({ loading: false })
        handleApiError(apiResponse.errors)
      }

    }
  }

  handleResponseForAllEmployeesList = (from: string, message: Message) => {
    if (this.allEmployeesListDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.data) {
        const result = apiResponse.data.map((item: EmployeeDataForPdf) => this.mapAllEmployeesAttributes(item));
        this.setState({
          all_employee_list: result,
        })
      } else {
        handleApiError(apiResponse.errors)
      }

    }
  }
  handleDepartmentResponse = (from: string, message: Message) => {
    if (this.departmentDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResponseData(message);
    }
  }

  handleResponseData = (message: Message) => {
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    handleExpiredToken(response, this.props.navigation);
    if (response) {
      let departData 
      if(isBO()){
        departData = response.map((item: { departments: { id: string, name: string } }) => item.departments).flat().map((item: { id: string; name: string; }) => {
          return {
            id: item.id,
            name: item.name,
            text: item.name
          }
        })
      }else {
        departData = usersDepartments.map((item: {attributes:{ id: string; name: string; }}) => {
          return {
            id: item.attributes.id,
            name: item.attributes.name,
            text: item.attributes.name
          }
        })
      }
      const updatedData = departData.map((item:DepartmentList) => {
        return {
          id: Number(item.id),
          value: Number(item.id),
          label: item.name
        }
      })
      const sortedData = sortArrayAlphabetically(departData, "name")
      this.setState({
        department_list: sortedData,
        selected_departments: sortArrayAlphabetically(updatedData, "name")
      })

    } else {
      handleApiError(response.errors);
    }
  }

  handleResponseForEditEmployee = (from: string, message: Message) => {
    if (this.editemployeeDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleExpiredToken(apiResponse, this.props.navigation);
      if (apiResponse.data) {
        this.employeeListData(this.state.meta.current_page)
        this.setState({ openEditModel: false, is_edit: false, assigned_department_ids: [], autoFocus: true, openSuccessModel: true })
      } else if (apiResponse.errors) {
        this.setState({ openEditModel: false, is_edit: false, assigned_department_ids: [], autoFocus: true })
        handleApiError(apiResponse.errors)
      } else {
        this.setState({ openEditModel: false, is_edit: false, assigned_department_ids: [], autoFocus: true })
        handleApiError([configJSON.errorMessage])
      }
    }
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForEmployeeListData(from, message)
    this.handleDepartmentResponse(from, message)
    this.handleResponseForEditEmployee(from, message)
    this.handleResponseForAllEmployeesList(from, message)
    // Customizable Area End
  }
  // Customizable Area End
}
