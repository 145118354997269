// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { ClassNameMap } from "@material-ui/styles";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleApiError, handleExpiredToken, checkToken, getDiffs, handleUndefinedData, getUserRoleID } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
import CommonTemplateController from "./CommonTemplateController.web";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"formContainer" | "inputField" | "dateField"
    | "fieldWrapper" | "inputLabel" | "helperText" | "textAreaField" | "textAreaInputField" | "templateBtnWrapper"
     | "fileInput" | "primaryBtn" | "secondaryBtn" | "inputLabel2" | "approvalContainer" | "dropdownWrapper" | "uploadImageContainer" | "titleText" | "gridItem" | "templateFooterContainer"
  >;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class Template16Controller extends CommonTemplateController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      ...this.state,
      ...this.initialState
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidMount(): any {
    // Customizable Area Start
    this.getBranchData();
    checkToken();
    let { state } = this.props.navigation.history.location;
    if (state) {
      this.setDefaultData16(state)
    } else if(![2,3].includes(getUserRoleID())) {
      this.props.navigation.goBack();
    }
    // Customizable Area End
  }

  setDefaultData16(data: any, action?: string) {
    const { id, attachment, creator } = data
    this.setState({
      creator,
      defaultData: data,
      formData: data,
      id,
      templateAction: action ?? configJSON.view,
      attachment: attachment.url ? attachment : "",
    })
  }

  template16InitialValues = () => {
    const { defaultData, formData, templateAction } = this.state;
    const isCreateTemplate16 = templateAction === configJSON.create;
    const data = {
      department_id: isCreateTemplate16 ? defaultData.department_id : handleUndefinedData(formData?.department_id, ""),
      title: isCreateTemplate16 ? defaultData.title : handleUndefinedData(formData?.title, ""),
      branch_id: isCreateTemplate16 ? defaultData.branch_id : handleUndefinedData(formData?.branch_id, ""),
      attachment: null,
      description1: isCreateTemplate16 ? "" : handleUndefinedData(formData?.descriptions_attributes[0]?.description, ""),
      description2: isCreateTemplate16 ? "" : handleUndefinedData(formData?.descriptions_attributes[1]?.description, ""),
      description3: isCreateTemplate16 ? "" : handleUndefinedData(formData?.descriptions_attributes[2]?.description, ""),
      description4: isCreateTemplate16 ? "" : handleUndefinedData(formData?.descriptions_attributes[3]?.description, ""),
      send_for_approval: isCreateTemplate16 ? defaultData.send_for_approval : handleUndefinedData(formData?.send_for_approval, false),

    }
    return data;
  }

  addEditTemplate16 = (values: any) => {
    const { formData,template_id, defaultData } = this.state;

    const { department_id,attachment,title, send_for_approval, branch_id, descriptions_attributes } = defaultData;
    const {description3, description1, description2, description4 } = values;

    const { id } = formData;

    const createDescriptionsAttr = [
      {
        description: description1,
      }, {
        description: description2,
      },{
        description: description3,
      },{
        description: description4,
      }
    ];

    const editDescriptionsAttr = () => {
      return [
        {
          description: description1,
          id: this.state.formData.descriptions_attributes[0].id,
        }, {
          description: description2,
          id: this.state.formData.descriptions_attributes[1].id,
        },
        {
          description: description3,
          id: this.state.formData.descriptions_attributes[2].id,
        },
        {
          description: description4,
          id: this.state.formData.descriptions_attributes[3].id,
        }
      ];

    }


    const orriginalData = {
      branch_id,
      department_id,
      title,
      ...(attachment ? { attachment } : {}),
      descriptions_attributes,
      send_for_approval,
    };

    const attrs = {
      send_for_approval: values.send_for_approval,
      department_id: values.department_id,
      title: values.title,
      ...(!id ? { template_id } : {}),
      descriptions_attributes: id ? editDescriptionsAttr() : createDescriptionsAttr,
      branch_id: values.branch_id,
      ...(values.attachment ? { attachment: values.attachment } : this.checkEmptyAttachment()),
    };

    let templatePayload = getDiffs(orriginalData, attrs);
    this.addEditTemplateApiCall(templatePayload)
  }

  addTemplate16Response = (responseData16: any, message: string) => {
    if (responseData16.data) {
      if (this.state.id) {
        const dataItem = responseData16.data.attributes
        this.setStateData(dataItem);
        this.setDefaultData16(dataItem)
      } else {
        this.setState({ ...this.initialState });
        this.navigateToListingPage()
      }
      this.handleActions(configJSON.view)
      toast.success(message);
    } else if (responseData16.message) {
      const {defaultData} = this.state
      const dataItem = {...defaultData, favorite: !defaultData.favorite};
      this.setStateData(dataItem);
      this.setDefaultData16(dataItem)
      toast.success(message);
    } else {
      handleApiError(responseData16.errors);
    }
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resData16 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const requestMessageCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resData16, this.props.navigation);
      this.setState({ loading: false });
      if (requestMessageCallId && resData16) {
        this.setState({ loading: false });
        switch (requestMessageCallId) {
          case this.createTemplateApiId:
            this.addTemplate16Response(resData16, configJSON.procedureSuccessMessage);
            break;
          case this.duplicateTemplateApiId:
            this.addTemplate16Response(resData16, configJSON.procedureDuplicateMessage);
            break;
          case this.deleteTemplateApiId:
            this.deleteTemplateResponse(resData16);
            break;
          case this.branchDataApiId:
            this.getBranchesResponse(resData16);
            break;
          case this.departmentsApiId:
            this.getDepartmentsResponse(resData16);
            break;
          case this.favouriteTemplateApiId:
            const toastMessage = this.state.formData.favorite ? configJSON.procedureUnFavMessage : configJSON.procedureFavMessage;
            this.addTemplate16Response(resData16, toastMessage);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
