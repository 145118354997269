
import { Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import { colors } from "./commonDesign";
import { inputField } from "./commonInputStyle";

export const styles: Styles<Theme, {}, string> = (theme) => ({
   ...inputField,
  mainCard: {
    borderRadius: 16,
    margin:"auto",
    overflow:"auto"
  },
  cardContentInner: {
    display:"flex",
    padding: "45px !important",
  },
  mainContainer:{
    width:"23rem",
    paddingLeft:"2rem"
  },
  logoHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom:"30px"
  },
  headerTitle: {
    color: colors.secondaryColor1,
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "42px",
  },
  btnWrapper: {
    padding: "0 36.5px",
  },
  innerBtn: {
    color: colors.primaryColor2,
    marginTop: 25,
    backgroundColor: colors.secondaryColor2,
    boxShadow: "none",
    fontWeight: 700,
    textTransform: "unset",
    height: "56px",
    borderRadius: "10px",
    fontSize: "16px",
    lineHeight: "21px",
    "&:hover": {
      backgroundColor: colors.secondaryColor2,
    },
  },

  imageBox:{
    height:"500px"
  },
  inputPassword:{
    "& .MuiFormHelperText-root": {
      width:"370px"
    },
  },
  btnOtp:{
    paddingLeft:0,
    paddingRight: "34px !important",
    "& button":{
      margin:0
    }
  },
  inputsField:{
    display:"flex",
    margin:"2rem 0 3rem 0",
    "& input":{
      textAlign:"center",
      width:"4.5rem",
      height:"4.5rem",
      borderRadius:"8px",
      marginRight:"1rem",
      border:"none",
      outline:"none",
      fontSize:"1.2rem",
      background:"#F5F5F5",
      "&::placeholder":{
        fontSize:"1.2rem",
        color:"#6D827F",
      }
    }
  },
  inputContent:{
    border:"1px solid #6D827F !important"
  },
  resendOtpBox:{
    display:"flex",
    justifyContent:"flex-end",
    alignItems:"center",
    marginTop:"0.5rem",
    "& p":{
      fontSize:"1.2rem"
    },
    "& button":{
      textTransform:"unset",
      fontSize:"1.2rem"
    }
  },
  para:{
    margin:"2rem 0",
    "& p":{
      color:"#6D827F"
    }
  }
});

