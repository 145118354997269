Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultipageForms";
exports.labelBodyText = "MultipageForms Body";

exports.btnExampleTitle = "CLICK ME";

exports.successMessage = "UserDetails inserted successfully";
exports.errorsDataMsg  = "Internal Server error";
exports.multiPageFormSubmitEndPoint = "/bx_block_multipageforms/user_profiles";

exports.checklistTitle = "Checklist";
exports.paragraph = "Paragraph";
exports.singleAnswer = "Single answer";
exports.checkbox = "Checkbox";
exports.multipleChoice = "Multiple choice";
exports.dropdown = "Dropdown";
exports.linearScale = "Linear Scale";
exports.title = "Title";
exports.insertText = "Insert text here";
exports.option = "Option";
exports.addOption = "+ Add Option";
exports.scaleLabel = "Label (Optional)";
exports.scaleLowestItems = [
  {label:0,value:0},
  {label:1,value:1}
];
exports.scaleHighestItems = [
  {label:2,value:2},
  {label:3,value:3},
  {label:4,value:4},
  {label:5,value:5},
  {label:6,value:6},
  {label:7,value:7},
  {label:8,value:8},
  {label:9,value:9},
  {label:10,value:10}
];
exports.lowest = "Lowest";
exports.highest = "Highest";
exports.departmentListApiEndpoint = "bx_block_departments/all_selected_departments";
exports.getBranchApiEndpoint = "bx_block_branch/branches";
exports.chooseOption = "Choose Option";
exports.createChecklistApiEndpoint = "bx_block_multipageforms2/checklists";
exports.save = "Save";
exports.branchPlaceholder = "Select Branch";
exports.noBranchMessage = "No Branch Found";
exports.noDepartmentMessage = "No Department Found";
exports.departSelect = "Select Department";
exports.getDeptApiEndpoint = "bx_block_departments/selected_departments/";
exports.createChecklistSuccessMsg = "Checklist Saved Successfully";
exports.create = "Create";
exports.view = "View";
exports.fill = "Fill";
exports.edit = "Edit";
exports.duplicate = "Duplicate";
exports.checklistDeleteTitle = "Checklist";
exports.formDeleteTitle = "Form";
exports.paragraphAnswer = "Paragraph answer";
exports.cancel = "Cancel"
exports.email = "Email";
exports.phone = "Phone";
exports.fileUpload = "File upload";
exports.linkUrl = "Link URL";
exports.formTitle = "Form";
exports.enter = "Enter";
exports.text = "Text";
exports.createFormApiEndpoint = "bx_block_multipageforms2/forms";
exports.createFromSuccessMsg = "Form Saved Successfully";
exports.duplicateFormSuccessMsg = "Form Duplicated Successfully";
exports.filledBy = "Filled By";
exports.countryCodeApiEndPoint = "account_block/country_code_and_flags/countries_with_phone_formats";
exports.countryCodePlaceholder = "Code";
exports.noCountryCode = "No data";
exports.attachFileText= "Add Attachment";
exports.viewAttachmentText = "View Attachment";
exports.templateNoDepartmentMessage = "Please Add Department";
exports.branchErrorMessage = "No branches found. Please add a branch first";
exports.submitFormSuccessMsg = "Form Submitted Successfully";
exports.submitEditFormSuccessMsg = "Form Response Edited Successfully";
exports.submitChecklistSuccessMsg = "Checklist Submitted Successfully"
exports.submitEditChecklistSuccessMsg = "Checklist Response Edited Successfully"
exports.submissionErrorMessage = "Submission Failed or UnSupported File Format, 'Please Upload Correct File'";
exports.editFormFields = "Edit form fields";
exports.editFilledForm = "Edit";
exports.editCheckListFields = "Edit checklist fields";
exports.editFilledCheckList = "Edit filled checklist";
exports.checklistFillApiEndpoint = "bx_block_multipageforms2/checklist_answers";
exports.formFillApiEndpoint = "bx_block_multipageforms2/form_answers";
exports.fromLibrary = "from library";
exports.fromUserLogs = "from userlogs";
exports.fromLogs = "from logs";
exports.pdfContentError = "No content found"

exports.removeFavorite = "/remove_from_favorite";
exports.addFavorite = "/favorite";

exports.deleteLibraryForm = "Form deleted successfully from the library";
exports.deleteLogsForm = "Form deleted successfully from the logs";

exports.deleteLibraryChecklist = "Checklist deleted successfully from the library";
exports.deleteLogsChecklist = "Checklist deleted successfully from the logs";
// Customizable Area End