import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, TextField, InputLabel, FormHelperText } from "@material-ui/core";
import EditNewBranchController, { Props } from "./EditNewBranchController.web"
export const configJSON = require("./config");
import { Formik, Form } from 'formik';
import { AddNewBranchSchema } from "../../../components/src/utils/validationSchema"
import { Wrapper } from "../../../components/src/Wrapper";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web"
import { styles } from "./AddNewBranch.web";
import { allowHoursMinutes, allowNumberWithSpaceValidation, generateTimeValues, handleInvoiceErrors, handleInvoiceHelperText, handleUndefinedData, isBO } from "../../../components/src/utils/commonFunctions";
import ImageUploadView from "../../../components/src/commonComponents/ImageUploadView.web";
import SuccessModal from "../../../components/src/commonComponents/SuccessModal.web";
import CustomAutoComplete from "../../../components/src/commonComponents/fields/CustomAutoComplete.web";
import { GreenEditIcon } from "../../../components/src/commonComponents/assets";
import Loader from "../../../components/src/Loader";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class EditNewBranch extends EditNewBranchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { classes } = this.props;
    const { data, isEdit, open, loading
    } = this.state
    return (
      <>
        <Loader loading={loading} />
        <Wrapper navigation={this.props.navigation} divider backbtn={true} backHandleClick={this.handleBack} title={isEdit ? configJSON.editTitle : configJSON.wrapperTitle} bellIcn={true} homeIcn={true} child={
          <>
            <Formik
              enableReinitialize={true}
              initialValues={{
                branch_name: handleUndefinedData(data?.attributes?.branch_name, ""),
                type_of_point_of_sale: handleUndefinedData(data?.attributes.type_of_point_of_sale,""),
                location_of_point_of_sale: handleUndefinedData(data?.attributes.location_of_point_of_sale,""),
                time_zone: handleUndefinedData(data?.attributes.time_zone, ""),
                from_working_hours: handleUndefinedData(data?.attributes?.from_working_hours, ""),
                to_working_hours: handleUndefinedData(data?.attributes?.to_working_hours, ""),
                phone_number: handleUndefinedData(data?.attributes.phone_number, ""),
                email: handleUndefinedData(data?.attributes.email, ""),
                photo: null,
                description: handleUndefinedData(data?.attributes.description, ""),
                important_information: handleUndefinedData(data?.attributes.important_information, ""),
                country_code_and_flag_id: handleUndefinedData(data?.attributes.country_code_and_flag_id, ""),

              }}
              validationSchema={AddNewBranchSchema}
              onSubmit={this.handleSave}
            >
              {({ errors, touched, handleBlur, handleChange, setFieldValue, values }) => (
                <Form translate={undefined} className={classes.formContainer}>
                  <Box data-test-id="main-container" className={`${classes.mainContainer} ${classes.editSection}`}>
                    <Box className={classes.mainContainer}>
                      <Box data-test-id="left-section" className={classes.leftSection}>
                        <Box className={`${classes.fieldWrapper} ${classes.marginWrap}`}>
                          <InputLabel data-test-id="branch_name_text" required className={classes.inputLabel}>
                            {configJSON.branchNameText}
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            data-test-id='branch_name_input'
                            placeholder="Enter here"
                            name={"branch_name"}
                            value={values.branch_name}
                            disabled={!isEdit}
                            onChange={handleChange}
                            className={classes.inputField}
                            fullWidth
                            error={handleInvoiceErrors(
                              errors.branch_name,
                              touched.branch_name
                            )}
                            onBlur={handleBlur}
                            helperText={handleInvoiceHelperText(
                              errors.branch_name,
                              touched.branch_name
                            )}
                          />
                        </Box>
                        <Box className={classes.fieldWrapper}>
                          <InputLabel required className={classes.inputLabel}>
                            {configJSON.pointOfSaleText}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            type="text"
                            placeholder="Enter here"
                            name={"type_of_point_of_sale"}
                            value={values.type_of_point_of_sale}
                            data-test-id='type_of_point_of_sale-input'
                            fullWidth
                            onChange={handleChange}
                            disabled={!isEdit}
                            className={classes.inputField}
                            error={handleInvoiceErrors(
                              errors.type_of_point_of_sale,
                              touched.type_of_point_of_sale
                            )}
                            onBlur={handleBlur}
                            helperText={handleInvoiceHelperText(
                              errors.type_of_point_of_sale,
                              touched.type_of_point_of_sale
                            )}
                          />
                        </Box>
                        <Box className={classes.fieldWrapper}>
                          <InputLabel required className={classes.inputLabel}>
                            {configJSON.locationText}
                          </InputLabel>
                          <TextField
                            name={"location_of_point_of_sale"}
                            type="text"
                            placeholder="Enter here"
                            variant="outlined"
                            value={values.location_of_point_of_sale}
                            onChange={handleChange}
                            disabled={!isEdit}
                            className={classes.inputField}
                            fullWidth
                            error={handleInvoiceErrors(
                              errors.location_of_point_of_sale,
                              touched.location_of_point_of_sale
                            )}
                            onBlur={handleBlur}
                            helperText={handleInvoiceHelperText(
                              errors.location_of_point_of_sale,
                              touched.location_of_point_of_sale
                            )}
                          />
                        </Box>
                        <Box className={classes.fieldWrapper}>
                          <InputLabel className={classes.inputLabel} required>
                            {configJSON.timeZoneText}
                          </InputLabel>
                          <DropdownSearch
                            id="time_zone"
                            options={this.state.timezone_data}
                            onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "time_zone")}
                            value={values.time_zone}
                            onBlur={handleBlur}
                            placeholder={"Search Timezone..."}
                            noOptionsMessage={configJSON.noTimeZoneMessage}
                            showError={handleInvoiceErrors(
                              errors.time_zone,
                              touched.time_zone
                            )}
                            isDisabled={!isEdit}
                            errorText={handleInvoiceHelperText(
                              errors.time_zone,
                              touched.time_zone
                            )}
                            isSearchable={true}
                          />
                        </Box>
                        <Box className={classes.fieldWrapper}>
                          <InputLabel required className={classes.inputLabel}>
                            {configJSON.hoursText}
                          </InputLabel>
                          <Box className={classes.timeContainer}>
                            <CustomAutoComplete
                              name="from_working_hours"
                              options={generateTimeValues()}
                              value={values.from_working_hours}
                              placeholder={configJSON.openingTitle}
                              classes={classes}
                              freeSolo
                              onChange={(event: { target: { name: string } }, newValue: OptionType) => setFieldValue("from_working_hours", newValue)}
                              handleBlur={handleBlur}
                              handleKeyPress={allowHoursMinutes}
                              error={handleInvoiceErrors(
                                errors.from_working_hours,
                                touched.from_working_hours
                              )}
                              disabled={!isEdit}
                            />
                            <CustomAutoComplete
                              options={generateTimeValues()}
                              name="to_working_hours"
                              onChange={(event: { target: { name: string } }, newValue: OptionType) => setFieldValue("to_working_hours", newValue)}
                              handleBlur={handleBlur}
                              disabled={!isEdit}
                              value={values.to_working_hours}
                              placeholder={configJSON.closingTitle}
                              error={handleInvoiceErrors(
                                errors.to_working_hours,
                                touched.to_working_hours
                              )}
                              freeSolo
                              handleKeyPress={allowHoursMinutes}
                              classes={classes}
                            />
                          </Box>
                          {(handleInvoiceErrors(errors.to_working_hours, touched.to_working_hours) ||
                            handleInvoiceErrors(errors.from_working_hours, touched.from_working_hours)) && (
                              <FormHelperText
                                data-test-id={"formToErrorMsg"}
                                className={classes.helperText}
                              >
                                {handleInvoiceHelperText(errors.from_working_hours,
                                  touched.from_working_hours)}
                                {handleInvoiceHelperText(errors.to_working_hours, touched.to_working_hours)}
                              </FormHelperText>
                            )}
                        </Box>
                        <Box className={classes.fieldWrapper}>
                          <InputLabel required className={classes.inputLabel}>
                            {configJSON.phoneNumberText}
                          </InputLabel>
                          <Box className={classes.phoneGroup}>
                            <Box className={classes.dropdownWidth}>
                              <DropdownSearch
                                value={values.country_code_and_flag_id}
                                options={this.state.country_code_data}
                                onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "country_code_and_flag_id")}
                                showError={handleInvoiceErrors(
                                  errors.country_code_and_flag_id,
                                  touched.country_code_and_flag_id
                                )}
                                onBlur={handleBlur}
                                id="country_code_and_flag_id"
                                placeholder={configJSON.countryCodePlaceholder}
                                noOptionsMessage={configJSON.noCountryCode}
                                isSearchable={true}
                                isDisabled={!isEdit}
                              />
                            </Box>
                            <TextField
                              name="phone_number"
                              value={values.phone_number}
                              type="text"
                              variant="outlined"
                              onChange={handleChange}
                              fullWidth
                              className={`${classes.inputField} ${classes.inputWidth}`}
                              placeholder="Enter here"
                              error={handleInvoiceErrors(
                                errors.phone_number,
                                touched.phone_number
                              )}
                              onBlur={handleBlur}
                              onKeyPress={allowNumberWithSpaceValidation}
                              disabled={!isEdit}
                            />
                          </Box>
                          {(handleInvoiceErrors(errors.phone_number, touched.phone_number) || 
                            handleInvoiceErrors(errors.country_code_and_flag_id, touched.country_code_and_flag_id)) && (
                            <FormHelperText
                              className={classes.helperText}
                              data-test-id={"errorMessage"}
                            >
                              {handleInvoiceHelperText(errors.country_code_and_flag_id, 
                                touched.country_code_and_flag_id)}
                              {handleInvoiceHelperText(errors.phone_number,
                                touched.phone_number)}
                            </FormHelperText>
                          )}
                        </Box>

                        <Box className={classes.fieldWrapper}>
                          <InputLabel className={classes.inputLabel}>
                            {configJSON.emailText}
                          </InputLabel>
                          <TextField
                            name={"email"}
                            type="email"
                            variant="outlined"
                            placeholder="Enter here"
                            value={values.email}
                            fullWidth
                            onChange={handleChange}
                            className={classes.inputField}
                            error={handleInvoiceErrors(
                              errors.email,
                              touched.email
                            )}
                            onBlur={handleBlur}
                            helperText={handleInvoiceHelperText(
                              errors.email,
                              touched.email
                            )}
                            disabled={!isEdit}
                          />
                        </Box>
                      </Box>
                      <Box data-test-id="right_section" className={classes.rightSection}>
                        <Box className={classes.editBtnContainer}>
                          <Box className={classes.imageUploadContainer}>
                            <ImageUploadView
                              handleImageChange={this.handleImageChange}
                              setFieldValue={setFieldValue}
                              name={"photo"}
                              currentImage={this.state.currentImage}
                              editable={!isEdit}
                            />
                          </Box>
                          {isBO() && <Button
                            data-test-id="edit_button"
                            variant="outlined"
                            className={`${classes.secondaryBtn} ${classes.editBtn} ${!isEdit ? "" : classes.displayNone}`}
                            startIcon={<img src={GreenEditIcon} alt="GreenEditIcon" style={{ marginRight: "9px" }} />}
                            onClick={this.handleEditClick}
                          >
                            {configJSON.editButton}
                          </Button>}
                        </Box>
                        <Box className={classes.fieldWrapper}>
                          <InputLabel className={classes.inputLabel}>
                            {configJSON.textDescription}
                          </InputLabel>
                          <TextField
                            value={values.description}
                            name={"description"}
                            variant="outlined"
                            data-test-id="description"
                            fullWidth
                            type="text"
                            multiline
                            onChange={handleChange}
                            placeholder={configJSON.textareaPlaceHolder}
                            className={`${classes.inputField} ${classes.textAreaField}`}
                            helperText={handleInvoiceHelperText(
                              errors.description,
                              touched.description
                            )}
                            onBlur={handleBlur}
                            error={handleInvoiceErrors(
                              errors.description,
                              touched.description
                            )}
                            disabled={!isEdit}
                          />
                        </Box>
                        <Box className={classes.fieldWrapper}>
                          <InputLabel className={classes.inputLabel}>
                            {configJSON.textInfo}
                          </InputLabel>
                          <TextField
                            data-test-id="text_info"
                            name={"important_information"}
                            value={values.important_information}
                            variant="outlined"
                            fullWidth
                            type="text"
                            placeholder={configJSON.textareaPlaceHolder}
                            onChange={handleChange}
                            className={`${classes.inputField} ${classes.textAreaField}`}
                            multiline
                            helperText={handleInvoiceHelperText(
                              errors.important_information,
                              touched.important_information
                            )}
                            onBlur={handleBlur}
                            error={handleInvoiceErrors(
                              errors.important_information,
                              touched.important_information
                            )}
                            disabled={!isEdit}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={`${classes.submitBtn} ${isEdit ? "" : classes.displayNone}`}>
                    <Button data-test-id="save-btn" type="submit">{configJSON.saveButtonText}</Button>
                  </Box>
                </Form>
              )}
            </Formik>

            <SuccessModal
              title={configJSON.greatTitle}
              message={configJSON.confirmMessage}
              open={open}
              handleClick={this.handleOkClick}
              buttonText={configJSON.okBtnText}
              closeIcon={true}
              onClose={this.handleCloseClick}
            />
          </>
        } />

      </>
    );
  }
  // Customizable Area End
}
export default withStyles(styles)(EditNewBranch);

