/** @format */

import React from "react";
// Customizable Area Start
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { DateRange } from '@material-ui/icons';
import { commonInputField, commonButton } from "../../../components/src/utils/commonInputStyle";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, TextField, Button, Grid, FormHelperText } from "@material-ui/core";
import CreateTaskController, { Props, configJSON } from "./CreateTaskController.web"
import { Formik } from 'formik';
import { CreateTaskSchema } from "../../../components/src/utils/validationSchema"
import { Wrapper } from "../../../components/src/Wrapper";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import SuccessModal from "../../../components/src/commonComponents/SuccessModal.web";
import AttachFileInput  from "../../../components/src/commonComponents/AttachFileInput.web";
// Customizable Area End

// Customizable Area Start
export const styles = {
  ...commonButton,
  ...commonInputField,
  formContainer: {
    marginTop: "50px",
  },
  createTicketBtnWrapper: {
    display: "flex",
    paddingBottom: "10px",
    marginTop: "100px",
    justifyContent: "center",
  },
  descriptionText: {
    fontSize: 18,
    fontWeight: 700
  },
  maxSize: {
    fontSize: 12,
    display: "block",
    color: "#020202",
    opacity: 0.6,
    position: "absolute" as const,
    right: 0
  },
  descriptionConatiner:{
    marginTop: "0px",
  }
};
// Customizable Area End

export class CreateTask extends CreateTaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { classes } = this.props;
    const { branch_data,
      attach_image,
      apiError,
      loading,
      open,
      department_data,
      employees_Data,
      priorities_data
    } = this.state;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper navigation={this.props.navigation} divider backbtn={true} title={configJSON.createTaskTitle} bellIcn={true} homeIcn={true} child={
          <Box>
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={this.state.defaultData}
              validationSchema={CreateTaskSchema}
              onSubmit={this.handleSave}
            >
              {({ errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, handleReset, values }) => (
                <form onSubmit={handleSubmit} className={classes.formContainer}>
                  <Box className={classes.fieldWrapper}>
                    <InputLabel data-test-id="title" required className={classes.inputLabel}>
                      {configJSON.titleFieldText}
                    </InputLabel>
                    <TextField
                      placeholder={configJSON.taskPlaceholder}
                      name={"title"}
                      type="text"
                      variant="outlined"
                      onChange={handleChange}
                      value={values.title}
                      fullWidth
                      error={handleInvoiceErrors(
                        errors.title,
                        touched.title
                      )}
                      className={classes.inputField}
                      helperText={handleInvoiceHelperText(
                        errors.title,
                        touched.title
                      )}
                      onBlur={handleBlur}
                    />
                  </Box>
                  <Grid className={classes.descriptionConatiner} container spacing={3}>
                    <Grid item sm={6}>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={`${classes.inputLabel} ${classes.descriptionText}`}>
                          {configJSON.descriptionText}
                        </InputLabel>
                        <TextField
                          name={"description"}
                          value={values.description}
                          variant="outlined"
                          fullWidth
                          data-test-id="description"
                          multiline
                          type="text"
                          placeholder={configJSON.descPlaceholder}
                          onChange={handleChange}
                          className={`${classes.inputField} ${classes.textAreaInputField
                            } ${classes.textAreaField}`}
                          helperText={handleInvoiceHelperText(
                            errors.description,
                            touched.description
                          )}
                          error={handleInvoiceErrors(
                            errors.description,
                            touched.description
                          )}
                          onBlur={handleBlur}
                        />
                        <Typography className={classes.maxSize} component="span">{configJSON.characterLengthText}</Typography>
                      </Box>
                      <Grid container spacing={3}>
                        <Grid item sm={6}>
                          <Box className={classes.fieldWrapper}>
                            <InputLabel required className={classes.inputLabel}>
                              {configJSON.dueDateText}
                            </InputLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                InputProps={{
                                  endAdornment: <DateRange />,
                                  disableUnderline: true,
                                }}
                                variant="inline"
                                margin="normal"
                                fullWidth
                                className={`${classes.inputField} ${classes.dateField}`}
                                autoOk
                                name="due_date"
                                value={values.due_date}
                                onBlur={handleBlur}
                                disablePast={true}
                                allowKeyboardControl={false}
                                placeholder={configJSON.creationDate}
                                onChange={setFieldValue.bind(null, "due_date")}
                                format="MM/dd/yyyy"
                                data-test-id="due_date"
                              />
                            </MuiPickersUtilsProvider>
                            <FormHelperText className="error-text">
                              {touched.due_date && errors.due_date}
                            </FormHelperText>
                          </Box>
                        </Grid>
                        <Grid item sm={6}>
                            <Box className={`${classes.fieldWrapper} ${classes.fileInput}`}>
                            <Box className={classes.fileUploadInput}>
                            <AttachFileInput
                                attachmentTitle={configJSON.attachmentTitle}
                                attachmentId="attach_image"
                                attachment={attach_image}
                                setFieldValue={setFieldValue}
                                handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(event, setFieldValue) }}
                                clearAttachment={() => this.handleClearValue(setFieldValue)}
                                attachmentError={apiError}
                            />
                            {!attach_image && !apiError && <Typography className={classes.maxSize} component="span">{configJSON.fileSizeText}</Typography>} 
                        </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={6}>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.textBranch}
                        </InputLabel>
                        <DropdownSearch
                          options={branch_data}
                          onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "branch_id", { department_id: "", assignee_ids: [] })}
                          onBlur={handleBlur}
                          value={values.branch_id}
                          placeholder={configJSON.branchPlaceholderText}
                          noOptionsMessage={configJSON.noBranchText}
                          showError={handleInvoiceErrors(
                            errors.branch_id,
                            touched.branch_id
                          )}
                          id="branch_id"
                          isSearchable={true}
                          errorText={handleInvoiceHelperText(
                            errors.branch_id,
                            touched.branch_id
                          )}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.departmentText}
                        </InputLabel>
                        <DropdownSearch
                          options={department_data}
                          placeholder={configJSON.departmentSelect}
                          value={values.department_id}
                          onBlur={handleBlur}
                          onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "department_id", { assignee_ids: [] })}
                          isSearchable={true}
                          isDisabled={!values.branch_id || department_data.length === 0}
                          noOptionsMessage={configJSON.noDepartMessage}
                          showError={handleInvoiceErrors(
                            errors.department_id,
                            touched.department_id
                          )}
                          id="department_id"
                          errorText={handleInvoiceHelperText(
                            errors.department_id,
                            touched.department_id
                          )}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.employeeText}
                        </InputLabel>
                        <DropdownSearch
                          options={employees_Data}
                          value={values.assignee_ids}
                          onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "assignee_ids")}
                          onBlur={handleBlur}
                          id="assignee_ids"
                          isSearchable={true}
                          isDisabled={!values.department_id || employees_Data.length === 0}
                          placeholder={configJSON.employeePlaceholder}
                          noOptionsMessage={configJSON.noEmployeeMessage}
                          showError={handleInvoiceErrors(
                            errors.assignee_ids,
                            touched.assignee_ids
                          )}
                          errorText={handleInvoiceHelperText(
                            errors.assignee_ids,
                            touched.assignee_ids
                          )}
                          isMulti={true}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.prorityText}
                        </InputLabel>
                        <DropdownSearch
                          options={priorities_data}
                          onChangeValue={(e: OptionType) => this.handleSelectChange(e, setFieldValue, "priority")}
                          value={values.priority}
                          onBlur={handleBlur}
                          placeholder={configJSON.priorityPlaceholder}
                          id="priority"
                          isSearchable={false}
                          noOptionsMessage={configJSON.noPriorityMessage}
                          showError={handleInvoiceErrors(
                            errors.priority,
                            touched.priority
                          )}
                          errorText={handleInvoiceHelperText(
                            errors.priority,
                            touched.priority
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={classes.createTicketBtnWrapper}>
                    <Button
                      className={classes.secondaryBtn}
                      onClick={handleReset}
                      variant="outlined"
                    >
                      {configJSON.cancelBtnText}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.primaryBtn}
                    >
                      {configJSON.createBtnText}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
            <SuccessModal
              closeIcon={true}
              onClose={this.handleOkClick}
              title={configJSON.greatTitle}
              message={configJSON.confirmMessage}
              open={open}
              handleClick={this.handleOkClick}
              buttonText={configJSON.okBtnText}
            />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(CreateTask);
// Customizable Area End

