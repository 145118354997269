
import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import MiscellaneousDocListingController, { Props } from "./MiscellaneousDocListingController.web";
export const configJSON = require("./config");
import { commonButton, libraryCommonCss } from "../../../components/src/utils/commonInputStyle";
import { libraryIcon } from "../../CfTabletView/src/assets";
import NoTickets from "../../../components/src/commonComponents/NoTickets.web";
import ActionButton from "../../../components/src/commonComponents/ActionButton.web";
import Loader from "../../../components/src/Loader";
import ConfirmationModal from "../../../components/src/commonComponents/ConfirmationModal.web";
import Pagination from "../../../components/src/commonComponents/Pagination.web";
import { FileDocumentIcon } from "../../../components/src/commonComponents/assets";
// Customizable Area End


export const styles = {
  ...commonButton,
  ...libraryCommonCss
};
// Customizable Area End

export class MiscellaneousDocListing extends MiscellaneousDocListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, gridView, searchQuery } = this.props;
    const { miscellaneousDoc_list, header_list, loading, openDeleteModal, meta } = this.state
      
    const MiscellaneousDocGridView = () => (
      <Grid container spacing={2}>
        {(Boolean(miscellaneousDoc_list.length)) ?
          miscellaneousDoc_list.map(miscellaneousDoc => (
            <Grid key={miscellaneousDoc.attributes.id} item lg={3} md={4} sm={6} xs={12}>
              <Box className={classes.cardWrapper}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <ActionButton
                    deleteVisible={this.props.allowEditDelete(miscellaneousDoc.attributes.creator?.id)}
                    handleDelete={this.handleOpenDeleteModal}
                    isOpen={this.state.isOpen[miscellaneousDoc.attributes.id]}
                    toggleDropdown={() => this.toggleDropdown(miscellaneousDoc.attributes.id)}
                    download
                    handleDownload={() => this.handleDownload(miscellaneousDoc.attributes)}
                  />
                </Box>
                <Box>
                  <Box display={"flex"} justifyContent={"center"}>
                    <img className={`${classes.templateImg} miscDocImg`} src={FileDocumentIcon} />
                  </Box>
                  <Box p={3} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                      <Typography data-test-id="miscellaneousDoc_name" noWrap className={classes.procedureTitleTxt}>{miscellaneousDoc.attributes.title}</Typography>
                      <Typography className={classes.dateTxt}>{configJSON.date}{miscellaneousDoc.attributes.attachment.uploaded_date}</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          )) :
          <Box className={classes.noTicketContainer}>
            <NoTickets
              title={configJSON.noDocTitle}
              imagePath={libraryIcon}
            />
          </Box>
        }
      </Grid>
    )

    const MiscellaneousDocListView = () => (
      <Box id="pdf-content" className={classes.listItem}>
        <Box className={`${classes.tableHeader}`}>
          {header_list.map((item, index) => (
            <Typography component="p" className={index === 1 ? "miscHeaderWidth" : ""} key={item}>
              {item}
            </Typography>
          ))}
        </Box>
        <Box className={miscellaneousDoc_list.length === 0 ? classes.listMain : ""}>
          {miscellaneousDoc_list.length > 0 ? (
            miscellaneousDoc_list.map((item) => {
              return (
                <Box key={item.attributes.id} className={classes.tableItem}>
                  <Box className={`${classes.imgContainer} miscImgContainer`}>
                    <img src={FileDocumentIcon} alt={item.attributes.attachment.name} />
                  </Box>
                  <Typography component="p" data-test-id="miscellaneousDoc_title" className="miscItemWidth">{item.attributes.title}</Typography>
                  <Typography component="p"></Typography>
                  <Typography component="p"></Typography>
                  <Typography component="p">{item.attributes.attachment.uploaded_date}</Typography>
                  <Box display={"flex"} >
                    <ActionButton
                      deleteVisible={this.props.allowEditDelete(item.attributes.creator?.id)}
                      handleDelete={this.handleOpenDeleteModal}
                      moreVert
                      isOpen={this.state.isOpen[item.attributes.id]}
                      toggleDropdown={() => this.toggleDropdown(item.attributes.id)}
                      download
                      handleDownload={() => this.handleDownload(item.attributes)}
                    />
                  </Box>
                </Box>
              );
            })
          ) : (
            <NoTickets
              title={configJSON.noDocTitle}
              imagePath={libraryIcon}
            />
          )}
        </Box>
      </Box>
    )

    const MiscellaneousDocViewer = () => {
      if (miscellaneousDoc_list.length > 0) {
        return (
          <>
            {gridView ? MiscellaneousDocGridView() : MiscellaneousDocListView()}
            <Box className={classes.paginationBox}>
              <Pagination onPageChange={this.handlePageChange} meta={meta} />
            </Box>
          </>
        )
      } else if (miscellaneousDoc_list.length === 0 && !loading && Boolean(searchQuery)) {
        return (
          <Box className={classes.noTicketContainer}>
            <Typography variant="h5">{configJSON.noSearchResult} <strong>{searchQuery}</strong>.</Typography>
          </Box>
        )
      } else if (miscellaneousDoc_list.length === 0 && !loading) {
        return (
          <Box className={classes.noTicketContainer} >
            <NoTickets
              title={configJSON.noDocTitle}
              imagePath={libraryIcon}
            />
          </Box>
        )
      }
    }

    return (
      <React.Fragment>
        <Loader loading={loading} />
        <Box className={`${miscellaneousDoc_list.length > 0 ? "" : classes.noTicketOuterContainer}`} pt={2}>
          {MiscellaneousDocViewer()}
        </Box>
        {openDeleteModal &&
          <ConfirmationModal
            open={openDeleteModal}
            onClose={this.handleCloseDeleteModal}
            confirmDelete={this.handleDeleteClick}
            title={configJSON.docDeleteTitle}
            from={configJSON.documentFrom}
          />}
      </React.Fragment>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(MiscellaneousDocListing);
// Customizable Area End
