// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, checkToken, commonDownloadPdfFunc, isManager, convertAllFormData, convertFormData, handleApiError, handleDownloadImage, handleExpiredToken, handleUndefinedData, isBO, sortArrayAlphabetically, userProfileId, usersDepartments, compareArrays } from "../../../components/src/utils/commonFunctions";
import { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import { statusList, selectAllOption } from "../../../components/src/utils/constant";
import { toast } from "react-toastify";
import { FormikErrors, FormikTouched } from "formik";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id?: string;
    // Customizable Area Start
    classes: Record<string, string>;

    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean
    show: boolean;
    document_id: string | number | undefined | null
    data: Partial<Attributes> | null;
    defaultData: Partial<Attributes> | null;
    document_name: string | null | undefined
    openMenu: boolean;
    edit: boolean;
    openDeleteModal: boolean;
    openConfirmationModal: boolean;
    statusList: ListData[];
    roleList: ListData[];
    reporting_employee: OptionType[];
    new_reporting_employee: OptionType[];
    countryList: OptionType[];
    countryCodeList: OptionType[];
    branch_list: Lists[];
    department_lists: Lists[];
    my_documents: (Documents | null | undefined)[];
    isOpen: { [key: string]: boolean };
    currentImage: string | ArrayBuffer | null;
    documents: Documents[]
    employeeId: number;
    accountId: number;
    allow_edit: boolean
    is_download: boolean
    total_child_count:number
    showReplaceUserInWork: boolean;
    showReplaceUserInOverview: boolean;
    formikValues: ProfileFormValues | null;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}
// Customizable Area Start

export interface ListData {
    id?: number,
    value: string;
    label: string;
    owner_name?: string;
    email?: string
}

type BirthType = string | Date | null | undefined

export interface ClearValue {
    [key: string]: string
}
export interface Attributes {
    address: string
    department_names: string[]
    branch_name: string
    branch_id: null | string | number
    parent_user_details: ParentUser | null | undefined | ListData
    account_id: number
    city: string
    joining_date?: string | Date | null
    country_info?: {
        name: string;
        id: number
    }
    tenure: string | number
    company: string
    owner_name: string
    owner_email: string
    working_email: string
    phone_number: string
    reporting_to?: string | number
    new_reporting_to?: string | number;
    country_code_and_flag_id: number | string
    family_name: string
    date_of_birth: BirthType;
    country_code: string
    country: string
    gender: string
    photo: string
    status: string
    role_name: string
    role_id: number | string
    country_id: string
    national_id?: DocumentType
    nationalidUrl?: DocumentType
    passport?: DocumentType
    passportUrl?: DocumentType
    vaccination_certificate?: DocumentType
    school_degree?: DocumentType
    university_degree?: DocumentType
    certificate?: DocumentType
    driving_license?: DocumentType
    drivingLicenseUrl?: DocumentType
    schoolDegreeUrl?: DocumentType
    universityDegreeUrl?: DocumentType
    vaccinationCertificateUrl?: DocumentType
    certificateUrl?: DocumentType
    assigned_departments?: {
        id: number | string;
        name: string;
        category_name?: string;
        photo?: string;

    }[],
    assigned_department_ids: string[],
    documents: Documents[]
}
type DocumentType = Documents | null | DocumentsNull;

type InputValue = string | OptionType | MaterialUiPickersDate | File | undefined
export interface ParentUser {
    id: number
    email: string
    role_id: number | string
    role: string
    owner_name: string
}
interface Lists {
    id?: number,
    value: string | number;
    label: string;
    isDisabled?: boolean;
}

export interface Documents {
    id?: number | string
    name?: string
    url?: string
    size?: string
    service_url?: string
}
export interface DocumentsNull {
    id?: null
    name?: null
    url?: null
    size?: null
}
interface ResponseDepartment {
    id: string;
    departments: {
        id: string;
        name: string;
    }[]
}
export interface ProfileFormValues {
    joining_date: BirthType;
    status: string;
    role_id: number | string;
    reporting_to: ReportingType;
    new_reporting_to?: string | number;
    branch_id: string | null | number;
    owner_name: string;
    tenure: string | number
    owner_email: string;
    working_email: string
    address: string;
    phone_number: string;
    country_code_and_flag_id: string | number;
    date_of_birth: BirthType;
    country_id: string;
    gender: string;
    nationalidUrl: Partial<Documents>
    drivingLicenseUrl: Partial<Documents>
    passportUrl: Partial<Documents>
    schoolDegreeUrl: Partial<Documents>
    universityDegreeUrl: Partial<Documents>
    vaccinationCertificateUrl: Partial<Documents>
    certificateUrl: Partial<Documents>
    assigned_department_ids?: string[]

}
interface FormikErrorsType {
    joining_date?: string | Date | null
    owner_name: string
    owner_email: string
    working_email: string
    phone_number: string
    country_code_and_flag_id: number | string
    date_of_birth: string | Date | null | undefined;
    national_id?: Documents | null
    passport?: Documents | null
    vaccination_certificate?: Documents | null
    school_degree?: Documents | null
    university_degree?: Documents | null
    certificate?: Documents | null
    driving_license?: Documents | null,
    assigned_department_ids?: string[],
    reporting_to: string | number
}
export type RenderFormProps = {
    errors: FormikErrors<Partial<FormikErrorsType>>,
    touched: FormikTouched<Partial<FormikErrorsType>>,
    handleBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
    handleChange: (field: string | React.ChangeEvent<Element>) => void,
    setFieldValue: (field: string, value: OptionType | string | MaterialUiPickersDate | File | undefined, shouldValidate?: boolean | undefined) => void,
    values: ProfileFormValues
};

type ValueType = OptionType | string | MaterialUiPickersDate | File | undefined
type ReportingType = string | number | undefined
// Customizable Area End

export default class EmployeeProfileDetailsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    countryListApiCallId: string = "";
    reportingToApiId: string = "";
    newReportingToApiId: string = "";
    getBranchListApiId: string = "";
    getDepartmentApiId: string = "";
    getEmployeeDetailsApiId: string = "";
    uploadDocumentsApiCallId: string = "";
    deleteDocumentApiId: string = "";
    pdfContentRef: React.RefObject<HTMLDivElement>
    updateProfileApiCallId: string = "";
    getRoleApiId: string = "";
    inialDocUrlObj = { name: "", url: "", size: "", uploaded_date: "" }

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.pdfContentRef = React.createRef();
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            loading: false,
            allow_edit: false,
            show: false,
            openMenu: false,
            openDeleteModal: false,
            openConfirmationModal: false,
            document_name: "",
            statusList: [],
            countryList: [],
            countryCodeList: [],
            branch_list: [],
            department_lists: [],
            my_documents: [],
            isOpen: {},
            document_id: "",
            employeeId: Number(this.props.navigation.getParam("id")),
            accountId: 0,
            currentImage: null,
            documents: [],
            is_download: false,
            reporting_employee: [],
            new_reporting_employee: [],
            edit: false,
            data: null,
            defaultData:null,
            roleList: [],
            total_child_count:0,
            showReplaceUserInWork: false,
            showReplaceUserInOverview: false,
            formikValues: null,
        }
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        checkToken(this.props.navigation);
        this.getStatusList();
        this.getRoles();
        this.getProfileDetails()
        this.countryListApiCallId = apiCall({ method: configJSON.getApiMethod, endPoint: configJSON.getCountryListEndpoint, token: true });
        let { state } = this.props.navigation.history.location;
        if (state) {
          this.setState({edit: state})
        }
        // Customizable Area End
    }
    // Customizable Area Start
    componentDidUpdate(prevProps: Props, prevState: S) {
        const { data, defaultData, total_child_count } = this.state
        if (data?.branch_id !== prevState.data?.branch_id && data?.branch_id) {
            this.getDepartments()
        }
        if (data) {
            let array1: string[] | undefined = data?.assigned_department_ids ?? [];
            let array2: string[] | undefined = prevState.data?.assigned_department_ids ?? [];
            let array3: string[] | undefined = defaultData?.assigned_department_ids ?? [];

            const compare = compareArrays(array1, array2)
            const compareToInitial = compareArrays(array1, array3)
            if ((data?.branch_id !== prevState.data?.branch_id && data?.branch_id) || !compare || data?.role_id !== prevState.data?.role_id && data?.role_id || data?.status !== prevState.data?.status) {
                const differentBranch = Number(data?.branch_id) !== defaultData?.branch_id;
                this.getReportingTo()
                if ((((!differentBranch && (array1.length < array3.length)) || differentBranch) || ((!compareToInitial) && (array1.length <= array3.length)) || (data?.role_id !== defaultData?.role_id)) && !!total_child_count) {
                  this.getnewReportingTo();
                  this.setState({showReplaceUserInWork: true, showReplaceUserInOverview: false, data: {...data, new_reporting_to: ""}});
                } else if ((data?.status !== "active") && total_child_count) {
                  this.setState({showReplaceUserInWork: false, showReplaceUserInOverview: true, data: {...data, new_reporting_to: ""}});
                } else {
                  this.setState({showReplaceUserInWork: false, showReplaceUserInOverview: false, data: {...data, new_reporting_to: ""}});
                }
            }
        }
        const urlId = Number(this.props.navigation.getParam("id"))
        if (urlId !== prevState.employeeId) {

            this.setState({
                employeeId: urlId,
                edit: false,
                isOpen: {}
            }, () => this.getProfileDetails())
        }
    }
    getRoles = () => {
      this.getRoleApiId = apiCall({ method: configJSON.getApiMethod, endPoint: configJSON.roleEndpoint, token: true });
    }
    getReportingTo = () => {

        const { data } = this.state
        this.reportingToApiId = apiCall({
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.reportingToApiEndPoint}&branch_id=${data?.branch_id}&department_id=${data?.assigned_department_ids}&role_id=${data?.role_id}`,
            token: true,
        });
    }
    getnewReportingTo = () => {
        const { defaultData } = this.state
        if(defaultData?.role_id === 3){
            this.newReportingToApiId = apiCall({
                method: configJSON.getApiMethod,
                endPoint: `${configJSON.newReportingApiEndPoint}&branch_id=${defaultData?.branch_id}&role_id=${defaultData?.role_id}`,
                token: true,
            });
        }else {
            this.newReportingToApiId = apiCall({
                method: configJSON.getApiMethod,
                endPoint: `${configJSON.newReportingApiEndPoint}&branch_id=${defaultData?.branch_id}&role_id=${defaultData?.role_id}&department_id=${defaultData?.assigned_department_ids}`,
                token: true,
            }); 
        }
    }
    getProfileDetails = () => {
        this.setState({ loading: true })
        const { employeeId } = this.state
        this.getEmployeeDetailsApiId = apiCall({ method: configJSON.getApiMethod, endPoint: `${configJSON.getProfileDetailsApiEndPoint}${employeeId}`, token: true });

    }
    handleCloseDeleteModal = () => {
        this.setState({ openDeleteModal: false })
    }
    handleOpenDeleteModal = (item: (Documents | null | undefined)) => {
        this.setState({ openDeleteModal: true, isOpen: {}, document_name: item?.name, document_id: item?.id })
    }

    handleCloseConfirmationModal = () => {
      this.setState({ openConfirmationModal: false, formikValues: null })
    }
    handleOpenConfirmationModal = (formikValues: (ProfileFormValues)) => {
      const { showReplaceUserInOverview, showReplaceUserInWork } = this.state;
      if((showReplaceUserInWork || showReplaceUserInOverview) && !formikValues.new_reporting_to) {
        this.setState({ openConfirmationModal: true, formikValues })
      } else {
        this.saveProfileData(formikValues)
      }
    }

    handleConfirmSaveClick = () => {
      this.state.formikValues && this.saveProfileData(this.state.formikValues)
      this.handleCloseConfirmationModal()
  }

    loggedUserFunc = () => {
        const { employeeId } = this.state
        if (employeeId === Number(userProfileId)) {
            return true
        } else {
            return false
        }
    }
    setresData = (data: Partial<Attributes> | null) => {
        const updateData = {
            ...data,
            status: handleUndefinedData(data?.status, null),
            reporting_to: handleUndefinedData(data?.reporting_to, null),
            tenure: handleUndefinedData(data?.tenure, null),
            branch_id: handleUndefinedData(data?.branch_id, null),
            joining_date: handleUndefinedData(data?.joining_date, null),
            owner_name: handleUndefinedData(data?.owner_name, ""),
            phone_number: handleUndefinedData(data?.phone_number, ""),
            country_code_and_flag_id: handleUndefinedData(data?.country_code_and_flag_id, ""),
            owner_email: handleUndefinedData(data?.owner_email, ""),
            working_email: handleUndefinedData(data?.working_email, ""),
            address: handleUndefinedData(data?.address, ""),
            date_of_birth: handleUndefinedData(data?.date_of_birth, null),
            country_id: handleUndefinedData(data?.country_id, null),
            gender: handleUndefinedData(data?.gender, ""),
            national_id: null,
            nationalidUrl: handleUndefinedData(data?.national_id, null),
            driving_license: null,
            drivingLicenseUrl: handleUndefinedData(data?.driving_license, null),
            passport: null,
            passportUrl: handleUndefinedData(data?.passport, null),
            school_degree: null,
            schoolDegreeUrl: handleUndefinedData(data?.school_degree, null),
            university_degree: null,
            universityDegreeUrl: handleUndefinedData(data?.university_degree, null),
            vaccination_certificate: null,
            vaccinationCertificateUrl: handleUndefinedData(data?.vaccination_certificate, null),
            certificate: null,
            certificateUrl: handleUndefinedData(data?.certificate, null),
            role_id: handleUndefinedData(data?.role_id, null),
            assigned_department_ids: data?.assigned_department_ids
        }
        const documents = [
            handleUndefinedData(data?.national_id, null),
            handleUndefinedData(data?.driving_license, null),
            handleUndefinedData(data?.passport, null),
            handleUndefinedData(data?.school_degree, null),
            handleUndefinedData(data?.university_degree, null),
            handleUndefinedData(data?.vaccination_certificate, null),
            handleUndefinedData(data?.certificate, null)
        ]
        this.setState({
            data: updateData,
            my_documents: documents.filter(item => item?.url !== null),
        })
    }
    setFormikInitialData = () => {
        const { data } = this.state
        const updateData = {
            status: handleUndefinedData(data?.status, null),
            reporting_to: data?.reporting_to,
            new_reporting_to: handleUndefinedData(data?.new_reporting_to, ""),
            owner_name: handleUndefinedData(data?.owner_name, ""),
            joining_date: handleUndefinedData(data?.joining_date, null),
            owner_email: handleUndefinedData(data?.owner_email, ""),
            working_email: handleUndefinedData(data?.working_email, ""),
            address: handleUndefinedData(data?.address, ""),
            phone_number: handleUndefinedData(data?.phone_number, ""),
            country_code_and_flag_id: handleUndefinedData(data?.country_code_and_flag_id, ""),
            date_of_birth: handleUndefinedData(data?.date_of_birth, null),
            country_id: handleUndefinedData(data?.country_id, null),
            gender: handleUndefinedData(data?.gender, ""),
            nationalidUrl: handleUndefinedData(data?.nationalidUrl, null),
            drivingLicenseUrl: handleUndefinedData(data?.drivingLicenseUrl, null),
            passportUrl: handleUndefinedData(data?.passportUrl, null),
            schoolDegreeUrl: handleUndefinedData(data?.schoolDegreeUrl, null),
            universityDegreeUrl: handleUndefinedData(data?.universityDegreeUrl, null),
            vaccinationCertificateUrl: handleUndefinedData(data?.vaccinationCertificateUrl, null),
            certificateUrl: handleUndefinedData(data?.certificateUrl, null),
            tenure: handleUndefinedData(data?.tenure, null),
            branch_id: handleUndefinedData(data?.branch_id, null),
            role_id: handleUndefinedData(data?.role_id, null),
            assigned_department_ids: data?.assigned_department_ids
        }
        return updateData

    }
    getStatusList = () => {
        const statusData = statusList.map(item => {
            return {
                id: item.id,
                label: item.name,
                value: item.text
            }
        })
        this.setState({
          statusList: statusData,
      })
    }
    scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }

    handleDropdownChange = (sectionId: string) => {
        if (sectionId) {
            this.scrollToSection(sectionId);
            this.setState({ show: !this.state.show })
        }
    }

    toggleMenu = () => {
        this.setState({ openMenu: !this.state.openMenu })
    }
    toggleDropdown = (itemId: string | number | undefined) => {
        this.setState((prevState) => {
            const updatedIsOpen = { ...prevState.isOpen };

            for (const keyValue in updatedIsOpen) {
                updatedIsOpen[keyValue] = false;
            }

            if (itemId) updatedIsOpen[itemId] = !prevState.isOpen[itemId];

            return {
                isOpen: updatedIsOpen,
            };
        });
    };
    handleEdit = () => {
        this.setState({
            edit: true,
            openMenu: false
        })
        this.getBranchListApiId = apiCall({ method: configJSON.getApiMethod, endPoint: configJSON.getBranchApiEndpoint, token: true });

    }
    saveProfileData = (values: ProfileFormValues) => {
        this.setState({ loading: true })

        const payload = {
            address: values.address,
            gender: values.gender,
            owner_name: values.owner_name,
            owner_email: values.owner_email,
            phone_number: values.phone_number,
            date_of_birth: String(values.date_of_birth),
            country_id: values.country_id,
            country_code_and_flag_id: values.country_code_and_flag_id,
            ...(this.state.data?.national_id ? { national_id: this.state.data?.national_id } : values.nationalidUrl?.url ? {} : { national_id: "" }),
            ...(this.state.data?.driving_license ? { driving_license: this.state.data?.driving_license } : values.drivingLicenseUrl?.url ? {} : { driving_license: "" }),
            ...(this.state.data?.passport ? { passport: this.state.data?.passport } : values.passportUrl?.url ? {} : { passport: "" }),
            ...(this.state.data?.school_degree ? { school_degree: this.state.data?.school_degree } : values.schoolDegreeUrl?.url ? {} : { school_degree: "" }),
            ...(this.state.data?.university_degree ? { university_degree: this.state.data?.university_degree } : values.universityDegreeUrl?.url ? {} : { university_degree: "" }),
            ...(this.state.data?.vaccination_certificate ? { vaccination_certificate: this.state.data?.vaccination_certificate } : values.vaccinationCertificateUrl?.url ? {} : { vaccination_certificate: "" }),
            ...(this.state.data?.certificate ? { certificate: this.state.data?.certificate } : values.certificateUrl?.url ? {} : { certificate: "" })
        }
        const payloadOverviewWork = {
            joining_date: String(values.joining_date),
            branch_id: values.branch_id,
            tenure: values.tenure,
            department_ids: values.assigned_department_ids?.filter(item => item !== selectAllOption.value),
            role_id: values.role_id,
            reporting_to: values.reporting_to,
            account_attributes: {
                status: values.status,
                id: this.state.data?.account_id,

            },
        }
        if (this.loggedUserFunc()) {
            this.updateProfileApiCallId = apiCall({
                method: configJSON.putApiMethod,
                body: convertAllFormData(payload, "data[attributes]"),
                endPoint: `${configJSON.updateProfileApiEndPoint}/${this.state.employeeId}/update_details`,
                token: true,
            });
        } else {
            const endPoint = values.new_reporting_to ? `${configJSON.updateProfileApiEndPoint}/${this.state.employeeId}/update_details?replace_user_id=${values.new_reporting_to}` : `${configJSON.updateProfileApiEndPoint}/${this.state.employeeId}/update_details`
            this.updateProfileApiCallId = apiCall({
                method: configJSON.putApiMethod,
                body: convertAllFormData(payloadOverviewWork, "data[attributes]"),
                endPoint,
                token: true,
            });
        }


    }
    handleReportingChange = (values: Partial<ProfileFormValues>, event: OptionType) => {
        this.setState({ openMenu: false })
        const finalResult = this.state.reporting_employee.find(item => item.value === event.value);

        const { data } = this.state
        const finalData = {
            ...data,
            ...values,
            reporting_to: event.value,
            parent_user_details: finalResult?.data,
        };
        this.setState({ data: finalData });

    };
    handleStatusChange = (values: Partial<ProfileFormValues>, event: OptionType) => {
        this.setState({ openMenu: false })

        const { data,defaultData } = this.state
        const finalData = {
            ...data,
            ...values,
            ...defaultData,
            status: event.value,
        };
        this.setState({ data: finalData });
        if(defaultData?.role_id !== 5){

            this.getnewReportingTo()
        }
    };


    handleSelectChange = (values: Partial<ProfileFormValues>, event: OptionType, setFieldValue: { (field: string, value: ValueType, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }, keyItem: keyof ProfileFormValues, clearValue?: ClearValue | null | undefined) => {
        this.setState({ openMenu: false })
        let finalResult: OptionType | string | string[] = event;
        if (finalResult && typeof finalResult === "object" && Array.isArray(finalResult)) {
            finalResult = event.map((item: OptionType) => item.value)
        } else if (keyItem === "assigned_department_ids") {
            finalResult = [event?.value]
        } else {
            finalResult = (values.role_id === 3 && !event?.value) ? [] : event?.value
        }
        const { data } = this.state
        const finalData = {
            ...data,
            ...values,
            [keyItem]: finalResult,
            ...((keyItem === "role_id" || keyItem === "branch_id") ? { assigned_department_ids: [], reporting_to: "", parent_user_details: null } : {}),
            ...(keyItem === "assigned_department_ids" ? { reporting_to: "", parent_user_details: null } : {}),

        };

        if ((clearValue && data) && (data[keyItem] !== finalResult)) {
            this.setState({ data: { ...finalData, ...clearValue } });
            for (let item of Object.keys(clearValue)) {
                setFieldValue(item, clearValue[item]);
            }
        } else {
            this.setState({ data: finalData });
        }
    };

    handleDocUpload = (event: React.ChangeEvent<HTMLInputElement>, stateName: string, stateNameUrl: string, setFieldValue: (field: string, value: InputValue, shouldValidate?: boolean | undefined) => void
    ) => {
        const file = event.target.files?.[0];
        setFieldValue(stateName, file)
        const allowedFileTypes = ['image/png', 'image/jpeg', 'application/pdf', 'image/jpg'];
        if (file) {
            if (allowedFileTypes.includes(file.type)) {
                const fileSize = file.size / (1024 * 1024)
                if (fileSize <= 5) {
                    this.setState({
                        ...this.state,
                        data: {
                            ...this.state.data,
                            ...{ [stateName]: file },
                            [stateNameUrl]: {
                                name: file.name,
                                url: URL.createObjectURL(file),
                                size: fileSize,
                                uploaded_date: file.lastModified
                            }
                        },
                    });
                }
            }
        }
    };
    handleResponseForCountry = (from: string, message: Message) => {
        if (this.countryListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const countryApiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(countryApiResponse, this.props.navigation);
            if (countryApiResponse.data) {
                const { data } = countryApiResponse;
                const defaultValue = { value: "", label: configJSON.countryCodePlaceholder, isDisabled: true }
                if (data.length > 0) {
                    let finalValue = data.map((country: { id: string; name: string; }) => {
                        const { id: countryId, name } = country;
                        return { label: name, value: Number(countryId) };
                    })
                    finalValue = sortArrayAlphabetically(finalValue, 'label');
                    finalValue.unshift(defaultValue);

                    let countryCodeList = data.map((item: { id: string; country_code: string; alpha2: string; }) => {
                        const { id: countryId, country_code, alpha2 } = item;
                        return { label: `${country_code} (${alpha2})`, value: Number(countryId) };
                    })
                    countryCodeList = sortArrayAlphabetically(countryCodeList, 'label');
                    this.setState({ countryList: finalValue, countryCodeList });
                }
                else {
                    this.setState({ countryList: [defaultValue] });
                    toast.error(configJSON.countryErrorMessage);
                }
            } else {
                handleApiError(countryApiResponse.errors)
            }

        }
    }

    handleClearDoc = (stateName: string, stateNameUrl: string, setFieldValue: (field: string, value: string | OptionType | MaterialUiPickersDate | File | undefined, shouldValidate?: boolean | undefined) => void) => {
        setFieldValue(stateName, "")
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                ...{ [stateName]: "" },
                [stateNameUrl]: this.inialDocUrlObj
            },
        });
    };
    handleBranchDataResponse = (from: string, message: Message) => {
        if (this.getBranchListApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const response = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(response, this.props.navigation);
            if (response.branches.data) {
                let branchData = response.branches.data.map((item: { id: number, attributes: { branch_name: string; location_of_point_of_sale: string } }) => ({ value: item.id, label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }));
                branchData = sortArrayAlphabetically(branchData, 'label');
                let filteredBranch = response.branches.data.filter((branch: { id: string; }) =>
                    branch.id == this.state.data?.branch_id).map((item: { id: string | number; attributes: { branch_name: string; location_of_point_of_sale: string; }; }) => ({ value: item.id, label: `${item.attributes.branch_name} - ${item.attributes.location_of_point_of_sale}` }));
                if (isBO()) {
                    this.setState({
                        branch_list: branchData
                    })
                } else {
                    this.setState({
                        branch_list: filteredBranch
                    })
                }
            } else {
                handleApiError(response.errors);

            }

        }
    }
    getDepartments = () => {
        this.getDepartmentApiId = apiCall({ method: configJSON.getApiMethod, endPoint: `${configJSON.selectedDepartmentApiEndpoint}/${this.state.data?.branch_id}`, token: true })
    }
    handleDepartmentResponse = (from: string, message: Message) => {
        if (this.getDepartmentApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const response: ResponseDepartment[] = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(response, this.props.navigation);
            if (response.length > 0) {
                let finalRes: Lists[] = [];
                if (isBO()) {
                    response.forEach((item) => {
                        item.departments.forEach((depItem) => {
                            const { id, name } = depItem;
                            finalRes.push({ label: name, value: id });
                        })
                    })
                }
                else {
                    let departData = usersDepartments.map((item: { attributes: { id: string; name: string; } }) => {
                        return {
                            id: item.attributes.id,
                            name: item.attributes.name,
                            text: item.attributes.name
                        }
                    })
                    departData.map((depItem: { name: string; id: string; }) => {
                        finalRes.push({ label: depItem.name, value: depItem.id });
                    })
                }
                finalRes = sortArrayAlphabetically(finalRes, 'label');
                this.setState({ department_lists: finalRes });
            } else {
                this.setState({ department_lists: [] });
                toast.error(configJSON.noDepartmentMessage);
            }

        }
    }
    handleViewImage = (imageUrl: string | undefined) => {
        window.open(imageUrl, '_blank');
        this.setState({ isOpen: {} })
    };
    handleDownload = (urlLink: string | undefined, name: string | undefined) => {
        const handleLoading = () => {
            this.setState({ isOpen: {} })
        }
        handleDownloadImage(urlLink, name, handleLoading)

    };
    handleImageChange = (event: { target: { name: string; files: File[]; }; }, setFieldValue: (field: string, value: string | OptionType | MaterialUiPickersDate | File | undefined, shouldValidate?: boolean | undefined) => void
    ) => {
        const imageFile = event.target.files[0];
        const imageSize = imageFile.size / (1024 * 1024)
        if (imageFile) {
            if (imageSize <= 5) {
                setFieldValue(event.target.name, imageFile)
                this.setState({
                    currentImage: URL.createObjectURL(event.target.files[0])
                }, () => this.uploadDocuments(imageFile));
            } else {
                toast.error(configJSON.fileSizeErrorMsg)
            }
        }
    };
    handleResponseForEmployeeDetails = (from: string, message: Message) => {
        if (this.getEmployeeDetailsApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            handleExpiredToken(apiResponse, this.props.navigation);
            if (apiResponse.data) {
                this.setState({
                    data: apiResponse.data.attributes,
                    defaultData:apiResponse.data.attributes,
                    accountId: apiResponse.data.attributes.account_id,
                    total_child_count:apiResponse.data.attributes.total_child_count,
                    loading: false,
                    allow_edit: apiResponse.editable,
                    documents: apiResponse.data.attributes.documents,
                }, () => this.setresData(this.state.data))

            } else {
                handleApiError(apiResponse.errors)
                this.setState({ loading: false })
            }

        }
    }
    handleResponseForNewReportingTo = (from: string, message: Message) => {
        if (this.newReportingToApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(apiResponse, this.props.navigation);
             if (apiResponse.data) {
                const updatedData = apiResponse.data.filter((item: { id: number; }) => item.id !== this.state.accountId).map((item: { email: string; id: number; }) => {
                    return {
                        label: item.email,
                        value: Number(item.id),
                        data: item
                    }
                })
                this.setState({
                    new_reporting_employee: updatedData
                })

            } else {
                handleApiError(apiResponse.errors)
                this.setState({ loading: false,new_reporting_employee:[] })
            }

        }
    }
    uploadDocuments = (currentImage: string | ArrayBuffer | null | File) => {
        const { employeeId } = this.state
        this.setState({ loading: true })
        const payload = {
            document: currentImage
        }

        this.uploadDocumentsApiCallId = apiCall({
            method: configJSON.postApiMethod,
            body: convertFormData(payload),
            endPoint: `${configJSON.updateProfileApiEndPoint}/${employeeId}/upload_document`,
            token: true,
        });

    }
    handleResponseForUploadDocs = (from: string, message: Message) => {
        if (this.uploadDocumentsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const uploadDocRes = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(uploadDocRes, this.props.navigation);
            if (uploadDocRes.documents && uploadDocRes.message) {
                this.setState({
                    documents: uploadDocRes.documents,
                    currentImage: null,
                    loading: false
                })
                toast.success(uploadDocRes.message)
            } else {
                handleApiError(uploadDocRes.errors)
            }

        }
    }

    handleDeleteClick = () => {
        const { employeeId } = this.state
        this.setState({ loading: true })
        this.deleteDocumentApiId = apiCall({ method: configJSON.deleteApiMethod, endPoint: `${configJSON.updateProfileApiEndPoint}/${employeeId}/documents/${this.state.document_id}`, token: true });
        this.handleCloseDeleteModal()
    }
    handleResponseForDeleteDocs = (from: string, message: Message) => {
        if (this.deleteDocumentApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const deleteRes = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(deleteRes, this.props.navigation);
            if (deleteRes.message) {
                toast.success(configJSON.deleteMessage)
                this.getProfileDetails()
                this.setState({ loading: false })
            } else {
                this.setState({ loading: false })
                handleApiError(deleteRes.errors)
            }

        }
    }
    capitalizeRole = (string: string) => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
    handleResponseForGetRoles = (from: string, message: Message) => {
      if (this.getRoleApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const roleApiRes = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          handleExpiredToken(roleApiRes, this.props.navigation);
          if (roleApiRes.length > 0) {
            const formateRole =  roleApiRes.map((item: {id: number, name: string}) => ({value: item.id, label: this.capitalizeRole(item.name)}))
   
            this.setState({
                roleList: isManager() ? formateRole.slice(1, 3) : formateRole
            })
          }

      }
    }
    handleResponseForUpdatedProfile = (from: string, message: Message) => {
        if (this.updateProfileApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(apiResponse, this.props.navigation);
            if (apiResponse.data) {
                toast.success(configJSON.profileSuccessMessage)
                this.setState({ loading: false, edit: false, isOpen: {}, data: apiResponse.data.attributes }, () => this.setresData(this.state.data))
            } else {
                this.setState({ loading: false })
                handleApiError(apiResponse.errors)
            }

        }
    }
    handleResponseForReportingTo = (from: string, message: Message) => {
        if (this.reportingToApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const response = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(response, this.props.navigation);
            if (response.parent_users) {
                const {data: userData, defaultData} = this.state;
                let mergedUsers = response.parent_users.concat(response.super_parent_users);
                  const updatedData = mergedUsers.map((item: { email: string; id: number; }) => {
                    return {
                        label: item?.email,
                        value: Number(item?.id),
                        data: item
                    }
                })
                .filter((userData: OptionType) => userData.value !== this.state.accountId)

                const parentUser = defaultData?.parent_user_details && [{ label: defaultData.parent_user_details.email, value: defaultData.parent_user_details.id, data : {...defaultData.parent_user_details, name: defaultData.parent_user_details.owner_name} }]
                this.setState({ loading: false, reporting_employee: (defaultData?.role_id && userData?.role_id && (defaultData?.role_id < userData?.role_id) && updatedData.length === 0) ? parentUser : updatedData })
                
            } else {
                this.setState({ loading: false, reporting_employee: [] })
                handleApiError(response.errors)
            }

        }
    }
    handleCancel = () => {
        this.setState({
            edit: false,
            isOpen: {},
            data: this.state.defaultData
        })
    }
    handleDownloadPdf = () => {
        this.setState({ openMenu: false, loading: true, is_download: true })
        const element = this.pdfContentRef.current;
        const handleLoading = () => {
            this.setState({ loading: false, is_download: false })
        }
        commonDownloadPdfFunc(element, handleLoading, `${this.state.data?.owner_name} Profile Details`, `${this.state.data?.owner_name} Profile Details`, true)

    };
    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResponseForCountry(from, message)
        this.handleBranchDataResponse(from, message)
        this.handleResponseForUpdatedProfile(from, message)
        this.handleDepartmentResponse(from, message)
        this.handleResponseForEmployeeDetails(from, message)
        this.handleResponseForUploadDocs(from, message)
        this.handleResponseForDeleteDocs(from, message)
        this.handleResponseForReportingTo(from, message)
        this.handleResponseForNewReportingTo(from,message)
        this.handleResponseForGetRoles(from, message)
        // Customizable Area End
    }
    checkShowDepartments = () => {
      const { defaultData } = this.state
      return defaultData?.assigned_department_ids && defaultData.assigned_department_ids.length > 0
    }
    // Customizable Area End
}
