import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Box, Avatar, DialogContent, Button, Paper, DialogActions, Modal } from "@material-ui/core";
import { Wrapper } from "../../../components/src/Wrapper";
export const configJSON = require("./config");
import NoTickets from "../../../components/src/commonComponents/NoTickets.web";
import { noBranchImage, exportImage, profilePic, editIcon } from "../../OrganisationHierarchy/src/assets"
import CustomRadioSelect from "../../../components/src/commonComponents/CustomRadioSelect.web";
import EmployeeDirectoryController, { Props } from "./EmployeeDirectoryController.web";
import Pagination from "../../../components/src/commonComponents/Pagination.web";
import Loader from "../../../components/src/Loader";
import { commonButton, commonListingCss } from "../../../components/src/utils/commonInputStyle";
import CloseIcon from '@material-ui/icons/Close';
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import SuccessModal from "../../../components/src/commonComponents/SuccessModal.web";
import { statusList } from "../../../components/src/utils/constant";
import { isAgent, isBO } from "../../../components/src/utils/commonFunctions";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
export const styles = {
    ...commonButton,
    ...commonListingCss,
    feedbackBtn: {
        display: "flex",
        alignItems: "center",
        "& .MuiAvatar-root": {
            width: 31,
            height: 31,
        },
        "& p": {
            marginLeft: 6,
            textTransform: "capitalize" as const
        },
        "& img": {
            borderRadius: "50%"
        },
        "& button": {
            borderRadius: "unset",
            textTransform: "unset" as const,
            color: "#4196F0",
            padding: 0,
            borderBottom: "1px solid #4196F0",
            fontSize: "1rem",
            background: "transparent",
            "&:disabled": {
                borderBottom: "1px solid #4196F040",
                color: "#4196F040",
            },
            "&:hover": {
                background: "transparent",
            },
        },
    },
    exportItem: {
        width: "9rem !important",
        display: "flex",
        flexDirection: "column" as const,
        gap: "15px",
        "& .clearAll": {
            justifyContent: "end" as const
        }
    },
    headerDropFLex: {
        display: "grid",
        alignItems: "center",
        gap: "20px",
        flex: '1',
        gridTemplateColumns: "16% 18%"
    },
    loader: {
        "& div > div": {
            top: '50%',
        }
    },
    content: {
        display: "flex",
        justifyContent: "space-between" as const,
        alignItems: "flex-start",
        "& svg": {
            cursor: "pointer"
        }
    },
    profileImg: {
        width: "96px",
        height: "96px",
        borderRadius: "50%"
    },
    roleModel: {
        position: 'absolute' as const,
        top: '9vh',
        right: '3vw',
        maxWidth: 450,
        width: '100%',
        padding: '1rem',
        borderRadius: '8px',
        maxHeight: '91vh',
        overflowY: 'auto' as const,
    },
    dialogContent: {
        padding: "0 !important",
        "& .editBtn": {
            border: "1px solid #02020240",
            margin: 0,
            padding: "6px 18px",
            borderRadius: 8
        }
    },
    employeeDetail: {
        fontSize: "20px !important",
        marginTop: "1rem",
        "& h4": {
            fontWeight: 700,
            fontSize: "inherit"
        },
        "& p": {
            fontWeight: 500,
            fontSize: "inherit"
        },
        "& img": {
            width: "54px",
            height: "54px",
            borderRadius: "50%"
        },
        "& .gender": {
            textTransform: "capitalize" as const
        }
    },
    saveBtn: {
        justifyContent: "center" as const,
        marginTop: "1rem",
        "& button": {
            margin: 0
        }
    },
    teamMemberSec: {
        display: "flex",
        alignItems: "center",
        marginRight: 10,
        "& .avatar": {
            marginRight: 10,
        }
    },
    headerContainer: {
        alignItems: "flex-start"
    }

};
// Customizable Area End
export class EmployeeDirectory extends EmployeeDirectoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    render() {
        const { classes } = this.props;
        const { employee_list, select_department, is_open_department,
            department_list, select_downloadTYpe,
            is_open_download, is_open_role, select_role, openEditModel, item, is_edit, select_edit_role,
            meta, is_open_edit_role, select_edit_department, is_open_edit_department, loading, all_employee_list,
            assigned_department_ids, selected_departments, autoFocus, openSuccessModel, searchQuery, select_edit_status, is_open_edit_status
        } = this.state
        return (
            <>
                <Loader loading={loading} />
                <Wrapper
                    data-test-id="wrapper-comp"
                    searchHandleClick={this.handleSearch}
                    navigation={this.props.navigation}
                    divider
                    backbtn={true} title={configJSON.headTitle}
                    search={true}
                    bellIcn={true}
                    homeIcn={true}
                    querySearch={searchQuery}
                    autoFocus={autoFocus}
                    child={
                        <Box className={classes.listContainer}>
                            <Box className={`${classes.headerSection} ${classes.headerContainer}`}>
                                <Box className={classes.headerDropFLex}>
                                    <Box >
                                        <CustomRadioSelect
                                            title={configJSON.roleTitle}
                                            data={configJSON.roleType}
                                            selectedValue={select_role}
                                            toggleModal={this.toggleSelectRole}
                                            isModalOpen={is_open_role}
                                            onValueSelect={this.handleSelectRole}
                                        />
                                    </Box>
                                    {(!isAgent()) && <Box >
                                        <CustomRadioSelect
                                            selectedValue={select_department}
                                            onValueSelect={this.handleSelectDepart}
                                            data-test-id="department_select"
                                            title={configJSON.departSelect}
                                            data={department_list}
                                            toggleModal={this.toggleSelectDepart}
                                            isModalOpen={is_open_department}
                                        />
                                    </Box>}
                                </Box>
                                <Box className={classes.exportItem} >
                                    <CustomRadioSelect
                                        hasButton={true}
                                        downloadData={all_employee_list}
                                        selectedValue={select_downloadTYpe}
                                        onValueSelect={this.handleSelectDownload}
                                        title={configJSON.exportTitle}
                                        toggleModal={this.toggleSelectDownloadModal}
                                        isModalOpen={is_open_download}
                                        isIcon={true}
                                        imagePath={exportImage}
                                        data={configJSON.downloadType}
                                        onClick={this.handleExport}
                                    />
                                    <Box data-test-id="clearall_button" onClick={this.handleClearAllFilter} className={`${classes.feedbackBtn} clearAll`}>
                                        <Button disabled={this.handleDisable()}>{configJSON.clearAll}</Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={classes.listItem}>
                                <div>
                                    <Box className={`${classes.tableHeader} directoryHeader `}>
                                        {configJSON.employeeHeaderData.map((item: string, index: number) => (
                                            <Typography component="p" key={index}>{item}</Typography>
                                        ))}
                                    </Box>
                                    <Box className={classes.listMain}>
                                        {employee_list.length > 0 ?
                                            employee_list.map((item, index) => (
                                                <Box
                                                    data-test-id="row_item"
                                                    key={item.id}
                                                    className={`${classes.tableItem} directoryHeader listPadding`}
                                                    onClick={() => this.handleEdit(item)}
                                                    style={index === employee_list.length - 1 ? { marginBottom: "40px" } : {}}
                                                >
                                                    <Box className={classes.feedbackBtn}>
                                                        <Avatar
                                                            src={item.attributes.profile.data?.attributes.photo === null ? profilePic : item.attributes.profile.data?.attributes.photo}
                                                            alt="avatar"
                                                        />
                                                        <Typography data-test-id="firstname" component="p" >{item.attributes.profile.data === null ? "" : item.attributes.profile.data.attributes.owner_name}</Typography>
                                                    </Box>
                                                    <Typography data-test-id="email" component="p" className="item-width" >{item.attributes.email}</Typography>
                                                    <Typography component="p" >{item.attributes.role_name}</Typography>
                                                    <Typography component="p" >{item.attributes.profile.data?.attributes.phone_number}</Typography>
                                                    <Typography component="p" >{item.attributes.joining_date === null ? "" : moment(item.attributes.joining_date).format('DD/MM/YYYY')}</Typography>
                                                    <Typography component="p" className="status" >{item.attributes?.status}</Typography>
                                                    <Typography component="p" className="item-width">{item.attributes.location_of_point_of_sale}</Typography>
                                                </Box>

                                            )) :
                                            employee_list.length === 0 && searchQuery ?
                                                <Box className={classes.noList}>
                                                    <Typography variant="h5" data-test-id="search_result" >{configJSON.searchResult} <strong>{searchQuery}</strong>.</Typography>
                                                </Box>
                                                :
                                                <Box className={classes.noList}>
                                                    <NoTickets
                                                        imagePath={noBranchImage}
                                                        title={configJSON.titleNoEmployee}
                                                        content={configJSON.contentText}
                                                    />
                                                </Box>

                                        }
                                    </Box>
                                </div>
                                <Box className={classes.paginationContainer}>
                                    {employee_list.length > 0 && <Pagination meta={meta} onPageChange={this.handlePageChange} />}
                                </Box>
                            </Box>
                        </Box >
                    } />
                <Modal
                    open={openEditModel}
                    onClose={this.handleClose}
                    aria-labelledby="edit-modal-title"
                    aria-describedby="edit-modal-description"
                    data-test-id="dialog_box"
                >
                    <Paper className={classes.roleModel}>
                        <DialogContent className={classes.dialogContent}>
                            <Box className={classes.content}>
                                <Avatar
                                    src={item.attributes?.profile.data?.attributes.photo === null ? profilePic : item.attributes?.profile.data?.attributes.photo}
                                    alt="avatar"
                                    className={classes.profileImg}
                                />
                                {(isBO()) ?
                                    !is_edit ?
                                        <Box className={`${classes.btnInner} editBtn`} data-test-id="edit_button" onClick={this.handleEditable}>
                                            <Avatar
                                                src={editIcon}
                                                alt="edit"
                                                className={classes.avatarImage}
                                            />
                                            <Button>{configJSON.editButton}</Button>
                                        </Box> : <CloseIcon onClick={this.handleClose} />
                                    : <CloseIcon onClick={this.handleClose} />}
                            </Box>
                            <Box className={classes.employeeDetail}>
                                <Typography component="h4">{item.attributes?.profile.data === null ? "" : item.attributes?.profile.data.attributes.owner_name}</Typography>
                                <Typography component="p">{item.attributes?.email}</Typography>
                                <Typography component="p">{item.attributes?.profile.data?.attributes.phone_number}</Typography>
                            </Box>
                            <Box className={classes.employeeDetail}>
                                <Typography component="h4">{configJSON.branchName}</Typography>
                                <Typography className="gender" component="p">{`${item.attributes?.branch_name} - ${item.attributes?.location_of_point_of_sale}`}</Typography>
                            </Box>
                            <Box className={classes.employeeDetail}>
                                <Typography component="h4">{configJSON.department}</Typography>
                                {is_edit && item.attributes?.status !== "inactive" ? <Box >
                                    {(item.attributes?.role_name === "Manager" && select_edit_role === "Manager") || select_edit_role === "Manager" ? <DropdownSearch
                                        options={selected_departments}
                                        value={assigned_department_ids}
                                        onChangeValue={(event: OptionType) => this.handleSelectChange(event)}
                                        id="assignee_ids"
                                        isSearchable={true}
                                        placeholder={configJSON.departSelect}
                                        isMulti={true}
                                        showError={assigned_department_ids.length > 0 ? false : true}
                                        errorText={assigned_department_ids.length <= 0 ? configJSON.departMessage : ""}
                                    /> : <CustomRadioSelect
                                        data-test-id="department_select"
                                        selectedValue={select_edit_department}
                                        onValueSelect={this.handleSelectEditDepart}
                                        title={configJSON.departSelect}
                                        toggleModal={this.toggleSelectEditDepart}
                                        isModalOpen={is_open_edit_department}
                                        data={department_list}
                                    />}
                                </Box> : <Typography component="p">{item.attributes?.department_names !== null && item.attributes?.department_names.join(",")}</Typography>}
                            </Box>
                            <Box className={classes.employeeDetail}>
                                <Typography component="h4">{configJSON.status}</Typography>
                                {is_edit ? <Box>
                                    <CustomRadioSelect
                                        title={configJSON.statusText}
                                        data={statusList.slice(1, 3)}
                                        selectedValue={select_edit_status.charAt(0).toUpperCase() + select_edit_status.slice(1)}
                                        toggleModal={this.toggleSelectStatus}
                                        isModalOpen={is_open_edit_status}
                                        onValueSelect={this.handleSelectStatus}
                                    />
                                </Box> : <Typography component="p">{item.attributes?.status.charAt(0).toUpperCase() + select_edit_status.slice(1)}</Typography>}
                            </Box>
                            <Box className={classes.employeeDetail}>
                                <Typography component="h4">{configJSON.role}</Typography>
                                {is_edit && item.attributes?.status !== "inactive" ? <Box>
                                    <CustomRadioSelect
                                        title={configJSON.roleTitle}
                                        data={configJSON.roleType}
                                        selectedValue={select_edit_role}
                                        toggleModal={this.toggleSelectEditRole}
                                        isModalOpen={is_open_edit_role}
                                        onValueSelect={this.handleSelectEditRole}
                                    />
                                </Box> : <Typography component="p">{item.attributes?.role_name}</Typography>}
                            </Box>
                            <Box className={classes.employeeDetail}>
                                <Typography component="h4">{configJSON.location}</Typography>
                                <Typography component="p">{item.attributes?.location_of_point_of_sale}</Typography>
                            </Box>
                            <Box className={classes.employeeDetail}>
                                <Typography component="h4">{configJSON.teamMemberText}</Typography>
                                <Box className={classes.teamMemberSec}>
                                    {item.attributes?.team_members.map(item => (
                                        <Avatar
                                            className="avatar"
                                            src={item.photo === null ? profilePic : item.photo}
                                            alt="avatar"
                                        />
                                    ))}
                                </Box>

                            </Box>
                        </DialogContent>
                        {is_edit && (
                            <DialogActions className={classes.saveBtn}>
                                <Button
                                    data-test-id="save-btn"
                                    disabled={assigned_department_ids.length <= 0}
                                    variant="contained"
                                    className={classes.primaryBtn}
                                    onClick={this.editEmployee}
                                >
                                    {configJSON.saveButtonText}
                                </Button>
                            </DialogActions>
                        )}
                    </Paper>

                </Modal>
                <SuccessModal
                    title={configJSON.confirmMessage}
                    message=""
                    open={openSuccessModel}
                    handleClick={this.handleOkClick}
                    buttonText={configJSON.okButtonText}
                />
            </>
        );
    }
    // Customizable Area End
}
export default withStyles(styles)(EmployeeDirectory);
