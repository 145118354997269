// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { ClassNameMap } from "@material-ui/styles";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleApiError, handleExpiredToken, checkToken, getDiffs, handleUndefinedData, getUserRoleID } from "../../../components/src/utils/commonFunctions";
import { toast } from "react-toastify";
import CommonTemplateController from "./CommonTemplateController.web";
import { CreateTemplate13Schema, EditTemplate13Schema } from "../../../components/src/utils/validationSchema";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"formContainer" | "inputField" | "textAreaField" | "approvalContainer"
    | "dateField" | "fileInput" | "primaryBtn" | "secondaryBtn" | "templateBtnWrapper" | "inputLabel2" | "dropdownWrapper" | "imageUploadContainer" | "titleText" | "gridItem" | "templateFooterContainer"
    | "fieldWrapper" | "inputLabel" | "helperText" | "textAreaInputField"
  >;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
interface TemplateData {
  title: string;
  department_id: string;
  branch_id: string;
  attachment: null;
  send_for_approval: boolean;
  [key: string]: string | null | boolean;
}

// Customizable Area End

export default class Template13Controller extends CommonTemplateController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      ...this.initialState,
      ...this.state
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resDataTemplate13 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resDataTemplate13, this.props.navigation);
      this.setState({ loading: false });
      if (requestCallId && resDataTemplate13) {
        this.setState({ loading: false });
        switch (requestCallId) {
          case this.createTemplateApiId:
            this.addTemplate13Response(resDataTemplate13, configJSON.procedureSuccessMessage);
            break;
          case this.duplicateTemplateApiId:
            this.addTemplate13Response(resDataTemplate13, configJSON.procedureDuplicateMessage);
            break;
          case this.deleteTemplateApiId:
            this.deleteTemplateResponse(resDataTemplate13);
            break;
          case this.branchDataApiId:
            this.getBranchesResponse(resDataTemplate13);
            break;
          case this.departmentsApiId:
            this.getDepartmentsResponse(resDataTemplate13);
            break;
          case this.favouriteTemplateApiId:
            const toastMessage = this.state.formData.favorite ? configJSON.procedureUnFavMessage : configJSON.procedureFavMessage;
            this.addTemplate13Response(resDataTemplate13, toastMessage);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  componentDidMount(): any {
    // Customizable Area Start
    checkToken();
    this.getBranchData();
    let { state } = this.props.navigation.history.location;
    if (state) {
      this.setDefaultData13(state)
    } else if(![2,3].includes(Number(getUserRoleID()))) {
      this.props.navigation.goBack();
    }
    // Customizable Area End
  }

  setDefaultData13(item: any, action?: string) {
    const { id, creator, attachment, descriptions_attributes } = item
    this.setState({
      creator,
      id,
      attachment: attachment.url ? attachment : "",
      formData: item,
      defaultData: item,
      imageUrls: {
        image1: handleUndefinedData(descriptions_attributes[0].images[0], ""),
        image2: handleUndefinedData(descriptions_attributes[1].images[0], ""),
        image6: handleUndefinedData(descriptions_attributes[5].images[0], ""),
        image3: handleUndefinedData(descriptions_attributes[2].images[0], ""),
        image4: handleUndefinedData(descriptions_attributes[3].images[0], ""),
        image5: handleUndefinedData(descriptions_attributes[4].images[0], ""),
        image7: handleUndefinedData(descriptions_attributes[6].images[0], ""),
        image8: handleUndefinedData(descriptions_attributes[7].images[0], ""),
        image9: handleUndefinedData(descriptions_attributes[8].images[0], ""),
        image10: handleUndefinedData(descriptions_attributes[9].images[0], ""),
        image11: handleUndefinedData(descriptions_attributes[10].images[0], ""),
        image12: handleUndefinedData(descriptions_attributes[11].images[0], ""),
      },
      templateAction: action ?? configJSON.view
    })
  }

  template13InitialValues = (): TemplateData => {
    const { defaultData, formData, templateAction } = this.state;
    const isCreateTemplate13 = templateAction === configJSON.create;
  
    const getDefaultValue = (index: number): string => {
      return isCreateTemplate13
        ? defaultData[`description${index + 1}`]
        : handleUndefinedData(formData?.descriptions_attributes[index]?.description, "");
    };
  
    const data: TemplateData = {
      title: isCreateTemplate13 ? defaultData.title : handleUndefinedData(formData?.title, ""),
      department_id: isCreateTemplate13 ? defaultData.department_id : handleUndefinedData(formData?.department_id, ""),
      branch_id: isCreateTemplate13 ? defaultData.branch_id : handleUndefinedData(formData?.branch_id, ""),
      attachment: null,
      send_for_approval: isCreateTemplate13
        ? defaultData.send_for_approval
        : handleUndefinedData(formData?.send_for_approval, false),
    };
  
    for (let i = 0; i < 12; i++) {
      const key = `description${i + 1}`;
      data[key] = getDefaultValue(i);
      data[`image${i + 1}`] = null;
    }
  
    return data;
  };
  
  
  template13ValidationSchema = () => {
    const { templateAction } = this.state;
    return templateAction === configJSON.edit ? EditTemplate13Schema : CreateTemplate13Schema
  }

  addEditTemplate13 = (values: any) => {
    const { defaultData, template_id, formData } = this.state;

    const { attachment, title, send_for_approval, branch_id, department_id, descriptions_attributes } = defaultData;
    const { description1, description2,
      description3, description4, description5, description6, description7, description8, description9, description10, description11,
      description12, image1, image2,
      image3, image4, image5, image6,
      image7, image8, image9, image10, image11, image12
    } = values;

    const { id } = formData;

    const createDescriptionsAttr = [
      {
        images: [image1],
        description: description1
      },
      {
        images: [image2],
        description: description2
      },
      {
        description: description3,
        images: [image3]
      }, 
      {
        images: [image4],
        description: description4
      },
      {
        description: description5,
        images: [image5]
      }, 
      {
        images: [image6],
        description: description6
      },
      {
        description: description7,
        images: [image7]
      }, 
      {
        description: description8,
        images: [image8]
      }, 
      {
        description: description9,
        images: [image9]
      }, 
      {
        description: description10,
        images: [image10]
      }, 
      {
        description: description11,
        images: [image11]
      },
      {
        description: description12,
        images: [image12]
      }
    ];

    const editDescriptionsAttributes = () => {
      return [
        {
          ...(image1 ? { images: [image1] } : {}),
          description: description1,
          id: this.state.formData.descriptions_attributes[0].id,
        }, {
          ...(image2 ? { images: [image2] } : {}),
          description: description2,
          id: this.state.formData.descriptions_attributes[1].id,
        },
        {
          ...(image3 ? { images: [image3] } : {}),
          description: description3,
          id: this.state.formData.descriptions_attributes[2].id,
        }, {
          ...(image4 ? { images: [image4] } : {}),
          description: description4,
          id: this.state.formData.descriptions_attributes[3].id,
        },
        {
          ...(image5 ? { images: [image5] } : {}),
          description: description5,
          id: this.state.formData.descriptions_attributes[4].id,
        }, {
          ...(image6 ? { images: [image6] } : {}),
          description: description6,
          id: this.state.formData.descriptions_attributes[5].id,
        },
        {
          description: description7,
          id: this.state.formData.descriptions_attributes[6].id,
          ...(image7 ? { images: [image7] } : {}),
        }, {
          description: description8,
          id: this.state.formData.descriptions_attributes[7].id,
          ...(image8 ? { images: [image8] } : {}),
        }, {
          description: description9,
          id: this.state.formData.descriptions_attributes[8].id,
          ...(image9 ? { images: [image9] } : {}),
        }, {
          description: description10,
          id: this.state.formData.descriptions_attributes[9].id,
          ...(image10 ? { images: [image10] } : {}),
        }, {
          description: description11,
          id: this.state.formData.descriptions_attributes[10].id,
          ...(image11 ? { images: [image11] } : {}),
        }, {
          description: description12,
          id: this.state.formData.descriptions_attributes[11].id,
          ...(image12 ? { images: [image12] } : {}),
        }
      ];
    }
    const orriginalData13 = {
      send_for_approval,
      title,
      branch_id,
      department_id,
      descriptions_attributes,
      ...(attachment ? { attachment } : {}),
    };

    const changedData13 = {
      department_id: values.department_id,
      title: values.title,
      branch_id: values.branch_id,
      send_for_approval: values.send_for_approval,
      ...(values.attachment ? { attachment: values.attachment } : this.checkEmptyAttachment()),
      ...(!id ? { template_id } : {}),
      descriptions_attributes: id ? editDescriptionsAttributes() : createDescriptionsAttr,
    };

    let differenceData13: any = getDiffs(orriginalData13, changedData13);
    this.addEditTemplateApiCall(differenceData13)
  }

  addTemplate13Response = (responseData13: any, message: string) => {
    if (responseData13.data) {
      if (this.state.id) {
        const dataItem = responseData13.data.attributes
        this.setStateData(dataItem);
        this.setDefaultData13(dataItem)
      } else {
        this.setState({ ...this.initialState });
        this.navigateToListingPage()
      }
      this.handleActions(configJSON.view)
      toast.success(message);
    } else if (responseData13.message) {
      const {defaultData} = this.state
      const dataItem = {...defaultData, favorite: !defaultData.favorite};
      this.setStateData(dataItem);
      this.setDefaultData13(dataItem)
      toast.success(message);
    } else {
      handleApiError(responseData13.errors);
    }
  };
  // Customizable Area End
}
