// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ClassNameMap } from "@material-ui/styles";
import { apiCall, filterNullObj, handleApiError, handleDownloadImage, handleExpiredToken, isBO, isManager } from "../../../components/src/utils/commonFunctions";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import { DateData } from "./LibraryController.web";
import moment from "moment";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap<"listContainer" | "cardWrapper" | "noTicketContainer" | "noTicketOuterContainer" | "templateImg" | "procedureTitleTxt" | "dateTxt" | "favouriteIcn" | "menuImg" | "menuTxt" | "menuPopper" | "departSelect" | "listItem" | "tableHeader" | "listMain" | "tableItem" | "imgContainer" | "svgIcon" | "deleteModalDesc" | "btn" | "btn1" | "paginationBox">;
  date: DateData;
  searchQuery: string;
  gridView: boolean;
  allowEditDelete: (arg0: string | number | undefined) => void;
  formData:FormValues
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  miscellaneousDoc_list: MiscellaneousDocData[];
  anchorEl: HTMLElement | null;
  miscellaneousDoc_id: string;
  header_list: string[];
  isOpen: {
    [itemKey: string]: boolean;
  };
  openDeleteModal: boolean;
  search_miscellaneousDoc_list: MiscellaneousDocData[] | null;
  meta: {
    total_count: number;
    total_pages: number;
    current_page: number;
    next_page: null | number;
    previous_page: null | number;
  },
  isBO:boolean;
  isManager:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}
// Customizable Area Start

interface FormValues {
  branch_id: string;
  department_id: string;
}

interface ResErrorData {
  errors: string[]
}

interface Attachment {
  name: string;
  url: string;
  uploaded_date: string;
}

interface ResponseMiscellaneousDoc {
  procedures: {
    data: {
      attributes: {
        id: string;
        title: string;
        created_at: string;
        template_id: number;
        attachment: Attachment,
        procedure_template: {
          data: {
            attributes: {
              template_image: {
                url: string;
                name: string;
              }
            }
          }
        }
      }
    }[]
  };
  meta: {
    total_pages: number;
    total_count: number;
    current_page: number;
    next_page: number | null;
    previous_page: number | null;
  }
}

interface MiscellaneousDocData {
  attributes: {
    id: string;
    creator?: ProcedureCreator;
    title: string;
    created_at: string;
    template_id: number;
    attachment: Attachment;
    procedure_template: {
      data: {
        attributes: {
          template_image: {
            url: string;
            name: string;
          }
        }
      }
    }
  }
}
export interface ProcedureCreator {
  id: number | string;
  name: string;
  designation: string;
  email: string
} 
// Customizable Area End

export default class MiscellaneousDocListingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getMiscellaneousDocsApiId: string = ""
  deleteMiscellaneousDocApiId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      loading: false,
      miscellaneousDoc_list: [],
      anchorEl: null,
      miscellaneousDoc_id: "",
      header_list: configJSON.miscDocHeaderList,
      isOpen: {},
      openDeleteModal: false,
      search_miscellaneousDoc_list: null,
      meta: {
        total_pages: 1,
        total_count: 1,
        current_page: 1,
        next_page: null,
        previous_page: null
      },
      isBO:isBO(),
      isManager: isManager()
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const { date: { from_date, to_date, time_range }, searchQuery,formData } = this.props
    this.setState({ loading: true })
    this.getMiscellaneousDocs(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(miscPrevProps: Props, miscPrevState: S) {
    const { date: { from_date, to_date, time_range }, searchQuery,formData } = this.props
    if ((miscPrevProps.date.time_range !== this.props.date.time_range) || (miscPrevProps.date.from_date !== this.props.date.from_date) || (miscPrevProps.date.to_date !== this.props.date.to_date) || formData.branch_id !== miscPrevProps.formData.branch_id || formData.department_id !== miscPrevProps.formData.department_id) {
      if (from_date && to_date) {
        this.setState({ loading: true })

        this.getMiscellaneousDocs(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
      }
      if (time_range !== "custom") {
        this.setState({ loading: true })
        this.getFilteredMiscellaneousDocs(from_date, to_date, time_range, searchQuery)
      }
    }
    if (miscPrevProps.gridView !== this.props.gridView) {
      this.closeDropDown()
    }
    if (miscPrevProps.searchQuery !== this.props.searchQuery) {
      const { date: { from_date, to_date, time_range }, searchQuery } = this.props
      this.setState({ loading: true })
      this.getMiscellaneousDocs(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id, searchQuery)
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let miscDocResJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(miscDocResJson, this.props.navigation);
      this.setState({ loading: false });

      if (apiCallId && miscDocResJson) {
       if (apiCallId === this.getMiscellaneousDocsApiId) {
          this.getMiscellaneousDocsResponse(miscDocResJson);
        } else if (apiCallId === this.deleteMiscellaneousDocApiId) {
          this.handleDeleteDocResponse(miscDocResJson);
        }
      }
    }
  }


  getMiscellaneousDocs = (page: number, from_date: string | null, to_date: string | null, time_range: string | null, department_id: string, searchQuery?: string) => {
    this.setState({loading:true})
    let endPointfiter: string = `${configJSON.getMiscellaneousDocListApiEndPoint}`
    const {branch_id} = this.props.formData
    let allData = {page, custom_start_date: from_date ? moment(from_date).format("DD/MM/YYYY") : "", custom_end_date: to_date ? moment(to_date).format("DD/MM/YYYY") : "", time_range, branch_id, department_id, query: searchQuery};
    
    this.getMiscellaneousDocsApiId = apiCall({ method: configJSON.getMethod, endPoint: endPointfiter  + filterNullObj(allData) , token: true })
  }

  getFilteredMiscellaneousDocs = (from_date: string | null, to_date: string | null, time_range: string | null, searchQuery: string = '') => {
    const department_id = this.props.formData.department_id
    switch (time_range) {
      case configJSON.today:
        this.getMiscellaneousDocs(this.state.meta.current_page, from_date, to_date, "today", department_id, searchQuery)
        break
      case configJSON.yesterday:
        this.getMiscellaneousDocs(this.state.meta.current_page, from_date, to_date, "yesterday", department_id, searchQuery)
        break
      case configJSON.lastDays:
        this.getMiscellaneousDocs(this.state.meta.current_page, from_date, to_date, "15_days", department_id, searchQuery)
        break
      default:
        this.getMiscellaneousDocs(this.state.meta.current_page, from_date, to_date, "", department_id, searchQuery)
        break
    }
  }


  getMiscellaneousDocsResponse = (responseData: ResponseMiscellaneousDoc & ResErrorData) => {
    this.closeDropDown()
    if (responseData.errors) {
      this.setState({loading:false})
      handleApiError(responseData.errors)
    } else {
      this.setState(prev => ({loading:false, miscellaneousDoc_list: responseData.procedures.data, meta: responseData.meta ?? prev.meta }))
    }
  }

  handleDeleteDocResponse = (responseData: ResErrorData) => {
    const { date: { from_date, to_date, time_range },formData } = this.props
    if (responseData.errors) {
      handleApiError(responseData.errors)
    } else {
      this.getMiscellaneousDocs(this.state.meta.current_page, from_date, to_date, time_range, formData.department_id)
      toast.success(configJSON.deleteDocumentSuccessMsg)
    }
  }

  handleDeleteClick = () => {
    this.setState({loading:true})
    this.deleteMiscellaneousDocApiId = apiCall({ method: configJSON.deleteMethod, endPoint: `${configJSON.deleteMiscellaneousDocApiEndPoint}${this.state.miscellaneousDoc_id}`, token: true });
    this.handleCloseDeleteModal()
  }

  handleCloseDeleteModal = () => {
    this.setState({ openDeleteModal: false })
  }
  handleOpenDeleteModal = () => {
    this.setState({ openDeleteModal: true })
    this.closeDropDown()
  }


  toggleDropdown = (itemId: string) => {
    this.setState((prevStates) => {
      const updatedIsOpens = { ...prevStates.isOpen };

      for (const keyValue in updatedIsOpens) {
        updatedIsOpens[keyValue] = false;
      }

      updatedIsOpens[itemId] = !prevStates.isOpen[itemId];

      return {
        isOpen: updatedIsOpens,
        miscellaneousDoc_id: itemId,
      };
    });
  };

  closeDropDown = () => {
    this.setState((prevStates) => {
      const updatedIsOpens = { ...prevStates.isOpen };

      for (const keyValue in updatedIsOpens) {
        updatedIsOpens[keyValue] = false;
      }

      return {
        isOpen: updatedIsOpens,
      };
    });
  }

  handleDownload = (attr: { attachment: Attachment }) => {
    this.setState({ loading: true });
    const { attachment } = attr;
    const name = attachment.name
    const url = attachment.url
    const handleLoading = () => {
      this.setState({loading:false, isOpen: {}})
  }
  handleDownloadImage(url, name, handleLoading)
  }

  handlePageChange = (page: number) => {
    const { date: { from_date, to_date, time_range },formData } = this.props
    this.setState({
      meta: {
        ...this.state.meta,
        current_page: page
      }
    });
    this.getMiscellaneousDocs(page, from_date, to_date, time_range, formData.department_id)
  };


  // Customizable Area End


}
