import React, { Component, ReactNode } from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import { ClassNameMap } from "@material-ui/styles";
import { commonInputField } from "../utils/commonInputStyle";
import { attachIcon, closeIcon } from "./assets";
import { Typography, Box, InputLabel, TextField } from "@material-ui/core";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export const styles: Styles<Theme, {}, string> = () => ({
  ...commonInputField,

});
interface ActionDropdownProps {
  attachment: any;
  attachmentError?: string;
  templateAction?: string;
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => void;
  clearAttachment: (setFieldValue: any) => void;
  setFieldValue: any;
  isViewOnly?: () => boolean;

  classes: ClassNameMap<"fieldWrapper" | "fileInput" | "inputLabel" | "inputField" | "helperText"

  >
  required?: boolean;
  attachmentTitle?: string;
  attachmentId?: string;
  placeholderIcon?: string;
  placeholderText?: string;
  accept?: string;
}
// Customizable Area End

export class AttachFileInput extends Component<ActionDropdownProps> {
  constructor(props: ActionDropdownProps) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  render(): ReactNode {
    const {
      attachment,
      attachmentError,
      templateAction,
      handleFileUpload,
      setFieldValue,
      clearAttachment,
      isViewOnly,
      classes,
      attachmentTitle,
      attachmentId,
      placeholderIcon,
      placeholderText,
      accept,
      required
    } = this.props;

    return (
      <Box className={`${classes.fieldWrapper} ${classes.fileInput} containerWidth`} style={{ maxWidth: "441px" }}>
        <InputLabel required={required ?? false} className={classes.inputLabel}>
          {attachmentTitle ?? configJSON.attachmentTitle}
        </InputLabel>
        <TextField
          key={attachment?.url}
          id={attachmentId ?? configJSON.attachmentName}
          name={attachmentId ?? configJSON.attachmentName}
          variant="outlined"
          type="file"
          className={classes.inputField}
          disabled={isViewOnly && isViewOnly()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleFileUpload(e, setFieldValue) }}
          InputProps={{
            inputProps: {
              accept: accept ?? "",
            },
          }}
        />
        {attachment?.name ?
          <Box style={{ justifyContent: "space-between" }} className={`label-block ${attachmentError && "error-block"}`}>
            <Typography component="span" className="file-text file-name" id="template8-file-name">
              {attachment.name}
            </Typography>
            {templateAction !== configJSON.viewBtn && <img id="close-icon" className="close-icon" src={closeIcon} alt="Close" onClick={clearAttachment.bind(null, setFieldValue)} />}
          </Box>
          :
          <Box className={`label-block ${attachmentError && "error-block"}`}>
            <img src={placeholderIcon ?? attachIcon} alt="Attach" />
            <Typography component="span" className="file-text" id="template8-file-name">
              {placeholderText ?? configJSON.attachFileText}
            </Typography>
          </Box>
        }
        {attachmentError && <Box data-test-id="error-message" className={classes.helperText}>{attachmentError}</Box>}
        {attachment?.name && <Box className={`${classes.helperText} attachment`}><Typography target="_blank" component="a" href={attachment.url}>View Attachment</Typography></Box>}
      </Box>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(AttachFileInput);
// Customizable Area End

