import React from "react";
// Customizable Area Start
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { DateRange } from '@material-ui/icons';

import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, InputLabel, TextField, Button, Grid, FormHelperText } from "@material-ui/core";
import CreateTicketController, { Props, configJSON } from "./CreateTicketController.web"
import { Formik ,Form} from 'formik';
import { CreateTicketSchema } from "../../../components/src/utils/validationSchema"
import { Wrapper } from "../../../components/src/Wrapper";
import { styles } from "./CreateTicketStyles.web";
import { handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import Loader from "../../../components/src/Loader";
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";
import  SuccessModal  from "../../../components/src/commonComponents/SuccessModal.web";
import AttachFileInput  from "../../../components/src/commonComponents/AttachFileInput.web";
// Customizable Area End

export class CreateTicket extends CreateTicketController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const { classes } = this.props;
    const { ticket_type_list, branch_list, employees_list,
       priorites, attachment, apiError, loading,department_list,open
      } = this.state;
    return (
      <>
        <Loader loading={loading} />
        <Wrapper data-test-id="create-ticket-wrapper-test" navigation={this.props.navigation} divider backbtn={true} title="Raise Tickets" bellIcn={true} homeIcn={true} child={
          <Box>
            <Typography variant="h4" className={classes.createTicketTitle}>
              {configJSON.titleText}
            </Typography>
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={this.state.defaultData}
              validationSchema={CreateTicketSchema}
              onSubmit={this.handleSave}
            >
              {({ errors, touched, handleBlur, handleChange, setFieldValue, handleReset, values }) => (
                <Form translate={undefined} className={classes.formContainer}>
                  <Box className={classes.fieldWrapper}>
                    <InputLabel required className={classes.inputLabel}>
                      {configJSON.titleFieldText}
                    </InputLabel>
                    <TextField
                      type="text"
                      variant="outlined"
                      placeholder={"Add text here"}
                      name={"title"}
                      value={values.title}
                      onChange={handleChange}
                      fullWidth
                      className={classes.inputField}
                      error={handleInvoiceErrors(
                        errors.title,
                        touched.title
                      )}
                      helperText={handleInvoiceHelperText(
                        errors.title,
                        touched.title
                      )}
                      onBlur={handleBlur}
                    />
                  </Box>
                  <Grid alignItems="stretch" direction="row" container spacing={3} style={{ marginTop: "0px" }}>
                    <Grid item sm={6}>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.descriptionText}
                        </InputLabel>
                        <TextField
                          data-test-id="txtInputDescription"
                          type="text"
                          variant="outlined"
                          name={"description"}
                          value={values.description}
                          placeholder="Add text here"
                          onChange={handleChange}
                          fullWidth
                          multiline
                          className={`${classes.inputField} ${classes.textAreaField} ticketTextAreaField`}
                            error={handleInvoiceErrors(
                              errors.description,
                              touched.description
                            )}
                            helperText={handleInvoiceHelperText(
                              errors.description,
                              touched.description
                            )}
                            onBlur={handleBlur}
                        />
                        <Typography className={classes.maxSizeTicket} component="span">{configJSON.characterLengthText}</Typography>
                      </Box>
                      <Grid container spacing={3}>
                        <Grid item sm={6}>
                          <Box className={classes.fieldWrapper}>
                            <InputLabel required className={classes.inputLabel}>
                              {configJSON.dueDateText}
                            </InputLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                variant="inline"
                                margin="normal"
                                fullWidth
                                className={`${classes.inputField} ${classes.dateField}`}
                                InputProps={{
                                  endAdornment: <DateRange />,
                                  disableUnderline: true,
                                }}
                                autoOk
                                name="due_date"
                                value={values.due_date}
                                format="MM/dd/yyyy"
                                allowKeyboardControl={false}
                                placeholder={"Enter the date of creating of the company"}
                                onChange={setFieldValue.bind(null, "due_date")}
                                onBlur={handleBlur}
                                disablePast={true}
                              />
                            </MuiPickersUtilsProvider>
                            <FormHelperText className="error-text">
                              {touched.due_date && errors.due_date}
                            </FormHelperText>
                          </Box>
                        </Grid>
                        <Grid item sm={6}>
                          <Box className={`${classes.fieldWrapper} ${classes.fileInput}`}>
                            <Box className={classes.fileUploadInput}>
                            <AttachFileInput
                                attachmentTitle={configJSON.attachmentTitle}
                                attachmentId="attachment"
                                placeholderText={configJSON.attachmentPlaceholder}
                                accept={configJSON.acceptImageType}
                                attachment={attachment}
                                setFieldValue={setFieldValue}
                                handleFileUpload={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(event, setFieldValue) }}
                                clearAttachment={() => this.handleClearFile(setFieldValue)}
                                attachmentError={apiError}
                            />
                            {!attachment && !apiError && <Typography className={classes.maxSizeTicket} component="span">{configJSON.fileSizeText}</Typography>} 
                        </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.prorityText}
                        </InputLabel>
                        <DropdownSearch
                          options={priorites}
                          value={values.priority}
                          onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "priority")}
                          onBlur={handleBlur}
                          id="priority"
                          isSearchable={false}
                          placeholder={configJSON.priorityPlaceholder}
                          noOptionsMessage={configJSON.noPriorityMessage}
                          showError={handleInvoiceErrors(
                            errors.priority,
                            touched.priority
                          )}
                          errorText={handleInvoiceHelperText(
                            errors.priority,
                            touched.priority
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={6}>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.ticketTypeText}
                        </InputLabel>
                        <DropdownSearch
                          options={ticket_type_list}
                          value={values.ticket_type_id}
                          onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "ticket_type_id",{department_id: "", assignee_ids: []})}
                          onBlur={handleBlur}
                          id="ticket_type_id"
                          isSearchable={true}
                          placeholder={configJSON.ticketTypePlaceholder}
                          noOptionsMessage={configJSON.noTicketMessage}
                          showError={handleInvoiceErrors(
                            errors.ticket_type_id,
                            touched.ticket_type_id
                          )}
                          errorText={handleInvoiceHelperText(
                            errors.ticket_type_id,
                            touched.ticket_type_id
                          )}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.branchText}
                        </InputLabel>
                        <DropdownSearch
                          options={branch_list}
                          value={values.branch_id}
                          onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "branch_id", {department_id: "", assignee_ids: []})}
                          onBlur={handleBlur}
                          id="branch_id"
                          isSearchable={true}
                          isDisabled={!values.ticket_type_id}
                          placeholder={configJSON.branchPlaceholder}
                          noOptionsMessage={configJSON.noBranchMessage}
                          showError={handleInvoiceErrors(
                            errors.branch_id,
                            touched.branch_id
                          )}
                          errorText={handleInvoiceHelperText(
                            errors.branch_id,
                            touched.branch_id
                          )}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.departmentText}
                        </InputLabel>
                        <DropdownSearch
                          options={department_list}
                          value={values.department_id}
                          onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "department_id",{assignee_ids: []})}
                          onBlur={handleBlur}
                          isSearchable={true}
                          id="department_id"
                          isDisabled={!values.branch_id || department_list.length === 0}
                          placeholder={configJSON.departSelect}
                          noOptionsMessage={configJSON.noDepartmentMessage}
                          showError={handleInvoiceErrors(
                            errors.department_id,
                            touched.department_id
                          )}
                          errorText={handleInvoiceHelperText(
                            errors.department_id,
                            touched.department_id
                          )}
                        />
                      </Box>
                      <Box className={classes.fieldWrapper}>
                        <InputLabel className={classes.inputLabel} required>
                          {configJSON.employeeText}
                        </InputLabel>
                        <DropdownSearch
                          options={employees_list}
                          value={values.assignee_ids}
                          onChangeValue={(event: OptionType) => this.handleSelectChange(event, setFieldValue, "assignee_ids")}
                          onBlur={handleBlur}
                          id="assignee_ids"
                          isSearchable={true}
                          isDisabled={!values.department_id || employees_list.length === 0}
                          placeholder={configJSON.employeePlaceholder}
                          noOptionsMessage={configJSON.noEmployeeMessage}
                          showError={handleInvoiceErrors(
                            errors.assignee_ids,
                            touched.assignee_ids
                          )}
                          errorText={handleInvoiceHelperText(
                            errors.assignee_ids,
                            touched.assignee_ids
                          )}
                          isMulti={true}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={classes.createTicketBtnWrapper}>
                    <Button
                      data-test-id="cancel-btn"
                      variant="outlined"
                      className={classes.secondaryBtn}
                      onClick={handleReset}
                    >
                      {configJSON.cancelBtnText}
                    </Button>
                    <Button
                      data-test-id="create-btn"
                      variant="contained"
                      className={classes.primaryBtn}
                      type="submit"
                    >
                      {configJSON.createBtnText}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
            <SuccessModal
                    title={configJSON.greatTitle}
                    message={configJSON.confirmMessage}
                    open={open}
                    handleClick={this.handleOkClick}
                    buttonText={configJSON.okBtnText}
                />
          </Box>
        } />
      </>
    );
  }
  // Customizable Area End
}
export default withStyles(styles)(CreateTicket);

