export const imgChecklist = require("../assets/checklist.png");
export const imgDropdown = require("../assets/dropdown.png");
export const imgLinearScale = require("../assets/linear-scale.png");
export const imgMultipleChoice = require("../assets/multiple-choice.png");
export const imgParagraph = require("../assets/paragraph.png");
export const imgSingleAnswer = require("../assets/single-answer.png");
export const imgEmail = require("../assets/email.png");
export const imgFileUpload = require("../assets/file-upload.png");
export const imgLinkUrl = require("../assets/link-url.png");
export const imgPhone = require("../assets/phone.png");
