// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleExpiredToken, handleApiError, passwordEncryption, apiCall } from "../../../components/src/utils/commonFunctions";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    classes:Record<string,string>
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    password: string;
    confirmPassword: string;
    enablePasswordField: boolean;
    enableConfirmPassword: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}
// Customizable Area Start

// Customizable Area End

export default class NewPasswordController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    resendPasswordCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            password: "",
            confirmPassword: "",
            enablePasswordField: true,
            enableConfirmPassword: true,
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    handleSave = (values: { password: string }) => {
        this.handleResetPassword(values)
    }
    handleClickShowPassword = () => {
        this.setState({
            enablePasswordField: !this.state.enablePasswordField,
        });
    };
    handleClickShowConfirmPassword = () => {
        this.setState({
            enableConfirmPassword: !this.state.enableConfirmPassword,
        });
    };

    handleResetPassword = async (values: { password: string }) => {
      let httpBody = {
        data: {
            new_password: values.password,
            token: await getStorageData(configJSON.tokenType)
        }
      }
      passwordEncryption(values?.password).then((response) => 
        {
          httpBody.data.new_password = response;
          this.resendPasswordCallId = apiCall({ contentType: configJSON.forgotPasswordAPiContentType, method: configJSON.httpPostMethod, body: JSON.stringify(httpBody), endPoint: configJSON.resetPasswordApiEndPoint, token: false });
        }
      )
    }
    handleResponseForResetPassword = (from: string, message: Message) => {
        if (this.resendPasswordCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            handleExpiredToken(apiResponse, this.props.navigation);
            if (apiResponse.data) {
                localStorage.removeItem("qwipresetiv");
                this.props.navigation.history.push("/PasswordChanged")
            } else {
                handleApiError(apiResponse.errors);
            }
        }
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResponseForResetPassword(from, message)
        // Customizable Area End
    }
    // Customizable Area End


}
