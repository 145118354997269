// Customizable Area Start
import React from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  InputLabel,
  Card,
  CardContent,
  MobileStepper,
  FormHelperText,

} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { DateRange, Close } from '@material-ui/icons';

import { Formik } from "formik";
import JoiningFormController, { Props, RenderFormProps, configJSON } from "./JoiningFormController.web";
import { joiningformstyles } from "./JoiningFormStyles.web";
import LoginSignupBackgroundImgWeb from "../../../components/src/LoginSignupBackgroundImg.web";
import { allowNumberWithSpaceValidation, handleInvoiceErrors, handleInvoiceHelperText } from "../../../components/src/utils/commonFunctions";
import { uploadDocIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";

const logo = require("../../../components/src/qwipLogo.svg");
import DropdownSearch, { OptionType } from "../../../components/src/commonComponents/DropdownSearch.web";

// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class JoiningForm extends JoiningFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      photoimageUrl,
      activefunction,
      owner_name,
      family_name,
      owner_email,
      phone_number,
      date_of_birth,
      company_name,
      photo,
      countryCodeListfunction,
      country_code_and_flag_id
    } = this.state;



    const Stepone = (
      { errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        handleBlur }: RenderFormProps

    ) => (
      <Grid container className={classes.joiningformcontainer}>
        <Grid
          item
          xs={12}
          className={classes.joiningformLeftSection}
        >
          <Box className={classes.joiningformfieldWrapper}>
            <InputLabel required className={classes.joiningforminputLabel}>
              {configJSON.companyName}
            </InputLabel>
            <TextField
              data-test-id="companyName"
              type="text"
              variant="outlined"
              name={"company_name"}
              value={values.company_name}
              fullWidth
              className={classes.joiningforminputField}
              error={handleInvoiceErrors(
                errors.company_name,
                touched.company_name
              )}
              helperText={handleInvoiceHelperText(
                errors.company_name,
                touched.company_name
              )}
              onBlur={handleBlur}
              disabled={true}
            />
          </Box>

          <Box className={classes.joiningformfieldWrapper}>
            <InputLabel required className={classes.joiningforminputLabel}>
              {configJSON.placeHolderName}
            </InputLabel>
            <TextField
              data-test-id="fullName"
              type="text"
              variant="outlined"
              placeholder={configJSON.fullNamePlaceholder}
              name={"owner_name"}
              value={values.owner_name}
              onChange={(event) => this.handleFieldChange("owner_name", event.target.value, setFieldValue)}
              fullWidth
              className={classes.joiningforminputField}
              error={handleInvoiceErrors(
                errors.owner_name,
                touched.owner_name
              )}
              helperText={handleInvoiceHelperText(
                errors.owner_name,
                touched.owner_name
              )}
              onBlur={handleBlur}
            />
          </Box>
          <Box className={classes.joiningformfieldWrapper}>
            <InputLabel required className={classes.joiningforminputLabel}>
              {configJSON.familyName}
            </InputLabel>
            <TextField
              data-test-id="familyName"
              type="text"
              variant="outlined"
              placeholder={configJSON.familyNamePlaceholder}
              name={"family_name"}
              value={values.family_name}
              onChange={(event) => this.handleFieldChange("family_name", event.target.value, setFieldValue)}
              fullWidth
              className={classes.joiningforminputField}
              error={handleInvoiceErrors(
                errors.family_name,
                touched.family_name
              )}
              helperText={handleInvoiceHelperText(
                errors.family_name,
                touched.family_name
              )}
              onBlur={handleBlur}
            />
          </Box>
        </Grid>
      </Grid>
    )




    const StepTwo = (
      { errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        handleBlur }: RenderFormProps
    ) => (
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.joiningformuploadBox}>
          <Box className={`${classes.joiningformuploadImage} ${handleInvoiceErrors(errors.photo, touched.photo) && "error"}`}>
            <TextField
              key={photo !== null ? photo.toString() : undefined}
              id="photo"
              name="photo"
              variant="outlined"
              type="file"
              data-test-id="PhotoImageUrl"
              fullWidth
              onChange={(event:  React.ChangeEvent<HTMLInputElement>) => this.handlephotoUpload(event, setFieldValue)}
              InputProps={{
                inputProps: {
                  accept: "image/png, image/jpeg",
                },
              }}
            />
            <Box className="joingingforminnerContent">
              {photoimageUrl ? (
                <img
                  src={this.state.photoimageUrl}
                  alt="uploadDocIcon"
                  className="joingingformfilePreview"
                />
              ) : (
                <React.Fragment>
                  <img
                    src={uploadDocIcon}
                    alt="uploadDocIcon"
                    className="uploadImage"
                  />
                  <Typography
                    variant="body1"
                    className="joingingformuploadText"
                  >
                    {configJSON.uploadFile}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="joingingformuploadText"
                  >
                    {configJSON.profilePic}
                  </Typography>
                </React.Fragment>
              )}
            </Box>
            {photoimageUrl && <Box
              data-test-id="removeimage"
              className={classes.joiningformcloseIcn}
              onClick={() => {
                setFieldValue("photo", null)
                this.handledeleteImage()
              }
              } >
              <Close />
            </Box>}
            {handleInvoiceErrors(errors.photo, touched.photo) &&
              <FormHelperText className={`error-text ${classes.helperText}`}>
                {errors.photo}
              </FormHelperText>}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.joiningformInputSection}
        >

          <Box className={classes.joiningformfieldWrapper}>
            <InputLabel required className={classes.joiningforminputLabel}>
              {configJSON.labelEmail}
            </InputLabel>
            <TextField
              data-test-id="txtInputEmail"
              type="email"
              variant="outlined"
              placeholder={configJSON.emailPlaceholder}
              name={"owner_email"}
              value={values.owner_email}
              onChange={(event) => this.handleFieldChange("owner_email", event.target.value, setFieldValue)}
              fullWidth
              className={classes.joiningforminputField}
              error={handleInvoiceErrors(
                errors.owner_email,
                touched.owner_email
              )}
              helperText={handleInvoiceHelperText(
                errors.owner_email,
                touched.owner_email
              )}
              onBlur={handleBlur}
            />
          </Box>
          <Box className={classes.joiningformfieldWrapper}>
            <InputLabel required className={classes.joiningforminputLabel}>
              {configJSON.placeHolderPhoneNumber}
            </InputLabel>
            <Box className={classes.joiningformPhoneGroup}>
              <Box className={classes.joiningformdropdownWidth}>
                <Box className={classes.Dropdownstyles}>
                  <DropdownSearch
                    value={values.country_code_and_flag_id}
                    options={countryCodeListfunction}
                    onBlur={handleBlur}
                    isMyProfile={true}
                    onChangeValue={(event: OptionType) => this.handleChangeSelect(event, setFieldValue, "country_code_and_flag_id")}
                    id="country_code_and_flag_id"
                    noOptionsMessage={configJSON.noCountryCode}
                    placeholder={configJSON.countryCodePlaceholder}
                    isSearchable={true}
                  />
                </Box>
              </Box>
              <TextField
                type="text"
                variant="outlined"
                placeholder={configJSON.phoneNumberPlaceholder}
                value={values.phone_number}
                data-test-id="txtInputPhone"
                name={"phone_number"}
                onChange={(event) => this.handleFieldChange("phone_number", event.target.value, setFieldValue)}
                onKeyPress={allowNumberWithSpaceValidation}
                fullWidth
                className={`${classes.joiningforminputField} ${classes.joiningforminputWidth}`}
                onBlur={handleBlur}
              />
            </Box>
            {(handleInvoiceErrors(errors.phone_number, touched.phone_number) ||
              handleInvoiceErrors(errors.country_code_and_flag_id, touched.country_code_and_flag_id)) && (
                <FormHelperText
                  data-test-id={"errorMessage"}
                  className={classes.helperText}
                >
                  {handleInvoiceHelperText(errors.country_code_and_flag_id, touched.country_code_and_flag_id)}
                  {handleInvoiceHelperText(errors.phone_number, touched.phone_number)}
                </FormHelperText>
              )}
          </Box>


          <Box className={classes.joiningformfieldWrapper}>
            <InputLabel required className={classes.joiningforminputLabel}>
              {configJSON.dateOfBirth}
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                variant="inline"
                margin="normal"
                fullWidth
                data-test-id="dateofbirth"
                className={`${classes.joiningforminputField} ${classes.joiningformdateField}`}
                InputProps={{
                  endAdornment: <DateRange />,
                  disableUnderline: true,
                }}
                autoOk
                name="date_of_birth"
                value={values.date_of_birth}
                format="MM/dd/yyyy"
                allowKeyboardControl={false}
                placeholder={configJSON.dobPlaceholder}
                onChange={(newdate) => { this.setState({ date_of_birth: newdate }); setFieldValue("date_of_birth", newdate) }}
                onBlur={handleBlur}
                disableFuture={true}
              />
            </MuiPickersUtilsProvider>
            {(touched.date_of_birth && errors.date_of_birth) &&
              <FormHelperText className="error-text">
                {errors.date_of_birth}
              </FormHelperText>}
          </Box>
        </Grid>
      </Grid>



    )

    const renderActiveForm = (
      { errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        handleBlur }: RenderFormProps
    ) => {
      switch (activefunction) {
        case 1:
          return (
            <>
              {
                Stepone(
                  {
                    errors,
                    touched,
                    values,
                    handleChange,
                    setFieldValue,
                    handleBlur,
                  }

                )
              }
            </>
          )
        case 2:
          return (
            <>
              {
                StepTwo(
                  {
                    errors,
                    touched,
                    values,
                    handleChange,
                    setFieldValue,
                    handleBlur
                  }
                )
              }
            </>
          )

      }
    };

    const getInitialValuefunction = () => {
      if (activefunction === 1) {
        return {
          company_name,
          owner_name,
          family_name,
        };
      }
      else {
        return {
          photo,
          photoimageUrl,
          owner_email,
          country_code_and_flag_id,
          phone_number,
          date_of_birth,
        };
      }
    };

    return (
      // Required for all blocks
      <React.Fragment>
        <Loader loading={this.state.loading} />
        <LoginSignupBackgroundImgWeb />
        <Card className={classes.joiningformcard}>
          <CardContent className={classes.joiningformcardContent}>
            <Box>
              <img src={logo} alt="qwipLogo" style={{ height: "45px" }} />
            </Box>
            <Typography data-test-id="joining_form" variant="h4" className={classes.joiningformtitle}>
              {configJSON.joinFormText}
            </Typography>

            <MobileStepper
              variant="progress"
              steps={3}
              position="static"
              activeStep={activefunction}
              className={classes.joiningformprogressStepper}
              nextButton={
                <Button
                  data-test-id="joinbutton2"
                  size="small"
                  style={{ display: "none" }}
                >
                  {configJSON.joinButton}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  style={{ display: "none" }}
                >
                  {configJSON.backButton}
                </Button>
              }
            />
            <Formik
              data-test-id="onsubmitbutton"
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={getInitialValuefunction()}
              validationSchema={this.getValidationSchemafunction}
              onSubmit={(event) => this.handleNextfunction(event)}
              validateOnMount={true}
            >
              {({
                errors,
                touched,
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                handleBlur
              }) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                    className={
                      activefunction === 2
                        ? classes.joiningformform1
                        : classes.joiningformform
                    }
                  >
                    {renderActiveForm(
                   {   errors,
                      touched,
                      values,
                      handleChange,
                      setFieldValue,
                      handleBlur
                    }
                    )}
                    <Box
                      className={classes.joiningformbtnWrapper}
                    >
                      <Button
                        size="small"
                        className={`${classes.joiningformbtn} ${classes.joiningformsecondaryBtn}`}
                        onClick={this.handleGoBack}
                        data-test-id ="back_button"
                      >
                        {configJSON.backButton}
                      </Button>

                      <Button
                        data-test-id="Joinbutton"
                        size="small"
                        className={`${classes.joiningformbtn} ${classes.joiningformprimaryBtn}`}
                        type={"submit"}
                      >
                        {activefunction === 2 ? "Join" : "Next"}
                      </Button>

                    </Box>
                  </form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </React.Fragment>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(joiningformstyles)(JoiningForm);
// Customizable Area End
