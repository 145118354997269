// Customizable Area Start
import { colors } from "../../../components/src/utils/commonDesign";

const styles = {
    label: {
        color: colors.text,
        marginBottom: "7px",
    },
    input: {
        borderRadius: "10px",
        "& ::placeholder": {
            color: "black",
            fontWeight: 300,
        },
        "& .MuiInputBase-root": {
            borderRadius: "10px",
        },
    },
    popperStyle: {
        border: "1px solid lightgray",
        borderRadius: "0px 0px 10px 10px",
        margin: 0,
        position: "inherit" as const,

    },
    paperStyle: {
        maxHeight: "220px",
        overflow: "auto",
        boxShadow: "none",
        "&::-webkit-scrollbar": {
            width: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
            borderRadius: "5px"
        }
    },
    input2: {
        "& .MuiInputBase-root": {
            borderRadius: "10px 10px 0px 0px",
        },
        
    },
    checkBoxStyle: {
        color: colors.secondaryColor2
    },
    primaryBtn: {
        backgroundColor: colors.secondaryColor2,
        fontSize: "1rem",
        fontFamily: "Switzer",
        borderRadius: "10px",
        textTransform: "capitalize" as const,
        fontWeight: 700,
        marginInline: "8px",
        color: colors.primaryColor2,
        "&:hover": {
            backgroundColor: colors.secondaryColor2
        },
    },
    cnacelBtn: {
        fontFamily: "Switzer",
        fontSize: "1rem",
        marginInline: "8px",
        textTransform: "capitalize" as const,
        borderRadius: "10px",
        fontWeight: 700,
        color: colors.primaryColor2,
    },
    listBox: {
        padding: 0
    },
    options: {
        paddingBlock: 0
    },
    optionMargin:{
        marginRight:8
    },
    chipMargin:{
        marginInline: "2px"
    },
    btnGroup:{
        paddingTop: "16px", 
        textAlign: "center" as const 
    },
    issueField:{
        paddingBottom: "16px",   
    }
}

export default styles;
// Customizable Area End