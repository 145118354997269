// Customizable Area Start
import { commonButton, commonInputField } from "../../../components/src/utils/commonInputStyle";
import { colors } from "../../../components/src/utils/commonDesign";

export const styles = {
  ...commonInputField,
  ...commonButton,
  createTicketTitle: {
    fontSize: "24px",
    lineHeight: "32px",
    color: colors.text,
    fontWeight: 600,
  },
  formContainer: {
    marginTop: "50px",
  },
  createTicketBtnWrapper : {
    display: "flex",
    marginTop: "100px",
    paddingBottom: "10px",
    justifyContent: "center",
  },
  maxSizeTicket: {
    fontSize: 12,
    display: "block",
    color: "#020202",
    opacity: 0.6,
    position: "absolute" as const,
    right: 0
  }
};
// Customizable Area End